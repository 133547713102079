import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {AbstractPhoneHistoryView, PhoneHistoryViewEventTypes} from "./AbstractPhoneHistoryView.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * An enum containing a list of busy markers for the master call view
 * @enum {string}
 * @readonly
 */
export const MasterPhoneHistoryViewBusyContext = {
	/* Load 'view' property for a phonehistory object, wait the response of the PhoneHistoryService.readById method.
	 * Set More/Less trigger as busy and display the loader */
    LOAD_CALL_VIEWS		: 'load_call_views',

	/* Display the list of the call legs. Set More/Less trigger as busy, loader is hidden */
    DISPLAY_CALL_VIEWS	: 'display_call_views'
};

/**
 * Creates a new object representing the content of the first view of a call from the callhistory list.
 *
 * @extends {AbstractPhoneHistoryView}
 * @unrestricted 
*/
export class MasterPhoneHistoryView extends AbstractPhoneHistoryView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Toggle button used to expand extra views for the call
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.showCallViewTrigger_;
    }

    /**
     * Mark call view as busy. Enable busy behavior on callViews trigger
     * @param {!boolean} isBusy
     * @param {!string} busyContext
     */
    setBusy(isBusy, busyContext) {
        if (isBusy) {
            /* mark button as busy */
            this.showCallViewTrigger_.setBusy(true);

        } else {
            this.showCallViewTrigger_.setBusy(false);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);

        const translator = Translator;

        /* expand trigger button for collapsing more call legs */
        this.showCallViewTrigger_ = new ToggleButton({
            'content'       : translator.translate('more'),
            'extraCSSClass' : ['hg-ch-item-call-views-trigger', 'expand']
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.showCallViewTrigger_);
        this.showCallViewTrigger_ = null;
    }

    /** @inheritDoc*/
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.showCallViewTrigger_, UIComponentEventTypes.ACTION, this.handleCallViewListToggle_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.mainPartyAvatar_, {'set': this.mainPartyAvatar_.setModel}, 'source.participant');

        this.setBinding(this.mainPartyName_, {'set': this.mainPartyName_.setModel}, 'source.participant');

        this.setBinding(this.mainPartyPhone_, {'set': this.mainPartyPhone_.setModel}, 'source');

        this.setBinding(this.addUnknownPartyBtn_, {'set': this.addUnknownPartyBtn_.setVisible}, {
            'sourceProperty': 'source.participant.type',
            'converter': {
                'sourceToTargetFn': function(callPartyType) {
                    return callPartyType == null || callPartyType === AuthorType.THIRDPARTY;
                }
            }
        });

        this.setBinding(this.secondaryPartyName_, {'set': this.secondaryPartyName_.setModel}, 'destination.participant');

        this.setBinding(this.secondaryPartyPhone_, {'set': this.secondaryPartyPhone_.setModel}, 'destination');

        this.setBinding(this.startedDate_, {'set': this.startedDate_.setDateTime},	'started');

        this.setBinding(this.disposition_, {'set': this.disposition_.setModel}, '');

        this.setBinding(this.showCallViewTrigger_, {'set': this.showCallViewTrigger_.setVisible}, {
            'sourceProperty': 'viewCount',
            'converter': {
                'sourceToTargetFn': function (viewCount) {
                    return viewCount != null && viewCount > 1;
                }
            }
        });
    }

    /** @inheritDoc */
    createDispositionDetailsDom() {
        const dispositionContainer = new VerticalStack({
            'extraCSSClass': 'hg-ch-disposition-info-container'
        });

        const infoItemTopContainer = new HorizontalStack({'extraCSSClass': 'hg-ch-disposition-details-container'});
        infoItemTopContainer.addChild(this.showCallViewTrigger_, true);
        infoItemTopContainer.addChild(this.startedDate_, true);

        const infoItemBottomContainer = new HorizontalStack({'extraCSSClass': 'hg-ch-disposition-resources-container'});
        infoItemBottomContainer.addChild(this.resourcesContainer_, true);
        infoItemBottomContainer.addChild(this.disposition_, true);

        dispositionContainer.addChild(infoItemTopContainer, true);
        dispositionContainer.addChild(infoItemBottomContainer, true);

        return dispositionContainer;
    }

    /** @inheritDoc */
    handleResourceSelection(e) {
        /* a resource has type {'attached': true/false, 'type': FAX/REC/VM} */
        const selectedResource = /** @type {hf.ui.Button} */(e.getTarget()),
            resourceModel = selectedResource.getModel(),
            phoneHistoryModel = this.getModel();

        if (resourceModel == null || phoneHistoryModel == null) {
            return;
        }

        if (resourceModel['attached'] === true && phoneHistoryModel['firstPhoneHistoryViewId'] != null) {
            const showResourceEvent = new Event(PhoneHistoryViewEventTypes.SHOW_RESOURCE);
            showResourceEvent.addProperty('phoneHistoryViewId', phoneHistoryModel['firstPhoneHistoryViewId']);

            this.dispatchEvent(showResourceEvent);
        }
    }

    /**
     * Handles call view list toggle
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleCallViewListToggle_(e) {
        const expand = (this.showCallViewTrigger_.isChecked()),
            translator = Translator;

        if(expand) {
            const loadCallViewsEvent = new Event(PhoneHistoryViewEventTypes.LOAD_CALL_VIEWS);
            loadCallViewsEvent.addProperty('phoneHistory', this.getModel());
            this.dispatchEvent(loadCallViewsEvent);
        }

        /* change appearance of the toggle button */
        this.showCallViewTrigger_.removeExtraCSSClass(expand ? 'expand' : 'collapse');
        this.showCallViewTrigger_.addExtraCSSClass(expand ? 'collapse' : 'expand');
        this.showCallViewTrigger_.setContent(translator.translate(expand ? 'less' : 'more'));

        /* dispatch OPEN/CLOSE event to parent in order to display / hide the view list */
        this.setOpen(expand);
    }
};