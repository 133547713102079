import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ScreenShareStatus} from "./../../../data/model/screenshare/Enums.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class ScreenShareViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* all active screen share session (incoming, mine) */
        this.addField({'name': 'allScreenShares', 'value': null});

        /* my ACTIVE screen share session, if any */
        this.addField({'name': 'myScreenShare', 'value': null});

        /* incoming screen share sessions (pending, joined) */
        this.addField({'name': 'incomingScreenShares', 'value': null});

        /* number of screen share sessions (both pending and onAir) */
        this.addField({'name': 'screenShareCount', 'value': 0});

        /* flag to determine if there are air screen share sessions */
        this.addField({'name': 'hasOnAirScreenShare', 'value': false});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updateIncomingScreenShare_();
        this.updateMyScreenShare_();
        this.updateScreenShareCount_();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'allScreenShares') {
            this.updateIncomingScreenShare_();
            this.updateMyScreenShare_();
            this.updateScreenShareCount_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e),
            payload = e['payload'];

        /* add or remove from sessions list */
        if (fieldName == 'allScreenShares' && (payload['field'] == null || payload['field'] == 'status')) {
            this.updateIncomingScreenShare_();
            this.updateMyScreenShare_();
            this.updateScreenShareCount_();
        }

        return result;
    }

    /**
     * @private
     */
    updateIncomingScreenShare_() {
        if (this['allScreenShares'] != null) {
            this['incomingScreenShares'] = new CollectionView({
                'source': this['allScreenShares'],
                'filters': function (screenShare) {
                    screenShare = /** @type {hg.data.model.screenshare.ScreenShare} */(screenShare);
                    return !screenShare.get('author.isMe');
                },
                'sorters': [
                    {
                        'sortBy': 'author.name',
                        'direction': SortDirection.ASC
                    }
                ]
            });
        } else {
            this['incomingScreenShares'] = null;
        }
    }

    /**
     * @private
     */
    updateMyScreenShare_() {
        if (this['allScreenShares'] != null) {
            this['myScreenShare'] = this['allScreenShares'].find(function (screenShare) {
                screenShare = /** @type {hg.data.model.screenshare.ScreenShare} */(screenShare);
                return !!screenShare.get('author.isMe');
            });
        } else {
            this['myScreenShare'] = null;
        }
    }

    /**
     * @private
     */
    updateScreenShareCount_() {
        this['screenShareCount'] = this['allScreenShares'] != null
            ? this['allScreenShares'].getCount() : 0;

        const match = this['allScreenShares'].find(function (screenShare) {
            screenShare = /** @type {hg.data.model.screenshare.ScreenShare} */(screenShare);
            return screenShare['status'] == ScreenShareStatus.ONAIR;
        });

        this['hasOnAirScreenShare'] = match != null;
    }
};