import { BaseUtils } from '../../../base.js';
import { ArrayUtils } from '../../../array/Array.js';
import { HfError } from '../../../error/Error.js';
import { CommonBusyContexts } from '../../../ui/Consts.js';
import { ObservableObject } from '../../../structs/observable/Observable.js';
import { StringUtils } from '../../../string/string.js';

/**
 * Creates a new {@see ViewModelBase} instance.
 *
 * @augments {ObservableObject}
 *
 */
export class ViewModelBase extends ObservableObject {
    /**
     * @param {!object=} opt_initialData
     *
     */
    constructor(opt_initialData) {
        super(opt_initialData);

        /**
         *
         * @type {object.<string, Promise>}
         * @private
         */
        this.loadingFields_;

        /**
         * @type {Array}
         * @private
         */
        this.activeAsyncOperations_;
    }

    /** @inheritDoc */
    init(opt_initialData) {
        this.loadingFields_ = {};

        this.activeAsyncOperations_ = [];

        // add default fields
        this.addField({ name: 'busyContext', value: null });
        this.addField({ name: 'isBusy', value: false });
        this.addField({ name: 'errorContext', value: null });
        this.addField({ name: 'error', value: null });

        this.defineFields();

        this.loadDataInternal(opt_initialData || {}, { overrideChanges: true });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.activeAsyncOperations_.length = 0;
        this.activeAsyncOperations_ = null;
        this.loadingFields_ = null;
    }

    /** @inheritDoc */
    generateClientId() {
        return StringUtils.createUniqueString('__hf_app_ui_view_model_');
    }

    /** @inheritDoc */
    onDataLoaded() {
        /* Reset the default fields */
        this.set('busyContext', null, true);
        this.set('isBusy', false, true);
        this.set('errorContext', null, true);
        this.set('error', null, true);
    }

    /**
     * Defines the fields of this model by calling the #addField() method foreach field that someone wants to add.
     * This method is overriden by the inheritors.
     *
     * @protected
     */
    defineFields() {
        // nop here; overriden by inheritors
    }

    /**
     * @inheritDoc
     */
    createProperty(fieldParams) {
        const name = fieldParams.name;

        const getterFn = BaseUtils.isFunction(fieldParams.getter)
        /** @type {Function} */? (fieldParams.getter).bind(this)
            : () => this.getInternal(name);

        const setterFn = (val, opt_silent) => {
            if (BaseUtils.isFunction(fieldParams.setter)) {
                /** @type {Function} */(fieldParams.setter).call(this, val, opt_silent);
            } else {
                this.setInternal(name, val);
            }
        };

        Object.defineProperty(this, name, {
            get: getterFn,
            set: setterFn
        });
    }

    /**
     * @inheritDoc
     */
    setInternal(fieldName, value, opt_silent) {
        super.setInternal(fieldName, value, opt_silent);

        /* if the field value is set while the field is loading then stop (or ignore) the loading operation */
        if (value !== undefined && this.isFieldLoading(fieldName)) {
            this.loadingFields_[fieldName] = null;
            delete this.loadingFields_[fieldName];
        }
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for busyContext, errorContext, error */
        if (fieldName == 'busyContext' || fieldName == 'errorContext' || fieldName == 'error') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /**
     *
     * @param {boolean} isBusy
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setIsBusy(isBusy, opt_busyContext) {
        if (this.isDisposed()) {
            return;
        }

        /* silently set the busy context */
        this.set('busyContext', opt_busyContext, true);

        this.isBusy = isBusy;

        /* if it became idle then reset the busy context as well */
        if (!isBusy) {
            this.set('busyContext', null, true);
        }
    }

    /**
     *
     * @param {*} error
     * @param {*=} opt_errorContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setError(error, opt_errorContext) {
        if (this.isDisposed()) {
            return;
        }

        // silently set the error context
        this.set('errorContext', opt_errorContext, true);

        this.error = error;

        /* reset the error context if it has no error */
        if (error == null) {
            this.set('errorContext', null, true);
        }
    }

    /**
     * Creates a lazy getter.
     *
     * @param {string} fieldName
     * @param {function(): *} getter
     * @returns {Function}
     * @protected
     */
    createLazyGetter(fieldName, getter) {
        return () => {
            if (!this.fieldHasValue(fieldName)) {
                // silently set the field value; there is no need to notify it changed
                this.setInternal(
                    fieldName,
                    getter.call(this),
                    true
                );
            }

            return this.getInternal(fieldName);
        };
    }

    /**
     *
     * @param {string} fieldName
     * @param {function(): Promise} asyncLoader
     * @returns {Function}
     * @protected
     */
    createAsyncGetter(fieldName, asyncLoader) {
        return () => this.loadFieldAsync(fieldName, asyncLoader);
    }

    /**
     * Asynchronously (lazy) loads the field value.
     *
     * @param {string} fieldName
     * @param {function(): Promise} asyncLoader
     * @protected
     */
    loadFieldAsync(fieldName, asyncLoader) {
        if (!this.fieldHasValue(fieldName) && !this.isFieldLoading(fieldName)) {
            this.loadingFields_[fieldName] = this.executeAsync(
                // async operation
                asyncLoader,

                // callback
                function (result) {
                    // check whether the containing model was disposed meanwhile
                    if (!this.isDisposed() && this.isFieldLoading(fieldName)) {
                        this.loadingFields_[fieldName] = null;
                        delete this.loadingFields_[fieldName];

                        // set the field value => a CHANGE event will be dispatched
                        this.setInternal(fieldName, result);
                    }

                    return result;
                },

                // errback
                function (error) {
                    // check whether the containing model was disposed meanwhile
                    if (!this.isDisposed()) {
                        this.loadingFields_[fieldName] = null;
                        delete this.loadingFields_[fieldName];
                    }

                    throw error;
                },

                // operation context
                { operation: CommonBusyContexts.LOAD_FIELD, fieldName }
            );
        }

        return this.getInternal(fieldName);
    }

    /**
     * Indicates whether the field is asynchronously lazy loaded.
     *
     * @param {string} fieldName
     * @returns {boolean}
     */
    isFieldLoading(fieldName) {
        return !this.isDisposed()
            && this.loadingFields_.hasOwnProperty(fieldName)
            && this.loadingFields_[fieldName] instanceof Promise;
    }

    /**
     *
     * @param  {string} fieldName
     * @returns {Promise|undefined}
     */
    getLoadingField(fieldName) {
        return this.loadingFields_[fieldName];
    }

    /**
     * Runs an async operation on this ViewModel. All functions will be bound to this instance.
     *
     * @param {function(): *} asyncOperation
     * @param {?(function(*): *)=} opt_callback Called if the operation is successful. If it returns something, it will be the new result.
     * @param {?(function(*): *)=} opt_errback Called if an error happened. If it returns/throws something, it will be the new error
     * @param {*=} opt_operationContext
     * @returns {Promise}
     * @protected
     */
    executeAsync(asyncOperation, opt_callback, opt_errback, opt_operationContext) {
        this.setIsBusy(true, opt_operationContext);

        /* reset the current error */
        this.setError(null);

        let result = asyncOperation.call(this)
            .then((result) => {
                this.setIsBusy(false, opt_operationContext);

                result = opt_callback ? (opt_callback.call(this, result) || result) : result;

                this.setError(null);

                return result;
            })
            .catch((error) => {
                this.setIsBusy(false, opt_operationContext);

                if (opt_errback) {
                    try {
                        error = opt_errback.call(this, error) || error;
                    } catch (e) {
                        error = e;
                    }
                }

                if (!(error instanceof HfError)) {
                    error = new HfError(error);
                }

                this.setError(error, opt_operationContext);

                throw error;
            })
            .finally(() => {
                if (BaseUtils.isArray(this.activeAsyncOperations_)) {
                    ArrayUtils.remove(this.activeAsyncOperations_, result);
                }
            });

        this.activeAsyncOperations_.push(result);

        return result;
    }
}
