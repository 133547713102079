import {HgAbstractReferenceEditorPlugin} from "./AbstractReference.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {StyleUtils} from "./../../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {MessageEditorCommands} from "./../Enums.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new Giphy editor plugin
 * @extends {HgAbstractReferenceEditorPlugin}
 * @unrestricted 
*/
export class HgGiphyEditorPlugin extends HgAbstractReferenceEditorPlugin {
    /**
     * @param {boolean=} encodeOnEnter Optional configuration
    */
    constructor(encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (encodeOnEnter != null && BaseUtils.isBoolean(encodeOnEnter)) ? encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * Whether the plugin should send the gif when press Enter key
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;
    }

    /** @override */
    getTrogClassId() {
        return 'Giphy';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.GIPHY;
    }

    /**
     * Enable or disable the behavior that enabled the giphy to be sent om enter
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        return HgMetacontentUtils.decodeGiphyTag(pastedContent, HgMetacontentUtils.GifSize.SMALL);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and replace gifs with graphical images.
        const element = field.getElement();
        if (element) {
            element.innerHTML = HgMetacontentUtils.decodeGiphyTag(element.innerHTML, HgMetacontentUtils.GifSize.SMALL);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeGiphyTag(content, HgMetacontentUtils.GifSize.SMALL);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        return HgMetacontentUtils.encodeGiphyTag(content);
    }

    /** @inheritDoc */
    execCommandInternal(command, giphy, size, ratio, frames, opt_checkWhitespace) {
        opt_checkWhitespace = opt_checkWhitespace || false;

        const editor = this.getFieldObject();
        let range = editor.getRange(),
            giphyStr,
            currentField = range.getAnchorNode();

        if (range && BaseUtils.isString(giphy)) {
            const emptyNode = this.getDocument().createTextNode('\xa0');

            if(currentField.nodeType != 3 && currentField.hasAttribute('class') && currentField.className == HgMetacontentUtils.GIPHY_WRAP) {
                if(HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                    if (currentField.parentNode) {
                        currentField.parentNode.insertBefore(emptyNode, currentField.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(currentField.nextSibling, false);
                } else if(!HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                    if (currentField.parentNode) {
                        currentField.parentNode.insertBefore(emptyNode, currentField);
                    }
                    EditorRange.placeCursorNextTo(currentField.previousSibling, true);
                }
            } else if(currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.PHONE_NUMBER]
                    || currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.LINK]) {
                if (currentField.parentNode.parentNode) {
                    currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                }
                EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
            } else if (currentField.parentNode.className == HgMetacontentUtils.ActionTagClassName[HgMetacontentUtils.ActionTag.HASHTAG]) {
                const offset = range.getAnchorOffset();
                /* if at the beginning of the tag, insert emoji before it, else after it. */
                if (offset == 0) {
                    if (currentField.parentNode.parentNode) {
                        currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode);
                    }
                    EditorRange.placeCursorNextTo(emptyNode, true);
                } else {
                    if (currentField.parentNode.parentNode) {
                        currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
                }
            } else if(currentField.className == HgMetacontentUtils.GIPHY_PLAY_BUTTON){
                if (currentField.parentNode.parentNode) {
                    currentField.parentNode.parentNode.insertBefore(emptyNode, currentField.parentNode.nextSibling);
                }
                EditorRange.placeCursorNextTo(currentField.parentNode.nextSibling, false);
            } else if(currentField.nodeType == 3){
                EditorRange.placeCursorNextTo(currentField, false);

                range = editor.getRange();
                currentField = range.getAnchorNode();

                if(range && currentField.className == HgMetacontentUtils.GIPHY_WRAP){
                    if (currentField.parentNode) {
                        currentField.parentNode.insertBefore(emptyNode, currentField);
                    }
                    EditorRange.placeCursorNextTo(currentField.previousSibling, true);
                }
            }

            range = editor.getRange();
            currentField = range.getAnchorNode();

            if(range) {
                /* insert images for every valid gif */
                if(HgMetacontentUtils.isNonFormattingTag(currentField) || HgMetacontentUtils.isNonFormattingTag(currentField.parentNode)) {
                    giphyStr = giphy;
                } else {
                    giphyStr = HgMetacontentUtils.decodeGiphyTag(/** @type {string} */(giphy), size, false, ratio, frames);
                };
                /* create a new node and set the node content with the string that contains the anchors images for every valid gif */
                const gif = DomUtils.htmlToDocumentFragment(/** @type {string} */(giphyStr));

                range.replaceContentsWithNode(gif);

                /* check whitespace before and after the tag */
                if (opt_checkWhitespace) {
                    if (gif.parentNode) {
                        gif.parentNode.insertBefore(emptyNode, gif.nextSibling);
                    }
                }

                if (editor.isScrollable() && (gif instanceof Element)) {
                    const offset = StyleUtils.getContainerOffsetToScrollInto(/** @type {Element} */(gif), editor.getOriginalElement());

                    setTimeout(() => {
                        editor.scrollTo(offset);
                    }, 300);
                }

                if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                    setTimeout(() => EditorRange.placeCursorNextTo(gif, false));
                } else {
                    EditorRange.placeCursorNextTo(gif, false);
                }
            }
        }
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        const keyCode = e.keyCode || e.charCode,
            editor = this.getFieldObject(),
            range = editor.getRange();

        if (range == null) {
            return false;
        }

        const currentCarretNode = range.getAnchorNode();

        if(keyCode == KeyCodes.PERIOD && e != null && !!e.shiftKey && (currentCarretNode.previousSibling != null && currentCarretNode.previousSibling.className === HgMetacontentUtils.GIPHY_WRAP)) {
            currentCarretNode.nodeValue = currentCarretNode.nodeValue.replace(/(^[\r\n\f\v \xa0])>/g, ">");
            if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode, false));
            } else {
                EditorRange.placeCursorNextTo(currentCarretNode, false);
            }
            return true;
        }
        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const keyCode = e.keyCode || e.charCode,
            editor = this.getFieldObject(),
            range = editor.getRange();

        if (range == null) {
            return false;
        }

        if (((e.ctrlKey != null && e.ctrlKey) || (e.metaKey != null && e.metaKey)) && !StringUtils.isEmptyOrWhitespace(range.getText)) {
            return false;
        }

        const currentCarretNode = range.getAnchorNode(),
            emptyNode = this.getDocument().createTextNode('\uFEFF'),
            enableEncodeOnEnter = (this.encodeOnEnter_ || (e != null && !!e.shiftKey)),
            offset = range.getAnchorOffset();

        switch (keyCode) {
            case KeyCodes.DELETE:
                if (currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP) {
                    if (!HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                        if (currentCarretNode && currentCarretNode.parentNode) {
                            currentCarretNode.parentNode.removeChild(currentCarretNode);
                        }
                        if (!StringUtils.isEmptyOrWhitespace(range.getText())) {
                            range.removeContents();
                        }
                        e.preventDefault();
                    }
                }
                if (!range.isCollapsed() && range.getFocusNode().className == HgMetacontentUtils.GIPHY_WRAP){
                    if (range.getFocusNode() && range.getFocusNode().parentNode) {
                        range.getFocusNode().parentNode.removeChild(range.getFocusNode());
                    }
                    range.removeContents();
                    e.preventDefault();
                }
                break;
            case KeyCodes.BACKSPACE:
                if (currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP && HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                    if (currentCarretNode && currentCarretNode.parentNode) {
                        currentCarretNode.parentNode.removeChild(currentCarretNode);
                    }
                    if (!StringUtils.isEmptyOrWhitespace(range.getText())) {
                        range.removeContents();
                    }
                    e.preventDefault();
                } else if (currentCarretNode.parentNode.className === HgMetacontentUtils.GIPHY_WRAP) {
                    if (currentCarretNode.parentNode.parentNode) {
                        currentCarretNode.parentNode.parentNode.removeChild(currentCarretNode.parentNode);
                    }
                    e.preventDefault();
                } else if (currentCarretNode.previousSibling != null && currentCarretNode.previousSibling.className === HgMetacontentUtils.GIPHY_WRAP && currentCarretNode.nodeType === 3 && range.getStartOffset() == 0 && range.getEndOffset() == 0){
                    if (currentCarretNode.previousSibling && currentCarretNode.previousSibling.parentNode) {
                        currentCarretNode.previousSibling.parentNode.removeChild(currentCarretNode.previousSibling);
                    }
                    e.preventDefault();
                }
                if (!range.isCollapsed() && range.getFocusNode().className == HgMetacontentUtils.GIPHY_WRAP){
                    if (range.getFocusNode() && range.getFocusNode().parentNode) {
                        range.getFocusNode().parentNode.removeChild(range.getFocusNode());
                    }
                    range.removeContents();
                    e.preventDefault();
                }
                break;
            case KeyCodes.ENTER:
                if (enableEncodeOnEnter && currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP) {

                    if (HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                        if (currentCarretNode.parentNode) {
                            currentCarretNode.parentNode.insertBefore(emptyNode, currentCarretNode.nextSibling);
                        }
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.nextSibling, false));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.nextSibling, false);
                        }
                    } else if (!HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                        if (currentCarretNode.parentNode) {
                            currentCarretNode.parentNode.insertBefore(emptyNode, currentCarretNode);
                        }
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, true));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, true);
                        }
                    }
                    e.preventDefault();
                    return true;
                } else
                    //used for Firefox - when inserting a gif, the cursor will be placed outside the editor. So we have to move it back in.
                    if (StringUtils.isEmptyOrWhitespace(currentCarretNode.className) && currentCarretNode.tagName == 'DIV'
                        && /** @type {Element|null} */ (currentCarretNode).style.position == 'relative' && /** @type {Element|null} */ (currentCarretNode).style.height == '100%'){
                        if (currentCarretNode.firstChild.firstChild.parentNode) {
                            currentCarretNode.firstChild.firstChild.parentNode.insertBefore(emptyNode, currentCarretNode.firstChild.firstChild.nextSibling);
                        }
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.firstChild.firstChild.nextSibling, true));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.firstChild.firstChild.nextSibling, true);
                        }
                        e.preventDefault();
                        return true;
                    } else break;
            case KeyCodes.SHIFT:
                e.preventDefault();
                return true;
            case KeyCodes.UP:
                if (currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP) {
                    e.preventDefault();
                    return true;
                } else break;
            case KeyCodes.LEFT:
                if (currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP) {
                    e.preventDefault();
                    return true;
                } else break;
            default: if (currentCarretNode.className === HgMetacontentUtils.GIPHY_WRAP) {
                        if (HgGiphyEditorPlugin.isCarretAfterGif(range)){
                            if (currentCarretNode.parentNode) {
                                currentCarretNode.parentNode.insertBefore(emptyNode, currentCarretNode.nextSibling);
                            }
                            if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                                setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.nextSibling, false));
                            } else {
                                const siblingAfterEmptyNode = currentCarretNode.nextSibling.nextSibling;
                                if (siblingAfterEmptyNode != null && siblingAfterEmptyNode.className == HgMetacontentUtils.GIPHY_WRAP) {
                                    EditorRange.placeCursorNextTo(siblingAfterEmptyNode, true);
                                } else {
                                    EditorRange.placeCursorNextTo(currentCarretNode.nextSibling, false);
                                }
                            }
                        } else if (!HgGiphyEditorPlugin.isCarretAfterGif(range)) {
                            if (currentCarretNode.parentNode) {
                                currentCarretNode.parentNode.insertBefore(emptyNode, currentCarretNode);
                            }
                            if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                                setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, true));
                            } else {
                                if (offset == 0) {
                                    const prevNode = emptyNode.previousSibling;
                                    if (prevNode != null) {
                                        EditorRange.placeCursorNextTo(prevNode, false);
                                    } else {
                                        EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, true);
                                    }
                                } else {
                                    EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, true);
                                }
                            }
                        }
                    } else
                    //used for Firefox - when writing after a gif, the cursor will be placed outside the editor. So we have to move it back in.
                    if (StringUtils.isEmptyOrWhitespace(currentCarretNode.className) && currentCarretNode.tagName == 'DIV'
                        && /** @type {Element|null} */ (currentCarretNode).style.position == 'relative' && /** @type {Element|null} */ (currentCarretNode).style.height == '100%') {
                        if (currentCarretNode.firstChild.firstChild.parentNode) {
                            currentCarretNode.firstChild.firstChild.parentNode.insertBefore(emptyNode, currentCarretNode.firstChild.firstChild.nextSibling);
                        }
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.firstChild.firstChild.nextSibling, false));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.firstChild.firstChild.nextSibling, false);
                        }
                    } else if (currentCarretNode.className == HgMetacontentUtils.GIPHY_PLAY_BUTTON){
                        if (currentCarretNode.parentNode.parentNode) {
                            currentCarretNode.parentNode.parentNode.insertBefore(emptyNode, currentCarretNode.parentNode.nextSibling);
                        }
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.parentNode.nextSibling, false));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.parentNode.nextSibling, false);
                        }
                    } else if(currentCarretNode.nodeType === 3 && currentCarretNode.length == 0 && currentCarretNode.previousSibling != null && currentCarretNode.previousSibling.className == HgMetacontentUtils.GIPHY_WRAP){
                        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                            setTimeout(() => EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, false));
                        } else {
                            EditorRange.placeCursorNextTo(currentCarretNode.previousSibling, false);
                        }
                    } else break;
        }
        return false;
    }

    /** @inheritDoc */
    handleKeyPress(e) {
        return false;
    }

    /** @inheritDoc */
    handleClick(e) {
        const elem = /** @type {Element} */ (e.getTarget()),
            giphyElem = elem.parentNode;

        if(elem.className === HgGiphyEditorPlugin.Buttons_.GIPHY_ACTION_BUTTON) {
            let nextSibling = giphyElem.nextSibling;
            if(nextSibling != null && nextSibling.nodeValue === '\n' && nextSibling.parentNode) {
                nextSibling.parentNode.removeChild(nextSibling);
            }
            nextSibling = giphyElem.nextSibling;
            if(nextSibling != null && nextSibling.nodeValue === '\xA0' && nextSibling.parentNode) {
                nextSibling.parentNode.removeChild(nextSibling);
            }
            const nextSiblingAfterDelete = giphyElem.nextSibling;
            const previousSiblingAfterDelete = giphyElem.previousSibling;
            if (giphyElem && giphyElem.parentNode) {
                giphyElem.parentNode.removeChild(giphyElem);
            }
            if(nextSiblingAfterDelete != null){
                if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                    setTimeout(() => EditorRange.placeCursorNextTo(nextSiblingAfterDelete, true));
                } else {
                    EditorRange.placeCursorNextTo(nextSiblingAfterDelete, true);
                }
            } else if(previousSiblingAfterDelete != null){
                if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
                    setTimeout(() => EditorRange.placeCursorNextTo(previousSiblingAfterDelete, false));
                } else {
                    EditorRange.placeCursorNextTo(previousSiblingAfterDelete, false);
                }
            }

            this.getFieldObject().dispatchChange();
            
            return true;
        }

        return false;
    }

    /**
     * Check if the caret current position is after the gif
     * @param {hf.AbstractDomRange} range - Current Range
     * @return {boolean}
     */
    static isCarretAfterGif(range) {
        return (range.getEndOffset() == 1 && range.getStartOffset() == 1);
    }
};

/**
 * Giphy action button
 * @enum {string}
 * @private
 */
HgGiphyEditorPlugin.Buttons_ = {
    /* The CSS class for the 'close' (X) button on gif*/
    GIPHY_ACTION_BUTTON: 'hg-giphy-action-button'
};