import {FileTagMeta} from "./../../../data/model/file/FileTagMeta.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Rect} from "./../../../../../../hubfront/phpnoenc/js/math/Rect.js";

import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FileDownloadStates, FileTypes} from "./../../../data/model/file/Enums.js";
import {HgUIEventType} from "./../events/EventType.js";
import {FilePreviewEventType} from "./Common.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgDateUtils} from "./../../date/date.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Bar} from "./../../../../../../hubfront/phpnoenc/js/ui/progress/Bar.js";
import {HTML5Video} from "./../../../../../../hubfront/phpnoenc/js/ui/media/HTML5Video.js";
import {HTML5Audio} from "./../../../../../../hubfront/phpnoenc/js/ui/media/HTML5Audio.js";
import {HTML5MediaSource} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Source.js";
import {HgButtonUtils} from "./../button/Common.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HTML5MediaEventTypes, HTML5MediaPreloadTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Enums.js";
import {AbstractHTML5Media} from "./../../../../../../hubfront/phpnoenc/js/ui/media/AbstractHTML5Media.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new media (audio/video) preview object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MediaPreviewContent extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *   @param {boolean=} opt_config.autoplay
     *   @param {boolean=} opt_config.readonly
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.footer_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.optionBtnSet_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.fileInfo_;

        /**
         * The container of the preview
         * @type {hf.ui.UIControl}
         * @private
         */
        this.previewCanvas_;

        /**
         * Actual file preview (audio/media player, image element)
         * @type {hf.ui.UIComponent| Element}
         * @protected
         */
        this.preview;

        /**
         * Progress bar used to indicate progress on file upload
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.progressBar_;

        /**
         * Cache desktop download start to let the progressbar visible for at least 2s if file is too small...
         * @type {Date}
         * @private
         */
        this.downloadStart_;

        /**
         * Hint on download complete/ download err for desktop app
         * @type {hf.ui.UIControl}
         * @private
         */
        this.downloadHint_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-preview-content';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['readonly'] = opt_config['readonly'] || false;
        opt_config['autoplay'] = opt_config['autoplay'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        let baseCSSClass = this.getBaseCSSClass(),
            translator = Translator;

        /* create specific file preview */
        this.preview = this.createPreviewContent();

        this.previewCanvas_ = new UIControl({
            'content'       : this.preview,
            'extraCSSClass'	: baseCSSClass + '-' + 'container'
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'name'			: MediaPreviewContent.Button_.CLOSE
        });

        this.optionBtnSet_ = new ButtonSet({
            'extraCSSClass': 'hg-file-preview-content-btnset'
        });

        if (!opt_config['readonly']) {
            this.optionBtnSet_.addButton(new Button({
                'name'			: MediaPreviewContent.Button_.DOWNLOAD,
                'extraCSSClass'	: 'hg-file-preview-content-download-btn',
                'tooltip'       : {
                    'content'       : translator.translate('download'),
                    'showArrow'     : true,
                    'placement'     : PopupPlacementMode.CENTER,
                    'verticalOffset': -46
                }
            }));
        }

        this.fileInfo_ = new Caption({
            'extraCSSClass' : 'hg-file-preview-content-file-name'
        });

        this.progressBar_ = new Bar({
            'orientation'   : Orientation.HORIZONTAL,
            'extraCSSClass' : 'hg-file-preview-content-progress-bar',
            'hidden'        : true
        });

        this.downloadHint_ = new UIControl({
            'extraCSSClass' : 'hg-file-preview-content-download-hint',
            'content'       : translator.translate('download_complete'),
            'hidden'        : true
        });
    }

    /**
     * Actual preview of file (image, media/audio player)
     * @protected
     */
    createPreviewContent() {
        return new UIControl({
            'contentFormatter': (fileData, control) => {
                if (fileData != null) {
                    const mimeType = /** @type {FileTagMeta} */(fileData)['mType'];

                    if (mimeType != null) {
                        if (mimeType == FileTypes.AUDIO) {
                            const audioPlayer = new HTML5Audio({
                                'displaySourceNameFormatter': function (sources) {
                                    if (sources != null && sources.getCount() > 0) {
                                        const filePath = sources.getAt(0).getSource(),
                                            attrs = HgMetacontentUtils.parseFilePreview(filePath);

                                        return attrs['name'];
                                    }

                                    return null;
                                },
                                'durationFormatter': function (seconds) {
                                    return HgDateUtils.formatDuration(seconds);
                                }
                            });

                            if (fileData['duration'] == null || fileData['duration'] == 0) {
                                audioPlayer.setPreload(HTML5MediaPreloadTypes.METADATA);
                            } else {
                                audioPlayer.setPreload(HTML5MediaPreloadTypes.NONE);
                            }

                            audioPlayer.setVolume(HgAppConfig.VOLUME);

                            audioPlayer.setSources([new HTML5MediaSource({
                                'source'    : fileData['downloadPath'],
                                'type'      : fileData['mime'] || fileData['ext'],
                                'duration'  : fileData['duration']
                            })]);

                            audioPlayer.setParentEventTarget(control);

                            control.addListener(UIComponentEventTypes.SHOW, (e) => { return this.handlePreviewShow_(audioPlayer, e); });

                            return audioPlayer;
                        }

                        if (mimeType == FileTypes.VIDEO) {
                            const videoPlayer = new HTML5Video({
                                'displaySourceNameFormatter': function (sources) {
                                    if (sources != null && sources.getCount() > 0) {
                                        const filePath = sources.getAt(0).getSource(),
                                            attrs = HgMetacontentUtils.parseFilePreview(filePath);

                                        return attrs['name'];
                                    }

                                    return null;
                                },
                                'durationFormatter': function (seconds) {
                                    return HgDateUtils.formatDuration(seconds);
                                }
                            });

                            if (fileData['duration'] == null || fileData['duration'] == 0) {
                                videoPlayer.setPreload(HTML5MediaPreloadTypes.METADATA);
                            } else {
                                videoPlayer.setPreload(HTML5MediaPreloadTypes.NONE);
                            }

                            videoPlayer.setVolume(HgAppConfig.VOLUME);

                            /* set poster */
                            if (fileData['posterNo'] != null && fileData['posterNo'] > 0) {
                                const posterUri = UriUtils.createURL(fileData['downloadPath']);
                                posterUri.searchParams.set('label', 'poster1');

                                videoPlayer.setPoster(posterUri.toString());
                            }

                            videoPlayer.setSources([new HTML5MediaSource({
                                'source'    : fileData['downloadPath'],
                                'type'      : fileData['mime'] || fileData['ext'],
                                'duration'  : fileData['duration']
                            })]);

                            videoPlayer.setParentEventTarget(control);

                            control.addListener(UIComponentEventTypes.SHOW, (e) => { return this.handlePreviewShow_(videoPlayer, e); });

                            return videoPlayer;
                        }
                    }
                }

                return null;
            },
            'extraCSSClass': 'hg-file-preview-player'
        });
    }

    /**
     * Adjust video player on wrapper visibility change
     */
    adjustPlayerVolume() {
        this.preview.dispatchEvent(UIComponentEventTypes.SHOW);

        /*
        var player = this.preview.getContent();
        if (player instanceof hf.ui.media.AbstractHTML5Media) {
            player.dispatchEvent(HTML5MediaEventTypes.VOLUME_CHANGE);
        }*/
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onFileChange_}, 'downloadPath');

        this.setBinding(this.fileInfo_, {'set': this.fileInfo_.setContent}, {
            'sources': [
                {'sourceProperty': 'name'},
                {'sourceProperty': 'ext'},
                {'sourceProperty': 'size'}
            ],
            'converter': {
                'sourceToTargetFn': (sources) => {
                    const fileName = sources[0],
                        fileExtension = sources[1],
                        fileSize = !StringUtils.isEmptyOrWhitespace(sources[2]) ? sources[2] : 0;
                    let displayedFileName = '';

                    if (!StringUtils.isEmptyOrWhitespace(fileName)) {
                        displayedFileName = displayedFileName + fileName;

                        if (!StringUtils.isEmptyOrWhitespace(fileExtension)) {
                            displayedFileName = displayedFileName + '.' + fileExtension;
                        }

                        if (fileSize > 0) {
                            const content = document.createDocumentFragment();

                            content.appendChild(DomUtils.createDom('span', 'hg-file-name', displayedFileName));
                            content.appendChild(DomUtils.createDom('span', 'hg-file-size', ' (' + HgStringUtils.formatFileSize(fileSize * 1000, true) + ')'));

                            displayedFileName = content;
                        }
                    }

                    return displayedFileName;
                }
            }
        });

        this.setBinding(this.preview, {'set': (filePreviewer) => {
            this.preview.setModel(filePreviewer);
            /* on media files dispatch LOAD as soon as model is received */
            setTimeout(() => this.dispatchEvent(FilePreviewEventType.LOAD), 200);
        }}, {
            'sourceProperty': ''
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        this.addChild(this.previewCanvas_, true);
        this.addChild(this.closeBtn_, true);

        const footer = new HorizontalStack({
            'extraCSSClass': 'hg-footer'
        });
        footer.addChild(this.optionBtnSet_, true);
        footer.addChild(this.fileInfo_, true);

        footer.addChild(this.progressBar_, true);
        footer.addChild(this.downloadHint_, true);

        this.addChild(footer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.optionBtnSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.preview, [BrowserEventType.ERROR, HTML5MediaEventTypes.ERROR], this.handleFileLoadError_)
            .listen(this.fileInfo_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;

        BaseUtils.dispose(this.footer_);
        this.footer_ = null;

        BaseUtils.dispose(this.optionBtnSet_);
        this.optionBtnSet_ = null;

        BaseUtils.dispose(this.fileInfo_);
        this.fileInfo_ = null;

        BaseUtils.dispose(this.previewCanvas_);
        this.previewCanvas_ = null;

        BaseUtils.dispose(this.progressBar_);
        this.progressBar_ = null;

        BaseUtils.dispose(this.downloadStart_);
        this.downloadStart_ = null;

        BaseUtils.dispose(this.downloadHint_);
        this.downloadHint_ = null;

        BaseUtils.dispose(this.preview);
        this.preview = null;
    }

    /**
     * @param {string} path
     * @private
     */
    onFileChange_(path) {
        /* make sure there are no remaining listeners registered */

        if (!StringUtils.isEmptyOrWhitespace(path)) {
            const model = this.getModel();
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            target = e.getTarget();

        let eventType;
        if (target === this.fileInfo_) {
            eventType = HgUIEventType.FILE_DOWNLOAD;
        } else {
            switch (btn.getName()) {
                case MediaPreviewContent.Button_.CLOSE:
                    eventType = FilePreviewEventType.PREVIEW_CLOSE;
                    break;

                case MediaPreviewContent.Button_.DOWNLOAD:
                    eventType = HgUIEventType.FILE_DOWNLOAD;
                    break;

                default:
                    break;
            }
        }

        if (eventType != null) {
            const event = new Event(eventType),
                file = this.getModel();

            if (eventType == HgUIEventType.FILE_DOWNLOAD && file != null) {
                event.addProperty('fileMeta', {
                    'id': file['id'],
                    'name': file['name'],
                    'uri': file['downloadPath']
                });
            }

            this.dispatchEvent(event);
        }

        e.stopPropagation();

        return false;
    }

    /**
     * Handles action on previous button
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handlePreviousPreview_(opt_e) {
        this.dispatchEvent(new Event(FilePreviewEventType.PREVIEW_PREV));
    }

    /**
     * Handles action on next button
     * @param {hf.events.Event=} opt_e
     * @private
     */
    handleNextPreview_(opt_e) {
        this.dispatchEvent(new Event(FilePreviewEventType.PREVIEW_NEXT));
    }

    /** @inheritDoc */
    handleMouseUp(e) {
        super.handleMouseUp(e);

        const target = e.getTarget(),
            element = this.getElement();

        if (target == element || target == this.previewCanvas_.getElement()) {
            const bounds = new Rect(element.getBoundingClientRect().x, element.getBoundingClientRect().y, element.offsetWidth, element.offsetHeight),
                mousePosition = UIUtils.getMousePosition(e);

            if (mousePosition.y > bounds.top && mousePosition.y < bounds.top + bounds.height) {
                if (mousePosition.x > bounds.left
                    && mousePosition.x < bounds.left + bounds.width/2) {

                    this.handlePreviousPreview_();
                }

                if (mousePosition.x > bounds.left + bounds.width/2
                    && mousePosition.x < bounds.left + bounds.width) {

                    this.handleNextPreview_();
                }
            }
        }
    }

    /**
     * Download start event on desktopapp
     * @private
     */
    onDownloadStarted_() {
        this.progressBar_.setVisible(true);

        const downloadBtn = this.optionBtnSet_.getButtonByName(MediaPreviewContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(false);
    }

    /**
     * Download start event on desktopapp
     * @param {number} bytes
     * @private
     */
    onDownloadProgress_(bytes) {
        const model = this.getModel();
        let progress = 20;
        if (model != null && model['size'] > 0) {
            progress = (100*bytes)/model['size'];
        }

        this.progressBar_.setValue(progress);
    }

    /**
     * Download end event on desktop-app
     * Provide feedback on
     * @private
     */
    onDownloadError_() {
        this.progressBar_.setVisible(false);

        const translator = Translator;

        this.downloadHint_.setContent(translator.translate('file_not_available'));
        this.downloadHint_.addExtraCSSClass('hg-button-download-err');
        this.downloadHint_.setVisible(true);

        const downloadBtn = this.optionBtnSet_.getButtonByName(MediaPreviewContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(false);

        setTimeout(() => this.onDownloadFeedbackEnded_(), 5*1000);
    }

    /**
     * Download end event on desktopapp
     * Provide feedback on
     * @private
     */
    onDownloadEnded_() {
        this.progressBar_.setVisible(false);

        const translator = Translator;

        this.downloadHint_.setContent(translator.translate('download_complete'));
        this.downloadHint_.removeExtraCSSClass('hg-button-download-err');
        this.downloadHint_.setVisible(true);

        setTimeout(() => this.onDownloadFeedbackEnded_(), 5*1000);
    }

    /**
     * Download feedback end (5s delay with hint) event on desktop-app
     * @private
     */
    onDownloadFeedbackEnded_() {
        this.downloadHint_.setVisible(false);

        /* restore download button */
        const downloadBtn = this.optionBtnSet_.getButtonByName(MediaPreviewContent.Button_.DOWNLOAD);
        downloadBtn.setEnabled(true);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleFileLoadError_(e) {
        this.dispatchEvent(FilePreviewEventType.ERROR);
    }

    /**
     * Handle display of preview control
     * @param {hf.ui.media.AbstractHTML5Media} player
     * @param {hf.events.Event} e
     * @private
     */
    handlePreviewShow_(player, e) {
        const target = e.getTarget();

        if (target == this.preview
            && target.isVisible()
            && player instanceof AbstractHTML5Media) {

            player.getVolumeSlider().setValue(player.getVolume() * 100);

            const cfg = this.getConfigOptions();
            if (!!cfg['autoplay']) {
                player.play();
            }
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
MediaPreviewContent.Button_ = {
    CLOSE       : 'close',
    DOWNLOAD    : 'download'
};