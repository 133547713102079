import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgAuthorUtils} from "./../author/Common.js";
import {Author} from "./../author/Author.js";
import {ResourceLink} from "./../resource/ResourceLink.js";
import {LikeActions} from "./Enums.js";
import {HgResourceUtils} from "./../resource/Common.js";

/**
 * Create new {@code hg.data.model.like.Like} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Like extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        /* Who liked */
        this.addField({'name': 'author', 'type': Author, 'isPersistable': false,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* The resource that was 'liked;. */
        this.addField({'name': 'liked', 'type': ResourceLink,
            'parser': HgResourceUtils.getResourceLink
        });

        /* The like action: LikeActions */
        this.addField({'name': 'action', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'intensity', 'type': DataModelField.PredefinedTypes.NUMBER});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['action'] = rawData['action'] || LikeActions.LIKE;
    }
};