import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ErrorHandler} from "./../../../common/ui/ErrorHandler.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {Editor} from "./../../../common/ui/message/Editor.js";
import {TeamTopicToolbarPresenterBusyContext} from "./../presenter/Toolbar.js";
import {UploadFileButtonEventType} from "./../../../common/ui/button/UploadFileButton.js";
import {EmoticonButton, EmoticonButtonEventType} from "./../../../common/ui/button/EmoticonButton.js";
import {GiphyButton, GiphyButtonEventType} from "./../../../common/ui/button/GiphyButton.js";
import {MessageEditorParts} from "./../../../common/ui/editor/Enums.js";
import {HgFileEditorPlugin} from "./../../../common/ui/editor/plugin/File.js";
import {TextEditorEventType} from "./../../../common/ui/editor/TextEditor.js";
import {GiphyBubbleMode} from "./../../../common/ui/GiphyBubble.js";
import {PopupButtonEventType} from "./../../../common/ui/button/PopupButton.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@code hg.board.ui.InlineMessagePanel} component.
 *
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class InlineMessagePanel extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.supportsOpenState
     *   @param {boolean=} opt_config.editMode  If true, this component's editor will have 'directFileRemoval' equal to false {@see hg.common.ui.message.Editor}.
     *      This means that when 'editMode' is true, files will NOT be deleted when this component is disposed. By default they are.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Message editor
         * @type {hg.common.ui.message.Editor}
         * @protected
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;

        /**
         *
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.editorButtonsSet_ = this.editorButtonsSet_ === undefined ? null : this.editorButtonsSet_;

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.postMessageButton_ = this.postMessageButton_ === undefined ? null : this.postMessageButton_;

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.clearButton_ = this.clearButton_ === undefined ? null : this.clearButton_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.submitPromisedResult_ = this.submitPromisedResult_ === undefined ? null : this.submitPromisedResult_;
    }

    /**
     * Number of pixels remaining free for the editor to fill
     * @return {number}
     */
    getEditorFreeSpace() {
        const viewportSize = DomUtils.getViewportSize();

        return 0.8 * viewportSize.height - this.occupiedFixedHeight_ + /* tolerance */ 20;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['supportsOpenState'] = opt_config['supportsOpenState'] != null ? opt_config['supportsOpenState'] : true;
        opt_config['editMode'] = opt_config['editMode'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        // activate the OPENED state if supportsExpandCollapse == true.
        this.setSupportedState(UIComponentStates.OPENED, opt_config['supportsOpenState']);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, opt_config['supportsOpenState']);

        let translator = Translator;

        this.editor_ = new Editor({
            'extraCSSClass'     : [InlineMessagePanel.CssClasses.MESSAGE_EDITOR],
            'placeholder'       : opt_config['placeholder'] || translator.translate('leave_reply'),
            'directFileRemoval' : !opt_config['editMode']
        });

        this.editorButtonsSet_ = new ButtonSet({
            'extraCSSClass': [MessageEditorParts.BUTTONS_SET]
        });

        this.editorButtonsSet_.addButton(new EmoticonButton({
            'name': InlineMessagePanel.Button_.EMOTICON,
            'tooltip': {
                'content': translator.translate('choose_emoticon'),
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1
            }
        }));

        this.editorButtonsSet_.addButton(new GiphyButton({
            'name': InlineMessagePanel.Button_.GIPHY,
            'tooltip': {
                'content': translator.translate('choose_emoticon'),
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1
            }
        }));

        this.editorButtonsSet_.addButton(HgButtonUtils.createUploadFileButton({
            'name': InlineMessagePanel.Button_.UPLOAD
        }));

        this.postMessageButton_ = HgButtonUtils.createPostMessageButton({
            'disabled': true
        });

        this.clearButton_ = HgButtonUtils.createClearTextButton({
            'hidden': true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.editor_ = null;
        this.editorButtonsSet_ = null;
        this.postMessageButton_ = null;
        this.clearButton_ = null;

        BaseUtils.dispose(this.submitPromisedResult_);
        this.submitPromisedResult_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return InlineMessagePanel.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return InlineMessagePanel.CssClasses.BASE;
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        contentContainer.addExtraCSSClass(InlineMessagePanel.CssClasses.CONTENT);

        contentContainer.addChild(this.editor_, true);
        contentContainer.addChild(this.editorButtonsSet_, true);
        contentContainer.addChild(this.postMessageButton_, true);
        contentContainer.addChild(this.clearButton_, true);
    }

    /** @inheritDoc */
    createFooter() {
        return null;
    }

    /** @inheritDoc */
    createButtonSet() {
        return null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.setOpen(false);

        this.updateStateStyling(UIComponentStates.OPENED, false);

        /* compute space occupied by editor's siblings */
        this.occupiedFixedHeight_ = this.getHeight(true) - this.editor_.getHeight(true);

        this.getHandler()
            .listen(this.postMessageButton_, UIComponentEventTypes.ACTION, this.handleSubmitAction_)
            .listen(this.clearButton_, UIComponentEventTypes.ACTION, this.handleDismissAction_)

            .listen(this.editorButtonsSet_, UploadFileButtonEventType.FILE_UPLOAD, this.handleFileUpload_)
            .listen(this.editorButtonsSet_, EmoticonButtonEventType.SHOW_EMOTICONS, this.handleShowEmoticons_)
            .listen(this.editorButtonsSet_, GiphyButtonEventType.SHOW_GIFS, this.handleShowGifs_)
            .listen(this.editor_, TextEditorEventType.SHOW_MAX_LENGTH_EXCEEDED_WARNING, this.handleShowMaxLengthExceededWarning_)
            .listen(this, PopupButtonEventType.OPEN_PANEL, this.handleOpenAttachmentsPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setOpen(false);

        if(this.submitPromisedResult_) {
            this.submitPromisedResult_ = null;
        }

        if(this.postMessageButton_) {
            this.postMessageButton_.setBusy(false);
        }

        super.exitDocument();
    }

    /**
     * @param {boolean} open Whether to open or close the popup
     * @override
     */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        if(open) {
            this.onOpening_();
        }
        else {
            this.onClosing_();
        }

        super.setOpen(open);
    }

    /** @inheritDoc */
    performActionInternal(e) {
        /* do not perform internal action if there is a text selection */
        if(this.hasSelectedText()) {
            return true;
        }

        const actionEvent = new Event(UIComponentEventTypes.ACTION, this);
        if (e) {
            actionEvent.altKey = e.altKey;
            actionEvent.ctrlKey = e.ctrlKey;
            actionEvent.metaKey = e.metaKey;
            actionEvent.shiftKey = e.shiftKey;
            actionEvent.platformModifierKey = e.platformModifierKey;
        }
        return this.dispatchEvent(actionEvent);
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        if(this.editor_) {
            this.editor_.focus();
        }
    }

    /** @inheritDoc */
    isFocused() {
        if (!this.isInDocument()) {
            return super.isFocused();
        }

        return this.editor_ && this.editor_.isFocused();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this, {'get': this.isOpen}, {
            'sourceProperty': 'isOpen',
            'mode'          : DataBindingMode.ONE_WAY_TO_SOURCE,
            'updateSourceTrigger': [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
        });

        this.setBinding(this.editor_, {'set': this.editor_.setModel}, '');

        this.setBinding(this.editor_, {'set': this.editor_.setContent}, {
            'sourceProperty': 'body',
            'mode': DataBindingMode.ONE_TIME
        });

        this.setBinding(this.postMessageButton_, {'set': this.postMessageButton_.setEnabled},
            {
                'sources'      : [
                    {'sourceProperty': ''},
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.hasContent},
                        'updateTargetTrigger': EditorFieldEventType.DELAYEDCHANGE
                    },
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.isBusy},
                        'updateTargetTrigger': EditorPluginEventType.BUSY_CHANGE
                    },
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.hasErrors},
                        'updateTargetTrigger': EditorPluginEventType.ERROR_CHANGE
                    }
                ],
                'converter'             : {
                    'sourceToTargetFn' : function (values) {
                        const message = values[0],
                            editorHasContent = values[1];
                        let isUploadingFiles = values[2],
                            hasErrosAtUploadingFiles = values[3];

                        return message != null && (message.isSavable() || editorHasContent) && !isUploadingFiles && !hasErrosAtUploadingFiles;
                    }
                }
            }
        );

        this.setBinding(this.clearButton_, {'set': this.clearButton_.setVisible},
            {
                'sources': [
                    {'sourceProperty': ''},
                    {
                        'source': this.editor_,
                        'sourceProperty': {'get': this.editor_.hasContent},
                        'updateTargetTrigger': EditorFieldEventType.DELAYEDCHANGE
                    }
                ],
                'converter'             : {
                    'sourceToTargetFn' : function (values) {
                        const message = values[0],
                            editorHasContent = values[1];

                        return message != null && (!message.isNew() || editorHasContent);
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(opt_busyContext == TeamTopicToolbarPresenterBusyContext.UPDATE_MESSAGE) {
            // mark the submit button
            const submitBtn = this.postMessageButton_;

            submitBtn.setBusy(enable);
        }
    }

    /**
     * @inheritDoc
     */
    enableHasErrorBehavior(enable, contextError) {
        if(enable) {
            this.getErrorHandler().setError(contextError, false);
        }
        else {
            this.getErrorHandler().clearError();
        }
    }

    /**
     * @inheritDoc
     */
    getErrorHandler() {
        return this.errorHandler ||
            (this.errorHandler = new ErrorHandler(
                    {
                        'target': this,
                        'errorsHost': this.getErrorContainerHost(),
                        'errorBuilder': this.createErrorContainer.bind(this),
                        'autoClose'  : false
                    })
            );
    }

    /**
     *
     * @private
     */
    onOpening_() {
        if(this.editor_) {
            this.editor_.focus();
            //this.editor_.setHeight('')
        }
    }

    /**
     *
     * @private
     */
    onClosing_() {
        if(this.editor_) {
            this.editor_.clearContent(true);
            this.editor_.blur();
        }
    }

    /**
     * @inheritDoc
     */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.handleSubmitAction_();
        }
        else {
            this.handleDismissAction_();
        }

        return true;
    }

    /**
     * @private
     */
    handleSubmitAction_() {
        const model = this.getModel(),
            message = /** @type {string} */(this.editor_.getContent());

        if (model && !StringUtils.isEmptyOrWhitespace(message) && this.postMessageButton_.isEnabled() && !this.postMessageButton_.isBusy()) {
            /* update message body on submit only to avoid running through all editor plugins on each DELAYEDCHANGE event */
            model.set('body', message, false);

            this.postMessageButton_.focus();

            const submitEvent = new Event(CommitChangesActionTypes.SUBMIT);
                submitEvent.addProperty('message', model);

            this.postMessageButton_.setBusy(true);

            this.dispatchEvent(submitEvent);

            const promisedResult = submitEvent.getProperty('promisedResult');
            if(promisedResult instanceof Promise) {
                this.submitPromisedResult_ =
                    promisedResult
                        .then((result) => {
                            if (this.editor_) {
                                /* clears the content and accepts the changes: e.g. the removed files will be actually deleted from remote data source */
                                this.editor_.clearContent();
                            }

                            this.setOpen(false);
                        })
                        .finally(() => {
                            if (this.postMessageButton_) {
                                this.postMessageButton_.setBusy(false);

                                this.submitPromisedResult_ = null;
                            }
                        });
            }
            else {
                this.postMessageButton_.setBusy(false);

                if(this.editor_) {
                    /* clears the content and accepts the changes: e.g. the removed files will be actually deleted from remote data source */
                    this.editor_.clearContent();
                }

                this.setOpen(false);
            }
        }
    }

    /**
     * @private
     */
    handleDismissAction_() {
        if(this.editor_) {
            /* clears the content and discard the changes: e.g. the removed files will be added back */
            this.editor_.clearContent(true);
        }

        const model = this.getModel();
        if(model) {
            const dismissEvent = new Event(CommitChangesActionTypes.DISMISS);
            dismissEvent.addProperty('message', model);

            this.dispatchEvent(dismissEvent);
        }

        this.setOpen(false);
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFileUpload_(e) {
        const files = e.getProperty('files');
        if(files) {
            this.editor_.uploadFiles(/**@type {FileList}*/(files));
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowEmoticons_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this,
            'eventTarget'           : this.editor_,
            'mode'                  : GiphyBubbleMode.DEFAULT
        });

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowGifs_(e) {
        const element = this.getElement();
        let parentElement = null;
        if (element.parentNode && element.parentNode.nodeType == Node.ELEMENT_NODE) {
            parentElement = element.parentNode;
        }
        e.addProperty('payload', {
            'renderParent'          : parentElement,
            'placementTarget'       : this,
            'eventTarget'           : this.editor_,
            'mode'                  : GiphyBubbleMode.DEFAULT
        });

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleShowMaxLengthExceededWarning_(e) {
        if (this.postMessageButton_ != null) {
            e.addProperty('placementTarget', this.postMessageButton_);
        }
        
        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenAttachmentsPanel_(e) {
        const target = e.getTarget();

        if (target instanceof Button
            && target.getName() == HgFileEditorPlugin.Buttons.UPLOADED) {
            e.addProperty('placementTarget', this);
            e.addProperty('placement', PopupPlacementMode.TOP);

            e.stopPropagation();
        }
    }
};
/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 */
InlineMessagePanel.CSS_CLASS_PREFIX = 'hg-team-board-inline-message-panel';

/**
 * Set of toolbar button names
 * @enum {string}
 * @private
 */
InlineMessagePanel.Button_ = {
    EMOTICON        : 'Emoticon',
    GIPHY           : 'Giphy',
    UPLOAD          : 'Upload'
};

/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 * @private
 */
InlineMessagePanel.CssClasses = {
    BASE                : InlineMessagePanel.CSS_CLASS_PREFIX,

    ERROR_CONTAINER     :  InlineMessagePanel.CSS_CLASS_PREFIX + '-' + 'error-container',

    CONTENT             : InlineMessagePanel.CSS_CLASS_PREFIX + '-' + 'content',

    MESSAGE_EDITOR      : InlineMessagePanel.CSS_CLASS_PREFIX + '-' + 'message-editor',

    MESSAGE_LENGTH_EXCEEDED : InlineMessagePanel.CSS_CLASS_PREFIX + '-' + 'message-length-exceeded'
};