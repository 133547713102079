import {EventsUtils} from "./../../../../hubfront/phpnoenc/js/events/Events.js";
import {ServiceLocator} from "./../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {DomUtils} from "./../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../hubfront/phpnoenc/js/base.js";
import {BrowserEventType} from "./../../../../hubfront/phpnoenc/js/events/EventType.js";
import {LayoutContainer} from "./../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {UIComponent} from "./../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ToggleButton} from "./../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {ElementResizeHandler} from "./../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {StyleUtils} from "./../../../../hubfront/phpnoenc/js/style/Style.js";
import {UIUtils} from "./../../../../hubfront/phpnoenc/js/ui/Common.js";
import {Popup, PopupPlacementMode} from "./../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {UIComponentEventTypes} from "./../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {LayoutDisplayRegions} from "./LayoutDisplayRegions.js";
import {DialogStates, FSDialogStates, UACStates} from "./../app/States.js";
import {OverlayLeft} from "./fx/OverlayLeft.js";
import {CollapseLeft} from "./fx/CollapseLeft.js";
import {AbstractResizableContent} from "./AbstractResizableContent.js";
import {HgHotKeyTypes} from "./../common/Hotkey.js";
import {HgAppEvents} from "./../app/Events.js";

import {AppDataCategory, AppDataGlobalKey} from "./../data/model/appdata/Enums.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AppDataService from "./../data/service/AppDataService.js";
import Translator from "../../../../hubfront/phpnoenc/js/translator/Translator.js";
import { PLT_UID } from "../app/PlatformUID.js";

const expandEvents = [
    HgAppEvents.SHOW_LIKED_RESOURCE,
    HgAppEvents.SHOW_REPLY_MESSAGE,
    HgAppEvents.LAYOUT_LEFT_SIDE_EXPAND
];

const collapseEvents = [
    HgAppEvents.CALL_PERSON,
    HgAppEvents.INVITE_TO_PAGE
];

/**
 * Creates a new Layout object.
 * @extends {AbstractResizableContent}
 * @unrestricted 
*/
export class General extends AbstractResizableContent {
    /**
     * @param {hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * Header app container: holds header and notify regions
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.headerContainer_;

        /**
         * Main app content container: holds left-side, right-side and footer zones
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.contentContainer_;

        /**
         * Left side container holding regions such as: toolbar, facet, breadcrumb, list
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.leftSideContainer_;

        /**
         * Right side container holding social regions: phone, chat
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.rightSideContainer_;

        /**
         * Storage of right side container width in resize events
         * @type {number}
         * @private
         */
        this.currentRightContainerWidth_;

        /**
         * Storage of left side container with in resize events
         * @type {number}
         * @private
         */
        this.currentLeftContainerWidth_;

        /**
         * Left-side container expand/collapse button
         * @type {hf.ui.Button}
         * @private
         */
        this.expandBtn_ = this.expandBtn_ === undefined ? null : this.expandBtn_;

        /**
         * Flag for right-side container state: collapsed or expanded
         * @type {boolean}
         * @private
         */
        this.leftSideContainerCollapsed_ = this.leftSideContainerCollapsed_ === undefined ? false : this.leftSideContainerCollapsed_;

        /**
         * Flag for left-side container state: overlaid right-side or not
         * @type {boolean}
         * @private
         */
        this.leftSideContainerOverlaid_ = this.leftSideContainerOverlaid_ === undefined ? false : this.leftSideContainerOverlaid_;

        /**
         * Collapse/expand animation
         * @type {hg.ui.fx.BaseLayoutTransition}
         * @private
         */
        this.collapseAnimation_ = this.collapseAnimation_ === undefined ? null : this.collapseAnimation_;

        /**
         * Overlay animation
         * @type {hg.ui.fx.BaseLayoutTransition}
         * @private
         */
        this.overlayAnimation_ = this.overlayAnimation_ === undefined ? null : this.overlayAnimation_;

        /**
         * The object handling the resize events of the header container to adjust content top.
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.headerResizeHandler_ = this.headerResizeHandler_ === undefined ? null : this.headerResizeHandler_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.dialogRegionExpandsOverFullContent_ = this.dialogRegionExpandsOverFullContent_ === undefined ? false : this.dialogRegionExpandsOverFullContent_;

        /**
         *  
         * @type {boolean}
         * @private
         */
        this.isContentContainerCovered = false;
    }

    /** @inheritDoc */
    getName() {
        return 'hg-layout-general';
    }

    /** @inheritDoc */
    create() {
        super.create();

        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            notifyRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.NOTIFY)),
            headerRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.HEADER));

        this.contentContainer_ = new LayoutContainer({
            'extraCSSClass': 'hg-layout-general-main-content'
        });

        const leftSideContainer = this.createLeftSideContent_();
        this.contentContainer_.addChild(leftSideContainer, true);

        const rightSideContainer = this.createRightSideContent_();
        this.contentContainer_.addChild(rightSideContainer, true);

        this.collapseAnimation_ = new CollapseLeft({'container': this.contentContainer_});

        this.outerContentContainer.addChild(this.contentContainer_, true);

        if (!PLT_UID) {
            /* using a wrapper to add restore viewport size (content is not available when the size is restores, only empty regions) */
            this.headerContainer_ = new LayoutContainer({
                'extraCSSClass': 'hg-layout-general-header'
            });
            this.headerContainer_.addChild(notifyRegion, true);
            this.headerContainer_.addChild(headerRegion, true);

            this.add(this.headerContainer_);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.originalWindowHeight_ = window.outerHeight;

        const eventBus = this.getEventBus();
        this.getHandler()
            .listen(this.expandBtn_, UIComponentEventTypes.ACTION, this.handleExpandBtnAction_)
            .listen(eventBus, collapseEvents, this.handleLeftSideCollapse_)
            .listen(eventBus, expandEvents, this.handleLeftSideExpand_)
            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_)
            .listen(eventBus, HgAppEvents.LAYOUT_DIALOG_EXPAND, this.handleDialogRegionExpandCollapse_);

        if(userAgent.platform.isAndroid()){
            this.getHandler()
                .listen(window, BrowserEventType.RESIZE, this.handleTabletAndroidResize_);
        }

        if(this.headerContainer_) {
            this.headerResizeHandler_ = new ElementResizeHandler(this.headerContainer_.getElement());
            this.headerResizeHandler_.enable(true);

            this.getHandler()
                .listen(this.headerResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handleHeaderResize_)
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        BaseUtils.dispose(this.headerResizeHandler_);
        delete this.headerResizeHandler_;
    }

    /** @inheritDoc */
    update(state) {
        super.update(state);

        this.checkIsContentContainerCovered_();
    }

    /** @inheritDoc */
    async restoreRegionsWidth() {
        const appDataService = AppDataService;
        if (appDataService) {
            super.restoreRegionsWidth();

            /* socialZoneParam */
            const socialZoneParam = await appDataService.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.SOCIAL_ZONE_WIDTH);

            if (socialZoneParam && socialZoneParam['value'] != null) {
                this.currentRightContainerWidth_ = parseFloat(socialZoneParam['value']);
                this.rightSideContainer_.setWidth(socialZoneParam['value'], false, false);
            }


            this.adjustLeftSideContainerState_();
        }
    };

    /** @inheritDoc */
    onViewportResize() {
        /* resize both the content region and the footer region */
        this.outerContentContainer.onResize();

        this.adjustLeftSideContainerState_();

        super.onViewportResize();
    }

    /** @inheritDoc */
    updateDialogRegion() {
        const currentState = this.getState(),
            stateName = currentState.getName();

        /* do nothing if the state is a UAC state or a FS state */
        if(UACStates.includes(stateName) || (FSDialogStates.includes(stateName) && !currentState.hasNavigatedBackwards())) {
            return;
        }

        const dialogRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.DIALOG),
            dialogState = DialogStates.find(function (item) {
                return item.name == stateName;
            });

        if (dialogState == null) {
            if (dialogRegion.isInDocument()) {
                /* remove dialog from wrapper container (left-side, content) */
                const container = dialogRegion.getParent();
                if (container.indexOfChild(dialogRegion) > -1) {
                    container.removeChild(dialogRegion, true);
                }

                this.dialogRegionZIndex_ = null;
            }

            this.dialogRegionExpandsOverFullContent_ = false;

            this.updateMiniThreadsRegion_(false);

            return;
        }

        let previousContainer = dialogRegion.getParent(),
            container;

        if (dialogState.fullContent || this.dialogRegionExpandsOverFullContent_) {
            container = this.outerContentContainer;
        }
        else {
            /*var leftSideWidth = this.leftSideContainer_.getWidth(true), leftSideWidth > 500*/
            /* do not allow dialog on left side if the left side container has width under 537 px */
            if(this.leftSideContainer_.getWidth(true) < General.LEFT_SIDE_MIN_WIDTH || this.leftSideContainerCollapsed_ == true) {
                container = this.outerContentContainer;
            }
            else {
                container = this.leftSideContainer_;
            }
        }

        dialogRegion.beginMoveTransition();

        /* set the zIndex if it's not set (i.e. the dialog is opening now) */
        if(this.dialogRegionZIndex_ == null) {
            this.dialogRegionZIndex_ = Popup.POPUP_Z_INDEX++;
        }

        if (previousContainer && previousContainer != container) {
            previousContainer.removeChild(dialogRegion, false);
        }

        //container.addChild(dialogRegion, !dialogRegion.isInDocument());
        if(container.indexOfChild(dialogRegion) == -1) {
            container.addChild(dialogRegion, !dialogRegion.isInDocument());
            dialogRegion.enterDocument();
        }

        /* update the dialog's zIndex */
        dialogRegion.setStyle('zIndex', this.dialogRegionZIndex_);

        /*
        If the display region is a dialog and it is displayed in the left side container (which means it's not full content),
        the mini threads region should still be visible.
        */
        this.updateMiniThreadsRegion_(container == this.leftSideContainer_);

        dialogRegion.endMoveTransition();
    }

    /**
     * Dispatch cover state change because we need to make sure latest updated is incremented also when in cover state
     * thread should be considered unseen in this case
     *
     * @private
     */
    checkIsContentContainerCovered_() {
        let isContentContainerCoveredOld = this.isContentContainerCovered;
        const currentState = this.getState(),
            stateName = currentState.getName();
        const dialogState = DialogStates.find(function (item) {
            return item.name == stateName;
        })

        this.isContentContainerCovered = (this.uacRegionZIndex_ != null && this.uacRegionZIndex_ > 0)
            || (this.fsdialogRegionZIndex__ != null)
            || (this.dialogRegionZIndex_ != null &&
                ((dialogState && dialogState.fullContent) || this.dialogRegionExpandsOverFullContent_));


        if(this.isContentContainerCovered && !isContentContainerCoveredOld) {
            this.dispatchAppEvent(HgAppEvents.APP_COVER_STATE_ON);
        }
        else if(isContentContainerCoveredOld && !this.isContentContainerCovered){
            this.dispatchAppEvent(HgAppEvents.APP_COVER_STATE_OFF);
        }
    }

    /**
     * @param {boolean} displayOverDialog
     * @private
     */
    updateMiniThreadsRegion_(displayOverDialog) {
        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            miniThreadsRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_MINI_THREADS));

        if (!displayOverDialog) {
            /* the default zIndex for the mini threads region */
            miniThreadsRegion.setStyle('zIndex', 1);
        } else {
            miniThreadsRegion.setStyle('zIndex', this.dialogRegionZIndex_ + 1);
        }
    }

    /**
     * Created the work zone: the left side of the content zone
     * @return {hf.ui.UIComponent}
     * @private
     */
    createLeftSideContent_() {
        const translator = Translator,
            regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            contentToolbarRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.TOOLBAR)),            //contentBreadcrumbRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(hg.layout.LayoutDisplayRegions.BREADCRUMB)),
            contentFacetRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.FACET)),
            contentRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CONTENT)),
            miniThreadsRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_MINI_THREADS));

        this.expandBtn_ = new ToggleButton({
            'extraCSSClass': 'hg-button-toolbar-expand',
            'content'      : DomUtils.getOuterHtml(DomUtils.createDom('div', 'hg-button-toolbar-expand-line')) + DomUtils.getOuterHtml(DomUtils.createDom('div', 'hg-button-toolbar-expand-line')),
            'tooltip': {
                'autoHide': true,
                'showArrow': true,
                'extraCSSClass': ['hg-tooltip', 'hf-button-tooltip', 'grayscheme'],
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -10,
                'contentFormatter': function(state){
                    return state != null && state['isExpanded'] ? translator.translate('expand_panel') : translator.translate('minimize_panel');
                }
            }
        });

        this.expandBtn_.setBinding (
            this.expandBtn_, {
                'set': this.expandBtn_.setModel
            }, {
                'source': this.expandBtn_,
                'sourceProperty': {
                    'get': this.expandBtn_.isChecked
                },
                'updateTargetTrigger': [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK],
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return {'isExpanded': !!value}
                    }
                }
            }
        );

        this.leftSideContainer_ = new LayoutContainer({'extraCSSClass': 'hg-layout-general-leftside', 'id': 'left-side'});
        this.leftSideContainer_.addChild(this.expandBtn_, true);
        this.leftSideContainer_.addChild(contentFacetRegion, true);

        const contentContainer = new VerticalStack({'extraCSSClass': 'hg-layout-content-container'});
        contentContainer.addChild(contentToolbarRegion, true);
        contentContainer.addChild(contentRegion, true);

        this.leftSideContainer_.addChild(contentContainer, true);
        this.leftSideContainer_.addChild(miniThreadsRegion, true);

        return this.leftSideContainer_;
    }

    /**
     * Created the social zone: the right side of the content zone including the phone and the chat
     * @return {hf.ui.UIComponent}
     * @private
     */
    createRightSideContent_() {
        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            phoneRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.PHONE)),
            chatToolbarRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_TOOLBAR)),
            chatRosterRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_ROSTER)),
            chatConversationRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.CHAT_THREAD)),
            viewPort = DomUtils.getViewportSize().width;

        this.rightSideContainer_ = new LayoutContainer({
            'extraCSSClass': 'hg-layout-general-rightside',
            'id'           : 'right-side'
        });

        if (viewPort > 670 && viewPort < 1200) {
            this.rightSideContainer_.setWidth(General.RIGHT_SIDE_MIN_WIDTH, false, true);
        } else if (viewPort > 1200 && viewPort < 1400) {
            this.rightSideContainer_.setWidth(650, false, true);
        } else if (viewPort > 1400 && viewPort < 1800) {
            this.rightSideContainer_.setWidth(850, false, true);
        } else if (viewPort > 1800 && viewPort < 2400) {
            this.rightSideContainer_.setWidth(1050, false, true);
        } else if (viewPort > 2400) {
            this.rightSideContainer_.setWidth(1350, false, true);
        }

        const chatContainer = new LayoutContainer({'extraCSSClass': 'hg-layout-chat-container'});
        chatContainer.addChild(chatToolbarRegion, true);
        chatContainer.addChild(chatRosterRegion, true);
        chatContainer.addChild(chatConversationRegion, true);

        this.resizeHandles[General.Resizer_.INNER] = new UIComponent({
            'renderTpl' : '<div class="hf-fx-resizer hf-fx-resizer-wrapper-left"><div class="hf-fx-resizer-visible-handle hf-fx-resizer-left"></div></div>'
        });

        this.rightSideContainer_.addChild(phoneRegion, true);
        this.rightSideContainer_.addChild(chatContainer, true);
        this.rightSideContainer_.addChild(this.resizeHandles[General.Resizer_.INNER], true);

        return this.rightSideContainer_;
    }

    /**
     * Adjust the position of the left-side container
     * @private
     */
    adjustLeftSideContainerState_() {
        if (!this.leftSideContainerFitsResolution_() && !this.isLeftSideContainerOverlaid_()) {
            this.setLeftSideContainerVisible(false);
        } else {
            if (this.isLeftSideContainerOverlaid_()) {
                this.setLeftSideContainerVisible(true);
            }

            /* if left side was collapsed automatically, expand if possible */
            if (this.automaticResize_ && this.isLeftSideContainerCollapsed_()) {
                this.setLeftSideContainerVisible(true);
            }
        }
    }

    /**
     * Returns true if left-side container is visible
     * @return {boolean} True if left-side container is visible, false otherwise
     * @protected
     */
    isLeftSideContainerVisible() {
        return !this.isLeftSideContainerCollapsed_() || this.isLeftSideContainerOverlaid_();
    }

    /**
     * Returns true if left-side container overlays the right-side one (both do nto fit on the current resolution)
     * @return {boolean}
     * @private
     */
    isLeftSideContainerOverlaid_() {
        return this.leftSideContainerOverlaid_;
    }

    /**
     * Returns true if the right-side container is collapsed
     * @return {boolean} True for collapsed, false for expanded
     * @private
     */
    isLeftSideContainerCollapsed_() {
        return this.leftSideContainerCollapsed_;
    }

    /**
     * Returns true if the left-side container is fits the current resolution
     * @param {boolean=} opt_force Check if left side container fits resolution independent of current right side container size (using default min-size for it)
     * @return {boolean} True for collapsed, false for expanded
     * @private
     */
    leftSideContainerFitsResolution_(opt_force) {
        const tolerance = 10;

        if (this.currentOuterContainerWidth == null) {
            this.currentOuterContainerWidth = this.outerContentContainer.getSize(true).width;
        }

        if (opt_force && this.currentOuterContainerWidth > (General.LEFT_SIDE_MIN_WIDTH + General.RIGHT_SIDE_MIN_WIDTH + tolerance)) {
            return true;
        }

        if (this.currentRightContainerWidth_ == null) {
            this.currentRightContainerWidth_ = this.rightSideContainer_.getSize(true).width;
        }

        return this.currentOuterContainerWidth - this.currentRightContainerWidth_ >= General.LEFT_SIDE_MIN_WIDTH;
    }

    /**
     * Collapse or expand the right-side container
     * @param {boolean} collapsed True for collapsed, false for expanded
     * @protected
     */
    setLeftSideContainerCollapsed(collapsed) {
        if (this.leftSideContainerCollapsed_ === collapsed) {
            return;
        }

        this.leftSideContainerCollapsed_ = collapsed;

        collapsed ? this.collapseAnimation_.play() : this.collapseAnimation_.playReverse();

        this.automaticResize_ = collapsed && this.isInWindowResizeTransition() ? true : false;

        General.LEFT_SIDE_VISIBLE = !collapsed;

        this.dispatchAppEvent(collapsed ? HgAppEvents.LAYOUT_LEFT_SIDE_HIDE : HgAppEvents.LAYOUT_LEFT_SIDE_SHOW, {});
    }

    /**
     * Toggle overlay on the left-side container
     * @param {boolean} overlay True for collapsed, false for expanded
     * @protected
     */
    setLeftSideContainerOverlaid(overlay) {
        if (this.leftSideContainerOverlaid_ === overlay) {
            return;
        }

        const rightSideContainerElem = this.rightSideContainer_.getElement();
        if (rightSideContainerElem) {
            const isDesktop = userAgent.device.isDesktop();
            if (!overlay) {
                EventsUtils.unlisten(rightSideContainerElem, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleLeftSideCollapse_, false, this);
            } else {
                EventsUtils.listen(rightSideContainerElem, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleLeftSideCollapse_, false, this);
            }
        }

        this.leftSideContainerOverlaid_ = overlay;

        if (this.overlayAnimation_ == null) {
            this.overlayAnimation_ = new OverlayLeft({'container': this.contentContainer_});
        }

        overlay ? this.overlayAnimation_.play() : this.overlayAnimation_.playReverse();

        General.LEFT_SIDE_VISIBLE = overlay;

        this.dispatchAppEvent(overlay ? HgAppEvents.LAYOUT_LEFT_SIDE_SHOW : HgAppEvents.LAYOUT_LEFT_SIDE_HIDE, {});

        overlay ? this.leftSideContainer_.setStyle('zIndex', Popup.POPUP_Z_INDEX++) : this.leftSideContainer_.clearStyle('zIndex');
    }

    /**
     * Show or hide left-side container
     * @param {boolean} visible True to show, false for hide
     * @param {boolean=} opt_force Force left side container visibility no matter of right side
     * (used when right side is expanded too much for the current resolution but left side still fits if not so)
     * @protected
     */
    setLeftSideContainerVisible(visible, opt_force) {
        this.expandBtn_.setChecked(!visible);

        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            contentFacetRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.FACET));

        if (visible) {
            EventsUtils.unlisten(contentFacetRegion, UIComponentEventTypes.ACTION, this.handleLeftSideExpand_, false, this);
        } else {
            EventsUtils.listen(contentFacetRegion, UIComponentEventTypes.ACTION, this.handleLeftSideExpand_, false, this);
        }

        if (!visible && !this.isLeftSideContainerCollapsed_()) {
            this.setLeftSideContainerCollapsed(true);
            return;
        }

        if (!this.leftSideContainerFitsResolution_(opt_force) || (!visible && this.isLeftSideContainerOverlaid_())) {
            this.setLeftSideContainerOverlaid(visible);
        } else if (visible && this.isLeftSideContainerOverlaid_()) {
            this.setLeftSideContainerOverlaid(false);

            EventsUtils.listenOnce(this.contentContainer_.getChildAt(0).getElement(), BrowserEventType.TRANSITIONEND, (e) => { return this.setLeftSideContainerCollapsed(false); });
        } else {
            if (opt_force) {
                /* make sure right side container is not too large */
                if (this.currentOuterContainerWidth == null) {
                    this.currentOuterContainerWidth = this.outerContentContainer.getSize(true).width;
                }

                if (this.currentRightContainerWidth_ == null) {
                    this.currentRightContainerWidth_ = this.rightSideContainer_.getSize(true).width;
                }

                if (this.currentOuterContainerWidth - this.currentRightContainerWidth_ < General.LEFT_SIDE_MIN_WIDTH) {
                    const maxPossibleWidth = this.currentOuterContainerWidth - General.LEFT_SIDE_MIN_WIDTH;

                    this.rightSideContainer_.setWidth(maxPossibleWidth, false, true);
                    this.currentRightContainerWidth_ = maxPossibleWidth;
                }
            }

            this.setLeftSideContainerCollapsed(!visible);
        }
    }

    /**
     * Handles left-side container expand
     * @param {hf.events.Event} e
     * @private
     */
    handleDialogRegionExpandCollapse_(e) {
        this.dialogRegionExpandsOverFullContent_ = e.getPayload()['fullContent'];

        this.updateDialogRegion();

        this.checkIsContentContainerCovered_();
    }

    /**
     * Handles left-side container expand
     * @param {hf.events.Event} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        if(hotkey == HgHotKeyTypes.TOGGLE_LEFT_PANEL) {
            this.setLeftSideContainerVisible(!this.isLeftSideContainerVisible(), true);
        }
    }

    /**
     * Handles left-side container expand
     * @param {hf.events.Event} e
     * @private
     */
    handleLeftSideExpand_(e) {
        if (!this.isLeftSideContainerVisible()) {
            this.setLeftSideContainerVisible(true, true);
        }
    }

    /**
     * Handles action in right-side container (collapse left is overlaid)
     * @param {hf.events.Event} e
     * @private
     */
    handleLeftSideCollapse_(e) {
        if (this.isLeftSideContainerOverlaid_()) {
            this.setLeftSideContainerVisible(false);
        }
    }

    /**
     * Handles left-side container toggle (expand/collapse)
     * @param {hf.events.Event} e
     * @private
     */
    handleExpandBtnAction_(e) {
        this.setLeftSideContainerVisible(!this.isLeftSideContainerVisible(), true);
    }

    /** @inheritDoc */
    handleResizeHandleHold(uniqueId, e) {
        super.handleResizeHandleHold(uniqueId, e);

        if (this.currentResizer == General.Resizer_.INNER) {
            /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
            this.currentRightContainerWidth_ = this.rightSideContainer_.getSize(true).width;
            this.currentLeftContainerWidth_ = this.leftSideContainer_.getSize(true).width;
        }
    }

    /** @inheritDoc */
    handleResizeHandleMove(e) {
        const mousePosition = UIUtils.getMousePosition(e);

        if (this.currentResizer == General.Resizer_.INNER) {
            let offsetX = mousePosition.x - this.currentMousePosition.x;

            this.resizeSocialZone_(offsetX);
        }
        else {
            super.handleResizeHandleMove(e);
        }

        this.currentMousePosition = mousePosition;
    }

    /** @inheritDoc */
    handleResizeHandleRelease(e) {
        const mousePosition = UIUtils.getMousePosition(e);

        if (this.currentResizer == General.Resizer_.INNER) {
            this.endResizeTransition();

            /* update selector size */
            let offsetX = mousePosition.x - this.currentMousePosition.x;

            this.resizeSocialZone_(offsetX);

            /* make sure container items are resizer correspondingly if required */
            this.rightSideContainer_.onResize();
            this.leftSideContainer_.onResize();

            /* store in appdata new value of left side container */
            const appDataService = AppDataService;
            if (appDataService) {
                appDataService.updateAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.SOCIAL_ZONE_WIDTH, this.currentRightContainerWidth_ + 'px', true, true);
            }
        }
        else {
            super.handleResizeHandleRelease(e);
        }

        this.currentMousePosition = mousePosition;
    }

    /**
     * Resize selector
     * @param {number=} offsetX
     * @private
     */
    resizeSocialZone_(offsetX) {
        /* do not allow left side container go under 537px */
        if (this.currentLeftContainerWidth_ + offsetX > General.LEFT_SIDE_MIN_WIDTH) {
            const newWidth = this.currentRightContainerWidth_ - offsetX;

            if (newWidth < General.RIGHT_SIDE_MIN_WIDTH) {
                /* in case the mouse is dragged fast and this leads to -[nr]px, the right side should be resized to minimum without stages */
                /* stages appears, because when you drag too much, your resize is not convenient, because exceed the limits , even if is still some room left, so
                 you have to drag a little slower the mouse */
                //this.rightSideContainer_.setWidth(hg.layout.General.RIGHT_SIDE_MIN_WIDTH + 'px', true, false);
                this.rightSideContainer_.getElement().style.width = General.RIGHT_SIDE_MIN_WIDTH + 'px';

                /* update current mouse position and size */
                this.currentRightContainerWidth_ = General.RIGHT_SIDE_MIN_WIDTH;
                this.currentLeftContainerWidth_ = this.currentOuterContainerWidth - General.RIGHT_SIDE_MIN_WIDTH;
            } else {
                /* this is a normal case */
                //this.rightSideContainer_.setWidth(newWidth + 'px', true, false);
                this.rightSideContainer_.getElement().style.width = newWidth + 'px';

                /* update current mouse position and size */
                this.currentRightContainerWidth_ = newWidth;
                this.currentLeftContainerWidth_ = this.currentLeftContainerWidth_ + offsetX;
            }

            /* announce left size container of resize, necessary especially for mini-threads */
            this.contentContainer_.onResize();
        }
    }

    /**
     * Handles the resize event of the header
     * Adjust top positioning on content container
     * @param {hf.events.Event} e Resize event to handle.
     * @protected
     */
    handleHeaderResize_(e) {
        if(!this.headerContainer_) return;

        const size = this.headerContainer_.getSize(true),
            topPos = size.height;

        this.outerContentContainer.setStyle('top', StyleUtils.normalizeStyleUnit(topPos));

        /* simulate a window resize event to allow popups to reposition when layout is resized */
        if (this.isInDocument()) {
            this.getHandler()
                .listenOnce(this.outerContentContainer.getElement(), BrowserEventType.TRANSITIONEND, function () {
                    /* simulate a window resize event to allow popups to reposition when layout is resized */
                    document.dispatchEvent(new Event(BrowserEventType.VIEWPORT_RESIZE));
                });
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleTabletAndroidResize_(e) {
        if(this.originalWindowHeight_ >= window.outerHeight && this.isLeftSideContainerOverlaid_()) {
            this.leftSideContainer_.setStyle('top', (this.originalWindowHeight_ < General.LEFT_CONTAINER_MAX_HEIGHT) ? (window.orientation == 90 || window.orientation == -90) ? General.LEFT_CONTAINER_MAX_OVERFLOW + 23 : General.LEFT_CONTAINER_MAX_OVERFLOW + 'px' : General.LEFT_CONTAINER_MIN_OVERFLOW + 'px');
        } else {
            this.leftSideContainer_.setStyle('top', '0');
        }
    }
};
/**
 * Resize handlers
 * @enum {number}
 * @readonly
 * @private
 */
General.Resizer_ = {
    INNER   : 2
};
/**
 * Whether the LEFT side container is visible or not
 * @type {boolean}
 */
General.LEFT_SIDE_VISIBLE = true;

/**
 * Minimum size of social zone
 * @type {number}
 * @default 425
 */
General.RIGHT_SIDE_MIN_WIDTH = 455;

/**
 * Minimum size of work zone
 * @type {number}
 * @default 537
 */
General.LEFT_SIDE_MIN_WIDTH = 537;

/**
 * Max size of reposition left container
 * @type {number}
 * @default 800
 */
General.LEFT_CONTAINER_MAX_HEIGHT = 800;

/**
 * Max overflow of left container
 * @type {number}
 * @default 45
 */
General.LEFT_CONTAINER_MAX_OVERFLOW = 45;

/**
 * Max overflow of left container
 * @type {number}
 * @default 121
 */
General.LEFT_CONTAINER_MIN_OVERFLOW = 121;

/**
 * Marker for automatic collapse action, restore to expand when window size changes if possible
 * @type {boolean}
 * @default false
 * @private
 */
General.automaticResize_ = false;

/**
 * @type {number}
 * @private
 */
General.originalWindowHeight_ = 0;