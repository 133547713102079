import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {IHgResource} from "./../resource/IHgResource.js";
import {Place} from "./../geolocation/Place.js";
import {HgResourceCanonicalNames} from "./../resource/Enums.js";

/**
 * Creates a {@see hg.data.model.visitor.Visitor} data model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class Visitor extends DataModel {
    /**
     * @param {!Object=} opt_initData The initial data for this object.
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'visitorId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the visitor. */
        this.addField({'name': 'visitorId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The avatar of the visitor */
        this.addField({'name': 'avatar', 'type': Array});

        /* The name of the visitor. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The email of the visitor. */
        this.addField({'name': 'email', 'type': DataModelField.PredefinedTypes.STRING});

        /* The phone number of the visitor. */
        this.addField({'name': 'phone', 'type': DataModelField.PredefinedTypes.STRING});

        /* The last IP address of the visitor. */
        this.addField({'name': 'ip', 'type': DataModelField.PredefinedTypes.STRING});

        /* The last location if known. */
        this.addField({'name': 'place', 'type': Place,
            'getter': function() {
                return this.getFieldValue('place');
            }
        });

        /* When the visitor has been last seen. */
        this.addField({'name': 'lastSeen', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* The visitor current status */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* The id of the person associated with the visitor. */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The type of the device where the visitor was last seen:
         DESKTOP - a desktop computer or notebook
         MOBILE - a mobile device, phone or tablet */
        this.addField({'name': 'deviceType', 'type': DataModelField.PredefinedTypes.STRING});

        /* A reputation score between 1 (lowest) and 5 (highest). */
        this.addField({'name': 'reputation', 'type': DataModelField.PredefinedTypes.NUMBER});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        super.defineCustomFields();

        /* resourceId - required by the implementation of IHgResource */
        this.addField({
            'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceId', function () {
                return this['visitorId'];
            })
        });

        /* resourceId - required by the implementation of IHgResource */
        this.addField({
            'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': this.createLazyGetter('resourceType', function () {
                return HgResourceCanonicalNames.VISITOR;
            })
        });
    }
};
// interface implementation
IHgResource.addImplementation(Visitor);