import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterCollection} from "./FilterCollection.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Create new facet model.
 * @extends {DataModel}
 * @unrestricted 
*/
export class RequestQuery extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* The filter that must be passed to the read method. */
        this.addField({'name': 'filter', 'type': FilterCollection});

        /* Field to sort entries by.*/
        this.addField({'name': 'sortField', 'type': DataModelField.PredefinedTypes.STRING});

        /* Can either be asc or desc, when not supplied defaults to asc. */
        this.addField({'name': 'sortOrder', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'fetchCriteria', 'type': Object, 'isReadOnly': true,
            'getter': this.createLazyGetter('fetchCriteria', function() {
                const filters = [];
                this['filter'].forEach(function(filter) { filters.push(filter.toJSONObject()); });

                return {
                    'filters': filters,
                    'sorters': !StringUtils.isEmptyOrWhitespace(this['sortField']) ? [{'sortBy': this['sortField'], 'direction': this['sortOrder']}] : undefined
                };
            })
        });
    }

    /** @inheritDoc */
    onDataLoaded() {
        if(this['sortOrder'] == null) {
            this['sortOrder'] = SortDirection.ASC
        }

        this['sortOrder'] = this['sortOrder'].toLowerCase();
    }
};