import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {GeolocationUtils} from "./../../../common/geolocation/geolocation.js";
import {AccountMenuItemCategories, HgStates} from "./../../../data/model/common/Enums.js";
import {UserCollection} from "./../../../data/model/user/UserCollection.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";

import {AuthAccount} from "./../../../data/model/auth/AuthAccount.js";
import {Region} from "./../../../data/model/geolocation/Region.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import UserService from "./../../../data/service/UserService.js";
import AvatarService from "./../../../data/service/AvatarService.js";
import PersonService from "./../../../data/service/PersonService.js";
import GeolocationService from "./../../../data/service/GeolocationService.js";
import PublicProfileService from "./../../../data/service/PublicProfileService.js";

/**
 * Creates a {@see hg.module.settings.viewmodel.WizardViewmodel} object
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class WizardViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService;

        /**
         * @type {GeolocationService}
         * @protected
         */
        this.geolocationService;

        /**
         * @type {PublicProfileService}
         * @protected
         */
        this.publicProfileService;

        /**
         * Reference to user service
         * @type {UserService}
         * @protected
         */
        this.userService;

        /**
         * @type {Object}
         * @private
         */
        this.settingsCategoriesMapping_;
    }

    /**
     *
     * @returns {hf.data.DataModel | hf.data.DataModelCollection}
     */
    getCurrentSettingModel() {
        let model = null;
        const currentCategory = this['currentCategory'];

        switch(currentCategory) {
            case AccountMenuItemCategories.PERSONAL_INFO:
                model = this['person'];
                break;

            case AccountMenuItemCategories.INVITE_TEAM:
                model = this['invitedMembers'];
                break;

            case AccountMenuItemCategories.SERVICES:
                model = this['services'];
                break;

            case AccountMenuItemCategories.HUBGETS_PAGE:
                model = this['publicProfile'];
                break;
        }

        return model;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     * @suppress {visibility}
     */
    isDirty() {
        const currentSettingModel = this.getCurrentSettingModel();

        if(this['currentCategory'] == AccountMenuItemCategories.PERSONAL_INFO) {
            const person = /**@type {hf.data.DataModel}*/(currentSettingModel),
                personDirtyFields = person.getDirtyFields();

            /* isDirty is computed differently if the 'avatar' field is the only one that's dirty */
            if(personDirtyFields.hasOwnProperty('avatar') && Object.keys(personDirtyFields).length == 1) {
                return person.isNew() || person.isMarkedForRemoval();
            }
        }

        return currentSettingModel != null && currentSettingModel.isDirty();
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        const currentSettingModel = this.getCurrentSettingModel(),
            currentCategory = this['currentCategory'];

        return currentCategory == AccountMenuItemCategories.INVITE_TEAM ||
            currentCategory == AccountMenuItemCategories.SERVICES ||
            (currentSettingModel != null && currentSettingModel.isValid());
    }

    /**
     * Check if current model is savable, on wizz mode this just means model is valid and no other restriction is imposed ()
     * @returns {boolean}
     */
    isSavable() {
        return true;
    }

    /**
     * Accepts all the current changes.
     */
    acceptChanges() {
        const currentSettingModel = this.getCurrentSettingModel();
        if(currentSettingModel != null) {
            currentSettingModel.acceptChanges();
        }
    }

    /**
     * Rejects all the current changes.
     */
    discardChanges() {
        const currentSettingModel = this.getCurrentSettingModel(),
            currentCategory = this['currentCategory'];

        if(currentCategory == AccountMenuItemCategories.INVITE_TEAM) {
            this['invitedMembers'] = undefined;
        }
        else if(currentSettingModel != null) {
            currentSettingModel.discardChanges();
        }
    }

    /**
     * @return {Promise}
     */
    loadPublicProfile() {
        if(this['publicProfile'] == null) {
            return this.publicProfileService.getPublicProfile()
                .then((profile) => {
                    this['publicProfile'] = profile;
                });
        }

        return Promise.resolve(this['publicProfile']);
    }

    /** @inheritDoc */
    init(opt_initData) {
        super.init(opt_initData);

        this.publicProfileService = PublicProfileService;
        this.geolocationService = GeolocationService;
        this.personService = PersonService;
        this.userService = UserService;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.publicProfileService = null;
        this.geolocationService = null;
        this.personService = null;
        this.userService = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The index of the wizard step where the user reached so far. */
        this.addField({'name': 'hgState'});

        /* canInviteUsers - whether the user that goes through the wizard at this moment can invite other users */
        this.addField({'name': 'canInviteUsers', 'value': false});

        /* person - the personal details of the user that goes through the wizard at this moment */
        this.addField({'name': 'person', 'value': null});

        /* invited users*/
        this.addField({'name': 'invitedMembers', 'getter': this.createLazyGetter('invitedMembers', () => {
            const invitedUsers = new UserCollection();

            /* add 5 users by default */
            invitedUsers.addNew();
            invitedUsers.addNew();
            invitedUsers.addNew();
            invitedUsers.addNew();
            invitedUsers.addNew();

            invitedUsers.acceptChanges(true);

            return invitedUsers;
        })
        });

        this.addField({'name': 'teamReport', 'getter': this.createAsyncGetter('teamReport', () => {
            if(this['canInviteUsers']) {
                return this.userService.readUserReport();
            }

            return Promise.resolve(null);
        })
        });

        /* services */
        this.addField({'name': 'services', 'value': null});

        /* hubgets page */
        this.addField({'name': 'publicProfile', 'value': null});

        /* countries */
        this.addField({'name': 'countries', 'getter': this.createLazyGetter('countries', () => {
            return GeolocationUtils.CountriesList;
        })});

        /* regions */
        //this.addField({'name': 'regions', 'value': null});
        this.addField({'name': 'regions', 'getter': this.createAsyncGetter('regions', () => {
            const person = this['person'],
                countryCode = person != null ? person.get('address.region.country.code') : null;

            if(!StringUtils.isEmptyOrWhitespace(countryCode)) {
                const country = GeolocationUtils.CountriesMap[countryCode];

                if(country['regionCount'] > 0) {
                    return this.geolocationService.getRegions(/**@type {string}*/(countryCode));
                }
            }

            return Promise.resolve([]);
        })});

        /* organizations */
        this.addField({'name'  : 'organizations', 'getter': this.createLazyGetter('organizations', () => {
            return new ListDataSource({
                'dataProvider'	: this.personService.loadSystemOrganizations.bind(/** @type {Object} */ (this.personService)),
                'fetchCriteria': {
                    'sorters': [{
                        'sortBy': 'name',
                        'direction': SortDirection.ASC
                    }]
                }
            });
        })});

        /* avatars */
        this.addField({'name' : 'avatars', 'getter': this.createLazyGetter('avatars',
            function() {
                return new ListDataSource({
                    'dataProvider': this.loadAvatars_.bind(this),
                    'localSorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                });
            })
        });

        /* currentCategory */
        this.addField({'name': 'currentCategory', 'value': AccountMenuItemCategories.PERSONAL_INFO});

        /* settingsCategories */
        this.addField({'name': 'settingsCategories', 'getter': this.createLazyGetter('settingsCategories', () => {
            const canInviteUsers = this['canInviteUsers'],
                hgState = this['hgState'];

            const settingsCategories = new ObservableCollection({
                'defaultItems': [
                    /* Wizard --> Personal Info */
                    {
                        'type': AccountMenuItemCategories.PERSONAL_INFO,
                        'hidden': false,
                        'enabled': hgState >= HgStates.WIZZ_PERSONAL_INFO,
                        'label': AccountMenuItemCategories.PERSONAL_INFO,
                        'hgState': HgStates.WIZZ_PERSONAL_INFO
                    },
                    /* Wizard --> Invite Team */
                    {
                        'type': AccountMenuItemCategories.INVITE_TEAM,
                        'hidden': !canInviteUsers,
                        'enabled': hgState >= HgStates.WIZZ_INVITE_TEAM,
                        'label': AccountMenuItemCategories.INVITE_TEAM,
                        'hgState': HgStates.WIZZ_INVITE_TEAM
                    },
                    /* Wizard --> Device Services */
                    {
                        'type': AccountMenuItemCategories.SERVICES,
                        'hidden': UserAgentUtils.ELECTRON,
                        'enabled': hgState >= HgStates.WIZZ_DEVICE_SERVICES,
                        'label': AccountMenuItemCategories.SERVICES,
                        'hgState': HgStates.WIZZ_DEVICE_SERVICES
                    },
                    /* Wizard --> Hubgets Page */
                    {
                        'type': AccountMenuItemCategories.HUBGETS_PAGE,
                        'hidden': false,
                        'enabled': hgState >= HgStates.WIZZ_HUBGETS_PAGE,
                        'label': AccountMenuItemCategories.HUBGETS_PAGE,
                        'hgState': HgStates.WIZZ_HUBGETS_PAGE
                    }
                ],
                'itemConverter': ObservableCollection.wrapChildrenIntoObservablesConverter
            });

            this.settingsCategoriesMapping_ = {};
            this.settingsCategoriesMapping_[AccountMenuItemCategories.PERSONAL_INFO] = settingsCategories.getAt(0);
            this.settingsCategoriesMapping_[AccountMenuItemCategories.INVITE_TEAM] = settingsCategories.getAt(1);
            this.settingsCategoriesMapping_[AccountMenuItemCategories.SERVICES] = settingsCategories.getAt(2);
            this.settingsCategoriesMapping_[AccountMenuItemCategories.HUBGETS_PAGE] = settingsCategories.getAt(3);

            return new CollectionView({
                'source': settingsCategories,
                'filters': function(category) {
                    return category['hidden'] == false;
                }
            });
        })});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['currentCategory'] = rawData['currentCategory'] || AccountMenuItemCategories.PERSONAL_INFO;
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'currentCategory') {
            if(this['currentCategory']) {
                const categoryItemData = this.settingsCategoriesMapping_[this['currentCategory']];
                if(categoryItemData) {
                    categoryItemData['enabled'] = true;

                    if(categoryItemData['hgState'] > this['hgState']) {
                        this['hgState'] = categoryItemData['hgState'];
                    }
                }
            }
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        super.onChildChange(fieldName, e);

        const payload = e.getProperty('payload');
        if (payload != null) {
            if (fieldName === 'person') {
                if(e.target instanceof AuthAccount && payload['field'] == '') {
                    /* It seems this is not needed.
                     If it's not removed it will be called when the person is saved triggering a reload of the regions which is not ok */
                    //this.onCountryCodeChange_();
                }
                else if(e.target instanceof Region && payload['field'] == 'country') {
                    this.onCountryCodeChange_();
                }
            }
        }

        return true;
    }

    /**
     * @private
     */
    onCountryCodeChange_() {
        /* reset the 'regions' field */
        this.set('regions', undefined);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} criteria
     * @return {Promise}
     * @private
     */
    loadAvatars_(criteria) {
        const personId = this['person'] ? this['person']['personId'] : null;

        const avatarService = AvatarService;

        return avatarService.loadAvatarsFor(HgResourceCanonicalNames.PERSON, personId, criteria);
    }
};