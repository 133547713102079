import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RegExpMatchRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import {Password} from "./Password.js";
import {AuthHashingAlgorithm} from "./Enums.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Create new PassToken model
 * @extends {Password}
 * @unrestricted 
*/
export class PassToken extends Password {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        this.errorsSeverityLevel = ValidationRuleSeverity.WARNING;
    }

    /** @inheritDoc */
    defineFields() {
        /* call the base class method to add the 'password' field */
        super.defineFields();

        /* hg.data.model.auth.HashingToken */
        this.addField({'name': 'secretHash', 'type': DataModelField.PredefinedTypes.STRING});

        /* id for the forget password token or old password depending on usage */
        this.addField({'name': 'token', 'type': DataModelField.PredefinedTypes.STRING});

        /* used only internally to compute password strength */
        this.addField({'name': 'username', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* the new password */
        this.addField({'name': 'newSecret', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        /* The model is considered invalid if it has at least one warning */
        this.errorsSeverityLevel = ValidationRuleSeverity.WARNING;

        super.defineValidationRules();

        /* The Old Password rules */
        this.addValidationRule(new RequiredRules({
            'targetProperty': 'token'
        }));

        /* The old password must have the same RegEx as the password field from Login screen */
        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'token',
            'pattern'       : RegExpUtils.PASSWORD_RE,
            'priority'      : 1
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        rawData['secretHash'] = rawData['secretHash'] || AuthHashingAlgorithm.PLAIN;

        if (rawData['newSecret'] != null) {
            rawData['password'] = rawData['newSecret'];
            rawData['clonePassword'] = rawData['newSecret'];
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'password') {
            this['newSecret'] = this['password'];
        }
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        const canSerializeField = super.canSerializeField(fieldName, serializeOptions);

        const excludedFields = ['password', 'username'];

        return !excludedFields.includes(fieldName) && canSerializeField;
    }

    /** @inheritDoc */
    getUserInputsForPasswordStrength() {
        const userInputs = [];

        if(!StringUtils.isEmptyOrWhitespace(this['username'])) {
            userInputs.push(this['username']);
        }

        return userInputs;
    }
};