import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {ObservableChangeEventName} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {EditorPluginEventType} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {List, ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {ObservableObject} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {CollectionView} from "./../../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {ImageUtils} from "./../../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MessageEditorCommands, MessageEditorParts} from "./../Enums.js";
import {PreviewResourceTypes} from "./../../../../data/model/preview/Enums.js";
import {HgUIEventType} from "./../../events/EventType.js";
import {FileUploadCollection} from "./../../../../data/model/file/FileUploadCollection.js";
import {FileUpload} from "./../../../../data/model/file/FileUpload.js";
import {Attachment} from "./../Attachment.js";
import {FileTypes, FileLabels} from "./../../../../data/model/file/Enums.js";
import {FilePreviewer} from "./../../file/FilePreviewer.js";
import {CollapsedAttachmentContent} from "./../CollapsedAttachmentContent.js";
import {PopupDialog} from "./../../PopupDialog.js";
import {HgFileUtils} from "./../../../../data/model/file/Common.js";
import {PopupButton} from "./../../button/PopupButton.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {HgAppEvents} from "./../../../../app/Events.js";
import EventBus from "./../../../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TEAM_TOPIC_ID} from "../../../../data/model/thread/Enums.js";

/**
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgFileEditorPlugin extends AbstractEditorPlugin {
    /**
     * @param {ResourceLike|null} context The context where the File was contributed (conversation, topic, board, person, file)
     * @param {hf.ui.UIComponent|Element} previewContainer
     * @param {boolean} opt_temporaryChanges If false files are removed on spot
     * @param {number=} opt_maxFiles
    */
    constructor(context, previewContainer, opt_temporaryChanges, opt_maxFiles) {
        super();

        /**
         * Observable collection of file uploads to be sent with the message
         * @private {hg.data.model.file.FileUploadCollection}
         */
        this.files_ = new FileUploadCollection();

        /**
         * Container for file preview
         * @private {hf.ui.UIComponent|Element}
         */
        this.previewContainer_ = previewContainer;

        /**
         * List displaying file uploads
         * @private {hf.ui.list.List}
         */
        this.filesListView_ = new List({
            'extraCSSClass' : MessageEditorParts.FILE_PREVIEW_LIST,
            'autoLoadData'  : false,
            'itemsLayout'   : ListItemsLayout.VSTACK,
            'isScrollable'  : false,
            'itemStyle'     : MessageEditorParts.FILE_PREVIEW_LIST + '-' + 'item',
            'itemContentFormatter'  : function(model, item) {
                if (model) {
                    return new Attachment({'model': model});
                }
                return null;
            },
            'errorFormatter': function(error) {
                return error['message'] != null ? error['message'] : error;
            }
        });

        /**
         * @private {hf.structs.CollectionView}
         */
        this.filesSource_ = new CollectionView({
            'source': this.files_,
            'sorters': [
                {'sortBy': 'uploaded', 'direction': 'desc'}
            ]
        });

        /**
         * The maximum number of attached files to be shown in the editor
         * By default - 5
         * @private {number|undefined}
         */
        this.maxFiles_ = opt_maxFiles != null ? opt_maxFiles : 5;

        if (context == null) {
            throw new Error('Cannot instantiate a File editor plugin without any resourceLink context.');
        }

        /**
         * The context where the File was contributed (conversation, topic, board, person)
         * @private {ResourceLike|null}
         */
        this.context_ = context;

        /**
         * True to remove a file on spot, false otherwise
         * @private {boolean}
         */
        this.temporaryChanges_ = opt_temporaryChanges != null ? opt_temporaryChanges : true;

        /**
         * File preview dialog for just uploaded files
         * @private {hg.common.ui.file.FilePreviewer}
         */
        this.filePreviewDialog_;

        /**
         * Button that displays the number of uploaded files
         * and opens a popup with it's content equal to {@see hg.common.ui.editor.CollapsedAttachmentContent}.
         * @private {hg.common.ui.button.PopupButton}
         */
        this.uploadedBtn_;

        /**
         * Popup in which the file limit exceeded warning will be displayed.
         * @private {hf.ui.popup.Popup}
         */
        this.fileLimitExceededPopup_;

        /**
         * Will be used:
         *      as nonce for the message with files that will be send
         *      to have the same blockId/nonce for all files from a thread
         *      because there is a problem when threads are switched and a new file upload collection is composed from the existing unsent URIs -> new blockId 
         * Keeps the blockId in which the files where uploaded
         * @private {string}
         */
        this.blockId_ = this.files_['blockId'];
    }

    /** @inheritDoc */
    getTrogClassId() {
        return 'File';
    }

    /** @inheritDoc */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.FILE;
    }

    /** @inheritDoc */
    hasUnattachedContent() {
        const matchIndex = this.files_.findIndex(function (fileUpload) {
            return fileUpload['error'] == null;
        });

        return matchIndex != -1;
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        this.cleanUp();

        if (!this.filesListView_.isInDocument()) {
            if (this.previewContainer_ instanceof UIComponent) {
                this.previewContainer_.addChild(this.filesListView_, true);
            } else {
                this.filesListView_.render(this.previewContainer_);
            }
        }


        // Parses the current content of editable DOM element and format the text according to metacontent tags
        const element = field.getElement();
        if (element) {
            element.innerHTML = this.prepareContent_(element.innerHTML);
        }
    }

    /** @inheritDoc */
    disable(field) {
        super.disable(field);

        this.cleanUp();

        if (this.filesListView_.isInDocument()) {
            if (this.previewContainer_ instanceof UIComponent) {
                this.previewContainer_.removeChild(this.filesListView_, true);
            } else {
                if (this.filesListView_.getElement() && this.filesListView_.getElement().parentNode) {
                    this.filesListView_.getElement().parentNode.removeChild(this.filesListView_.getElement());
                }
                this.filesListView_.exitDocument();
            }
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        this.cleanUp();

        return this.prepareContent_(content);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        let emptyMessage = StringUtils.isEmptyOrWhitespace(content);
        const filesCount = this.files_.getCount();
        this.files_.forEach((fileUpload, index) => {
            if (fileUpload['error'] == null && fileUpload['version'].getCount() > 0) {
                if (index == 0 && !emptyMessage && !content.endsWith('\n')) {
                    content += '\n';
                }

                content += HgFileUtils.getOriginalUri(fileUpload);

                if (index != filesCount -1) {
                    content += '\n';
                }

            } else if(fileUpload['error']) {
                /* encode File Error in order to restore it when changing threads. */
                if (fileUpload['originalFile'] || fileUpload['name']) {
                    if (index == 0 && !emptyMessage && !content.endsWith('\n')) {
                        content += '\n';
                    }

                    content += HgMetacontentUtils.encodeFileError(fileUpload['name'] || fileUpload['originalFile']['name']);

                    if (index != filesCount -1) {
                        content += '\n';
                    }
                }
            }
        });

        /* ! if there are no files, this plugin should not change the content of the message ! */
        content += filesCount > 0 ? ' ' + HgMetacontentUtils.encodeNonce(this.getBlockId_()) : '';

        return content;
    }

    /**
     * Submit to server file removal only to temporary files (added on this message update)
     * @override
     */
    discardTemporaryChanges() {
        const filesMarkedForRemoval = this.files_.getDeletedItems();
        let filesForRemoval = [];

        filesMarkedForRemoval.forEach(function (fileUpload) {
            if (!StringUtils.isEmptyOrWhitespace(fileUpload['fileId'])) {
                if (!!fileUpload['temporary']) {
                    filesForRemoval.push(fileUpload);
                } else {
                    this.files_.add(fileUpload);
                }
            }
        }, this);

        /* remove from backend if this change was temporary! */
        if (this.temporaryChanges_) {
            this.files_.forEach(function (media) {
                if (!StringUtils.isEmptyOrWhitespace(media['fileId']) && !!media['temporary']) {
                    filesForRemoval.push(media);
                }
            });

            /* filter only uploaded files */
            filesForRemoval = filesForRemoval.filter(function (file) {
                return file['progress'] == 1;
            });

            if (filesForRemoval.length > 0) {
                const event = new Event(EditorPluginEventType.DATA_ACTION);
                event.addProperty('files', filesForRemoval);
                event.addProperty('action', HgFileEditorPlugin.FileActions.REMOVE);
                event.addProperty('blockId', this.getBlockId_());

                this.dispatchEvent(event);
            }
        }

        this.removeAllFiles();

        this.files_.acceptChanges(true);
    }

    /**
     * Submit to server file removal no matter of temporary flag
     * @override
     */
    acceptTemporaryChanges() {
        const filesMarkedForRemoval = this.files_.getDeletedItems();

        if (filesMarkedForRemoval.length > 0) {
            const event = new Event(EditorPluginEventType.DATA_ACTION);
            event.addProperty('files', filesMarkedForRemoval);
            event.addProperty('action', HgFileEditorPlugin.FileActions.REMOVE);
            event.addProperty('blockId', this.getBlockId_());

            this.dispatchEvent(event);
        }

        this.files_.acceptChanges(true);
    }

    /** @inheritDoc */
    handleResize() {
        if (this.uploadedBtn_ != null) {
            this.uploadedBtn_.onResize();
        }

        if(this.fileLimitExceededPopup_ != null) {
            this.fileLimitExceededPopup_.reposition();
        }
    }

    /**
     * @param {string} content Original content
     * @return {string} New HTML that's ok for editing.
     * @private
     */
    prepareContent_(content) {
        const context = this.context_;

        const resources = {};
        resources[PreviewResourceTypes.IMAGE_FILE] = 0;
        resources[PreviewResourceTypes.REGULAR_FILE] = 0;

        /* parse url and get array of file object */
        const files = /** @type {Array.<FileTagMeta>} */(HgMetacontentUtils.decodeFileMetadata(content));

        if (files.length) {
            const uploadedCollection = files.map(function (file) {
                if (HgFileUtils.isImageLike(file)) {
                    resources[PreviewResourceTypes.IMAGE_FILE]++;
                } else {
                    resources[PreviewResourceTypes.REGULAR_FILE]++;
                }

                return new FileUpload({
                    'fileId'            : file['id'],
                    'originalName'      : file['name'],
                    'originalExtension' : file['ext'],
                    'name'              : file['name'],
                    'type'              : file['mType'],
                    'version'           : [{
                        'fileView'      : [{
                            'mime'      : file['mime'],
                            'uri'       : file['downloadPath'],
                            'size'      : file['size'],
                            'label'     : FileLabels.ORIGINAL
                        }],
                        'description'   : file['description']
                    }],
                    'context'           : {
                        'resourceId'    : context['resourceId'],
                        'resourceType'  : context['resourceType']
                    },
                    'created'           : file['created'],
                    'progress'          : 1,
                    'error'             : file['error']
                });
            });

            this.files_.addRangeAt(uploadedCollection, 0);
        }

        if (context == null || context['resourceId'] !== TEAM_TOPIC_ID) {
            const filesInProgressEvent = new Event(EditorPluginEventType.DATA_REQUEST);
            filesInProgressEvent.addProperty('context', context);

            if (this.dispatchEvent(filesInProgressEvent)) {
                const filesInProgress = /** @type {Array} */(filesInProgressEvent.getProperty('files'));

                if (filesInProgress != null && filesInProgress.length > 0) {
                    filesInProgress.map(function (file) {
                        if (HgFileUtils.isImageLike(file)) {
                            resources[PreviewResourceTypes.IMAGE_FILE]++;
                        } else {
                            resources[PreviewResourceTypes.REGULAR_FILE]++;
                        }
                    });

                    this.files_.addRangeAt(/** @type {!Array} */(filesInProgress), 0);
                }
            }
        }

        this.checkDisplayPreview_(resources);

        /* apply full decode by default or when decode is FULL; replace file Action Tag with empty string, also extract nonce and
           replace nonce action tag it with empty string */
        const remainingContent = HgMetacontentUtils.decodeNonce(HgMetacontentUtils.decodeFullFileActionTag(content));
        this.blockId_ = remainingContent['nonce'];

        return remainingContent['newContent'];
    }

    /** @inheritDoc */
    execCommandInternal(command, files) {
        files = /** @type {Array} */(files instanceof FileList ? Array.from(files) : files);

        const editor = this.getFieldObject(),
            uploadedFilesCollection = [],
            maxlen = files.length - 1,
            resources = {},
            uploadedFileNo = this.files_['hasErrors'] ? (this.files_['totalCount'] - this.files_['errorCount']) : this.files_['totalCount'];

        resources[PreviewResourceTypes.IMAGE_FILE] = 0;
        resources[PreviewResourceTypes.REGULAR_FILE] = 0;

        const processFilesPromise = new Promise((resolve, reject) => {
            files.forEach((file, idx) => {
                const fileUpload = new FileUpload({
                    'originalFile': file
                });

                /* mark new fileUploads in order to dispatch a removal request to server in case of a cleanup */
                fileUpload.temporary = true;

                if (this.context_ != null) {
                    fileUpload['context'] = HgResourceUtils.getResourceLink({
                        'resourceId': this.context_['resourceId'],
                        'resourceType': this.context_['resourceType']
                    });
                }

                /* enforce limit on file upload */
                if (uploadedFileNo + idx + 1 > HgFileEditorPlugin.MAX_FILE_NO_) {
                    fileUpload['error'] = new Error('Limit enforced');
                }

                uploadedFilesCollection.push(fileUpload);

                if (!HgFileUtils.isImageLike(file)) {
                    resources[PreviewResourceTypes.REGULAR_FILE]++;
                    if (idx == maxlen) {
                        resolve(uploadedFilesCollection);
                    }
                }
                else {
                    ImageUtils.load(URL.createObjectURL(file))
                        .then((img) => {
                            const width = file['naturalWidth'] = img.naturalWidth,
                                height = file['naturalHeight'] = img.naturalHeight;

                            URL.revokeObjectURL(img.src);

                            if (width > HgAppConfig.MAX_IMAGE_WIDTH || height > HgAppConfig.MAX_IMAGE_HEIGHT) {
                                resources[PreviewResourceTypes.REGULAR_FILE]++;
                            } else {
                                resources[PreviewResourceTypes.IMAGE_FILE]++;
                            }

                            if (idx == maxlen) {
                                resolve(uploadedFilesCollection);
                            }
                        })
                        .catch(() => {
                            if (idx == maxlen) {
                                resolve(uploadedFilesCollection);
                            }
                        });
                }
            });
        });

        processFilesPromise.then((uploadedCollection) => {
            /* enforce limit on files uploaded */
            if (uploadedFileNo < HgFileEditorPlugin.MAX_FILE_NO_) {
                let limitExceeded = false;
                if (uploadedFileNo + uploadedCollection.length > HgFileEditorPlugin.MAX_FILE_NO_) {
                    uploadedCollection = uploadedCollection.slice(0, HgFileEditorPlugin.MAX_FILE_NO_ - uploadedFileNo);
                    limitExceeded = true;
                }

                this.files_.addRange(uploadedCollection);

                /* if files are already collapsed or the full preview can be displayed */
                if (this.isCompactPreview_() || this.checkDisplayPreview_(resources)) {
                    editor.dispatchResizeEvent();
                }

                this.showFileLimitExceededPopup_(limitExceeded);

                const event = new Event(EditorPluginEventType.DATA_ACTION);
                event.addProperty('files', uploadedCollection);
                event.addProperty('blockId', this.getBlockId_());
                event.addProperty('context', this.context_);
                event.addProperty('action', HgFileEditorPlugin.FileActions.UPLOAD);

                this.dispatchEvent(event);
            } else {
                this.showFileLimitExceededPopup_(true);
            }
        });
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = EventBus;
        if(eventBus) {
            this.getHandler()
                .listen(eventBus, [HgAppEvents.DATA_CHANNEL_MESSAGE_FILE_DELETE, HgAppEvents.FILE_DELETE], this.handleFileRemovalConfirmation)
        }

        this.getHandler()
            .listen(this.filesListView_, HgUIEventType.FILE_REMOVE, this.handleFileRemoval)
            .listen(this.filesListView_, HgUIEventType.FILE_PREVIEW, this.handleFilePreview)
            .listen(this.files_, ObservableChangeEventName, this.handleFilesChange_);
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.files_);
        this.files_ = null;

        BaseUtils.dispose(this.filesListView_);
        this.filesListView_ = null;

        BaseUtils.dispose(this.filesSource_);
        this.filesSource_ = null;

        BaseUtils.dispose(this.uploadedBtn_);
        this.uploadedBtn_ = null;

        BaseUtils.dispose(this.previewContainer_);
        this.previewContainer_ = null;

        BaseUtils.dispose(this.filePreviewDialog_);
        this.filePreviewDialog_ = null;

        this.context_ = null;

        BaseUtils.dispose(this.fileLimitExceededPopup_);
        this.fileLimitExceededPopup_ = null;

    }

    /**
     * @param {hf.structs.CollectionView} files
     * @return {hg.common.ui.file.FilePreviewer}
     * @private
     */
    getFilePreviewDialog_(files) {
        if (this.filePreviewDialog_ == null) {
            this.filePreviewDialog_ = new FilePreviewer({'readonly': true});
        }

        this.filePreviewDialog_.setModel(new ObservableObject({
            /* file metadata extracted from url */
            'currentFile'   : new ObservableObject((files.getCurrentItem())),

            /* files in current viewed container (thread message, NOT group) */
            'files'         : files
        }));

        return this.filePreviewDialog_;
    }

    /**
     * @return {hg.common.ui.button.PopupButton}
     * @private
     */
    getUploadedBtn_() {
        if (this.uploadedBtn_ == null) {
            const popupContent = new CollapsedAttachmentContent();
            popupContent.addListener(HgUIEventType.FILE_REMOVE, this.handleFileRemoval, false, this);
            popupContent.addListener(HgUIEventType.FILE_PREVIEW, this.handleFilePreview, false, this);

            popupContent.setModel(this.filesSource_);

            this.uploadedBtn_ = new PopupButton({
                'extraCSSClass': ['uploaded-files-number', 'hg-linklike'],
                'name': HgFileEditorPlugin.Buttons.UPLOADED,
                'content': '',
                'popup': {
                    'type': PopupDialog,
                    'extraCSSClass': ['hg-attachments-popup'],
                    'content': popupContent,
                    'placement': PopupPlacementMode.TOP,
                    'showArrow': false,
                    'staysOpen': false,
                    'hasCloseButton': true
                }
            });

            this.uploadedBtn_.addListener(HgUIEventType.FILE_REMOVE_ALL, this.handleRemoveAllFiles_, false, this);
        }

        return this.uploadedBtn_;
    }

    /**
     * Cleanup file resources
     * @protected
     */
    cleanUp() {
        this.files_.clear();
        this.blockId_ = null;

        if (this.uploadedBtn_ != null && this.uploadedBtn_.isInDocument()) {
            if (this.previewContainer_ instanceof UIComponent) {
                this.previewContainer_.removeChild(this.uploadedBtn_, true);
            } else {
                if (this.uploadedBtn_.getElement() && this.uploadedBtn_.getElement().parentNode) {
                    this.uploadedBtn_.getElement().parentNode.removeChild(this.uploadedBtn_.getElement());
                }
                this.uploadedBtn_.exitDocument();
            }
        }

        if (this.filePreviewDialog_ != null) {
            this.filePreviewDialog_.close();

            BaseUtils.dispose(this.filePreviewDialog_);
            this.filePreviewDialog_ = null;
        }

        if (this.uploadedBtn_ != null) {
            this.uploadedBtn_.close();

            BaseUtils.dispose(this.uploadedBtn_);
            this.uploadedBtn_ = null;
        }
    }

    /**
     * Clears the list with the attached files and closes the popup.
     */
    removeAllFiles() {
        const files = this.files_.getAll().slice();

        this.removeFilesNotUploaded_(files);
        this.removeFileInternally_(files);

        this.cleanUp();
    }

    /**
     * Remove file
     * @param {Array} files
     * @param {boolean=} opt_fromPreview True if removal done from media preview, false otherwise
     * @private
     */
    removeFileInternally_(files, opt_fromPreview) {
        /* remove file internally, removal will be dispatched to server on discard/acceptTemporary changes request */
        for(let i = files.length - 1; i >= 0; i--) {
            this.files_.remove(files[i]);
        }

        if (!this.temporaryChanges_ && opt_fromPreview) {
            this.files_.acceptChanges();
        }

        const editor = this.getFieldObject();
        if(editor) {
            editor.dispatchResizeEvent();
            editor.startChangeEvents(true, true);
        }

        if (!this.temporaryChanges_ && !opt_fromPreview) {
            this.acceptTemporaryChanges();
        }

        if(this.files_.getCount() === 0) {
            this.cleanUp();
        }
    }

    /**
     * Dispatches hg.common.ui.editor.plugin.HgFileEditorPlugin.FileActions.CANCEL_UPLOAD for the files that haven't finished uploading (progress < 1)
     * and need to be removed or the upload was canceled.
     *
     * @param {Array.<hg.data.model.file.FileUpload>} files
     * @private
     */
    removeFilesNotUploaded_(files) {
        const filesNotUploaded = files.filter(function (file) {
            return file['progress'] < 1;
        });

        if (filesNotUploaded.length) {
            const event = new Event(EditorPluginEventType.DATA_ACTION);
            event.addProperty('files', filesNotUploaded);
            event.addProperty('action', HgFileEditorPlugin.FileActions.CANCEL_UPLOAD);

            this.dispatchEvent(event);
        }
    }

    /**
     * @param {number=} opt_count
     * @private
     */
    updateFileCountDom_(opt_count) {
        if (this.uploadedBtn_ != null) {
            const translator = Translator,
                uploadedCount = this.files_['uploadedCount'] || 0,
                totalCount = this.files_['totalCount'] || opt_count;

            const content = uploadedCount > 1
                ? translator.translate('status_files_uploaded', [uploadedCount, totalCount]) : translator.translate('status_file_uploaded', [uploadedCount, totalCount]);

            this.uploadedBtn_.setContent(content);
        }
    }

    /**
     * Handles file collection busy change
     * @private
     */
    onBusyChange_() {
        this.setBusy(this.files_['isBusy']);
    }

    /**
     * Handles file collection busy change
     * @private
     */
    onErrorChange_() {
        this.setHasError(this.files_['hasErrors']);
    }

    /**
     * Handle file or version removal
     * @param {hg.data.model.file.File} fileInfo
     * @private
     */
    onFileRemoval_(fileInfo) {
        const match = this.files_.find(function (fileUpload) {
            return fileUpload['fileId'] == fileInfo['fileId']
        }, this);

        if (match) {
            this.removeFileInternally_([/** @type {hg.data.model.file.FileUpload} */(match)], true);
        }
    }

    /**
     * Returns the file limit exceeded warning popup
     * @returns {hf.ui.popup.Popup}
     * @private
     */
    getFileLimitExceededPopup_() {
        if (this.fileLimitExceededPopup_ == null) {
            const translator = Translator;

            this.fileLimitExceededPopup_ = new Popup({
                'extraCSSClass': ['hg-popup',  'hg-file-limit-popup'],
                'content'      : translator.translate('reached_upload_limit', [HgFileEditorPlugin.MAX_FILE_NO_]),
                'placement'    : PopupPlacementMode.TOP_MIDDLE,
                'showArrow'    : true
            });
        }

        return this.fileLimitExceededPopup_;
    }

    /**
     * Shows the file limit exceeded popup
     * @param {boolean} enable
     * @private
     */
    showFileLimitExceededPopup_(enable) {
        const popup = this.getFileLimitExceededPopup_();

        if (enable) {
            if (!popup.isOpen() && this.uploadedBtn_ != null) {
                popup.setPlacementTarget(this.uploadedBtn_);

                popup.open();
            }
        } else {
            if (popup.isOpen()) {
                popup.close();
            }

            BaseUtils.dispose(this.fileLimitExceededPopup_);
            this.fileLimitExceededPopup_ = null;
        }
    }

    /**
     * Dispatches {@see EditorPluginEventType.CAN_DISPLAY_PREVIEW} and displays a compact preview if necessary.
     * @param {Object} resources
     * @return {boolean} Whether the files can have the full preview displayed (not collapsed).
     * @private
     */
    checkDisplayPreview_(resources) {
        const previewEvent = new Event(EditorPluginEventType.CAN_DISPLAY_PREVIEW);
        previewEvent.addProperty('resources', resources);

        /* can display preview, editor has enough space on the current resolution to display preview */
        let canDisplayPreview = this.dispatchEvent(previewEvent) && this.files_.getCount() <= this.maxFiles_;

        if (!this.filesListView_.getItemsSource() || this.isCompactPreview_() == canDisplayPreview) {
            this.setItemsSource_(canDisplayPreview);
        }

        if (!canDisplayPreview) {
            this.uploadedBtn_ = this.getUploadedBtn_();

            this.updateFileCountDom_(this.files_.getCount());

            if (!this.isCompactPreview_()) {
                this.previewContainer_.addChild(this.uploadedBtn_, true);
            }
        }

        return canDisplayPreview;
    }

    /**
     * @private
     * @return {boolean} Whether the uploaded files are collapsed.
     */
    isCompactPreview_() {
        return this.previewContainer_.indexOfChild(this.uploadedBtn_) != -1;
    }

    /**
     * @param {boolean} canDisplayPreview
     * @private
     */
    setItemsSource_(canDisplayPreview) {
        this.filesListView_.setItemsSource(canDisplayPreview ? this.filesSource_ : null);
    }

    /**
     * Handles change event on files collection
     * @param {hf.events.Event} e
     * @private
     */
    handleFilesChange_(e) {
        const payload = e.getProperty('payload');
        if (payload) {
            if (payload['field'] == 'isBusy') {
                this.onBusyChange_();

                if (!payload['newValue']) {
                    /* busy false */
                    const editor = this.getFieldObject();
                    editor.startChangeEvents(true, true);
                }
            }

            if (payload['field'] == 'hasErrors') {
                this.onErrorChange_();
            }

            if (payload['field'] == 'totalCount' || payload['field'] == 'uploadedCount') {
                this.updateFileCountDom_();
            }
        }
    }

    /**
     * Returns the correct blockId, if the one set on the plugin does not exist, return the one from the plugin file upload collection
     * @returns {*}
     * @private
     */
    getBlockId_() {
        return this.blockId_ ? this.blockId_ : this.files_['blockId'];
    }

    /**
     * Handles request for removing a file
     * @param {hf.events.Event} e
     * @protected
     */
    handleFileRemoval(e) {
        const editor = this.getFieldObject();
        if (userAgent.device.isDesktop()  && (this.uploadedBtn_ == null || !this.uploadedBtn_.isOpen())) {
            editor.focus();
        }

        const file = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('file'));

        if (file != null) {
            this.removeFilesNotUploaded_([file]);
            this.removeFileInternally_([file]);
        }

        e.stopPropagation();
    }

    /**
     * Handles data channel removal confirmation event
     * @param {hf.events.Event} e
     * @protected
     */
    handleFileRemovalConfirmation(e) {
        const files = e.getPayload()['deleted'];

        if (files && files.length > 0) {
            files.forEach(this.onFileRemoval_, this);
        }
    }

    /**
     * Clears the list with the attached files and closes the popup.
     **/
    handleRemoveAllFiles_(e) {
        this.removeAllFiles();
    }

    /**
     * Handles request for preview a just uploaded file
     * Preview can be made for media (audio, video, image)
     * @param {hf.events.Event} e
     * @protected
     */
    handleFilePreview(e) {
        const file = /** @type {hg.data.model.file.FileUpload} */(e.getProperty('file'));

        if (file != null) {
            let content = '';

            this.files_.forEach(function (fileUpload, index) {
                fileUpload = /** @type {hg.data.model.file.FileUpload} */(fileUpload);

                if (fileUpload['error'] == null && fileUpload['version'].getCount() > 0) {
                    const originalViewUri = HgFileUtils.getOriginalUri(fileUpload);

                    content += originalViewUri + '\n';
                }
            });

            /* compute the collection of files that have previews */
            const filePreviews = /** @type {Array.<FileTagMeta>} */(HgMetacontentUtils.decodeFileMetadata(content));
            const filesWithPreviews = new CollectionView({
                'source': filePreviews,
                'filters': function (file) {
                    return /** @type {FileTagMeta} */(file)['mType'] != FileTypes.OTHER;
                }
            });

            /* determine current file */
            const match = filePreviews.find(function (filePreview) {
                return filePreview['id'] == file['fileId'];
            });
            if (match != null) {
                /* use collection view as selector, unfortunately cannot be used entirely as it does not dispatch CHANGE events */
                filesWithPreviews.moveCurrentTo(match);
            }

            const mediaTypes = [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO],
                mediaFiles = this.files_.getAll().filter(function (fileUpload) {
                    fileUpload = /** @type {hg.data.model.file.FileUpload} */(fileUpload);

                    return mediaTypes.includes(fileUpload.get('type'));
                });

            const event = new Event(EditorPluginEventType.DATA_ACTION);
            event.addProperty('files', mediaFiles);
            event.addProperty('currentFile', match['downloadPath']);
            event.addProperty('action', HgFileEditorPlugin.FileActions.PREVIEW);

            if (!this.dispatchEvent(event)) {
                const previewFileDialog = this.getFilePreviewDialog_(filesWithPreviews);
                previewFileDialog.open();
            }
        }
    }
};
/**
 * Action to execute on file
 * @enum {string}
 * @readonly
 */
HgFileEditorPlugin.FileActions = {
    UPLOAD        : 'upload',
    REMOVE        : 'remove',
    PREVIEW       : 'preview',
    CANCEL_UPLOAD : 'cancel_upload'
};

/**
 * Set of button names.
 * @enum {string}
 * @readonly
 */
HgFileEditorPlugin.Buttons = {
    UPLOADED: 'uploaded'
};
/**
 * Maximum allowed file number of a single message
 * @const
 * @type {number}
 */
HgFileEditorPlugin.MAX_FILE_NO_ = 40;