import {ViewModelBase} from "./../../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {FilterOperators} from "./../../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {ListDataSource} from "./../../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {
    FetchCriteria,
    FetchNextChunkPointer
} from "./../../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {SortDirection} from "./../../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {QueryDataResult} from "./../../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {KeyValCollection} from "./../../../../data/model/common/KeyValCollection.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {SearchFilter} from "./../../../../data/model/file/SearchFilter.js";
import {HgAppConfig} from "./../../../../app/Config.js";

import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import TagService from "./../../../../data/service/TagService.js";
import FileService from "./../../../../data/service/FileService.js";

/**
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class GalleryPreviewViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {*}
         * @protected
         */
        this.fileService_;

        /**
         * @type {?string}
         * @protected
         */
        this.lastFetchedCategory_;
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        this.fileService_ = FileService.getInstance();

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.fileService_ = null;
        this.lastFetchedCategory_ = null;
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* context - the context in which the file was uploaded */
        this.addField({'name': 'context'});

        /* contextInfo - context data, i.e. a Topic, or a File, or A Person  data model */
        this.addField({'name': 'contextInfo'});

        /* inThread - the Resource representing the main thread on which this file is attached: board, topic, conversation, person */
        this.addField({'name': 'inThread'});

        /* inThreadInfo - the thread data, i.e. a Topic data model */
        this.addField({'name': 'inThreadInfo'});

        /* mediaPreview - hg.data.model.file.FileMeta */
        this.addField({'name': 'mediaPreview'});

        /* we need to determine how to read these from backend */
        this.addField({'name': 'categories', 'value': new KeyValCollection([
            {'key': FileTypes.OTHER, 'value': 0},
            {'key': FileTypes.IMAGE, 'value': 0},
            {'key': FileTypes.VIDEO, 'value': 0},
            {'key': FileTypes.AUDIO, 'value': 0}
        ])});

        /* FileTypes */
        this.addField({'name': 'currentCategory', 'value': FileTypes.OTHER});

        this.addField({'name': 'searchValue'});

        this.addField({'name': 'searchFilter', 'value': new SearchFilter()});

        /* marker to know if we are or not in search context */
        this.addField({'name': 'searchContext', 'value': false});

        /* cloudTags */
        this.addField({'name': 'cloudTags', 'getter': this.createLazyGetter('cloudTags', () => {
            return new ListDataSource({
                'dataProvider': this.searchSystemTags_.bind(this)
            });
        })});

        /* source of data for the image gallery and the carousel in search mode */
        this.addField({
            'name': 'searchMediaFiles', 'getter': this.createLazyGetter('searchMediaFiles',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.searchForMediaFiles.bind(this),
                        'fetchCriteria': {
                            'fetchSize' : HgAppConfig.DEFAULT_FETCH_SIZE * 2
                        }
                    });
                })
        });

        /* source of data for the image gallery */
        this.addField({
            'name': 'galleryMediaFiles', 'getter': this.createLazyGetter('galleryMediaFiles',
                function () {
                    return new ListDataSource({
                        'dataProvider': this.loadGalleryMediaFiles.bind(this),
                        'initialFetchSizeFactor': 8, /* initial loaded items: 8 * hg.HgAppConfig.DEFAULT_FETCH_SIZE  = 80 */
                        'fetchCriteria': {
                            'fetchSize'         : HgAppConfig.DEFAULT_FETCH_SIZE,
                            'nextChunkPointer'  : FetchNextChunkPointer.START_ITEM,
                            'startItemProperty' : 'created',
                            'sorters'           : [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                        },
                        'localSorters': [{'sortBy': 'created', 'direction': SortDirection.DESC}]
                    });
                })
        });

        /* total number of media files on this thread */
        this.addField({'name': 'mediaFilesNo'});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.lastFetchedCategory_ = this['currentCategory'];
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchSystemTags_(searchCriteria) {
        const tagService = TagService;

        return tagService.searchTags(HgResourceCanonicalNames.FILE, null, searchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    searchForMediaFiles(fetchCriteria) {
        fetchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(fetchCriteria.clone());

        if (this['inThread'] != null) {

            fetchCriteria.filter({
                'filterBy': 'context',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': {
                    'resourceType': this['inThread']['resourceType'],
                    'resourceId': this['inThread']['resourceId']
                }
            });
        }

        fetchCriteria.filter({
            'filterBy': 'type',
            'filterOp': FilterOperators.CONTAINED_IN,
            'filterValue': [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO]
        });

        /* clear preview, escape its context */
        this['mediaPreview'] = null;

        return this.fileService_.searchFilesLikeMediaPreview(fetchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    loadGalleryMediaFiles(fetchCriteria) {
        fetchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(fetchCriteria.clone());

        if (!this.fieldHasValue('galleryMediaFiles') || this['galleryMediaFiles'].getCount() == 0) {
            if (this['mediaPreview']) {
                fetchCriteria.filter({
                    "filterBy": 'fileId',
                    "filterOp": 'neighbors',
                    "filterValue": {
                        'value': this['mediaPreview']['id'],
                        'range': HgAppConfig.DEFAULT_FETCH_SIZE * 4 /* 40 items before + 40 items after */
                    }
                });
            } else {
                /* neighbors on created or fetch last media file from conversation! */
                const lastFileCriteria = new FetchCriteria()
                    .sortBy('created', SortDirection.DESC)
                    .setFetchSize(1);

                if (this['inThread'] != null) {
                    lastFileCriteria.filter({
                        'filterBy': 'context',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': {
                            'resourceType': this['inThread']['resourceType'],
                            'resourceId': this['inThread']['resourceId']
                        }
                    });
                }

                return this.fileService_.getFiles(lastFileCriteria)
                    .then((queryResult) => {
                        const file = queryResult instanceof QueryDataResult && queryResult.getItems().length > 0 ? queryResult.getItems()[0] : null;

                        if (file) {
                            fetchCriteria.filter({
                                "filterBy": 'fileId',
                                "filterOp": 'neighbors',
                                "filterValue": {
                                    'value': file['fileId'],
                                    'range': HgAppConfig.DEFAULT_FETCH_SIZE * 4 /* 40 items before + 40 items after */
                                }
                            });
                        }
                        return this.loadMediaFilesInternal_(fetchCriteria);
                    });
            }
        }

        return this.loadMediaFilesInternal_(fetchCriteria);
    }

    /**
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @protected
     */
    loadMediaFilesInternal_(fetchCriteria) {
        if (this['inThread'] != null) {
            fetchCriteria.filter({
                'filterBy': 'context',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': {
                    'resourceType': this['inThread']['resourceType'],
                    'resourceId': this['inThread']['resourceId']
                }
            });
        }

        if (this['currentCategory'] != null && this['currentCategory'] != FileTypes.OTHER) {
            fetchCriteria.filter({
                'filterBy': 'type',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': this['currentCategory']
            });
        }
        else {
            fetchCriteria.filter({
                'filterBy': 'type',
                'filterOp': FilterOperators.CONTAINED_IN,
                'filterValue': [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO]
            });
        }

        /* update report if lastFetchedCategory_ changed as well */
        const promisesList = [
            this.fileService_.getFilesLikeMediaPreview(fetchCriteria)
        ];

        if (this.lastFetchedCategory_ != this['currentCategory'] || (!fetchCriteria.getStartItem() && fetchCriteria.getStartIndex() == 0)) {
            promisesList.push(this.fileService_.readReport(this['inThread']));
        }
        this.lastFetchedCategory_ = this['currentCategory'];

        return Promise.all(promisesList)
            .then((results) => {
                if (!this.isDisposed() && results.length > 1) {
                    this.updateReport_(results[1]);
                }

                return results[0];
            });
    }

    /**
     * @return {hf.data.ListDataSource}
     * @protected
     */
    getMediaFiles() {
        return this['searchContext'] ? this['searchMediaFiles'] : this['galleryMediaFiles'];
    }

    /**
     * @param {Object} report
     * @private
     */
    updateReport_(report) {
        if(report) {
            const allowedCategories = [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO];
            let total = 0;

            allowedCategories.forEach(function (mType) {
                const count = parseInt(report[mType], 10) || 0;

                this['categories'].setValue(mType.toUpperCase(), count);

                total += count;
            }, this);

            this['categories'].setValue(FileTypes.OTHER, total);
        }
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'currentCategory') {
            this.onCurrentCategoryChange_(newValue, oldValue);
        }

        if(fieldName == 'searchValue' && newValue == null && this['searchContext']) {
            /* do not allow searchValue change in this case, it means component exitDocument */
            this.set('searchValue', oldValue, true);
        }

        if (fieldName == 'searchContext') {
            if (!!newValue && this.fieldHasValue('searchMediaFiles') && this.getFieldValue('searchMediaFiles') != null) {
                this['mediaFilesNo'] = this['searchMediaFiles'].getCount();
            }
            else if (!newValue && this.fieldHasValue('galleryMediaFiles') && this.getFieldValue('galleryMediaFiles') != null) {
                this['mediaFilesNo'] = this['galleryMediaFiles'].getCount();
            }
        }

        if (fieldName == 'searchMediaFiles') {
            this['mediaFilesNo'] = this['searchMediaFiles'].getCount();
        }
    }

    /**
     * Handles the change of the current category filter
     * @param {*} newValue
     * @param {*} oldValue
     * @private
     */
    onCurrentCategoryChange_(newValue, oldValue) {
        if (!StringUtils.isEmptyOrWhitespace(newValue)) {
            if (newValue !== FileTypes.OTHER) {
                /** @type {hf.data.ListDataSource} */(this['galleryMediaFiles']).filter({
                    'filterBy'      : 'type',
                    'filterOp'      : FilterOperators.EQUAL_TO,
                    'filterValue'   : newValue
                });
            } else {
                /* image, video, audio */
                /** @type {hf.data.ListDataSource} */(this['galleryMediaFiles']).filter({
                    'filterBy'      : 'type',
                    'filterOp'      : FilterOperators.CONTAINED_IN,
                    'filterValue'   : [FileTypes.IMAGE, FileTypes.VIDEO, FileTypes.AUDIO]
                });
            }
        }
    }

    /**
     * Discard Advanced Search model changes.
     */
    resetAdvancedSearchFilters() {
        const searchFilter = this['searchFilter'];

        if (searchFilter != null) {
            searchFilter.loadData({}, {'overrideChanges': true});
        }
    }

    /**
     * Executes a search using the current criteria
     */
    clearSearch() {
        this['searchContext'] = false;

        this['searchValue'] = null;
        this.resetAdvancedSearchFilters();
    }

    /**
     * Executes a search using the current criteria
     */
    search() {
        /* 1. obtain the raw first criteria */
        const searchCriteria = this.getSearchCriteria();

        /* 2. clear search criteria model */
        this.resetAdvancedSearchFilters();

        /* 3. run the search */
        if(!this.isSearchCriteriaEmpty(searchCriteria)) {

            this['searchMediaFiles'].load({
                'searchValue'   : searchCriteria['searchValue'],
                'isQuickSearch' : false,
                'filters'       : searchCriteria['filters']
            }).then((result) => {
                if (result) {
                    this['mediaFilesNo'] = result['count'];
                }
            });

            this['searchContext'] = true;
        }
    }

    /**
     * Gets the current search criteria.
     * @returns {Object}
     */
    getSearchCriteria() {
        return {
            'filters': this.getAdvancedSearchFilters(),
            'searchValue': this['searchValue'],
            'isQuickSearch': false
        };
    }

    /**
     *
     * @param {Object} searchCriteria
     * @return {boolean}
     */
    isSearchCriteriaEmpty(searchCriteria) {
        return searchCriteria == null ||
            ((!BaseUtils.isArray(searchCriteria['filters']) || /**@type {Array}*/(searchCriteria['filters']).length == 0) &&
            (StringUtils.isEmptyOrWhitespace(searchCriteria['searchValue'])));
    }

    /**
     * Gets the advanced search filters.
     * @return {Array} The advanced search filters
     * @protected
     */
    getAdvancedSearchFilters() {
        const searchFilters = [];

        if (this['searchFilter'] != null) {
            const advancedSearchModel = this['searchFilter'];

            /* Add 'created' filters */
            const createdStartDate = advancedSearchModel['createdDateRangeStart'],
                createdEndDate = advancedSearchModel['createdDateRangeEnd'];

            if (createdStartDate != null) {
                searchFilters.push({
                    'filterBy'      : 'created',
                    'filterValue'   : createdStartDate,
                    'filterOp'      : FilterOperators.GREATER_THAN_OR_EQUAL_TO
                });
            }

            if(createdEndDate) {
                searchFilters.push({
                    'filterBy'      : 'created',
                    'filterValue'   : createdEndDate,
                    'filterOp'      : FilterOperators.LESS_THAN_OR_EQUAL_TO
                });
            }

            /* Add 'reaction.tag.name' filters */
            const selectedTags = advancedSearchModel['tags'];
            if (selectedTags != null) {
                if (selectedTags.length !== 0) {
                    searchFilters.push({
                        'filterBy'      : 'reaction.tag.name',
                        'filterValue'   : selectedTags,
                        'filterOp'      : FilterOperators.CONTAINED_IN
                    });
                }
            }
        }

        return searchFilters;
    }
};