/**
 * Interface for an editor to be used with the display field on clickToEdit feature
 *
 * @interface
 *
 */
export class IEditor {
    constructor() {
        /**
         * Adds the specified component as the last child of this component.  See
         * {@link hf.ui.UIComponentBase#addChildAt} for detailed semantics.
         *
         * @see hf.ui.UIComponentBase#addChildAt
         * @param {hf.ui.UIComponentBase} child The new child component.
         * @param {boolean=} opt_render If true, the child component will be rendered
         *    into the parent.
         */
        this.addChild;

        /**
         * Gets the name of the view
         *
         * @returns {*}
         */
        this.getValue;

        /**
         * Attaches this view to a presenter.
         * The presenter is the view's 'back-end'
         *
         * @param {*} value
         */
        this.setValue;

        /**
         * Gets the DOM element of the input field (the 'input' tag)
         * The function saves the input field in a class variable the first time when it is calculated
         *
         * @returns {Element|null} It returns null if the element is not rendered or if the input tag does not exist.
         */
        this.getInputElement;

        /**
         * Sets the name of the input field.
         *
         * @param {string} inputName The input field name to be set
         * @throws {Error} If the input is already rendered.
         * @throws {TypeError} If the parameter does not have the right type.
         */
        this.setInputName;

        /**
         * Determines whether the component has been added to the document.
         *
         * @returns {boolean} TRUE if rendered. Otherwise, FALSE.
         */
        this.isInDocument;

        /**
         * @inheritDoc
         */
        this.exitDocument;

        /**
         * Renders the component before another element. The other element should be in
         * the document already.
         *
         * Throws an Error if the component is already rendered.
         *
         * @param {Node} sibling Node to render the component before.
         */
        this.renderBefore;

        /**
         * An optional extra CSS class name to be appended to the list of extra css classes.
         *
         * @param { string | !Array.<string> } extraClass A single extra css class name - represented as a string or more extra css class names - represented as an array of strings
         */
        this.addExtraCSSClass;

        /**
         * Focuses the component.
         * It dispatches UIComponentEventTypes.FOCUS event
         *
         * @param {boolean|number=} opt_delay Number of milliseconds to delay the focus (default 10 milliseconds on true); if it is a boolean, it must be true to focus the component
         * @throws {TypeError} when the parameter is not valid
         */
        this.focus;

        /**
         * Gets the component's element.
         *
         * @returns {Element} The element for the component.
         */
        this.getElement;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IEditor.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IEditor.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.ui.form.field.IEditor.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IEditor.IMPLEMENTED_BY_PROP_ = '__hubfront_ui_form_field_ieditor';
