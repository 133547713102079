import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {ICollection} from "./../../../../../../hubfront/phpnoenc/js/structs/collection/ICollection.js";
import {PopupButton} from "./PopupButton.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 *
 * @enum {string}
 */
export const MenuButtonEventType = {
    /**  */
    MENU_ITEM_ACTION: StringUtils.createUniqueString('hg_button_menu_menu_item_action'),

    /**  */
    OPEN_MENU_PANEL: StringUtils.createUniqueString('hg_button_menu_open_menu_panel')
};

/**
 * Creates a {@see hg.common.ui.button.MenuButton} component.
 *
 * @extends {PopupButton}
 * @unrestricted 
*/
export class MenuButton extends PopupButton {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {!Object=} opt_config.menu The menu configuration object
     *     @param {function(*): ?UIControlContent=} opt_config.itemContentFormatter The formatter function used to generate the content of the menu items.
     *     @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.itemStyle The optional custom CSS class to be added to all the menu items.
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Holds the menu items
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.menu_ = this.menu_ === undefined ? null : this.menu_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.menu_);
        this.menu_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-button-menu';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    getMainExtraCssClass() {
        return 'hg-button-menu';
    }

    /** @inheritDoc */
    setOpen(open) {
        /* do not open the button if there are no menu items */
        if(open && !this.hasMenuItems()) {
            return;
        }

        super.setOpen(open);

    }

    /** @inheritDoc */
    getPopupConfig() {
        const popupConfig = super.getPopupConfig();

        popupConfig['content'] = this.getMenu();

        return popupConfig;
    }

    /** @inheritDoc */
    onOpening() {
        const event = new Event(MenuButtonEventType.OPEN_MENU_PANEL);
        this.dispatchEvent(event);

        const renderParent = /**@type {Element|hf.ui.UIComponentBase}*/(event.getProperty('renderParent')),
            placementTarget = /**@type {hf.ui.UIComponent | Element}*/(event.getProperty('placementTarget')),
            placement = event.getProperty('placement'),
            verticalOffset = event.getProperty('verticalOffset'),
            horizontalOffset = event.getProperty('horizontalOffset');

        const popup = this.getPopup();
        if(popup) {
            popup.setRenderParent(renderParent);
            popup.setPlacementTarget(placementTarget || this);

            if (placement) {
                popup.setPlacement(placement);
            }

            if (verticalOffset) {
                popup.setVerticalOffset(parseFloat(verticalOffset));
            }

            if (horizontalOffset) {
                popup.setHorizontalOffset(parseFloat(horizontalOffset));
            }
        }

        super.onOpening();
    }

    /**
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getMenu() {
        if(!this.menu_) {
            const mainExtraCssClass = this.getMainExtraCssClass(),
                menuConfig = this.getConfigOptions()['menu'] || {};

            this.menu_ =  new UIControl({
                'baseCSSClass'      : mainExtraCssClass + '-' + 'menu',
                'extraCSSClass'		: menuConfig['extraCSSClass'],
                'contentFormatter'  : function(dataItems) {
                    dataItems = dataItems || [];

                    return dataItems.map(function (dataItem) {
                        return new UIControl({
                            'baseCSSClass'		: mainExtraCssClass + '-' + 'menu-item',
                            'extraCSSClass'		: menuConfig['itemStyle'],
                            'contentFormatter'	: menuConfig['itemContentFormatter'],
                            'model'				: dataItem
                        });
                    }, this);
                }
            });

            //this.setBinding(this.menu_, {'set': this.menu_.setModel}, '');

            this.menu_.addListener(UIComponentEventTypes.ACTION, this.handleMenuItemAction_, false, this);
        }

        return this.menu_;
    }

    /**
     *
     * @returns {boolean}
     * @protected
     *
     */
    hasMenuItems() {
        const model = this.getModel();

        if(BaseUtils.isArray(model)) {
            return (/**@type {Array}*/(model).length) > 0;
        }
        else if(ICollection.isImplementedBy(/**@type {Object}*/(model))) {
            return (/**@type {hf.structs.ICollection}*/(model).getCount()) > 0;
        }

        return false;
    }

    /**
     * Handler menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleMenuItemAction_(e) {
        const mainExtraCssClass = this.getMainExtraCssClass(),
            menuItem = e.getTarget();

        if (menuItem instanceof UIControl && menuItem.getBaseCSSClass() == mainExtraCssClass + '-' + 'menu-item') {
            e.stopPropagation();

            const dataItem = (/** @type {hf.ui.UIControl} */(menuItem)).getModel();
            if (dataItem == null) {
                return;
            }

            const event = new Event(MenuButtonEventType.MENU_ITEM_ACTION, menuItem);
            event.addProperty('dataItem', dataItem);

            this.dispatchEvent(event);

            this.setOpen(false);
        }
    }
};