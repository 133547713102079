import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {FormButton} from "./../../../../../../hubfront/phpnoenc/js/ui/form/Button.js";
import {ToggleButton} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ToggleButton.js";
import {DialogButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {HgUIEventType} from "./../events/EventType.js";
import {ChatThreadActions} from "./../../enums/Enums.js";
import {UploadFileButton} from "./UploadFileButton.js";
import {CopyToClipboardButton} from "./CopyToClipboardButton.js";
import {URLButton} from "./LinkButton.js";
import {HgStringUtils} from "./../../string/string.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @unrestricted 
*/
export class HgButtonUtils {
    constructor() {
        //	
    }

    /**
     * Generic function that returns a button instance with toggle or default type according to parameters value
     * @param {hg.HgButtonUtils.ButtonCSSClass|null} baseCSSClass The CSS class of the new button.
     * @param {?string=} opt_extraCSSClassKey The optional extra CSS class key to append to the base CSS class and add as extra class.
     * @param {?boolean=} opt_isToggle True if the button to instantiate is a toggle button. It defaults to false
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button|hf.ui.ToggleButton}
     * @private
     */
    static generateButton_(baseCSSClass, opt_extraCSSClassKey, opt_isToggle, opt_config = {}) {
        let button;

        if (opt_isToggle != null && opt_isToggle) {
            button = new ToggleButton(opt_config);
        } else {
            button = new Button(opt_config);
        }

        if (baseCSSClass !== null) {
            button.setBaseCSSClass(baseCSSClass);
        }

        if (opt_extraCSSClassKey != null && baseCSSClass !== null) {
            button.addExtraCSSClass(baseCSSClass + "-" + opt_extraCSSClassKey);
        }

        return button;
    }

    /**
     * Merges the parameters into a button config object
     *
     * @param {?string} typeCSS The CSS class used to denote de type of the button. {@see hg.HgButtonUtils.ButtonCSSClass}
     * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
     * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
     * @param {?Object=} opt_config Extra config params.
     *
     * @return {!Object}
     *
     * @private
     */
    static createConfig_(typeCSS, opt_extraCSS, opt_content, opt_config = {}) {
        if (typeCSS) {
            opt_config['extraCSSClass'] = opt_extraCSS ? [].concat(typeCSS, opt_extraCSS) : [typeCSS];
        } else {
            opt_config['extraCSSClass'] = opt_extraCSS;
        }

        if (BaseUtils.isFunction(opt_content)) {
            opt_config['contentFormatter'] = opt_content;
        } else {
            opt_config['content'] = opt_content;
        }

        return opt_config;
    }

    /**
     * Creates a button with the given params.
     * @param {?string} typeCSS The CSS class used to denote de type of the button. {@see hg.HgButtonUtils.ButtonCSSClass}
     * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
     * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
     * @param {?boolean=} opt_toggle True to create a toggle button
     * @param {?Object=} opt_config Extra config params.
     * @returns {!hf.ui.Button|hf.ui.ToggleButton}
     * @protected
     */
    static createButton_(typeCSS, opt_extraCSS, opt_content, opt_toggle, opt_config = {}) {
        const config = HgButtonUtils.createConfig_(typeCSS, opt_extraCSS, opt_content, opt_config);

        if (opt_toggle) {
            return new ToggleButton(config);
        } else {
            return new Button(config);
        }
    }

    /**
     * Creates a form submit button
     * @param {?string=} opt_extraCSS
     * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content
     * @param {?Object=} opt_config
     * @returns {hf.ui.Button}
     */
    static createSubmit(opt_extraCSS, opt_content, opt_config = {}) {
        const config = HgButtonUtils.createConfig_(
            HgButtonUtils.ButtonCSSClass.SUBMIT_BUTTON, opt_extraCSS, opt_content, opt_config);

        return new FormButton(config);
    }

    /**
     * Function that returns a 'post message' button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button|hf.ui.ToggleButton}
     */
    static createPostMessageButton(opt_config = {}) {
        const translator = Translator;


        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] ?
            [].concat(HgButtonUtils.ButtonCSSClass.POST_MESSAGE_BUTTON, opt_config['extraCSSClass']) : [HgButtonUtils.ButtonCSSClass.POST_MESSAGE_BUTTON];

        opt_config['tooltip'] = opt_config['tooltip'] || {};

        opt_config['tooltip']['content'] = opt_config['tooltip']['content'] || translator.translate('post_message');
        opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_MIDDLE;
        opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['verticalOffset'] || -1;

        return new Button(opt_config);
    }

    /**
     * Function that returns an 'upload file' button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hg.common.ui.button.UploadFileButton}
     */
    static createUploadFileButton(opt_config = {}) {
        const translator = Translator;

        /* By default there is a tooltip, unless thde config option for tooltip is null */
        if (opt_config['tooltip'] !== null) {
            opt_config['tooltip'] = opt_config['tooltip'] || {};
            opt_config['tooltip']['content'] = opt_config['tooltip']['content'] || translator.translate('upload');
            opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_MIDDLE;
            opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['verticalOffset'] || -1;
        }

        return new UploadFileButton(opt_config);
    }

    /**
     * Function that returns a 'copy to clipboard' button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hg.common.ui.button.CopyToClipboardButton}
     */
    static createCopyToClipoboardButton(opt_config = {}) {
        return new CopyToClipboardButton(opt_config);
    }

    /**
     * Function that returns a lookalike link button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hg.common.ui.button.LinkButton}
     */
    static createUrlButton(opt_config = {}) {
        return new URLButton(opt_config);
    }

    /**
     * Function that returns a lookalike link button
     * The primary button is the bigger, more visible button that can confirm/cancel the form action.
     * @param {?string=} opt_extraCSSClassKey The optional extra CSS class key to append to the base CSS class and add as extra class.
     * @param {?boolean=} opt_isToggle True if the button to instantiate is a toggle button. It defaults to false
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button|hf.ui.ToggleButton}
     */
    static createLinkButton(opt_extraCSSClassKey = null, opt_isToggle = false, opt_config = {}) {
        let button = new Button(opt_config);

        let extraCssClass = FunctionsUtils.normalizeExtraCSSClass(opt_extraCSSClassKey, HgButtonUtils.ButtonCSSClass.LINK_BUTTON);

        button.addExtraCSSClass(extraCssClass);

        return button;
    }

    /**
     * Function that returns a 'close'(X) button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button}
     */
    static createCloseButton(opt_config = {}) {
        const translator = Translator;

        /* extra css class */
        opt_config['extraCSSClass'] = opt_config['extraCSSClass'] ?
            [].concat(HgButtonUtils.ButtonCSSClass.CLOSE_BUTTON, opt_config['extraCSSClass']) : [HgButtonUtils.ButtonCSSClass.CLOSE_BUTTON];

        opt_config['tooltip'] = null;
        /* loader - busy indicator */
        if (opt_config['loader'] !== null) {
            opt_config['loader'] = opt_config['loader'] || {};

            opt_config['loader']['type'] = Loader.Type.CIRCULAR_LINE;
            opt_config['loader']['size'] = Loader.Size.XSMALL;
        }

        return new Button(opt_config);
    }

    /**
     * Function that returns a delete button that is hosted by a list item (e.g. forward recipients)
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button}
     */
    static createListItemDeleteButton(opt_config = {}) {
        const translator = Translator;

        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], HgButtonUtils.ButtonCSSClass.LIST_ITEM_DELETE_BUTTON);

        if (opt_config['tooltip'] !== null) {
            opt_config['tooltip'] = opt_config['tooltip'] || {};

            opt_config['tooltip']['content'] = opt_config['tooltip']['content'] || translator.translate('remove');
            opt_config['tooltip']['placement'] = opt_config['tooltip']['placement'] || PopupPlacementMode.TOP_MIDDLE;
            opt_config['tooltip']['verticalOffset'] = opt_config['tooltip']['verticalOffset'] || -1;
        }

        /* loader - busy indicator */
        if (opt_config['loader'] !== null) {
            opt_config['loader'] = opt_config['loader'] || {};

            opt_config['loader']['type'] = Loader.Type.CIRCULAR_LINE;
            opt_config['loader']['size'] = Loader.Size.XSMALL;
        }

        return new Button(opt_config);
    }

    /**
     * Function that returns a 'label' button
     * @param {!Object=} opt_config Optional configuration object for a button
     * @return {hf.ui.Button}
     */
    static createStatusButton(opt_config = {}) {
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], HgButtonUtils.ButtonCSSClass.STATUS_BUTTON);

        return new Button(opt_config);
    }

    /**
     * Function that returns a button set with a primary and a secondary button.
     * @param {string} primaryCaption The content of the primary button.
     * @param {string} secondaryCaption The content of the secondary button.
     * @return {DialogButtonSet}
     */
    static createPrimarySecondaryButtonSet(primaryCaption, secondaryCaption) {
        const btnSet = new DialogButtonSet();

        btnSet.addButton(HgButtonUtils.createSecondaryButton(null, secondaryCaption, false, {
            'name': HgButtonUtils.ButtonSetName.SECONDARY_BUTTON
        }));
        btnSet.addButton(HgButtonUtils.createPrimaryButton(null, primaryCaption, false, {
            'name': HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        return btnSet;
    }

    /**
     * Function that returns a button set with a dismiss button.
     * @param {string=} opt_caption
     * @return {DialogButtonSet}
     */
    static createDismissButtonSet(opt_caption) {
        const btnSet = new DialogButtonSet(),
            translator = Translator;

        opt_caption = translator.translate(opt_caption || 'Cancel');

        btnSet.addButton(HgButtonUtils.createSecondaryButton(null, opt_caption, false, {
            'name': HgButtonUtils.ButtonSetName.DISMISS_BUTTON
        }));

        return btnSet;
    }

    /**
     * Function that returns a custom button for communication actions.
     *
     * @param {!ContactModes} contactMode
     * @param {boolean} isActive
     * @param {hg.HgButtonUtils.ButtonSize} size
     * @param {boolean=} opt_toggle
     * @param {!Object=} opt_config
     * @return {hf.ui.ToggleButton|hf.ui.Button}
     */
    static createContactButton(contactMode, isActive, size, opt_toggle, opt_config = {}) {

        opt_config['disabled'] = !isActive;

        const extraCSSClasses = ['hg-contact-button', 'hg-contact-button-' + contactMode, size],
            contactButton = HgButtonUtils.create(extraCSSClasses, null, opt_toggle, opt_config);

        contactButton.addListener(UIComponentEventTypes.ACTION, function (e) {
            const target = /** @type {hf.ui.Button} */ (e.getTarget()),
                interlocutor = target.getModel();

            if (interlocutor != null) {
                const contactEvent = new Event(HgUIEventType.CONTACT_ACTION, target);

                contactEvent.addProperty('payload', {
                    'contactMode': contactMode,
                    'interlocutor': interlocutor,

                    'origin': target,

                    'placementTarget': target,
                    'placement': PopupPlacementMode.BOTTOM_MIDDLE
                });

                target.dispatchEvent(contactEvent);
            }
        }, false);

        return contactButton;
    }

    /**
     * Function that returns a custom button as trigger that opens a thread in chat.
     * @param {boolean} isActive
     * @param {HgButtonUtils.ButtonSize} size
     * @param {boolean=} opt_toggle
     * @param {!Object=} opt_config
     * @return {ToggleButton|Button}
     */
    static createOpenThreadInChatButton(isActive, size, opt_toggle, opt_config = {}) {

        opt_config['disabled'] = !isActive;

        const extraCSSClasses = ['hg-open-thread-button', size],
            openThreadButton = HgButtonUtils.create(extraCSSClasses, null, opt_toggle, opt_config);

        openThreadButton.addListener(UIComponentEventTypes.ACTION, function (e) {
            const target = /** @type {hf.ui.Button} */ (e.getTarget()),
                thread = target.getModel();

            if (thread != null) {
                const threadActionEvent = new Event(HgUIEventType.THREAD_ACTION, target);
                threadActionEvent.addProperty('payload', {
                    'action': ChatThreadActions.OPEN_IN_CHAT,
                    'thread': thread
                });

                target.dispatchEvent(threadActionEvent);
            }
        }, false);

        return openThreadButton;
    }

    /**
     * Factory method for buttons badge
     * @param {number|string} count
     * @param {number=} opt_digitsCount Defaults to 2
     * @return {!Element}
     */
    static createCounterBadge(count, opt_digitsCount) {
        return DomUtils.createDom('span', HgButtonUtils.ButtonCSSClass.BUTTON_BADGE,
            HgStringUtils.formatNotificationsCount(count, opt_digitsCount));
    }
}

/**
 * @enum {number}
 * @readonly
 */
HgButtonUtils.DisplayLayout = {
	/** buttons in sets will be displayed inline when possible */
	INLINE : 0,

	/** actions in sets will be displayed in bubble control */
	BUBBLE : 1
};

/**
 * Set of button CSS classes.
 * @enum {string}
 */
HgButtonUtils.ButtonCSSClass = {
	/* The CSS class for buttons that appear in the toolbar region of the app */
	TOOLBAR_BUTTON: 'hg-toolbar-button',

	/* The CSS class for buttons that open the advanced search popup */
	TOOLBAR_ADVANCED_SEARCH: 'hg-toolbar-advanced-search-btn',

	/* The CSS class for the facet filter buttons */
	FACET_BUTTON: 'hg-button-facet',

	/* The CSS class for the primary button - usually in a form */
	PRIMARY_BUTTON: 'hg-button-primary',

	/* The CSS class for the secondary button - usually in a form */
	SECONDARY_BUTTON: 'hg-button-secondary',

	/* The CSS class for the a link button */
	LINK_BUTTON: 'hg-button-link',

	SUBMIT_BUTTON: 'hg-button-submit',

	/* The CSS class for the 'post message' button */
	POST_MESSAGE_BUTTON: 'hg-button-post-message',

	/* The CSS class for the 'upload file' button */
	UPLOAD_FILE_BUTTON: 'hg-button-upload',

	/* The CSS class for the 'emoticon' button */
	EMOTICON_BUTTON: 'hg-button-emoticon',

	/* The CSS class for the 'giphy' button */
	GIPHY_BUTTON: 'hg-button-giphy',

    /* The CSS class for the 'open mini' button */
	OPEN_MINI_BUTTON: 'hg-button-open-mini',

    /* The CSS class for the 'copy to clipboard' button */
	COPY_TO_CLIPBOARD_BUTTON: 'hg-button-copy-to-clipboard',

	/* The CSS class for the 'clear text' button */
	CLEAR_TEXT_BUTTON: 'hg-button-clear-text',

	/* The CSS class for the 'close' (X) button */
	CLOSE_BUTTON: 'hg-button-close',

	/* The CSS class for 'status' button - the ones that are used for statuses or for screen sharing for example */
	STATUS_BUTTON: 'hg-button-status',

	/**/
	LIST_ITEM_DELETE_BUTTON: 'hg-list-item-delete-button',

    BUTTON_BADGE: 'hg-button-badge'
};

/**
 * Set of button CSS classes.
 * @enum {string}
 */
HgButtonUtils.ButtonSetName = {
	/* The name of the primary button in a button set */
	PRIMARY_BUTTON: 'btn-primary',

	/* The name of the secondary button in a button set */
	SECONDARY_BUTTON: 'btn-secondary',

	/* The name of the dismiss button in a button set */
	DISMISS_BUTTON: 'btn-dismiss'
};

/**
 * List of size that can be set on a button
 * @enum {string}
 * @readonly
 */
HgButtonUtils.ButtonSize = {
	SMALL	: 'small',
    MEDIUM  : 'medium',
	LARGE	: 'large'
};

/**
 * List of generic actions
 * @enum {string}
 * @readonly
 */
HgButtonUtils.GenericActionsButtonCSSClass = {
	VIEW    		: 'hg-button-view',
	EDIT			: 'hg-button-edit',
	DELETE			: 'hg-button-delete',
	CONTENT_VIEW	: 'hg-button-content-view',
	CONTENT_EDIT	: 'hg-button-content-edit',
	CONTENT_DELETE	: 'hg-button-content-delete'
};

/**
 * Creates a button with the given params.
 * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
 * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
 * @param {?boolean=} opt_toggle True to create a toggle button
 * @param {?Object=} opt_config Extra config params.
 * @returns {!hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.create = HgButtonUtils.createButton_.bind(null, null);

/**
 * Creates a toolbar button with the given params.
 * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
 * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
 * @param {?boolean=} opt_toggle True to create a toggle button
 * @param {?Object=} opt_config Extra config params.
 * @returns {!hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createToolbarButton = HgButtonUtils.createButton_.bind(null, HgButtonUtils.ButtonCSSClass.TOOLBAR_BUTTON);

/**
 * Creates a secondary button with the given params.
 * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
 * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
 * @param {?boolean=} opt_toggle True to create a toggle button
 * @param {?Object=} opt_config Extra config params.
 * @returns {!hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createSecondaryButton = HgButtonUtils.createButton_.bind(null, HgButtonUtils.ButtonCSSClass.SECONDARY_BUTTON);

/**
 * Creates a primary button with the given params.
 * @param {?(string|Array.<string>)=} opt_extraCSS Extra CSS classes to add on the button
 * @param {?(UIControlContent|function(*): UIControlContent)=} opt_content The content of this button
 * @param {?boolean=} opt_toggle True to create a toggle button
 * @param {?Object=} opt_config Extra config params.
 * @returns {!hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createPrimaryButton = HgButtonUtils.createButton_.bind(null, HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON);

/**
 * Function that returns an advanced search toolbar button
 * @param {?string=} opt_extraCSSClassKey The optional extra CSS class key to append to the base CSS class and add as extra class.
 * @param {?boolean=} opt_isToggle True if the button to instantiate is a toggle button. It defaults to false
 * @param {!Object=} opt_config Optional configuration object for a button
 * @return {hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createAdvancedSearchToolbarButton = HgButtonUtils.generateButton_.bind(null, HgButtonUtils.ButtonCSSClass.TOOLBAR_ADVANCED_SEARCH);

/**
 * Function that returns a facet button.
 * @param {?string=} opt_extraCSSClassKey The optional extra CSS class key to append to the base CSS class and add as extra class.
 * @param {?boolean=} opt_isToggle True if the button to instantiate is a toggle button. It defaults to false
 * @param {!Object=} opt_config Optional configuration object for a button
 * @return {hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createFacetButton = HgButtonUtils.generateButton_.bind(null, HgButtonUtils.ButtonCSSClass.FACET_BUTTON);

/**
 * Function that returns a default button
 * @param {?boolean=} opt_isToggle True if the button to instantiate is a toggle button. It defaults to false
 * @param {!Object=} opt_config Optional configuration object for a button
 * @param {!Object=} opt_config Optional configuration object for a button
 * @return {hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createStandardButton = HgButtonUtils.generateButton_.bind(null, null, null);

/**
 * Function that returns a 'clear text' button
 * @param {!Object=} opt_config Optional configuration object for a button
 * @return {hf.ui.Button|hf.ui.ToggleButton}
 */
HgButtonUtils.createClearTextButton = HgButtonUtils.createButton_.bind(null, HgButtonUtils.ButtonCSSClass.CLEAR_TEXT_BUTTON, '', '', false);