import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";

import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {User} from "./../model/user/User.js";
import {UserReport} from "./../model/user/UserReport.js";
import {UserAddStatus, UserStatus} from "./../model/user/Enums.js";
import {CreateBulkStatus} from "./../../common/enums/Enums.js";
import {UserDataMapping} from "./datamapping/User.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new User service
 * @extends {AbstractService}
 * @unrestricted 
*/
class UserService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Resend invitation to a team member
     * @param {string} userId The unique identifier of the team member
     * @return {Promise}
     */
    reinviteUser(userId) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/reinvite/',
                'withCredentials': true
            }
        });
        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'userId': userId}), "invite_member_error");
    }

    /**
     * Resend invitation to a team member
     * @return {Promise}
     */
    readUserReport() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/report/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.GET), 'cannot_load_members')
            .then((response) => {
                return new UserReport(response);
            });
    }

    /**
     * Load users details list.
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch users on.
     * @return {Promise}
     */
    loadUsers(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'            : DataProxyType.REST,
                'endpoint'        : this.getEndpoint(),
                'dataMapper'      : UserDataMapping.User,
                'withCredentials' : true
            }
        });

        return this.handleErrors(dataPortal.load(User, fetchCriteria), "load_members_failure");
    }

    /**
     * Searches in the list of Users by a provided criteria.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    searchUsers(fetchCriteria) {
        if(!(fetchCriteria instanceof FetchCriteria)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('users_search_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'dataMapper': UserDataMapping.User,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(User, fetchCriteria), 'users_search_failure');
    }

    /**
     * Invite one or more users to Hubgets
     * @param {hg.data.model.user.UserCollection} usersCollection
     * @return {Promise}
     */
    inviteUsers(usersCollection) {
        const translator = Translator;
        
        const users = [];

        usersCollection.forEach(function(userModel) {
            if(userModel.isSavable()) {
                users.push(userModel.toJSONObject({
                    'excludeUnchanged': true,
                    'excludeNonPersistable': true
                }));
            }
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/bulk/',
                'dataMapper': UserDataMapping.User,
                'withCredentials': true
            }
        });

        const promisedResult = users.length > 0 ? dataPortal.invoke(HTTPVerbs.POST, null, users) : Promise.resolve(null);

        return this.handleErrors(promisedResult, "invite_members_error")
            .then((result) => {
                this.dispatchAppEvent(HgAppEvents.USER_INVITED);

                const invitationResult = {
                    'invitedCandidatesCount': users.length,
                    'added': [],
                    'invited': [],
                    'duplicate': [],
                    'limit': [],
                    'error': []
                };

                if(BaseUtils.isArray(result)) {
                    result.forEach(function(invitedUser) {
                        const email = invitedUser['emailBackup'],
                            userModel = usersCollection.getUserByEmail(email);

                        if(userModel != null) {
                            switch(invitedUser['status']) {
                                /* user added successfully */
                                case CreateBulkStatus.ADDED:
                                    invitationResult['added'].push(email);
                                    
                                    usersCollection.remove(userModel);
                                    break;

                                /* already invited user */
                                case UserAddStatus.INVITED:
                                    invitationResult['invited'].push(email);
                                    break;

                                /* existing HG user */
                                case UserAddStatus.DUPLICATE:
                                    invitationResult['duplicate'].push(email);
                                    break;

                                /* reached limit of users/emails per hour */
                                case UserAddStatus.LIMIT:
                                    invitationResult['limit'].push(email);
                                    break;

                                /* any other error */
                                case CreateBulkStatus.ERROR:
                                    invitationResult['error'].push(email);
                                    break;
                            }
                        }
                    });
                }
                return invitationResult;
            });
    }

    /**
     * Update a user from list.
     * @param {hg.data.model.user.User} user The user model to be updated.
     * @return {Promise}
     */
    saveUser(user) {
        const translator = Translator;

        if (user == null) {
            return Promise.reject(new Error(translator.translate("update_member_failure")));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'            : DataProxyType.REST,
                'endpoint'        : this.getEndpoint(),
                'dataMapper'      : UserDataMapping.User,
                'withCredentials' : true
            }
        });

        return this.handleErrors(dataPortal.save(user), "update_member_failure")
            .then((saveResult) => {
                const userWasAdded = saveResult.created.length > 0,
                    changedUser = userWasAdded ? saveResult.created[0] : saveResult.updated[0];

                return changedUser;
            });
    }

    /**
     * Changes the user status.
     * The following transitions are allowed on status:
     * - ACTIVE -> DISABLED
     * - DISABLED -> ACTIVE
     *
     * @param {hg.data.model.user.User} user The user whose status is changed
     * @return {Promise}
     */
    toggleUserStatus(user) {
        const translator = Translator;

        let promisedResult;

        if (user != null && user['status'] != null) {
            const userStatus =
                user['status'] == UserStatus.ACTIVE ?
                    UserStatus.DISABLED :
                    UserStatus.ACTIVE;

            user.setBusy(true);

            const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/' + user['userId'] + '/',
                    'withCredentials': true
                }
            });

            promisedResult = dataPortal.invoke(HTTPVerbs.PUT, null, {'status': userStatus})
                .then((result) => {
                    user['status'] = userStatus;
                    user.acceptChanges(true);

                    return user;
                })
                .finally(() => {
                    user.setBusy(false);
                });
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('member_status_failure'))), 'member_status_failure');
    }

    /**
     * Removes an member from the team list.
     * @param {string} userIdToDelete The userId of the user that should be removed
     * @return {Promise}
     */
    deleteUser(userIdToDelete) {
        const dataPortal = DataPortal.createPortal({
            'proxy'     : {
                'type'            : DataProxyType.REST,
                'endpoint'        : this.getEndpoint(),
                'withCredentials' : true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, [userIdToDelete]), 'member_cannot_delete');
    }

    /** @inheritDoc */
    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/user';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new UserService();

export default instance;