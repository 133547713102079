import {ApplicationEventType} from "./../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Dialog} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {DailyMoodInquiryViewmodel} from "./ViewModel.js";
import {DailyMoodInquiryView} from "./View.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {AppDataCategory, AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import PresenceService from "./../../../data/service/PresenceService.js"
import AppDataService from "./../../../data/service/AppDataService.js";

/**
 * Creates a new {@see hg.module.global.presenter.DailyMoodInquiryPresenter} presenter.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class DailyMoodInquiryPresenter extends BasePresenter {
    constructor() {
        /* Call the base class constructor */
        super();
    }

    /**
     * Updates the current user mood.
     */
    submit() {
        const model = this.getModel(),
            presenceService = PresenceService;

        if(presenceService != null && model != null && model['userMood'] != null) {
            presenceService.updateUserMood(/**@type {PresenceUserMood}*/(model['userMood']))
                .then((result) => {
                    /* Updates the last mood inquiry date. */
                    this.updateLastDailyInquiry_();

                    return result;
                })
                .finally(() => this.closeDialog());
        }
        else {
            /* Updates the last mood inquiry date. */
            this.updateLastDailyInquiry_();

            this.closeDialog();
        }
    }

    /**
     */
    cancel(opt_close) {
        /* Updates the last mood inquiry date. */
        this.updateLastDailyInquiry_();

        this.closeDialog();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        BaseUtils.dispose(this.dialog_);
        this.dialog_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHOW_DAILY_MOOD_INQUIRY, this.handleShowDailyMoodInquiry_)
            .listen(eventBus, [ApplicationEventType.APP_SHOW, ApplicationEventType.APP_ACTIVE], this.handleAppCoreActive_)
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        super.listenToModelEvents(model);

        if(model != null) {
            this.getModelEventHandler()
                .listen(/** @type {hf.events.Listenable} */ (model), ObservableChangeEventName, this.handleModelInternalChange_);
        }
    }

    /** @inheritDoc */
    loadView() {
        return new DailyMoodInquiryView();
    }

    /** @inheritDoc */
    showView(opt_displayRegion) {
        //nop
    }

    /**
     *
     * @returns {hf.ui.Dialog}
     */
    getDialog() {
        if(this.dialog_ == null) {
            this.dialog_ = new Dialog({
                'extraCSSClass': 'hg-daily-mood-inquiry-dialog',
                'isModal': true,
                'closeOnBackgroundClick': false,
                'hasCloseButton': false,
                'closeOnEscape': false,
                'body': this.getView()
            });

            this.dialog_.addListener(UIComponentEventTypes.CLOSE, this.onCloseDialog_, false, this);
        }

        return this.dialog_;
    }

    /**
     * @protected
     */
    openDialog() {
        this.getDialog().open();
    }

    /**
     * @protected
     */
    closeDialog() {
        this.getDialog().close();
    }

    /**
     * Load model (empty AuthObject)
     * @protected
     */
    loadModel() {
        const viewmodel = new DailyMoodInquiryViewmodel({});

        this.setModel(viewmodel);    
    }

    /**
     * Updates the last mood inquiry date.
     * @private
     */
    updateLastDailyInquiry_() {
        const appDataService = AppDataService,
            currentDate = new Date();

        appDataService.updateAppDataParam(AppDataCategory.GLOBAL,
            AppDataGlobalKey.LAST_MOOD_INQUIRY,
            currentDate, false, true);
    }

    /**
     * Handles changes on header model
     * @param {hf.events.Event} e
     * @private
     */
    handleModelInternalChange_(e) {
        const model = this.getModel();

        if(e && e['payload'] && model != null) {
            const payload = e['payload'];

            if(payload['field'] == 'userMood') {
                this.submit();
            }
        }
    }

    /**
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleShowDailyMoodInquiry_(e) {
        this.loadModel();

        this.openDialog();
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!hf.app.AppEvent} e
     * @protected
     */
    handleAppCoreActive_(e) {
        const model = this.getModel();
        if(model != null) {
            model['message'] = undefined;
        }
    }

    /**
     * @param {hf.events.Event} e
     */
    onCloseDialog_(e) {
        this.clearError();
        this.markIdle();
        this.setModel(null);
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.module.global.presenter.DailyMoodInquiryPresenter);
/**
 * @type {hf.ui.Dialog}
 * @private
 */
DailyMoodInquiryPresenter.dialog_;