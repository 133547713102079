import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * General events dispatched by ui components
 * @enum {string}
 * @readonly
 */
export const MediaPreviewEventType = {
    /**
     * @event MediaPreviewEventType.MEDIA_FILE_SELECT
     */
    MEDIA_FILE_SELECT: StringUtils.createUniqueString('__hg_module_media_events_media_file_select'),

    /**
     * @event MediaPreviewEventType.CLOSE_MEDIA_FILE_VIEW
     */
    CLOSE_MEDIA_FILE_VIEW: StringUtils.createUniqueString('__hg_module_media_events_close_media_file_view')
};

/**
 * @enum {string}
 */
export const MediaPreviewButtonType = {
    HG_VIEW         : 'hg-view',
    FULL_SCREEN_VIEW: 'fs-view',
    SPLIT_VIEW      : 'split-view',
    MEDIA_GALLERY   : 'media-gallery',
    CLOSE           : 'close',
    NEXT            : 'next',
    PREV            : 'prev',
    ROTATE_LEFT     : 'rotate-left',
    ROTATE_RIGHT    : 'rotate-right',
    DOWNLOAD        : 'download',
    UPLOAD          : 'upload',
    SHARE           : 'share',
    INFO            : 'info',
    DELETE          : 'delete',
    REMOVE          : 'remove',
    COMMENT         : 'comment',
    LIKE            : 'like'
};

/**
 * Set of supported preview context
 * @enum {string}
 */
export const MediaPreviewContext = {
    /* preview files just uploaded (still in editor) */
    INTERNAL        : 'internal',
    LINK_PREVIEW    : 'link_preview'
};

/**
 * Set of display mode for the thread album
 * @enum {string}
 */
export const MediaPreviewDisplayMode = {
    /* Full screen image preview */
    PREVIEW: 'media_file_view',

    /* Media gallery */
    GALLERY: 'media_gallery'
};

/**
 * Set of busy contexts used on async request to media viewmodel
 * @enum {string}
 * @readonly
 */
export const MediaPreviewBusyContext = {
    CONTEXT_CHANGE: 'context-change',

    LOAD_FILE: 'load-file'
};