import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

/**
 * @unrestricted 
*/
export class VisitorDataMapping {
    constructor() {
      //
    }
};

/**
 * Mapping template for QuickConnectInvitation data model: used in Write operation
 * @type {Object}
 * @readonly
 */
VisitorDataMapping.QuickConnectInvitation = {
    'write': {
        'path'      : '',
        'field'  : {
            'connectInvitationId': 'connectInvitationId',
            'name'               : 'name',
            'email'              : 'email',
            'visitor'            : function(quickInvitation) {
                return {
                    'visitorId' : quickInvitation['visitorId'],
                    'name'      : quickInvitation['name'],
                    'email'     : quickInvitation['email'],
                    'personId'  : quickInvitation['personId']
                };
            },
            'message'            : function(quickInvitation) {
                return {
                    'subject'   : quickInvitation['subject'],
                    'body'      : quickInvitation['message']
                }
            },
            'globalUserId'        : 'globalUserId'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */

VisitorDataMapping.ConnectInvitation = {
    'read': {
        'path'      : '',
        'field'  : {
            'connectInvitationId': 'connectInvitationId',
            'thread'             : 'thread',
            'author'             : 'author',
            'recipient'          : 'recipient',
            'status'             : 'status',
            'reinvited'          : 'reinvited',
            'reinviteUpdated'    : 'reinviteUpdated',
            'reported'           : 'reported',
            'privacy'            : 'privacy',
            'visitor'            : 'visitor',
            'message'            : 'message',
            'gRecaptchaResponse' : 'gRecaptchaResponse',
            'globalUserId'       : 'globalUserId',
            'created'            : 'created',
            'updated'            : 'updated',
            'accepted'           : 'accepted',
            'publicProfileId'    : 'publicProfileId'
        }
    }
};

/**
 *
 * @type {Object}
 * @readonly
 */

VisitorDataMapping.ConnectSession = {
    'read': {
        'path'      : '',
        'field'  : {
            'connectInvitationId': 'connectInvitationId',
            'visitor'            : 'visitor',
            'user'               : 'user',
            'thread'             : 'thread',
            'status'             : 'status',
            'token'              : 'token',
            'expires'            : 'expires',
            'communication'      : function(rawSession) {
                const result = {},
                    arrayPayload = rawSession['communication'];

                if(BaseUtils.isArray(arrayPayload)) {
                    arrayPayload.forEach(function(item) {
                        return result[item['key']] = item['value'];
                    });
                }

                return result;
            },
            'uri'            : function(rawSession) {
                const result = {},
                    arrayPayload = rawSession['uri'];

                if(BaseUtils.isArray(arrayPayload)) {
                    arrayPayload.forEach(function(item) {
                        return result[item['key']] = item['value'];
                    });
                }

                return result;
            }
        }
    }
};

/**
 * Mapping template for visitor's presence.
 * @type {Object}
 * @readonly
 */
VisitorDataMapping.Presence = {
    'read': {
        'path'      : '',
        'field'  : {
            'visitorId'  : 'visitorId',
            'status'     : 'status',
            'lastSeen'   : 'lastSeen',
            'deviceState': function(presence) {
                return {
                    'type': presence['deviceType'],
                    'place': presence['place']                    
                };
            },
            'updated'    : 'updated'
        }
    }
};