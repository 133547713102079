import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {GoodByeView} from "./../view/GoodBye.js";
import AuthService from "../../../data/service/AuthService.js";
import AppDataService from "./../../../data/service/AppDataService.js";

/**
 * Creates a new presenter for the init app state.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class GoodByePresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.GOOD_BYE;
    }

    /** @inheritDoc */
    loadView() {
        return new GoodByeView();
    }

    /** @inheritDoc */
    async onStartup() {
        super.onStartup();

        try {
            await this.saveUserBehaviour_();

        } catch (err) {
            // log the error
        }

        AuthService.logout();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.LOGOUT_SUCCESSFUL, this.handleLogoutSuccessful);
    }

    /**
     * Store user behaviour for restoring on next login
     *
     * @return {Promise}
     * @private
     */
    saveUserBehaviour_() {
        try {
            AppDataService.clearLocalStorage();

            return AppDataService.sync();
        } catch (err) {
            // only log the error
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @protected
     */
    handleLogoutSuccessful(e) {
        this.dispatchEvent(HgAppEvents.RELOAD_APP);
    }
}