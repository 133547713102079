import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {PhoneEventType} from "./../../Common.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class IncomingCallAlert extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.notice_ = new Caption({
            'contentFormatter': function(remoteVideo) {
                const content = document.createDocumentFragment();

                content.appendChild(DomUtils.createDom('div', 'hg-call-type', translator.translate(!!remoteVideo ? 'Incoming_video_call' : 'Incoming_audio_call')));
                content.appendChild(DomUtils.createDom('div', 'hg-vcard-label', translator.translate('new')));

                return content;
            }
        });

        this.partyDetails_ = new PhoneCallPartyDetails({
            'displayAvatar': false
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass': 'hg-contact-incoming-call-btnset'
        });
        this.actionButtonSet_.addButton(HgButtonUtils.createSecondaryButton('', translator.translate('ignore'), false, {
            'name' : IncomingCallAlert.Button_.IGNORE
        }));
        this.actionButtonSet_.addButton(new Button({
            'content'       : '',
            'extraCSSClass' : 'hangup',
            'name'          : IncomingCallAlert.Button_.HANGUP
        }));
        this.actionButtonSet_.addButton(new Button({
            'content'       : '',
            'extraCSSClass' : 'follow',
            'name'          : IncomingCallAlert.Button_.FOLLOW
        }));
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.partyDetails_);
        this.partyDetails_ = null;

        BaseUtils.dispose(this.notice_);
        this.notice_ = null;

        BaseUtils.dispose(this.actionButtonSet_);
        this.actionButtonSet_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-incoming-alert';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.notice_, true);
        this.addChild(this.partyDetails_, true);
        this.addChild(this.actionButtonSet_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.notice_, {'set': this.notice_.setModel}, 'incomingCall.remoteVideo');

        this.setBinding(this.partyDetails_, {'set': this.partyDetails_.setModel}, 'incomingCall.party');

        this.setBinding(this, {'set': this.onVoicemailActiveChange_}, 'extension.settings.voicemailActive');
    }

    /**
     * Display hangup or ignore (voicemail) button according to voicemail capability
     * @param {boolean} voicemail
     */
    onVoicemailActiveChange_(voicemail) {
        const ignoreBtn = this.actionButtonSet_.getButtonByName(IncomingCallAlert.Button_.IGNORE),
            hangupBtn = this.actionButtonSet_.getButtonByName(IncomingCallAlert.Button_.HANGUP);

        ignoreBtn.setVisible(!!voicemail);
        hangupBtn.setVisible(!voicemail);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget());

        let eventType;
        switch (btn.getName()) {
            case IncomingCallAlert.Button_.HANGUP:
                eventType = PhoneEventType.HANGUP;
                break;

            case IncomingCallAlert.Button_.IGNORE:
                /* transfer extension to voicemail */
                eventType = PhoneEventType.TRANSFER_TO_VOICEMAIL;
                break;

            case IncomingCallAlert.Button_.FOLLOW:
                /* open and focus incoming call panel */
                eventType = PhoneEventType.FOLLOW_INCOMING;
                break;

            default:
                break;
        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('call', /** @type {hg.data.model.phonecall.FlatPhoneCall} */(this.getModel()['incomingCall']));

            this.dispatchEvent(event);
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
IncomingCallAlert.Button_ = {
    HANGUP : 'hangup',
    IGNORE : 'ignore',
    FOLLOW : 'follow'
};