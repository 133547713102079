import { Event } from '../../events/Event.js';
import { BaseUtils } from '../../base.js';
import { ITrackStatus } from './ITrackStatus.js';
import { IModel, IModelCollection } from './IModel.js';
import { IObservable, IObservableObject } from '../../structs/observable/IObservable.js';
import { ObservableObject } from '../../structs/observable/Observable.js';
import { DataModelField } from './Field.js';
import { IValidatable } from '../../validation/IValidatable.js';
import { Validator, ValidatorEventType } from '../../validation/Validator.js';
import { ValidationRuleSeverity } from '../../validation/RuleSeverity.js';
import { SaveDataError } from '../dataportal/SaveDataError.js';
import { ObjectUtils } from '../../object/object.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new {@see hf.data.DataModel} object.
 *
 * @example
 *
 * @augments {ObservableObject}
 * @implements {hf.data.ITrackStatus}
 * @implements {hf.data.IModel}
 * @implements {IValidatable}
 *
 */
export class DataModel extends ObservableObject {
    /**
     * @param {!object=} opt_initialData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initialData) {
        super(opt_initialData || {});

        DataModel.instanceCount_++;

        /**
         * The parent reference
         *
         * @type {hf.data.IModel | undefined}
         * @private
         */
        this.parent_;

        /**
         *
         * @type {hf.validation.Validator}
         * @private
         */
        this.validator_;

        /**
         * The severity level for which the validation is considered to be failed.
         *
         * @type {?ValidationRuleSeverity}
         * @default ValidationRuleSeverity.ERROR
         * @protected
         */
        this.errorsSeverityLevel;

        /**
         *
         * @type {object.<string, boolean>}
         * @private
         */
        this.loadingFields_;

        /**
         * True if the model is marked for deletion.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isMarkedForRemoval_ = this.isMarkedForRemoval_ === undefined ? false : this.isMarkedForRemoval_;

        /**
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isInEditMode_ = this.isInEditMode_ === undefined ? false : this.isInEditMode_;
    }

    /**
     * Returns the name of the field representing the unique identifier of this Model.
     *
     * @returns {?string | undefined}
     */
    getUIdField() {
        return undefined; // the default
    }

    /**
     * Gets the unique identifier of this Model.
     * If the model is new (doesn't have an image at the storage level) then the generated client id is returned.
     *
     * @returns {*}
     *
     */
    getUId() {
        const uidField = /** @type {string} */ (this.getUIdField());

        if (this.isNew() || !this.hasField(uidField)) {
            return this.getClientUId();
        }

        return this[uidField];
    }

    /**
     * Sets the unique identifier of this Model
     *
     * @param {*} uid
     */
    setUId(uid) {
        const uidField = /** @type {string} */ (this.getUIdField());

        if (!this.hasField(uidField)) {
            throw new Error("Can not set the 'uid' because there is no 'uid' field specified on this Model.");
        }

        // NOTE: Do not silently set the id, because there are use cases when you need to inform third parties about the change of the Model's id - see Topic
        this.setInternal(uidField, uid/*, true*/);
    }

    /**
     * Gets the parent of this model.
     *
     * @returns {hf.data.IModel | undefined}
     *
     */
    getParent() {
        return this.parent_;
    }

    /**
     * Sets the parent of this model.
     *
     * @param {hf.data.IModel=} parent
     */
    setParent(parent) {
        this.parent_ = parent;
    }

    /**
     * @inheritDoc
     *
     */
    loadData(source, opt_loadOptions) {
        let hasDirtyFields = this.hasDirtyFields();

        this.loadDataInternal(source, opt_loadOptions);

        /* Accept changes made by loading data if:
         * - no load options are specified, or
         * - load options specify to override the changes, or
         * - the model was not dirty before loading data.
         */
        if (opt_loadOptions == null || opt_loadOptions.overrideChanges || !hasDirtyFields) {
            this.acceptChanges(true);
        }
    }

    /**
     * @returns {boolean}
     *
     */
    isInEditMode() {
        return this.isInEditMode_;
    }

    /**
     *
     */
    beginEdit() {
        if (this.isInEditMode_) {
            return;
        }

        this.onEnterEditMode();
        this.isInEditMode_ = true;
    }

    /**
     *
     * @param {boolean} acceptChanges
     */
    endEdit(acceptChanges) {
        if (!this.isInEditMode_) {
            return;
        }

        this.onExitEditMode(acceptChanges);
        this.isInEditMode_ = false;

        if (!acceptChanges) {
            this.discardChanges();
        }

    }

    /**
     * Commits all the changes.
     *
     * @param {boolean=} opt_silent
     *
     */
    acceptChanges(opt_silent) {
        if (!this.isDirty()) {
            return;
        }

        this.commitFieldsChanges(true);

        if (!opt_silent) {
            // Let the listeners know the entire object has changed.
            this.dispatchChangeEvent();
        }
    }

    /**
     * Rejects all the current changes.
     *
     * @param {boolean=} opt_silent
     *
     */
    discardChanges(opt_silent) {
        if (!this.isDirty()) {
            return;
        }

        this.enableChangeNotification(false);

        // silently revert the current changes to the old Model's snapshot
        this.discardFieldsChanges(true);

        // update the validation status
        this.validate();

        this.enableChangeNotification(true);

        if (!opt_silent) {
            // Let the listeners know the entire object has changed.
            this.dispatchChangeEvent();
        }
    }

    /**
     * Gets whether the model is busy (e.g. is being saved).
     * Any changes are prevented until the model becomes idle (the save operation completed, successfully or not)
     *
     * @returns {boolean}
     *
     */
    isBusy() {
        return /** @type {boolean} */(this.get('isBusy_'));
    }

    /**
     * Sets whether the model is busy.
     * If busy, no further changes are allowed.
     *
     * @param {boolean} isBusy
     *
     */
    setBusy(isBusy) {
        this.setInternal('isBusy_', isBusy);
    }

    /**
     * @inheritDoc
     *
     */
    isDirty() {
        return this.isNew() || this.isMarkedForRemoval() || this.hasDirtyFields();
    }

    /**
     * Marks a model for deletion. This also marks the model as being dirty.
     * You should call this method in your business logic in the case that you want to have the model deleted when it is
     * saved to the database.
     *
     * @returns {void}
     */
    markForRemoval() {
        this.isMarkedForRemoval_ = true;
    }

    /**
     * @inheritDoc
     *
     */
    isMarkedForRemoval() {
        return this.isMarkedForRemoval_;
    }

    /**
     * @inheritDoc
     *
     */
    isNew() {
        const uidField = /** @type {string} */ (this.getUIdField());

        return this.hasField(uidField) && this[uidField] == null;
    }

    /**
     * @inheritDoc
     *
     */
    isSavable() {
        return (this.isMarkedForRemoval() || this.isDirty() && this.hasSavableFields()) && this.isValid() && !this.isBusy();
    }

    /**
     * @inheritDoc
     *
     */
    toJSONObject(opt_serializeOptions) {
        opt_serializeOptions = opt_serializeOptions || {};

        opt_serializeOptions.excludeUnchanged = opt_serializeOptions.excludeUnchanged || false;
        opt_serializeOptions.excludeNonPersistable = opt_serializeOptions.excludeNonPersistable || false;

        return super.toJSONObject(opt_serializeOptions);
    }

    /**
     * Marks the start of the save operation.
     */
    onSaving() {
        // inform listeners about the object entering into the 'Busy' state.
        this.setBusy(true);

        // disable change events while saving
        this.enableChangeNotification(false);
    }

    /**
     * Marks the end of the save operation.
     * If the save operation has a result (data or error), then it is processed.
     *
     * @param {*=} opt_saveResult The save result
     */
    onSaved(opt_saveResult) {
        // enable change events after saving
        this.enableChangeNotification(true);

        // inform listeners about the object exiting from the 'Busy' state.
        this.setBusy(false);

        let hasError = false;

        // if the save operation returns a result (either a data result or an error) then...
        if (opt_saveResult != null) {
            // handle the save error...
            if (opt_saveResult instanceof SaveDataError) {
                hasError = true;

                this.processSaveError(/** @type {hf.data.SaveDataError} */(opt_saveResult));
            }
            // ...or handle the data result by trying to merge it into the model (see update Presence)
            else {
                this.processSaveResult(/** @type {!object} */(opt_saveResult));
            }
        }

        if (!hasError) {
            // the save succeeded => commit all the pending changes
            this.acceptChanges();
        }
    }

    /**
     * Process the save error.
     *
     * @param {hf.data.SaveDataError} saveError
     * @protected
     */
    processSaveError(saveError) {
        // if(saveError.getInnerError()) {
        //     throw saveError.getInnerError();
        // }
        // else {
        //     throw new Error(saveError.getMessage());
        // }
    }

    /**
     * Processes the save result.
     * by merging it into the model (see update Presence)
     *
     * @param {!object} saveData
     * @protected
     */
    processSaveResult(saveData) {
        if (ObjectUtils.isPlainObject(saveData) && Object.keys(saveData).length > 0) {
            this.loadDataInternal(saveData);
        } else if (this.isNew()) {
            this.setUId(saveData);
        }
    }

    /**
     * @inheritDoc
     *
     */
    enableValidation(enable) {
        if (this.validator_) {
            this.validator_.enableRuleValidation(enable);
        }
    }

    /**
     * @inheritDoc
     *
     */
    isValidationEnabled() {
        return this.validator_ != null && this.validator_.isRuleValidationEnabled();
    }

    /**
     * @inheritDoc
     *
     */
    validate(opt_fieldName) {
        if (this.validator_) {
            this.validator_.validateRules(opt_fieldName);
        }
    }

    /**
     * @inheritDoc
     *
     */
    isValid() {
        return this.validator_ == null || !this.validator_.hasErrors() && !this.hasInvalidFields();
    }

    /**
     * @inheritDoc
     *
     */
    getPropertyValidationErrors(propertyName) {
        if (this.validator_) {
            return this.validator_.getPropertyBrokenRules(propertyName);
        }

        return [];
    }

    /**
     * @inheritDoc
     *
     */
    getAllValidationErrors() {
        const validationErrors = [];

        if (this.validator_) {
            const invalidFields = Object.keys(this.getInvalidFields());

            validationErrors.push(...this.validator_.getAllBrokenRules());

            let i = 0;
            const len = invalidFields.length;
            for (; i < len; i++) {
                const field = this.getField(invalidFields[i]),
                    fieldValue = /** @type {object} */ (field.getValue());

                if (IValidatable.isImplementedBy(fieldValue)) {
                    validationErrors.push(...(fieldValue).getAllValidationErrors());
                }
                if (IModelCollection.isImplementedBy(fieldValue)) {
                    validationErrors.push(...(fieldValue).getAllValidationErrors());
                }
            }
        }

        return validationErrors;
    }

    /** @inheritDoc */
    init(opt_initialData) {
        this.loadingFields_ = {};

        this.defineInternalFields_();

        this.defineFields();

        this.defineCustomFields();

        this.defineValidationRules();

        // load initial data
        this.loadDataInternal(opt_initialData || {}, { overrideChanges: true });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        DataModel.instanceCount_--;

        this.parent_ = null;
        this.errorsSeverityLevel = null;
        this.loadingFields_ = null;

        BaseUtils.dispose(this.validator_);
        this.validator_ = null;
    }

    /** @inheritDoc */
    generateClientId() {
        return StringUtils.createUniqueString('__hf_data_model_');
    }

    /**
     * Defines the internal fields of the object like isBusy.
     *
     * @private
     */
    defineInternalFields_() {
        this.addField({
            name: 'uid_',
            type: DataModelField.PredefinedTypes.STRING,
            isInternal: true,
            isReadOnly: true,
            isPersistable: false,
            getter() {
                return this.getUId();
            }
        });
        /* 'created_' is an internal field in hf.data.DataModel; it is set when the model is created on the client; it may be used for sorting purposes */
        this.addField({
            name: 'created_', type: DataModelField.PredefinedTypes.DATE_TIME, isInternal: true, isReadOnly: true, isPersistable: false, value: new Date()
        });
        this.addField({
            name: 'isBusy_', type: DataModelField.PredefinedTypes.BOOL, isInternal: true, isReadOnly: true, isPersistable: false, value: false
        });
        this.addField({
            name: 'isLoadingData_', type: DataModelField.PredefinedTypes.BOOL, isInternal: true, isReadOnly: true, isPersistable: false, value: false
        });
        this.addField({
            name: 'objectState_',
            type: Object,
            isInternal: true,
            isReadOnly: true,
            isPersistable: false,
            getter() {
                return {
                    uid: this.getUId(),
                    isNew: this.isNew(),
                    isDirty: this.isDirty(),
                    isMarkedForRemoval: this.isMarkedForRemoval(),
                    isValid: this.isValid(),
                    isBusy: this.isBusy(),
                    isSavable: this.isSavable()
                };
            }
        });
    }

    /**
     *
     * @param {string} fieldName
     * @returns {boolean}
     */
    isFieldInternal(fieldName) {
        const field = this.getField(fieldName);

        return field != null && field.isInternal();
    }

    /**
     * Defines the fields of this model by calling the #addField() method foreach field that someone wants to add.
     * This method is overriden by the inheritors.
     *
     * @protected
     */
    defineFields() {
        // nop
    }

    /**
     * Defines the Model's custom fields by calling the #Object.defineProperty(...) method
     *
     * @protected
     */
    defineCustomFields() {
        // nop
    }

    /** @inheritDoc */
    createField(fieldParams) {
        return new DataModelField(fieldParams);
    }

    /** @inheritDoc */
    createProperty(fieldParams) {
        const name = fieldParams.name;
        let fieldIsReadonly = fieldParams.isReadOnly;
        const fieldType = fieldParams.type,
            isModelType = fieldType != null && IModel.isImplementedBy(fieldType.prototype);

        /* The property's getter */
        const getterFn = BaseUtils.isFunction(fieldParams.getter)
        /** @type {Function} */? (fieldParams.getter).bind(this)
            : (fieldType == Array || isModelType) && !fieldIsReadonly
                ? this.createLazyGetter(name, fieldType)
                : () => this.getFieldValue(name);


        /* The property's setter
           NOTE: the Model doesn't have setters for read-only fields or for the field representing the unique identifier */
        const setterFn = fieldIsReadonly || name == this.getUIdField
            ? undefined
            : (val, opt_silent) => {
                if (BaseUtils.isFunction(fieldParams.setter)) {
                    /** @type {Function} */(fieldParams.setter).call(this, val, opt_silent);
                } else {
                    this.setInternal(name, val);
                }
            };

        Object.defineProperty(this, name, {
            get: getterFn,
            set: setterFn
        });
    }

    /**
     *
     * @param {string} fieldName
     * @returns {hf.data.DataModelField}
     * @protected
     * @override
     */
    getField(fieldName) {
        return super.getField(fieldName);
    }

    /**
     * Creates a lazy getter.
     *
     * @param {string} fieldName
     * @param {function(): *} getter
     * @returns {Function}
     * @protected
     */
    createLazyGetter(fieldName, getter) {
        return () => {
            if (!this.fieldHasValue(fieldName)) {
                // silently set the field value; there is no need to notify it changed
                this.setInternal(
                    fieldName,
                    IModel.isImplementedBy(getter.prototype)
                        ? new /** @type {function(new: hf.data.DataModel, !object=) | function(new: hf.data.DataModelCollection, !object=)} */ (getter)({})
                        : getter.call(this),
                    true
                );

                // silently mark the field as not changed; there is no need to notify it changed
                this.getField(fieldName).commitChanges(true);
            }

            return this.getFieldValue(fieldName);
        };
    }

    /**
     *
     * @param {string} fieldName
     * @param {function(): Promise} asyncLoader
     * @returns {Function}
     * @protected
     */
    createAsyncGetter(fieldName, asyncLoader) {
        return () => this.loadFieldAsync(fieldName, asyncLoader);
    }

    /**
     * Asynchronously (lazy) loads the field value.
     *
     * @param {string} fieldName
     * @param {function(): Promise} asyncLoader
     * @protected
     */
    loadFieldAsync(fieldName, asyncLoader) {
        if (!this.fieldHasValue(fieldName) && !this.isFieldLoading(fieldName)) {
            this.loadingFields_[fieldName] = true;

            asyncLoader.call(this)
                .then((result) => {
                    // check whether the containing model was disposed meanwhile
                    if (!this.isDisposed()) {
                        // store the current value of the field...to be used later
                        const currrentValue = this.getFieldValue(fieldName);

                        // silently set the field value
                        this.setInternal(fieldName, result, true);

                        // silently mark the field as not changed
                        this.getField(fieldName).commitChanges(true);

                        // dispatch the CHANGE event...if the change notifications is enabled
                        if (this.isChangeNotificationEnabled()) {
                            this.onFieldValueChanged(fieldName, result, currrentValue);
                        }
                    }

                    return result;
                })
                .catch((err) => {
                    throw err;
                })
                .finally(() => {
                    // check whether the containing model was disposed meanwhile
                    if (!this.isDisposed()) {
                        this.loadingFields_[fieldName] = false;
                        delete this.loadingFields_[fieldName];
                    }
                });
        }

        return this.getFieldValue(fieldName);
    }

    /**
     * Indicates whether the field is asynchronously lazy loaded.
     *
     * @param {string} fieldName
     * @returns {boolean}
     */
    isFieldLoading(fieldName) {
        return this.loadingFields_.hasOwnProperty(fieldName) && this.loadingFields_[fieldName] == true;
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        if (value == null) {
            return value;
        }

        const field = this.getField(fieldName);
        let fieldType = field.getType();

        if (fieldType == null) {
            field.inferTypeFromValue(value);
            fieldType = field.getType();
        }

        const valueParser = field.getValueParser() || DataModelField.ValueParsers[fieldType];
        if (valueParser != null) {
            return valueParser(value);
        }

        // TODO: Test this
        if (BaseUtils.isFunction(fieldType)) {
            // if(fieldType === value.constructor){
            if (value instanceof /** @type {object} */(fieldType)) {
                return value;
            }
            if (IModel.isImplementedBy(fieldType.prototype)) {
                return new /** @type {!function(new: hf.data.DataModel, !object=)} */(fieldType)(/** @type {!object | undefined} */(value));
            }
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        this.validate(fieldName);

        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }

    /** @inheritDoc */
    createParentChildLink(child, fieldName) {
        super.createParentChildLink(child, fieldName);

        if (IModel.isImplementedBy(child)) {
            /** @type {hf.data.DataModel | hf.data.DataModelCollection} */ (child).setParent(this);
        }
    }

    /** @inheritDoc */
    removeParentChildLink(child, fieldName) {
        super.removeParentChildLink(child, fieldName);

        if (IModel.isImplementedBy(child)) {
            /** @type {hf.data.DataModel | hf.data.DataModelCollection} */ (child).setParent(undefined);
        }
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        let excludeUnchanged = serializeOptions.excludeUnchanged || false,
            excludeNonPersistable = serializeOptions.excludeNonPersistable || false;
        const dataField = /** @type {hf.data.DataModelField} */(this.getField(fieldName));

        return fieldName === this.getUIdField() || dataField.mustSerialize()
            || (!dataField.isInternal() && !dataField.isReadonly()
            && (!excludeNonPersistable || dataField.isPersistable())
            && dataField.hasValue() && (!excludeUnchanged || dataField.isSavable()));
    }

    /**
     * Gets whether currently data is loaded into the model.
     *
     * @returns {boolean}
     * @protected
     */
    isLoadingData() {
        return /** @type {boolean} */(this.get('isLoadingData_'));
    }

    /**
     * @override
     */
    loadDataInternal(source, opt_loadOptions) {
        if (!ObjectUtils.isPlainObject(source)) {
            throw new Error('Invalid source object to load data from.');
        }

        opt_loadOptions = opt_loadOptions || {};
        opt_loadOptions.overrideChanges = opt_loadOptions.overrideChanges != null ? opt_loadOptions.overrideChanges : true;

        /* store whether the object is dirty before loading data into it */
        // var wasDirty = this.hasDirtyFields();

        let overrideChanges = !!opt_loadOptions.overrideChanges;

        // Make sure that no 'CHANGE' event will be dispatched while the new data is loaded.
        this.enableChangeNotification(false);

        this.setInternal('isLoadingData_', true);

        // disable the validation
        this.enableValidation(false);

        // start the process of loading the data
        this.onDataLoading(source);

        const sourceIsEmpty = Object.keys(source).length === 0,
            fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            if (this.isFieldInternal(fields[i])) {
                continue;
            }

            if (sourceIsEmpty) {
                this.resetFieldValue(fields[i], true);
            } else {
                if (!overrideChanges && this.isFieldDirty(fields[i])) {
                    continue;
                }

                let fieldValue = source[fields[i]];
                fieldValue = fieldValue == '@empty' ? undefined : fieldValue;

                if (fieldValue !== undefined) {
                    this.loadFieldValue(fields[i], fieldValue, opt_loadOptions);
                }
            }
        }

        this.onDataLoaded();

        // enable the validation
        this.enableValidation(true);

        // validates the new model's data
        this.validate();

        this.setInternal('isLoadingData_', false);

        this.enableChangeNotification(true);

        /* if the object wasn't dirty before loading the data and if it is dirty after the data was loaded then... */
        // if(!wasDirty && this.isDirty()) { // NOTE: there are objects like Presence objects (e.g. rawStatus) that remain dirty (no accept changes is called) so wasDirty inhibits the dispatch of the change event which in turn inhibits the update of the ui (e.g. presence indicator). A better solution must be found!
        if (this.isDirty()) {
            // ...let the listeners know the entire object has changed.
            this.dispatchChangeEvent();
        }
    }

    /**
     * @protected
     */
    onEnterEditMode() {
        // nop
    }

    /**
     * @param {boolean} acceptChanges
     * @protected
     */
    onExitEditMode(acceptChanges) {
        // nop
    }

    /**
     * Returns {@code true} if any of the Model's fields or child objects has been changed.
     *
     * @returns {boolean}
     * @protected
     */
    hasDirtyFields() {
        let fields = this.getFields(),
            hasDirtyFields = false;

        for (let key in fields) {
            let field = /** @type {hf.data.DataModelField} */(fields[key]);

            if (!field.ignoreDirty() && field.isDirty()) {
                hasDirtyFields = true;
                break;
            }
        }

        return hasDirtyFields;
    }

    /**
     * Returns the fields which are dirty.
     *
     * @returns {object.<string, hf.data.DataModelField>}
     * @protected
     */
    getDirtyFields() {
        const dirtyFields = {},
            fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const field = /** @type {hf.data.DataModelField} */ (this.getField(fields[i]));
            if (!field.ignoreDirty() && field.isDirty()) {
                dirtyFields[field.getName()] = field;
            }
        }

        return dirtyFields;
    }

    /**
     * Returns {@code true} if any of the Model's fields or child objects is savable.
     *
     * @returns {boolean}
     * @protected
     */
    hasSavableFields() {
        let fields = this.getFields(),
            hasSavableFields = false;

        for (let key in fields) {
            let field = /** @type {hf.data.DataModelField} */(fields[key]);

            if (field.isSavable()) {
                hasSavableFields = true;
                break;
            }
        }

        return hasSavableFields;
    }

    /**
     * Returns the fields which are savable.
     *
     * @returns {object.<string, hf.data.DataModelField>}
     * @protected
     */
    getSavableFields() {
        const savableFields = {},
            fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const field = /** @type {hf.data.DataModelField} */ (this.getField(fields[i]));
            if (field.isSavable()) {
                savableFields[field.getName()] = field;
            }
        }

        return savableFields;
    }

    /**
     * @param {boolean=} opt_silent
     * @protected
     */
    commitFieldsChanges(opt_silent) {
        const fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const field = /** @type {hf.data.DataModelField} */ (this.getField(fields[i]));
            if (!field.isReadonly() && !field.isInternal()) {
                field.commitChanges(opt_silent);
            }
        }
    }

    /**
     * @param {boolean=} opt_silent
     * @protected
     */
    discardFieldsChanges(opt_silent) {
        const fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const field = /** @type {hf.data.DataModelField} */ (this.getField(fields[i]));
            if (!field.isReadonly() && !field.isInternal()) {
                field.discardChanges(opt_silent);
            }
        }
    }

    /**
     * Returns {@code true} if any of the Model's children has validation errors.
     *
     * @returns {boolean}
     * @protected
     */
    hasInvalidFields() {
        let fields = this.getFields(),
            hasInvalidFields = false;

        for (let key in fields) {
            let field = /** @type {hf.structs.observable.ObservableObjectField} */(fields[key]);

            if (!field.isValid()) {
                hasInvalidFields = true;
                break;
            }
        }

        return hasInvalidFields;
    }

    /**
     * Returns the fields which are dirty.
     *
     * @returns {!object.<string, hf.data.DataModelField>}
     * @protected
     */
    getInvalidFields() {
        const invalidFields = {},
            fields = Object.keys(this.getFields());

        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const field = /** @type {hf.data.DataModelField} */ (this.getField(fields[i]));
            if (!field.isValid()) {
                invalidFields[field.getName()] = field;
            }
        }

        return invalidFields;
    }

    /**
     * Gets the Model's data validator.
     *
     * @returns {hf.validation.Validator}
     * @protected
     */
    getValidator() {
        if (this.validator_ == null) {
            this.validator_ = new Validator({ target: this, errorsSeverityLevel: this.errorsSeverityLevel || ValidationRuleSeverity.ERROR });

            // TODO: Can't I use setParentEventTarget method instead of listening to the Validator event? This implies that the Validator class implements the IValidatable interface.
            this.validator_.listen(ValidatorEventType.VALIDATED, this.onValidated, false, this);
        }

        return this.validator_;
    }

    /**
     * Handles the chnage of the collection of validation errors.
     *
     * @param {hf.events.Event} e
     * @protected
     */
    onValidated(e) {
        const affectedProperties = e.affectedProperties;

        /* Do not dispatch the VALIDATION_ERRORS_CHANGED if the change notifications are inhibited */
        if (this.isChangeNotificationEnabled() && affectedProperties.length > 0) {
            const event = new Event(IValidatable.EventType.VALIDATION_ERRORS_CHANGED);
            event.addProperty('affectedProperties', affectedProperties);

            this.dispatchEvent(event);
        }
    }

    /**
     * Defines the validation rules for this Model.
     *
     * @protected
     */
    defineValidationRules() {
        // nop
    }

    /**
     * Adds a new validation rule.
     *
     * @param {!hf.validation.Rule | !object} rule
     * @returns {hf.data.DataModel}
     * @protected
     */
    addValidationRule(rule) {
        this.getValidator().addRule(rule);

        return this;
    }
}
// implements interfaces:
IObservable.addImplementation(DataModel);
IObservableObject.addImplementation(DataModel);
ITrackStatus.addImplementation(DataModel);
IModel.addImplementation(DataModel);
IValidatable.addImplementation(DataModel);

/**
 *
 * @type {number}
 * @protected
 */
DataModel.instanceCount_ = 0;
