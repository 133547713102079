import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";

/**
 * Constructor for a new KeyVal model
 * @extends {DataModel}
 * @unrestricted 
*/
export class KeyVal extends DataModel {
 /**
  * @param {Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     // TODO: remove this after the tagcloud from person service is fixed!!!
     opt_initData = (BaseUtils.isArray(opt_initData) ? opt_initData[0] : opt_initData) || {};
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     super.defineFields();

     /* A uniquely identifiable key */
     this.addField({'name': 'key', 'type': Object});

     /* The value corresponding to that key */
     this.addField({'name': 'value', 'type': Object});
 }
};