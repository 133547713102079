import {
    CommitChangesActionTypes,
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {BaseView} from "./BaseView.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Search, SearchFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HgStringUtils} from "./../../string/string.js";
import {HgButtonUtils} from "./../button/Common.js";
import {PopupDialog} from "./../PopupDialog.js";
import {PopupButton} from "./../button/PopupButton.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";
import {PopupBounceIn} from "./../fx/PopupBounceIn.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TextInputChangeValueOn} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";

/**
 * Base class for entities lists' toolbars views
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class AbstractToolbarView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {HorizontalStack}
         * @private
         */
        this.facetTitleContainer_;

        /**
         * @type {HorizontalStack}
         * @private
         */
        this.rightContainer_;

        /**
         * The title of the section e.g. People, Topics, Topic Search etc.
         * @type {Caption}
         * @private
         */
        this.title_;

        /**
         * The subtitle of the section displaying info about the current filter/search criteria.
         * @type {UIControl}
         * @private
         */
        this.subtitle_;

        /**
         * The button to go back to the previous state
         * @type {Button}
         * @private
         */
        this.backBtn_;

        /**
         * The container with the action buttons.
         * @type {ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         *
         * @type {Button}
         * @private
         */
        this.searchBtn_;

        /**
         * Search form field.
         * @type {Search}
         * @private
         */
        this.searchField_;

        /**
         * The button which opens the advanced search panel.
         * @type {PopupButton}
         * @private
         */
        this.advancedSearchBtn_;

        /**
         * @type {number}
         * @private
         */
        this.exitSearchModeTimerId_;

        /**
         * @type {boolean}
         * @private
         */
        this.isInSearchState_ = this.isInSearchState_ === undefined ? false : this.isInSearchState_;
    }

    /**
     * Enters into the search state:
     * - expands the search field => the last search value will be visible.
     */
    enterSearchState() {
        if (!this.isInSearchState_) {
            this.isInSearchState_ = true;

            clearTimeout(this.exitSearchModeTimerId_);

            if (this.searchField_) {
                this.beforeSearchFieldExpand_();

                this.searchField_.expand();

                this.moveBackBtn(true);
            }
        }
    }

    /**
     * Exits from the search state:
     * - collapses the search field
     * - closes the advanced search popup
     */
    exitSearchState() {
        if (this.isInSearchState_) {
            this.isInSearchState_ = false;

            if (this.searchField_) {
                this.searchField_.collapse();
            }

            if (this.advancedSearchBtn_) {
                this.advancedSearchBtn_.close();

                this.moveBackBtn(false);
            }
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['displayTotalCount'] = opt_config['displayTotalCount'] != null ? opt_config['displayTotalCount'] : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.title_ = new Caption({
            'extraCSSClass': 'hg-toolbar-title',
            'contentFormatter': function (title) {
                return !StringUtils.isEmptyOrWhitespace(title) ?
                    translator.translate(title) : null;
            },
            'ellipsis': true
        });

        this.subtitle_ = new Caption({
            'extraCSSClass': 'hg-toolbar-subtitle',
            'contentFormatter': this.createSubtitleDom.bind(this)
        });

        this.backBtn_ = HgButtonUtils.createStandardButton(false, {
            'extraCSSClass' : ['hg-toolbar-back-button'],
            'tooltip': {
                'content': translator.translate('back'),
                'autoHide': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': 0
            }
        });

        this.searchBtn_ = HgButtonUtils.createToolbarButton('hg-toolbar-search-button', '', false, {
            'tooltip': {
                'content': translator.translate('search'),
                'autoHide': true,
                'showArrow': true,
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': 0
            }
        });

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.title_ = null;
        this.subtitle_ = null;
        this.backBtn_ = null;
        this.buttonSet_ = null;
        this.searchBtn_ = null;
        this.searchField_ = null;
        this.advancedSearchBtn_ = null;

    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-toolbar';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-list-toolbar');

        this.facetTitleContainer_ = new HorizontalStack({'extraCSSClass': 'hg-toolbar-facet-container'});
        this.facetTitleContainer_.addChild(this.subtitle_, true);
        this.facetTitleContainer_.addChild(this.backBtn_, true);

        const leftContainer = new VerticalStack({'extraCSSClass': 'hg-toolbar-left-container'});
        leftContainer.addChild(this.title_, true);
        leftContainer.addChild(this.facetTitleContainer_, true);

        this.rightContainer_ = new HorizontalStack({'extraCSSClass': 'hg-toolbar-right-container'});
        this.rightContainer_.addChild(this.getButtonSet(), true);
        this.getButtonSet().addChild(this.searchBtn_, true);
        this.rightContainer_.addChild(this.getSearchField(), true);

        this.rightContainer_.addChild(this.getAdvancedSearchButton(), true);

        const content = new UIComponent({'baseCSSClass': 'hg-toolbar-content'});
        content.setSupportedState(UIComponentStates.ALL, false);
        content.setDispatchTransitionEvents(UIComponentStates.ALL, false);

        content.addChild(leftContainer, true);
        content.addChild(this.rightContainer_, true);

        this.addChild(content, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getButtonSet().setVisible(true);
        this.getSearchField().collapse();
        this.getSearchField().setVisible(false);

        this.getHandler()
            /* listener for back action */
            .listen(this.backBtn_, UIComponentEventTypes.ACTION, this.handleNavigateBack)

            /* listener for toolbar's button actions */
            .listen(this.getButtonSet(), UIComponentEventTypes.ACTION, this.handleButtonAction)

            /* listener for search button action */
            .listen(this.searchBtn_, UIComponentEventTypes.ACTION, this.handleSearchButtonAction)

            /* listen for search field events */
            .listen(this.getSearchField(), [SearchFieldEventType.EXPAND, SearchFieldEventType.COLLAPSE], this.handleSearchFieldExpandCollapse)
            .listen(this.getSearchField(), SearchFieldEventType.VALUE_SEARCH, this.handleSearchFieldFilter)
            .listen(this.getSearchField(), SearchFieldEventType.OPTION_SELECT, this.handleSearchFieldSelect)
            .listen(this.getSearchField(), UIComponentEventTypes.BLUR, this.handleSearchFieldBlur)

            /* listen for advanced search button events */
            .listen(this.getAdvancedSearchButton(), [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE], this.handleAdvancedSearchButtonBlur)
            .listen(this.getAdvancedSearchButton(), [CommitChangesActionTypes.SUBMIT], this.handleAdvancedSearchSubmit_)
            .listen(this.getAdvancedSearchButton(), [CommitChangesActionTypes.DISMISS], this.handleAdvancedSearchDismiss_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.searchField_.collapse();

        clearTimeout(this.exitSearchModeTimerId_);

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.title_, {'set': this.title_.setModel}, 'title');

        this.setBinding(this.subtitle_, {'set': this.subtitle_.setModel}, 'filterCriteriaInfo');

        this.setBinding(this.backBtn_, {'set': this.backBtn_.setVisible}, 'canGoBack');
    }

    /**
     * Lazy creates and then returns the button set.
     *
     * @return {ButtonSet}
     * @protected
     */
    getButtonSet() {
        if (this.buttonSet_ == null) {
            /* action buttons on the left of the toolbar */
            this.buttonSet_ = new ButtonSet({'extraCSSClass': 'hg-toolbar-actions'});
        }

        return this.buttonSet_;
    }

    /**
     * @protected
     */
    search() {
        // Make sure the search value is updated; Search field doesn't dispatch CHANGE event
        // so the viewmodel's searchValue field is not updated real time
        this.getModel()['searchValue'] = this.getSearchField().getSearchValue();

        /**@type {AbstractToolbarPresenter}*/(this.getPresenter()).search();

        if (this.advancedSearchBtn_) {
            this.advancedSearchBtn_.close();
        }
    }

    /**
     * Lazy creates and then returns the search field.
     *
     * @return {Search}
     * @protected
     */
    getSearchField() {
        if (this.searchField_ == null) {
            /* Initialize the search field */
            this.searchField_ = new Search(this.getSearchFieldConfig());

            this.setBinding(
                this.searchField_,
                {'get': this.searchField_.getValue, 'set': this.searchField_.setValue},
                {
                    'sourceProperty': 'searchValue',
                    'mode': DataBindingMode.TWO_WAY
                }
            );
        }

        return this.searchField_;
    }

    /**
     * Gets the config object for the search field.
     * @return {!Object}
     * @protected
     */
    getSearchFieldConfig() {
        return {
            'extraCSSClass': ['hg-form-field-search', 'hg-toolbar-quick-search'],
            'supportsExpandCollapse': true,
            'popup': {
                'showArrow': true,
                'extraCSSClass': ['hg-popup']
            },
            'findMode': AutoCompleteFindMode.SEARCH,
            'selectSuggestionOnHighlight': true,
            'clearValueOnSearch': false
        };
    }

    /**
     * Lazy creates and then returns the advanced search button.
     *
     * @return {PopupButton} Advanced search popup content
     * @protected
     */
    getAdvancedSearchButton() {
        if (this.advancedSearchBtn_ == null) {
            const translator = Translator;

            this.advancedSearchBtn_ = new PopupButton({
                'extraCSSClass': [HgButtonUtils.ButtonCSSClass.TOOLBAR_ADVANCED_SEARCH],
                'hidden': true,
                'hideMode': UIComponentHideMode.VISIBILITY,
                'popup': {
                    'type': PopupDialog,
                    'content': this.createAdvancedSearchPopupContent(),
                    'placement': PopupPlacementMode.BOTTOM_RIGHT,
                    'extraCSSClass': ['hg-advanced-search-popup'],
                    'verticalOffset': 4,
                    'showArrow': true,
                    'staysOpen': false,
                    'staysOpenWhenClicking': [this.getSearchField().getElement()],
                    'openAnimation': {
                        'type': PopupBounceIn
                    }
                },
                'tooltip': {
                    'content': translator.translate('advanced_search'),
                    'autoHide': true,
                    'showArrow': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE,
                    'verticalOffset': 0
                }
            });

            this.setBinding(this.advancedSearchBtn_, {'set': this.advancedSearchBtn_.setModel}, '');

            /* making the advanced search button invisible will also close its popup */
            this.setBinding(this.advancedSearchBtn_, {'set': this.advancedSearchBtn_.setVisible},
                {
                    'source': this.getSearchField(),
                    'sourceProperty': {'get': this.getSearchField().isExpanded},
                    'updateTargetTrigger': [SearchFieldEventType.EXPAND, SearchFieldEventType.COLLAPSE]
                }
            );
        }

        return this.advancedSearchBtn_;
    }

    /**
     * @return {UIComponent}
     * @protected
     */
    createAdvancedSearchPopupContent() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Creates the DOM for the facet title.
     * @param {Object} filterCriteriaInfo
     * @returns {?DocumentFragment}
     * @protected
     */
    createSubtitleDom(filterCriteriaInfo) {
        if (filterCriteriaInfo == null) {
            return null;
        }

        if (filterCriteriaInfo['quickTitle'] != null) {
            const content = document.createDocumentFragment();
            let quickTitleElement;

            /* quick title found, criteria is ignored
             * do not use translator on quickTitle, it should be displayed as it is */

            /* create quick title as A element in View state and as SPAN element in other states */
            quickTitleElement = DomUtils.createDom('span', 'hg-highlight-word', filterCriteriaInfo['quickTitle']);

            content.appendChild(quickTitleElement);

            return content;
        }

        /* fallback: process filtering criteria */
        if (filterCriteriaInfo['criteria'] != null) {
            if (filterCriteriaInfo['criteria'] instanceof SearchFacet) {
                return this.createSearchSubtitleDom(filterCriteriaInfo);
            } else {
                return this.createFacetSubtitleDom(filterCriteriaInfo);
            }
        }

        return null;
    }

    /**
     * Creates the brief DOM when dealing with a facet filtering.
     * @param {Object} filterCriteriaInfo
     * @returns {?DocumentFragment}
     * @protected
     */
    createFacetSubtitleDom(filterCriteriaInfo) {
        const content = document.createDocumentFragment();

        if (filterCriteriaInfo['criteria'] != null) {
            const facet = /** @type {Facet} */ (filterCriteriaInfo['criteria']),
                totalCount = filterCriteriaInfo['totalCount'];

            /* display facets like =>  category : name || translate(uid)
             * except for static category =>  name || translate(uid) */
            const facetName = HgStringUtils.formatFacetName(facet);

            if (facet['category'] === 'static') {
                content.appendChild(DomUtils.createDom('span', 'hg-toolbar-subtitle-facet-name', facetName));
            } else {
                const category = HgStringUtils.formatFacetCategoryTitle(facet);
                content.appendChild(DomUtils.createDom('span', 'hg-toolbar-subtitle-facet-name', category + AbstractToolbarView.SEPARATOR + facetName));
            }

            if (BaseUtils.isNumber(totalCount) && !!this.getConfigOptions()['displayTotalCount']) {
                if (totalCount > 0) {
                    content.appendChild(DomUtils.createDom('span', 'hg-toolbar-subtitle-facet-total-count', '(' + HgStringUtils.formatNotificationsCount(totalCount) + ')'));
                }
            }
        }

        return content;
    }

    /**
     * Creates the brief DOM when dealing with a search filtering
     * @param {Object} filterCriteriaInfo
     * @returns {?DocumentFragment}
     * @protected
     */
    createSearchSubtitleDom(filterCriteriaInfo) {
        const content = document.createDocumentFragment(),
            translator = Translator;

        if (filterCriteriaInfo['criteria'] != null) {
            const searchCriteria = filterCriteriaInfo['criteria'],
                totalCount = /** @type {number} */ (filterCriteriaInfo['totalCount']) || 0;

            let resultsMessage = translator.translate('no_search_results');

            if (totalCount == 1) {
                resultsMessage = translator.translate('totalcount_search_result', [HgStringUtils.formatNotificationsCount(totalCount)]);
            } else if (totalCount > 1) {
                resultsMessage = translator.translate('totalcount_search_result', [HgStringUtils.formatNotificationsCount(totalCount)]);
            }

            content.appendChild(DomUtils.createDom('span', 'hg-toolbar-subtitle-search-total-count', resultsMessage));
        }

        return content;
    }

    /**
     * Hide the dummy search icon before the expand, to avoid duplicated search icon during the animation
     * @private
     */
    beforeSearchFieldExpand_() {
        this.getButtonSet().setVisible(false);
    }

    /**
     * Handles back navigation request
     * @param {Event} e
     * @protected
     */
    handleNavigateBack(e) {
        /**@type {AbstractToolbarPresenter}*/(this.getPresenter()).goBack();
    }

    /**
     * Handles click on an action button (button from the left of the toolbar)
     * @param {Event} e Call event to handle.
     * @protected
     */
    handleButtonAction(e) {
        //nop
    }

    /**
     * Handles back navigation request
     * @param {Event} e
     * @protected
     */
    handleSearchButtonAction(e) {
        this.getSearchField().setVisible(true);

        setTimeout(() => {
            this.beforeSearchFieldExpand_();
            this.getSearchField().expand();
        }, 1);
    }

    /**
     * Handle search field expanding/collapsing.
     * @param {Event} evt The event
     * @protected
     */
    handleSearchFieldExpandCollapse(evt) {
        const isExpanded = this.getSearchField().isExpanded();

        if (isExpanded) {
            this.getSearchField().setVisible(true);
        } else {
            /* making the advanced search button invisible will also close its popup */
            this.getSearchField().setVisible(false);

            this.getButtonSet().setVisible(true);
        }
    }

    /**
     * Handle search filtering. Delegates to the presenter.
     * @param {Event} evt The event
     * @protected
     */
    handleSearchFieldFilter(evt) {
        this.search();
    }

    /**
     * Handles search selection. Delegates to the presenter.
     *
     * @param {Event} evt The event
     * @protected
     */
    handleSearchFieldSelect(evt) {
        //nop
    }

    /**
     * Handles the Submit event from the AdvancedSearchPopup.
     * Delegates to the presenter in order to save the changes on SearchFilter Model.
     * @private
     */
    handleAdvancedSearchSubmit_() {
        this.search();
    }

    /**
     * Handles the Dismiss event from the AdvancedSearchPopup.
     * Delegates to the presenter in order to discard changes on SearchFilter Model.
     * @private
     */
    handleAdvancedSearchDismiss_() {
        /**@type {AbstractToolbarPresenter}*/(this.getPresenter()).resetAdvancedSearchFilters();
    }

    /**
     * Handles search selection. Delegates to the presenter.
     *
     * @param {Event} evt The event
     * @protected
     */
    handleSearchFieldBlur(evt) {
        this.onSearchTriggersBlur_();
    }

    /**
     * Handles search selection. Delegates to the presenter.
     *
     * @param {Event} evt The event
     * @protected
     */
    handleAdvancedSearchButtonBlur(evt) {
        this.onSearchTriggersBlur_();
    }

    onSearchTriggersBlur_() {
        clearTimeout(this.exitSearchModeTimerId_);
        this.exitSearchModeTimerId_ = setTimeout(() => {
            if (!this.isInSearchState_
                && !this.getAdvancedSearchButton().isOpen()
                && this.searchField_
                && !this.searchField_.isFocused()
                && !this.searchField_.hasSearchValue()) {
                this.searchField_.collapse();
            }
        }, 300);
    }

    /**
     * Moves the back btn between facetTitleContainer and rightContainer.
     *
     * @param {boolean} enable true: Move back btn to facetTitleContainer; false: Move back btn to rightContainer
     * @protected
     */
    moveBackBtn(enable) {
        if (this.backBtn_) {
            if (enable) {
                this.facetTitleContainer_.removeChild(this.backBtn_, true);
                this.rightContainer_.addChildAt(this.backBtn_, 0, true);

                this.rightContainer_.addExtraCSSClass('searchMode');
            } else {
                this.rightContainer_.removeChild(this.backBtn_, true);
                this.facetTitleContainer_.addChild(this.backBtn_, true);

                this.rightContainer_.removeExtraCSSClass('searchMode');
            }
        }
    }
}
//hf.app.ui.IView.addImplementation(AbstractToolbarView);
/**
 * Separator placed between the category and the facet name when formatting a Facet criteria
 * @type {string}
 * @readonly
 */
AbstractToolbarView.SEPARATOR = ' / ';