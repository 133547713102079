import { EventsUtils } from '../events/Events.js';
import { EventTarget } from '../events/EventTarget.js';
import { ElementActivityMonitor } from './ElementActivityMonitor.js';
import { ActivityMonitor } from './ActivityMonitor.js';
import { IdleTimer } from './IdleTimer.js';

/**
 * Event target that will give notification of state changes between active and
 * idle. This class is designed to require few resources while the user is
 * active.
 *
 * @augments {EventTarget}
 * @final

 *
 */
export class ElementIdleTimer extends EventTarget {
    /**
     * @param {number} idleThreshold Amount of time in ms at which we consider the
     *     user has gone idle.
     * @param {Element} target Element to listen to.
     * @param {boolean=} opt_useBubble Whether to use the bubble phase to listen for
     *     events. By default listens on the capture phase so that it won't miss
     *     events that get stopPropagation/cancelBubble'd. However, this can cause
     *     problems in IE8 if the page loads multiple scripts that include the
     *     closure event handling code.
     *
     */
    constructor(idleThreshold, target, opt_useBubble) {
        super();

        /**
         * The amount of time in ms at which we consider the user has gone idle
         *
         * @type {number}
         * @private
         */
        this.idleThreshold_ = idleThreshold;

        /**
         * The activity monitor keeping track of user interaction
         *
         * @type {hf.ui.ElementActivityMonitor}
         * @private
         */
        this.activityMonitor_ = new ElementActivityMonitor(target, idleThreshold, opt_useBubble);

        /**
         * Whether a listener is currently registered for an idle timer event. On
         * initialization, the user is assumed to be active.
         *
         * @type {boolean}
         * @private
         */
        this.hasActivityListener_ = false;

        /**
         * Handle to the timer ID used for checking ongoing activity, or null
         *
         * @type {?number}
         * @private
         */
        this.onActivityTimerId_ = null;

        /**
         * Whether the user is currently idle
         *
         * @type {boolean}
         * @private
         */
        this.isIdle_ = false;

        // Decide whether the user is currently active or idle. This method will
        // check whether it is correct to start with the user in the active state.
        this.maybeStillActive_();
    }

    /**
     * Checks whether the user is active. If the user is still active, then a timer
     * is started to check again later.
     *
     * @private
     */
    maybeStillActive_() {
        // See how long before the user would go idle. The user is considered idle
        // after the idle time has passed, not exactly when the idle time arrives.
        const remainingIdleThreshold = this.idleThreshold_ + 1 - (Date.now() - this.activityMonitor_.getLastEventTime());

        if (remainingIdleThreshold > 0) {
            // The user is still active. Check again later.
            clearTimeout(this.onActivityTimerId_);
            this.onActivityTimerId_ = setTimeout(() => this.onActivityTick_(), remainingIdleThreshold);
        } else {
            // The user has not been active recently.
            this.becomeIdle_();
        }
    }

    /**
     * Handler for the timeout used for checking ongoing activity
     *
     * @private
     */
    onActivityTick_() {
        // The timer has fired.
        this.onActivityTimerId_ = null;

        // The maybeStillActive method will restart the timer, if appropriate.
        this.maybeStillActive_();
    }

    /**
     * Transitions from the active state to the idle state
     *
     * @private
     */
    becomeIdle_() {
        this.isIdle_ = true;

        // The idle timer will send notification when the user does something
        // interactive.
        EventsUtils.listen(this.activityMonitor_,
            ActivityMonitor.Event.ACTIVITY,
            this.onActivity_, false, this);
        this.hasActivityListener_ = true;

        // Notify clients of the state change.
        this.dispatchEvent(IdleTimer.Event.BECOME_IDLE);
    }

    /**
     * Handler for idle timer events when the user does something interactive
     *
     * @param {hf.events.Event} e The event object.
     * @private
     */
    onActivity_(e) {
        this.becomeActive_();
    }

    /**
     * Transitions from the idle state to the active state
     *
     * @private
     */
    becomeActive_() {
        this.isIdle_ = false;

        // Stop listening to every interactive event.
        this.removeActivityListener_();

        // Notify clients of the state change.
        this.dispatchEvent(IdleTimer.Event.BECOME_ACTIVE);

        // Periodically check whether the user has gone inactive.
        this.maybeStillActive_();
    }

    /**
     * Removes the activity listener, if necessary
     *
     * @private
     */
    removeActivityListener_() {
        if (this.hasActivityListener_) {
            EventsUtils.unlisten(this.activityMonitor_,
                ActivityMonitor.Event.ACTIVITY,
                this.onActivity_, false, this);
            this.hasActivityListener_ = false;
        }
    }

    /** @override */
    disposeInternal() {
        this.removeActivityListener_();
        if (this.onActivityTimerId_ != null) {
            clearTimeout(this.onActivityTimerId_);
            this.onActivityTimerId_ = null;
        }

        super.disposeInternal();
    }

    /**
     * @returns {number} the amount of time at which we consider the user has gone
     *     idle in ms.
     */
    getIdleThreshold() {
        return this.idleThreshold_;
    }

    /**
     * @returns {hf.ui.ElementActivityMonitor} the activity monitor keeping track of user
     *     interaction.
     */
    getActivityMonitor() {
        return this.activityMonitor_;
    }

    /**
     * Returns true if there has been no user action for at least the specified
     * interval, and false otherwise
     *
     * @returns {boolean} true if the user is idle, false otherwise.
     */
    isIdle() {
        return this.isIdle_;
    }

    /**
     * Dispatch idle
     *
     * @param {hf.events.BrowserEvent} e Mouse event to handle.
     * @private
     */
    handleMouseOut_(e) {
        if (!this.isIdle_) {
            this.becomeIdle_();
        }
    }

    /**
     * Updates the last event time to be the present time, useful for non-DOM
     * events that should update idle time.
     */
    resetTimer() {
        this.activityMonitor_.resetTimer();
    }
}
