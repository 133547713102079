import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import userAgent from "./../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Type declaration for a geolocation watcher
 * @typedef {{
 *  success: Function,
 *  error: Function,
 *  options: Object
 * }}
 */
export let WatcherInfo;

/**
 * Type declaration for a country
 * @typedef {{
 *  name: string,
 *  code: string,
 *  regionCount: number
 * }}
 */
export let Country;

/**
 *
 * @unrestricted
*/
export class GeolocationUtils {
    constructor() {
        //
    }

    /**
     * Clear all geolocation watchers
     * @param {boolean=} opt_temporary
     */
    static clearAllGeolocationWatchers(opt_temporary) {
        if (navigator && navigator.geolocation) {
            const watchersKeysArr = Array.from(GeolocationUtils.watchers_.keys());
            for(let i = 0, len = watchersKeysArr.length; i < len; i++) {
                GeolocationUtils.clearGeolocationWatchById(/**@type {number}*/(watchersKeysArr[i]), opt_temporary);
            }
        }
    }

    /**
     * Clear geolocation watch
     * @param {WatcherInfo} watcher
     * @param {boolean=} opt_temporary
     */
    static clearGeolocationWatch(watcher, opt_temporary) {
        if (navigator && navigator.geolocation) {
            let match;

            for (const [key, value] of GeolocationUtils.watchers_.entries()) {
                if (value['success'] === watcher['success']
                    && value['error'] === watcher['error']
                    && value['options'] === watcher['options']) {
                    match = key;
                    break;
                }
            }

            if (match != null) {
                navigator.geolocation.clearWatch(match);

                if (!opt_temporary) {
                    GeolocationUtils.watchers_.delete(match);
                }
            }
        }
    }

    /**
     * Clear geolocation watch by ID
     * @param {number} id If none provided, remove all
     * @param {boolean=} opt_temporary
     */
    static clearGeolocationWatchById(id, opt_temporary) {
        if (navigator && navigator.geolocation && id != null) {
            navigator.geolocation.clearWatch(id);

            if (!opt_temporary) {
                GeolocationUtils.watchers_.delete(id);
            }
        }
    }

    /**
     * Add geolocation watch
     * @param {function(GeolocationPosition): ?} success
     * @param {Function=} opt_error
     * @param {Object=} opt_options
     * @return {number|null} watch ID value then can be used to unregister the handler by passing it to the Geolocation.clearWatch() method
     */
    static watchPosition(success, opt_error, opt_options) {
        if (navigator && navigator.geolocation) {
            /* watch device location changes */
            let id = navigator.geolocation.watchPosition(success, opt_error, opt_options);

            /* store watch */
            if (id && !GeolocationUtils.watchers_.has(id)) {
                GeolocationUtils.watchers_.set(id, {
                    'success': success,
                    'error': opt_error,
                    'options': opt_options
                });

            }

            return id;
        }

        return null;
    }

    /**
     * Add geolocation watch
     * @param {function(GeolocationPosition): ?} success
     * @param {Function=} opt_error
     * @param {Object=} opt_options
     */
    static getCurrentPosition(success, opt_error, opt_options) {
        if (navigator && navigator.geolocation) {
            if (GeolocationUtils.watchers_.size === 0) {
                /* fetch current location */
                navigator.geolocation.getCurrentPosition(success, opt_error, opt_options);
            }
            else if (userAgent.browser.isFirefox()) {
                /**
                 * Geolocation success callback
                 * @param {...*} var_args Any extra arguments to pass.
                 */
                const fn_success = function (var_args) {
                    if (BaseUtils.isFunction(success)) {
                        success.apply(null, arguments);
                    }

                    /* clear position watcher */
                    if (currentPositionWatcher != null) {
                        GeolocationUtils.clearGeolocationWatchById(currentPositionWatcher, false);
                    }
                };

                /**
                 * Geolocation failure callback
                 * @param {...*} var_args Any extra arguments to pass.
                 */
                const fn_failure = function (var_args) {
                    if (BaseUtils.isFunction(opt_error)) {
                        opt_error.apply(null, arguments);
                    }

                    /* clear position watcher */
                    if (currentPositionWatcher != null) {
                        GeolocationUtils.clearGeolocationWatchById(currentPositionWatcher, false);
                    }
                };

                const currentPositionWatcher = GeolocationUtils.watchPosition(fn_success, fn_failure, opt_options);
            }
            else {
                /* stop all current position watchers */
                let watchersKeysArr = Array.from(GeolocationUtils.watchers_.keys());
                for(let i = 0, len = watchersKeysArr.length; i < len; i++) {
                    GeolocationUtils.clearGeolocationWatchById(/**@type {number}*/(watchersKeysArr[i]), true);
                }

                /* fetch current location */
                navigator.geolocation.getCurrentPosition(success, opt_error, opt_options);

                /* restore all position watchers
                * NOTE: firstly clone the map entries! */
                let watchersArr = Array.from(GeolocationUtils.watchers_.entries());
                for(let i = 0, len = watchersArr.length; i < len; i++) {
                    let watcherEntry = watchersArr[i];

                    GeolocationUtils.watchPosition(watcherEntry[1]['success'], watcherEntry[1]['error'], watcherEntry[1]['options']);

                    /* remove cleared id, a new one has been generated */
                    GeolocationUtils.watchers_.delete(watcherEntry[0]);
                }
            }
        }
    }
};


/**
 * Current geolocation watchers
 * @type {Map}
 * @private
 */
GeolocationUtils.watchers_ = new Map();

/**
 * The list of all countries.
 * @type {Array.<{Country}>}
 */
GeolocationUtils.CountriesList = [
    {"name": "Afghanistan", "code": "AF", "regionCount":38},
    {"name": "\u00c5land Islands", "code": "AX", "regionCount": 0},
    {"name": "Albania", "code": "AL", "regionCount": 12},
    {"name": "Algeria", "code": "DZ", "regionCount": 48},
    {"name": "American Samoa", "code": "AS", "regionCount": 0},
    {"name": "Andorra", "code": "AD", "regionCount": 7},
    {"name": "Angola", "code": "AO", "regionCount": 18},
    {"name": "Anguilla", "code": "AI", "regionCount": 0},
    {"name": "Antarctica", "code": "AQ", "regionCount": 0},
    {"name": "Antigua And Barbuda", "code": "AG", "regionCount": 7},
    {"name": "Argentina", "code": "AR", "regionCount": 24},
    {"name": "Armenia", "code": "AM", "regionCount": 11},
    {"name": "Aruba", "code": "AW", "regionCount": 0},
    {"name": "Australia", "code": "AU", "regionCount": 8},
    {"name": "Austria", "code": "AT", "regionCount": 9},
    {"name": "Azerbaijan", "code": "AZ", "regionCount": 71},
    {"name": "Bahamas", "code": "BS", "regionCount": 21},
    {"name": "Bahrain", "code": "BH", "regionCount": 17},
    {"name": "Bangladesh", "code": "BD", "regionCount": 70},
    {"name": "Barbados", "code": "BB", "regionCount": 11},
    {"name": "Belarus", "code": "BY", "regionCount": 7},
    {"name": "Belgium", "code": "BE", "regionCount": 12},
    {"name": "Belize", "code": "BZ", "regionCount": 6},
    {"name": "Benin", "code": "BJ", "regionCount": 7},
    {"name": "Bermuda", "code": "BM", "regionCount": 11},
    {"name": "Bhutan", "code": "BT", "regionCount": 18},
    {"name": "Bolivia", "code": "BO", "regionCount": 9},
    {"name": "Bosnia And Herzegovina", "code": "BA", "regionCount": 2},
    {"name": "Botswana", "code": "BW", "regionCount": 9},
    {"name": "Bouvet Island", "code": "BV", "regionCount": 0},
    {"name": "Brazil", "code": "BR", "regionCount": 27},
    {"name": "British Indian Ocean Territory", "code": "IO", "regionCount": 0},
    {"name": "Brunei Darussalam", "code": "BN", "regionCount": 12},
    {"name": "Bulgaria", "code": "BG", "regionCount": 29},
    {"name": "Burkina Faso", "code": "BF", "regionCount": 45},
    {"name": "Burundi", "code": "BI", "regionCount": 16},
    {"name": "Cambodia", "code": "KH", "regionCount": 20},
    {"name": "Cameroon", "code": "CM", "regionCount": 10},
    {"name": "Canada", "code": "CA", "regionCount": 13},
    {"name": "Cape Verde", "code": "CV", "regionCount": 16},
    {"name": "Cayman Islands", "code": "KY", "regionCount": 8},
    {"name": "Central African Republic", "code": "CF", "regionCount": 17},
    {"name": "Chad", "code": "TD", "regionCount": 14},
    {"name": "Chile", "code": "CL", "regionCount": 13},
    {"name": "China", "code": "CN", "regionCount": 31},
    {"name": "Christmas Island", "code": "CX", "regionCount": 0},
    {"name": "Cocos (keeling) Islands", "code": "CC", "regionCount": 0},
    {"name": "Colombia", "code": "CO", "regionCount": 37},
    {"name": "Comoros", "code": "KM", "regionCount": 3},
    {"name": "Congo", "code": "CG", "regionCount":10},
    {"name": "Congo, Democratic Republic Of The", "code": "CD", "regionCount": 12},
    {"name": "Cook Islands", "code": "CK", "regionCount": 0},
    {"name": "Costa Rica", "code": "CR", "regionCount": 7},
    {"name": "C\u00f4te D'Ivoire", "code": "CI", "regionCount": 21},
    {"name": "Croatia", "code": "HR", "regionCount": 21},
    {"name": "Cuba", "code": "CU", "regionCount": 15},
    {"name": "Cyprus", "code": "CY", "regionCount": 6},
    {"name": "Czech Republic", "code": "CZ", "regionCount": 29},
    {"name": "Denmark", "code": "DK", "regionCount": 20},
    {"name": "Djibouti", "code": "DJ", "regionCount": 6},
    {"name": "Dominica", "code": "DM", "regionCount": 10},
    {"name": "Dominican Republic", "code": "DO", "regionCount": 34},
    {"name": "Ecuador", "code": "EC", "regionCount": 22},
    {"name": "Egypt", "code": "EG", "regionCount": 26},
    {"name": "El Salvador", "code": "SV", "regionCount": 14},
    {"name": "Equatorial Guinea", "code": "GQ", "regionCount": 7},
    {"name": "Eritrea", "code": "ER", "regionCount": 6},
    {"name": "Estonia", "code": "EE", "regionCount": 21},
    {"name": "Ethiopia", "code": "ET", "regionCount": 19},
    {"name": "Falkland Islands (malvinas)", "code": "FK", "regionCount": 0},
    {"name": "Faroe Islands", "code": "FO", "regionCount": 0},
    {"name": "Fiji", "code": "FJ", "regionCount": 5},
    {"name": "Finland", "code": "FI", "regionCount": 6},
    {"name": "France", "code": "FR", "regionCount": 22},
    {"name": "French Guiana", "code": "GF", "regionCount": 0},
    {"name": "French Polynesia", "code": "PF", "regionCount": 0},
    {"name": "French Southern Territories", "code": "TF", "regionCount": 0},
    {"name": "Gabon", "code": "GA", "regionCount": 9},
    {"name": "Gambia", "code": "GM", "regionCount": 6},
    {"name": "Georgia", "code": "GE", "regionCount": 64},
    {"name": "Germany", "code": "DE", "regionCount": 16},
    {"name": "Ghana", "code": "GH", "regionCount": 10},
    {"name": "Gibraltar", "code": "GI", "regionCount": 0},
    {"name": "Greece", "code": "GR", "regionCount": 51},
    {"name": "Greenland", "code": "GL", "regionCount": 3},
    {"name": "Grenada", "code": "GD", "regionCount": 6},
    {"name": "Guadeloupe", "code": "GP", "regionCount": 0},
    {"name": "Guam", "code": "GU", "regionCount": 0},
    {"name": "Guatemala", "code": "GT", "regionCount": 22},
    {"name": "Guernsey", "code": "GG", "regionCount": 0},
    {"name": "Guinea", "code": "GN", "regionCount": 34},
    {"name": "Guinea-bissau", "code": "GW", "regionCount": 9},
    {"name": "Guyana", "code": "GY", "regionCount": 10},
    {"name": "Haiti", "code": "HT", "regionCount": 10},
    {"name": "Heard Island And Mcdonald Islands", "code": "HM", "regionCount": 0},
    {"name": "Honduras", "code": "HN", "regionCount": 18},
    {"name": "Hong Kong", "code": "HK", "regionCount": 0},
    {"name": "Hungary", "code": "HU", "regionCount": 42},
    {"name": "Iceland", "code": "IS", "regionCount": 25},
    {"name": "India", "code": "IN", "regionCount": 35},
    {"name": "Indonesia", "code": "ID", "regionCount": 40},
    {"name": "Iran, Islamic Republic Of", "code": "IR", "regionCount": 40},
    {"name": "Iraq", "code": "IQ", "regionCount": 18},
    {"name": "Ireland", "code": "IE", "regionCount": 26},
    {"name": "Isle Of Man", "code": "IM", "regionCount": 0},
    {"name": "Israel", "code": "IL", "regionCount": 6},
    {"name": "Italy", "code": "IT", "regionCount": 20},
    {"name": "Jamaica", "code": "JM", "regionCount": 14},
    {"name": "Japan", "code": "JP", "regionCount": 47},
    {"name": "Jersey", "code": "JE", "regionCount": 0},
    {"name": "Jordan", "code": "JO", "regionCount": 9},
    {"name": "Kazakhstan", "code": "KZ", "regionCount": 17},
    {"name": "Kenya", "code": "KE", "regionCount": 8},
    {"name": "Kiribati", "code": "KI", "regionCount": 3},
    {"name": "Korea, Democratic People's Republic Of", "code": "KP", "regionCount": 13},
    {"name": "Korea, Republic Of ", "code": "KR", "regionCount": 16},
    {"name": "Kosovo", "code": "XK", "regionCount": 0},
    {"name": "Kuwait", "code": "KW", "regionCount": 6},
    {"name": "Kyrgyzstan", "code": "KG", "regionCount": 9},
    {"name": "Lao People's Democratic Republic", "code": "LA", "regionCount": 13},
    {"name": "Latvia", "code": "LV", "regionCount": 33},
    {"name": "Lebanon", "code": "LB", "regionCount": 10},
    {"name": "Lesotho", "code": "LS", "regionCount": 10},
    {"name": "Liberia", "code": "LR", "regionCount": 10},
    {"name": "Libyan Arab Jamahiriya", "code": "LY", "regionCount": 25},
    {"name": "Liechtenstein", "code": "LI", "regionCount": 13},
    {"name": "Lithuania", "code": "LT", "regionCount": 10},
    {"name": "Luxembourg", "code": "LU", "regionCount": 3},
    {"name": "Macao", "code": "MO", "regionCount": 2},
    {"name": "Macedonia", "code": "MK", "regionCount": 123},
    {"name": "Madagascar", "code": "MG", "regionCount": 6},
    {"name": "Malawi", "code": "MW", "regionCount": 27},
    {"name": "Malaysia", "code": "MY", "regionCount": 16},
    {"name": "Maldives", "code": "MV", "regionCount": 20},
    {"name": "Mali", "code": "ML", "regionCount": 9},
    {"name": "Malta", "code": "MT", "regionCount": 0},
    {"name": "Marshall Islands", "code": "MH", "regionCount": 0},
    {"name": "Martinique", "code": "MQ", "regionCount": 0},
    {"name": "Mauritania", "code": "MR", "regionCount": 12},
    {"name": "Mauritius", "code": "MU", "regionCount": 12},
    {"name": "Mayotte", "code": "YT", "regionCount": 0},
    {"name": "Mexico", "code": "MX", "regionCount": 32},
    {"name": "Micronesia, Federated States Of", "code": "FM", "regionCount": 4},
    {"name": "Moldova, Republic Of", "code": "MD", "regionCount": 45},
    {"name": "Monaco", "code": "MC", "regionCount": 3},
    {"name": "Mongolia", "code": "MN", "regionCount": 24},
    {"name": "Montenegro", "code": "ME", "regionCount": 0},
    {"name": "Montserrat", "code": "MS", "regionCount": 3},
    {"name": "Morocco", "code": "MA", "regionCount": 54},
    {"name": "Mozambique", "code": "MZ", "regionCount": 11},
    {"name": "Myanmar", "code": "MM", "regionCount": 15},
    {"name": "Namibia", "code": "NA", "regionCount": 38},
    {"name": "Nauru", "code": "NR", "regionCount": 14},
    {"name": "Nepal", "code": "NP", "regionCount": 14},
    {"name": "Netherlands", "code": "NL", "regionCount": 16},
    {"name": "Netherlands Antilles", "code": "AN", "regionCount": 0},
    {"name": "New Caledonia", "code": "NC", "regionCount": 0},
    {"name": "New Zealand", "code": "NZ", "regionCount": 16},
    {"name": "Nicaragua", "code": "NI", "regionCount": 16},
    {"name": "Niger", "code": "NE", "regionCount": 8},
    {"name": "Nigeria", "code": "NG", "regionCount": 39},
    {"name": "Niue", "code": "NU", "regionCount": 0},
    {"name": "Norfolk Island", "code": "NF", "regionCount": 0},
    {"name": "Northern Mariana Islands", "code": "MP", "regionCount": 0},
    {"name": "Norway", "code": "NO", "regionCount": 19},
    {"name": "Oman", "code": "OM", "regionCount": 8},
    {"name": "Pakistan", "code": "PK", "regionCount": 8},
    {"name": "Palau", "code": "PW", "regionCount": 0},
    {"name": "Palestinian Territory, Occupied", "code": "PS", "regionCount": 2},
    {"name": "Panama", "code": "PA", "regionCount": 10},
    {"name": "Papua New Guinea", "code": "PG", "regionCount": 20},
    {"name": "Paraguay", "code": "PY", "regionCount": 19},
    {"name": "Peru", "code": "PE", "regionCount": 25},
    {"name": "Philippines", "code": "PH", "regionCount": 134},
    {"name": "Pitcairn", "code": "PN", "regionCount": 0},
    {"name": "Poland", "code": "PL", "regionCount": 65},
    {"name": "Portugal", "code": "PT", "regionCount": 20},
    {"name": "Puerto Rico", "code": "PR", "regionCount": 0},
    {"name": "Qatar", "code": "QA", "regionCount": 11},
    {"name": "R\u00e9union", "code": "RE", "regionCount": 0},
    {"name": "Romania", "code": "RO", "regionCount": 42},
    {"name": "Russian Federation", "code": "RU", "regionCount": 91},
    {"name": "Rwanda", "code": "RW", "regionCount": 8},
    {"name": "Saint Barthelemy", "code": "BL", "regionCount": 0},
    {"name": "Saint Helena, Ascension and Tristan da Cunha", "code": "SH", "regionCount": 3},
    {"name": "Saint Kitts And Nevis", "code": "KN", "regionCount": 14},
    {"name": "Saint Lucia", "code": "LC", "regionCount": 11},
    {"name": "Saint Pierre And Miquelon", "code": "PM", "regionCount": 0},
    {"name": "Saint Vincent And The Grenadines", "code": "VC", "regionCount": 6},
    {"name": "Samoa", "code": "WS", "regionCount": 10},
    {"name": "San Marino", "code": "SM", "regionCount": 9},
    {"name": "Sao Tome And Principe", "code": "ST", "regionCount": 2},
    {"name": "Saudi Arabia", "code": "SA", "regionCount": 14},
    {"name": "Senegal", "code": "SN", "regionCount": 12},
    {"name": "Serbia", "code": "RS", "regionCount": 3},
    {"name": "Seychelles", "code": "SC", "regionCount": 23},
    {"name": "Sierra Leone", "code": "SL", "regionCount": 4},
    {"name": "Singapore", "code": "SG", "regionCount": 0},
    {"name": "Slovakia", "code": "SK", "regionCount": 8},
    {"name": "Slovenia", "code": "SI", "regionCount": 141},
    {"name": "Solomon Islands", "code": "SB", "regionCount": 9},
    {"name": "Somalia", "code": "SO", "regionCount": 20},
    {"name": "South Africa", "code": "ZA", "regionCount": 9},
    {"name": "South Georgia And The South Sandwich Islands", "code": "GS", "regionCount": 0},
    {"name": "Spain", "code": "ES", "regionCount": 17},
    {"name": "Sri Lanka", "code": "LK", "regionCount": 32},
    {"name": "Sudan", "code": "SD", "regionCount": 9},
    {"name": "Suriname", "code": "SR", "regionCount": 10},
    {"name": "Svalbard And Jan Mayen", "code": "SJ", "regionCount": 0},
    {"name": "Swaziland", "code": "SZ", "regionCount": 5},
    {"name": "Sweden", "code": "SE", "regionCount": 26},
    {"name": "Switzerland", "code": "CH", "regionCount": 26},
    {"name": "Syrian Arab Republic", "code": "SY", "regionCount": 14},
    {"name": "Taiwan, Province Of China", "code": "TW", "regionCount": 4},
    {"name": "Tajikistan", "code": "TJ", "regionCount": 3},
    {"name": "Tanzania, United Republic Of ", "code": "TZ", "regionCount": 26},
    {"name": "Thailand", "code": "TH", "regionCount": 74},
    {"name": "Timor-leste", "code": "TL", "regionCount": 0},
    {"name": "Togo", "code": "TG", "regionCount": 7},
    {"name": "Tokelau", "code": "TK", "regionCount": 0},
    {"name": "Tonga", "code": "TO", "regionCount": 3},
    {"name": "Trinidad And Tobago", "code": "TT", "regionCount": 12},
    {"name": "Tunisia", "code": "TN", "regionCount": 25},
    {"name": "Turkey", "code": "TR", "regionCount": 81},
    {"name": "Turkmenistan", "code": "TM", "regionCount": 5},
    {"name": "Turks And Caicos Islands", "code": "TC", "regionCount": 0},
    {"name": "Tuvalu", "code": "TV", "regionCount": 0},
    {"name": "Uganda", "code": "UG", "regionCount": 39},
    {"name": "Ukraine", "code": "UA", "regionCount": 27},
    {"name": "United Arab Emirates", "code": "AE", "regionCount": 7},
    {"name": "United Kingdom", "code": "GB", "regionCount": 252},
    {"name": "United States", "code": "US", "regionCount": 62},
    {"name": "United States Minor Outlying Islands", "code": "UM", "regionCount": 0},
    {"name": "Uruguay", "code": "UY", "regionCount": 19},
    {"name": "Uzbekistan", "code": "UZ", "regionCount": 14},
    {"name": "Vanuatu", "code": "VU", "regionCount": 14},
    {"name": "Vatican City", "code": "VA", "regionCount": 0},
    {"name": "Venezuela", "code": "VE", "regionCount": 25},
    {"name": "Vietnam", "code": "VN", "regionCount": 85},
    {"name": "Virgin Islands, British", "code": "VG", "regionCount": 0},
    {"name": "Virgin Islands, U.S.", "code": "VI", "regionCount": 0},
    {"name": "Wallis And Futuna ", "code": "WF", "regionCount": 0},
    {"name": "Western Sahara", "code": "EH", "regionCount": 0},
    {"name": "Yemen", "code": "YE", "regionCount": 17},
    {"name": "Zambia", "code": "ZM", "regionCount": 9},
    {"name": "Zimbabwe", "code": "ZW", "regionCount": 10}
];

/**
 * The map of countries indexed by country's code.
 * @type {Object.<string, {Country}>}
 */
GeolocationUtils.CountriesMap =
    Object.assign({}, ...GeolocationUtils.CountriesList.map(item => ({[item['code']]: item})));
