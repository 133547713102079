import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {PhoneEventType} from "./../../Common.js";
import {PhoneReceiverEventType} from "./Enums.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import {PhoneCallStatus, PhoneExtensionAgentDeviceTypes} from "./../../../../data/model/phonecall/Enums.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class RingingState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.notice_;

        /**
         * @type {hg.module.phone.PhoneCallPartyDetails}
         * @private
         */
        this.partyDetails_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.actionButtonSet_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.transferButtonSet_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.callDetails_;

        /**
         * @type {hf.ui.Loader}
         * @private
         */
        this.loader_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-incoming';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.partyDetails_ = new PhoneCallPartyDetails({
            'avatar': {
                'extraCSSClass': ['grayscheme'],
                'avatarSize' : AvatarSizes.LARGE,
                'showInfoBubble': false
            }
        });

        this.callDetails_ = new Caption({
            'contentFormatter': function (model) {
                if (model == null || model['phone'] == null) {
                    return '';
                }

                const content = document.createDocumentFragment(),
                    phone = model['phone'];
                let isWebPhone = model['isWebPhone'];
                const localVideo = model['isVideo'],
                    hasActiveCall = phone['activeCall'] != null && phone['incomingCall'] != phone['activeCall'];

                if (!isWebPhone) {
                    content.appendChild(DomUtils.createDom('span', 'hg-info small'));
                    content.appendChild(document.createTextNode(translator.translate('pick_your_phone')));
                } else {
                    content.appendChild(DomUtils.createDom('div', 'hg-call-type', translator.translate(localVideo ? 'incoming_video_call' : 'incoming_audio_call')));
                }

                if (hasActiveCall) {
                    content.appendChild(DomUtils.createDom('span', 'hg-vcard-label', translator.translate('new')));
                }

                return content;
            }
        });

        this.notice_ = new Caption({
            'extraCSSClass' : 'hg-call-hold-warning',
            'content': translator.translate('answering_calls_hold'),
            'hidden': true
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass' : 'hg-call-answer-btnset',
            'hidden'        : true
        });
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'hangup',
            'name'          : RingingState.Button_.HANGUP
        }));
        this.actionButtonSet_.addButton(HgButtonUtils.createSecondaryButton('', translator.translate('ignore'), false, {
            'name' : RingingState.Button_.IGNORE
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'video',
            'name'          : RingingState.Button_.VIDEO,
            'hidden'        : true
        }));
        this.actionButtonSet_.addButton(new Button({
            'extraCSSClass' : 'audio',
            'name'          : RingingState.Button_.AUDIO
        }));

        this.transferButtonSet_ = new ButtonSet({
            'extraCSSClass': 'hg-call-action-btnset'
        });
        this.transferButtonSet_.addButton(new Button({
            'extraCSSClass' : 'transfer',
            'name'          : RingingState.Button_.TRANSFER
        }));
        this.transferButtonSet_.addButton(new Button({
            'extraCSSClass' : 'voicemail',
            'name'          : RingingState.Button_.VOICEMAIL,
            'disabled'      : true
        }));

        this.loader_ = new Loader({
            'hidden'        : true
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.notice_);
        this.notice_ = null;

        BaseUtils.dispose(this.partyDetails_);
        this.partyDetails_ = null;

        BaseUtils.dispose(this.actionButtonSet_);
        this.actionButtonSet_ = null;

        BaseUtils.dispose(this.transferButtonSet_);
        this.transferButtonSet_ = null;

        BaseUtils.dispose(this.callDetails_);
        this.callDetails_ = null;

        BaseUtils.dispose(this.loader_);
        this.loader_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const container = new LayoutContainer();
        container.addChild(this.notice_, true);
        container.addChild(this.partyDetails_, true);
        container.addChild(this.actionButtonSet_, true);
        container.addChild(this.loader_, true);

        const footer = new LayoutContainer({'extraCSSClass': 'hg-footer'});
        footer.addChild(this.callDetails_, true);
        footer.addChild(this.transferButtonSet_, true);

        this.addChild(container, true);
        this.addChild(footer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleCallButtonAction_)
            .listen(this.transferButtonSet_, UIComponentEventTypes.ACTION, this.handleTransferButtonAction_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callDetails_, {'set': this.callDetails_.setModel}, {
            'sources': [
                {'sourceProperty': 'phone'},
                {'sourceProperty': 'phone.incomingCall.remoteVideo'},
                {'sourceProperty': 'phone.extension.agentDevice'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    return {
                        'phone': sources[0],
                        'isVideo': !!sources[1],
                        'isWebPhone': sources[2] === PhoneExtensionAgentDeviceTypes.WEB
                    }
                }
            }
        });

        this.setBinding(this.partyDetails_, {'set': this.partyDetails_.setModel}, 'phone.incomingCall.party');

        const videoBtn = this.actionButtonSet_.getButtonByName(RingingState.Button_.VIDEO);
        this.setBinding(videoBtn, {'set': videoBtn.setVisible}, {
            'sources': [
                {'sourceProperty': 'phone.incomingCall.remoteVideo'},
                {'source': HgCurrentUser, 'sourceProperty': 'hasCamera'}
            ],
            'converter': {
                'sourceToTargetFn' : function (sources) {
                    return !!sources[0] && !!sources[1];
                }
            }
        });

        this.setBinding(this, {'set': this.onActiveCallOrVoicemailChange_}, {
            'sources': [
                {'sourceProperty': 'phone.incomingCall'},
                {'sourceProperty': 'phone.activeCall'},
                {'sourceProperty': 'phone.extension.settings.voicemailActive'}
            ]
        });

        this.setBinding(this, {'set': this.onDeviceTypeChange_}, 'phone.extension.agentDevice');

        this.setBinding(this.actionButtonSet_, {'set': this.actionButtonSet_.setEnabled}, {
            'sourceProperty': 'phone.incomingCall.status',
            'converter': {
                'sourceToTargetFn': function (status) {
                    return status != PhoneCallStatus.ANSWERING;
                }
            }
        });

        this.setBinding(this.transferButtonSet_, {'set': this.transferButtonSet_.setEnabled}, {
            'sourceProperty': 'phone.incomingCall.status',
            'converter': {
                'sourceToTargetFn': function (status) {
                    return status != PhoneCallStatus.ANSWERING;
                }
            }
        });
    }

    /**
     * Compute visibility of answer and transfer btn
     * @param {Array} sources
     * @private
     */
    onActiveCallOrVoicemailChange_(sources) {
        sources = sources || [];

        const incomingCall = sources[0],
            activeCall = sources[1];
        let voicemailActive = !!sources[2];

        const voicemailBtn = this.transferButtonSet_.getButtonByName(RingingState.Button_.VOICEMAIL),
            hangupBtn = this.actionButtonSet_.getButtonByName(RingingState.Button_.HANGUP),
            ignoreBtn = this.actionButtonSet_.getButtonByName(RingingState.Button_.IGNORE);

        /* hasActiveCall */
        const hasActiveCall = activeCall != null && incomingCall != activeCall;

        if (hasActiveCall) {
            this.notice_.setVisible(true);
            voicemailBtn.setVisible(false);
            voicemailBtn.setEnabled(false);
            hangupBtn.setVisible(!voicemailActive);
            ignoreBtn.setVisible(voicemailActive);
        } else {
            this.notice_.setVisible(false);
            ignoreBtn.setVisible(false);
            hangupBtn.setVisible(true);
            voicemailBtn.setVisible(voicemailActive);
            voicemailBtn.setEnabled(voicemailActive);
        }
    }

    /**
     * Show hide loader and action buttons relative to curren device type
     * @param {boolean} agentDevice
     * @private
     */
    onDeviceTypeChange_(agentDevice) {
        let isWebPhone = (agentDevice == PhoneExtensionAgentDeviceTypes.WEB);

        if (!isWebPhone) {
            this.addExtraCSSClass('remote-phone');
        } else {
            this.removeExtraCSSClass('remote-phone');
        }

        this.loader_.setVisible(!isWebPhone);
        this.actionButtonSet_.setVisible(!!isWebPhone);
    }

    /**
     * Handler for incoming call actions
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleCallButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            model = this.getModel();

        let eventType;
        switch (btn.getName()) {
            case RingingState.Button_.IGNORE:
                eventType = PhoneEventType.TRANSFER_TO_VOICEMAIL;
                break;

            case RingingState.Button_.HANGUP:
                eventType = PhoneEventType.HANGUP;
                break;

            case RingingState.Button_.AUDIO:
                model.set('phone.incomingCall.localVideo', false);
                eventType = PhoneEventType.ANSWER;
                break;

            case RingingState.Button_.VIDEO:
                model.set('phone.incomingCall.localVideo', true);
                eventType = PhoneEventType.ANSWER;
                break;

        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('call', model.get('phone.incomingCall'));
                event.addProperty('video', !!model.get('phone.incomingCall.localVideo'));

            this.dispatchEvent(event);
        }
    }

    /**
     * Handler for incoming call transfer
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleTransferButtonAction_(e) {
        const btn = /** */(e.getTarget()),
            model = this.getModel();

        let eventType;
        switch (btn.getName()) {
            case RingingState.Button_.TRANSFER:
                /* internal event: initiate transfer to party */
                eventType = PhoneReceiverEventType.INITIATE_TRANSFER;
                break;

            case RingingState.Button_.VOICEMAIL:
                eventType = PhoneEventType.TRANSFER_TO_VOICEMAIL;
                break;

            default:
                break;
        }

        if (eventType != null) {
            const event = new Event(eventType);
                event.addProperty('call', model.get('phone.incomingCall'));

            this.dispatchEvent(event);
        }
    }
};
/**
 * Specific button names
 * @enum {string}
 * @private
 */
RingingState.Button_ = {
    IGNORE : 'ignore',
    HANGUP : 'hangup',
    VIDEO: 'video',
    AUDIO: 'audio',
    VOICEMAIL: 'voicemail',
    TRANSFER: 'transfer'
};