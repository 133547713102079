import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HorizontalStack} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {
    List,
    ListItemsLayout,
    ListLoadingTrigger
} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Separator} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {InvoiceListItemContent} from "./../../billing/InvoiceListItemContent.js";
import {ListUtils} from "./../../../../../common/ui/list/List.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {Invoice} from "./../../../../../data/model/billing/Invoice.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const InvoicesEventType = {
    /**
     * Dispatched when the user clicks on the invoice number
     * @event hg.module.settings.form.Invoices.VIEW_INVOICE
     */
    VIEW_INVOICE : 'view_invoice',

    /**
     * Dispatched when the user clicks on the pay invoice trigger
     * @event hg.module.settings.form.Invoices.PAY_INVOICE
     */
    PAY_INVOICE : 'pay_invoice'
};

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class Invoices extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The message displayed when there are not invoices
         * @type {hf.ui.Caption}
         * @private
         */
        this.noInvoices_ = this.noInvoices_ === undefined ? null : this.noInvoices_;

        /**
         * The details about the last invoice
         * @type {hf.ui.UIControl}
         * @private
         */
        this.latestInvoiceDetails_ = this.latestInvoiceDetails_ === undefined ? null : this.latestInvoiceDetails_;

        /**
         * The header for last invoice list
         * @type {hf.ui.Caption}
         * @private
         */
        this.olderInvoicesHeader_ = this.olderInvoicesHeader_ === undefined ? null : this.olderInvoicesHeader_;

        /**
         * The list with the older invoices
         * @type {hf.ui.list.List}
         * @private
         */
        this.olderInvoicesList_ = this.olderInvoicesList_ === undefined ? null : this.olderInvoicesList_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'billing-invoices';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-invoices-form');

        const translator = Translator;

        this.noInvoices_ = new Caption({
            'extraCSSClass'	: ['hg-billing-page-header', 'hg-billing-no-invoices-message'],
            'content'		: translator.translate('no_invoices_yet')
        });

        this.latestInvoiceDetails_ = new Caption({
            'extraCSSClass'		: ['hg-billing-page-header', 'hg-billing-invoices-latest-details'],
            'contentFormatter'	: this.createLatestInvoiceDom_.bind(this)
        });

        this.olderInvoicesHeader_ = new Caption({
            'content'      : translator.translate('previous_invoices'),
            'extraCSSClass': ['hg-billing-page-header', 'hg-billing-invoices-order-invoices-header']
        });

        this.olderInvoicesList_ = new List({
            'itemContentFormatter': function (model, item) {
                return model != null ? new InvoiceListItemContent({'model': model}) : null;
            },
            'itemStyle'             : 'hg-billing-invoice-list-item',
            'extraCSSClass'			: 'hg-billing-invoices-order-invoices-list',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'isScrollable'          : true,
            'loadMoreItemsTrigger'	: ListLoadingTrigger.END_EDGE,
            'emptyContentFormatter' : function() {
                return translator.translate('no_invoices_yet');
            },
            'errorFormatter': ListUtils.createErrorFormatter
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.olderInvoicesList_, {'set': this.olderInvoicesList_.setItemsSource}, {
            'sourceProperty': 'invoicesList',
            'converter'		: {
                'sourceToTargetFn': function(invoicesList) {
                    let invoices = null;

                    /* remove the last invoice from invoices list */
                    if (invoicesList != null) {
                        invoices = invoicesList.getAll();

                        if (invoices.length > 0) {
                            invoices.splice(0, 1);
                        }
                    }

                    return invoices;
                }
            }
        });

        this.setBinding(this.latestInvoiceDetails_, {'set': this.latestInvoiceDetails_.setModel}, {
            'sourceProperty': 'invoicesList',
            'converter'		: {
                'sourceToTargetFn': function(invoicesList) {
                    let lastInvoice = null;

                    /* remove the last invoice from invoices list */
                    if (invoicesList != null) {
                        lastInvoice = invoicesList.getAt(0);
                    }

                    return lastInvoice;
                }
            }
        });

        this.setBinding(this, {'set': this.setControlsVisibility_}, 'invoicesListCount');

        this.setBinding(this, {'set': (invoicesListCount) => {
            if(invoicesListCount == null || !BaseUtils.isNumber(invoicesListCount)) {
                return;
            }

            if (invoicesListCount > 1) {
                this.latestInvoiceDetails_.removeExtraCSSClass(Invoices.ExtraCSSClass_.ONLY_LATEST_INVOICE);
            } else {
                this.latestInvoiceDetails_.addExtraCSSClass(Invoices.ExtraCSSClass_.ONLY_LATEST_INVOICE);
            }
        }}, 'invoicesListCount');
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addChild(this.noInvoices_, true);
        this.contentContainer.addChild(this.latestInvoiceDetails_, true);
        this.contentContainer.addChild(this.olderInvoicesHeader_, true);
        this.contentContainer.addChild(this.olderInvoicesList_, true);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.latestInvoiceDetails_);
        this.latestInvoiceDetails_ = null;

        BaseUtils.dispose(this.olderInvoicesHeader_);
        this.olderInvoicesHeader_ = null;

        BaseUtils.dispose(this.olderInvoicesList_);
        this.olderInvoicesList_ = null;

        BaseUtils.dispose(this.noInvoices_);
        this.noInvoices_ = null;
    }

    /**
     * Update the visibility on children controls depends on the numbers of invoices item in invoice list
     * @param {!number} invoicesListCount The count for invoices list
     * @private
     */
    setControlsVisibility_(invoicesListCount) {
        const invoicesListCountValue = BaseUtils.isNumber(invoicesListCount) ? invoicesListCount : 0;

        /* the invoices list is displayed when there are at least two invoices: one is displayed in last invoice
        section and one as list item */
        if (this.olderInvoicesList_ != null) {
            this.olderInvoicesList_.setVisible(invoicesListCountValue > 1);
        }

        /* the header for the invoices list is displayed when there are at least two invoices: one is displayed in last invoice
        section and one as list item */
        if (this.olderInvoicesHeader_ != null) {
            this.olderInvoicesHeader_.setVisible(invoicesListCountValue > 1);
        }

        /* the details of the last invoices are displayed when there is at least one invoice */
        if (this.latestInvoiceDetails_ != null) {
            this.latestInvoiceDetails_.setVisible(invoicesListCountValue > 0);
        }

        /* no invoices message is displayed when there are no invoices */
        if (this.noInvoices_ != null) {
            this.noInvoices_.setVisible(invoicesListCountValue == 0);
        }
    }

    /**
     * Handles CLICK on view invoice details for latest invoice. Display invoice details window
     * @param {hf.events.Event} e
     * @private
     */
    handleViewInvoice_(e) {
        const model = this.getModel(),
            invoicesList = model != null ? model['invoicesList'] : null;

        /* get the latest invoice */
        if (invoicesList != null &&
            invoicesList.getAt(0 != null) && (invoicesList.getAt(0) instanceof Invoice)) {

            /* dispatch view_invoice event */
            const event = new Event(InvoicesEventType.VIEW_INVOICE);
            event.addProperty('invoiceId', invoicesList.getAt(0)['invoiceId']);

            this.dispatchEvent(event);
        }
    }

    /**
     * Handles CLICK on pay now trigger
     * @param {hf.events.Event} e
     * @private
     */
    handlePayInvoice_(e) {
        const model = this.getModel(),
            invoicesList = model != null ? model['invoicesList'] : null;

        /* get the latest invoice */
        if (invoicesList != null &&
            invoicesList.getAt(0 != null) && (invoicesList.getAt(0) instanceof Invoice)) {

            /* dispatch pay_now event */
            const event = new Event(InvoicesEventType.PAY_INVOICE);
            event.addProperty('invoiceId', invoicesList.getAt(0)['invoiceId']);

            this.dispatchEvent(event);
        }
    }

    /**
     * Creates the DOM for the latest invoice details
     * @param {*} model
     * @returns {UIControlContent}
     * @protected
     */
    createLatestInvoiceDom_(model) {
        if (model == null) {
            return null;
        }

        const translator = Translator;

        const content = [];

        /* add the header for latest invoice area */
        content.push(new Caption({
            'extraCSSClass'	: ['hg-billing-page-header', 'hg-billing-invoices-latest-header'],
            'content'		: translator.translate('latest_invoice')
        }));

        /* add invoice total value */
        if (model['totalRaw'] != null) {
            content.push(new UIControl({
                'content'		: model['totalRaw'],
                'extraCSSClass'	: ['hg-billing-page-header', 'hg-billing-invoices-latest-total']
            }));
        }

        /* add status marker and the triggers for invoice changes */
        const actionsTriggersDom = this.createInvoiceActionsTriggersDom_(model);
        if (actionsTriggersDom != null) {
            content.push(actionsTriggersDom);
        }

        return content;
    }

    /**
     * Creates the DOM for the triggers that execute actions on a invoice model
     * @param {*} model
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createInvoiceActionsTriggersDom_(model) {
        const translator = Translator;
        
        /* add view invoice trigger and change card trigger */
        const actionsTriggersDom = new HorizontalStack({'extraCSSClass': 'latest-invoice-option-changes-container'});

        /* add invoice status marker*/
        if (!StringUtils.isEmptyOrWhitespace(model['statusRaw'])) {
            actionsTriggersDom.addChild(new UIControl({
                'content'		: model['statusRaw'],
                'baseCSSClass'	: 'hg-billing-invoices-latest-status',
                'extraCSSClass'	: ['invoice-status', model['statusRaw'].toLowerCase()]
            }), true);
        }

        /* First trigger - displayed for each invoice, does not depends on invoice status: add view invoice trigger */
        if (model['invoiceId'] != null) {
            const viewInvoiceTrigger = HgButtonUtils.createLinkButton(null, false, {
                'content': translator.translate('view_invoice'),
                'extraCSSClass': ['view-invoice-trigger']
            });
            actionsTriggersDom.addChild(viewInvoiceTrigger, true);
            this.getHandler().listen(viewInvoiceTrigger, UIComponentEventTypes.ACTION, this.handleViewInvoice_);
        }

        /* Other triggers: display pay_now triggers only when the invoice is OVERDUE or UNPAID (while the paid property is undefined).*/
        if (model['paid'] == null) {
            /* add triggers separator */
            actionsTriggersDom.addChild(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}), true);

            /* add pay_now trigger */
            const payInvoiceTrigger = HgButtonUtils.createLinkButton(null, false, {
                'content': translator.translate('pay_now').toLowerCase(),
                'extraCSSClass': ['pay-now-invoice-trigger']
            });
            actionsTriggersDom.addChild(payInvoiceTrigger, true);
            this.getHandler().listen(payInvoiceTrigger, UIComponentEventTypes.ACTION, this.handlePayInvoice_);
        }

        return actionsTriggersDom;
    }
};
/**
 * CSS classes by this component
 * @enum {string}
 * @private
 */
Invoices.ExtraCSSClass_ = {
	ONLY_LATEST_INVOICE : 'only-latest-invice'
};