import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {LayoutContainer} from "./../../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {StyleUtils} from "./../../../../../../../../hubfront/phpnoenc/js/style/Style.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {ObjectUtils} from "../../../../../../../../hubfront/phpnoenc/js/object/object.js";

/**
 * Constants for event names dispatched by bubbles.
 * @enum {string}
 * @readonly
 */
export const SuggestionsBubbleEventTypes = {
    /** triggered from the PhoneContent when clicking on a phone number to call a contact
     * @event ContactBubbleEventType.PERSON_CALL */
    SUGGESTION_PICK : StringUtils.createUniqueString('suggestionpick')
};

/**
 * Creates a new bubble for PersonReference editor plugin
 * @extends {Popup}
 * @unrestricted 
*/
export class SuggestionsBubbleBase extends Popup {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Header of the list
         * @type {hf.ui.Caption}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * List with suggested people
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.selector_ = this.selector_ === undefined ? null : this.selector_;

        /**
         * Reference to editor
         * @type {hf.ui.editor.FieldBase}
         * @private
         */
        this.editor_ = this.editor_ === undefined ? null : this.editor_;
    }

    /**
     * Set the reference to the editor
     * @param {hf.ui.editor.FieldBase} editor
     */
    setEditor(editor) {
        this.editor_ = editor;
    }

    /**
     * Get the reference to the editor
     * @return {hf.ui.editor.FieldBase}
     */
    getEditor() {
        return this.editor_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* TODO: Popup placement cannot be set to TOP because the content is retrieved dynamically.
         * The position is computed correctly, but the popup changes size when the content arrives.
         * When the popup accommodates larger content, it overlaps with the target undesirably.
         */
        let placement = !userAgent.device.isDesktop() ? PopupPlacementMode.TOP : PopupPlacementMode.BOTTOM,

            defaultValues = {
                'showArrow': true,
                'staysOpen': false,
                'placement': placement,
                'horizontalOffset': -30,
                'style': {'zIndex': 1010}
            };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* by default popup used in list pickers is not focusable, in order to avoid tab catcher */
        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, false);
        this.setFocusable(false);

        this.addExtraCSSClass(['hg-popup']);

        this.header_ = this.createTitle();

        this.selector_ = this.createSuggestionList();
        this.selector_.setSupportedState(UIComponentStates.FOCUSED, false);

        const container = new LayoutContainer();
        if (this.header_ != null) {
            container.addChild(this.header_, true);
        }
        container.addChild(this.selector_, true);

        this.setContent(container);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.header_ = null;
        this.selector_ = null;
        this.editor_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const selector = this.getSelector();

        this.getHandler()
            .listen(selector, UIComponentEventTypes.ACTION, this.handleSuggestionSelected);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const selector = this.getSelector();

        this.setBinding(selector, {'set': selector.setItemsSource}, '');
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        /* ONLY for MOUSEDOWN !
         1. prevent default action on reference bubbles to avoid problems when reference bubbles are used inside other popups
         and a click on them will trigger the closing of the parent container popup.
         2. also prevent the stealing of the focus from the editor. */
        if (e.type == BrowserEventType.MOUSEDOWN || e.type == BrowserEventType.TOUCHSTART) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    /** @inheritDoc */
    onOpening(opt_silent) {
        super.onOpening(opt_silent);
    }

    /** @inheritDoc */
    getTargetPageOffset(target) {
        return StyleUtils.getFramedPageOffset(target, window);
    }

    selectIndexByNavigationKey(keyCode) {
        return this.getSelector().selectIndexByNavigationKey(keyCode);
    }

    selectPreviousIndex() {
        this.getSelector().selectPreviousIndex();
    }

    selectNextIndex() {
        this.getSelector().selectNextIndex();
    }

    getSelector() {
        return /** @type {hf.ui.selector.Selector} */ (this.selector_);
    }

    getSelection() {
        return /** @type {hf.ui.selector.Selector} */ (this.selector_).getSelectedValue();
    }

    /**
     * Generates the suggestion list object.
     * @return {!hf.ui.selector.Selector}
     * @protected
     */
    createSuggestionList() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Generates the suggestion list title.
     * @return {hf.ui.Caption}
     * @protected
     */
    createTitle() {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Handles the CLICK event of the suggestion list.
     * Dispatches a selection event and closes the popup
     *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @protected
     */
    handleSuggestionSelected(e) {
        const selector = this.getSelector();

        requestAnimationFrame(() => {
            if (!selector.isSelectionEmpty()) {
                let suggestion = ObjectUtils.getPropertyByPath(this.getSelection(), selector.getValueField());

                const ev = new Event(SuggestionsBubbleEventTypes.SUGGESTION_PICK);
                ev.addProperty('suggestion', suggestion);

                this.dispatchEvent(ev);

                this.close();
            }
        });
    }
}