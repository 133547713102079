import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Constructor for a new PhoneHistorySearchFilter Model
 * @extends {DataModel}
 * @unrestricted 
*/
export class PhoneHistorySearchFilter extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'phoneHistorySearchFilterId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Call flow has Bool type because checkbox field doesn't accept a value (the value is set with true/false depends
        on checked/unchecked).*/

        /* Call is made to an extension */
        this.addField({'name': 'flowIn', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Call is made from an extension */
        this.addField({'name': 'flowOut', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Call finished with disposition: ANSWERED */
        this.addField({'name': 'dispositionAnswered', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Call finished with disposition: BUSY */
        this.addField({'name': 'dispositionBusy', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Call finished with disposition: NO ANSWER, FAILED, NOT ALLOWED */
        this.addField({'name': 'dispositionMissed', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Any voiceMail has been left on the call */
        this.addField({'name': 'hasVM', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Any recordings has been recorded on the call */
        this.addField({'name': 'hasREC', 'type': DataModelField.PredefinedTypes.BOOL});

        /* Any fax has been left on the call */
        this.addField({'name': 'hasFAX', 'type': DataModelField.PredefinedTypes.BOOL});

        /* A start value for a period of time in which the calls have been received */
        this.addField({'name': 'callStartedDateRangeStart', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A end value for a period of time in which the calls have been received */
        this.addField({'name': 'callStartedDateRangeEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A period of time in which the call has been received - not used at this moment */
        this.addField({'name': 'answered', 'type': DataModelField.PredefinedTypes.DATE_TIME});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        let defaultFields = ['flowIn', 'flowOut', 'dispositionAnswered', 'dispositionBusy', 'dispositionMissed', 'hasVM', 'hasREC', 'hasFAX'];

        defaultFields.forEach(function(field) {
            rawData[field] = rawData[field] || false;
        }, this);
    }
};