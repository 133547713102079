import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {BillingRawInvoiceStatus} from "./../../../../data/model/billing/Enums.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const InvoiceListItemContentEventType = {
    /**
     * Dispatched when the user clicks on the invoice number
     * @event InvoiceListItemContentEventType.VIEW_INVOICE
     */
    VIEW_INVOICE : 'view_invoice',

    /**
     * Dispatched when the user clicks on the pay invoice trigger
     * @event hg.module.settings.InvoiceListItemContent.PAY_INVOICE
     */
    PAY_INVOICE : 'pay_invoice'
};

/**
 * Creates a new object representing an item from the invoices list in billing module.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class InvoiceListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The invoice number
         * @type {hf.ui.UIControl}
         * @private
         */
        this.invoiceNumber_ = this.invoiceNumber_ === undefined ? null : this.invoiceNumber_;

        /**
         * The total value of the invoice
         * @type {hf.ui.Caption}
         * @private
         */
        this.taxValue_ = this.taxValue_ === undefined ? null : this.taxValue_;

        /**
         * The invoice paid date
         * @type {hf.ui.UIControl}
         * @private
         */
        this.paidDate_ = this.paidDate_ === undefined ? null : this.paidDate_;

        /**
         * The Thank You message
         * @type {hf.ui.UIControl}
         * @private
         */
        this.hgStatusMessage_ = this.hgStatusMessage_ === undefined ? null : this.hgStatusMessage_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.invoiceNumber_ = new UIControl({'extraCSSClass': this.getDefaultBaseCSSClass() + '-number'});

        this.taxValue_ = new Caption({'extraCSSClass': this.getDefaultBaseCSSClass() + '-value'});

        this.paidDate_ = new UIControl({'extraCSSClass': this.getDefaultBaseCSSClass() + '-paid-date'});

        this.hgStatusMessage_ = new UIControl({'extraCSSClass'	: this.getDefaultBaseCSSClass() + '-message'});
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-billing-invoices-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.invoiceNumber_, true);
        this.addChild(this.taxValue_, true);
        this.addChild(this.paidDate_, true);
        this.addChild(this.hgStatusMessage_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.invoiceNumber_, UIComponentEventTypes.ACTION, this.handleViewInvoice_)
            .listen(this.hgStatusMessage_, UIComponentEventTypes.ACTION, this.handlePayInvoice_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.invoiceNumber_, {'set': this.invoiceNumber_.setContent}, {
            'sourceProperty': 'invoiceId',
            'converter'		: {
                'sourceToTargetFn': function(invoiceId) {
                    let value = '#';

                    if (invoiceId != null && BaseUtils.isString(invoiceId)) {
                        value += invoiceId;
                    }

                    return value;
                }
            }
        });

        this.setBinding(this.taxValue_, {'set': this.taxValue_.setContent}, 'totalRaw');

        this.setBinding(this.paidDate_, {'set': this.paidDate_.setContent}, {
            'sources': [
                {'sourceProperty': 'paid'},
                {'sourceProperty': 'overdueDays'},
                {'sourceProperty': 'statusRaw'},
                {'sourceProperty': 'dueDate'}
            ],
            'converter'		: {
                'sourceToTargetFn': (sources) => {
                    if (sources == null) {
                        return null;
                    }

                    const paidDate = sources[0];
                    let overdueDays = sources[1];
                    const statusRaw = sources[2],
                        dueDate = sources[3];

                    const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT),
                        content = new UIComponent();

                    /* add invoice raw status */
                    content.addChild(new UIControl({
                        'content'		: statusRaw,
                        'extraCSSClass'	: ['invoice-status', statusRaw.toLowerCase()]
                    }), true);

                    /* add extra details about the invoice paidDate, overdueDate, dueDate */
                    if (paidDate != null) {
                        const paidDatetime = formatter.format(paidDate);

                        content.addChild(new UIControl({
                            'content'		: paidDatetime,
                            'baseCSSClass'	: 'paid-details'
                        }), true);
                    }
                    else {
                        if (overdueDays != null) {
                            /* the invoice has been overdue for more than 24 hours */
                            overdueDays = parseInt(overdueDays, 10);
                            if (overdueDays > 0) {
                                content.addChild(new UIControl({
                                    'content'		: (overdueDays === 1) ? translator.translate('1_day') : translator.translate('x_days', [overdueDays]),
                                    'baseCSSClass'	: 'overdue-days'
                                }), true);
                            }
                        }
                        else {
                            /* the invoice hasn't been overdue for more than 24 hours OR the invoice is unpaid */
                            content.addChild(new UIControl({
                                'content'		: formatter.format(dueDate),
                                'baseCSSClass'	: 'invoice-due-date'
                            }), true);
                        }
                    }

                    return content;
                }
            }
        });

        this.setBinding(this,
            {'set': (paidDate) => {
                if (paidDate != null) {
                    this.hgStatusMessage_.setContent(translator.translate('thank_you'));

                    this.hgStatusMessage_.removeExtraCSSClass(InvoiceListItemContent.ExtraCSSClass_.INVOICE_UNPAID);
                    this.hgStatusMessage_.addExtraCSSClass(InvoiceListItemContent.ExtraCSSClass_.INVOICE_PAID);
                }
                else {
                    /* invoice is overdue and invoice is unpaid */
                    this.hgStatusMessage_.setContent(translator.translate('pay_now'));

                    this.hgStatusMessage_.removeExtraCSSClass(InvoiceListItemContent.ExtraCSSClass_.INVOICE_PAID);
                    this.hgStatusMessage_.addExtraCSSClass(InvoiceListItemContent.ExtraCSSClass_.INVOICE_UNPAID);
                }
            }},
            'paid'
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.invoiceNumber_);
        this.invoiceNumber_ = null;

        BaseUtils.dispose(this.taxValue_);
        this.taxValue_ = null;

        BaseUtils.dispose(this.paidDate_);
        this.paidDate_ = null;

        BaseUtils.dispose(this.hgStatusMessage_);
        this.hgStatusMessage_ = null;
    }

    /**
     * Handles CLICK on invoice number. Display the invoice details
     * @param {hf.events.Event} e
     * @private
     */
    handleViewInvoice_(e) {
        const model = this.getModel();

        if (model != null) {
            const event = new Event(InvoiceListItemContentEventType.VIEW_INVOICE);
            event.addProperty('invoiceId', model['invoiceId']);

            this.dispatchEvent(event);
        }
    }

    /**
     * Handles CLICK on invoice number. Display the invoice details
     * @param {hf.events.Event} e
     * @private
     */
    handlePayInvoice_(e) {
        const model = this.getModel();

        if (model != null && model['statusRaw'] != BillingRawInvoiceStatus.PAID) {
            const event = new Event(InvoiceListItemContentEventType.PAY_INVOICE);
            event.addProperty('invoiceId', model['invoiceId']);

            this.dispatchEvent(event);
        }
    }
};
/**
 * CSS classes by this component
 * @enum {string}
 * @private
 */
InvoiceListItemContent.ExtraCSSClass_ = {
	INVOICE_PAID 	: 'invoice-paid',
	INVOICE_UNPAID	: 'invoice-unpaid'
};