import { ApplicationEventType } from './EventType.js';
import { AppEvent } from './AppEvent.js';

/**
 * Creates a new StateChangingEvent object.
 *
 * @augments {AppEvent}
 *
 */
export class StateChanging extends AppEvent {
    /**
     * @param {hf.app.state.AppState} currentState Object identifying the current state of the App
     * @param {hf.app.state.AppState} newState Object identifying the new state of the App
     * @param {boolean=} opt_isNavigatingBack True if it's navigating back from currentState to newState.
     */
    constructor(currentState, newState, opt_isNavigatingBack) {
        /* Call the base class constructor */
        super(ApplicationEventType.STATE_CHANGING, {
            currentState,
            newState,
            isNavigatingBack: !!opt_isNavigatingBack
        });
    }

    /**
     * Gets the new state.
     *
     * @returns {hf.app.state.AppState}
     */
    getNewState() {
        return /** @type {hf.app.state.AppState} */ (this.getPayloadEntry('newState'));
    }

    /**
     * Gets the current state of the App.
     *
     * @returns {hf.app.state.AppState}
     */
    getCurrentState() {
        return /** @type {hf.app.state.AppState} */ (this.getPayloadEntry('currentState'));
    }

    /**
     * Gets whether if it's navigating back from currentState to newState.
     *
     * @returns {boolean}
     */
    isNavigatingBack() {
        return /** @type {boolean} */ (this.getPayloadEntry('isNavigatingBack'));
    }

    /**
     * Gets whether the change of the App state is allowed.
     *
     * @returns {boolean}
     */
    allowChange() {
        return /** @type {boolean} */ (this.getPayloadEntry('allowChange'));
    }

    /**
     *
     * @param {boolean} allow
     */
    setAllowChange(allow) {
        this.addPayloadEntry('allowChange', allow);
    }
}
