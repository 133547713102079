import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {CollaborationControl} from "./../../../module/chat/component/collaboration/CollaborationControl.js";
import {MenuButton, MenuButtonEventType} from "./../button/MenuButton.js";
import {DelayedActionButton, DelayedActionButtonEventType} from "./../button/DelayedActionButton.js";
import {ChatThreadActions, TopicActions} from "./../../enums/Enums.js";
import {ShareButton} from "./../share/ShareButton.js";
import {ThreadPriority} from "./ThreadPriority.js";
import {TopicShareViewmodel} from "./../viewmodel/TopicShare.js";
import {EditTopicButton} from "./../../../module/topic/component/EditTopicButton.js";
import {TopicViewmodel, TopicViewStates} from "./../../../module/topic/viewmodel/Topic.js";
import {HgUIEventType} from "./../events/EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import {AuthorType} from "../../../data/model/author/Enums.js";

/**
 * Base thread header.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ThreadHeaderBase extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.layout.HorizontalStack}
         * @protected
         */
        this.threadActionsContainer = this.threadActionsContainer === undefined ? null : this.threadActionsContainer;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.threadNameControl_ = this.threadNameControl_ === undefined ? null : this.threadNameControl_;

        /**
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.threadStatusMarker = this.threadStatusMarker === undefined ? null : this.threadStatusMarker;

        /**
         * @type {hg.module.chat.collaboration.CollaborationControl}
         * @protected
         */
        this.collaborationControl_ = this.collaborationControl_ === undefined ? null : this.collaborationControl_;

        /**
         * @type {hg.common.ui.button.MenuButton}
         * @protected
         */
        this.threadActionsMenuButton_ = this.threadActionsMenuButton_ === undefined ? null : this.threadActionsMenuButton_;

        /**
         * @type {hg.common.ui.button.MenuButton}
         * @protected
         */
        this.actionsMenuButton_ = this.actionsMenuButton_ === undefined ? null : this.actionsMenuButton_;

        /**
         * @type {hg.common.ui.share.ShareButton}
         * @protected
         */
        this.shareButton = this.shareButton === undefined ? null : this.shareButton;

        /**
         * @type {hg.topic.ui.EditTopicButton}
         * @protected
         */
        this.editTopicButton = this.editTopicButton === undefined ? null : this.editTopicButton;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.threadStatusMarker = new UIControl({
            //'baseCSSClass': 'hg-presence-status-marker',
            'baseCSSClass': 'hg-thread-status-marker',
            'extraCSSClass': function(status) {
                return !StringUtils.isEmptyOrWhitespace(status) ? status.toLowerCase() : null;
            }
        });

        this.threadActionsContainer = new HorizontalStack({'extraCSSClass': 'thread-actions-container'});

        this.actionsMenuButton_ = new MenuButton(this.getActionsMenuButtonsConfig());
        
        this.threadActionsMenuButton_ = new MenuButton(this.getThreadActionsMenuButtonsConfig());
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.threadNameControl_ = null;
        this.threadStatusMarker = null;
        this.collaborationControl_ = null;
        this.actionsMenuButton_ = null;
        this.threadActionsMenuButton_ = null;
        this.threadActionsContainer = null;

        BaseUtils.dispose(this.shareButton);
        this.shareButton = null;

        BaseUtils.dispose(this.editTopicButton);
        this.editTopicButton = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ThreadHeaderBase.CssClasses.CHAT_THREAD_HEADER_BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ThreadHeaderBase.CssClasses.CHAT_THREAD_HEADER_BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.threadActionsContainer.addChild(this.getCollaborationControl(), true);
        this.threadActionsContainer.addChild(this.actionsMenuButton_, true);
        this.threadActionsContainer.addChild(this.threadActionsMenuButton_, true);

        this.addChild(this.getThreadNameControl(), true);
        this.addChild(this.threadStatusMarker, true);
        this.addChild(this.threadActionsContainer, true);
        this.addChild(this.getShareButton(), true);
        this.addChild(this.getEditTopicButton(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionsMenuButton_, MenuButtonEventType.MENU_ITEM_ACTION, this.handleMenuItemAction)
            .listen(this.threadActionsMenuButton_, MenuButtonEventType.MENU_ITEM_ACTION, this.handleMenuItemAction)
            .listen(this.threadActionsMenuButton_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction)
            .listen(this.threadActionsMenuButton_, HgUIEventType.THREAD_PRIORITY_CHANGE, this.handlePriorityChange);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const collaborationControl = this.getCollaborationControl();
        this.setBinding(collaborationControl, {'set': collaborationControl.setModel}, '');
        this.setBinding(collaborationControl, {'set': collaborationControl.setVisible}, {
            'sourceProperty': 'thread',
            'converter': {
                'sourceToTargetFn': function (thread) {
                    return thread
                        && thread['type'] === TopicType.DIRECT
                        && !thread['isSharedWithMe']
                        && [AuthorType.USER, AuthorType.VISITOR].includes(thread.get('interlocutor.type'));
                }
            }
        });

        this.setBinding(this.actionsMenuButton_, {'set': this.actionsMenuButton_.setModel}, {
            'sourceProperty': 'actions',
            'converter': {
                'sourceToTargetFn': function (visitorActions) {
                    return visitorActions ? visitorActions.getAll() : [];
                }
            }
        });

        this.setBinding(this.actionsMenuButton_, {'set': this.actionsMenuButton_.setVisible}, {
            'sourceProperty': 'actions',
            'converter': {
                'sourceToTargetFn': function (actions) {
                    return actions != null && actions.getCount() > 0;
                }
            }
        });

        this.setBinding(this.threadActionsMenuButton_, {'set': this.threadActionsMenuButton_.setModel}, {
            'sourceProperty': 'threadActions',
            'converter': {
                'sourceToTargetFn': function (threadActions) {
                    return threadActions ? threadActions.getAll() : [];
                }
            }
        });
    }

    /**
     * @return {hf.ui.UIComponent}
     */
    createThreadNameControl() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @return {hf.ui.UIComponent}
     */
    getThreadNameControl() {
        if(!this.threadNameControl_) {
            this.threadNameControl_ = this.createThreadNameControl();

            this.threadNameControl_.addExtraCSSClass(ThreadHeaderBase.CssClasses.CHAT_THREAD_HEADER_BASE + '-' + 'thread-name');
        }

        return this.threadNameControl_;
    }

    /**
     * @return {hg.module.chat.collaboration.CollaborationControl}
     * @protected
     */
    getCollaborationControl() {
        return this.collaborationControl_
            || (this.collaborationControl_ = new CollaborationControl(this.getCollaborationControlConfig()));
    }

    /**
     *
     * @returns {Object!}}
     */
    getCollaborationControlConfig() {
        const translator = Translator;

        return {
            'tooltip': {
                'content'       : translator.translate("Communicate"),
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1,
                'showArrow'     : true
            }
        };
    }

    /**
     *
     * @returns {Object!}}
     */
    getActionsMenuButtonsConfig() {
        const translator = Translator;

        return {
            'name'          : ThreadHeaderBase.Button.ACTION,
            'extraCSSClass' : ThreadHeaderBase.Button.ACTION,
            'menu'          : {
                'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'menu',
                'itemContentFormatter'  : this.formatActionsMenuItem.bind(this),
                'itemStyle'             : this.styleActionsMenuItem.bind(this)
            },
            'popup'         : {
                'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'menu-popup',
                'placement'		  		: PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset'      : 10,
                'verticalOffset'      	: 4
            },
            'tooltip'       : {
                'content'               : translator.translate("thread_actions"),
                'placement'             : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'        : -1,
                'showArrow'             : true
            }
        };
    }

    /**
     *
     * @returns {Object!}}
     */
    getThreadActionsMenuButtonsConfig() {
        const translator = Translator;

        return {
            'name'          : ThreadHeaderBase.Button.THREAD_ACTION,
            'extraCSSClass' : ThreadHeaderBase.Button.THREAD_ACTION,
            'menu'          : {
                'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'menu',
                'itemContentFormatter'  : this.formatActionsMenuItem.bind(this),
                'itemStyle'             : this.styleActionsMenuItem.bind(this)
            },
            'popup'         : {
                'extraCSSClass'         : this.getBaseCSSClass() + '-' + 'menu-popup',
                'placement'		  		: PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset'      : 10,
                'verticalOffset'      	: 4
            },
            'tooltip'       : {
                'content'               : translator.translate("thread_options"),
                'placement'             : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'        : -1,
                'showArrow'             : true
            }
        };
    }

    /**
     * @param {*} threadAction
     * @param {hf.ui.UIComponent} menuItem
     * @return {UIControlContent}
     * @protected
     */
    formatActionsMenuItem(threadAction, menuItem) {
        const translator = Translator;

        if(threadAction['type'] == ChatThreadActions.NONE) {
            menuItem.setEnabled(false);

            return new Separator({
                'model'            : threadAction
            });
        }

        if(threadAction['type'] == TopicActions.DELETE) {
            return new DelayedActionButton({
                'displayProgressMode': DelayedActionButton.DisplayProgressMode.BAR,
                'extraCSSClass'      : [ThreadHeaderBase.CssClasses.CHAT_THREAD_HEADER_BASE + '-' + 'menu-item'],
                'content'            : translator.translate(threadAction['caption']),
                'model'              : threadAction
            });
        }

        if(threadAction['type'] == TopicActions.CLOSE) {
            return new DelayedActionButton({
                'displayProgressMode': DelayedActionButton.DisplayProgressMode.BAR,
                'extraCSSClass'      : [ThreadHeaderBase.CssClasses.CHAT_THREAD_HEADER_BASE + '-' + 'menu-item'],
                'content'            : translator.translate(threadAction['caption']),
                'model'              : threadAction
            });
        }

        if(threadAction['type'] == ChatThreadActions.PRIORITY) {
            return new ThreadPriority({
                'model' : threadAction
            });
        }

        return threadAction != null ? DomUtils.createDom('SPAN', null, translator.translate(threadAction['caption'])) : null;
    }

    /**
     * @param {*} model
     * @return {(string | !Array.<string> | function(*): (string | !Array.<string>))}
     * @protected
     */
    styleActionsMenuItem(model) {
        const css = [this.getBaseCSSClass() + '-' + 'menu-item'];

        if(model != null) {
            css.push(model['cssClass']);
        }

        return css;
    }

    /**
     * @param {*} threadAction
     * @protected
     */
    onMenuItemAction(threadAction) {
        if (this.getModel() == null) {
            return;
        }

        if (threadAction['type'] != null && threadAction['type'] === TopicActions.SHARE) {
            this.openShareButton();

            return;
        }

        if (threadAction['type'] != null && threadAction['type'] == TopicActions.EDIT) {
            this.openEditTopicButton();

            return;
        }


        if(threadAction['type'] != null && threadAction['type'] == TopicActions.DELETE) {
            threadAction = TopicActions.DELETE;
        }

        const event = new Event(HgUIEventType.THREAD_ACTION);
            event.addProperty('payload', {
                'action': threadAction,
                'thread':  this.getModel()['thread']
            });

        this.dispatchEvent(event);
    }

    /**
     * @protected
     */
    openShareButton() {
        const chatThread = this.getModel(),
            shareButton = this.getShareButton();

        if(chatThread && shareButton) {
            let shareThreadResourceViewmodel = chatThread['threadId']
                ?  new TopicShareViewmodel({'resourceId': chatThread['threadId']})
                : null;

            shareButton.setModel(shareThreadResourceViewmodel);

            shareButton.open();
        }
    }

    /**
     *
     * @returns {hg.common.ui.share.ShareButton}
     * @protected
     */
    getShareButton() {
        return this.shareButton || (this.shareButton = this.createShareButton());
    }

    /**
     * @returns {hg.common.ui.share.ShareButton}
     * @protected
     */
    createShareButton() {
        return new ShareButton({ 'hidden': true });
    }

    /**
     * @protected
     */
    openEditTopicButton() {
        const chatThread = this.getModel(),
            editButton = this.getEditTopicButton();

        if(chatThread && editButton) {
            let editTopicViewmodel = null;

            // you can edit only the 'normal' topics (not PERSONAL, nor DIRECT, nor TEAM)
            if (chatThread['threadId']) {
                editTopicViewmodel = new TopicViewmodel({
                    'resourceId': chatThread['threadId'],
                    'currentView': TopicViewStates.EDIT
                });
            }

            editButton.setModel(editTopicViewmodel);

            editButton.open();
        }
    }

    /**
     *
     * @returns {hg.topic.ui.EditTopicButton}
     * @protected
     */
    getEditTopicButton() {
        return this.editTopicButton || (this.editTopicButton = new EditTopicButton({ 'hidden': true }));
    }

    /**
     * Handler for menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleMenuItemAction(e) {
        const menuItem = e.getTarget();
        if (menuItem) {
            const threadAction = e.getProperty('dataItem');
            if (threadAction != null
                && threadAction['type'] != TopicActions.DELETE
                && threadAction['type'] != ChatThreadActions.PRIORITY
                && threadAction['type'] != TopicActions.CLOSE) {
                this.onMenuItemAction(threadAction);
            }
        }
    }

    /**
     * Handler for delayed action btn
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleDelayedAction(e) {
        const target = e.getTarget(),
            threadAction = target.getModel();

        this.threadActionsMenuButton_.close();

        this.onMenuItemAction(threadAction);
    }

    /**
     * Handler menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handlePriorityChange(e) {
        const target = e.getTarget(),
            threadAction = target.getModel();

        if (threadAction) {
            this.onMenuItemAction(threadAction);
        }
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ThreadHeaderBase.CSS_CLASS_PREFIX = 'hg-chat-thread-header';

/**
 * Specific button names
 * @enum {string}
 */
ThreadHeaderBase.Button = {
    THREAD_ACTION   : 'thread-action',
    ACTION          : 'action',
    CLOSE           : 'close'
};

/**
 * The css classes used by this component
 * @enum {string}
 */
ThreadHeaderBase.CssClasses = {
    CHAT_THREAD_HEADER_BASE: ThreadHeaderBase.CSS_CLASS_PREFIX,

    MAIN_CHAT_THREAD_HEADER: 'hg-chat-main-thread-header',

    MINI_CHAT_THREAD_HEADER: 'hg-mini-thread-header',

    CONVERSATION_HEADER: 'hg-chat-conversation-header',

    TOPIC_HEADER: 'hg-chat-topic-header'
};