import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Label} from "./../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {Separator} from "./../../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Password} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Password.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Form} from "./../../../../common/ui/Form.js";
import {Captcha, CaptchaEventType} from "./../../../../common/ui/Captcha.js";
import {HgAuthBusyContext, HgAuthEventType} from "./../../Common.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {UserAgentUtils} from "./../../../../common/useragent/useragent.js";
import {SocialLogin} from "./../SocialLogin.js";
import {HgServiceErrorCodes, ServiceError} from "./../../../../data/service/ServiceError.js";
import {HgAppStates} from "./../../../../app/States.js";
import {Loader} from "./../../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Login object.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class Login extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.Label}
         * @private
         */
        this.titleLabel_;

        /**
         * Submit button reference
         * @type {hf.ui.Button}
         * @private
         */
        this.submitBtn_;

        /**
         * Social login component reference
         * @type {hg.module.auth.SocialLogin}
         * @private
         */
        this.socialLogin_;

        /**
         * Submit button
         * @type {hf.ui.Button}
         * @private
         */
        this.forgotPassBtn_;

        /**
         * Change team domain button
         * @type {hf.ui.Button}
         * @private
         */
        this.changeTeamDomainBtn_;

        /**
         * Container for the captcha.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.captchaContainer_;

        /**
         * Captcha input field
         * @type {hg.common.ui.Captcha}
         * @private
         */
        this.captcha_;

        /**
         * Id of the timer used to start the slide-in animation of the captcha container.
         * @type {number}
         * @private
         */
        this.captchaAnimationTimerId_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.downloadDesktopBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-large');

        const translator = Translator;

        this.titleLabel_ = new Label({'extraCSSClass' : 'hf-label-top'});

        this.forgotPassBtn_ = HgButtonUtils.create(['hg-auth-forgot-password', 'hg-button-link'], translator.translate('forgot_password'), false);
        this.forgotPassBtn_.setTabIndex(3);

        if (UserAgentUtils.ELECTRON) {
            this.changeTeamDomainBtn_ = HgButtonUtils.create(['hg-auth-change-domain', 'hg-button-link'], translator.translate('change_team_domain'), false);
            this.changeTeamDomainBtn_.setTabIndex(3);
            this.forgotPassBtn_.setTabIndex(4);
        }

        /* NOTE: the submit button will be ALWAYS enabled */
        this.submitBtn_ = HgButtonUtils.createSubmit (HgButtonUtils.ButtonCSSClass.PRIMARY_BUTTON, translator.translate('login'),
            {
                'loader': {
                    'size' : Loader.Size.LARGE,
                    'extraCSSClass': 'grayscheme'
                }
            }
        );
        this.submitBtn_.setTabIndex(1);

        /* captcha resources */
        this.captchaContainer_ = new LayoutContainer({
            'extraCSSClass'  : 'hg-auth-challenge-container',
            /* animate when captcha is required */
            'hidden'        : true
        });

        /* avoid captcha elements to catch focus while not displayed */
        this.captchaContainer_.setSupportedState(UIComponentStates.DISABLED, true);
        this.captchaContainer_.setEnabled(false);

        this.captcha_ = new Captcha({
            'tabIndex'  : 2
        });

        this.downloadDesktopBtn_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('download_for_desktop', [CurrentApp.Name]),
            'extraCSSClass' : 'hg-auth-button-desktopapp'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.titleLabel_);
        this.titleLabel_ = null;

        BaseUtils.dispose(this.submitBtn_);
        this.submitBtn_ = null;

        BaseUtils.dispose(this.forgotPassBtn_);
        this.forgotPassBtn_ = null;

        BaseUtils.dispose(this.changeTeamDomainBtn_);
        this.changeTeamDomainBtn_ = null;

        BaseUtils.dispose(this.captchaContainer_);
        this.captchaContainer_ = null;

        BaseUtils.dispose(this.captcha_);
        this.captcha_ = null;

        BaseUtils.dispose(this.downloadDesktopBtn_);
        this.downloadDesktopBtn_ = null;

        BaseUtils.dispose(this.socialLogin_);
        this.socialLogin_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        /* add form fields */
        const authFieldGroup = new FieldGroup({
            'label': this.titleLabel_,
            'fields': [
                this.getField(Login.FieldName.USERNAME),
                this.getField(Login.FieldName.PASSWORD)
            ]
        });

        /* captcha resources */
        this.captchaContainer_.addChild(this.getField(Login.FieldName.CHALLENGE), true);
        this.captchaContainer_.addChild(this.captcha_, true);

        this.addChild(authFieldGroup, true);
        this.addChild(this.forgotPassBtn_, true);
        if (UserAgentUtils.ELECTRON) {
            this.addChild(this.changeTeamDomainBtn_, true);
        }
        this.addChild(this.captchaContainer_, true);
        this.addChild(this.submitBtn_, true);

        /* add link for desktop win/mac only */
        if (userAgent.device.isDesktop() && !UserAgentUtils.ELECTRON && !userAgent.platform.isLinux()) {
            this.addChild(this.downloadDesktopBtn_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.captchaContainer_.setVisible(false);

        /* listen to events */
        this.getHandler()
            .listen(this.forgotPassBtn_, UIComponentEventTypes.ACTION, this.handleForgotAction_)

            /* listen to captcha refresh requests */
            .listen(this.captcha_, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            /* listen on first captcha load to animate the container */
            .listenOnce(this.captcha_, CaptchaEventType.LOADED, this.handleCaptchaLoad_)

            .listen(this.downloadDesktopBtn_, UIComponentEventTypes.ACTION, this.handleDesktopAppDownload_);

            if (UserAgentUtils.ELECTRON) {
                this.getHandler().listen(this.changeTeamDomainBtn_, UIComponentEventTypes.ACTION, this.handleChangeTeamDomain_);
            }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        clearTimeout(this.captchaAnimationTimerId_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.titleLabel_, {'set': this.titleLabel_.setContent}, {
            'sourceProperty': 'team',
            'converter': {
                'sourceToTargetFn': function(teamName) {
                    return !StringUtils.isEmptyOrWhitespace(teamName) ? translator.translate('team_name', [teamName]) : translator.translate('login')
                }
            }
        });

        const username = this.getField(Login.FieldName.USERNAME);
        this.setBinding(username, {'get': username.getValue, 'set': username.setValue},
            {
                'sourceProperty': 'authObject.identity',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const password = this.getField(Login.FieldName.PASSWORD);
        this.setBinding(password, {'get': password.getValue, 'set': password.setValue},
            {
                'sourceProperty': 'authObject.secret',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const challenge = this.getField(Login.FieldName.CHALLENGE);
        this.setBinding(challenge, {'get': challenge.getValue},
            {
                'sourceProperty': 'challenge',
                'mode'          : DataBindingMode.ONE_WAY_TO_SOURCE
            }
        );

        this.setBinding(this.captcha_, {'set': this.captcha_.setModel},
            {
                'sourceProperty'    : 'authObject.captchaToken',
                'mode'              : DataBindingMode.ONE_WAY
            }
        );

        this.setBinding(this, {'set': this.createSocialLogin_},
            {
                'sourceProperty' : 'googleLogin',
                'converter'      : {
                    'sourceToTargetFn' : function (googleLogin) {
                        return googleLogin != null;
                    }
                },
                'mode'           : DataBindingMode.ONE_WAY
            }
        );
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
        let cfg = {};

        cfg = this.getFieldOptions(Login.FieldName.USERNAME, translator.translate('username'));
        cfg['autofocus']    = true;
        cfg['tabIndex']     = 1;
        this.addField(new Text(cfg));

        cfg = this.getFieldOptions(Login.FieldName.PASSWORD, translator.translate('password'));
        cfg['strength']     = false;
        cfg['tabIndex']     = 1;
        this.addField(new Password(cfg));

        cfg = this.getFieldOptions(Login.FieldName.CHALLENGE, translator.translate('type_characters_below'));
        cfg['strength']     = false;
        cfg['tabIndex']     = 1;
        this.addField(new Text(cfg));
    }

    /**
     * Gets the object containing the configuration options used to initialize this Component.
     * @param {string} name Field name
     * @param {string} placeholder Language pack key for placeholder
     * @return {!Object}
     * @protected
     */
    getFieldOptions(name, placeholder) {
        const translator = Translator;

        return {
            'placeholder'       : translator.translate(placeholder),
            'name'              : name,
            'extraCSSClass'     : ['hg-auth-field-' + name],
            'autocomplete'      : false,
            'autocorrect'       : false,
            'autocapitalize'    : false,
            'validation'        : {
                'validateOn': FormFieldValidateOn.NEVER,
                'showErrors': false
            }
        }
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* clear challenge */
        this.getField(Login.FieldName.CHALLENGE).setValue(null);
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * Handles captcha image appearance
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaLoad_(e) {
        this.blurFocusedField();

        if (!this.captchaContainer_.isVisible()) {
            this.captchaContainer_.setEnabled(true);
            this.captchaContainer_.setVisible(true);

            /* compute actual captcha container height */
            const actualHeight = this.captchaContainer_.getHeight(true) + 'px';

            /* animate the captcha container */
            this.captchaContainer_.setMaxHeight(0);

            clearTimeout(this.captchaAnimationTimerId_);
            this.captchaAnimationTimerId_ = setTimeout(() => {
                this.captchaContainer_.addExtraCSSClass('hg-animate');
                this.captchaContainer_.setMaxHeight(actualHeight);

                const usernameField = this.getField(Login.FieldName.USERNAME);
                if (usernameField instanceof Text) {
                    /* focus username field after the captcha is loaded */
                    usernameField.focus(true);
                }
            }, Login.ANIMATION_DELAY_MS_);
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDesktopAppDownload_(e) {
        this.dispatchEvent(HgAuthEventType.DOWNLOAD_DESKTOPAPP);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(isBusy, opt_busyContext) {
        switch (opt_busyContext) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                /* add gray layer on top of current captcha image */
                this.captcha_.setBusy(isBusy);
                break;

            case HgAuthBusyContext.AUTHENTICATE:
                this.submitBtn_.setBusy(isBusy);
                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextError) {
        super.enableHasErrorBehavior(enable, contextError);

        if(contextError && contextError['context']) {
            switch (contextError['context']) {
                case HgAuthBusyContext.AUTHENTICATE:
                    if(enable) {
                        /* refresh captcha on authentication failure */
                        if (this.captchaContainer_.isVisible()) {
                            this.refreshCaptcha_();
                        }
                    }

                case HgAuthBusyContext.DEVICE_UNKNOWN:
                case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                default:
                    break;
            }
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextError) {
         const errContainer = new UIControl({
             'baseCSSClass': 'hg-auth-form-err',
             'extraCSSClass': function (contextError) {
                 if (!contextError) {
                     return '';
                 }

                 const extraCSS = [],
                     err = contextError['error'];

                 if (err instanceof ServiceError && err.code == HgServiceErrorCodes.LOGIN_ATTEMPTS_EXCEEDED) {
                     extraCSS.push('hg-warning');
                 }
                 else {
                     switch (contextError['context']) {
                         case HgAuthBusyContext.FROM_PASS_RECOVERY:
                             extraCSS.push('hg-info');
                             break;

                         case HgAuthBusyContext.DEVICE_UNKNOWN:
                             extraCSS.push('hg-warning');
                             break;

                         default:
                             extraCSS.push('hg-error');
                             break;
                     }
                 }

                 return extraCSS;
             },
             'contentFormatter': function (contextError, control) {
                 if (!contextError) {
                     return;
                 }

                 const err = contextError['error'];
                 if (err instanceof ServiceError && err.code == HgServiceErrorCodes.LOGIN_ATTEMPTS_EXCEEDED) {

                     return err.message.replace(RegExpUtils.LP_LINK_RE,
                         function (fullMatch, linkText) {
                             return `<a href="#${HgAppStates.RECOVER}">${linkText}</a>`;
                         }
                     );
                 }

                 return err.message;
             }
         });

        return errContainer;
    }

    /**
     * Add social login component
     * @param {boolean} hasGoogleLogin
     * @private
     */
    createSocialLogin_(hasGoogleLogin) {
        if (hasGoogleLogin) {
            const translator = Translator;

            this.socialLogin_ = new SocialLogin({
                'googleButtonText' : translator.translate("google_sign_in")
            });

            this.addChild(new Separator({
                'extraCSSClass' : this.getBaseCSSClass() + '-' + 'social-login-separator',
                'orientation'   : Orientation.HORIZONTAL
            }), true);
            this.addChild(this.socialLogin_, true);

            this.setBinding(this.socialLogin_, {'set' : this.socialLogin_.setModel}, 'googleLogin');
        }
    }

    /**
     * Handles the login form forgot password
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleForgotAction_(e) {
        this.dispatchEvent(HgAuthEventType.FORGOT_PASS);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    /**
     * Handles the login form change team domain
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleChangeTeamDomain_(e) {
        this.dispatchEvent(HgAuthEventType.CLEAR_ORGANIZATION);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }
};
/**
 * The time in milliseconds after which to start the captcha animation
 * @type {number}
 * @const
 * @private
 */
Login.ANIMATION_DELAY_MS_ = 50;
/**
 * Field names used in login form
 * @enum {string}
 */
Login.FieldName = {
    USERNAME    : 'username',
    PASSWORD    : 'password',
    CHALLENGE   : 'challenge'
};