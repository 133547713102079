import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UriUtils} from "./../../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {HgPartyName} from "./../../../../common/ui/vcard/HgPartyName.js";
import {HgAppConfig} from "./../../../../app/Config.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {FileLabels} from "./../../../../data/model/file/Enums.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FileInfoPanelContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.Avatar}
         * @private
         */
        this.avatar_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.authorAndDate_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.fileInfo_;

        /**
         * The close button in the FileInfoPanel.
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.closeBtn_ = this.closeBtn_ === undefined ? null : this.closeBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-media-file-info';
    }

    /** @inheritDoc */
    init(opt_config = {}) {

        super.init(opt_config);

        const baseCSSClass = this.getBaseCSSClass();

        this.avatar_ = new Avatar({
            'avatarSize'    : AvatarSizes.MEDIUM,
            'extraCSSClass' : baseCSSClass + '-' + 'avatar'
        });

        this.authorAndDate_ = new Caption({
            'contentFormatter'  : this.createAuthorAndDateDom_.bind(this),
            'extraCSSClass'     : baseCSSClass + '-' + 'header-container'
        });

        this.fileInfo_ = new Caption({
            'contentFormatter'  : this.createContentDom_.bind(this),
            'extraCSSClass'     : baseCSSClass + '-' + 'content-container'
        });

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'extraCSSClass': ['grayscheme']
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'author');

        this.setBinding(this.authorAndDate_, {'set': this.authorAndDate_.setModel}, '');

        this.setBinding(this.fileInfo_, {'set': this.fileInfo_.setModel}, '');
    }

    /**
     * @return {UIControlContent}
     * @private
     */
    createAuthorAndDateDom_(fileContent) {
        if (!fileContent) {
            return null;
        }

        const content = [],
            formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_TIME_FORMAT);

        content.push(new HgPartyName({
            'extraCSSClass'     : ['small', 'grayscheme'],
            'showVisitorMarker' : false,
            'showBubble'        : false,
            'model'             : fileContent['author']
        }));

        content.push(new UIControl({
            'extraCSSClass' :   ['date'],
            'content': formatter.format(fileContent['created'])
        }));

        return content;
    }

    /**
     * @param {hg.data.model.file.FileVersion} fileContent
     * @return {UIControlContent}
     * @private
     */
    createContentDom_(fileContent) {
        if (!fileContent) {
            return null;
        }

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        const content = document.createDocumentFragment();
        content.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'description', fileContent['description']));

        const dateAndName = DomUtils.createDom('div', baseCSSClass + '-' + 'date-name', '');

        if (fileContent['fileView'].items_[0].uri != null) {
            const fileURL = UriUtils.createURL(fileContent['fileView'].items_[0].uri),
                name = fileURL.searchParams.get('nm') || fileURL.searchParams.get('name');

            dateAndName.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'name',
                name + '.' + fileURL.searchParams.get('ext')));
        }

        /* Get media */
        const originalView = fileContent['fileView'].items_ ? fileContent['fileView'].items_.find(function (view) {
            return view['label'] == FileLabels.ORIGINAL || !view['label'];
        }) : null;
        const media = originalView['media'];

        if (media['taken'] != null) {
            const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_TIME_FORMAT),
                taken = media['taken'];

            if(BaseUtils.isDate(taken)) {
                dateAndName.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'takenDate',
                    translator.translate("date_taken", [formatter.format(/**@type {!Date}*/(taken))])));
            }
        }

        const details = DomUtils.createDom('div', baseCSSClass + '-' + 'details', '');

        const mediaExif = media['exif'];
        if (mediaExif != null) {
            let fNumber;

            if (mediaExif['fNumber'] != null) {
                const res     = mediaExif['fNumber'].split("/");

                fNumber = res.length == 2 ? (res[0] / res[1]).toFixed(2) : res[0];
            }

            if (mediaExif['model'] != null) {
                content.appendChild(DomUtils.createDom('div', this.getBaseCSSClass() + '-' + 'model', /** @type {string} */(mediaExif['model'])));
            }

            if (mediaExif['focalLength35mm'] != null) {
                details.appendChild(DomUtils.createDom('div', this.getBaseCSSClass() + '-' + 'focalLength', mediaExif['focalLength35mm'] + 'mtm'));
            }
            if (fNumber != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'fNumber', 'f/' + fNumber));
            }
            if (mediaExif['exposureTime'] != null) {
                details.appendChild(DomUtils.createDom('div', this.getBaseCSSClass() + '-' + 'exposureTime', mediaExif['exposureTime'] + 's'));
            }
            if (mediaExif['flash'] != null) {
                details.appendChild(DomUtils.createDom('div', this.getBaseCSSClass() + '-' + 'flash', (mediaExif['flash'] == '0') ? translator.translate('off') : translator.translate('on')));
            }
            if (mediaExif['iso'] != null) {
                details.appendChild(DomUtils.createDom('div', this.getBaseCSSClass() + '-' + 'iso', /** @type {string} */(mediaExif['iso'])));
            }
        }

        const videoProperties = media['videoProperties'];
        if (videoProperties != null) {

            if (videoProperties['codec'] != null || (videoProperties['width'] != null && videoProperties['height'] != null)) {
                const infoDiv = DomUtils.createDom('div', baseCSSClass + '-' + 'info');
                if (videoProperties['codec'] != null) {
                    infoDiv.appendChild(DomUtils.createDom('span', '', videoProperties['codec']));
                }

                if (videoProperties['width'] != null && videoProperties['height'] != null) {
                    infoDiv.appendChild(DomUtils.createDom('span', '', videoProperties['width'] + 'x' + videoProperties['height']));
                }

                content.appendChild(infoDiv);
            }

            if (videoProperties['bitrate'] != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'bitrate', videoProperties['bitrate'] + 'bps'));
            }

            if (videoProperties['framerate'] != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'framerate', videoProperties['framerate'] + 'fps'));
            }
        }

        const audioProperties = media['audioProperties'];
        if (audioProperties != null) {
            if (audioProperties['codec'] != null) {
                content.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'info', audioProperties['codec']));
            }

            if (audioProperties['channels'] != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'channels', audioProperties['channels'].toString()));
            }

            if (audioProperties['samplerate'] != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'samplerate', audioProperties['samplerate'] + 'HZ'));
            }

            if (audioProperties['bitrate'] != null) {
                details.appendChild(DomUtils.createDom('div', baseCSSClass + '-' + 'bitrate', audioProperties['bitrate'] + 'bps'));
            }
        }

        content.appendChild(details);
        content.appendChild(dateAndName);

        return content;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getBaseCSSClass();

        const container = new HorizontalStack({
            'extraCSSClass': baseCSSClass + '-' + 'header'
        });

        container.addChild(this.avatar_, true);
        container.addChild(this.authorAndDate_, true);

        this.addChild(this.closeBtn_, true);
        this.addChild(container, true);
        this.addChild(this.fileInfo_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatar_);
        this.avatar_ = null;

        BaseUtils.dispose(this.authorAndDate_);
        this.authorAndDate_ = null;

        BaseUtils.dispose(this.fileInfo_);
        this.fileInfo_ = null;

        BaseUtils.dispose(this.closeBtn_);
        this.closeBtn_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        this.dispatchEvent(HgUIEventType.PANEL_CLOSE);
    }
};