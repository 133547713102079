import {BrowserEventType, EventsUtils, LayoutContainer} from "../../../../../../hubfront/phpnoenc/js/index.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {HgUIEventType} from "../events/EventType.js";

/**
 * 
 * @extends {BaseView}
 * @unrestricted 
*/
export class AbstractThreadHostView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {UIComponent}
         * @protected
         */
        this.threadsHost;

        /**
         * @type {Object.<string, UIComponent>}
         * @protected
         */
        this.openedThreads;
    }

    /**
     * Opens a message thread window.
     *
     * @param {MessageThreadViewmodel} messageThread
     * @param {boolean} [opt_focus]
     * @return {UIComponent}
     */
    openThread(messageThread, opt_focus = false) {
        let threadComponent = null;

        if (messageThread) {
            threadComponent = this.openedThreads[messageThread['recipientId']];

            if (threadComponent == null) {
                threadComponent = this.createThreadComponent(messageThread);
                threadComponent.setModel(messageThread);

                this.openedThreads[messageThread['recipientId']] = threadComponent;
            }

            // if the thread component already exists in openThreads, it will be brought to front
            this.addThreadToThreadsHost(threadComponent);

            if (!!opt_focus) {
                threadComponent.focus();
            }

            this.onThreadOpened(threadComponent);
        }

        return threadComponent;
    }

    /**
     * Closes a message thread window.
     *
     * @param {MessageThreadViewmodel} messageThread
     * @return {boolean}
     */
    closeThread(messageThread) {
        return messageThread != null && this.closeThreadInternal(messageThread['recipientId']);
    }

    /**
     * Closes all opened thread windows.
     */
    closeAllThreads() {
        for (let threadId in this.openedThreads) {
            if (this.openedThreads.hasOwnProperty(threadId)) {
                this.closeThreadInternal(threadId);
            }
        }
    }

    /**
     * Scrolls to last received message.
     *
     * @param {string} recipientId
     * @param {boolean=} opt_force True to scroll to the last message no matter what.
     * If false, the the messages list is scroll to the last message ONLY if the scroll is near the last message.
     */
    scrollToLastMessage(recipientId, opt_force) {
        const threadComponent = this.openedThreads[recipientId];

        if (threadComponent && typeof threadComponent.scrollToLastMessage === 'function') {
            threadComponent.scrollToLastMessage(opt_force);
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.openedThreads = {};
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const host = this.getThreadsHost();
        this.addChild(host, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, HgUIEventType.THREAD_ACTION, this.handleThreadAction)
            .listen(this, HgUIEventType.MESSAGE_ACTION, this.handleMessageAction);

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.threadsHost = null;
        this.openedThreads = null;
    }

    /**
     * @return {UIComponent}
     * @protected
     */
    getThreadsHost() {
        if (this.threadsHost == null) {
            this.threadsHost = new LayoutContainer();
        }

        return this.threadsHost;
    }

    /**
     * @param {MessageThreadViewmodel} messageThreadModel
     * @return {UIComponent}
     * @protected
     */
    createThreadComponent(messageThreadModel) {
        throw new Error('unimplemented abstract method');
    }

    /**
     * Adds a thread component to the threads' host
     *
     * @param {UIComponent} threadComponent
     * @protected
     */
    addThreadToThreadsHost(threadComponent) {
        if (threadComponent != null) {
            const host = this.getThreadsHost();

            if (host.indexOfChild(threadComponent) < 0) {
                host.addChild(threadComponent, !threadComponent.isInDocument());
            }

            /* make sure the component is visible */
            threadComponent.setVisible(true);

            // /* NOTE: we must force a resize see HG-6090 */
            requestAnimationFrame(() => threadComponent.onResize());
        }
    }

    /**
     * Removes a thread component from the threads' host.
     *
     * @param {UIComponent} threadComponent
     * @protected
     */
    removeThreadFromThreadsHost(threadComponent) {
        if (threadComponent != null) {
            const host = this.getThreadsHost();

            host.removeChild(threadComponent, true);

            // simulate a media viewport resize event on the current element in order to add restore sources if necessary
            this.dispatchMediaViewportResizeEvent(threadComponent.getElement());
        }
    }

    /**
     *
     * @param {string} threadId
     * @returns {boolean}
     * @protected
     */
    closeThreadInternal(threadId) {
        const threadComponent = this.openedThreads[threadId];
        if (threadComponent) {
            delete this.openedThreads[threadId];

            this.removeThreadFromThreadsHost(threadComponent);

            this.onThreadClosed(threadComponent);

            return true;
        }

        return false;
    }

    /**
     *
     * @param {UIComponent} threadComponent
     * @protected
     */
    onThreadOpened(threadComponent) {
        // nop - overridden by inheritors
    }

    /**
     *
     * @param {UIComponent} threadComponent
     * @protected
     */
    onThreadClosed(threadComponent) {
        // nop - overridden by inheritors
    }

    /**
     * Simulate a media viewport resize event to allow media to pause if required, remove or restore sources
     * @param {Element} viewportElement The viewport element
     * @protected
     */
    dispatchMediaViewportResizeEvent(viewportElement) {
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport': viewportElement
        });
    }

    /* region ============================================= Event handlers ============================================= */
    /**
     * Handles the THREAD_ACTION UI event.
     * @param {Event} e
     * @protected
     */
    handleThreadAction(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action'] || !payload['thread']) return;

        const actionResult = /**@type {AbstractThreadHostPresenter}*/(this.getPresenter())
            .onThreadAction({
                ...payload,
                // NOTE: Normalize the action type: sometimes action is an object, and the action type is under the 'type' field (i.e. payload.action.type)
                action: payload['action']['type'] || payload['action'],
                // NOTE: if the action is an object, then put it under 'data' (i.e. payload) property
                ...(typeof payload['action'] === 'object' ? {data: payload['action']}  : {})
            });

        if (actionResult) {
            // NOTE: I attach both response and promisedResult because there are consumers that use one or the other
            e.addProperty('response', actionResult);
            e.addProperty('promisedResult', actionResult);
        }
    }


    /**
     * Handles the MESSAGE_ACTION UI event.
     * @param {Event} e
     * @protected
     */
    handleMessageAction(e) {
        const payload = e.getProperty('payload');
        if (!payload || !payload['action'] || (!payload['message'] && !payload['messageGroup'])) return;

        const actionResult = /**@type {AbstractThreadHostPresenter}*/(this.getPresenter()).onMessageAction(payload);

        if (actionResult) {
            // NOTE: I attach both response and promisedResult because there are consumers that use one or the other
            e.addProperty('response', actionResult);
            e.addProperty('promisedResult', actionResult);
        }
    }

    /* endregion ============================================= Event handlers ============================================= */
}