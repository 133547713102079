import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by chat module components.
 * @enum {string}
 * @readonly
 */
export const PhoneReceiverEventType = {
    /** triggered from dialer panel when using keypad or agenda/recents
     * @event PhoneReceiverEventType.INITIATE_TRANSFER */
    INITIATE_TRANSFER : StringUtils.createUniqueString('inittransfer')
};

/**
 * Set of operating modes
 * @enum {number}
 * @default 1
 */
export const PhoneReceiverMode = {
    /* incoming call received for webPhone */
    RINGING :0,

    /* incoming call received for remote device */
    //REMOTE_RINGING :1,

    /* transferring call, use dialer for content */
    TRANSFERRING    : 1
};

