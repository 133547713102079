import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new media preview model
 * @extends {DataModel}
 * @unrestricted 
*/
export class MediaPreview extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /**
  * @inheritDoc
  */
 defineFields() {
     this.addField({'name': 'mediaPreviewId', 'type': DataModelField.PredefinedTypes.STRING});

     /* The URL to the resource */
     this.addField({'name': 'url', 'type': DataModelField.PredefinedTypes.STRING});

     /* The width of the the media file. */
     this.addField({'name': 'width', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* The height of the the media file. */
     this.addField({'name': 'height', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* The number of frames in GIF. */
     this.addField({'name': 'frames', 'type': DataModelField.PredefinedTypes.NUMBER});
 }
};