import { Checkbox } from '../field/Checkbox.js';
import { ArrayUtils } from '../../../array/Array.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { FieldGroup } from './FieldGroup.js';
import { UIComponentEventTypes } from '../../Consts.js';
import { BaseUtils } from '../../../base.js';

/**
 * Default implementation of CheckboxGroup form component.
 *
 * @example
    var colors = new hf.ui.form.FieldGroup({
        label: {
            content: 'Colors',
            layout: 'left',
            align: 'left',
            width: 100
        },
        hint: {
            content: 'Pick two colors',
            layout: 'bottom',
            align: 'left'
        },
        maxSelectedCheckboxes: 2,
        minSelectedCheckboxes: 2,
        fields : [
            new hf.ui.form.field.Checkbox({
                'inputLabel' : 'blue',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
                'inputLabel' : 'yellow',
                'checked' : false,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
               'inputLabel' : 'brown',
               'checked' : false,
               'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
                'inputLabel' : 'pink',
                'checked' : true,
                'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
               'inputLabel' : 'black',
               'checked' : false,
               'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
               'inputLabel' : 'grey',
               'checked' : false,
               'name': 'colorVS'
            }),
            new hf.ui.form.field.Checkbox({
               'inputLabel' : 'white',
               'checked' : false,
               'name': 'colorVS'
            })
        ]
    });
    colors.render();
 * @throws {TypeError} if at least one of the configuration parameters
 *						doesn't have the appropriate type.
 * @augments {FieldGroup}
 
 *
 */
export class CheckboxGroup extends FieldGroup {
    /**
     * @param {!object=} opt_config Optional object containing config parameters.
     *   @param {number=} opt_config.maxSelectedCheckboxes The maximum allowed checkboxes to be selected
     *   @param {number=} opt_config.minSelectedCheckboxes The minimum allowed checkboxes to be selected
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The name of the checkboxes.
         *
         * @type {null|string}
         * @private
         */
        this.name_;

        /**
         * The maximum number of selected checkboxes.
         *
         * @type {?number|undefined}
         * @private
         */
        this.maxSelectedCheckboxes_;

        /**
         * The minimum number of selected checkboxes.
         *
         * @type {number}
         * @default 0
         * @private
         */
        this.minSelectedCheckboxes_;

        /**
         * The selected checkboxes.
         *
         * @type {Array.<hf.ui.form.field.Checkbox>}
         * @private
         */
        this.selectedCheckboxes_;
    }

    /**
     * Gets the selected checkboxes.
     *
     * @returns {Array.<hf.ui.form.field.Checkbox>} The checkboxes which are selected.
     *
     */
    getSelectedCheckboxes() {
        return this.selectedCheckboxes_;
    }

    /**
     * Gets the unselected checkboxes.
     *
     * @returns {Array.<hf.ui.form.field.Checkbox>} The checkboxes which are not selected.
     *
     */
    getUnselectedCheckboxes() {
        const unselected = [];
        this.forEachField((field) => {
            if (!field.isChecked()) {
                unselected.push(field);
            }
        }, this);

        return unselected;
    }

    /**
     * Checks or unchecks all fields
     *
     * @param {boolean} checked If true it will check all fields, if false it will uncheck them
     *
     */
    setAllChecked(checked) {
        this.forEachField((field) => {
            field.setChecked(checked);
        });
    }

    /**
     * The fields from a checkboxGroup must have hf.ui.form.field.Checkbox type and must have all the same name.
     *
     * @override
     */
    addFieldAt(field, index) {
        if (!(field instanceof Checkbox)) {
            throw new TypeError("The 'field' parameter must be a hf.ui.form.field.Checkbox object.");
        }
        /* check if the name of this field is the same as the names of the previously added fields */
        this.processFieldName_(field);

        super.addFieldAt(field, index);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], 'hf-form-checkboxgroup');

        super.init(opt_config);

        /* initialize 'this.selectedCheckboxes_' field */
        this.selectedCheckboxes_ = [];

        /* Set the maxSelectedCheckboxes field */
        if (opt_config.maxSelectedCheckboxes != null) {
            this.setMaxSelectedCheckboxes(opt_config.maxSelectedCheckboxes);
        }

        /* Set the minSelectedCheckboxes field */
        this.setMinSelectedCheckboxes(opt_config.minSelectedCheckboxes || 0);
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        /* add all the checked checkboxes into the 'this.selectedCheckboxes_' array */
        this.forEachField(function (field) {
            if (field.isChecked()) {
                this.selectedCheckboxes_.push(field);
            }
        }, this);

        /* validate maximum number of checkboxes */
        this.validateMaxSelected_();
    }

    /**
     * @inheritDoc
     */
    enterDocument() {
        super.enterDocument();

        /* register event handlers for checking and unchecking a checkbox */
        const handler = this.getHandler();
        this.forEachField(function (field) {
            handler
                .listen(field, UIComponentEventTypes.CHECK, this.handleCheck_)
                .listen(field, UIComponentEventTypes.UNCHECK, this.handleUncheck_);
        }, this);
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        /* Reset object properties with reference values. */
        this.selectedCheckboxes_ = null;
    }

    /**
     * Sets the minimum allowed checkboxes to be selected.
     *
     * @param {number} minSelectedCheckboxes The minimum allowed checkboxes to be selected.
     * @throws {TypeError} If the parameter does not have the right type.
     * @protected
     */
    setMinSelectedCheckboxes(minSelectedCheckboxes) {
        if (!BaseUtils.isNumber(minSelectedCheckboxes)) {
            throw new TypeError('The \'minSelectedCheckboxes\' parameter must be a number.');
        }

        this.minSelectedCheckboxes_ = minSelectedCheckboxes;
    }

    /**
     * Sets the maximum allowed checkboxes to be selected.
     *
     * @param {?number} maxSelectedCheckboxes The maximum allowed checkboxes to be selected.
     * @throws {TypeError} If the parameter does not have the right type.
     * @protected
     */
    setMaxSelectedCheckboxes(maxSelectedCheckboxes) {
        if (BaseUtils.isNumber(maxSelectedCheckboxes) || maxSelectedCheckboxes == null) {
            this.maxSelectedCheckboxes_ = maxSelectedCheckboxes;
        } else {
            throw new TypeError('The \'maxSelectedCheckboxes\' parameter must be a number.');
        }
    }

    /**
     * Checks if a provided checkbox object has the same name as the other checkbox objects which were added in the CheckboxGroup.
     *
     * @param {!hf.ui.form.field.Checkbox} field The checkbox object field.
     * @throws {Error} If there are two checkbox objects with different names.
     * @private
     */
    processFieldName_(field) {
        /* the name of the provided checkbox field */
        const name = field.getInputName();

        /* check if the name of this field is the same as the name of the previous checkbox field */
        if (this.name_ != null) {
            /* if this name is not the same as the saved name, an error must be thrown */
            if (name != this.name_) {
                throw new Error('There are at least two checkbox fields with different names. All the checkboxes from a CheckboxGroup must have the same name.');
            }
        } else {
            /* this is the first checkbox field from the group */
            this.name_ = /** @type {string} */(name);
        }

        /* the name must end in '[]' */
        if (!this.name_.endsWith('[]')) {
            field.setInputName(`${this.name_}[]`);
        }
    }

    /**
     * If there are more checkboxes selected than the maximum allowed throw an error.
     *
     * @throws {Error} If there are more checkboxes selected than the maximum allowed.
     * @private
     */
    validateMaxSelected_() {
        const selected = this.selectedCheckboxes_.length;

        if (this.maxSelectedCheckboxes_ != null) {
            if (selected > this.maxSelectedCheckboxes_) {
                throw new Error('There are more chekboxes selected than the maximum number allowed.');
            } else {
                /* no more selected checkboxes allowed */
                if (selected == this.maxSelectedCheckboxes_) {
                    /* disable all the unselected checkboxes */
                    this.enableUnselected_(false);
                }
            }
        }
    }

    /**
     * Enables/Disables all the checkboxes from this group which are not selected, depending on the provided parameter.
     *
     * @param {boolean} enable True for enabling; false for disabling.
     * @private
     */
    enableUnselected_(enable) {
        this.forEachField((field) => {
            if (!field.isChecked()) {
                field.setEnabled(enable);
            }
        }, this);
    }

    /**
     * Handles the CHECK event for a checkbox.
     *
     * @param {hf.events.Event=} e The event object.
     * @private
     */
    handleCheck_(e) {
        const checkedElement = /** @type {hf.ui.form.field.Checkbox} */(e.target);

        /* add this field into the selected checkboxes */
        this.selectedCheckboxes_.push(checkedElement);

        /* check if the number of selected elements reached the maximum allowed number of selected checkboxes */
        if (this.selectedCheckboxes_.length === this.maxSelectedCheckboxes_) {
            /* disable all the unselected checkboxes */
            this.enableUnselected_(false);
        }
    }

    /**
     * Handles the UNCHECK event for a checkbox.
     *
     * @param {hf.events.Event=} opt_event The event object.
     * @private
     */
    handleUncheck_(opt_event) {
        const uncheckedElement = opt_event.target;

        /* remove this field from the selected checkboxes */
        ArrayUtils.remove(this.selectedCheckboxes_, uncheckedElement);

        /* check if the disabled checkboxes must be enabled, because the maximum selected limit is not reached anymore */
        if (this.selectedCheckboxes_.length === this.maxSelectedCheckboxes_ - 1) {
            /* enable all the unselected checkboxes */
            this.enableUnselected_(true);
        }
    }
}
