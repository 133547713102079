import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {CardTokenDetails} from "./CardTokenDetails.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class CardToken extends DataModel {
 /**
  * @param {!Object=} opt_initData
  *
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The card this token will represent. If you also pass in a customer, the card must be the ID of a card belonging
     to the customer. Otherwise, if you do not pass a customer, a dictionary containing a user's credit card details,
     with the options described below. */
     this.addField({'name': 'card', 'type': CardTokenDetails});

     /* A customer (owned by the application's account) to create a token for. */
     this.addField({'name': 'customer', 'type': DataModelField.PredefinedTypes.STRING});
 }
};