import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";

import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {ObjectUtils} from "../../../../../../hubfront/phpnoenc/js/object/object.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BasePresenter} from "./../presenter/BasePresenter.js";
import {UserAgentUtils} from "./../../useragent/useragent.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {HgNotification} from "./../../../data/model/notification/Notification.js";
import {
    HgNotificationActionContexts,
    HgNotificationActions,
    HgNotificationCategories,
    HgNotificationEvents
} from "./../../../data/model/notification/Enums.js";
import {ContactModes, DesktopNotificationContexts} from "./../../enums/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {AvailabilityPolicy} from "./../../../data/model/presence/Enums.js";
import {Severity} from "./Enums.js";
import {WindowManager} from "./../../../data/service/WindowManager.js";
import {HgAppStates} from "./../../../app/States.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {HgPartyTypes} from "./../../../data/model/party/Enums.js";
import {PersonTypes} from "./../../../data/model/person/Enums.js";
import {HgServiceErrorCodes} from "./../../../data/service/ServiceError.js";
import {TEAM_TOPIC_ID, TopicType} from "./../../../data/model/thread/Enums.js";
import {Priority} from "./../../../data/model/common/Enums.js";
import fullscreen from "../../../../../../hubfront/phpnoenc/js/dom/fullscreen.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import LatestThreadsService from "./../../../data/service/LatestThreadsService.js";
import SkinManager from "./../../../../../../hubfront/phpnoenc/js/skin/SkinManager.js";
import LikeService from "./../../../data/service/LikeService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {MessageEvents} from "../../../data/model/message/Enums.js";
import {ApplicationEventType} from "../../../../../../hubfront/phpnoenc/js/app/events/EventType.js";
import {MessageThreadUIRegion} from "../viewmodel/MessageThread.js";
import {HgStringUtils} from "../../string/string.js";
import {ElectronAPI} from "../../Electron.js";

/**
 * Sound files playes on events happening in the app
 * @enum {string}
 * @readonly
 */
export const NotificationsManagerAlertType = {
    /**  */
    IMPORTANT_USER_NOTIFICATION : 'notification_alert_important_user_notification',

    /**  */
    NORMAL_USER_NOTIFICATION    : 'notification_alert_normal_user_notification',

    /**  */
    UNREAD_DIRECT_TOPIC_MESSAGE : 'notification_alert_unread_direct_topic_message',

    /**  */
    UNREAD_TOPIC_MESSAGE        : 'notification_alert_unread_topic_message'
};

/**
 * Creates a {@see NotificationsManager} object.
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class NotificationsManager extends BasePresenter {
    constructor() {
        super();

        /**
         * A queue containing the user notifications (important ones) opened as App Notifications.
         * @type {Array.<string>}
         * @private
         */
        this.userNotifications_;

        /**
         * A map containing the opened Tray Notifications.
         * @type {Object.<string, !Notification|!Webview.Notifier>}
         * @private
         */
        this.trayNotifications_;

        /**
         * A queue that stores the metadata for the threads that have unread messages (aka unread/unseen threads).
         * Based on that queue
         * messages for which app title has been changed.
         * Used to restore another app title if the last one was read from another device.
         * @type {Array}
         * @private
         */
        this.unreadThreadsQueue_;

        /**
         * @type {string|null}
         * @private
         */
        this.osxBoundId_;
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.userNotifications_ = [];
        this.trayNotifications_ = {};
        this.unreadThreadsQueue_ = [];
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.userNotifications_.length = 0;
        for (let key in this.trayNotifications_) {
            delete this.trayNotifications_[key];
        }

        this.unreadThreadsQueue_.length = 0;

        this.osxBoundId_ = null;
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
          /* handle user notifications */
          .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_NOTIFICATION_CENTER_UPDATED, this.handleUserNotificationsCenterUpdated_)
          .listen(eventBus, HgAppEvents.NEW_USER_NOTIFICATION, this.handleNewUserNotification_)
          .listen(eventBus, HgAppEvents.HANDLE_USER_NOTIFICATION, this.handleUserNotificationAction_)

          /* system tray notification request */
          .listen(eventBus, HgAppEvents.SHOW_TRAY_NOTIFICATION, this.handleShowTrayNotification_)

          /* handle unread messages */
          .listen(eventBus, HgAppEvents.NEW_UNREAD_MESSAGE, this.handleNewUnreadMessage_)
          .listen(eventBus, HgAppEvents.UNREAD_THREADS_COUNT_CHANGE, this.handleUnreadThreadsCountChange_)
          .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_SEEN, this.handleRTMSeenEvent_)

          /* handle platform related events */
          .listen(eventBus, ApplicationEventType.APP_SHOW, this.handleAppCoreShow_)
          .listen(eventBus, [HgAppEvents.APP_COVER_STATE_ON, HgAppEvents.APP_COVER_STATE_OFF], this.handleCoverStateToggle_)

          /* listen for event dispatched when all incoming calls have been seen */
          .listen(eventBus, HgAppEvents.NO_INCOMING_PHONECALL, this.handleAllIncomingPhoneCallsSeen_)
          .listen(eventBus, HgAppEvents.NO_MISSED_PHONECALL, this.handleAllPhoneCallsSeen_);
    }

    /** @inheritDoc */
    loadView() {
        return null;
    }

    /** @inheritDoc */
    showView(opt_displayRegion) {
        //nop
    }

    /**
     * Focuses the main window.
     * @return {boolean}
     * @private
     */
    focusMainWindow_() {
        WindowManager.focusMainWindow(true);

        return true;
    }

    /**
     * Emits the PLAY_NOTIFICATION_ALERT app event that instructs the AppView to play a sound
     * that depends on the type of the alert.
     *
     * @param {NotificationsManagerAlertType} alertType
     * @private
     */
    playNotificationAlert_(alertType) {
        this.dispatchEvent(HgAppEvents.PLAY_NOTIFICATION_ALERT, {'alertType': alertType});
    }

    /**
     * Enqueue a new alternative title because a new unread message is received on a thread.
     *
     * @param {!object} recipient The thread as Recipient
     * @param {!object} newUnreadMessage The new unread message.
     * @private
     */
    enqueueUnreadThread_(recipient, newUnreadMessage) {
        if (!newUnreadMessage
          || newUnreadMessage['errorSending']
          || (newUnreadMessage['event'] != null && newUnreadMessage['event'] === MessageEvents.GONE)
        ) return;

        const threadId = recipient['resourceId'];

        /* store the metadata for the unread threads in order to extract another one
         * when last thread is seen from another device */
        const matchIndex = this.unreadThreadsQueue_.findIndex((entry) => entry['recipient']['resourceId'] === threadId);
        if (matchIndex < 0) {
            this.unreadThreadsQueue_.push({recipient, unreadMessage: newUnreadMessage});
        } else {
            // if there is already an entry then move it to the last position of the queue
            this.unreadThreadsQueue_.push(this.unreadThreadsQueue_.splice(matchIndex, 1)[0]);
        }

        let unreadThreadMeta = this.unreadThreadsQueue_[this.unreadThreadsQueue_.length - 1];
        // make sure the unreadMessage is updated
        unreadThreadMeta.unreadMessage = newUnreadMessage;

        this.notifyUnreadThreadsChange_(unreadThreadMeta)
    }

    /**
     * Dequeue an alternative title because the thread:
     * - either became active, or
     * - an ack (i.e. seen) was received for this thread.
     * @param {!object} recipient The thread as Recipient
     * @private
     */
    dequeueUnreadThread_(recipient) {
        const threadId = recipient['resourceId'];

        // all threads are seen
        if (threadId === '@all') {
            if (this.unreadThreadsQueue_) this.unreadThreadsQueue_.length = 0;

            this.removeUnreadNotificationsFromTray_();
        } else {
            const matchIndex = this.unreadThreadsQueue_.findIndex((entry) => entry['recipient']['resourceId'] === threadId);
            if (matchIndex >= 0) {
                /* remove the entry by its index from the queue */
                this.unreadThreadsQueue_.splice(matchIndex, 1);

                const len = this.unreadThreadsQueue_.length;

                /* if the removed index pointed to the last entry then take from queue the last entry and set it as the current one */
                if (matchIndex === len) {
                    /* take the last entry WITHOUT removing it from the queue (i.e. DO NOT USE the .pop() method)*/
                    const nextInLine = this.unreadThreadsQueue_[len - 1];
                    this.notifyUnreadThreadsChange_(nextInLine);
                }
            }
        }
    }

    /**
     * @param {object} [unreadThreadMeta] The pieces of information that are used to compute the alternative title.
     *                                      If not provided then it is considered that no alternative title exists in queue.
     * @return {boolean} True if the update notification was sent, otherwise false.
     * @private
     */
    notifyUnreadThreadsChange_(unreadThreadMeta) {
        if (!unreadThreadMeta) {
            return false;
        }

        // play sound for any unread message if the thread is NOT muted and the message is important
        this.notifyUnreadThreadWithSound_(unreadThreadMeta);

        this.notifyUnreadThreadInAppTitle_(unreadThreadMeta);

        this.notifyUnreadThreadInTray_(unreadThreadMeta);

        return true;
    }

    /**
     * Play sound for any unread message if the thread is NOT muted and the message is important.
     * @param {object} unreadThreadMeta
     * @private
     */
    notifyUnreadThreadWithSound_(unreadThreadMeta = {}) {
        const {recipient, unreadMessage} = unreadThreadMeta;

        // FIXME: Should I play sound when the App is visible?
        //if (CurrentApp.Status.VISIBLE || !recipient) return false;

        const topicPriority = recipient['priority'] || Priority.NORMAL;

        /* determine whether the message is important */
        const isImportant = HgMetacontentUtils.isImportant(unreadMessage['body']) || topicPriority === Priority.HIGH;

        /* play sound for any unread message (in detached thread or not) if the thread is NOT muted and the message is important */
        if (topicPriority !== Priority.LOW && this.canNotify_(isImportant)) {
            /* Play notification alert sound */
            this.playNotificationAlert_(recipient['topicType'] === TopicType.DIRECT ?
              NotificationsManagerAlertType.UNREAD_DIRECT_TOPIC_MESSAGE :
              NotificationsManagerAlertType.UNREAD_TOPIC_MESSAGE
            );
        }

    }

    /**
     *
     * @param {object} unreadThreadMeta
     * @private
     */
    notifyUnreadThreadInAppTitle_(unreadThreadMeta = {}) {
        const {recipient, unreadMessage} = unreadThreadMeta;

        // FIXME Should I show alternative App Title when App is visible?
        //if (CurrentApp.Status.VISIBLE || !recipient) return false;

        const totalUnseenThreads = this.unreadThreadsQueue_.length;
        let topicName = recipient['name'];

        if (recipient['topicType'] !== TopicType.DIRECT) {
            const authorName = ObjectUtils.getPropertyByPath(unreadMessage, 'author.name');
            topicName = topicName + '\n(' + authorName + ')';
        }

        const title = Translator.translate('interlocutor_message', [topicName]);
        const faviconName = totalUnseenThreads > 0 && totalUnseenThreads < 9
          ? totalUnseenThreads
          : totalUnseenThreads > 9 ? '9+' : '';


        this.dispatchEvent(HgAppEvents.APP_ALTERNATIVE_TITLE_UPDATE, {
            'title': title,
            'favicon': StringUtils.isEmptyOrWhitespace(faviconName) ?
              '' : SkinManager.getImageUrl(`notification/favicons/${faviconName}.ico`)
        });
    }

    /**
     *
     * @param {object} unreadThreadMeta
     * @private
     */
    notifyUnreadThreadInTray_(unreadThreadMeta = {}) {
        const {recipient, unreadMessage} = unreadThreadMeta;

        const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT);
        const totalUnseenThreads = this.unreadThreadsQueue_.length;
        const topicPriority = recipient['priority'] || Priority.NORMAL;
        const isImportant = HgMetacontentUtils.isImportant(unreadMessage['body']) || topicPriority === Priority.HIGH;
        let topicName = recipient['name'];

        if (recipient['topicType'] !== TopicType.DIRECT) {
            const authorName = ObjectUtils.getPropertyByPath(unreadMessage, 'author.name');
            topicName = topicName + '\n(' + authorName + ')';
        }

        const title = totalUnseenThreads <= 1 ?
          Translator.translate('1_topic_message') : Translator.translate('number_topic_messages', [totalUnseenThreads])

        this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
            'title': title,
            'body': topicName + ' \n' + formatter.format(new Date(/**@type {string}*/(unreadMessage['created']))),
            'action': () => {
                try {
                    this.focusMainWindow_();

                    this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                        'recipientId': recipient['recipientId'],
                        'type': recipient['type']
                    });

                    setTimeout(() => this.dispatchEvent(HgAppEvents.SCROLL_TO_LAST_MESSAGE, {'threadId': recipient['recipientId']}), 100);
                } catch (err) {
                    // ignore errors
                }
            },
            'context': DesktopNotificationContexts.UNSEEN_TOPIC_MESSAGE,
            'isImportant': isImportant,
            'threadPriority': topicPriority,
            'lang': HgCurrentUser.get('address.region.country.code'),
            'tag': unreadMessage['messageId'],
            'payload': recipient['resourceId']
        });
    }

    /**
     * Closes unread notifications from tray.
     * @private
     */
    removeUnreadNotificationsFromTray_() {
        if (this.trayNotifications_[DesktopNotificationContexts.UNSEEN_DIRECT_MESSAGE] != null) {
            this.trayNotifications_[DesktopNotificationContexts.UNSEEN_DIRECT_MESSAGE].close();
            delete this.trayNotifications_[DesktopNotificationContexts.UNSEEN_DIRECT_MESSAGE];
        }

        if (this.trayNotifications_[DesktopNotificationContexts.UNSEEN_TOPIC_MESSAGE] != null) {
            this.trayNotifications_[DesktopNotificationContexts.UNSEEN_TOPIC_MESSAGE].close();
            delete this.trayNotifications_[DesktopNotificationContexts.UNSEEN_TOPIC_MESSAGE];
        }

        if (UserAgentUtils.ELECTRON && userAgent.platform.isMacintosh() && this.osxBoundId_ != null) {
            ElectronAPI.NotificationCenter.osxCancelBounce(this.osxBoundId_);
            this.osxBoundId_ = null;
        }
    }

    /**
     * Returns true if presence rules allow us to notify user
     * No intrusion allowed.
     *
     * @param {boolean=} opt_important True if important notification
     * @param {HgNotification=} opt_notification
     * @return {boolean}
     * @private
     */
    canNotify_(opt_important, opt_notification) {
        let canNotify = false;
        if (!opt_important) {
            const policy = HgCurrentUser['availabilityPolicy'];
            switch (policy) {
                case AvailabilityPolicy.AI:
                    /* if less than 5 min of inactivity send */
                    if (CurrentApp.getIdleTime() <= 5 * 60 * 1000) {
                        /* around */
                        canNotify = true;
                    } else {
                        /* away */
                        if (opt_notification != null &&
                          ((opt_notification['category'] == HgNotificationCategories.EVENT && opt_notification['event'] == HgNotificationEvents.APPROACH) ||
                            (opt_notification['category'] == HgNotificationCategories.VISITOR && opt_notification['event'] == HgNotificationEvents.CONNECT) ||
                            (opt_notification['category'] == HgNotificationCategories.RESOURCE && opt_notification['event'] == HgNotificationEvents.REQUEST_ACCESS))) {

                            canNotify = true;
                        } else {
                            canNotify = false;
                        }
                    }
                    break;

                case AvailabilityPolicy.NEVER:
                    if (opt_notification != null &&
                      ((opt_notification['category'] == HgNotificationCategories.EVENT && opt_notification['event'] == HgNotificationEvents.APPROACH) ||
                        (opt_notification['category'] == HgNotificationCategories.VISITOR && opt_notification['event'] == HgNotificationEvents.CONNECT) ||
                        (opt_notification['category'] == HgNotificationCategories.RESOURCE && opt_notification['event'] == HgNotificationEvents.REQUEST_ACCESS))) {

                        canNotify = true;
                    } else {
                        canNotify = false;
                    }
                    break;

                default:
                    /* available, send no matter what */
                    canNotify = true;
                    break;
            }
        } else {
            /* important messages are sent no matter what */
            canNotify = true;
        }

        /* HG-14985: avoid missed phone call notification, these are handled by phone module */
        if (canNotify && (opt_notification != null && opt_notification['category'] == HgNotificationCategories.CALL && opt_notification['event'] == HgNotificationEvents.MISSED)) {
            canNotify = false;
        }

        return canNotify;
    }

    /**
     *
     * @param {string} notificationId
     * @private
     */
    onUserNotificationHandled_(notificationId) {
        if (!StringUtils.isEmptyOrWhitespace(notificationId)) {
            /* close any App Notification (displayed in top-left corner) that's related  to this notificationId */
            this.dispatchEvent(HgAppEvents.REMOVE_APP_NOTIFICATION, {
                'id': notificationId
            });

            ArrayUtils.remove(this.userNotifications_, notificationId);

            /* close any Tray Notification that's related  to this notificationId */
            if (this.trayNotifications_[DesktopNotificationContexts.NEW_USER_NOTIFICATION] != null) {
                this.trayNotifications_[DesktopNotificationContexts.NEW_USER_NOTIFICATION].close();
                delete this.trayNotifications_[DesktopNotificationContexts.NEW_USER_NOTIFICATION];
            }
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleUserNotificationsCenterUpdated_(e) {
        const payload = e.getPayload() || {},
          notificationId = payload['notificationId'] || '@all';

        if (!StringUtils.isEmptyOrWhitespace(notificationId)) {
            if (notificationId == '@all') {
                /* Close all the user notifications which are open as App Notifications or Tray Notifications */
                this.userNotifications_.forEach(function (notificationId) {
                    this.onUserNotificationHandled_(notificationId);
                }, this);
            } else {
                /* Close the user notification associated with notificationId which are open as App Notifications or Tray Notifications */
                this.onUserNotificationHandled_(notificationId);
            }
        }
    }

    /**
     * Handles an user notification.
     * @param {AppEvent} e
     * @private
     */
    handleNewUserNotification_(e) {
        const payload = e.getPayload() || {},
          notification = /** @type {HgNotification} */(payload['notification']);

        if (notification instanceof HgNotification) {
            const isImportant = !!notification['important'];

            /* HG-8885: hg:tel tag remained unchanged in body */
            let countryCode;
            if (!HgCurrentUser.isEmpty() && BaseUtils.isFunction(HgCurrentUser.get)) {
                countryCode = /**@type {string}*/(HgCurrentUser.get('address.region.country.code'));
            }

            let body = HgMetacontentUtils.decodeActionTag(notification['body'] || '', HgMetacontentUtils.ActionTag.PHONE_NUMBER, countryCode);
            body = HgMetacontentUtils.decodeActionTag(body || '', HgMetacontentUtils.ActionTag.PERSON);
            body = HgMetacontentUtils.decodeActionTag(body || '', HgMetacontentUtils.ActionTag.TOPIC);
            body = HgMetacontentUtils.decodeActionTag(body || '', HgMetacontentUtils.ActionTag.BOT);
            body = DomUtils.getTextContent(DomUtils.htmlToDocumentFragment(body));

            /* if the user notification is IMPORTANT then show an App Notification bubble (top left corner) */
            if (isImportant) {
                this.userNotifications_.push(/** @type {string} */(notification.getUId()));

                this.dispatchEvent(HgAppEvents.PUSH_APP_NOTIFICATION, {
                    'id': notification.getUId(),
                    'severity': Severity.CRITICAL,
                    'title': notification['subject'],
                    'body': body,
                    'avatar': notification.get('author.avatar'),
                    'openAction': () => {
                        this.dispatchEvent(HgAppEvents.HANDLE_USER_NOTIFICATION, {
                            'notification': notification,
                            'action': HgNotificationActions.OPEN,
                            'context': HgNotificationActionContexts.APP
                        });
                    },
                    'dismissAction': () => {
                        this.dispatchEvent(HgAppEvents.HANDLE_USER_NOTIFICATION, {
                            'notification': notification,
                            'action': HgNotificationActions.DISMISS,
                            'context': HgNotificationActionContexts.APP
                        });
                    }
                });
            }

            /* Check whether the user notification can be more 'intrusive' for the user; if yes then... */
            if (this.canNotify_(isImportant, notification)) {
                /* Play notification alert sound */
                this.playNotificationAlert_(isImportant ?
                  NotificationsManagerAlertType.IMPORTANT_USER_NOTIFICATION :
                  NotificationsManagerAlertType.NORMAL_USER_NOTIFICATION
                );

                /* Show tray notification */
                if (!CurrentApp.Status.VISIBLE) {
                    const translator = Translator,
                      formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, HgAppConfig.MEDIUM_DATE_FORMAT);

                    this.dispatchEvent(HgAppEvents.SHOW_TRAY_NOTIFICATION, {
                        'title': translator.translate('new_notification'),
                        'body': body + ' \n' + formatter.format(new Date()),
                        'action': (notifier) => {
                            /* try to open the desired thread */
                            try {
                                this.focusMainWindow_();

                                this.dispatchEvent(HgAppEvents.HANDLE_USER_NOTIFICATION, {
                                    'notification': notification,
                                    'action': HgNotificationActions.OPEN,
                                    'context': HgNotificationActionContexts.APP
                                });
                            } catch (err) {
                                // ignore any error
                            }
                        },
                        'context': DesktopNotificationContexts.NEW_USER_NOTIFICATION,
                        'isImportant': true,
                        'lang': /**@type {string}*/(HgCurrentUser.get('address.region.country.code')),
                        'tag': btoa(unescape(encodeURIComponent(notification.toJSON())))
                    });
                }
            }
        }
    }

    /**
     * Handles the action (open or dismiss) on a user notification
     * @param {AppEvent} e
     * @private
     */
    handleUserNotificationAction_(e) {
        const payload = e.getPayload() || {},
          notification = /** @type {HgNotification} */ (payload['notification']),
          context = payload['context'],
          userAction = payload['action'],
          translator = Translator;

        if (notification instanceof HgNotification) {
            if (userAction === HgNotificationActions.OPEN) {
                /* execute the action associated with the user notification */

                const action = notification['action'];

                let contactInfo;

                switch (notification['category']) {
                    case HgNotificationCategories.VOICEMAIL:
                    case HgNotificationCategories.FAX:
                        this.navigateTo(HgAppStates.CALLHISTORY_VIEW, /** payload */ {
                            'phoneHistoryId': action['phoneHistoryId'],
                            'phoneHistoryViewId': action['phoneHistoryViewId']
                        });
                        break;

                    case HgNotificationCategories.CALL :
                        if (!HgCurrentUser.isEmpty() && HgCurrentUser['canCall']) {
                            const callData = {
                                'from': action['destinationExtension'],
                                'to': action['phoneNumber'],
                                'video': false,
                                'callParty': {
                                    'phoneNumber': action['phoneNumber'],
                                    'phoneName': action['phoneName']
                                }
                            };

                            if (!StringUtils.isEmptyOrWhitespace(action['visitorId'] || action['personId'] || action['userId'])) {
                                callData['callParty']['participant'] = {
                                    'authorId': action['visitorId'] || action['personId'] || action['userId'],
                                    'type': !StringUtils.isEmptyOrWhitespace(action['visitorId']) ? AuthorType.VISITOR :
                                      !StringUtils.isEmptyOrWhitespace(action['personId']) ? AuthorType.PERSON :
                                        !StringUtils.isEmptyOrWhitespace(action['userId']) ? AuthorType.USER : AuthorType.THIRDPARTY
                                }
                            }

                            this.dispatchEvent(HgAppEvents.CALL_PERSON, callData);
                        }
                        break;

                    case HgNotificationCategories.TOPIC:
                        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                            'recipientId': action['resourceId'],
                            'type': HgPartyTypes.TOPIC,
                            /* open the topic in Main Chat or in Chat History */
                            'uiRegion': notification['event'] === HgNotificationEvents.CREATE
                              ? MessageThreadUIRegion.CHAT_HISTORY : MessageThreadUIRegion.MAIN_CHAT
                        });

                        break;

                    case HgNotificationCategories.USER:
                    case HgNotificationCategories.CHAT:
                        contactInfo = {
                            'contactMode': ContactModes.CHAT,
                            'interlocutor': {
                                'personId': action['personId'],
                                'type': PersonTypes.CUSTOMER
                            }
                        };

                        this.dispatchEvent(HgAppEvents.CONTACT_PERSON, contactInfo);

                        break;

                    case HgNotificationCategories.PERSON:
                        if (!StringUtils.isEmptyOrWhitespace(action['resourceId'])) {
                            contactInfo = {
                                'contactMode': ContactModes.VIEW_DETAILS,
                                'interlocutor': {
                                    'personId': action['resourceId'],
                                    'type': PersonTypes.CUSTOMER
                                }
                            };

                            this.dispatchEvent(HgAppEvents.CONTACT_PERSON, contactInfo);
                        }
                        break;

                    case HgNotificationCategories.EVENT:
                        // no action for now
                        //if (notification['event'] == HgNotificationEvents.CREATE) {
                        /* create event */
                        //} else {
                        /* approach event */
                        //}
                        break;

                    case HgNotificationCategories.PROVIDER:
                        if (notification['event'] == HgNotificationEvents.MESSAGE && notification.get('action.category') == 'billing') {
                            // open up the billing panel
                            this.dispatchEvent(HgAppEvents.OPEN_BILLING);
                        }
                        break;

                    case HgNotificationCategories.VISITOR:
                        if (notification['event'] == HgNotificationEvents.CONNECT) {
                            this.dispatchEvent(HgAppEvents.SHOW_CONNECT_INVITATION,
                              {
                                  'connectInvitationId': action['connectInvitationId']
                              }
                            );
                        }
                        break;

                    case HgNotificationCategories.LIKE:
                        /* check whether the resource still exists or I can access it */
                        LikeService.getLikedResource(action['liked'], action['likeReference'])
                          .then((likedResource) => {
                              if (likedResource) {
                                  this.dispatchEvent(HgAppEvents.SHOW_LIKED_RESOURCE,
                                    {
                                        'liked': likedResource,
                                        'likeReference': action['likeReference']
                                    }
                                  );
                              } else {
                                  throw new Error(translator.translate(HgServiceErrorCodes.NO_PERMISSION));
                              }
                          })
                          .catch(err => {
                              this.dispatchEvent(HgAppEvents.RESOURCE_ERROR_NOTIFICATION,
                                {
                                    'subject': translator.translate('resource_open_failure'),
                                    'description': translator.translate(HgServiceErrorCodes.NO_PERMISSION)
                                }
                              );
                          })
                        ;


                        break;

                    case HgNotificationCategories.MENTION:
                        let inThread = /** @type {ResourceLike} */(action['inThread']);
                        if (!!inThread) {
                            if (inThread['resourceId'] == TEAM_TOPIC_ID) {
                                const statePayload = {
                                    'inThread': inThread,
                                    'messageId': action['replyTo'] || action['rtmId'] || null
                                };

                                if (!!action['where']) {
                                    statePayload['reference'] = action['where'];
                                }

                                this.navigateTo(HgAppStates.MESSAGE_THREAD_VIEW, statePayload);
                            } else {
                                this.dispatchEvent(HgAppEvents.OPEN_THREAD,
                                  {
                                      'recipientId': inThread['resourceId'],
                                      'type': inThread['resourceType']
                                  });
                            }
                        }

                        break;

                    default:
                        // unknown action
                        break;
                }
            }

            /* the user notification was handled */
            this.onUserNotificationHandled_(/**@type {string}*/(notification.getUId()));
        }
    }

    /**
     * Handles system tray notification event
     * @param {AppEvent} e
     * @private
     */
    handleShowTrayNotification_(e) {
        const payload = e.getPayload() || {},
          important = payload['isImportant'] || false,
          threadPriority = payload['threadPriority'] || Priority.NORMAL;

        if (threadPriority !== Priority.LOW && this.canNotify_(important)) {
            const title = payload['title'] !== undefined ? payload['title'] : Translator.translate('notification');
            const type = payload['context'];

            if (this.trayNotifications_[type] != null) {
                this.trayNotifications_[type].close();
            }

            const icon = payload['icon'] != null
              ? payload['icon']
              : CurrentApp.StartupUrl.toString() + SkinManager.getImageUrl('common/logo/hg_icon-desktop.png');

            const notification = new Notification(title, {
                icon: icon,
                body: payload['body'],
                lang: payload['lang'],
                tag: payload['tag']
            });

            this.trayNotifications_[type] = notification;


            if (payload['action'] != null) {
                EventsUtils.listenOnce(notification, BrowserEventType.CLICK, (e) => {
                    return this.onTrayNotificationClick_(payload, notification);

                });
            } else {
                // schedule notification closing
                setTimeout(() => notification.close(), 4000);
            }

            if (UserAgentUtils.ELECTRON && userAgent.platform.isMacintosh()) {
                this.osxBoundId_ = ElectronAPI.NotificationCenter.osxBounce('informational');
            }
        }
    }

    /**
     * Handles click on tray notification
     * @param {Object} payload
     * @param {Notification} notification
     * @private
     */
    onTrayNotificationClick_(payload, notification) {
        if (!BaseUtils.isFunction(payload['action'])) {
            throw new Error('Assertion failed');
        }

        /* call click handler */
        payload['action'](notification);

        /* closing notification */
        if (BaseUtils.isFunction(notification.close)) {
            notification.close();
        }

        /* exit the full screen */
        if (DomUtils.isFullScreen()) {
            fullscreen.exitFullScreen();

            /* scroll to the last message */
            const messageContexts = [
                DesktopNotificationContexts.UNSEEN_DIRECT_MESSAGE,
                DesktopNotificationContexts.UNSEEN_TOPIC_MESSAGE
            ];
            if (messageContexts.includes(payload['context'])) {
                setTimeout(() => this.dispatchEvent(HgAppEvents.SCROLL_TO_LAST_MESSAGE, {'threadId': payload['payload']}), 100);
            }
        }
    }

    /**
     * Handles the HgAppEvents.NEW_UNREAD_MESSAGE
     * - play different sounds, relative to the message status (unread)
     *
     * @param {AppEvent} e
     * @private
     */
    async handleNewUnreadMessage_(e) {
        const payload = e.getPayload() || {},
          message = payload['message'];

        const recipient = await LatestThreadsService.getRecipientFromMessageData(message);
        if (recipient) {
            /* set alternative blinking title for thread (platform types: normal/mini-thread) if
            *  -> thread is not active and window state is visible: title becomes "(n - no. of unseen threads) Hubgets"
            *  -> thread status no matter and window state is not visible: title becomes "%interlocutor% - message"*/
            this.enqueueUnreadThread_(recipient, message);
        }
    }

    /**
     * FIXME
     * @param {AppEvent} e
     * @private
     */
    handleUnreadThreadsCountChange_(e) {
        const {unreadCount} = e.getPayload();

        /* updates the badge on desktop app */
        if (UserAgentUtils.ELECTRON) {
            ElectronAPI.NotificationCenter.setBadge(HgStringUtils.formatNotificationsCount(unreadCount));
        }

    }

    /**
     * Handles the datachannel event rtm/new - event: seen
     * It alters the unreadThreads queue.
     * @param {AppEvent} e New message event
     */
    async handleRTMSeenEvent_(e) {
        const {message} = e.getPayload();

        const threadLink = message['inThread'];
        if (threadLink) {
            this.dequeueUnreadThread_(threadLink);
        }
    }

    /**
     * Handles event dispatched when all incoming calls are seen
     * Automatically close desktop notification if still opened
     *
     * @param {AppEvent} e
     * @private
     */
    handleAllIncomingPhoneCallsSeen_(e) {
        if (this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL] != null) {
            this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL].close();
            delete this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL];
        }
    }

    /**
     * Handles event dispatched when all missed calls are seen
     * Automatically close desktop notification if still opened
     *
     * @param {AppEvent} e
     * @private
     */
    handleAllPhoneCallsSeen_(e) {
        if (this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL] != null) {
            this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL].close();
            delete this.trayNotifications_[DesktopNotificationContexts.NEW_PHONECALL];
        }

        if (this.trayNotifications_[DesktopNotificationContexts.MISSED_PHONECALL] != null) {
            this.trayNotifications_[DesktopNotificationContexts.MISSED_PHONECALL].close();
            delete this.trayNotifications_[DesktopNotificationContexts.MISSED_PHONECALL];
        }
    }

    /**
     * Handles change in app status: active (focused)
     * @param {!AppEvent} e
     * @private
     */
    handleAppCoreShow_(e) {
        this.removeUnreadNotificationsFromTray_();
    }

    /**
     * @param {AppEvent} e The event
     * @private
     */
    handleCoverStateToggle_(e) {
        const isCoverState = e.getType() === HgAppEvents.APP_COVER_STATE_ON;
        if (!isCoverState) {
            this.removeUnreadNotificationsFromTray_();
        }
    }
}