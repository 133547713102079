import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {ArrayUtils} from "./../../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new TextFormatter plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgSanitizeEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'Sanitize';
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        /* clean the content of no width spaces */
        return content.replace(/(\u200B|\u200C)/g, '');
    }

    /**
     * @override
     * @suppress {visibility}
     */
    cleanContentsDom(fieldCopy) {
        /* todo: cleanup all <font> tag, span tags with style attr only */
        let nodesList = fieldCopy.querySelectorAll('font');

        if (nodesList.length) {
            ArrayUtils.forEachRight(nodesList, function (node) {
                DomUtils.flattenElement(node);
            });
        }

        nodesList = fieldCopy.querySelectorAll('span[style]');

        if (nodesList.length) {
            ArrayUtils.forEachRight(nodesList, function (node) {
                DomUtils.flattenElement(node);
            });
        }

        //HG-12468 - IE throws Syntax error if just files are being sent
        try {
            nodesList = fieldCopy.querySelectorAll('div:not([class]):not([style])');
        } catch (err) {
            nodesList = [];
        }

        if (nodesList.length) {
            ArrayUtils.forEachRight(nodesList, function (node) {
                DomUtils.flattenElement(node);
            });
        }

        /* HG-18117 remove empty giphy containers after CTRL+X action */
        const giphyNodesList = fieldCopy.getElementsByClassName('hg-giphy-container');
        if (giphyNodesList.length) {
            ArrayUtils.forEachRight(giphyNodesList, function (node) {
                if (!node.hasChildNodes() && node && node.parentNode) {
                    node.parentNode.removeChild(node);
                }
            });
        }
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        if ((!userAgent.engine.isGecko() || (e.keyCode != KeyCodes.BACKSPACE && e.keyCode != KeyCodes.DELETE)) &&
            (e.keyCode != KeyCodes.X || !(e.ctrlKey || e.metaKey))) {
            return false;
        }

        const editor = this.getFieldObject(),
            range = editor.getRange();
        if (range == null) {
            return false;
        }
        const selectedText = range.getText(),
            anchorNode = range.getAnchorNode();

        /* CTRL + X case */
        if (e.keyCode == KeyCodes.X && (e.ctrlKey || e.metaKey)) {
            return true;
        }

        if ((anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE && anchorNode.getAttribute('class') != null) ||
            (anchorNode.textContent.length > 0 && (anchorNode.textContent.charAt(0) == '@' || anchorNode.textContent.charAt(0) == '&')) ||
            (anchorNode.parentNode != null && anchorNode.parentNode.tagName != 'DIV')) {
            
            return false;
        }

        if (anchorNode.textContent.length == 1 || anchorNode.textContent == selectedText) {
            if (anchorNode && anchorNode.parentNode) {
                anchorNode.parentNode.removeChild(anchorNode);
            }
            
            /* avoid browser default action: does not insert br any more */
            e.preventDefault();
            /* must emit a CHANGE event as they are inhibited if event was prevented */
            editor.dispatchChange(true);
            return true;
        }

        return false;
    }
};