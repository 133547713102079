import {HgAppConfig} from "./../../../../app/Config.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {HgCurrentUser} from "./../../../../app/CurrentUser.js";
import {Form} from "./../../../../common/ui/Form.js";
import {ErrorAlertMessage} from "./../../../../common/ui/alert/Error.js";
import {HgHeaderModuleEventTypes} from "./../../EventType.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {TextArea} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Textarea.js";
import {HgStringUtils} from "./../../../../common/string/string.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new Login object.
 *
 * @extends {Form}
 * @unrestricted 
*/
export class Help extends Form {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.callBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-help-form';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.callBtn_ = new Caption({
            'extraCSSClass'  : baseCSSClass + '-' + 'call',
            'content'       : translator.translate('call_toll_free_phone',
                [HgStringUtils.formatPhone('+1 866-339-2998', 'INTERNATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code'))), HgStringUtils.formatPhone('+1 302-353-4432', 'INTERNATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')))]).replace(RegExpUtils.LP_LINK_RE,
                function(fullMatch, linkText) {
                    return `<span data-role="helpcall">${linkText}</span>`;
                }
            )
        });

        super.init(opt_config);
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        this.addField(new Text({
            'extraCSSClass'     : 'hg-help-form-subject',
            'name'              : Help.FieldName.SUBJECT,
            'placeholder'       : translator.translate('subject'),
            'autofocus'         : true,
            'maxlength'         : HgAppConfig.SUBJECT_MAXLEN
        }));

        this.addField(new TextArea({
            'extraCSSClass'     : 'hg-help-form-message',
            'name'              : Help.FieldName.MESSAGE,
            'placeholder'       : translator.translate('describe_issue'),
            'autoResize'        : true,
            'resizable'         : false,
            //'editorMinHeight'   : 210,
            //'editorMaxHeight'   : 240,
            'required'          : true
        }));
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.addChild(this.getField(Help.FieldName.SUBJECT), true);
        this.addChild(this.getField(Help.FieldName.MESSAGE), true);
        this.addChild(this.callBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.callBtn_.getElement(), BrowserEventType.CLICK, this.handleCall_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.callBtn_);
        this.callBtn_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callBtn_, {'set': this.callBtn_.setVisible}, {
            'source': HgCurrentUser,
            'sourceProperty': 'canCall',
            'converter': {
                'sourceToTargetFn': function(value) {
                    return value;
                }
            }
        });

        const subject = this.getField(Help.FieldName.SUBJECT);
        this.setBinding(
            subject,
            {'get': subject.getValue, 'set': subject.setValue},
            {
                'sourceProperty': 'email.subject',
                'mode'          : DataBindingMode.TWO_WAY
            }
        );

        const message = this.getField(Help.FieldName.MESSAGE);
        this.setBinding(
            message,
            {'get': message.getValue, 'set': message.setValue},
            {
                'sourceProperty': 'email.message',
                'mode'          : DataBindingMode.TWO_WAY,
                'converter': {
                    'sourceToTargetFn': function(value) {
                        return value;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        let errorHost = this.getErrorContainerHost(contextErr),
            errorContainer = this.getErrorContainer(contextErr);

        if(!errorHost || !errorContainer) {
            return;
        }

        if (enable) {
            if (errorContainer.getParent() == null) {
                errorContainer.setModel(contextErr);
                errorHost.addChildAt(errorContainer, 0, true);
            }
        } else {
            super.enableHasErrorBehavior(enable, contextErr);
        }
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        return new ErrorAlertMessage({
            'extraCSSClass'     : 'small',
            'contentFormatter'  : function (contextError) {
                return contextError != null ? /** @type {Error} */(contextError['error']).message : null;
            }
        });
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCall_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'helpcall') {
            this.dispatchEvent(HgHeaderModuleEventTypes.HELPCALL);
        }
    }
};
/**
 * Field names use in invitation form
 * @enum {string}
 */
Help.FieldName = {
    SUBJECT    : 'subject',
    MESSAGE    : 'message'
};