import { UIComponentEventTypes, UIComponentStates } from '../../Consts.js';
import { DataBindingMode } from '../../databinding/BindingBase.js';
import { FunctionsUtils } from '../../../functions/Functions.js';
import { BrowserEventType } from '../../../events/EventType.js';
import { KeyCodes } from '../../../events/Keys.js';
import { TriggerBase } from './TriggerBase.js';
import { Popup, PopupPlacementMode } from '../../popup/Popup.js';
import { BaseUtils } from '../../../base.js';
import { TextInputTypes } from './Text.js';
import { StyleUtils } from '../../../style/Style.js';
import userAgent from '../../../../thirdparty/hubmodule/useragent.js';

/**
 * Creates a new Picker object.
 *
 * @augments {TriggerBase}
 *
 */
export class Picker extends TriggerBase {
    /**
     * @param {!object=} opt_config Optional configuration object
     *   @param {void} opt_config.autocomplete NOT SUPPORTED
     *   @param {void} opt_config.confirmation NOT SUPPORTED
     *   @param {void} opt_config.confirmationLabel NOT SUPPORTED
     *   @param {void} opt_config.type NOT SUPPORTED
     *   @param {!object=} opt_config.popup The configuration options for the popup
     *     @param {!boolean=} opt_config.matchFieldWidth Whether the popup width should equal the field width
     *     @param {string=} opt_config.extraCSSClass The css class of the popup
     *     @param {number|string=} opt_config.width The width of the popup
     *     @param {number|string=} opt_config.height The height of the popup
     *     @param {!boolean=} opt_config.hasDropShadow Flag which indicates whether the Popup is displayed with a drop shadow effect or not.
     *     @param {!PopupPlacementMode=} opt_config.placement The placement of the popup
     *     @param {number=} opt_config.horizontalOffset The horizontal distance between the target origin and the popup alignment point
     *     @param {number=} opt_config.verticalOffset The vertical distance between the target origin and the popup alignment point
     *     @param {?hf.fx.dom.PredefinedEffect=} opt_config.openAnimation The animation object to be played when opening the popup.
     *     @param {?hf.fx.dom.PredefinedEffect=} opt_config.closeAnimation The animation object to be played when closing the popup.
     *     @param {?hf.fx.dom.PredefinedEffect=} opt_config.showArrow Whether the popup should display an arrow.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The reference to the popup object used by the picker.
         *
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.popup;

        /**
         * The content of the popup.
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.popupContent_;

        /**
         * @type {*}
         * @private
         */
        this.currentSuggestedValue_;

        /* The list of unsupported methods */
        this.enableAutocompletion = this.enableAutocompletion === undefined ? FunctionsUtils.nullFunction : this.enableAutocompletion;

        this.hasAutocompletion = this.hasAutocompletion === undefined ? FunctionsUtils.nullFunction : this.hasAutocompletion;
        this.setType = this.setType === undefined ? FunctionsUtils.nullFunction : this.setType;
        this.getType = this.getType === undefined ? FunctionsUtils.nullFunction : this.getType;
    }

    /**
     * Shows up the popup.
     *
     *
     */
    open() {
        this.setOpen(true);
    }

    /**
     * Closes down the popup.
     *
     *
     */
    close() {
        if (!this.isInDocument()) {
            return;
        }

        this.setOpen(false);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* Unset the not supported parameters */
        opt_config.autocomplete = false;
        opt_config.type = TextInputTypes.TEXT;

        opt_config.popup = opt_config.popup || {};
        opt_config.popup.matchFieldWidth = opt_config.popup.matchFieldWidth != null ? opt_config.popup.matchFieldWidth : true;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        /* Dispose the popup, since it's not a child of the picker and will not be automatically disposed. */
        BaseUtils.dispose(this.popup);
        this.popup = null;

        BaseUtils.dispose(this.popupContent_);
        this.popupContent_ = null;

        this.currentSuggestedValue_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    getContentElement() {
        return this.getValueWrapperElement();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        /* The popup should also exit the document. Since the popup is not a child of the picker because it can not be
         * a child in the DOM hierarchy, but it is a child in the logical hierarchy, we must call enterDocument/exitDocument
         * when needed. */
        if (this.popup) {
            this.popup.exitDocument();
        }
    }

    /** @inheritDoc */
    setOpen(open) {
        if (!this.isTransitionAllowed(UIComponentStates.OPENED, open)) {
            return;
        }

        super.setOpen(open);

        if (open) {
            this.onOpening();
        } else {
            this.onClosing();
        }
    }

    /** @inheritDoc */
    handleBlur(e) {
        super.handleBlur(e);

        this.close();
    }

    /** @inheritDoc */
    clearValue(opt_silent) {
        super.clearValue(opt_silent);

        this.close();
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.updatePopupWidth();
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        const key = e.keyCode,
            isOpen = this.isOpen();

        switch (key) {

            case KeyCodes.ESC:
                /* if the popup is opened, firstly close it and only then (at the second ESC) send */
                if (isOpen) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                this.dismiss();

                break;

            case KeyCodes.ENTER:
                this.handleEnterKey(e);

                break;

            case KeyCodes.UP:
                if (e.altKey) {
                    // if ALT+UP then close the popup
                    this.close();
                }
                break;

            case KeyCodes.DOWN:
                if (e.altKey) {
                    // if ALT+DOWN then open the popup
                    this.open();
                }
                break;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleEnterKey(e) {
        if (this.isOpen() && this.isSuggestionAvailable()) {
            e.preventDefault();
            e.stopPropagation();

            this.handleSuggestionSelected();
        } else {
            this.accept(this.getRawValue());
        }
    }

    /**
     *
     * @protected
     */
    onOpening() {
        /* open the popup the first time when onOpening is called; after that the binding enters into the scene */
        if (this.popup == null) {
            this.getPopup().open();
        }

        /* update the popup width */
        this.updatePopupWidth();

        this.getHandler()
        /* 1. prevent default on pickers popup to avoid problems when pickers are used inside other popups
         and a click on the picker popup triggers the closing of the parent container popup.
         2. also do not steal the focus from the key event target. */
            .listen(this.popup.getElement(), BrowserEventType.MOUSEDOWN, this.handlePopupElementMouseDown)
            .listen(this.popup.getElement(), [userAgent.engine.isGecko() ? 'DOMMouseScroll' : 'mousewheel'], this.handleMouseWheel);

        if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
            this.getHandler()
                .listen(this.popup.getElement(), BrowserEventType.TOUCHSTART, this.handlePopupElementTouchStart);
        }
    }

    /**
     *
     * @protected
     */
    onClosing() {
        const popup = this.popup;
        if (popup) {
            this.getHandler()
            /* 1. prevent default on pickers popup to avoid problems when pickers are used inside other popups
             and a click on the picker popup triggers the closing of the parent container popup.
             2. also do not steal the focus from the key event target. */
                .unlisten(popup.getElement(), BrowserEventType.MOUSEDOWN, this.handlePopupElementMouseDown);

            if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                this.getHandler()
                    .unlisten(this.popup.getElement(), BrowserEventType.TOUCHSTART, this.handlePopupElementTouchStart);
            }
        }

        // clears the current suggestion
        this.clearSuggestion();
    }

    /**
     * Gets the popup.
     *
     * @returns {hf.ui.popup.Popup} The popup object.
     * @protected
     */
    getPopup() {
        if (this.popup == null) {
            this.popup = this.createPopup();
        }

        return this.popup;
    }

    /**
     * Creates the {@see hf.ui.popup.Popup} object that hosts the suggestion to select from.
     *
     * @returns {!hf.ui.popup.Popup} The popup object.
     * @protected
     */
    createPopup() {
        const popup = new Popup(this.getPopupConfig());

        /* by default popup used in list pickers is not focusable, in order to avoid tab catcher */
        popup.setSupportedState(UIComponentStates.FOCUSED, false);

        popup.addListener(UIComponentEventTypes.ACTION, this.handleSuggestionSelected, false, this);

        this.setBinding(
            popup,
            { get: popup.isOpen, set: popup.setOpen },
            {
                source: this,
                sourceProperty: { get: this.isOpen, set: this.setOpen },
                mode: DataBindingMode.TWO_WAY,
                updateSourceTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE],
                updateTargetTrigger: [UIComponentEventTypes.OPEN, UIComponentEventTypes.CLOSE]
            }
        );

        return popup;
    }

    /**
     * @returns {!object}
     * @protected
     */
    getPopupConfig() {
        const config = this.getConfigOptions(),
            popupConfig = config.popup || {};

        popupConfig.idPrefix = `${this.getId()}-popup`;

        const predefinedExtraCSSClass = [Picker.CssClasses.POPUP_BASE, this.getBaseCSSClass() + Picker.CssClasses.POPUP_SUFFIX];

        popupConfig.extraCSSClass = popupConfig.extraCSSClass ? [].concat(predefinedExtraCSSClass, popupConfig.extraCSSClass) : predefinedExtraCSSClass;

        popupConfig.content = this.getPopupContent();
        // popupConfig['staysOpen'] = true;
        popupConfig.hasDropShadow = BaseUtils.isBoolean(popupConfig.hasDropShadow) ? popupConfig.hasDropShadow : true;
        popupConfig.placementTarget = this.getValueWrapperElement();
        popupConfig.placement = PopupPlacementMode.BOTTOM;

        return popupConfig;
    }

    /**
     * Gets the popupContent field.
     *
     * @returns {hf.ui.UIComponent} The content of the popup.
     * @protected
     */
    getPopupContent() {
        if (this.popupContent_ == null) {
            this.popupContent_ = this.createPopupContent(this.getPopupContentConfig());
            if (this.popupContent_) {
                this.popupContent_.setExtraCSSClass([
                    Picker.CssClasses.POPUP_CONTENT_BASE,
                    this.getBaseCSSClass() + Picker.CssClasses.POPUP_CONTENT_SUFFIX
                ]);
            }
        }

        return this.popupContent_;
    }

    /**
     * Creates the content of the popup.
     *
     * @param {!object=} opt_config Optional configuration object.
     * @returns {hf.ui.UIComponent} The content of the popup.
     * @protected
     */
    createPopupContent(opt_config) { throw new Error('unimplemented abstract method'); }

    /**
     * @returns {!object}
     * @protected
     */
    getPopupContentConfig() {
        return {};
    }

    /**
     * Applies the match field width property. Will set the width of the popup the
     * same as the width of the field.
     *
     * @returns {void}
     * @protected
     */
    updatePopupWidth() {
        if (!this.isInDocument()) {
            return;
        }
        const matchFieldWidth = this.getConfigOptions().popup.matchFieldWidth;
        if (matchFieldWidth && this.popup) {
            this.popup.setWidth(`${StyleUtils.getComputedWidth(this.getValueWrapperElement())}px`);
        }
    }

    /**
     * @param {hf.events.Event} event
     * @protected
     */
    handlePopupElementMouseDown(event) {
        // this.getKeyEventTarget().focus();
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     * @param {hf.events.Event} event
     * @protected
     */
    handlePopupElementTouchStart(event) {
        /* stop the propagation of the TOUCHSTART event to avoid problems when pickers are used inside other popups
         and a click on the picker popup triggers the closing of the parent container popup (see hf.ui.popup.Popup.registerAutomaticClosingEvents_ - listeners on the document) */
        event.stopPropagation();

        /* NOTE: Do not call event.preventDefault() because this will inhibit also the MOUSEDOWN event - which is used to dispatch ACTION event.
         * See also the order of events on touch devices to understand why preventing TOUCHSTART will inhibit also MOUSEDOWN :
         * touchstart
         * touchmove
         * touchend
         * mouseover
         * mousemove
         * mousedown
         * mouseup
         * click
        * */
    }

    /**
     * Gets whether there is any suggestion available.
     *
     * @protected
     */
    isSuggestionAvailable() { throw new Error('unimplemented abstract method'); }

    /**
     * @param {*} suggestedValue
     * @protected
     */
    suggest(suggestedValue) {
        this.currentSuggestedValue_ = suggestedValue;

        suggestedValue = this.coerceSuggestedValue(suggestedValue);

        this.setRawValue(suggestedValue);
    }

    /**
     *
     * @param {*} suggestedValue
     * @protected
     */
    coerceSuggestedValue(suggestedValue) {
        return suggestedValue;
    }

    /**
     * Gets currently suggested value.
     *
     * @returns {*} The currently suggested value.
     * @protected
     */
    getCurrentSuggestedValue() {
        return this.currentSuggestedValue_;
    }

    /**
     * Clears the current suggested value
     *
     * @protected
     */
    clearSuggestion() {
        this.currentSuggestedValue_ = null;
    }

    /**
     * @param {*=} opt_suggestedValue
     * @protected
     */
    accept(opt_suggestedValue) {
        let acceptedValue = opt_suggestedValue != null ? opt_suggestedValue : this.currentSuggestedValue_;
        if (acceptedValue == null) {
            return;
        }

        acceptedValue = this.coerceAcceptedValue(acceptedValue);

        // update the value, setting it to the acceptedValue
        this.setValue(acceptedValue);

        // closes popup and clears the current suggestion as well
        // this.closePopup();
        this.close();
    }

    /**
     *
     * @param {*} acceptedValue
     * @protected
     */
    coerceAcceptedValue(acceptedValue) {
        return acceptedValue;
    }

    /**
     * @protected
     */
    dismiss() {
        this.updateRawValue();

        // this.closePopup();
        this.close();
    }

    /**
     *
     *
     * @param {hf.events.Event=} opt_e The event object.
     * @returns {void}
     * @protected
     */
    handleSuggestionSelected(opt_e) {
        // nop
    }

    /**
     * Handles the MOUSEWHEEL event.
     * If the Picker is hosted in a parent popup, this handler will prevent the closing of the parent popup.
     * Check the Popup behaviour related to the MOUSEWHEEL event
     *
     * @param {hf.events.Event} e The event.
     * @protected
     */
    handleMouseWheel(e) {
        e.stopPropagation();
    }
}

/**
 * @static
 * @protected
 */
Picker.CssClasses = {
    POPUP_BASE: 'hf-form-field-picker-popup',

    POPUP_SUFFIX: '-popup',

    POPUP_CONTENT_BASE: 'hf-form-field-picker-popup-content',

    POPUP_CONTENT_SUFFIX: '-popup-content'
};
