import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {Occupation} from "./../../../common/ui/vcard/Occupation.js";
import {ContactButtonSet} from "./../../../common/ui/ContactButtonSet.js";
import {Avatar} from "./../../../common/ui/Avatar.js";

/**
 * @enum {string}
 */
export const PersonListItemContentEventTypes = {
    /** Triggered when clicking the name or photo of a person in the list
     *  @event hg.person.ui.view.PersonUiListView.EventType.VIEW
     */
    VIEW: 'view'
};

/**
 * Creates a new object representing an item from the people list.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ListItemContent extends UIComponent {
 /**
  * @param {!Object=} opt_config The optional configuration object.
 */
 constructor(opt_config = {}) {
  super(opt_config);

  /**
   * The component that displays the avatar
   * @type {hg.common.ui.Avatar}
   * @private
   */
  this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

  /**
   * @type {hf.ui.UIComponent}
   * @private
   */
  this.personInfoContainer_ = this.personInfoContainer_ === undefined ? null : this.personInfoContainer_;

  /**
   * The component that displays the name.
   * @type {hg.common.ui.vcard.HgPartyName}
   * @private
   */
  this.nameCaption_ = this.nameCaption_ === undefined ? null : this.nameCaption_;

  /**
   * The component that displays the job of the person (organization and position)
   * @type {hg.common.ui.vcard.Occupation}
   * @private
   */
  this.job_ = this.job_ === undefined ? null : this.job_;

  /**
   * Container for contact buttons
   * @type {hg.common.ui.ContactButtonSet}
   * @private
   */
  this.contactButtonSet_ = this.contactButtonSet_ === undefined ? null : this.contactButtonSet_;
 }

 /**
  * @inheritDoc
  */
 init(opt_config = {}) {


     super.init(opt_config);

     this.avatar_ = new Avatar({
         'avatarSize': AvatarSizes.LARGE,
         'extraCSSClass' : ['hg-mainresource-avatar'],
         'showInfoBubble': true
     });

     this.personInfoContainer_ = new LayoutContainer({
         'extraCSSClass': ['hg-content', this.getBaseCSSClass() + '-person-info']
     });

     this.nameCaption_ = new HgPartyName({
         'extraCSSClass' : ['hg-mainresource-name'],
         'displayType'   : true
     });

     this.job_ = new Occupation();

     /* initialize contact button set */
     this.contactButtonSet_ = new ContactButtonSet({
         'extraCSSClass': this.getBaseCSSClass() + '-person-social-button-set',
         'buttonSize': HgButtonUtils.ButtonSize.MEDIUM
     });
 }

 /**
  * @inheritDoc
  */
 getDefaultBaseCSSClass() {
     return 'hg-list-item-content';
 }

 /**
  * @inheritDoc
  */
 createDom() {
     super.createDom();

     this.personInfoContainer_.addChild(this.nameCaption_, true);
     this.personInfoContainer_.addChild(this.job_, true);

     this.addChild(this.avatar_, true);
     this.addChild(this.personInfoContainer_, true);
     this.addChild(this.contactButtonSet_, true);
 }

 /**
  * @inheritDoc
  */
 enterDocument() {
     super.enterDocument();

     this.getHandler()
         .listen(this.personInfoContainer_.getElement(), BrowserEventType.CLICK, this.handleDetailsOpen_)
         .listen(this.avatar_, UIComponentEventTypes.ACTION, this.handleDetailsOpen_);
 }

 /**
  * @inheritDoc
  */
 initBindings() {
     super.initBindings();

     this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, '');

     this.setBinding(this.contactButtonSet_, {'set': this.contactButtonSet_.setModel},'');

     this.setBinding(this.nameCaption_, {'set': this.nameCaption_.setModel}, '');

     this.setBinding(this.job_, {'set': this.job_.setModel}, 'job');
 }

 /**
  * Handles click on person list item. Dispatch custom event depends on the required action: view person or enable the
  * action corresponding to the selected social button.
  * @param {hf.events.Event} e Mouse event to handle.
  * @private
  */
 handleDetailsOpen_(e) {
     /* do not perform any action if there is a text selection */
     if (this.hasSelectedText()) {
         return true;
     }

     const target = e.getTarget();

     const event = new Event(PersonListItemContentEventTypes.VIEW);
     event.addProperty('personId', this.getModel()['personId']);

     this.dispatchEvent(event);
 }

 /**
  * @inheritDoc
  */
 disposeInternal() {
     super.disposeInternal();

     this.avatar_ = null;
     this.nameCaption_ = null;
     this.job_ = null;
     this.contactButtonSet_ = null;

     BaseUtils.dispose(this.personInfoContainer_);
     this.personInfoContainer_ = null;
 }
};