import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {HgStringUtils} from "./../../../common/string/string.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {INTERNAL_PHONE_LABEL} from "./../../../data/model/phonecall/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {HgPhoneCallUtils} from "./../../../data/model/phonecall/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.phone.PhoneCallPartyPhone} caption.
 *
 * @extends {Caption}
 * @unrestricted 
*/
export class PhoneCallPartyPhone extends Caption {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.displayLabel
     *   @param {boolean=} opt_config.allowDialNumber
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return PhoneCallPartyPhone.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        let translator = Translator;

        opt_config['displayLabel'] = opt_config['displayLabel'] != null ? opt_config['displayLabel'] : true;
        opt_config['allowDialNumber'] = opt_config['allowDialNumber'] || false;

        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], PhoneCallPartyPhone.CssClasses.BASE);

        /* extraCSSClass */
        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(
            opt_config['extraCSSClass'] || [],
            function(callParty) {
                let css = [PhoneCallPartyPhone.CssClasses.BASE];

                if(PhoneCallPartyPhone.canDialPhoneNumber_(/**@type {Object}*/(callParty), opt_config['allowDialNumber'])) {
                    css.push(PhoneCallPartyPhone.CssClasses.CAN_DIAL);
                }

                return css;
            });

        /* contentFormatter */
        opt_config['contentFormatter'] = opt_config['contentFormatter'] ||
            function(callParty) {
                if (!PhoneCallPartyPhone.canDisplayPhoneNumber_(/**@type {Object}*/(callParty), opt_config['allowDialNumber'])) {
                    return null;
                }

                const content = document.createDocumentFragment();

                const phoneNumber = callParty['phoneNumber'];
                let isHubgetsPhone = phoneNumber.startsWith(HgAppConfig.HUBGETS_PHONE_PREFIX);

                /* phone number */
                if (callParty['participant'] != null && callParty['participant']['type'] === AuthorType.VISITOR) {
                    content.appendChild(DomUtils.createDom('span', PhoneCallPartyPhone.CssClasses.PHONE_NUMBER, translator.translate('call')));
                }
                else if (callParty['participant'] != null && callParty['participant']['type'] !== AuthorType.THIRDPARTY){
                    const formattedPhoneNumber = isHubgetsPhone ? translator.translate('%ProductName% phone', [CurrentApp.Name]) : HgStringUtils.formatPhone(callParty['phoneNumber'], 'NATIONAL', /**@type {string}*/(HgCurrentUser.get('address.region.country.code')));

                    content.appendChild(DomUtils.createDom('span', PhoneCallPartyPhone.CssClasses.PHONE_NUMBER, formattedPhoneNumber));
                }

                /* phone label - include it if available */
                if (opt_config['displayLabel'] && !StringUtils.isEmptyOrWhitespace(callParty['phoneLabel']) && !isHubgetsPhone) {
                    // todo: add a regexp check also for extra security
                    if (callParty['phoneLabel'] === INTERNAL_PHONE_LABEL) {
                        content.appendChild(DomUtils.createDom('span', PhoneCallPartyPhone.CssClasses.PHONE_LABEL + ' hg-vcard-label', translator.translate('product_phone', [CurrentApp.Name])));
                    }
                    else {
                        content.appendChild(DomUtils.createDom('span', PhoneCallPartyPhone.CssClasses.PHONE_LABEL + ' hg-vcard-label', translator.translate((/** @type {string}*/(callParty['phoneLabel'])).toLowerCase())));
                    }
                }

                return content;
            };

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if(this.getConfigOptions()['allowDialNumber']) {
            this.getHandler().listen(this.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleMouseDown_);
        }

        /* update the dom content if the model is already set */
        if(this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /**
     * @param {hf.events.Event} e the mousedown event
     * @protected
     */
    handleMouseDown_(e) {
        if (this.isEnabled() && !this.hasSelectedText()) {
            const target = e.getTarget();

            if(target && target.nodeType == Node.ELEMENT_NODE && /**@type {Element}*/(target).classList.contains(PhoneCallPartyPhone.CssClasses.PHONE_NUMBER)) {
                const callParty = this.getModel();

                if(HgCurrentUser['canCall'] && callParty != null) {
                    const callPartyEvent = new Event(HgUIEventType.CALL_PARTY);
                    callPartyEvent.addProperty('callInfo', HgPhoneCallUtils.getQuickCallInfo(/**@type {Object}*/(callParty)));

                    this.dispatchEvent(callPartyEvent);
                }
            }
        }
    }

    /**
     *
     * @param {Object} callParty
     * @param {boolean} allowDialNumber
     * @private
     */
    static canDisplayPhoneNumber_(callParty, allowDialNumber) {
        if (callParty == null) {
            return false;
        }

        /* display the visitor's phone number (the 'call' text) only if dialing is allowed */
        if(callParty['participant']['type'] === AuthorType.VISITOR) {
            return allowDialNumber;
        }

        /* phone number conditions */
        if (StringUtils.isEmptyOrWhitespace(callParty['phoneNumber'])
            || [HgAppConfig.HELPLINE, HgAppConfig.ECHOTEST, HgAppConfig.INTERNALSOURCE].includes(callParty['phoneNumber'])) {
            return false;
        }

        return true;
    }

    /**
     *
     * @param {Object} callParty
     * @param {boolean} allowDialNumber
     * @private
     */
    static canDialPhoneNumber_(callParty, allowDialNumber) {
        if (callParty == null || !allowDialNumber) {
            return false;
        }

        /* phone number conditions */
        if (StringUtils.isEmptyOrWhitespace(callParty['phoneNumber'])
            || [HgAppConfig.HELPLINE, HgAppConfig.ECHOTEST, HgAppConfig.INTERNALSOURCE].includes(callParty['phoneNumber'])) {
            return false;
        }

        return true;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @private
 */
PhoneCallPartyPhone.CSS_CLASS_PREFIX = 'hg-call-party-phone';
/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
PhoneCallPartyPhone.CssClasses = {
    BASE            : PhoneCallPartyPhone.CSS_CLASS_PREFIX,

    PHONE_NUMBER    : PhoneCallPartyPhone.CSS_CLASS_PREFIX + '-' + 'number',

    PHONE_LABEL     : PhoneCallPartyPhone.CSS_CLASS_PREFIX + '-' + 'label',

    CAN_DIAL        : PhoneCallPartyPhone.CSS_CLASS_PREFIX + '-' + 'can-dial'
};