import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 *
 * @extends {DataModel}
 * @unrestricted 
*/
export class PersonCSVMapping extends DataModel {
 /**
  * @param {!Object=} opt_initData
 */
 constructor(opt_initData) {
     super(opt_initData);
 }

 /** @inheritDoc */
 defineFields() {
     super.defineFields();

     /* The column position in the CSV file */
     this.addField({'name': 'from', 'type': DataModelField.PredefinedTypes.NUMBER});

     /* Where it should be imported - @type {ImportTaskPersonCSVMappingTo} */
     this.addField({'name': 'to', 'type': DataModelField.PredefinedTypes.STRING});

     /* Optional, will display an indication for this field based on an actual file parsed */
     this.addField({'name': 'preview', 'type': DataModelField.PredefinedTypes.STRING});

     /* True when this mapping has a duplicated value for 'to' field.
     'to' field  is marked as duplicated when the value is identical with another mapping and it is not one of the values
      accepted as duplicated: IGNORE, EMAILOTHER, PHONEOTHER */
     this.addField({'name': 'isDuplicated', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});
 }
};