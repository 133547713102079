import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {ThreadHeaderBase} from "./ThreadHeaderBase.js";
import {HgButtonUtils} from "./../button/Common.js";
import {MessageThreadUIState} from "./../viewmodel/MessageThread.js";
import {ChatEventType} from "./../../../module/chat/EventType.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Base thread header for mini-chat.
 *
 * @extends {ThreadHeaderBase}
 * @unrestricted 
*/
export class MiniThreadHeaderBase extends ThreadHeaderBase {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Button}
         * @protected
         */
        this.closeBtn_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        /* include CHECKED state in the set of supported states */
        this.setSupportedState(UIComponentStates.CHECKED, true);
        this.setDispatchTransitionEvents(UIComponentStates.CHECKED, true);

        /* initialize checked state */
        this.setChecked(!!opt_config['checked']);

        const translator = Translator;

        this.closeBtn_ = HgButtonUtils.createCloseButton({
            'name'          : ThreadHeaderBase.Button.CLOSE,
            'extraCSSClass' : 'mini-chat'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.closeBtn_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass([ThreadHeaderBase.CssClasses.MINI_CHAT_THREAD_HEADER]);

        this.addChild(this.closeBtn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.threadActionsContainer.getElement(), BrowserEventType.MOUSEUP, this.stopPropagation_)
            //.listen(this.threadActionsContainer, [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.stopPropagation_)

            .listen(this.closeBtn_.getElement(), BrowserEventType.MOUSEUP, this.stopPropagation_)
            .listen(this.closeBtn_, UIComponentEventTypes.ACTION, this.handleCloseAction);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.threadActionsContainer, {'set': this.threadActionsContainer.setVisible},
            {
                'sourceProperty': 'uiState',
                'converter'     : {
                    'sourceToTargetFn': function (uiState) {
                        return !!(uiState & MessageThreadUIState.OPENED);
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    getCollaborationControlConfig() {
        const config = super.getCollaborationControlConfig();

        config['tooltip'] = config['tooltip'] || {};
        config['tooltip']['verticalOffset'] = -10;

        return config;
    }

    /** @inheritDoc */
    getActionsMenuButtonsConfig() {
        const config = super.getActionsMenuButtonsConfig();

        config['tooltip'] = config['tooltip'] || {};
        config['tooltip']['verticalOffset'] = -10;

        return config;
    }

    /** @inheritDoc */
    getThreadActionsMenuButtonsConfig() {
        const config = super.getThreadActionsMenuButtonsConfig();

        config['tooltip'] = config['tooltip'] || {};
        config['tooltip']['verticalOffset'] = -10;

        return config;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleCloseAction(e) {
        const model = /** @type {hg.data.model.thread.IThread} */(this.getModel());

        if(model) {
            const event = new Event(ChatEventType.THREAD_CLOSE);
            event.addProperty('thread', model);

            this.dispatchEvent(event);
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    stopPropagation_(e) {
        e.stopPropagation();
        return false;
    }
};