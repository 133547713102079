import { Listener } from './Listener.js';
import { ArrayUtils } from '../array/Array.js';

/**
 * Creates a new listener map.
 *
 * @final
 
 *
 */
export class ListenerMap {
    /**
     * @param {ListenableType} src The src object.
     */
    constructor(src) {
        /** @type {ListenableType} */
        this.src = src;

        /**
         * Maps of event type to an array of listeners.
         *
         * @type {!object<string, !Array<!hf.events.Listener>>}
         */
        this.listeners = {};

        /**
         * The count of types in this map that have registered listeners.
         *
         * @private {number}
         */
        this.typeCount_ = 0;
    }

    /**
     * @returns {number} The count of event types in this map that actually
     *     have registered listeners.
     */
    getTypeCount() {
        return this.typeCount_;
    }

    /**
     * @returns {number} Total number of registered listeners.
     */
    getListenerCount() {
        let count = 0;
        for (let type in this.listeners) {
            count += this.listeners[type].length;
        }
        return count;
    }

    /**
     * Adds an event listener. A listener can only be added once to an
     * object and if it is added again the key for the listener is
     * returned.
     *
     * Note that a one-off listener will not change an existing listener,
     * if any. On the other hand a normal listener will change existing
     * one-off listener to become a normal listener.
     *
     * @param {string} type The listener event type.
     * @param {!Function} listener This listener callback method.
     * @param {boolean} callOnce Whether the listener is a one-off
     *     listener.
     * @param {boolean=} opt_useCapture The capture mode of the listener.
     * @param {object=} opt_listenerScope Object in whose scope to call the
     *     listener.
     * @returns {!hf.events.ListenableKey} Unique key for the listener.
     */
    add(type, listener, callOnce, opt_useCapture, opt_listenerScope) {
        const typeStr = type.toString();
        let listenerArray = this.listeners[typeStr];
        if (!listenerArray) {
            listenerArray = this.listeners[typeStr] = [];
            this.typeCount_++;
        }

        let listenerObj;
        const index = ListenerMap.findListenerIndex_(
            listenerArray, listener, opt_useCapture, opt_listenerScope
        );
        if (index > -1) {
            listenerObj = listenerArray[index];
            if (!callOnce) {
                // Ensure that, if there is an existing callOnce listener, it is no
                // longer a callOnce listener.
                listenerObj.callOnce = false;
            }
        } else {
            listenerObj = new Listener(
                listener, null, this.src, typeStr, !!opt_useCapture, opt_listenerScope
            );
            listenerObj.callOnce = callOnce;
            listenerArray.push(listenerObj);
        }
        return listenerObj;
    }

    /**
     * Removes a matching listener.
     *
     * @param {string} type The listener event type.
     * @param {!Function} listener This listener callback method.
     * @param {boolean=} opt_useCapture The capture mode of the listener.
     * @param {object=} opt_listenerScope Object in whose scope to call the
     *     listener.
     * @returns {boolean} Whether any listener was removed.
     */
    remove(type, listener, opt_useCapture, opt_listenerScope) {
        const typeStr = type.toString();
        if (!(typeStr in this.listeners)) {
            return false;
        }

        const listenerArray = this.listeners[typeStr];
        const index = ListenerMap.findListenerIndex_(
            listenerArray, listener, opt_useCapture, opt_listenerScope
        );
        if (index > -1) {
            const listenerObj = listenerArray[index];
            listenerObj.markAsRemoved();
            listenerArray.splice(index, 1);

            if (listenerArray.length == 0) {
                delete this.listeners[typeStr];
                this.typeCount_--;
            }
            return true;
        }
        return false;
    }

    /**
     * Removes the given listener object.
     *
     * @param {!hf.events.ListenableKey} listener The listener to remove.
     * @returns {boolean} Whether the listener is removed.
     */
    removeByKey(listener) {
        const type = listener.type;
        if (!(type in this.listeners)) {
            return false;
        }

        const removed = ArrayUtils.remove(this.listeners[type], listener);
        if (removed) {
            /** @type {!hf.events.Listener} */ (listener).markAsRemoved();
            if (this.listeners[type].length == 0) {
                delete this.listeners[type];
                this.typeCount_--;
            }
        }
        return removed;
    }

    /**
     * Removes all listeners from this map. If opt_type is provided, only
     * listeners that match the given type are removed.
     *
     * @param {string=} opt_type Type of event to remove.
     * @returns {number} Number of listeners removed.
     */
    removeAll(opt_type) {
        let typeStr = opt_type && opt_type.toString();
        let count = 0;
        for (let type in this.listeners) {
            if (!typeStr || type == typeStr) {
                const listenerArray = this.listeners[type];
                for (let i = 0; i < listenerArray.length; i++) {
                    ++count;
                    listenerArray[i].markAsRemoved();
                }
                delete this.listeners[type];
                this.typeCount_--;
            }
        }
        return count;
    }

    /**
     * Gets all listeners that match the given type and capture mode. The
     * returned array is a copy (but the listener objects are not).
     *
     * @param {string} type The type of the listeners
     *     to retrieve.
     * @param {boolean} capture The capture mode of the listeners to retrieve.
     * @returns {!Array<!hf.events.ListenableKey>} An array of matching
     *     listeners.
     */
    getListeners(type, capture) {
        const listenerArray = this.listeners[type.toString()];
        const rv = [];
        if (listenerArray) {
            for (let i = 0; i < listenerArray.length; ++i) {
                const listenerObj = listenerArray[i];
                if (listenerObj.capture == capture) {
                    rv.push(listenerObj);
                }
            }
        }
        return rv;
    }

    /**
     * Gets the hf.events.ListenableKey for the event or null if no such
     * listener is in use.
     *
     * @param {string} type The type of the listener
     *     to retrieve.
     * @param {!Function} listener The listener function to get.
     * @param {boolean} capture Whether the listener is a capturing listener.
     * @param {object=} opt_listenerScope Object in whose scope to call the
     *     listener.
     * @returns {hf.events.ListenableKey} the found listener or null if not found.
     */
    getListener(type, listener, capture, opt_listenerScope) {
        let listenerArray = this.listeners[type.toString()],
            i = -1;

        if (listenerArray) {
            i = ListenerMap.findListenerIndex_(listenerArray, listener, capture, opt_listenerScope);
        }

        return i > -1 ? listenerArray[i] : null;
    }

    /**
     * Whether there is a matching listener. If either the type or capture
     * parameters are unspecified, the function will match on the
     * remaining criteria.
     *
     * @param {string=} opt_type The type of the listener.
     * @param {boolean=} opt_capture The capture mode of the listener.
     * @returns {boolean} Whether there is an active listener matching
     *     the requested type and/or capture phase.
     */
    hasListener(opt_type, opt_capture) {
        let hasListener = false,
            hasType = opt_type !== undefined,
            typeStr = hasType ? opt_type.toString() : '',
            hasCapture = opt_capture !== undefined;

        for (let key in this.listeners) {
            if (this.listeners.hasOwnProperty(key)) {
                let listenerArray = this.listeners[key];

                for (let i = 0; i < listenerArray.length; ++i) {
                    if ((!hasType || listenerArray[i].type == typeStr)
                        && (!hasCapture || listenerArray[i].capture == opt_capture)) {
                        hasListener = true;

                        break;
                    }
                }
            }
        }

        return hasListener;
    }

    /**
     * Finds the index of a matching hf.events.Listener in the given
     * listenerArray.
     *
     * @param {!Array<!hf.events.Listener>} listenerArray Array of listener.
     * @param {!Function} listener The listener function.
     * @param {boolean=} opt_useCapture The capture flag for the listener.
     * @param {object=} opt_listenerScope The listener scope.
     * @returns {number} The index of the matching listener within the listenerArray.
     * @private
     */
    static findListenerIndex_(listenerArray, listener, opt_useCapture, opt_listenerScope) {
        for (let i = 0; i < listenerArray.length; ++i) {
            const listenerObj = listenerArray[i];
            if (!listenerObj.removed && listenerObj.listener == listener
                && listenerObj.capture == !!opt_useCapture
                && listenerObj.handler == opt_listenerScope) {
                return i;
            }
        }
        return -1;
    }
}
