import {AppEvent} from "./../../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";

import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {HgAppViews} from "./../../../app/Views.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {PersonAddViewmodel} from "./../viewmodel/PersonAdd.js";
import {PersonTypes, PhoneContactLabels} from "./../../../data/model/person/Enums.js";
import {HgResourceAccessLevels, HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {PersonEdit} from "./../../../data/model/person/PersonEdit.js";
import {HgAppConfig} from "./../../../app/Config.js";

import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {AddDialogView} from "./../view/AddDialog.js";
import {CurrentApp} from "./../../../../../../hubfront/phpnoenc/js/app/App.js";
import {ResourceShareGranteeTypes} from "./../../../data/model/share/Enums.js";
import ShareService from "../../../data/service/ShareService.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class AddDialogPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {PersonService}
         * @protected
         */
        this.personService;
    }

    /** @inheritDoc */
    submit() {
        const model = /** @type {hg.module.person.viewmodel.PersonAddViewmodel} */(this.getModel()),
            person = model ? model['personEdit'] : null;

        if (person != null) {
            /* if the person was not changed there is no point to try to save it */
            if(!person.isDirty()) {
                this.closeDialog();
            }else if(!person.isSavable()) {
                return;
            }
            else {
                this.executeAsync(
                    this.savePersonInternal,
                    this.onSaveSuccess,
                    null,
                    CommonBusyContexts.SUBMIT
                );
            }
        }
        else {
            this.closeDialog();
        }
    }

    /**
     * Close dialog and discard changes
     * Person: discard changes, cached for an amount of time in order to allow easy transition from view to edit modes
     * @param {boolean=} opt_close
     * @override
     */
    cancel(opt_close) {
        if(!BaseUtils.isBoolean(opt_close) || !!opt_close) {
            this.closeDialog();
        }
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.personService = PersonService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.personService = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.PEOPLE_ADD;
    }
    
    /** @inheritDoc */
    loadView() {
        return new AddDialogView();
    }

    /**
     * @protected
     */
    loadModel() {
        this.onModelLoaded(this.loadEmptyModel_());
    }

    /**
     * @param {*} model
     * @protected
     */
    onModelLoaded(model) {
        if(model && model['personEdit'] instanceof DataModel) {
            (/**@type {hf.data.DataModel}*/(model['personEdit'])).beginEdit();
        }

        this.setModel(model);

        this.openDialog();
    }

    /**
     * @returns {!Object}
     * @private
     */
    loadEmptyModel_() {
        const newPerson = new PersonEdit({
            'type': PersonTypes.CUSTOMER
        });


        const payload = this.getState().getPayload(),
            phoneNumber = payload != null ? payload['phoneNumber'] : undefined;

        if (phoneNumber != null) {
            const newPhone = newPerson.get('contact.phone').addNew({
                'value': phoneNumber,
                'label': PhoneContactLabels.OFFICE,
                'primary': true
            });
        }

        return new PersonAddViewmodel({
            'personEdit' : newPerson
        });
    }

    /**
     * @protected
     * @returns {!Promise}
     */
    savePersonInternal() {
        const model = /** @type {hg.module.person.viewmodel.PersonAddViewmodel} */(this.getModel());

        const translator = Translator;
        if(model) {
            const isOrgShared = model['personEdit']['orgShared'];
            return this.personService.savePerson(model['personEdit'])
                .then((person) => {
                    if (person && isOrgShared) {
                        const shareService = ShareService;

                        if (shareService) {
                            return shareService.share({
                                'resource': {
                                    'resourceId': person['personId'],
                                    'resourceType': HgResourceCanonicalNames.PERSON
                                },
                                'grantee': [{
                                    'granteeId' : undefined,
                                    'type'      : ResourceShareGranteeTypes.ORGANIZATION,
                                    'name'      : translator.translate('entire_team_all'),
                                    'avatar' : UriUtils.buildFromEncodedParts(UriUtils.getScheme(CurrentApp.StartupUrl), '', CurrentApp.StartupUrl.hostname, '', HgAppConfig.ORGANIZATION_DEFAULT_AVATAR_PATH),
                                    'accessLevel': HgResourceAccessLevels.READ
                                }],
                            });
                        }
                    }

                    return person;
                });
        }

        return Promise.reject(new Error(translator.translate('person_save_failure')));
    }

    /**
     * @protected
     */
    onSaveSuccess() {
        const model = /** @type {hg.module.person.viewmodel.PersonAddViewmodel} */(this.getModel()),
            translator = Translator,
            eventBus = this.getEventBus(),
            person = model['personEdit'];

        // firstly close the dialog
        this.closeDialog();

        /* compute metacontent tag */
        const tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.PERSON, person), /* initialize payload for hg.HgAppEvents.PUSH_APP_NOTIFICATION*/
            payload = {
                'title': translator.translate('people'),
                'avatar': HgCurrentUser['avatar'],
                'body': translator.translate('person_successfully_added', [tag])
            };

        /* dispatch person add notification
         * NOTE: use the event bus directly to dispatch the event and not the #dispatchEvent method. */
        eventBus.dispatchEvent(new AppEvent(HgAppEvents.PUSH_APP_NOTIFICATION, payload));
    }
};