import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {HorizontalStack} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {ChatEventType} from "./../../EventType.js";
import {HgResourceStatus, HgResourceWatchStatus} from "./../../../../data/model/resource/Enums.js";
import {Avatar} from "./../../../../common/ui/Avatar.js";
import {TopicType} from "./../../../../data/model/thread/Enums.js";
import {AuthorType} from "./../../../../data/model/author/Enums.js";
import {ConversationHeader} from "./ConversationHeader.js";
import {MiniConversationHeader} from "./MiniConversationHeader.js";
import {TopicHeader} from "./TopicHeader.js";
import {MiniTopicHeader} from "./MiniTopicHeader.js";
import {HelpPanel} from "./../../../../common/ui/HelpPanel.js";
import {NotificationsContainer} from "./../../../../common/ui/notification/NotificationsContainer.js";
import {MessageList} from "./../../../../common/ui/message/MessageList.js";
import {DisplayContexts, TopicActions} from "./../../../../common/enums/Enums.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.chat.MessageThread} component.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class MessageThread extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *      @param {!Object=} opt_config.displayContext Used to further configure how the message thread
     *      will be displayed depending on the context it's instantiated from. {@see DisplayContexts}
     *      It is also passed down to {@see hg.common.ui.message.MessageGroup} and {@see hg.common.ui.message.GeneralEventGroup}.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {string}
         * @private
         */
        this.topicCss_;

        /**
         * Thread header.
         * @type {hg.common.ui.thread.ThreadHeaderBase}
         * @private
         */
        this.header_;

        /**
         * Thread header.
         * @type {hf.ui.UIControl}
         * @private
         */
        this.threadStatusWarning_;

        /**
         * Thread message history
         * @type {hg.common.ui.message.MessageList}
         * @private
         */
        this.messagesHistoryList_;

        /**
         * Indicator for when there is no thread history
         * @type {hg.common.ui.HelpPanel}
         * @private
         */
        this.noMessagesIndicator_;

        /**
         * @type {hg.common.ui.notification.NotificationsContainer}
         * @private
         */
        this.notificationsContainer_ = this.notificationsContainer_ === undefined ? null : this.notificationsContainer_;
    }

    /**
     * Scrolls to the last message.
     *
     * @param {boolean=} opt_force True to scroll to the last message no matter what.
     * If false, the the messages list is scroll to the last message ONLY if the scroll is near the last message.
     */
    scrollToLastMessage(opt_force) {
        opt_force = !!opt_force;

        this.messagesHistoryList_.scrollToLastMessage(opt_force);
    }

    /**
     * Displays a thread notification.
     * For example, for a topic thread displays Join/Leave notifications for participants (except me).
     * @param {Object} notificationData
     */
    pushNotification(notificationData) {
        this.notificationsContainer_.pushNotification(notificationData);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['displayContext'] = opt_config['displayContext'] || DisplayContexts.CHAT;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.threadStatusWarning_ = new UIControl({
            'extraCSSClass': 'hg-thread-status-warning',
            'contentFormatter': (topicData) => {
                let warningMessage = '';

                if (topicData == null) return warningMessage;

                if (topicData['type'] === TopicType.DIRECT) {
                    if (topicData['status'] === HgResourceStatus.CLOSED) {
                        switch (topicData['interlocutorType']) {
                            case AuthorType.USER:
                                warningMessage = "member_isNot_available";
                                break;

                            case AuthorType.VISITOR:
                                warningMessage = "conversation_closed";
                                break;

                            case AuthorType.BOT:
                                warningMessage = "bot_isNot_available";
                                break;
                        }
                    }
                } else {
                    if (topicData['status'] === HgResourceStatus.CLOSED) {
                        warningMessage = "topic_closed";
                    } else if (topicData['watchedByMe'] === false) { // NOTE: compare to false to skip the use case when the value is null or undefined
                        warningMessage = "topic_start_watching"
                    }
                }

                if (warningMessage) {
                    warningMessage = Translator.translate(warningMessage);

                    this.setNoMessagesPopupOpen_(false);

                    if (topicData['watchedByMe'] === false) { // NOTE: compare to false to skip the use case when the value is null or undefined
                        warningMessage = warningMessage.replace(
                            RegExpUtils.LP_LINK_RE,
                            function (fullMatch, linkText) {
                                return `<div class="hg-linklike">${linkText}</div>`;
                            }
                        );
                    }
                }

                return warningMessage;
            }
        });

        /* initialize current thread history */
        this.messagesHistoryList_ = new MessageList({
            'extraCSSClass': 'hg-chat-conversation',
            'displayContext': opt_config['displayContext']
        });

        this.noMessagesIndicator_ = new HelpPanel({
            'extraCSSClass': 'hg-chat-empty',
            'showArrow': true
        });

        this.notificationsContainer_ = new NotificationsContainer({
            'extraCSSClass': 'hg-topics-notifications-container',
            'notification': {
                'hideDelay': 3000,
                'contentFormatter': function (notificationData) {
                    if (notificationData == null) {
                        return null;
                    }

                    const mainContainer = new HorizontalStack(),
                        name = new UIControl({
                            'model': notificationData,
                            'extraCSSClass': 'hg-topic-notification-participant-name',
                            'contentFormatter': function (notification) {
                                if (notification == null) {
                                    return null;
                                }

                                return notification['watchStatus'] == HgResourceWatchStatus.WATCH ?
                                    translator.translate('name_started_watch', [notification['watcher']['name']]).replace(/<status>(.*?)<\/status>/gi,
                                        function (fullMatch, status) {
                                            return `<br><span class="hg-topic-notification-participant-status">${status}</span>`;
                                        }) : translator.translate('name_no_watching', [notification['watcher']['name']]).replace(/<status>(.*?)<\/status>/gi,
                                        function (fullMatch, status) {
                                            return `<br><span class="hg-topic-notification-participant-status">${status}</span>`;
                                        });
                            }
                        });

                    if (opt_config['displayContext'] != DisplayContexts.MINICHAT) {
                        mainContainer.addChild(new Avatar({
                            'avatarSize': AvatarSizes.MEDIUM,
                            'extraCSSClass': 'hg-topic-notification-participant-avatar',
                            'model': notificationData['watcher']
                        }), true);
                    }
                    mainContainer.addChild(name, true);

                    return mainContainer;
                },
                'styleFormatter': function (notificationData) {
                    if (notificationData == null) {
                        return '';
                    }

                    return notificationData['watchStatus'];
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.header_ = null;
        this.messagesHistoryList_ = null;
        this.noMessagesIndicator_ = null;
        this.notificationsContainer_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-chat-thread';
    }

    /**
     * @override
     */
    createDom() {
        super.createDom();

        this.addChild(this.threadStatusWarning_, true);
        this.addChild(this.messagesHistoryList_, true);
        this.addChild(this.notificationsContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.threadStatusWarning_.getElement(), BrowserEventType.CLICK, this.handleThreadStatusWarningAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.cleanupNoMessagesPopup_();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateThreadCss_}, 'thread');

        this.setBinding(this, {'set': this.createThreadHeader}, {
            'sourceProperty': 'thread',
            'mode': DataBindingMode.ONE_TIME
        });

        this.setBinding(this.threadStatusWarning_, {'set': this.threadStatusWarning_.setModel}, {
            'sources': [
                {'sourceProperty': 'thread.status'},
                {'sourceProperty': 'thread.watchedByMe'},
                {'sourceProperty': 'thread.type'},
                {'sourceProperty': 'thread.interlocutor.type'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    // NOTE: return an object every time instead of returning the topic (sources[0]), otherwise the content formatter will not run
                    return {
                        status: sources[0],
                        watchedByMe: sources[1],
                        type: sources[2],
                        interlocutorType: sources[3]
                    }
                }
            }
        });

        this.setBinding(this.messagesHistoryList_, {'set': this.messagesHistoryList_.setModel}, '');

        const cfg = this.getConfigOptions();
        if (cfg['displayContext'] != DisplayContexts.MINICHAT) {
            this.setBinding(this, {'set': this.updateNoMessagesIndicator_}, 'thread');

            this.setBinding(this, {'set': this.setNoMessagesPopupOpen_}, {
                'sources': [
                    {'sourceProperty': 'messageCount'},
                    {'sourceProperty': 'thread.status'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        return sources[0] === 0 && sources[1] !== HgResourceStatus.CLOSED;
                    }
                }
            });
        }
    }

    /**
     * Cleanup help panel
     * @private
     */
    cleanupNoMessagesPopup_() {
        if (this.indexOfChild(this.noMessagesIndicator_) > -1) {
            this.removeChild(this.noMessagesIndicator_, true);
        }
    }

    /**
     * Handle popup open/close with fade animation
     * @param {boolean} open Whether to open or close the popup
     * @private
     */
    setNoMessagesPopupOpen_(open) {
        if (open) {
            if (this.indexOfChild(this.noMessagesIndicator_) == -1) {
                this.addChild(this.noMessagesIndicator_, true);
            }

            this.repositionNoMessagesIndicator_();
            this.noMessagesIndicator_.open();
        } else {
            if (this.noMessagesIndicator_ != null) {
                this.noMessagesIndicator_.close();
                this.cleanupNoMessagesPopup_();
            }
        }
    }

    /**
     * @param {hg.data.model.thread.IThread} topic
     * @private
     */
    updateNoMessagesIndicator_(topic) {
        if (topic) {
            if (topic['type'] === TopicType.DIRECT) {
                this.noMessagesIndicator_.swapExtraCSSClass('topic', 'conversation');
                if (topic['interlocutor']['type'] === AuthorType.VISITOR) {
                    this.noMessagesIndicator_.setTitle("you_are_connected");
                    this.noMessagesIndicator_.setDescription("start_conversation_guest");
                } else {
                    this.noMessagesIndicator_.setTitle("say_hi");
                    this.noMessagesIndicator_.setDescription("start_conversation");
                }
                //this.repositionNoMessagesIndicator_();
            } else {

                this.noMessagesIndicator_.swapExtraCSSClass('conversation', 'topic');
                this.noMessagesIndicator_.setTitle("topic_is_crying");
                this.noMessagesIndicator_.setDescription("break_the_ice");
                this.repositionNoMessagesIndicator_();
            }
        } else {
            /* remove empty message indicator */
            //this.cleanupNoMessagesPopup_();
        }
    }

    /** Reposition indication for empty thread on thread change  */
    repositionNoMessagesIndicator_() {
        if (this.header_) {
            const size = this.header_.getSize(true),
                elem = this.noMessagesIndicator_.getElement();

            if (elem && size.height != null) {
                elem.style.top = size.height + 'px';
            }
        }
    }

    updateThreadCss_(topic) {
        if (!topic) {
            this.removeExtraCSSClass(this.topicCss_);
        }

        if (topic['type'] === TopicType.DIRECT) {
            const interlocutorType = topic.get('interlocutor.type');

            this.topicCss_ = `hg-thread-conversation`;

            if (interlocutorType) {
                this.topicCss_ = `${this.topicCss_} interlocutor-${interlocutorType.toLowerCase()}`;
            }
        } else {
            this.topicCss_ = 'hg-thread-topic';
        }

        this.addExtraCSSClass(this.topicCss_);
    }

    /**
     * Initialize the thread header.
     * @param {hf.data.DataModel} topic
     * @protected
     */
    createThreadHeader(topic) {
        if (!this.header_ && topic != null) {
            if (this.getConfigOptions()['displayContext'] == DisplayContexts.MINICHAT) {
                this.header_ = topic['type'] === TopicType.DIRECT ? new MiniConversationHeader() : new MiniTopicHeader();
            } else {
                this.header_ = topic['type'] === TopicType.DIRECT ? new ConversationHeader() : new TopicHeader();
            }

            this.addChildAt(this.header_, 0, true);

            this.header_.addListener(UIComponentEventTypes.ACTION, this.handleHeaderAction_, false, this);

            this.setBinding(this.header_, {'set': this.header_.setModel}, '');
        }
    }

    /**
     *
     * @param {boolean} isBusy
     * @param {*} opt_busyContext
     */
    setBusy(isBusy, opt_busyContext) {
        if (this.noMessagesIndicator_ && isBusy) {
            this.cleanupNoMessagesPopup_();
        }

        if (this.header_) {
            this.header_.setVisible(!isBusy);
        }

        this.messagesHistoryList_.setVisible(!isBusy);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleHeaderAction_(e) {
        const model = /** @type {ChatThreadViewmodel} */(this.getModel()),
            target = e.getTarget();

        if (model && this.header_ && target == this.header_) {
            const event = new Event(e.originalType === BrowserEventType.MOUSEUP ? ChatEventType.THREAD_EDITOR_FOCUS :
                model.isOpen() ? ChatEventType.THREAD_MINIMIZE : ChatEventType.THREAD_MAXIMIZE);
            event.addProperty('thread', model);

            this.dispatchEvent(event);

            /* dispatch event to focus thread editor */
            const threadFocusEvent = new Event(ChatEventType.THREAD_FOCUS);
            threadFocusEvent.addProperty('thread', model);

            this.dispatchEvent(threadFocusEvent);
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleThreadStatusWarningAction_(e) {
        const model = /** @type {ChatThreadViewmodel} */(this.getModel()),
            topic = model['thread'];

        if (topic != null) {
            if (!topic['type'] && topic['isOpen'] && !topic['watchedByMe']) {
                const event = new Event(HgUIEventType.THREAD_ACTION);
                event.addProperty('payload', {
                    'action': TopicActions.WATCH,
                    'thread': topic
                });

                this.dispatchEvent(event);
            }
        }
    }
}