import {QueryableCache} from "./../../../../../hubfront/phpnoenc/js/cache/QueryableCache.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {EmoticonBubble, EmoticonBubbleMode} from "./../../common/ui/EmoticonBubble.js";
import {EmoticonBubbleViewmodel} from "./../../common/ui/viewmodel/EmoticonBubble.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import {HUGList, EmoticonCategory} from "./../../common/enums/Enums.js";

/**
 * Service provider for the remote URLPreview service.
 * @extends {AbstractService}
 * @unrestricted 
*/
class EmoticonService extends AbstractService {
    constructor() {
        super();

        /**
         * Local cache for peers.
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.emojiCache_ = this.emojiCache_ === undefined ? null : this.emojiCache_;

        /**
         * @private
         */
        this.emoticonPopup_ = this.emoticonPopup_ === undefined ? null : this.emoticonPopup_;

        /**
         * @type {hf.ui.ToggleButton}
         * @private
         */
        this.toggleActionButton_ = this.toggleActionButton_ === undefined ? null : this.toggleActionButton_;
    }

    /**
     *
     * @param {Object} payload
     * @returns {hg.common.ui.EmoticonBubble}
     */
    openEmoticonsBubble(payload) {
        return this.openEmoticonsBubbleInternal_(payload);
    }

    /**
     * Load emoji matching the search criteria
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria The criteria to search people on.
     * @return {Promise}
     */
    searchEmoji(searchCriteria) {
        const searchTerm = searchCriteria.getSearchValue() || '';

        if (searchTerm.startsWith(':') && !StringUtils.isEmptyOrWhitespace(searchTerm.substring(1))) {
            searchCriteria.filter({
                'predicate' : (record) => {
                    if (record['code'].includes(/** @type {string} */(searchTerm))) {
                        return true;
                    }

                    const match = record['alias'] != null ? record['alias'].find(function (shortName) {
                        return shortName.includes(/** @type {string} */(searchTerm));
                    }) : null;

                    return match != null;
                }
            });
        }

        return Promise.resolve(this.emojiCache_.query(searchCriteria));
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.emoticonPopup_ = {
            /** @type {hg.common.ui.EmoticonBubble} */  MINI_CHAT   : null,
            /** @type {hg.common.ui.EmoticonBubble} */  DEFAULT     : null
        };

        this.emojiCache_ = new QueryableCache();

        Object.values(emoji.emojis).forEach(function (value) {
            if (value['shortnames']) {
                const shortName = value['shortnames'] ? value['shortnames'][0] : null;

                if (shortName) {
                    this.emojiCache_.set(shortName, {
                        'code': shortName,
                        'alias': value['shortnames'].filter(function (entry) {
                            return entry !== shortName;
                        }),
                        'category': EmoticonService.RemoteCategory[value['group']],
                        'score': value['score'] || 0
                    });
                }
            }
        }, this);

        emoji.filterByCategory('animation').forEach(function (value) {
            var shortName = value['shortnames'] ? value['shortnames'][0] : null;

            if (shortName) {
                this.emojiCache_.set(shortName, {
                    'code': shortName,
                    'alias': [],
                    'category': EmoticonService.RemoteCategory['animation'],
                    'score': 0
                });
            }
        }, this);
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHOW_EMOTICONS, this.handleEmoticonBubbleDisplay_)
            .listen(eventBus, HgAppEvents.EDITOR_RESIZE, this.handleEditorResize_)
            .listen(eventBus, HgAppEvents.CONNECTION_STATUS_CHANGE, this.onConnectionChange_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.emojiCache_);
        delete this.emojiCache_;

        if (this.emoticonPopup_ !== null) {
            BaseUtils.dispose(this.emoticonPopup_);
            delete this.emoticonPopup_;
        }
    }

    /**
     *
     * @param {Object} payload
     * @returns {hg.common.ui.EmoticonBubble}
     * @private
     */
    openEmoticonsBubbleInternal_(payload) {
        const placementTarget = payload['placementTarget'],
            placement = payload['placement'],
            staysOpenWhenClicking = payload['staysOpenWhenClicking'],
            eventTarget = payload['eventTarget'],
            bubbleMode = payload['mode'];

        this.toggleActionButton_ = payload['toggleActionButton'] || null;

        const emoticonBubble = this.getEmoticonBubble_(bubbleMode);
        let canOpen = false;

        // if open and has the same target close it, otherwise close it and reopen with different target
        if (emoticonBubble.isOpen()) {
            const currentTarget = emoticonBubble.getPlacementTarget();

            /* close bubble for current target */
            emoticonBubble.close();
            if (this.toggleActionButton_ &&  this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
                this.toggleActionButton_.setChecked(false);
            }

            if (placementTarget != currentTarget) {
                canOpen = true;
            }
        } else {
            canOpen = true;
        }

        if (canOpen) {
            emoticonBubble.setPlacementTarget(placementTarget);

            if(bubbleMode == EmoticonBubbleMode.MINI_CHAT) {
                emoticonBubble.setRenderParent(payload['renderParent']);
            }

            if (staysOpenWhenClicking != null) {
                emoticonBubble.setStaysOpenWhenClicking(staysOpenWhenClicking);
            }

            if (placement != null) {
                emoticonBubble.setPlacement(placement);
            }

            /* bubble content events to desired component */
            if (eventTarget != null) {
                emoticonBubble.setParentEventTarget(eventTarget);
            }

            if (this.toggleActionButton_ && this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
                this.toggleActionButton_.setChecked(true);
            }

            /* create and set the model for emoticon bubble */
            const emoticonViewmodel = new EmoticonBubbleViewmodel();
            emoticonViewmodel.setEmoticonsDataProvider(this);
            emoticonBubble.setModel(emoticonViewmodel);

            /* open emoticon bubble */
            emoticonBubble.open();
        }

        return emoticonBubble;sear
    }

    /**
     * Opens the smiley selector popup.
     * @param {number=} bubbleMode
     * @return {hg.common.ui.EmoticonBubble}
     * @private
     */
    getEmoticonBubble_(bubbleMode) {
        if(bubbleMode == EmoticonBubbleMode.DEFAULT || bubbleMode == null) {
            if (this.emoticonPopup_.DEFAULT == null) {
                /* custom emoticon bubble that avoids focus steal from editor */
                this.emoticonPopup_.DEFAULT = new EmoticonBubble();

                this.emoticonPopup_.DEFAULT.addListener(UIComponentEventTypes.CLOSE, this.handleEmoticonBubbleClose_, false, this);
            }

            return this.emoticonPopup_.DEFAULT;

        } else if(bubbleMode == EmoticonBubbleMode.MINI_CHAT){
            if (this.emoticonPopup_.MINI_CHAT == null) {
                /* custom emoticon bubble that avoids focus steal from editor */
                this.emoticonPopup_.MINI_CHAT = new EmoticonBubble({
                    'showArrow'     : false,
                    'extraCSSClass' : ['hg-emoticon-bubble-mini-chat'],
                    'mode'          : bubbleMode,
                    'staysOpen'     : true
                });

                this.emoticonPopup_.MINI_CHAT.addListener(UIComponentEventTypes.CLOSE, this.handleEmoticonBubbleClose_, false, this);
            }

            return this.emoticonPopup_.MINI_CHAT;
        }

        return this.emoticonPopup_.DEFAULT;
    }

    /**
     * Repositioning the popup on resize
     * @private
     */
    handleEditorResize_() {
        if (this.emoticonPopup_.DEFAULT != null && this.emoticonPopup_.DEFAULT.isOpen()) {
            this.emoticonPopup_.DEFAULT.reposition();
        }

        if (this.emoticonPopup_.MINI_CHAT != null && this.emoticonPopup_.MINI_CHAT.isOpen()) {
            this.emoticonPopup_.MINI_CHAT.reposition();
        }
    }

    /**
     * Opens or closes the emoticon bubble selector.
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleEmoticonBubbleDisplay_(e) {
        this.openEmoticonsBubbleInternal_(e.getPayload());
    }

    /**
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmoticonBubbleClose_(e) {
        /**@type {hg.common.ui.EmoticonBubble}*/(e.getTarget()).setModel(null);

        if (this.toggleActionButton_ && this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
            this.toggleActionButton_.setChecked(false);
        }
    }

    /**
     * Close emoticon bubble if connection not available
     * @param e
     * @private
     */
    onConnectionChange_(e) {
        const payload = e.getPayload() || {};
        let isConnected = payload['isConnected'];
        if(isConnected != null && !isConnected){
            this.getEmoticonBubble_().close();
        }
    }
};

/**
 * Map of hg category - twemoji category
 *
 * @type {Object}
 * @private
 */
EmoticonService.RemoteCategory = {
    'animation' : EmoticonCategory.STICKERS,
    'smileys-emotion' : EmoticonCategory.SMILEYS_AND_PEOPLE,
    'people-body' : EmoticonCategory.SMILEYS_AND_PEOPLE,
    'animals-nature' : EmoticonCategory.ANIMALS_AND_NATURE,
    'food-drink' : EmoticonCategory.FOOD_AND_DRINK,
    'travel-places' : EmoticonCategory.TRAVEL_AND_PLACES,
    'activities' : EmoticonCategory.ACTIVITY,
    'objects' : EmoticonCategory.OBJECTS,
    'symbols' : EmoticonCategory.SYMBOLS,
    'flags' : EmoticonCategory.FLAGS
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new EmoticonService();

export default instance;