import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgUIEventType} from "./../events/EventType.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const AlertMessageSeverity = {
    ERROR  : 'info_severity_error',

    WARNING: 'info_severity_warning',

    INFO   : 'info_severity_info'
};

/**
 * A message. Can contain an optional icon
 * @extends {Caption}
 * @unrestricted 
*/
export class AlertMessage extends Caption {
    /**
     * @param {!Object=} opt_config Optional object containing config parameters
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-info-message';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-info-message';
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getElement(), BrowserEventType.CLICK, this.handleClick_);
    }

    /**
     * Handles CLICK on the element of this component
     * @param {hf.events.Event} e
     * @private
     */
    handleClick_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            const event = new Event(HgUIEventType.CLICK_HYPERLINK);
            event.addProperty('hyperlinkTarget', target.getAttribute('target'));

            this.dispatchEvent(event);
        }
    }
};