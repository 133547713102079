import {
    FileDropHandler,
    FileDropHandlerEventType
} from "./../../../../../../hubfront/phpnoenc/js/events/FileDropHandler.js";
import {EditorCommandType, EditorPluginEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StyleUtils} from "./../../../../../../hubfront/phpnoenc/js/style/Style.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Box} from "./../../../../../../hubfront/phpnoenc/js/math/Box.js";
import {Coordinate} from "./../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {EditorFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/FieldBase.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {ElementResizeHandler} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {ElementResizeHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {HfSanitizeNewLineEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/SanitizeNewLine.js";
import {HfKeyboardShortcutsEditorPlugin} from "./../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/KeyboardShortcuts.js";
import {IThread} from "./../../../data/model/thread/IThread.js";
import {MessageEditorCommands, MessageEditorParts} from "./../editor/Enums.js";
import {TextEditor} from "./../editor/TextEditor.js";
import {HgPersonReferEditorPlugin} from "./../editor/plugin/PersonRefer.js";
import {HgBotReferEditorPlugin} from "./../editor/plugin/BotRefer.js";
import {HgTopicReferEditorPlugin} from "./../editor/plugin/TopicRefer.js";
import {HgMailtoEditorPlugin} from "./../editor/plugin/Mailto.js";
import {HgPhoneNumberEditorPlugin} from "./../editor/plugin/PhoneNumber.js";
import {HgTextFormatterEditorPlugin} from "./../editor/plugin/TextFormatter.js";
import {HgEmoticonEditorPlugin} from "./../editor/plugin/Emoticon.js";
import {HgGiphyEditorPlugin} from "./../editor/plugin/Giphy.js";
import {HgUnorderedListEditorPlugin} from "./../editor/plugin/UnorderedList.js";
import {HgTableEditorPlugin} from "./../editor/plugin/Table.js";
import {HgQuoteEditorPlugin} from "./../editor/plugin/Quote.js";
import {HgCodeEditorPlugin} from "./../editor/plugin/Code.js";
import {HgTextDirectionEditorPlugin} from "./../editor/plugin/TextDirection.js";
import {HgSanitizeEditorPlugin} from "./../editor/plugin/Sanitize.js";
import {HgHashtagReferEditorPlugin} from "./../editor/plugin/HashtagRefer.js";
import {HgFileEditorPlugin} from "./../editor/plugin/File.js";
import {HgLinkEditorPlugin} from "./../editor/plugin/Link.js";
import {HgIndentEditorPlugin} from "./../editor/plugin/Indent.js";
import {HgShortcutEditorPlugin} from "./../editor/plugin/Shortcut.js";
import {EmoticonBubbleEventType} from "./../EmoticonBubble.js";
import {GiphyBubbleEventType} from "./../GiphyBubble.js";
import {LinkPreviewer} from "./../LinkPreviewer.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";

import MetacontentService from "../../../data/service/MetacontentService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import { Editor as Editor2 } from "./Editor2.js";

/**
 * Constructor for the message Editor UI component. The message Editor component displays
 * a text field and controls for sending chat text, files, and links.
 * For files it also displays preview, just like the chat editor.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
class EditorLegacy extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.directFileRemoval If true, files will be removed on spot
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Holds the actual Text Editor component.
         * @type {hg.common.ui.editor.TextEditor}
         * @private
         */
        this.textEditor_;

        /**
         * Drop handler to catch file drop on the root element
         * @type {hf.events.FileDropHandler}
         * @private
         */
        this.fileDropHandler_;

        /**
         * Resize handler on preview area for setting editor max-height dynamically
         * @type {hf.events.ElementResizeHandler}
         * @private
         */
        this.previewElementResizeHandler_;

        /**
         * @type {hf.math.Box}
         * @private
         */
        this.visibleRect_;

        /**
         * Counter for dragover events
         * http://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
         * @type {number}
         * @private
         */
        this.dragOverCounter_ = this.dragOverCounter_ === undefined ? 0 : this.dragOverCounter_;

        /**
         * Container for link preview.
         * The container must be child of chat.Editor in order to be displayed as child of this component before the uploaded files/images.
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.linkPreviewContainer_ = this.linkPreviewContainer_ === undefined ? null : this.linkPreviewContainer_;

        /**
         * Preview container for links and files
         * @type {hf.ui.layout.VerticalStack}
         * @private
         */
        this.previewArea_ = this.previewArea_ === undefined ? null : this.previewArea_;
    }

    /**
     * @return {string}
     */
    getMessageDraft() {
        const editor = this.textEditor_;
        if(editor) {
            /* accept temporary changes before fetching draft message */
            editor.acceptTemporaryChanges();

            const rawContent = editor.getRawTextContent();
            if (!StringUtils.isEmptyOrWhitespace(rawContent)) {
                return StringUtils.htmlEscape(/** @type {string} */(rawContent));
            }
        }

        return '';
    }

    /**
     * Returns current editor content
     * @return {string|null}
     */
    getContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.getRawTextContent();
        }

        return '';
    }

    /**
     * Retrieve the text content of the field
     * @return {string|null} The scrubbed contents of the field.
     */
    getTextContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.getTextContent();
        }

        return '';
    }

    /**
     * Set the content of the editor
     * @param {?string} content Editor content. If html=null, then this defaults
     *    to a nsbp for mozilla and an empty string for IE.
     */
    setContent(content) {
        const editor = this.textEditor_;
        if (editor) {
            if (content != null) {
                /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                content = StringUtils.htmlEscape(content);

                content = StringUtils.newLineToBr(content);
            }

            editor.setContent(content);
        }
    }

    /**
     * Returns true is editor has content (considering unattached as well - link preview, files)
     * @return {boolean}
     */
    hasContent() {
        const editor = this.textEditor_;
        if (editor) {
            return editor.hasContent();
        }

        return false;
    }

    /**
     * Clear any content entered in the editor.
     * @param {boolean=} opt_dismiss
     */
    clearContent(opt_dismiss) {
        if (!this.isInDocument()) {
            return;
        }

        const editor = this.textEditor_;
        if (editor) {
            opt_dismiss ? editor.discardTemporaryChanges() : editor.acceptTemporaryChanges();

            editor.setContent('');
        }
    }

    /**
     * Set the placeholder for empty content
     * @param {string} placeholder
     */
    setPlaceholder(placeholder) {
        const editor = this.textEditor_;
        if(editor) {
            editor.setPlaceholder(placeholder);
        }
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    isBusy() {
        const editor = this.textEditor_;

        if (editor) {
            return editor.isBusy();
        }

        return false;
    }

    /**
     * Returns true is editor is busy processing content, false otherwise
     * @return {boolean}
     */
    hasErrors() {
        const editor = this.textEditor_;

        if (editor) {
            return editor.hasErrors();
        }

        return false;
    }

    /**
     * @param {string} command The command to execute.
     * @param {...*} var_args Any additional parameters needed to
     *     execute the command.
     * @return {*} The result of the execCommand, if any.
     */
    execCommand(command, var_args) {
        const editor = this.textEditor_;
        if (editor) {
            editor.focus();

            return editor.execCommand.apply(editor, arguments);
        }

        return;
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo +regular file + delta input)
     * @param {FileList} files
     */
    uploadFiles(files) {
        this.onFileUpload_(files);
    }

    /**
     * Return placeholder for file preview area
     * @return {hf.ui.UIComponent}
     */
    getPreviewArea() {
        return this.previewArea_;
    }

    /**
     * Append link preview as child of Editor component. LinkPreview is added as child of link preview container, initialized
     * as direct child of the current component
     * @param {hg.common.ui.LinkPreviewer} linkPreview The link preview component
     */
    addLinkPreviewChild(linkPreview) {
        if (linkPreview != null
            && this.linkPreviewContainer_ != null
            && linkPreview instanceof LinkPreviewer) {

            this.linkPreviewContainer_.addChild(linkPreview, true);
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['directFileRemoval'] = opt_config['directFileRemoval'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.previewArea_ = new VerticalStack({'extraCSSClass': MessageEditorParts.PREVIEW_AREA});
        this.linkPreviewContainer_ = new LayoutContainer({'extraCSSClass': MessageEditorParts.LINK_PREVIEW_CONTAINER});
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.textEditor_);
        this.textEditor_ = null;

        BaseUtils.dispose(this.fileDropHandler_);
        this.fileDropHandler_ = null;

        BaseUtils.dispose(this.linkPreviewContainer_);
        this.linkPreviewContainer_ = null;

        BaseUtils.dispose(this.previewArea_);
        this.previewArea_ = null;
        
        BaseUtils.dispose(this.previewElementResizeHandler_);
        this.previewElementResizeHandler_ = null;

        BaseUtils.dispose(this.visibleRect_);
        this.visibleRect_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Editor.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Editor.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const textEditor = this.getTextEditor(),
            previewArea = this.getPreviewArea();

        this.addChild(textEditor, true);
        this.addChild(previewArea, true);
        previewArea.addChild(this.linkPreviewContainer_, true);

        this.fileDropHandler_ = new FileDropHandler(textEditor.getElement(), true);

        /* set editor element resize handler */
        this.previewElementResizeHandler_ = new ElementResizeHandler(previewArea.getElement());
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const rootElement = this.getElement();

        /* enable ElementResizeHandler for editor field */
        this.previewElementResizeHandler_.enable(true);

        this.getHandler()
            /* Add editor events */
            .listen(this.textEditor_, EditorFieldEventType.FILE_PASTE, this.handleFilePaste_)
            .listen(this.textEditor_, EditorPluginEventType.BUSY_CHANGE, this.handleBusyChange_)
            .listen(this.textEditor_, EditorFieldEventType.DELAYEDCHANGE, this.handleDelayedChange_)

            .listen(this.fileDropHandler_, FileDropHandlerEventType.DROP, this.handleFileDrop_)
            .listen(rootElement, BrowserEventType.DRAGSTART, this.handleDragStart_)
            .listen(rootElement, BrowserEventType.DRAGENTER, this.handleDragFileEnter_)
            .listen(rootElement, BrowserEventType.DRAGLEAVE, this.handleDragFileLeave_)
            .listen(rootElement, BrowserEventType.DROP, function (e) {
                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            })

            .listen(this.previewElementResizeHandler_, ElementResizeHandlerEventType.RESIZE, this.handlePreviewContainerResize_)
            .listen(window, BrowserEventType.RESIZE, this.handleWindowResize_)

            .listen(this, EmoticonBubbleEventType.EMOTICON_PICK, this.handleEmoticonSelect_)
            .listen(this, GiphyBubbleEventType.GIF_PICK, this.handleGifSelect_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        this.updateTextEditorPlugins();
    }

    /** @inheritDoc */
    focus() {
        super.focus();

        if (!this.isInDocument()) {
            return;
        }

        const editor = this.textEditor_;
        if (editor) {
            editor.focusAndPlaceCursorAtEnd();
        }
    }

    /** @inheritDoc */
    blur() {
        super.blur();

        if (!this.isInDocument()) {
            return;
        }

        const editor = this.textEditor_;
        if (editor) {
            editor.blur();
        }
    }

    /** @inheritDoc */
    isFocused() {
        if (!this.isInDocument()) {
            return super.isFocused();
        }

        const editor = this.textEditor_;

        return editor != null && editor.isFocused();
    }

    /** @inheritDoc */
    setEnabled(enabled, opt_force) {
        if (!this.isParentDisabled() && this.isTransitionAllowed(UIComponentStates.DISABLED, !enabled)) {
            const translator = Translator;

            if (!enabled) {
                this.setPlaceholder(translator.translate('editor_read_only'));

                /* Disable children first, this is because controls can't be disabled
                 if their parent is already disabled. */

                if (this.textEditor_ != null) {
                    this.textEditor_.setEnabled(enabled);
                }            

                super.setEnabled(enabled, opt_force);
            }
            else {
                /* Enable the FullEditor first, then update the children.
                 This is because controls can't be enabled if their parent is disabled. */
                super.setEnabled(enabled, opt_force);

                this.setPlaceholder(translator.translate(this.getConfigOptions()['placeholder'] || 'type_your_message'));
            }
        }
    }

    /**
     * Returns the Text Editor instance if exists. If not, it creates a new Text Editor
     * instance and returns it.
     *
     * @return {hg.common.ui.editor.TextEditor}
     * @protected
     */
    getTextEditor() {
        if (this.textEditor_ == null) {
            this.textEditor_ = new TextEditor({
                'extraCSSClass'     : MessageEditorParts.INPUT,
                'placeholder'       : this.getConfigOptions()['placeholder'] || '',
                'showLimitWarning'  : true,
                'maxRawLength'      : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN
            }, true);

            /* register service to delegate event processing */
            const service = MetacontentService.getInstance();
            if (service != null) {
                this.textEditor_.registerService(service);
            }        
        }

        return this.textEditor_;
    }

    /**
     *
     * @protected
     */
    updateTextEditorPlugins() {
        const editor = this.getTextEditor();

        if(editor) {
            /* Unregister all the plugins */
            editor.unregisterAllPlugins();

            /* Register plugins dependent on the Threaded Resource */
            const resourceLinkedPlugins = this.getResourceLinkedTextEditorPlugins();
            resourceLinkedPlugins.forEach(function (plugin) {
                editor.registerPlugin(plugin);
            }, this);

            /* Register default plugins */
            const defaultPlugins = this.getDefaultTextEditorPlugins();
            defaultPlugins.forEach(function (plugin) {
                editor.registerPlugin(plugin);
            }, this);
        }
    }

    /**
     * Compute and fetch list of default editor plugins
     * @param {boolean=} opt_enableEncodeOnEnter
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    getDefaultTextEditorPlugins(opt_enableEncodeOnEnter) {
        const defaultPlugins = [
            new HgShortcutEditorPlugin(),
            new HgSanitizeEditorPlugin(),
            /* TextDirection plugin should be always registered before the Code plugin */
            new HgTextDirectionEditorPlugin(),
            new HgCodeEditorPlugin(),
            /* Emoticon plugin should be registered after Code to prevent showing suggestion bubble inside code*/
            new HgEmoticonEditorPlugin(opt_enableEncodeOnEnter),
            /* Indent plugin should be registered after Emoticon so that if the trigger character for
             indent is part of an Emoticon, to be transformed */
            new HgIndentEditorPlugin(),
            new HgTableEditorPlugin(),
            new HgPersonReferEditorPlugin(),
            new HgBotReferEditorPlugin(),
            new HgTopicReferEditorPlugin(),
            new HgMailtoEditorPlugin(opt_enableEncodeOnEnter),
            new HgPhoneNumberEditorPlugin(opt_enableEncodeOnEnter),
            /* Unordered list should be registered after reference plugins to add a new bullet on enter after in reference
             plugins, the cursor is taken outside the reference */
            new HgUnorderedListEditorPlugin(opt_enableEncodeOnEnter),

            /* Giphy should be registered after Code so that it will not be decoded inside code tags. */
            new HgGiphyEditorPlugin(opt_enableEncodeOnEnter),
            /*  processes bold, italic markup.
            TextFormatter is always registered after UnorderedList */
            new HgTextFormatterEditorPlugin(),
            /* Sanitize the new lines.
             * It must be be registered after all plugins that encode message content */
            new HfSanitizeNewLineEditorPlugin(),
            new HgQuoteEditorPlugin()
        ];

        if (userAgent.browser.isFirefox()){
            defaultPlugins.push(new HfKeyboardShortcutsEditorPlugin());
        }

        return defaultPlugins;
    }

    /**
     * Compute and fetch list of resource linked editor plugins
     * @param {boolean=} opt_enableEncodeOnEnter
     * @return {Array.<hf.ui.editor.AbstractEditorPlugin>}
     * @protected
     */
    getResourceLinkedTextEditorPlugins(opt_enableEncodeOnEnter) {
        const model = this.getModel();

        if(model != null) {
            let inThread = null, context = null;

            if(IThread.isImplementedBy(/**@type {Object}*/(model))) {
                /* inThread */
                if(model.hasOwnProperty('inThread')
                    && model['inThread'] != null
                    && model['inThread']['resourceId'] != null
                    && model['inThread']['resourceType'] != null) {
                    inThread = {
                        'resourceId': model['inThread']['resourceId'],
                        'resourceType': model['inThread']['resourceType']
                    }
                }
                else {
                    inThread = {
                        'resourceId': model['resourceId'],
                        'resourceType': model['resourceType']
                    }
                }

                /* context */
                if(model.hasOwnProperty('reference')
                    && model['reference'] != null
                    && model['reference']['resourceId'] != null
                    && model['reference']['resourceType'] != null
                    && model['reference']['resourceId'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['reference']['resourceId'],
                        'resourceType': model['reference']['resourceType']
                    };
                }
                else if(model.hasOwnProperty('replyTo')
                    && model['replyTo'] != null
                    && model['replyTo'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['replyTo'],
                        'resourceType': HgResourceCanonicalNames.MESSAGE
                    };
                }
            }
            else if(IThread.isImplementedBy(/**@type {Object}*/(model['thread']))) {
                /* inThread */
                if(model.hasOwnProperty('inThread')
                    && model['inThread'] != null
                    && model['inThread']['resourceId'] != null
                    && model['inThread']['resourceType'] != null) {
                    inThread = {
                        'resourceId': model['inThread']['resourceId'],
                        'resourceType': model['inThread']['resourceType']
                    }
                }
                else {
                    inThread = {
                        'resourceId': model['thread']['resourceId'],
                        'resourceType': model['thread']['resourceType']
                    }
                }

                /* context */
                if(model['thread']['resourceId'] !== inThread['resourceId']) {
                    context = {
                        'resourceId': model['thread']['resourceId'],
                        'resourceType': model['thread']['resourceType']
                    }
                }
            }

            if(inThread != null) {
                return [
                    new HgFileEditorPlugin(inThread, this.getPreviewArea(), !this.getConfigOptions()['directFileRemoval']),
                    new HgLinkEditorPlugin(context || inThread, opt_enableEncodeOnEnter),
                    new HgHashtagReferEditorPlugin(context || inThread)
                ];
            }
        }

        return [];
    }

    /**
     * Adjust max-height of editor input when preview size changes or viewport (window size) changes
     * @private
     */
    adjustMaxHeigh_() {
        /* fetch max free space remaining for editor */
        const parent = this.getParent();
        if (parent && BaseUtils.isFunction(parent.getEditorFreeSpace)) {
            const maxFreeSpace = parent.getEditorFreeSpace(),
                textEditor = this.textEditor_,
                previewArea = this.linkPreviewContainer_.getParent() || this.linkPreviewContainer_,
                previewContainerHeight_ = parseInt(previewArea.getHeight(true), 10);

            if(textEditor) {
                /* seamless: compute max-height on editor input (without previews, required on each preview change!) */
                const maxEditorHeight = maxFreeSpace - previewContainerHeight_;

                textEditor.setMaxHeight(Math.max(maxEditorHeight, 36));

                /* adjust scrollbars */
                textEditor.onResize();
            }
        }
    }

    /**
     * Event handler for when a smiley is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleEmoticonSelect_(e) {
        const code = e.getProperty('emoticon');

        if (code) {
            /* Add spaces to avoid sticking the smiley to the rest of the text */
            this.execCommand(EditorCommandType.EMOJI, code, true);
        }

        return;
    }

    /**
     * Event handler for when a gif is selected.
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleGifSelect_(e) {
        const url = e.getProperty('gifUrl'),
            ratio = e.getProperty('gifRatio'),
            frames = e.getProperty('gifFrames');

        if (url) {
            /* Add spaces to avoid sticking the smiley to the rest of the text */
            this.execCommand(MessageEditorCommands.GIPHY, url, HgMetacontentUtils.GifSize.SMALL, ratio, frames, true);
        }

        return;
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleDragStart_(e) {
        const target = e.getTarget();
        if (target.tagName == 'IMG') {
            e.preventDefault();
            return false;
        }
    }

    /**
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleDragFileEnter_(e) {
        /* needed for IE */
        e.preventDefault();

        /* HG-6608: making sure drag leave is correctly determined on FF */
        const coordonates = new Coordinate(this.getElement().getBoundingClientRect().x, this.getElement().getBoundingClientRect().y),
            elementSize = StyleUtils.getSize(this.getElement());

        this.visibleRect_ = new Box(coordonates.y, coordonates.x+elementSize.width, coordonates.y+elementSize.height, coordonates.x);


        this.dragOverCounter_++;
        this.addExtraCSSClass('hg-dropzone-hover');
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleDragFileLeave_(e) {
        this.dragOverCounter_--;
        if (this.dragOverCounter_ == 0) {
            this.removeExtraCSSClass('hg-dropzone-hover');
        } else {
            /* HG-6608: making sure drag leave is correctly determined on FF */
            const mousePosition = UIUtils.getMousePosition(e);

            if (this.visibleRect_ != null
                && (mousePosition.x < this.visibleRect_.left || mousePosition.x > this.visibleRect_.right
                || mousePosition.y < this.visibleRect_.top || mousePosition.y > this.visibleRect_.bottom)) {

                this.dragOverCounter_ = 0;
                this.removeExtraCSSClass('hg-dropzone-hover');
            }
        }
    }

    /**
     * Handles file drop
     * @param {hf.events.BrowserEvent} e
     * @private
     */
    handleFileDrop_(e) {
        const browserEvent = e.getBrowserEvent();

        this.dragOverCounter_ = 0;
        this.removeExtraCSSClass('hg-dropzone-hover');

        if (browserEvent.dataTransfer != null) {
            const files = /** @type {DataTransfer} */(browserEvent.dataTransfer).files;

            this.focus();

            this.uploadFiles(files);
        }
    }

    /**
     * Handles files upload, both dropped or chosen from browser window
     * For regular file uploads adjust editor height:
     *  - store current height to be restored on message submit
     *  - set editor height not under (toolbar + photo +regular file + delta input)
     * @param {FileList|Array} files
     * @private
     */
    onFileUpload_(files) {
        this.execCommand(MessageEditorCommands.FILE, files);
    }

    /**
     * Handles text editor BUSY_CHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (bu default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleBusyChange_(e) {
        this.dispatchEvent(EditorPluginEventType.BUSY_CHANGE);
    }

    /**
     * Handles text editor DELAYEDCHANGE event: dispatch this event as if it is own
     * Todo: this is a temporary hack in order to set binding with Editor.isBusy (bu default the binding is not
     * triggered if event is bubbled, not trigger by source itself)
     * @param {hf.events.Event} e
     * @private
     */
    handleDelayedChange_(e) {
        this.dispatchEvent(EditorFieldEventType.DELAYEDCHANGE);
    }

    /**
     * Handles file upload triggered by the input type=file
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePaste_(e) {
        const files = e.getProperty('files');

        if (files != null && BaseUtils.isArrayLike(files)) {
            this.onFileUpload_(/** @type {Array} */(files));
        }
    }

    /**
     * Handles the RESIZE event dispatched by ElementResizeHandler when preview container changes size
     * Adjust max-height of the input and adjust scrollbars
     * @private
     */
    handlePreviewContainerResize_() {
        this.adjustMaxHeigh_();
    }

    /**
     * Handles the window RESIZE event. Will adjust editor max-height *
     * @param {hf.events.Event} e The event object.
     * @return {void}
     * @private
     */
    handleWindowResize_(e) {
        this.adjustMaxHeigh_();
    }
};

/**
 * Constructor for the message Editor UI component. The message Editor component displays
 * a text field and controls for sending chat text, files, and links.
 * For files it also displays preview, just like the chat editor.
 *
 * @extends {UIComponent}
 * @unrestricted
 */
export class Editor {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     *   @param {string=} opt_config.placeholder Placeholder in display mode when no content is set
     *   @param {boolean=} opt_config.directFileRemoval If true, files will be removed on spot
     *
     */
    constructor(opt_config = {}, opt_forceLegacy = false) {
        let editor;
        if (HgAppConfig.LEGACY_EDITOR || opt_forceLegacy) {
            editor = new EditorLegacy(opt_config);
            editor.isLegacy = true;
        } else {
            editor = new Editor2(opt_config);
            editor.isLegacy = false;
        }

        return editor;
    }
};

/**
 * The prefix we use for the CSS class names for this component and its elements.
 * @type {string}
 */
Editor.CSS_CLASS_PREFIX = 'hg-message-editor';
/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 */
Editor.CssClasses = {
    BASE: Editor.CSS_CLASS_PREFIX,

    TEXT_INPUT: Editor.CSS_CLASS_PREFIX + '-' + 'input'
};