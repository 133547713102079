import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControlContent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {ViewCallHistoryLeg} from "./../component/form/ViewCallHistoryLeg.js";
import {PhoneCallResourceEventTypes} from "./../component/AbstractResource.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class ChUiViewDialogView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The form that display a callHistory leg
         * @type {hg.ch.ui.form.ViewCallHistoryLeg}
         * @protected
         */
        this.form;

        /**
         * Footer with meta information for dialogs
         * @type {UIControlContent}
         * @protected
         */
        this.footer;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.form = new ViewCallHistoryLeg({'name': 'call-history-view'});
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, PhoneCallResourceEventTypes.REMOVE, this.handleRemoveResource_)
            .listen(this, HgUIEventType.CALL_PARTY, this.handleCallParty_);

    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.form);
        this.form = null;

        BaseUtils.dispose(this.footer);
        this.footer = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass('hg-ch-view-dialog');

        return dialog;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        this.form.setModel(model);
        return this.form;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        /* lazy create on first use */
        if (this.footer == null) {
            this.footer = this.createDialogFooterInternal(model, footerControl);
        }

        return this.footer;
    }

    /**
     * Lazy create on first use, storing footer to not be recreated on model change
     * @param {*} model The dialog model
     * @param {hf.ui.UIControl} footerControl Footer control component
     * @returns {hf.ui.UIComponent}
     * @protected
     */
    createDialogFooterInternal(model, footerControl) {
        const container = new UIComponent({
            'baseCSSClass': 'hg-ch-view-dialog-footer'
        });

        return container;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            buttonSet = new DialogButtonSet();

        buttonSet.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CLOSE
        }));

        return buttonSet;
    }

    /** @inheritDoc */
    createBusyIndicator() {
        const busyIndicator = super.createBusyIndicator();
        busyIndicator.addExtraCSSClass('hg-ch-view-busy-indicator');

        return busyIndicator;
    }

    /** @inheritDoc */
    createErrorContainer(contextErr) {
        const errContainer = super.createErrorContainer(contextErr);

        if(contextErr && contextErr['context'] == CommonBusyContexts.LOAD) {
            errContainer.addExtraCSSClass('hg-ch-view-error-container');
        }

        return errContainer;
    }

    /**
     * Handles the REMOVE event of a call resource. Call the presenter to remove the resource
     * @param {hf.events.Event} e The action event
     * @private
     */
    handleRemoveResource_(e) {
        const phoneHistoryMediaId = e.getProperty('phoneHistoryMediaId');

        if (!StringUtils.isEmptyOrWhitespace(phoneHistoryMediaId)) {
            const promisedResult = this.getPresenter().removeCallMediaResource(phoneHistoryMediaId);

            e.addProperty('promisedResult', promisedResult);
        }
    }

    /**
     * Handles QUICK_CALL_CALLPARTY event in order to quick call a caller from callHistory module
     * Call present method in order to dispatch CALL_PERSON app event
     * @param {hf.events.Event=} e The event
     * @private
     */
    handleCallParty_(e) {
        this.getPresenter().quickCallParty(e.getProperty('callInfo'));
    }
};