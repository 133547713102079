import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Bubble} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Bubble.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {MediaPreviewButtonType} from "./../Enums.js";
import {FileInfoPanelContent} from "./FileInfoPanelContent.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {DownloadButton} from "./../../../../common/ui/button/DownloadButton.js";
import {
    DelayedActionButton,
    DelayedActionButtonActionType,
    DelayedActionButtonEventType
} from "./../../../../common/ui/button/DelayedActionButton.js";
import {ShareButton, ShareButtonEventType} from "./../../../../common/ui/share/ShareButton.js";
import {HgUIEventType} from "./../../../../common/ui/events/EventType.js";
import {PopupBounceIn} from "./../../../../common/ui/fx/PopupBounceIn.js";

import {UploadFileButtonEventType} from "./../../../../common/ui/button/UploadFileButton.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {HgFileUtils} from ".././../../../data/model/file/Common.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ResponsiveMediaToolbar extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.popup.Bubble}
         * @private
         */
        this.menuBubble_ = this.menuBubble_ === undefined ? null : this.menuBubble_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.menu_ = this.menu_ === undefined ? null : this.menu_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.menuTriggerButton_ = this.menuTriggerButton_ === undefined ? null : this.menuTriggerButton_;

        /**
         * @type {hg.common.ui.share.ShareButton}
         * @private
         */
        this.shareButton_ = this.shareButton_ === undefined ? null : this.shareButton_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.infoPopup_ = this.infoPopup_ === undefined ? null : this.infoPopup_;

        /**
         * @type {hg.common.ui.button.UploadFileButton}
         * @private
         */
        this.uploadButton_ = this.uploadButton_ === undefined ? null : this.uploadButton_;
    }

    /**
     * Returns a button by a provided name.
     *
     * @param {string} name The button name.
     * @return {hf.ui.Button}
     */
    getButtonByName(name) {
        return this.menu_ != null ? this.menu_.getButtonByName(name) : null;
    }

    /**
     * Change button layout
     * @param {hg.HgButtonUtils.DisplayLayout} layout
     */
    setLayout(layout) {
        if (!(Object.values(HgButtonUtils.DisplayLayout).includes(layout))) {
            throw new TypeError('The resource action buttons layout options are: ' + Object.values(HgButtonUtils.DisplayLayout) + '.');
        }

        const cfg = this.getConfigOptions();

        if (cfg['layout'] != layout) {
            cfg['layout'] = layout;
            if(this.isInDocument()) {
                this.adjustLayout();
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['layout'] =  opt_config['layout'] || HgButtonUtils.DisplayLayout.BUBBLE;

        super.init(opt_config);

        this.setSupportedState(UIComponentStates.OPENED, true);
        this.setDispatchTransitionEvents(UIComponentStates.OPENED, true);
        this.setAutoStates(UIComponentStates.OPENED, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.menu_);
        this.menu_ = null;

        BaseUtils.dispose(this.menuBubble_);
        this.menuBubble_ = null;

        BaseUtils.dispose(this.menuTriggerButton_);
        this.menuTriggerButton_ = null;

        if (this.shareButton_ != null) {
            BaseUtils.dispose(this.shareButton_);
            this.shareButton_ = null;
        }

        if (this.infoPopup_ != null) {
            BaseUtils.dispose(this.infoPopup_);
            this.infoPopup_ = null;
        }

        if (this.uploadButton_ != null) {
            BaseUtils.dispose(this.uploadButton_);
            this.uploadButton_ = null;
        }
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ResponsiveMediaToolbar.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return ResponsiveMediaToolbar.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.uploadButton_ = HgButtonUtils.createUploadFileButton({
            'hideMode' : 'display',
            'hidden' : true
        });

        this.addChild(this.uploadButton_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.adjustLayout();

        this.getHandler()
            .listen(this.menu_, UIComponentEventTypes.ACTION, this.handleMenuAction_)
            .listen(this.menu_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction_)
            .listen(this.uploadButton_, UploadFileButtonEventType.FILE_UPLOAD, this.handleFileUpload_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.hasDeleteError(false);

        this.setOpen(false);

        this.disposeMenuBubble();

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateMenuItems}, {
            'sources': [
                {'sourceProperty': 'context'},
                {'sourceProperty': 'mediaFile'},
                {'sourceProperty': 'mediaFile.access'}
            ],
        });
        this.setBinding(this, {'set': this.onFileVersionChange}, 'currentMediaFileVersion');
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        if(userAgent.device.isDesktop()
            && this.isHighlighted()
            && this.getConfigOptions()['layout'] == HgButtonUtils.DisplayLayout.BUBBLE) {
            this.openMenuBubble();
        }
    }

    /**
     * @param {MediaPreviewButtonType} buttonName
     * @param {string} label
     * @return {!Object}
     * @protected
     */
    getToolbarButtonConfig(buttonName, label) {
        return {
            'extraCSSClass' : ['hg-button-bubble', ResponsiveMediaToolbar.CSS_CLASS_PREFIX + '-' + 'button', buttonName],
            'name'          : buttonName,
            'tooltip'       : userAgent.device.isDesktop() ? this.getToolbarButtonTooltipConfig_(label) : null
        };
    }

    /**
     * @param {string} label
     * @return {!Object}
     * @private
     */
    getToolbarButtonTooltipConfig_(label) {
        return  {
                'autoHide'          : false,
                'showArrow'         : false,
                'showDelay'         : 200,
                'content'           : label,
                'extraCSSClass'     : ['hg-button-bubble-tooltip'],
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -10
        };
    }

    /**
     * @param {boolean=} open
     * @private
     */
    setPopupOpen_(open) {
        const className = 'panel-open';

        open ? this.addExtraCSSClass(className) : this.removeExtraCSSClass(className);
    }

    /**
     *
     * @private
     */
    updateOpenState_() {
        this.setOpen(!!(this.menuBubble_ && this.menuBubble_.isOpen()) ||
            !!(this.shareButton_ && this.shareButton_.isOpen()) ||
            !!(this.infoPopup_ && this.infoPopup_.isOpen()));
    }

    /**
     * @return {Bubble}
     * @protected
     */
    getMenuBubble() {
        if(this.menuBubble_ == null) {
            this.menuBubble_ = new Bubble({
                'extraCSSClass' : [ResponsiveMediaToolbar.CssClasses.MENU_BUBBLE],
                'showArrow'     : false,
                'showDelay'     : 150,
                'placement'     : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -10,
                'openAnimation' : {
                    'type': PopupBounceIn
                }
            });

            this.menuBubble_.setParentEventTarget(this);

            this.menuBubble_.addListener(UIComponentEventTypes.OPEN, this.handleMenuBubbleOpen_, false, this);
            this.menuBubble_.addListener(UIComponentEventTypes.CLOSE, this.handleMenuBubbleClose_, false, this);
        }

        return this.menuBubble_;
    }

    /**
     *
     * @protected
     */
    openMenuBubble() {
        const menuBubble = this.getMenuBubble();
        if(menuBubble) {
            menuBubble.setPlacementTarget(this.menuTriggerButton_);

            if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                menuBubble.open();
            }
        }
    }

    /**
     * @protected
     */
    disposeMenuBubble() {
        if(this.menuBubble_ != null) {
            this.menuBubble_.setContent(null);
            this.menuBubble_.setPlacementTarget(null);
            BaseUtils.dispose(this.menuBubble_);
            this.menuBubble_ = null;
        }
    }

    /**
     * @return {hf.ui.ButtonSet}
     * @protected
     */
    getMenu() {
        if(!this.menu_) {
            /* Create Menu */
            this.menu_ = new ButtonSet();
        }

        return this.menu_;
    }

    /**
     * @protected
     */
    updateMenuItems() {
        const menu = this.getMenu();
        if (menu) {
            menu.removeAll();

            const model = this.getModel();
            const mediaFile = model ? model['mediaFile'] : null;

            if(mediaFile && model['context']) {
                const translator = Translator;

                // Info button - add it always
                const infoCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.INFO, translator.translate('Info'));
                menu.addButton(new Button(infoCfg));

                // Share button - add it if the operation is allowed at the global level
                if (HgResourceUtils.canShareResource()) {
                    const shareCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.SHARE, translator.translate('Share'));
                    menu.addButton(new Button(shareCfg));
                }

                // Download button
                const downloadCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.DOWNLOAD, translator.translate('download'));
                const downloadBtn = menu.addButton(new DownloadButton(downloadCfg));
                this.setBinding(downloadBtn, {'set': downloadBtn.setModel}, 'mediaPreview');

                // Upload button - add it the user has the access to update the file
               if(mediaFile['access']['canUpdate']) {
                    const uploadCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.UPLOAD, translator.translate('upload'));
                    menu.addButton(new Button(uploadCfg));
               }

                // Delete button - add it if the operation is allowed at the global level and the user has the access to delete the file
               if (HgResourceUtils.canDeleteResource() && mediaFile['access']['canDelete']) {
                    menu.addButton(new DelayedActionButton({
                        'name': MediaPreviewButtonType.DELETE,
                        'actionType': DelayedActionButtonActionType.DELETE,
                        'extraCSSClass': [DelayedActionButtonActionType.DELETE, 'hg-button-bubble'],
                        'tooltip': userAgent.device.isDesktop() ? this.getToolbarButtonTooltipConfig_(translator.translate('message_action_delete')) : null
                    }));
               }
            }

            this.adjustLayout();
        }
    }

    /** @protected */
    adjustLayout() {
        if(!this.isInDocument()) {
            return;
        }

        const cfg = this.getConfigOptions();

        if (cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
            this.swapExtraCSSClass(ResponsiveMediaToolbar.CssClasses.BUBBLE_LAYOUT, ResponsiveMediaToolbar.CssClasses.INLINE_LAYOUT);

            this.disposeMenuBubble();

            if (this.menuTriggerButton_ != null && this.indexOfChild(this.menuTriggerButton_) != -1) {
                this.removeChild(this.menuTriggerButton_, true);
                /* device is mobile or tablet */
                if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                    this.getHandler()
                        .unlisten(this.menuTriggerButton_.getElement(), [BrowserEventType.TOUCHSTART], this.handleMenuButtonTouchStart_);
                }
            }

            const menu = this.getMenu(),
                parent = menu.getParent();

            const downloadBtn = menu.getButtonByName(MediaPreviewButtonType.DOWNLOAD);
            if(downloadBtn) {
                downloadBtn.setFeedbackCustomPlacementTarget();
            }

            if (parent && parent != this) {
                parent.removeChild(menu, true);
            }

            if (this.indexOfChild(menu) == -1) {
                this.addChild(menu, true);
            }
        }
        else {
            this.swapExtraCSSClass(ResponsiveMediaToolbar.CssClasses.INLINE_LAYOUT, ResponsiveMediaToolbar.CssClasses.BUBBLE_LAYOUT);

            if (this.menu_ && this.indexOfChild(this.menu_) != -1) {
                this.removeChild(this.menu_, true);
            }

            if (this.menuTriggerButton_ == null) {
                this.menuTriggerButton_ = new Button({
                    'extraCSSClass' : ['hg-media-preview-button', ResponsiveMediaToolbar.CssClasses.MENU_BUTTON]
                });
            }
            if (this.indexOfChild(this.menuTriggerButton_) == -1) {
                this.addChild(this.menuTriggerButton_, true);
                /* device is mobile or tablet */
                if (userAgent.device.isTablet() || userAgent.device.isMobile()) {
                    this.getHandler()
                        .listen(this.menuTriggerButton_.getElement(), [BrowserEventType.TOUCHSTART], this.handleMenuButtonTouchStart_);
                }
            }

            const downloadBtn = this.getMenu().getButtonByName(MediaPreviewButtonType.DOWNLOAD);
            if(downloadBtn) {
                downloadBtn.setFeedbackCustomPlacementTarget(this.menuTriggerButton_);
            }
        }
    }

    /**
     * @param {boolean=} hasError
     */
    hasDeleteError(hasError) {
        if (this.menu_ != null && !this.menu_.isDisposed()) {
            const deleteBtn = this.menu_.getButtonByName(MediaPreviewButtonType.DELETE);
            if (deleteBtn) {
                hasError ? deleteBtn.addExtraCSSClass('retry') : deleteBtn.removeExtraCSSClass('retry');
            }
        }
    }

    /**
     * Get the share panel
     * @returns { hg.common.ui.share.ShareButton}
     * @protected
     */
    getShareButton() {
        if (!this.shareButton_ && HgResourceUtils.canShareResource()) {
            const translator = Translator,
                shareCfg = this.getToolbarButtonConfig(MediaPreviewButtonType.SHARE, translator.translate('Share'));
            shareCfg['popup'] = {
                'horizontalOffset' : -10
            };

            this.shareButton_ = new ShareButton(shareCfg);

            this.setBinding(this.shareButton_, {'set': this.shareButton_.setModel}, 'share');

            this.shareButton_.addListener(UIComponentEventTypes.OPEN, this.updateOpenState_, false, this);
            this.shareButton_.addListener(UIComponentEventTypes.CLOSE, this.updateOpenState_, false, this);
            this.shareButton_.addListener(ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_, false, this);
        }

        return this.shareButton_;
    }

    /**
     *
     * @private
     */
    openShareButton_() {
        const shareBtn = this.getShareButton();
        if(shareBtn) {
            if(!shareBtn.getElement()) {
                shareBtn.createDom();
            }

            if(!shareBtn.isInDocument()) {
                shareBtn.enterDocument();
            }

            shareBtn.open();
        }
    }

    /**
     * Get the info popup
     * @returns {hf.ui.popup.Popup}
     * @protected
     */
    getInfoPopup() {
        if (!this.infoPopup_) {
            const content = new FileInfoPanelContent();

            this.infoPopup_ = new Popup({
                'content'           : content,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'extraCSSClass'     : ['hg-popup', 'grayscheme', 'hg-file-info-popup'],
                'verticalOffset'    : -10,
                'showArrow'         : true,
                'staysOpen'         : false
            });

            this.setBinding(content, {'set': content.setModel}, 'currentMediaFileVersion');

            content.addListener(HgUIEventType.PANEL_CLOSE, this.handleInfoPanelClose_, false, this);

            this.infoPopup_.addListener(UIComponentEventTypes.OPEN, this.updateOpenState_, false, this);
            this.infoPopup_.addListener(UIComponentEventTypes.CLOSE, this.updateOpenState_, false, this);
        }

        const cfg = this.getConfigOptions();
        this.infoPopup_.setPlacementTarget(cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE ?
            this.menu_.getButtonByName(MediaPreviewButtonType.INFO) : this.menuTriggerButton_);

        return this.infoPopup_;
    }

    /**
     *
     * @private
     */
    openInfoPopup_() {
        const infoPopup = this.getInfoPopup();
        if(infoPopup) {
            infoPopup.open();
        }
    }

    /**
     * Instructs the View to display or hide the 'Busy' state.
     *
     * @param {boolean} isBusy Whether to mark the View as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        const downloadBtn = this.menu_ ? this.menu_.getButtonByName(MediaPreviewButtonType.DOWNLOAD) : null,
            cfg = this.getConfigOptions();

        switch (opt_busyContext) {
            case ResponsiveMediaToolbar.BusyContext.DOWNLOADING:
            default:
                if (this.isInDocument() && downloadBtn != null && cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                    downloadBtn.setBusy(enable);
                }
                break;

            case ResponsiveMediaToolbar.BusyContext.DOWNLOAD_FAILED:
                if (this.isInDocument() && downloadBtn != null && cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE) {
                    downloadBtn.setBusy(enable);
                    downloadBtn.addExtraCSSClass('retry');
                }
                break;
        }
    }

    /**
     *
     * @private
     */
    toggleShareResourceButton_() {
        const shareBtn = this.getShareButton();

        if (shareBtn && shareBtn.isOpen()) {
            shareBtn.close();
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleDelayedAction_(e) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel} */(this.getModel()),
            action = e.getProperty('actionType');

        if (model && action === DelayedActionButtonActionType.DELETE) {
            const event = new Event(HgUIEventType.FILE_REMOVE);
                event.addProperty('file', model['mediaFile']);
                event.addProperty('fileContent', model['currentMediaFileVersion']);

            if (this.dispatchEvent(event)) {
                const outcome = /** @type {Promise} */(event.getProperty('result'));
                if (outcome) {
                    outcome.catch(() => { return this.hasDeleteError(true); });
                    outcome.then(() => { return this.hasDeleteError(false); });
                }
            }
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleFileUpload_(e) {
        const model = /** @type {hg.module.global.media.viewmodel.MediaFileThreadViewmodel} */(this.getModel()),
            files = e.getProperty('files');

        if (files && model) {
            const event = new Event(HgUIEventType.FILE_UPLOAD);

            event.addProperty('fileVersion', files[0]);
            event.addProperty('fileId', model['mediaPreview']['id']);
            event.addProperty('context', model['context']);
            event.addProperty('blockId', HgFileUtils.generateBlockId());
            this.dispatchEvent(event);
        }
    }

    /**
     * Handles the close event for the info and version popupButtons
     * @param {hf.events.Event} e
     * @private
     */
    handleInfoPanelClose_(e) {
        const infoPopup = this.getInfoPopup();

        if (infoPopup != null) {
            infoPopup.close();
        }
    }

    /**
     * Handle file version displayed, update FileShare information
     * @param {hg.data.model.file.FileVersion} currentMediaFileVersion
     * @protected
     */
    onFileVersionChange(currentMediaFileVersion) {
        const shareBtn = this.getShareButton();
        if (shareBtn) {
            const fileShare = /** @type {hg.common.ui.viewmodel.FileShareViewmodel} */(shareBtn.getModel());
            if (fileShare) {
                fileShare['versionId'] = currentMediaFileVersion ? currentMediaFileVersion['versionId'] : null;
            }
        }

        if(this.menuBubble_ != null && this.menuBubble_.isOpen()) {
            this.menuBubble_.setOpen(false);
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleMenuButtonTouchStart_(e) {
        e.stopPropagation();

        this.openMenuBubble();

        return false;
    }

    /**
     * @param {Event} e
     * @private
     */
    handleMenuAction_(e) {
        const target = e.getTarget();

        if(target instanceof Button) {
            const buttonName = /**@type {hf.ui.Button}*/(target).getName();
            switch(buttonName) {
                case MediaPreviewButtonType.SHARE:
                    this.openShareButton_();
                    break;

                case MediaPreviewButtonType.INFO:
                    this.openInfoPopup_();
                    break;

                case MediaPreviewButtonType.UPLOAD:
                    this.uploadButton_.triggerUpload();
                    break;

                default:
                    break;
            }
        }
    }

    /**
     * @param {Event} e
     * @private
     */
    handleMenuBubbleOpen_(e) {
        this.updateOpenState_();

        if(this.menuBubble_ && e.getTarget() == this.menuBubble_) {
            this.menuBubble_.setContent(this.getMenu());
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleMenuBubbleClose_(e) {
        this.updateOpenState_();

        if(this.menuBubble_ && e.getTarget() == this.menuBubble_) {
            this.disposeMenuBubble();
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        this.dispatchEvent(e);

        if(!e.hasOwnProperty('placementTarget')) {
            const cfg = this.getConfigOptions();

            e.addProperty('placementTarget', cfg['layout'] == HgButtonUtils.DisplayLayout.INLINE ?
                this.menu_.getButtonByName(MediaPreviewButtonType.SHARE) : this.menuTriggerButton_);
        }
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
ResponsiveMediaToolbar.CSS_CLASS_PREFIX = 'hg-media-preview-responsive-toolbar';
/**
 * Busy context
 * @enum {string}
 * @readonly
 */
ResponsiveMediaToolbar.BusyContext = {
    DOWNLOADING     : 'downloading',
    DOWNLOAD_FAILED : 'download-failed'
};

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
ResponsiveMediaToolbar.CssClasses = {
    BASE: ResponsiveMediaToolbar.CSS_CLASS_PREFIX,

    INLINE_LAYOUT: ResponsiveMediaToolbar.CSS_CLASS_PREFIX + '-' + 'inline-layout',

    BUBBLE_LAYOUT: ResponsiveMediaToolbar.CSS_CLASS_PREFIX + '-' + 'bubble-layout',

    MENU_BUBBLE: ResponsiveMediaToolbar.CSS_CLASS_PREFIX + '-' + 'menu-bubble',

    MENU_BUTTON: ResponsiveMediaToolbar.CSS_CLASS_PREFIX + '-' + 'menu-button'
};