import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {MaxLengthRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {Message} from "./Message.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates new {@code hg.data.model.message.EditableMessage} data model.
 *
 * @extends {Message}
 * @unrestricted 
*/
export class EditableMessage extends Message {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'messageId';
    }

    /** @inheritDoc */
    addField(fieldParams) {
        /* only the subject and the body should be considered when computing the isDirtyFlag */
        if (fieldParams['name'] != 'subject' && fieldParams['name'] != 'body') {
            fieldParams['ignoreDirty'] = true;
        }

        return super.addField(fieldParams);
    }

    /** @inheritDoc */
    isDirty() {
        return this.isMarkedForRemoval() || this.hasDirtyFields();
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /* The user message that is displayed, does not contains content tags. The limit of this message as string is 140 chars
         as 'what the user has written' without any encoding tags */
        this.addField({'name': 'displayText', 'type': DataModelField.PredefinedTypes.STRING, 'isPersistable': false});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        /* subject cannot exceed 255 chars */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'subject',
            'maxLength'     : 255
        }));

        /* the maxLength validation rules must be added on the displayed text, not on the text that is sent to server
         (contains content tag) */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'displayText',
            'maxLength'     : HgAppConfig.MESSAGE_STANZA_BODY_MAXLEN
        }));

        this.addValidationRule(new Rule({
            'targetProperties': ['body'],
            'validationHandler': function (context) {
                const target = context['target'],
                    rawText = target['body'];

                return !StringUtils.isEmptyOrWhitespace(rawText);

            },
            'failMessage': translator.translate('You cannot post an empty message.')
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);

        rawData['displayText'] = '';    
    }
};