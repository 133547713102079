import {CurrentApp} from "./../../../../../hubfront/phpnoenc/js/app/App.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @unrestricted 
*/
export class HgDateUtils {
    constructor() {
        //
    }

    /**
     * Register client-server delay (only if app is visible and active to avoid delays on sleep mode)
     * @param {!number} delay Milliseconds
     * @param {boolean=} opt_force
     */
    static registerDelay(delay, opt_force) {
        if (!CurrentApp.Status.IDLE || !!opt_force) {
            HgDateUtils.Client_Server_Delay = delay;
        }
    }

    /**
     * Gets the current date time including delay between client-server.
     * @return {!Date} The current date time.
     */
    static now() {
        const now = new Date();

        /* simulate time diff 1h */
        //now.setMilliseconds(now.getMilliseconds() + 60*60*1000);

        /* take into consideration client-server time delay */
        now.setMilliseconds(now.getMilliseconds() - HgDateUtils.Client_Server_Delay);

        return now;
    }

    /**
     * Accepts a duration in milliseconds and outputs an absolute duration time in
     * form of hh:mm:ss
     * @param {number} duration Duration in seconds.
     * @return {string} The formatted duration.
     */
    static formatDuration(duration) {
        let content = '';

        if (duration != null) {
            let hours = Math.floor(duration / 3600);
            duration = duration % 3600;

            let minutes = Math.floor(duration / 60);
            duration = duration % 60;

            let seconds = Math.floor(duration);

            // Pad the minutes and seconds with leading zeros, if required
            minutes = HgDateUtils.padNumber_(minutes, 2);
            seconds = HgDateUtils.padNumber_(seconds, 2);

            content = minutes + ':' + seconds;

            if (hours != 0) {
                hours = HgDateUtils.padNumber_(hours, 2);
                content = hours + ':' + content;
            }
        }

        return content;
    }

    /**
     * Accepts a duration in milliseconds and outputs an absolute duration time in
     * a friendly format:    hh hours, mm minutes and ss seconds
     * @param {number} duration Duration in seconds.
     * @return {string} The formatted duration.
     */
    static formatDurationFriendly(duration) {
        let content = '';

        if (duration != null) {
            const hours = Math.floor(duration / 3600);
            duration = duration % 3600;

            const minutes = Math.floor(duration / 60);
            duration = duration % 60;

            const seconds = Math.floor(duration);

            if(hours > 0) {
                if(hours > 1) {
                    if(minutes > 0) {
                        if(minutes > 1) {
                            if(seconds > 0) {
                                if(seconds > 1) {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hours_minutes_seconds', [hours, minutes, seconds]);
                                } else {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hours_minutes_second', [hours, minutes]);
                                }
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hours_minutes', [hours, minutes]);
                            }
                        } else {
                            if(seconds > 0) {
                                if(seconds > 1) {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hours_minute_seconds', [hours, seconds]);
                                } else {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hours_minute_second', [hours]);
                                }
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hours_minute', [hours]);
                            }
                        }
                    } else {
                        if(seconds > 0) {
                            if(seconds > 1) {
                                content = HgDateUtils.getFriendlyDurationText_ ('hours_seconds', [hours, seconds]);
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hours_second', [hours]);
                            }
                        } else {
                            content = HgDateUtils.getFriendlyDurationText_ ('hours', [hours]);
                        }
                    }
                } else {
                    if(minutes > 0) {
                        if(minutes > 1) {
                            if(seconds > 0) {
                                if(seconds > 1) {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hour_minutes_seconds', [minutes, seconds]);
                                } else {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hour_minutes_second', [minutes]);
                                }
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hour_minutes', [minutes]);
                            }
                        } else {
                            if(seconds > 0) {
                                if(seconds > 1) {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hour_minute_seconds', [seconds]);
                                } else {
                                    content = HgDateUtils.getFriendlyDurationText_ ('hour_minute_second');
                                }
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hour_minute');
                            }
                        }
                    } else {
                        if(seconds > 0) {
                            if(seconds > 1) {
                                content = HgDateUtils.getFriendlyDurationText_ ('hour_seconds', [seconds]);
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('hour_second');
                            }
                        } else {
                            content = HgDateUtils.getFriendlyDurationText_ ('hour');
                        }
                    }
                }
            } else {
                if(minutes > 0) {
                    if(minutes > 1) {
                        if(seconds > 0) {
                            if(seconds > 1) {
                                content = HgDateUtils.getFriendlyDurationText_ ('minutes_seconds', [minutes, seconds]);
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('minutes_second', [minutes]);
                            }
                        } else {
                            content = HgDateUtils.getFriendlyDurationText_ ('minutes', [minutes]);
                        }
                    } else {
                        if(seconds > 0) {
                            if(seconds > 1) {
                                content = HgDateUtils.getFriendlyDurationText_ ('minute_seconds', [seconds]);
                            } else {
                                content = HgDateUtils.getFriendlyDurationText_ ('minute_second');
                            }
                        } else {
                            content = HgDateUtils.getFriendlyDurationText_ ('minute');
                        }
                    }
                } else {
                    if(seconds > 0) {
                        if(seconds > 1) {
                            content = HgDateUtils.getFriendlyDurationText_ ('z_seconds', [seconds]);
                        } else {
                            content = HgDateUtils.getFriendlyDurationText_ ('second');
                        }
                    } else {
                        content = HgDateUtils.getFriendlyDurationText_ ('0_seconds');
                    }
                }
            }
        }
        return content;
    }

    /***
     *
     * @param {string} duration
     * @param {!Array=} opt_durationParts
     * @return {string}
     * @private
     */
    static getFriendlyDurationText_(duration, opt_durationParts) {
        const translator = Translator;
        return translator.translate(duration, opt_durationParts || []);
    }

    /**
     * Pads number to given length and optionally rounds it to a given precision.
     * For example:
     * <pre>padNumber(1.25, 2, 3) -> '01.250'
     * padNumber(1.25, 2) -> '01.25'
     * padNumber(1.25, 2, 1) -> '01.3'
     * padNumber(1.25, 0) -> '1.25'</pre>
     *
     * @param {number} num The number to pad.
     * @param {number} length The desired length.
     * @param {number=} opt_precision The desired precision.
     * @return {string} {@code num} as a string with the given options.
     * @private
     */
    static padNumber_(num, length, opt_precision) {
        const s = opt_precision !== undefined ? num.toFixed(opt_precision) : String(num);
        let index = s.indexOf('.');
        if (index == -1) {
            index = s.length;
        }
        return '0'.repeat(Math.max(0, length - index)) + s;
    }
};

/**
 * Delay between client time and server time computed on message received
 * Note: It is currently updated by the MessageExchangeService with delay computed based
 * on received xmpp packages containing server timestamp
 * @type {number}
 */
HgDateUtils.Client_Server_Delay = 0; //ms