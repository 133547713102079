import {AbstractChatViewmodel} from "./AbstractChat.js";
import {CollectionView} from "../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {MessageThreadUIRegion} from "../../../common/ui/viewmodel/MessageThread.js";

/**
 *
 * @extends {AbstractChatViewmodel}
 * @unrestricted 
*/
export class MiniChatViewmodel extends AbstractChatViewmodel {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * Determine oldest opened thread and minimize it
     * @param {ChatThreadViewmodel|undefined} opt_chatThread
     */
    minimizeOldestThread(opt_chatThread) {
        if (Object.keys(this['openedThreads']).length > 0) {
            const openedThreadsArr = this.sortOpenedThreads();
            let oldestThread;

            oldestThread = openedThreadsArr.pop();
            if (oldestThread == opt_chatThread) {
                oldestThread = openedThreadsArr.pop();
            }

            if (oldestThread) {
                oldestThread.setExpanded(false);
            }
        }
    }

    /**
     * Enqueue oldest threads
     * @param {ChatThreadViewmodel|undefined} opt_chatThread
     * @return {ChatThreadViewmodel}
     */
    enqueueOldestThread(opt_chatThread) {
        if (Object.keys(this['openedThreads']).length > 0) {
            const openedThreadsArr = this.sortOpenedThreads();
            let oldestThread;

            oldestThread = openedThreadsArr.pop();
            if (oldestThread == opt_chatThread) {
                oldestThread = openedThreadsArr.pop();
            }

            if (oldestThread) {
                delete this['openedThreads'][oldestThread['recipientId']];

                this.enqueueThread(oldestThread);

                return oldestThread;
            }
        }

        return null;
    }

    /**
     * @param {ChatThreadViewmodel} chatThread
     * @param {boolean=} opt_close True if dequeue caused by thread closing
     */
    dequeueThread(chatThread, opt_close) {
        if (this['queue'].remove(chatThread)) {
            /* maximize thread on removal from queue */
            if (!opt_close) {
                chatThread.setVisible(true);
                chatThread.setExpanded(true);
            }

            this['queueCount']--;
        }
    }

    /**
     * @param {ChatThreadViewmodel} chatThread
     */
    enqueueThread(chatThread) {
        const index = this['queue'].indexOf(chatThread);
        if (index < 0) {
            this['queue'].add(chatThread);
            chatThread.setVisible(false);
            this['queueCount']++;
        }

        /* collapse the thread on adding it to the queue */
        chatThread.setExpanded(false);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* enqueued mini-threads */
        this.addField({
            'name': 'queue', 'value': new CollectionView({
                'sorters': [
                    {'sortBy': 'thread.thread.updated', 'direction': 'desc'}, // descending ordering by thread.thread.updated
                ]
            })
        });

        this.addField({'name': 'queueCount', 'isReadOnly': true, 'value': 0});

        this.addField({'name': 'threadsNotificationsCount', 'value': 0});
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if (fieldName == 'queue') {
            this.updateThreadsNotificationsCount_();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName == 'queue') {
            this.updateThreadsNotificationsCount_();
        }

        return result;
    }

    /** @inheritDoc */
    getUIRegion() {
        return MessageThreadUIRegion.MINI_CHAT;
    }

    /** @inheritDoc */
    onThreadOpened(openResult) {
        // be aware that thread is maximized in base onThreadOpened
        super.onThreadOpened(openResult);

        // if the chat thread is enqueued then remove it from the queue
        this.dequeueThread(openResult['thread']);
    }

    /** @inheritDoc */
    onThreadClosed(threadInfo) {
        super.onThreadClosed(threadInfo);

        this.dequeueThread(threadInfo['thread'], true);

    }

    /**
     * Sort opened threads
     * @return {Array}
     */
    sortOpenedThreads() {
        const openedThreadsArr = Object.values(this['openedThreads']);

        openedThreadsArr.sort(function (a, b) {
            return a.get('thread.thread.updated') > b.get('thread.thread.updated') ? -1 : 1;
        });

        return openedThreadsArr;
    }

    /**
     * @private
     */
    updateThreadsNotificationsCount_() {
        const chatThreads = this['queue'];

        this['threadsNotificationsCount'] = chatThreads != null ?
            chatThreads.getAll().filter(function (chatThread) {
                return chatThread.get('thread.thread.isUnseen');
            }).length :
            0;
    }
}