import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {Orientation, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {Display} from "./../../../common/ui/metacontent/Display.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Separator} from "./../../../../../../hubfront/phpnoenc/js/ui/Separator.js";
import {HgDateUtils} from "./../../../common/date/date.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import {Avatar} from "./../../../common/ui/Avatar.js";
import {HgEmoticonMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Emoticon.js";
import {HgGiphyMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Giphy.js";
import {HgPersonReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PersonRefer.js";
import {HgBotReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/BotRefer.js";
import {HgTopicReferMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TopicRefer.js";
import {HgHashtagMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Hashtag.js";
import {HgMailtoMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Mailto.js";
import {HgNumberFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/NumberFormatter.js";
import {HgTextFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextFormatter.js";
import {HgTextDirectionMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/TextDirection.js";
import {HgPhoneNumberMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/PhoneNumber.js";
import {HgCodeMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Code.js";
import {HgUnorderedListMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/UnorderedList.js";
import {HgScreenShareEventMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/ScreenShareEvent.js";
import {HgHighlightMetacontentPlugin} from "../metacontent/plugin/Highlight.js";
import {HgMessageHintMetacontentPlugin} from "../metacontent/plugin/MessageHint.js";
import {HgPartyName} from "./../../../common/ui/vcard/HgPartyName.js";
import {Author} from "./../../../common/ui/vcard/Author.js";
import {HgDateFormatterMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/DateFormatter.js";
import {HgTableMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Table.js";
import {HgQuoteMetacontentPlugin} from "./../../../common/ui/metacontent/plugin/Quote.js";
import {AvatarSizes} from "./../../../common/ui/avatar/Common.js";
import {HgMetacontentUtils} from "./../../../common/string/metacontent.js";
import {HgAppConfig} from "./../../../app/Config.js";
import MetacontentService from "../../../data/service/MetacontentService.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {MessageEvents, MessageTypes} from "../../../data/model/message/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import {HgPartyStatus} from "../../../data/model/party/Enums.js";
import {HgFileMetacontentPlugin} from "../metacontent/plugin/File.js";
import {HgMessageOptionsMetacontentPlugin} from "../metacontent/plugin/MessageOptions.js";
import {HgLinkMetacontentPlugin} from "../metacontent/plugin/Link.js";
import {HgMessageMetacontentPlugin} from "../metacontent/plugin/Message.js";

/**
 * Creates a new object representing an item from the thread search results list.
 *
 * @extends {UIComponent}
 * @unrestricted
 */
export class SearchListItemContent extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that displays the avatar
         * @type {Avatar}
         * @protected
         */
        this.avatar_ = this.avatar_ === undefined ? null : this.avatar_;

        /**
         * The name of the thread interlocutor
         * @type {HgPartyName}
         * @protected
         */
        this.name_ = this.name_ === undefined ? null : this.name_;

        /**
         * The latest message in thread
         * @type {Display}
         * @protected
         */
        this.messageHint = this.messageHint === undefined ? null : this.messageHint;

        /**
         * @type {UIComponent}
         * @protected
         */
        this.threadActivity = this.threadActivity === undefined ? null : this.threadActivity;

        /**
         * Social button that open the thread in chat as an active thread
         * @type {Button}
         * @protected
         */
        this.openInChatBtn = this.openInChatBtn === undefined ? null : this.openInChatBtn;

        /**
         * Current conversation's status
         * @type {UIControl}
         * @protected
         */
        this.statusLabel = this.statusLabel === undefined ? null : this.statusLabel;

        /**
         * Container used to display the interlocutor name and the button that opent the thread thread in chat area
         * @type {UIComponent}
         * @protected
         */
        this.nameContainer = this.nameContainer === undefined ? null : this.nameContainer;

        /**
         * @type {UIComponent}
         * @protected
         */
        this.infoContainer = this.infoContainer === undefined ? null : this.infoContainer;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.avatar_ = new Avatar({
            'avatarSize': AvatarSizes.LARGE,
            'extraCSSClass' : ['hg-mainresource-avatar'],
            'showInfoBubble': true
        });

        this.name_ = new HgPartyName({
            'extraCSSClass': ['hg-mainresource-name', 'hg-thread-name'],
            'displayDisabled': false
        });

        this.messageHint = new Display({
            'baseCSSClass'  : 'hg-thread-message-hint',
            'ellipsis'      : true
        });
        /* register service to delegate event processing */
        const service = MetacontentService.getInstance();
        if (service != null) {
            this.messageHint.registerService(service);
        }
        /* TextDirection plugin should be always registered before the Code plugin */
        this.messageHint.registerPlugin(new HgTextDirectionMetacontentPlugin());
        this.messageHint.registerPlugin(new HgCodeMetacontentPlugin(HgMetacontentUtils.CodeDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgTextFormatterMetacontentPlugin());
        this.messageHint.registerPlugin(new HgEmoticonMetacontentPlugin(HgMetacontentUtils.EmoticonDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgTableMetacontentPlugin(HgMetacontentUtils.TableDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgGiphyMetacontentPlugin());
        this.messageHint.registerPlugin(new HgPersonReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgBotReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgTopicReferMetacontentPlugin());
        this.messageHint.registerPlugin(new HgHashtagMetacontentPlugin());
        this.messageHint.registerPlugin(new HgMailtoMetacontentPlugin());
        this.messageHint.registerPlugin(new HgNumberFormatterMetacontentPlugin());
        this.messageHint.registerPlugin(new HgDateFormatterMetacontentPlugin(HgAppConfig.MEDIUM_DATE_TIME_FORMAT));
        this.messageHint.registerPlugin(new HgPhoneNumberMetacontentPlugin());
        this.messageHint.registerPlugin(new HgUnorderedListMetacontentPlugin());
        this.messageHint.registerPlugin(new HgScreenShareEventMetacontentPlugin(HgMetacontentUtils.ScreenShareEventDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgQuoteMetacontentPlugin(HgMetacontentUtils.QuoteDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgHighlightMetacontentPlugin());
        this.messageHint.registerPlugin(new HgFileMetacontentPlugin(HgMetacontentUtils.FileDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgLinkMetacontentPlugin(HgMetacontentUtils.LinkDecodeType.SHORT));
        this.messageHint.registerPlugin(new HgMessageOptionsMetacontentPlugin());
        this.messageHint.registerPlugin(new HgMessageMetacontentPlugin());
        this.messageHint.registerPlugin(new HgMessageHintMetacontentPlugin({
            'lengthLimitOnEmoji': 55
        }));

        this.threadActivity = new UIControl({
            'baseCSSClass'   : 'hg-thread-activity',
            'contentFormatter': this.createThreadActivityDom.bind(this)
        });

        this.openInChatBtn = HgButtonUtils.createOpenThreadInChatButton(
            true,
            HgButtonUtils.ButtonSize.SMALL,
            false,
            {
                'tooltip': {
                    'content': Translator.translate('send_message'),
                    'extraCSSClass': ['grayscheme', 'hg-tooltip'],
                    'autoHide': true,
                    'showArrow': true
                }
            }
        );

        this.statusLabel = HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(thread) {
                if(!thread) {
                    return null;
                }

                const status = thread['status'];
                const sharedWithMe = thread['sharedWithMe'];

                let statusText = sharedWithMe
                    ? 'conversation_shared_status' : status === HgPartyStatus.ACTIVE ? 'OPEN' : 'CLOSED';

                return statusText ? Translator.translate(statusText) : null;
            },
            'extraCSSClass': function(thread) {
                if(!thread) {
                    return null;
                }

                const status = thread['status'];
                const sharedWithMe = thread['sharedWithMe'];
                const css = ['hg-thread-status'];

                if(sharedWithMe) {
                    css.push('hg-thread-shared-with-me');
                }

                switch(status) {
                    case HgPartyStatus.DISABLED:
                        css.push('hg-thread-status-closed');
                        break;

                    case HgPartyStatus.ACTIVE:
                        css.push('hg-thread-status-open');
                        break;
                }

                if(sharedWithMe) {
                    css.push('blue');
                } else if(thread['status'] === HgPartyStatus.ACTIVE
                    && thread['watch'] && thread['watch']['watchedByMe']) {
                    css.push('watched-by-me', 'green');
                } else {
                    css.push('gray');
                }

                return css;
            }
        });

        this.nameContainer = new HorizontalStack({'extraCSSClass': ['hg-thread-name-container']});

        this.infoContainer = new VerticalStack({'extraCSSClass': ['hg-content', 'hg-thread-info']});
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.avatar_ = null;
        this.name_ = null;
        this.messageHint = null;
        this.threadActivity = null;
        this.openInChatBtn = null;        
        this.statusLabel = null;

        this.nameContainer = null;
        this.infoContainer = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-list-item-content';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-list-item-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.nameContainer.addChild(this.name_, true);
        this.nameContainer.addChild(this.openInChatBtn, true);
        this.nameContainer.addChild(this.statusLabel, true);

        this.infoContainer.addChild(this.nameContainer, true);
        this.infoContainer.addChild(this.messageHint, true);
        this.infoContainer.addChild(this.threadActivity, true);

        this.addChild(this.avatar_, true);
        this.addChild(this.infoContainer, true);

        this.addExtraCSSClass('hg-thread-search-list-item-content');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.infoContainer.getElement(), BrowserEventType.CLICK, this.handleDetailsOpen_)
            .listen(this.avatar_, UIComponentEventTypes.ACTION, this.handleDetailsOpen_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.avatar_, {'set': this.avatar_.setModel}, 'thread');

        this.setBinding(this.name_, {'set': this.name_.setModel}, 'thread');

        this.setBinding(this.openInChatBtn,	{'set': this.openInChatBtn.setModel}, 'thread');
        this.setBinding(this.openInChatBtn, {'set': this.openInChatBtn.setVisible}, {
            'sources': [
                { 'sourceProperty': 'thread.status'},
                { 'sourceProperty': 'thread.sharedWithMe'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return values[0] === HgPartyStatus.ACTIVE && !values[1];
                }
            }
        });

        this.setBinding(this.statusLabel,	{'set': this.statusLabel.setModel}, 'thread');

        const isLegacy = this.messageHint.isLegacy;
        this.setBinding(this.messageHint, {'set': this.messageHint.setContent},
            {
                'sourceProperty': 'message',
                'converter': {
                    'sourceToTargetFn': function(message) {
                        if(!message) {
                            return null;
                        }

                        let messageHint = message['body'] || message['subject'];
                        const messageType = message['type'],
                            messageVerb = message['event'];

                        if (messageType === MessageTypes.EVENT
                            && messageVerb === MessageEvents.GONE
                            && StringUtils.isEmptyOrWhitespace(messageHint)) {
                            messageHint = Translator.translate('conversation_archived_successfully');
                        }

                        /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                        if(!StringUtils.isEmptyOrWhitespace(messageHint) && isLegacy) {
                            messageHint = StringUtils.htmlEscape(messageHint);
                            messageHint = StringUtils.newLineToBr(messageHint);
                        }

                        return messageHint;
                    }
                }
            }
        );

        this.setBinding(this.messageHint, {'set': this.messageHint.setVisible},
            {
                'sourceProperty': 'message',
                'converter': {
                    'sourceToTargetFn': function(message) {
                        if(!message) {
                            return false;
                        }

                        let messageHint = message['body'] || message['subject'];

                        /* escape html entities as the message stored in bkend is considered plain (what you see is what you get) */
                        if(!StringUtils.isEmptyOrWhitespace(messageHint) && isLegacy) {
                            messageHint = StringUtils.htmlEscape(messageHint);
                            messageHint = StringUtils.newLineToBr(messageHint);
                        }

                        return !StringUtils.isEmptyOrWhitespace(messageHint);
                    }
                }
            }
        );

        this.setBinding(this.threadActivity, {'set': this.threadActivity.setModel}, 'message');
    }

    /**
     * @protected
     * @return {boolean}
     */
    openThreadDetails() {
        const event = new Event(HgUIEventType.VIEW_THREAD_HISTORY);
        event.addProperty('thread', this.getModel());

        return this.dispatchEvent(event);
    }

    /**
     * Creates the DOM for the thread date.
     * @param {*} message
     * @returns {Array}
     * @protected
     */
    createThreadActivityDom(message) {
        if(message == null) {
            return null;
        }

        const components = [];

        if(message['author'] != null && message['created'] != null) {
            components.push(new Author({
                'extraCSSClass' : ['hg-thread-thread-last-message-author'],
                'model': message['author']
            }));

            components.push(new Separator({'extraCSSClass': 'hg-bullet-separator', 'orientation': Orientation.VERTICAL}));

            components.push(new RelativeDate({
                'datetime'              : message['created'],
                'referenceDatetime'     : HgDateUtils.now,
                'absoluteDateFormat' 	: HgAppConfig.MEDIUM_DATE_FORMAT,
                'extraCSSClass'         : ['hg-thread-thread-last-message-date']
            }));
        }

        return components;
    }

    /**
     * Handles click on the topic name or the avatar. Dispatches HgUIEventType.VIEW_THREAD_HISTORY event
     * @param {hf.events.Event} e
     * @private
     */
    handleDetailsOpen_(e) {
        /* do not perform any action if there is a text selection */
        if(this.hasSelectedText()) {
            return true;
        }

        const target = e.getTarget();

        /* prevent event when click on a short link decode */
        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            return true;
        }

        /* Prevent event when click on the button that open the thread thread. When click on the openInChat button,
         the event is catched by the button inner element */
        if (target && target.nodeType == Node.ELEMENT_NODE
            && this.openInChatBtn.getElement() != null
            && this.openInChatBtn.getElement().contains(/**@type {Element}*/(target))) {
            return true;
        }

        return this.openThreadDetails();
    }
}