import { Disposable } from '../disposable/Disposable.js';
import { EventsUtils } from '../events/Events.js';
import { BrowserEventType } from '../events/EventType.js';
import { BaseUtils } from '../base.js';

/**
 * Create an instance of a skin manager
    Helps loading skin based on current user theme and current identified theme
 *
 * @example
var mySkinManager = hf.skin.SkinManager.getInstance();
mySkinManager.setTheme('customTheme');
 * @example
var mySkinManager = hf.skin.SkinManager.getInstance();
var completeImgPath = mySkinManager.getImageUrl('myProfilePhoto');
var photo = DomUtils.createDom('img', {src: completeImgPath});
 * @augments {Disposable}
 *
 */
class SkinManager extends Disposable {
    /**
     * @param {string=} opt_theme Current theme
     * @param {string=} opt_basePath Base path.
     */
    constructor(opt_theme, opt_basePath) {
        /* Call the parent */
        super();
        /**
         * Current theme name
         *
         * @type {string}
         * @private
         */
        this.theme_ = opt_theme || '';


        if (BaseUtils.isString(opt_basePath) && !opt_basePath.endsWith('/')) {
            opt_basePath += '/';
        }
        /**
         * Current base path
         *
         * @type {string}
         * @private
         */
        this.basePath_ = opt_basePath || '';
    }

    /**
     * Set current theme
     *
     * @param {string} theme Current theme
     * @throws {TypeError} If theme parameter is not a string
     *
     */
    setTheme(theme) {
        if (!BaseUtils.isString(theme) || BaseUtils.isEmpty(theme)) {
            throw new TypeError('The theme parameter is not a string');
        }

        this.theme_ = theme;
    }

    /**
     * Get current theme
     *
     * @returns {string} Current theme
     *
     */
    getTheme() {
        return this.theme_;
    }

    /**
     * Set base path
     *
     * @param {string} basePath Current base path
     * @throws {TypeError} If theme parameter is not a string
     *
     */
    setBasePath(basePath) {
        if (!BaseUtils.isString(basePath) || BaseUtils.isEmpty(basePath)) {
            throw new TypeError('The basePath provided is not a valid path.');
        }

        if (BaseUtils.isString(basePath) && !basePath.endsWith('/')) {
            basePath += '/';
        }

        this.basePath_ = basePath;
    }

    /**
     * Get current base path
     *
     * @returns {string} Current base path
     *
     */
    getBasePath() {
        return this.basePath_;
    }

    /**
     * Dynamic CSS file load
     *
     * @param {string} cssFilename
     * @param {boolean=} opt_absolute
     * @returns {Promise}
     *
     */
    loadCssFile(cssFilename, opt_absolute) {
        opt_absolute = opt_absolute || false;

        const cssPath = opt_absolute ? cssFilename : this.getCssUrl_(cssFilename);

        return loadFile_(cssPath);
    }

    /**
     * Dynamic CSS file load for files that are independent of theme and device
     *
     * @param {string} cssFilename
     * @returns {Promise}
     *
     */
    loadIndependentCssFile(cssFilename) {
        let cssPath = `${this.getBasePath()}skin/`;
        cssPath += `css/${cssFilename}`;


        return loadFile_(cssPath);
    }

    /**
     * Return true if we are on a retina display
     *
     * @returns {boolean}
     *
     */
    isRetina() {
        return window.devicePixelRatio > SkinManager.HIGH_DENSITY_RATIO_;
    }

    /**
     * Compute the image url
     skin/<theme>/<device>/images/<context>/filename.*
     *
     * @param {string} imageFilename Image filename
     * @param {boolean=} opt_detectRetina
     * @returns {string} path Full image path
     *
     */
    getImageUrl(imageFilename, opt_detectRetina) {
        let path = `${this.getBasePath()}skin/${this.getTheme()}`;

        /* var localization = hf.localization.Locale.getInstance();
         var device = localization.getDevice();

         var type = device.getType();
         switch (type) {
         case (hf.localization.Device.Type.DESKTOP):
         default: {
         path = path + '/default';
         }
         } */

        path += '/images/';

        /* try to automatically detect retina if possible:  @2x.ext */
        if (opt_detectRetina && this.isRetina()) {
            const extIndex = imageFilename.lastIndexOf('.');
            if (extIndex != -1) {
                const ext = imageFilename.substr(extIndex);
                imageFilename = imageFilename.replace(ext, `@2x${ext}`);
            }
        }

        path += imageFilename;

        return path;
    }

    /**
     * Compute the css file url
     skin/<theme>/<device>/css/filename.*
     *
     * @param {string} cssFilename
     *
     */
    getCssUrl_(cssFilename) {
        let path = `${this.getBasePath()}skin/${this.getTheme()}`;

        /*	var localization = hf.localization.Locale.getInstance();
         var device = localization.getDevice();

         var type = device.getType();
         switch (type) {
         case (hf.localization.Device.Type.DESKTOP):
         default: {
         path = path + '/default';
         }
         } */

        path += `/css/${cssFilename}`;

        return path;
    }

    /**
     * Dynamic CSS file load for external files
     *
     * @param {string} filename
     * @returns {Promise}
     *
     */
    loadExternalFile(filename) {
        return loadFile_(filename);
    }
}

/**
 * Dynamic CSS file load for external files
 *
 * @param {string} filename
 * @returns {Promise}
 * @private
 */
function loadFile_(filename) {
    /* load cssPath */
    const fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', filename);

    const promise = new Promise((resolve, reject) => {
        let hasFired = false;

        const listenerKey = EventsUtils.listenOnce(fileref, [BrowserEventType.LOAD, BrowserEventType.READYSTATECHANGE],
            (e) => {
                if (!hasFired) {
                    if (e.getType() == BrowserEventType.LOAD) {
                        resolve();
                    } else {
                        const state = fileref.readyState;
                        if (state === 'loaded' || state === 'complete') {
                            resolve();
                        }
                    }

                    hasFired = true;
                }
            });

        /* if not fired in less than 5s fire automatically */
        setTimeout(() => {
            if (!hasFired) {
                EventsUtils.unlistenByKey(listenerKey);

                resolve();
            }
        }, SkinManager.FIRE_LOAD_);
    });

    document.getElementsByTagName('head')[0].appendChild(fileref);

    return promise;
}

/**
 * Interval after which to fire css load success automatically if not fired meanwhile
 *
 * @type {number}
 * @default 5000
 * @private
 */
SkinManager.FIRE_LOAD_ = 5000;

/**
 * Minimum ratio between device and screen pixel needed for high density mode.
 *
 * @type {number}
 * @private
 */
SkinManager.HIGH_DENSITY_RATIO_ = 1.25;

/**
 * Static instance property
 *
 * @static
 * @private
 */
const instance = new SkinManager();

export default instance;
