import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import {HgTopicReferMetacontentPlugin} from "./TopicRefer.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new Event metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgEventMetacontentPlugin extends AbstractMetacontentPlugin {
    constructor() {
        super();
    }

    /** @override */
    getClassId() {
        return 'Event';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.EVENT);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.EVENT);
    }

    /**
     * Handles click on:  <span class="hg-metacontent-event" data-resource-type="EVENT" %s>%s</span>
     * Returns true if event is handled to that no other plugins should be called to handle it
     * Event is not propagated to parent display or other components
     *
     * @override
     */
    handleMouseOver(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (this.isTargetedAnchor(target)) {
            return true;
        }

        return false;
    }

    /**
     * Handles click on:  <span class="hg-metacontent-topic" data-resource-type="TOPIC" %s>%s</span>
     * Returns true if event is handled to that no other plugins should be called to handle it
     * Event is not propagated to parent display or other components
     *
     * @override
     */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /**
     * Check if anchor must be targeted on click (show options bubble)
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.EVENT);
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (!e.defaultPrevented && this.isTargetedAnchor(target)) {
            const topicId = target.getAttribute('data-resourceid');
            if (!StringUtils.isEmptyOrWhitespace(topicId)) {
                const event = new Event(MetacontentPluginEventType.DATA_ACTION);
                event.addProperty('action', HgTopicReferMetacontentPlugin.Action.CHAT);
                event.addProperty('resource', HgResourceUtils.getResourceLink({
                    'resourceType'  : HgResourceCanonicalNames.TOPIC,
                    'resourceId'    : topicId
                }));

                if (this.dispatchEvent(event)) {
                    /* mark the event as handled */
                    e.preventDefault();

                    return true;
                }
            }
        }

        return false;
    }
};