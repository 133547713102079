import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BaseLayoutTransition} from "./BaseLayoutTransition.js";
import {BrowserEventType} from "./../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {EventsUtils} from "./../../../../../hubfront/phpnoenc/js/events/Events.js";

/**
 * Constructor for an overlay animation
 * @extends {BaseLayoutTransition}
 * @unrestricted 
*/
export class OverlayLeft extends BaseLayoutTransition {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.container_ = this.container_ === undefined ? null : this.container_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.container_ = opt_config['container'];
        if (!(this.container_ instanceof UIComponent)) {
            throw new Error('Assertion failed');
        }
    }

    /** @inheritDoc */
    playInternal() {
        this.container_.addExtraCSSClass('hg-overlay');
    }

    /** @inheritDoc */
    playReverseInternal() {
        EventsUtils.listenOnce(this.container_.getChildAt(0).getElement(), BrowserEventType.TRANSITIONEND, this.handleTransitionEnd_, false, this);
        this.container_.swapExtraCSSClass('hg-overlay', 'hg-overlay-out');
    }

    /** @inheritDoc */
    disposeInternal() {
        this.playReverse();
        this.container_ = null;

        super.disposeInternal();
    }

    /**
     * Trigger reflow on container when animation ends
     * @private
     */
    handleTransitionEnd_(e) {
        this.container_.removeExtraCSSClass('hg-overlay-out');
    }
};