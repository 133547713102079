import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractFacetView} from "./../../../common/ui/view/AbstractFacet.js";
import {TimeRangeSelector} from "./../../../common/ui/labs/TimeRangeSelector.js";

/**
 * Creates a new {@see hg.ch.ui.view.ChUiFacetView} object.
 *
 * @extends {AbstractFacetView}
 * @unrestricted 
*/
export class ChUiFacetView extends AbstractFacetView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The selector for calls time range.
         * @type {hg.common.ui.TimeRangeSelector}
         * @private
         */
        this.rangeSelector_ = this.rangeSelector_ === undefined ? null : this.rangeSelector_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* time range jump in list */
        this.rangeSelector_ = new TimeRangeSelector();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.rangeSelector_);
        delete this.rangeSelector_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-facet-ch';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

    //    this.getHandler().
    //        listen(this.rangeSelector_, SelectorEventType.SELECTION_CHANGE, this.handleRangeSelection_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /**
     * Handles selection of a time range
     * @param {hf.events.Event} e The event
     * @private
     */
    handleRangeSelection_(e) {
        // TODO: jump in current context
    }
};