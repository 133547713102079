import {UIComponent} from './../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js';
import {BaseUtils} from './../../../../../../hubfront/phpnoenc/js/base.js';
import {HgMetacontentUtils} from './../../../common/string/metacontent.js';
import {HgAppConfig} from './../../../app/Config.js';
import {Display as DisplayLegacy} from './../../../../../../hubfront/phpnoenc/js/ui/metacontent/Display.js';
import {HgPersonUtils} from "../../../data/model/person/Common.js";
import {HgCurrentUser} from "../../../app/CurrentUser.js";
import ScreenShareService from "../../../data/service/ScreenShareService.js";
import {FileTypes} from "../../../data/model/file/Enums.js";

/**
 * Creates a new display
 * @extends {UIComponent}
 * @unrestricted
 */
export class Display2 extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Service delegated to respond to data requests
         * @type {hf.domain.service.IMetacontentService}
         * @private
         */
        this.service_;

        /**
         * @type {Object}
         * @private
         */
        this.cprActiveContent_;

        /**
         * @type {string}
         * @private
         */
        this.markup_;
    }

    /**
     * @param {hf.domain.service.IMetacontentService} service
     */
    registerService(service) {
        if (this.service_ != null) {
            this.unregisterService();
        }

        service.registerDisplay(this);
        this.service_ = service;
    }

    /**
     * Unregister service
     */
    unregisterService() {
        if (this.service_ != null) {
            this.service_.unregisterDisplay(this);
        }

        this.service_ = null;
    }

    /**
     *
     * @param {Array.<hf.ui.metacontent.AbstractMetacontentPlugin>} plugins
     * todo: optimization: update the content after the plugins registration
     */
    setPlugins(plugins) {
        /* unregisters the existing plugins */
        this.unregisterAllPlugins();

        /* Register the plugins */
        plugins.forEach(function (plugin) {
            this.registerPlugin(plugin);
        }, this);
    }

    /**
     * Registers the plugin with the display.
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin} plugin The plugin to register.
     * @throws {Error} When registering a plugin twice
     */
    registerPlugin(plugin) {
        const classId = plugin.getClassId();
        if (this.plugins_[classId]) {
            throw new Error('Cannot register the same class of plugin twice on a metacontent Display!');
        }
        this.plugins_[classId] = plugin;

        const options = plugin.getConfigOptions();
        const viewDescriptor = options.viewDescriptor || null;
        let cprOptions = {};
        switch (classId) {
            case 'BotRefer':
                cprOptions['decode'] = plugin.action_;
                break;

            case 'ChunkEllipsis':
            case 'MessageHint':
                cprOptions = options;
                break;

            case 'Code':
                cprOptions['decode'] = options['decodeMode'];
                cprOptions['highlight'] = true;
                break;

            case 'DateFormatter':
                cprOptions['format'] = (value) => {
                    const formatter = new Intl.DateTimeFormat(HgAppConfig.LOCALE, plugin.absoluteDateFormat_),
                        valueAsDate = new Date(value);

                    if (BaseUtils.isDate(valueAsDate) && !isNaN(valueAsDate.getTime())) {
                        return formatter.format(valueAsDate);
                    } else {
                        return value;
                    }
                };
                break;

            case 'File':
                if (options['decodeMode'] === HgMetacontentUtils.FileDecodeType.FULL_PREVIEW
                    /*|| options['decodeMode'] === HgMetacontentUtils.FileDecodeType.EXTERNAL_PREVIEW*/
                    || options['decodeMode'] === undefined) {
                    cprOptions['decode'] = 'full';
                } else if (options['decodeMode'] === HgMetacontentUtils.FileDecodeType.SHORT) {
                    cprOptions['decode'] = 'short';
                } else {
                    cprOptions['decode'] = 'minipreview';
                }
                cprOptions['viewport'] = options['viewport'];
                this.env_['thread'] = options['thread'];
                this.env_['context'] = options['context'];
                cprOptions['allowedActions'] = options['allowedActions'] || ['share', 'download'];
                cprOptions['isPreviewable'] = (file) => file.type === FileTypes.IMAGE || HgMetacontentUtils.isPreviewableDoc(file);
                break;

            case 'Giphy':
                this.env_['isNewMessage'] = options['isMessageNew'] || false;
                break;

            case 'Link':
                if (plugin.decodeMode_ === HgMetacontentUtils.LinkDecodeType.EXTERNAL) {
                    cprOptions['decode'] = 'nopreview';
                } else if (plugin.decodeMode_ === HgMetacontentUtils.LinkDecodeType.PREVIEW) {
                    cprOptions['decode'] = 'minipreview';
                } else {
                    cprOptions['decode'] = plugin.decodeMode_;
                }
                break;

            case 'MessageOptions':
                this.env_['thread'] = options['thread'];
                this.env_['reference'] = options['reference'];
                this.env_['context'] = options['context'] || options['replyMessage'];
                break;

            case 'PersonRefer':
                cprOptions['decode'] = plugin.action_;
                cprOptions['isMe'] = HgPersonUtils.isMe;
                cprOptions['isHug'] = HgPersonUtils.isHUG;
                break;

            case 'Quote':
                cprOptions['decode'] = plugin.decodeMode_;
                break;

            case 'Table':
                cprOptions['decode'] = plugin.decodeMode_;
                break;

            case 'TopicRefer':
                cprOptions['decode'] = plugin.action_;
                break;

            case 'Emoticon':
                cprOptions = HgMetacontentUtils.defaultCapriEmoticonConfig();
                cprOptions['decode'] = plugin.decodeMode_ === 'short' ? 'short' : 'full';
                break;

            case 'Notification':
                this.env_['context'] = options['context'] == 'tb' ? 'tb' : 'default';
                cprOptions['maxRows'] = options['rowLimit'];
                cprOptions['lengthLimit'] = options['lengthLimit'];
                break;

            case 'PhoneNumber':
                let countryCode = '';
                if (!HgCurrentUser.isEmpty() && BaseUtils.isFunction(HgCurrentUser.get)) {
                    countryCode = /**@type {string}*/(HgCurrentUser.get('address.region.country.code'));
                }
                cprOptions['countryCode'] = countryCode;
                break;

            case 'NumberFormatter':
                cprOptions['format'] = function(number) {
                    return number;
                };
                break;

            case 'SShareEvent':
                cprOptions['service'] = ScreenShareService;
                cprOptions['isMe'] = HgPersonUtils.isMe;
                break;
        }

        if (Display2.CPR_EXTENSION_MAP[classId]) {
            this.extensions_.push([Display2.CPR_EXTENSION_MAP[classId], cprOptions, viewDescriptor]);
        }

        /* refresh content, apply new plugin */
        if (this.timeoutId_) {
            clearTimeout(this.timeoutId_);
            this.timeoutId_ = null;
        }

        this.timeoutId_ = setTimeout(this.reconfigureState_.bind(this), 0);
    }

    /**
     * Unregisters the plugin from the display.
     * @param {hf.ui.metacontent.AbstractMetacontentPlugin} plugin The plugin to unregister.
     */
    unregisterPlugin(plugin) {
        const classId = plugin.getClassId();
        if (!this.plugins_[classId]) {
            return;
            //throw new Error('Cannot unregister a plugin that isn\'t registered on a metacontent Display!');
        }
        delete this.plugins_[classId];

        if (this.timeoutId_) {
            clearTimeout(this.timeoutId_);
            this.timeoutId_ = null;
        }

        this.timeoutId_ = setTimeout(this.reconfigureState_.bind(this), 0);
    }

    /**
     * Unregisters all plugins.
     */
    unregisterAllPlugins() {
        if (this.plugins_ != null) {
            for (let key in this.plugins_) {
                let plugin = this.plugins_[key];

                if (plugin != null) {
                    this.unregisterPlugin(plugin);
                }
            }
        }
    }

    /**
     * Check is plugin is registered
     * @param {string} classId
     */
    hasPlugin(classId) {
        return this.plugins_ != null && this.plugins_[classId] != null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Display.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Display.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /**
         * Map of class id to registered plugin.
         * @type {Object}
         * @private
         */
        this.plugins_ = {};

        /**
         * @type {Object}
         * @private
         */
        this.env_ = HgMetacontentUtils.defaultCapriActiveContentEnv();

        /**
         * @type {Array}
         * @private
         */
        this.extensions_ = [];


        this.addExtraCSSClass(['cpr-activecontent', 'cpr-v-g-2x']);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        if (!this.cprActiveContent_) {
            hui.initTranslator({
                '$t': HgMetacontentUtils.$t
            });

            this.cprActiveContent_ = new hui.ActiveContent({
                'env': this.env_,
                'extensions': this.extensions_,
                'markup': this.markup_
            }, {
                '$el': this.getElement(),
                '$emit': this.handleCPREvent_.bind(this),
                '$options': {},
                '$children': []
            });

            /* refresh content, apply new plugin */
            if (this.timeoutId_) {
                clearTimeout(this.timeoutId_);
                this.timeoutId_ = null;
            }

            this.cprActiveContent_.render();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.timeoutId_) {
            clearTimeout(this.timeoutId_);
            this.timeoutId_ = null;
        }

        for (let classId in this.plugins_) {
            const plugin = this.plugins_[classId];
            plugin.dispose();
        }

        delete(this.plugins_);
        delete(this.env_);

        this.unregisterService();

        if (this.cprActiveContent_) {
            this.cprActiveContent_.destroy();
            delete(this.cprActiveContent_);
        }
    }

    setContent(markup = '') {
        this.markup_ = markup || '';

        this.updateContent_();
    }

    getContent() {
        return this.markup_;
    }

    updateContent_() {
        if (this.cprActiveContent_) {
            // markup might end with {hg-internal:author}
            let authorName = '', content = this.markup_;
            if (content && content.indexOf('{' + HgMetacontentUtils.InternalTag.AUTHOR + '}') != -1) {
                const match = content.match(new RegExp('{' + HgMetacontentUtils.InternalTag.AUTHOR + '}(.*?){/' + HgMetacontentUtils.InternalTag.AUTHOR + '}'));
                if (match != null) {
                    authorName = match[1];
                    content = content.replace(match[0], '');
                }
            }

            // remove newlines from the begining of the text!!
            this.cprActiveContent_.setContent(content.replace(/^(\s*\n)*/, ''));

            const elem = this.getElement();
            if (elem && authorName && elem.firstChild) {
                elem.innerHTML = `<span class="inline-text"><span class="bold-text">${authorName}: </span>${elem.innerHTML}</span>`;
            }
        }
    }

    reconfigureState_() {
        if (this.cprActiveContent_) {
            this.cprActiveContent_.setExtensions(this.extensions_);
        }
    }

    /**
     * Handler for prosemirror editor events
     * @type {string} ev Editor event type
     * @type {*} params Editor event payload
     */
    handleCPREvent_(ev, params) {
        if (this.service_) {
            if (ev === 'data-request') {
                this.service_.onActiveContentDataRequest(params['extension'], params['payload']);
            } else if (ev === 'data-action') {
                this.service_.onActiveContentDataAction(params['extension'], params['payload']);
            }
        }
    }
};

/**
 * Map between Capri and HG App events
 *
 * @enum {string}
 * @readonly
 */
Display2.CPR_EXTENSION_MAP = {
    ['BotRefer']: hui.ActiveContentExtensions.Bot,
    ['ChunkEllipsis']: hui.ActiveContentExtensions.ChunkEllipsis,
    ['Code']: hui.ActiveContentExtensions.Code,
    ['DateFormatter']: hui.ActiveContentExtensions.DateFormatter,
    // shortDecode for emoticon does not exists, messageHint does the work
    ['Emoticon']: hui.ActiveContentExtensions.Emoticon,
    ['TextFormatter']: hui.ActiveContentExtensions.Emphasis,
    ['File']: hui.ActiveContentExtensions.File,
    ['Giphy']: hui.ActiveContentExtensions.Giphy,
    ['Highlight']: hui.ActiveContentExtensions.Highlight,
    ['Label']: hui.ActiveContentExtensions.Label,
    ['Link']: hui.ActiveContentExtensions.Link,
    ['Mailto']: hui.ActiveContentExtensions.Mailto,
    ['Message']: hui.ActiveContentExtensions.Message,
    // todo: what options does it use?
    ['Notification']: hui.ActiveContentExtensions.MessageHint,
    ['NumberFormatter']: hui.ActiveContentExtensions.NumberFormatter,
    ['MessageOptions']: hui.ActiveContentExtensions.Option,
    ['PersonRefer']: hui.ActiveContentExtensions.Person,
    ['PhoneNumber']: hui.ActiveContentExtensions.PhoneNumber,
    ['Quote']: hui.ActiveContentExtensions.Quote,
    // todo: screenShareService needed !
    // shortDecode for screenshare does not exist
    ['SShareEvent']: hui.ActiveContentExtensions.ScreenShare,
    ['Table']: hui.ActiveContentExtensions.Table,
    ['Hashtag']: hui.ActiveContentExtensions.Tag,
    ['TextDirection']: hui.ActiveContentExtensions.TextDirection,
    ['TopicRefer']: hui.ActiveContentExtensions.Topic,
    ['UnorderedList']: hui.ActiveContentExtensions.List
};

/**
 * Constructor for the Chat Editor UI component. The Chat Editor component displays
 * a text field and controls for sending chat text, smileys, files, and links.
 * For links, it also shows a Link Previewer component to allow fine tunning of
 * the link preview to be sent.
 *
 * @extends {LayoutContainer}
 * @unrestricted
 */
export class Display {
    /**
     * @param {!Object=} opt_config Optional configuration object.
     *  @param {Object=} opt_config.context Optional. Whether to start the editor in mini mode or full mode.
     *                                  Mini mode is used in mini-threads.
     *    @param {boolean=} opt_config.sendOnEnter Optional. Whether to submit the typed message
     *                                    when the user presses the ENTER key.
     *                                    Defaults to true.
     *  @param {number=} opt_config.stopTypingDelayMs Optional. The time to wait after the user
     *                                pressed the last key before considering he
     *                                stopped typing.
     *                                Defaults to 2500 ms.
     *
     */
    constructor(opt_config = {}, opt_forceLegacy = false) {
        let display;
        if (HgAppConfig.LEGACY_EDITOR || opt_forceLegacy) {
             display = new DisplayLegacy(opt_config);
             display.isLegacy = true;
        } else {
            display = new Display2(opt_config);
            display.isLegacy = false;
        }

        return display;
    }
};

/**
 * The prefix we use for the CSS class names for the button and its elements.
 * @type {string}
 * @protected
 */
Display.CSS_CLASS_PREFIX = DisplayLegacy.CSS_CLASS_PREFIX;

/**
 * @static
 * @protected
 */
Display.CssClasses = DisplayLegacy.CssClasses;