import {Event} from "./../../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DataBindingMode} from "./../../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {LabelTextAlign} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {UIComponentEventTypes} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIControl} from "./../../../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {AddressDetails} from "./../../billing/AddressDetails.js";
import {CardDetails} from "./../../billing/CardDetails.js";
import {HgButtonUtils} from "./../../../../../common/ui/button/Common.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The list of events that can be dispatched by this component
 * @enum {string}
 * @readonly
 */
export const PayPlanAccountStatusEventType = {
    /**
     * Dispatched when the user clicks on the change billing plan trigger
     * @event PayPlanAccountStatusEventType.CHANGE_BILLING_PLAN
     */
    CHANGE_BILLING_PLAN : 'change_billing_plan'
};

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class PayPlanAccountStatus extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {hf.ui.form.FieldGroup}
         * @private
         */
        this.referralFieldGroup_;

        /**
         * The name of the billing plan
         * @type {hf.ui.Caption}
         * @private
         */
        this.planName_ = this.planName_ === undefined ? null : this.planName_;

        /**
         * The details about the billing plan
         * @type {hf.ui.UIControl}
         * @private
         */
        this.planDetails_ = this.planDetails_ === undefined ? null : this.planDetails_;

        /**
         * The trigger that change the current selected billing plan
         * @type {hf.ui.Button}
         * @private
         */
        this.changePlanTrigger_ = this.changePlanTrigger_ === undefined ? null : this.changePlanTrigger_;

        /**
         *
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.referralCodeField_ = this.referralCodeField_ === undefined ? null : this.referralCodeField_;

        /**
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.discountNotice_ = this.discountNotice_ === undefined ? null : this.discountNotice_;

        /**
         * The fields that require details about the billing address
         * @type {hg.module.settings.billingplan.AddressDetails}
         * @private
         */
        this.billingAddressFields_ = this.billingAddressFields_ === undefined ? null : this.billingAddressFields_;

        /**
         * The fields that require details about the credit card
         * @type {hg.module.settings.billingplan.CardDetails}
         * @private
         */
        this.cardDetailsFields_ = this.cardDetailsFields_ === undefined ? null : this.cardDetailsFields_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'pay-plan-status';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-payplan-form');

        const translator = Translator;

        this.planName_ = new Caption({'extraCSSClass': ['hg-billing-page-header', 'hg-billing-payplan-form-name']});

        this.planDetails_ = new UIControl({
            'extraCSSClass'		: 'hg-billing-payplan-form-details',
            'contentFormatter'	: function(model) {
                if (model == null) {
                    return null;
                }

                const amountRaw = model['amountRaw'],
                    intervalRaw = model['intervalRaw'],
                    interval = model['interval'],
                    teamReport = model['teamReport'],
                    totalAmountRaw = model['totalAmountRaw'];

                let paidDetails = '';
                const content = document.createDocumentFragment();

                if (!StringUtils.isEmptyOrWhitespace(totalAmountRaw) && !StringUtils.isEmptyOrWhitespace(interval)) {
                    paidDetails = paidDetails + totalAmountRaw + ' / ' + interval.toLowerCase();
                }

                /* add user details */
                let activeUsers = 0,
                    rawActiveUsers = 0;

                if (teamReport != null) {
                    activeUsers = BaseUtils.isNumber(teamReport['active']) ? teamReport['active'] : activeUsers;
                }

                /* if the number of active users is lower than the minimum quantity set on a service plan, then display
                 servicePlan.minQuantity value */
                const minQuantity = model['minQuantity'];
                if (BaseUtils.isNumber(minQuantity)) {
                    rawActiveUsers = (minQuantity > activeUsers) ? parseInt(minQuantity, 10) : parseInt(activeUsers, 10);
                }

                if (rawActiveUsers != 0) {
                    let usersCountMessage = '';

                    /* add custom message when the minimum quantity of users are displayed for a service plan item */
                    if (rawActiveUsers === minQuantity) {
                        /* the message displayed when the service plan quantity is: 1 user (maybe redundant case) */
                        if (rawActiveUsers === 1) {
                            usersCountMessage = translator.translate('for_member');
                        } else {
                            usersCountMessage = translator.translate('for_members', [rawActiveUsers]);
                        }
                    } else {
                        /* the message displayed when the service plan quantity is: 1 user (maybe redundant case) */
                        if (rawActiveUsers === 1) {
                            usersCountMessage = translator.translate('for_1_member');
                        } else {
                            usersCountMessage = translator.translate('for_x_members', [rawActiveUsers]);
                        }
                    }

                    /* add minimum users count message */
                    if (!StringUtils.isEmptyOrWhitespace(usersCountMessage)) {
                        paidDetails = paidDetails + ' ' + usersCountMessage;
                    }
                }

                content.appendChild(DomUtils.createDom('span', 'plan-details', paidDetails));

                return content;
            }
        });

        this.changePlanTrigger_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('change_plan'),
            'extraCSSClass' : ['change-plan-trigger']
        });

        this.referralCodeField_ = new Text({
            'name'        	: PayPlanAccountStatus.FieldName.REFERRAL_CODE,
            'autocomplete'	: false,
            'placeholder' 	: translator.translate('referral_code'),
            'extraCSSClass'	: 'hg-billing-payplan-form-referral-code',
            'maxlength'     : 32,
            'validation'  	: {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        });

        this.referralFieldGroup_ = new FieldGroup({
            'extraCSSClass' : 'hg-billing-payplan-form-referral-field-group',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : {
                'content' : translator.translate('heard_about_us'),
                'layout'  : FormFieldLabelLayout.TOP,
                'align'   : LabelTextAlign.LEFT
            },
            'fields'      : [ this.referralCodeField_ ],
            'hidden': true
        });

        this.discountNotice_ = new Caption({
            'extraCSSClass'	: 'hg-billing-payplan-form-discount-notice',
            'hidden': true
        });

        this.billingAddressFields_ = new AddressDetails();

        this.cardDetailsFields_ = new CardDetails({
            'header': translator.translate('card_details')
        });
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.changePlanTrigger_, UIComponentEventTypes.ACTION, this.handleChangePlan_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.planName_, {'set': this.planName_.setContent}, 'subscribedBillingPlan.name');

        this.setBinding(this.planDetails_, {'set': this.planDetails_.setModel}, {
            'sources': [
                {'sourceProperty': 'subscribedBillingPlan.amountRaw'},
                {'sourceProperty': 'subscribedBillingPlan.intervalRaw'},
                {'sourceProperty': 'subscribedBillingPlan.interval'},
                {'sourceProperty': 'subscribedBillingPlan.teamReport'},
                {'sourceProperty': 'subscribedBillingPlan.minQuantity'},
                {'sourceProperty': 'subscribedBillingPlan.totalAmountRaw'}
            ],
            'converter': {
                'sourceToTargetFn': function(sources) {
                    return sources != null ? {
                        'amountRaw'		: sources[0],
                        'intervalRaw'	: sources[1],
                        'interval'		: sources[2],
                        'teamReport'	: sources[3],
                        'minQuantity'	: sources[4],
                        'totalAmountRaw': sources[5]
                    } : null;
                }
            }
        });

        this.setBinding(
            this.billingAddressFields_,
            {'set': this.billingAddressFields_.setModel},
            {
                'sources': [
                    {'sourceProperty': 'billingAddress'},
                    {'sourceProperty': 'countriesList'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        return sources != null ? {
                            'billingAddress': sources[0],
                            'countriesList'	: sources[1]
                        } : null;
                    }
                }
            }
        );

        this.setBinding(
            this.cardDetailsFields_,
            {'set': this.cardDetailsFields_.setModel, 'get': this.cardDetailsFields_.getModel},
            {
                'sourceProperty': 'cardToken.card',
                'model'			: DataBindingMode.TWO_WAY
            }
        );

        this.setBinding(this.referralCodeField_, {'get': this.referralCodeField_.getValue, 'set': this.referralCodeField_.setValue}, {
            'mode'			: DataBindingMode.TWO_WAY,
            'sourceProperty': 'subscribedBillingPlan.referralCode'
        });

        this.setBinding(this.referralFieldGroup_, {'set': this.referralFieldGroup_.setVisible}, {
            'sourceProperty': 'subscribedBillingPlan.referral',
            'converter': {
                'sourceToTargetFn': function(referral) {
                    return !referral;
                }
            }
        });

        this.setBinding(this.discountNotice_, {'set': this.discountNotice_.setContent}, 'subscribedBillingPlan.referralDesc');

        this.setBinding(this.discountNotice_, {'set': this.discountNotice_.setVisible}, {
            'sources': [
                {'sourceProperty': 'subscribedBillingPlan.referral'},
                {'sourceProperty': 'subscribedBillingPlan.referralDesc'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    let referral = values[0];
                    const referralDesc = values[1];

                    return !!referral && !StringUtils.isEmptyOrWhitespace(referralDesc);
                }
            }
        });
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const translator = Translator;

        this.contentContainer.addChild(this.planName_, true);
        this.contentContainer.addChild(this.planDetails_, true);
        this.contentContainer.addChild(this.changePlanTrigger_, true);
        this.contentContainer.addChild(this.discountNotice_, true);
        this.contentContainer.addChild(this.referralFieldGroup_, true);

        this.contentContainer.addChild(this.billingAddressFields_, true);
        this.contentContainer.addChild(this.cardDetailsFields_, true);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.planName_);
        this.planName_ = null;

        BaseUtils.dispose(this.planDetails_);
        this.planDetails_ = null;

        BaseUtils.dispose(this.changePlanTrigger_);
        this.changePlanTrigger_ = null;

        this.referralCodeField_ = null;
        this.referralFieldGroup_ = null;
        this.discountNotice_ = null;
        this.billingAddressFields_ = null;
        this.cardDetailsFields_ = null;
    }

    /**
     * Handles CLICK on change plan trigger. Call view in order to display the billing plans list
     * @param {hf.events.Event} e
     * @private
     */
    handleChangePlan_(e) {
        this.dispatchEvent(new Event(PayPlanAccountStatusEventType.CHANGE_BILLING_PLAN));
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
PayPlanAccountStatus.FieldName = {
    REFERRAL_CODE   : 'billing_referral_code',

	STREET			: AddressDetails.AddressFieldNames.STREET,
	REGION			: AddressDetails.AddressFieldNames.REGION,
	POSTAL_CODE		: AddressDetails.AddressFieldNames.POSTAL_CODE,
	COUNTRY			: AddressDetails.AddressFieldNames.COUNTRY,
	CITY			: AddressDetails.AddressFieldNames.CITY,

	NAME_ON_CARD		: CardDetails.CreditCardFieldNames.NAME_ON_CARD,
	CARD_NUMBER         : CardDetails.CreditCardFieldNames.CARD_NUMBER,
	CVV					: CardDetails.CreditCardFieldNames.CVV,
	EXPIRATION_MONTH	: CardDetails.CreditCardFieldNames.EXPIRATION_MONTH,
	EXPIRATION_YEAR		: CardDetails.CreditCardFieldNames.EXPIRATION_YEAR
};