import userAgent from '../../thirdparty/hubmodule/useragent.js';
import { StringUtils } from './string.js';
/**
 *
 *
 */
export class CryptBase64Utils {
    constructor() {
        //
    }

    /**
     * Base64-encode a string.
     *
     * @param {string} input A string to encode.
     * @param {boolean=} opt_webSafe True indicates we should use the alternative
     *     alphabet, which does not require escaping for use in URLs.
     * @returns {string} The base64 encoded string.
     */
    static encodeString(input, opt_webSafe) {
        // Shortcut for browsers that implement
        // a native base64 encoder in the form of "btoa/atob"
        if (HAS_NATIVE_ENCODE_ && !opt_webSafe) {
            return window.btoa(input);
        }
        return CryptBase64Utils.encodeByteArray(
            CryptBase64Utils.stringToByteArray(input), opt_webSafe
        );
    }

    /**
     * Turns a string into an array of bytes; a "byte" being a JS number in the
     * range 0-255. Multi-byte characters are written as little-endian.
     *
     * @param {string} str String value to arrify.
     * @returns {!Array<number>} Array of numbers corresponding to the
     *     UCS character codes of each character in str.
     */
    static stringToByteArray(str) {
        const output = [];
        let p = 0;
        for (let i = 0; i < str.length; i++) {
            let c = str.charCodeAt(i);
            // NOTE: c <= 0xffff since JavaScript strings are UTF-16.
            if (c > 0xff) {
                output[p++] = c & 0xff;
                c >>= 8;
            }
            output[p++] = c;
        }
        return output;
    }

    /**
     * Base64-encode an array of bytes.
     *
     * @param {Array<number>|Uint8Array} input An array of bytes (numbers with
     *     value in [0, 255]) to encode.
     * @param {boolean=} opt_webSafe True indicates we should use the alternative
     *     alphabet, which does not require escaping for use in URLs.
     * @returns {string} The base64 encoded string.
     */
    static encodeByteArray(input, opt_webSafe) {
        if (!(Array.isArray(input) || (typeof input === 'object' && input !== null) && typeof input.length == 'number')) {
            throw new Error('encodeByteArray takes an array as a parameter');
        }

        CryptBase64Utils.init_();

        const byteToCharMap = opt_webSafe ? CryptBase64Utils.byteToCharMapWebSafe_ : CryptBase64Utils.byteToCharMap_;

        const output = [];

        for (let i = 0; i < input.length; i += 3) {
            const byte1 = input[i];
            let haveByte2 = i + 1 < input.length;
            const byte2 = haveByte2 ? input[i + 1] : 0;
            let haveByte3 = i + 2 < input.length;
            const byte3 = haveByte3 ? input[i + 2] : 0;

            const outByte1 = byte1 >> 2;
            const outByte2 = ((byte1 & 0x03) << 4) | (byte2 >> 4);
            let outByte3 = ((byte2 & 0x0F) << 2) | (byte3 >> 6);
            let outByte4 = byte3 & 0x3F;

            if (!haveByte3) {
                outByte4 = 64;

                if (!haveByte2) {
                    outByte3 = 64;
                }
            }

            output.push(
                byteToCharMap[outByte1], byteToCharMap[outByte2],
                byteToCharMap[outByte3], byteToCharMap[outByte4]
            );
        }

        return output.join('');
    }

    /**
     * Base64-decode a string.
     *
     * @param {string} input Input to decode. Any whitespace is ignored, and the
     *     input maybe encoded with either supported alphabet (or a mix thereof).
     * @param {boolean=} opt_webSafe True indicates we should use the alternative
     *     alphabet, which does not require escaping for use in URLs. Note that
     *     passing false may also still allow webSafe input decoding, when the
     *     fallback decoder is used on browsers without native support.
     * @returns {string} string representing the decoded value.
     */
    static decodeString(input, opt_webSafe) {
        // Shortcut for browsers that implement
        // a native base64 encoder in the form of "btoa/atob"
        if (HAS_NATIVE_DECODE_ && !opt_webSafe) {
            return window.atob(input);
        }
        let output = '';
        function pushByte(b) { output += String.fromCharCode(b); }

        CryptBase64Utils.decodeStringInternal_(input, pushByte);

        return output;
    }

    /**
     * @param {string} input Input to decode.
     * @param {function(number):void} pushByte result accumulator.
     * @private
     */
    static decodeStringInternal_(input, pushByte) {
        CryptBase64Utils.init_();

        let nextCharIndex = 0;
        /**
         * @param {number} default_val Used for end-of-input.
         * @returns {number} The next 6-bit value, or the default for end-of-input.
         */
        function getByte(default_val) {
            while (nextCharIndex < input.length) {
                const ch = input.charAt(nextCharIndex++);
                const b = CryptBase64Utils.charToByteMap_[ch];
                if (b != null) {
                    return b; // Common case: decoded the char.
                }
                if (!StringUtils.isEmptyOrWhitespace(ch)) {
                    throw new Error(`Unknown base64 encoding at char: ${ch}`);
                }
                // We encountered whitespace: loop around to the next input char.
            }
            return default_val; // No more input remaining.
        }

        while (true) {
            const byte1 = getByte(-1);
            const byte2 = getByte(0);
            const byte3 = getByte(64);
            const byte4 = getByte(64);

            // The common case is that all four bytes are present, so if we have byte4
            // we can skip over the truncated input special case handling.
            if (byte4 === 64) {
                if (byte1 === -1) {
                    return; // Terminal case: no input left to decode.
                }
                // Here we know an intermediate number of bytes are missing.
                // The defaults for byte2, byte3 and byte4 apply the inferred padding
                // rules per the public API documentation. i.e: 1 byte
                // missing should yield 2 bytes of output, but 2 or 3 missing bytes yield
                // a single byte of output. (Recall that 64 corresponds the padding char).
            }

            const outByte1 = (byte1 << 2) | (byte2 >> 4);
            pushByte(outByte1);

            if (byte3 != 64) {
                const outByte2 = ((byte2 << 4) & 0xF0) | (byte3 >> 2);
                pushByte(outByte2);

                if (byte4 != 64) {
                    const outByte3 = ((byte3 << 6) & 0xC0) | byte4;
                    pushByte(outByte3);
                }
            }
        }
    }

    /**
     * Lazy static initialization function. Called before
     * accessing any of the static map variables.
     *
     * @private
     */
    static init_() {
        if (!CryptBase64Utils.byteToCharMap_) {
            CryptBase64Utils.byteToCharMap_ = {};
            CryptBase64Utils.charToByteMap_ = {};
            CryptBase64Utils.byteToCharMapWebSafe_ = {};

            // We want quick mappings back and forth, so we precompute two maps.
            for (let i = 0; i < ENCODED_VALS.length; i++) {
                CryptBase64Utils.byteToCharMap_[i] =
                    ENCODED_VALS.charAt(i);
                CryptBase64Utils.charToByteMap_[CryptBase64Utils.byteToCharMap_[i]] = i;
                CryptBase64Utils.byteToCharMapWebSafe_[i] =
                    ENCODED_VALS_WEBSAFE.charAt(i);

                // Be forgiving when decoding and correctly decode both encodings.
                if (i >= ENCODED_VALS_BASE.length) {
                    CryptBase64Utils.charToByteMap_[ENCODED_VALS_WEBSAFE.charAt(i)] = i;
                }
            }
        }
    }
}

// Static lookup maps, lazily populated by init_()

/**
 * Maps bytes to characters.
 *
 * @type {object}
 * @private
 */
CryptBase64Utils.byteToCharMap_ = null;


/**
 * Maps characters to bytes. Used for normal and websafe characters.
 *
 * @type {object}
 * @private
 */
CryptBase64Utils.charToByteMap_ = null;


/**
 * Maps bytes to websafe characters.
 *
 * @type {object}
 * @private
 */
CryptBase64Utils.byteToCharMapWebSafe_ = null;


/**
 * Our default alphabet, shared between
 * ENCODED_VALS and ENCODED_VALS_WEBSAFE
 *
 * @type {string}
 */
export const ENCODED_VALS_BASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    + 'abcdefghijklmnopqrstuvwxyz'
    + '0123456789';

/**
 * Our default alphabet. Value 64 (=) is special; it means "nothing."
 *
 * @type {string}
 */
export const ENCODED_VALS = `${ENCODED_VALS_BASE}+/=`;

/**
 * Our websafe alphabet.
 *
 * @type {string}
 */
export const ENCODED_VALS_WEBSAFE = `${ENCODED_VALS_BASE}-_.`;

/**
 * Does this browser have a working btoa function?
 *
 * @private {boolean}
 */
export const HAS_NATIVE_ENCODE_ =
    (userAgent.engine.isGecko() || (userAgent.engine.isWebKit() && !userAgent.browser.isSafari()) || userAgent.browser.isOpera())
    || typeof (window.btoa) == 'function';

/**
 * Does this browser have a working atob function?
 * We blacklist known-bad implementations:
 *  - IE (10+) added atob() but it does not tolerate whitespace on the input.
 *
 * @private {boolean}
 */
export const HAS_NATIVE_DECODE_ =
    (userAgent.engine.isGecko() || (userAgent.engine.isWebKit() && !userAgent.browser.isSafari()) || userAgent.browser.isOpera())
    || (!userAgent.browser.isSafari() && !userAgent.browser.isIE()
    && typeof (window.atob) == 'function');
