import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {Caption} from "./../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Button} from "./../../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {PhoneCallPartyDetails} from "./../PhoneCallPartyDetails.js";
import {PhoneEventType} from "./../../Common.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class CallingState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.phone.PhoneCallPartyDetails}
         * @private
         */
        this.partyDetails_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.hangupBtn_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.callDetails_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-call-ringing';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        super.init(opt_config);

        const translator = Translator;

        this.hangupBtn_ = new Button({
            'extraCSSClass': 'hg-button-hangup'
        });

        this.partyDetails_ = new PhoneCallPartyDetails({
            'avatar': {
                'extraCSSClass': ['grayscheme'],
                'avatarSize' : AvatarSizes.LARGE,
                'showInfoBubble': false
            }
        });

        this.callDetails_ = new Caption({
            'contentFormatter': function (localVideo) {
                return translator.translate(localVideo ? 'video_calling' : 'audio_calling');
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.partyDetails_);
        this.partyDetails_ = null;

        BaseUtils.dispose(this.hangupBtn_);
        this.hangupBtn_ = null;

        BaseUtils.dispose(this.callDetails_);
        this.callDetails_ = null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const content = new LayoutContainer();
        content.addChild(this.partyDetails_, true);
        content.addChild(this.hangupBtn_, true);

        const footer = new LayoutContainer({'extraCSSClass': 'hg-footer'});
        footer.addChild(this.callDetails_, true);

        this.addChild(content, true);
        this.addChild(footer, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.hangupBtn_, UIComponentEventTypes.ACTION, this.handleHangup_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.callDetails_, {'set': this.callDetails_.setModel}, 'localVideo');

        this.setBinding(this.partyDetails_, {'set': this.partyDetails_.setModel}, 'party');
    }

    /**
     * Handler for call hangup
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleHangup_(e) {
        const model = this.getModel();

        if (model != null) {
            const event = new Event(PhoneEventType.HANGUP);
                event.addProperty('call', model);

            this.dispatchEvent(event);
        }

        this.dispatchEvent(PhoneEventType.HIDE_DIALER);
    }
};