import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectUtils} from "./../../../../../hubfront/phpnoenc/js/object/object.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {AbstractService} from "./AbstractService.js";
import {UserBot} from "./../model/dev/UserBot.js";
import {UserBotShort} from "./../model/dev/UserBotShort.js";
import {ActiveBot} from "./../model/dev/ActiveBot.js";
import {UserAppReport} from "./../model/dev/UserAppReport.js";
import {DevDataMapping} from "./datamapping/Dev.js";
import {DevAssetInstallationStatus} from "./../model/dev/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgAppEvents} from "./../../app/Events.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new {@see hg.data.service.BotService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class BotService extends AbstractService {
    constructor() {
        super();
    }

    /**
     * Lists all bots that can be or are installed by the authenticated user.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    loadBots(fetchCriteria) {
        if(!(fetchCriteria instanceof FetchCriteria)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('bots_load_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': DevDataMapping.UserBotShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(UserBotShort, fetchCriteria), 'bots_load_failure');
    }

    /**
     * Searches in the list of Bots by a provided criteria. Does not read platform bots.
     *
     * @param {!criteria.FetchCriteria} fetchCriteria
     * @return {Promise}
     */
    searchBots(fetchCriteria) {
        if(!(fetchCriteria instanceof FetchCriteria)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('search_bots_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'dataMapper': DevDataMapping.UserBotShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(UserBotShort, fetchCriteria), 'search_bots_failure');
    }

    /**
     * Searches for installed bots.
     *
     * @param {!criteria.FetchCriteria} searchCriteria The criteria to search items on.
     * @param {boolean=} opt_excludeDisabled True to exclude the disabled installed bots, otherwise false; default is true
     * @return {Promise}
     */
    searchTeamBots(searchCriteria, opt_excludeDisabled) {
        searchCriteria.filter({
            'filterBy'   : 'installation.status',
            'filterOp'   : FilterOperators.EQUAL_TO,
            'filterValue': DevAssetInstallationStatus.INSTALLED
        });

        opt_excludeDisabled = BaseUtils.isBoolean(opt_excludeDisabled) ? opt_excludeDisabled : true;

        if(opt_excludeDisabled) {
            searchCriteria.filter({
                'filterBy'   : 'installation.active',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': true
            });
        }

        return this.searchBots(searchCriteria);
    }

    /**
     * Loads the details of an user bot.
     *
     * @param {string} botId
     * @return {Promise}
     */
    loadBot(botId) {
        if(StringUtils.isEmptyOrWhitespace(botId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('bot_not_exists')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': DevDataMapping.UserBot,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.loadById(UserBot, botId), 'bot_not_exists');
    }

    /**
     * Use this method to install a Bot.
     *
     * @param {string} botId
     * @return {Promise}
     */
    installBot(botId) {
        if(!HgCurrentUser['isOwnerOrAdmin'] || StringUtils.isEmptyOrWhitespace(botId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('bot_install_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/install/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'botId': botId}), 'bot_install_failure');
    }

    /**
     * Use this method to remove a Bot from the team.
     *
     * @param {string} botId
     * @return {Promise}
     */
    removeBot(botId) {
        if(!HgCurrentUser['isOwnerOrAdmin'] || StringUtils.isEmptyOrWhitespace(botId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('remove_bot_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.DELETE, null, {'botId': botId}), 'remove_bot_failure');
    }

    /**
     * Use this method to suspend a Bot from the current organization.
     *
     * @param {string} botId
     * @return {Promise}
     */
    suspendBot(botId) {
        if(!HgCurrentUser['isOwnerOrAdmin'] || StringUtils.isEmptyOrWhitespace(botId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('suspend_bot_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/suspend/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'botId': botId}), 'suspend_bot_failure');
    }

    /**
     * Use this method to enable a Bot from the current organization.
     *
     * @param {string} botId
     * @return {Promise}
     */
    enableBot(botId) {
        if(!HgCurrentUser['isOwnerOrAdmin'] || StringUtils.isEmptyOrWhitespace(botId)) {
            const translator = Translator;

            return Promise.reject(new Error(translator.translate('enable_bot_failure')));
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/enable/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, {'botId': botId}), 'enable_bot_failure');
    }

    /**
     * Retrieves a short report about available bots.
     *
     * @return {Promise}
     */
    readReport() {
        if(!HgCurrentUser['isOwnerOrAdmin']) {
            return Promise.resolve(null);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/report/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(UserAppReport, {}), 'load_botsReport_failure')
            .then((result) => {return this.extractSingleQueryResult(result)});
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/bot';

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_BOT_ADD, this.handleBotAdd_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_BOT_REMOVE, this.handleBotRemove_);
    }

    /**
     *
     * @param {ObservableCollectionChangeAction} changeAction
     * @param {Array=} opt_items
     * @private
     */
    onActiveBotsChange_(changeAction, opt_items) {
        this.dispatchAppEvent(HgAppEvents.ACTIVE_BOTS_CHANGE, { 'changeAction': changeAction, 'items': opt_items || [] });
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleBotAdd_(e) {
        let userBotData = /**@type {!Object}*/(e.getPayload());

        if (userBotData != null) {
            userBotData = /**@type {!Object}*/(ObjectMapper.getInstance()
                .transform(userBotData, DevDataMapping.UserBotShort['read']));

            const userBot = new ActiveBot();
            userBot.loadData(userBotData);

            this.onActiveBotsChange_(ObservableCollectionChangeAction.ADD, [userBot]);
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleBotRemove_(e) {
        const payload = /**@type {!Object}*/(e.getPayload());

        const botId = ObjectUtils.getPropertyByPath(payload, 'botId');

        this.onActiveBotsChange_(ObservableCollectionChangeAction.REMOVE, [botId]);
    }
};
/**
 * Standard error codes thrown by HG JSON-RPC services
 * @enum {string}
 */
export const BotServiceErrorCode = {
    /** Bot installed but the management endpoint did not respond properly */
    INSTALL_ENDPOINT_NOT_WORKING: 'BOT_INSTALLED_UNCON_BOTURI'
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new BotService();

export default instance;