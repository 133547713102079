/**
 * @enum {boolean}
 * @readonly
 */
export const RosterFilterTopics = {
    SHOW_ALL: false,

    SHOW_NONE: true
};

/**
 * @enum {boolean}
 * @readonly
 */
export const RosterFilterVisitors = {
    SHOW_ACTIVE: false,

    SHOW_NONE: true
};

/**
 * @enum {boolean}
 * @readonly
 */
export const RosterFilterBots = {
    SHOW_ACTIVE: false,

    SHOW_NONE: true
};

/**
 * Enum with roster states
 * Roster is the engine of the chat, it commands the thread and the toolbar regions
 * Basically, it checks connection and availability of parties to talk to and emits STATE_CHANGED app events to the other regions
 * in order to display specific busy reasons
 * @enum {string}
 */
export const RosterState = {
    /* the roster is loading */
    LOADING : 'loading',

    /** An error occurred when loading the roster */
    ERROR: 'error',

    /** Chat is active */
    ACTIVE: 'active',

    /** There are no parties to talk to, neither roster items or topics */
    NO_PARTY: 'no_party'
};