import {Label} from "./../../label/Label.js";
import {DataModelField} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Phone contact model
 * @extends {Label}
 * @unrestricted 
*/
export class Phone extends Label {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'uid';
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();

        /*  */
        this.addField({'name': 'uid', 'type': DataModelField.PredefinedTypes.STRING});

        /* the actual value of the field */
        this.addField({'name': 'isInternal', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }
};