import {UIComponentEventTypes, UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {
    List,
    ListEventType,
    ListItemsLayout,
    ListLoadingState
} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Popup, PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Selector} from "./../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {ObservableObject} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {HTML5MediaEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/media/Enums.js";
import {PhoneBusyContext, PhoneEventType} from "./../Common.js";
import {WebPhone} from "./../component/WebPhone.js";
import {DevicePhone} from "./../component/DevicePhone.js";
import {
    PhoneCallFlow,
    PhoneCallSide,
    PhoneCallStatus,
    PhoneCallTransferState,
    PhoneExtensionAgentDeviceTypes
} from "./../../../data/model/phonecall/Enums.js";
import {Dialer} from "./../component/Dialer.js";
import {PopupFastBounceIn} from "./../../../common/ui/fx/PopupSlowBounceIn.js";
import {UIUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/Common.js";
import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {AbstractPhone} from "./../component/AbstractPhone.js";
import {KeypadEventType} from "./../component/dialer/Keypad.js";
import {PhoneDialerTab} from "./../component/dialer/Enums.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class PhoneGeneralView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.phone.WebPhone}
         * @private
         */
        this.webPhone_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.activePhoneList_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.otherPhoneList_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.otherPhonesTriggerBtn_;

        /**
         * @type {HTMLElement}
         * @private
         */
        this.arrowOtherPhonesTrigger_;

        /**
         * @type {hf.ui.popup.Popup}
         * @protected
         */
        this.otherPhonePanel_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.incomingBeep_ = this.incomingBeep_ === undefined ? null : this.incomingBeep_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.outgoingRinging_ = this.outgoingRinging_ === undefined ? null : this.outgoingRinging_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.incomingRinging_ = this.incomingRinging_ === undefined ? null : this.incomingRinging_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.callBusy_ = this.callBusy_ === undefined ? null : this.callBusy_;

        /**
         * @type {HTMLMediaElement}
         * @private
         */
        this.hangup_ = this.hangup_ === undefined ? null : this.hangup_;

        /**
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.dialer_ = this.dialer_ === undefined ? null : this.dialer_;
    }

    /**
     * Set video ratio depending on device orientation
     * @param {number} ratio
     */
    setVideoRatio(ratio) {
        if (this.webPhone_ != null) {
            this.webPhone_.setVideoRatio(ratio);
        }
    }

    /**
     * Play short beep sound for incoming calls to remote device phones (rpc)
     */
    playBeepSound() {
        UIUtils.play(this.incomingBeep_);
    }

    /**
     * Play hangup sound, use different sound when an the interlocutor you are calling is busy
     * @param {boolean=} opt_isBusy
     */
    playHangupSound(opt_isBusy) {
        UIUtils.play(!!opt_isBusy ? this.callBusy_ : this.hangup_);
    }

    /**
     *
     * @param {PhoneCallSide} side
     */
    startRingingSound(side) {
        UIUtils.play(side === PhoneCallSide.REMOTE ? this.outgoingRinging_ : this.incomingRinging_);
    }

    /**
     *
     * @param {PhoneCallSide} side
     */
    stopRingingSound(side) {
        if (side === PhoneCallSide.REMOTE) {
            this.outgoingRinging_.pause();
        } else {
            this.incomingRinging_.pause();
        }
    }

    /**
     * Open by default other phones panel when webPhone in otherPhones
     */
    openOtherPhonePanel() {
        const panel = this.getOtherPhonePanel_();
        if (panel != null) {
            panel.open();
        }
    }

    /**
     * Open dialer if not already for webPhone outgoing calls
     * E.g.: calls given from person contact bubble
     * @param {!hg.data.model.phonecall.PhoneExtension} extension
     * @param {PhoneDialerTab=} opt_readyModeFilter
     */
    openDialerFor(extension, opt_readyModeFilter) {
        if (this.dialer_ != null && !this.dialer_.isOpen()) {
            if (extension['agentDevice'] == PhoneExtensionAgentDeviceTypes.WEB) {
                /* check if placement is provided */
                const placement = PopupPlacementMode.BOTTOM_RIGHT,
                    target = this.webPhone_,
                    phone = this.webPhone_.getModel() != null ? this.webPhone_.getModel()['phone'] : null,
                    call = phone.get('activeCall') || null;

                /* detach from old target if any */
                const oldTarget = this.dialer_.getPlacementTarget();
                if (oldTarget != null && oldTarget !== target) {
                    this.detachDialerFromPhone_();
                }

                /* set new placement */
                this.dialer_.setPlacement(placement);
                this.dialer_.setPlacementTarget(/** @type {hf.ui.UIComponent} */(target));

                /* viewmodel updates */
                const model = this.getModel();
                if (model) {
                    const dialerViewmodel = /** @type {hg.module.phone.viewmodel.DialerViewmodel} */(this.getModel()['dialer']);

                    if (call != null) {
                        dialerViewmodel['call'] = call;
                    }

                    dialerViewmodel['phone'] = phone;
                    if(opt_readyModeFilter) {
                        dialerViewmodel['readyModeFilter'] = opt_readyModeFilter;
                    }
                }

                if (target instanceof AbstractPhone) {
                    this.attachDialerToPhone_();
                }

                this.dialer_.open();
            }/* else {

            }*/
        }
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-phone';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.dialer_ = new Popup({
            'content'               : new Dialer(),
            'placement'             : PopupPlacementMode.BOTTOM_RIGHT,        
            'extraCSSClass'         : ['hg-popup', 'hg-dialer-popup', 'grayscheme'],
            'showArrow'             : true,
            'staysOpenOnMouseWheel' : true,
            'horizontalOffset'      : 5,
            'verticalOffset'        : 5,
            'openAnimation'         : {
                'type': PopupFastBounceIn
            }
        });
        /* The Popup must accept the FOCUS state in order to be closed using the ESC key */
        this.dialer_.setSupportedState(UIComponentStates.FOCUSED, true);

        this.dialer_.setParentEventTarget(this);

        /* making sure we treat this event specifically, not received from other popups managed */
        this.dialer_.addListener(UIComponentEventTypes.CLOSE, (e) => { return this.handleDialerClose_(e); });

        this.activePhoneList_ = new List({
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter'  : (phone, listItem) => {
                if (phone == null) {
                    return null;
                }

                const model = /** @type {hg.module.phone.viewmodel.PhoneAggregatorViewmodel} */(this.getModel()),
                    viewmodel = new ObservableObject({
                        'phone': phone,
                        'dialer': null
                    });

                const control = new UIControl();
                    listItem.setBinding(control, {'set': control.setContent}, {
                        'source'            : /** @type {hg.module.phone.viewmodel.PhoneViewmodel} */(phone),
                        'sourceProperty'    : 'extension.agentDevice',
                        'converter'         : {
                            'sourceToTargetFn': (agentDevice) => {
                                const isWebPhone = (agentDevice === PhoneExtensionAgentDeviceTypes.WEB);

                                if (isWebPhone) {
                                    this.webPhone_ = new WebPhone({'model': viewmodel});
                                    return this.webPhone_;
                                } else {
                                    return new DevicePhone({'model': viewmodel});
                                }
                            }
                        }
                    });

                return control;
            },
            'emptyContentFormatter' : () => {
                return translator.translate("no_records");
            },
            'errorFormatter': function(error) {
                return error['message'] != null ? error['message'] : error;
            }
        });

        this.otherPhoneList_ = new List({
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'itemContentFormatter'  : (phone, listItem) => {
                if (phone == null) {
                    return null;
                }

                const model = /** @type {hg.module.phone.viewmodel.PhoneAggregatorViewmodel} */(this.getModel()),
                    viewmodel = new ObservableObject({
                        'phone': phone,
                        'dialer': null
                    });

                const control = new UIControl();
                    listItem.setBinding(control, {'set': control.setContent}, {
                        'source'            : /** @type {hg.module.phone.viewmodel.PhoneViewmodel} */(phone),
                        'sourceProperty'    : 'extension.agentDevice',
                        'converter'         : {
                            'sourceToTargetFn': (agentDevice) => {
                                const isWebPhone = (agentDevice === PhoneExtensionAgentDeviceTypes.WEB);

                                if (isWebPhone) {
                                    this.webPhone_ = new WebPhone({'model': viewmodel});
                                    return this.webPhone_;
                                } else {
                                    return new DevicePhone({'model': viewmodel});
                                }
                            }
                        }
                    });

                return control;
            },
            'emptyContentFormatter' : () => {
                return translator.translate("no_records");
            },
            'errorFormatter': function(error) {
                return error['message'] != null ? error['message'] : error;
            }
        });

        this.otherPhonesTriggerBtn_ = new UIControl({
            'baseCSSClass'  : 'hg-other-phones-trigger',
            'hidden'        : true,
            'disabled'      : true
        });

        this.arrowOtherPhonesTrigger_ = DomUtils.createDom('span', 'hg-other-phones-arrow');
    }

    /**
     * Update container visibility with animation when microphone or ext no change
     * @param {boolean} visible
     */
    // onVisibilityChange_(visible) {
    //     const region = this.getParent();
    //
    //     if (region && region.isInDocument()) {
    //         region.getElement().style.maxHeight = visible ? '475px' : '0px';
    //     }
    // }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        /* HG-8596: we are not interested in otherPhones existence, we check only activePhones */
        // this.setBinding(this, {'set': this.onVisibilityChange_}, {
        //     'sources': [
        //         {
        //             'source': HgCurrentUser,
        //             'sourceProperty': 'hasMicrophone'
        //         },
        //         {
        //             'sourceProperty': 'activePhones'
        //         }
        //     ],
        //     'converter'     : {
        //         'sourceToTargetFn': function (sources) {
        //             if (sources == null) {
        //                 return false;
        //             }
        //
        //             const hasMicrophone = !!sources[0],
        //                 activePhonesCount = sources[1] != null ? sources[1].getCount() : 0;
        //
        //             return hasMicrophone && activePhonesCount > 0;
        //         }
        //     }
        // });

        this.setBinding(this.activePhoneList_, {'set': this.activePhoneList_.setItemsSource}, 'activePhones');
        this.setBinding(this.otherPhoneList_, {'set': this.otherPhoneList_.setItemsSource}, 'otherPhones');

        /* allow otherPhones panel closing only for initialized web phones if in otherPhones */
        this.setBinding(this.otherPhonesTriggerBtn_, {'set': this.otherPhonesTriggerBtn_.setEnabled}, {
            'sources': [
                {'sourceProperty': 'otherPhones'},
                {'sourceProperty': 'webPhone'},
                {'sourceProperty': 'webPhone.status'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const otherPhones = sources[0] != null ? sources[0].getAll() : [],
                        webPhone = sources[1],
                        status = sources[2];

                    const webPhoneInitialized = !otherPhones.includes(webPhone) || status != null,
                        remotePhoneActive = otherPhones.find(function (phone) {
                            return phone['activeCall'] != null && phone['activeCall']['status'] == PhoneCallStatus.PRE_DIALING;
                        });

                    return webPhoneInitialized && remotePhoneActive == null;
                }
            }
        });

        this.setBinding(this, {'set': this.onOtherPhonesCountChange_}, 'otherPhonesTotalCount');

        this.setBinding(this.otherPhonesTriggerBtn_, {'set': this.otherPhonesTriggerBtn_.setContent}, {
            'sources': [
                {'sourceProperty': 'otherPhonesTotalCount'},
                {'sourceProperty': 'otherPhonesCount'}
            ],
            'converter': {
                'sourceToTargetFn': (sources) => {
                    const count = sources[1] || 0,
                        totalCount = sources[0] || 0;

                    const content = document.createDocumentFragment();

                    if (totalCount - count > 0) {
                        content.appendChild(DomUtils.createDom('span', 'hg-other-phones-unregistered'));
                    }
                    const countString = count ? translator.translate('other_device_available', [count]) : translator.translate('no_device_available');
                    content.appendChild(document.createTextNode(countString));
                    content.appendChild(this.arrowOtherPhonesTrigger_);

                    return content;
                }
            }
        });

        /* dialer requires readyFilter and activeCall but receives the entire viewmodel because
         * readyFilter must be changed from dialer and be sensed in the exterior by the availablePhoneContent */
        const dialerContent = /** @type {Object} */(this.dialer_.getContent());
        if (dialerContent != null) {
            this.setBinding(dialerContent, {'set': dialerContent.setModel}, 'dialer');
            this.setBinding(this, {'set': this.onDialerCloseTransition_}, 'dialer.closeTransition');
        }

        this.setBinding(this.dialer_, {'set': this.dialer_.enableStayingOpen}, {
            'sources': [
                {'sourceProperty': 'dialer.call.flow'},
                {'sourceProperty': 'dialer.call.status'},
                {'sourceProperty': 'dialer.call.transferState'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    const status = sources[1],
                        flow = sources[0];
                    let transfer = sources[2];

                    let staysOpen = false;
                    switch (status) {
                        case PhoneCallStatus.ONCALL:
                            /* agenda for transferring to party, or final transferring state */
                            staysOpen = transfer == PhoneCallTransferState.IN_PROGRESS ? true : false;
                            break;

                        case PhoneCallStatus.PRE_DIALING:
                            staysOpen = true;
                            break;

                        case PhoneCallStatus.RINGING:
                        case PhoneCallStatus.ANSWERING:
                        case PhoneCallStatus.DIALING:
                            if (flow == PhoneCallFlow.IN) {
                                /* agenda for transferring to party, or final transferring state */
                                staysOpen = !!transfer ? true : false;
                            } else {
                                staysOpen = true;
                            }
                            break;

                        case PhoneCallStatus.ENDED:
                        default:
                            staysOpen = false;
                            break;
                    }

                    return staysOpen;
                }
            }
        });
    }

    /**
     * Update visibility of control and trigger reflow event to adjust chat on resize
     * @param {number} totalCount
     */
    onOtherPhonesCountChange_(totalCount) {
        if (totalCount == null) {
            return;
        }

        const visible = totalCount > 0,
            previousVisibility = this.otherPhonesTriggerBtn_.isVisible();

        this.otherPhonesTriggerBtn_.setVisible(visible);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.activePhoneList_, true);
        this.addChild(this.otherPhonesTriggerBtn_, true);

        this.incomingRinging_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'ringtone','src': "resources/sound/ringtone.mp3"}));
        this.outgoingRinging_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'ringbacktone', 'loop': true, 'src': "resources/sound/ringbacktone.mp3"}));
        this.incomingBeep_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'beeptone', 'loop': false, 'src': "resources/sound/beeptone.mp3"}));
        this.callBusy_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'callbusy', 'loop': false, 'src': "resources/sound/callbusy.mp3"}));
        this.hangup_ = /** @type {HTMLMediaElement} */(DomUtils.createDom('audio', {'id': 'hangup', 'loop': false, 'src': "resources/sound/hangup.mp3"}));

        if(!userAgent.engine.isEdge()){
            this.incomingRinging_.setAttribute('loop', true);
        }
        /* create dialer dom to set in other phones staysOpenWhenClicking array of elements */
        this.dialer_.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* insert them in body to avoid loosing ongoing sound on window resize */
        const doc = document;
            doc.body.appendChild(this.incomingRinging_);
            doc.body.appendChild(this.incomingBeep_);
            doc.body.appendChild(this.outgoingRinging_);
            doc.body.appendChild(this.callBusy_);
            doc.body.appendChild(this.hangup_);

        this.getHandler()
            .listen(this.otherPhonesTriggerBtn_, UIComponentEventTypes.ACTION, this.handleOtherPhonePanelToggle_)

            .listen(this.otherPhoneList_, ListEventType.LOADING_STATE_CHANGED, this.handleOtherPhoneListChange_)
            .listen(this.activePhoneList_, ListEventType.LOADING_STATE_CHANGED, this.handlePhoneListChange_)

            .listen(this, PhoneEventType.ACKNOWLEDGE_ERR, this.handleErrAcknowledge_)
            .listen(this, PhoneEventType.RETRY_INITIALIZATION, this.handleInitializationRetryRequest_)
            .listen(this, PhoneEventType.SERVICE_REVIEW, this.handleServiceReview_)
            .listen(this, PhoneEventType.FORCE_REGISTRATION, this.handleForceRegistrationRequest_)

            /* dial requests can come from dialer but also from incoming panel in case of transfer */
            .listen(this, PhoneEventType.DIAL, this.handleDialRequest_)

            .listen(this, PhoneEventType.TRANSFER_TO_PARTY, this.handleTransferRequest_)
            .listen(this, PhoneEventType.TRANSFER_TO_VOICEMAIL, this.handleTransferToVoicemailRequest_)
            .listen(this, PhoneEventType.HANGUP, this.handleCallHangup_)
            .listen(this, PhoneEventType.ANSWER, this.handleCallAnswer_)
            .listen(this, [PhoneEventType.HOLD, PhoneEventType.UNHOLD], this.handleCallHold_)
            .listen(this, [PhoneEventType.RECORDING_ON, PhoneEventType.RECORDING_OFF], this.handleCallRecording_)
            .listen(this, [PhoneEventType.VIDEO_ON, PhoneEventType.VIDEO_OFF], this.handleVideoToggle_)
            .listen(this, [PhoneEventType.HIDE_DIALER, PhoneEventType.SHOW_DIALER], this.handleDialerToggle_)
            .listen(this, PhoneEventType.BACK_DIALER, this.handleDialerBackRequest_)
            .listen(this, KeypadEventType.KEYPRESS, this.handleDtmf_)

            .listen(this, PhoneEventType.CALL_ECHO_TEST, this.handleEchoTest_)

            .listen(this, PhoneEventType.FOLLOW_INCOMING, this.handleFollowIncoming_);

            if(userAgent.engine.isEdge()) {
                this.getHandler().listen(this.incomingRinging_, HTML5MediaEventTypes.ENDED, this.handleEdgeIncomingRinging_);
            }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.dialer_.exitDocument();

        if (this.otherPhonePanel_ != null) {
            this.otherPhonePanel_.exitDocument();
        }

        if (this.incomingRinging_ && this.incomingRinging_.parentNode) {
            this.incomingRinging_.parentNode.removeChild(this.incomingRinging_);
        }
        if (this.incomingBeep_ && this.incomingBeep_.parentNode) {
            this.incomingBeep_.parentNode.removeChild(this.incomingBeep_);
        }
        if (this.outgoingRinging_ && this.outgoingRinging_.parentNode) {
            this.outgoingRinging_.parentNode.removeChild(this.outgoingRinging_);
        }
        if (this.callBusy_ && this.callBusy_.parentNode) {
            this.callBusy_.parentNode.removeChild(this.callBusy_);
        }
        if (this.hangup_ && this.hangup_.parentNode) {
            this.hangup_.parentNode.removeChild(this.hangup_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.incomingRinging_ = null;
        this.incomingBeep_ = null;
        this.outgoingRinging_ = null;
        this.callBusy_ = null;
        this.hangup_ = null;
        this.arrowOtherPhonesTrigger_ = null;

        BaseUtils.dispose(this.dialer_);
        delete this.dialer_;

        if (this.otherPhonePanel_ != null) {
            BaseUtils.dispose(this.otherPhonePanel_);
            delete this.otherPhonePanel_;
        }

        BaseUtils.dispose(this.webPhone_);
        this.webPhone_ = null;

        BaseUtils.dispose(this.activePhoneList_);
        this.activePhoneList_ = null;

        BaseUtils.dispose(this.otherPhoneList_);
        this.otherPhoneList_ = null;

        BaseUtils.dispose(this.otherPhonesTriggerBtn_);
        this.otherPhonesTriggerBtn_ = null;
    }

    /**
     * @return {hf.ui.popup.Popup}
     * @private
     */
    getOtherPhonePanel_() {
        if (this.otherPhonePanel_ == null) {
            this.otherPhonePanel_ = new Popup({
                'content'               : this.otherPhoneList_,
                'placement'             : PopupPlacementMode.TOP_RIGHT,
                'placementTarget'       : this.arrowOtherPhonesTrigger_,
                //'disallowedEdges'       : [ViewportEdges.LEFT],
                'extraCSSClass'         : ['hg-popup', 'hg-other-phone-popup'],
                'showArrow'             : true,
                //'staysOpen'             : true,
                'staysOpenWhenClicking' : [this.otherPhonesTriggerBtn_.getElement(), this.dialer_.getElement()],
                'staysOpenOnMouseWheel' : true,
                'verticalOffset'        : -20,
                'horizontalOffset'      : 9,
                'processStrictOverflow' : true
            });

            this.otherPhonePanel_.setParentEventTarget(this);

            /* todo: there should be an event thrown by the popup to avoid so many bindings
             * or  we can bind on dialer_.staysOpen */
            this.setBinding(this.otherPhonePanel_, {'set': this.otherPhonePanel_.enableStayingOpen}, {
                'sources': [
                    {'sourceProperty': 'dialer.call.flow'},
                    {'sourceProperty': 'dialer.call.status'},
                    {'sourceProperty': 'dialer.call.transferState'},
                    {'sourceProperty': 'dialer.phone'},
                    {'sourceProperty': 'otherPhones'}
                ],
                'converter': {
                    'sourceToTargetFn': function (sources) {
                        if (sources == null) {
                            return false;
                        }

                        const dialerPhone = /** @type {hg.module.phone.viewmodel.PhoneViewmodel} */(sources[3]),
                            otherPhones = /** @type {hf.structs.CollectionView} */(sources[4]);

                        /* check if dialer is opened for a phone in other phones panel */
                        const match = otherPhones.find(function (otherPhone) {
                            return dialerPhone == otherPhone;
                        });
                        if (match == null) {
                            return false;
                        }

                        const status = sources[1],
                            flow = sources[0];
                        let transfer = sources[2] == PhoneCallTransferState.IN_PROGRESS;

                        let staysOpen = false;
                        switch (status) {
                            case PhoneCallStatus.ONCALL:
                                /* agenda for transferring to party, or final transferring state */
                                staysOpen = !!transfer ? true : false;
                                break;

                            case PhoneCallStatus.PRE_DIALING:
                                staysOpen = true;
                                break;

                            case PhoneCallStatus.RINGING:
                            case PhoneCallStatus.DIALING:
                                if (flow == PhoneCallFlow.IN) {
                                    /* agenda for transferring to party, or final transferring state */
                                    staysOpen = !!transfer ? true : false;
                                } else {
                                    staysOpen = true;
                                }
                                break;

                            case PhoneCallStatus.ENDED:
                            default:
                                staysOpen = false;
                                break;
                        }

                        return staysOpen;
                    }
                }
            })
        }

        return this.otherPhonePanel_;
    }

    /**
     * Attach dialer to phone
     * @private
     */
    attachDialerToPhone_() {
        const phone = /** @type {hg.module.phone.AbstractPhone} */(this.dialer_.getPlacementTarget()),
            model = phone.getModel();

        if (model != null) {
            model['dialer'] = this.getModel()['dialer'];
        }

        return;
    }

    /**
     * Detach dialer to phone
     * @private
     */
    detachDialerFromPhone_() {
        const phone = /** @type {hg.module.phone.AbstractPhone} */(this.dialer_.getPlacementTarget()),
            model = phone.getModel();

        if (model != null) {
            model['dialer'] = null;
        }

        /* make sure there is no button checked (keypad, contacts button) */
        if (phone instanceof Selector) {
            phone.clearSelection();
        }

        return;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case PhoneBusyContext.INITIALIZE:
            case PhoneBusyContext.NO_REMOTE_VIDEO:
                if (this.webPhone_ != null) {
                    this.webPhone_.setBusy(enable, opt_busyContext);
                }
                break;
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, contextErr) {
        if(contextErr && contextErr['context']) {
            switch (contextErr['context']) {
                case PhoneBusyContext.NO_WEBRTC_SUPPORT:
                    if (this.webPhone_ != null) {
                        this.webPhone_.setHasError(enable, contextErr);
                    }
                    break;

                default:
                    break;
            }
        } else {
            //hg.module.phone.view.PhoneGeneralView.superClass_.enableHasErrorBehavior.call(this, enable, contextErr);
        }
    }

    /**
     * Handles acknowledgement of fatal error: no webrtc, blacklist, etc...
     * @param {hf.events.Event} e
     * @private
     */
    handleErrAcknowledge_(e) {
        const context = /** @type {PhoneBusyContext} */(e.getProperty('context')),
            presenter = this.getPresenter();

        switch (context) {
            //case PhoneBusyContext.NO_AUDIO_DEVICE:
            case PhoneBusyContext.MEDIA_BLACKLIST:
                let discard = !!e.getProperty('discard');
                if (!discard) {
                    presenter.retryMediaAccess();
                } else {
                    presenter.acknowledgeError(context);
                }
                break;

            default:
                presenter.acknowledgeError(context);
                break;
        }
    }

    /**
     * Handles another phone init retry
     * @param {hf.events.Event} e
     * @private
     */
    handleInitializationRetryRequest_(e) {
        this.getPresenter().retryWebPhoneInit();
    }

    /**
     * Handles another phone init retry
     * @param {hf.events.Event} e
     * @private
     */
    handleServiceReview_(e) {
        this.getPresenter().reviewServicePermission();
    }

    /**
     * Handles force registration
     * @param {hf.events.Event} e
     * @private
     */
    handleForceRegistrationRequest_(e) {
        this.getPresenter().forceRegistration();
    }

    /**
     * Handles dial request
     * @param {hf.events.Event} e
     * @private
     */
    handleDialRequest_(e) {
        const extension = e.getProperty('extension'),
            party = e.getProperty('party'),
            video = e.getProperty('video') || false,
            fromContext = e.getProperty('fromContext');

        if (extension != null && party != null) {
            this.getPresenter().call(party, extension, video, undefined, fromContext);
        }
    }

    /**
     * Handles call hangup
     * @param {hf.events.Event} e
     * @private
     */
    handleCallHangup_(e) {
        const call = e.getProperty('call');
        if (call != null) {
            this.getPresenter().hangup(call);
        }
    }

    /**
     * Handles call hangup
     * @param {hf.events.Event} e
     * @private
     */
    handleCallAnswer_(e) {
        const call = e.getProperty('call'),
            video = e.getProperty('video') || false;
        if (call != null) {
            this.getPresenter().answer(call, video);
        }
    }

    /**
     * Handles call hold
     * @param {hf.events.Event} e
     * @private
     */
    handleCallHold_(e) {
        const call = e.getProperty('call'),
            enable = e.getType() == PhoneEventType.HOLD ? true : false;

        if (call != null) {
            this.getPresenter().setHoldEnabled(call, enable);
        }
    }

    /**
     * Handles call recording
     * @param {hf.events.Event} e
     * @private
     */
    handleCallRecording_(e) {
        const call = e.getProperty('call'),
            enable = e.getType() == PhoneEventType.RECORDING_ON ? true : false;
        if (call != null) {
            this.getPresenter().setRecordingEnabled(call, enable);
        }
    }

    /**
     * Handles call transfering
     * @param {hf.events.Event} e
     * @private
     */
    handleTransferRequest_(e) {
        const call = e.getProperty('call'),
            party = e.getProperty('party');
        if (call != null) {
            this.getPresenter().transfer(call, party);
        }
    }

    /**
     * Handles call transfering to voicemail
     * @param {hf.events.Event} e
     * @private
     */
    handleTransferToVoicemailRequest_(e) {
        const call = e.getProperty('call');
        if (call != null) {
            this.getPresenter().transferToVoicemail(call);
        }
    }

    /**
     * Handles call hangup
     * @param {hf.events.Event} e
     * @private
     */
    handleVideoToggle_(e) {
        const call = e.getProperty('call');
        if (call != null) {
            this.getPresenter().setVideoEnabled(call, e.getType() == PhoneEventType.VIDEO_ON);
        }
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleFollowIncoming_(e) {
        const call = e.getProperty('call');
        if (call != null) {
            this.getPresenter().followIncomingCall(call);
        }
    }

    /**
     * Handles echo test. Call presenter in order to call to *52
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleEchoTest_(e) {
        if(this.dialer_) {
            this.dialer_.close();
        }

        this.getPresenter().callEchoTest();
    }

    /**
     * Handler for back dialer event
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDialerBackRequest_(e) {
        const model = /** @type {hg.module.phone.viewmodel.PhoneAggregatorViewmodel} */(this.getModel());

        if (model != null) {
            const dialerViewModel = model['dialer'];

            /* cleanup rejectedCall */
            if (dialerViewModel['phone'] != null) {
                if (dialerViewModel.get('phone.activeCall') != null && dialerViewModel.get('phone.activeCall.status') == PhoneCallStatus.ENDED) {
                    dialerViewModel['phone'].set('activeCall', null, true);
                }
            }

            dialerViewModel['call'] = null;
            dialerViewModel['closeTransition'] = false;

            const fromContext = e.getProperty('fromContext');
            const contextParts = fromContext.split(':');
            if (contextParts.length == 2) {
                dialerViewModel['readyModeFilter'] = contextParts[0];
                dialerViewModel['filterValue'] = contextParts[1];
            } else {
                dialerViewModel['readyModeFilter'] = fromContext;
            }
        }
    }

    /**
     * Handler for show/hide dialer event
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDialerToggle_(e) {
        if (e.getType() == PhoneEventType.HIDE_DIALER) {
            const content = this.dialer_.getContent();
            if (content != null) {
                const model = content.getModel();
                if (model != null && model.get('call.transferState') == PhoneCallTransferState.BEGIN) {
                    model.set('call.transferState', PhoneCallTransferState.NONE);
                }
            }

            this.dialer_.close();
        }
        else if (!this.dialer_.isOpen()) {
            /* check if placement is provided */
            const placement = e.getProperty('placement') || PopupPlacementMode.BOTTOM_RIGHT,
                zIndex = e.getProperty('zIndex'),
                target = e.getProperty('placementTarget'),
                hOffset = e.getProperty('horizontalOffset') || 5,
                phone = e.getProperty('phone'),
                call = e.getProperty('call') || (phone != null ? phone.get('activeCall') : null),
                readyModeFilter = e.getProperty('readyModeFilter') || PhoneDialerTab.CONTACTS;

            /* detach from old target if any */
            const oldTarget = this.dialer_.getPlacementTarget();
            if (oldTarget != null && oldTarget !== target) {
                this.detachDialerFromPhone_();
            }

            /* set new placement */
            this.dialer_.setPlacement(placement);
            this.dialer_.setPlacementTarget(/** @type {hf.ui.UIComponent} */(target));
            this.dialer_.setStaysOpenWhenClicking([/** @type {hf.ui.UIComponent} */(target).getElement()]);
            this.dialer_.setHorizontalOffset(/** @type {number} */(hOffset));

            /* viewmodel updates */
            const model = this.getModel();
            if (model) {
                const dialerViewmodel = /** @type {hg.module.phone.viewmodel.DialerViewmodel} */(model['dialer']);

                if (call != null) {
                    dialerViewmodel['call'] = call;
                }

                dialerViewmodel['readyModeFilter'] = readyModeFilter;
                dialerViewmodel['phone'] = phone;
            }

            if (target instanceof AbstractPhone) {
                this.attachDialerToPhone_();
            }

            this.dialer_.open();

            if(zIndex != null) {
                this.dialer_.setStyle('zIndex', /**@type {number}*/(zIndex));
            }
        }
    }

    /**
     * @param {boolean} isCloseTransition
     * @private
     */
    onDialerCloseTransition_(isCloseTransition) {
        if (isCloseTransition) {
            this.dialer_.close();
        }
    }

    /**
     * Reset rejectedCall, clear binding on target for button set: readyModeFilter
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDialerClose_(e) {
        if(e.getTarget() != this.dialer_) {
            return;
        }

        const model = /** @type {hg.module.phone.viewmodel.PhoneAggregatorViewmodel} */(this.getModel());

        if (model != null) {
            const dialerViewModel = model['dialer'];

            /* cleanup rejectedCall */
            if (dialerViewModel['phone'] != null) {
                if (dialerViewModel.get('phone.activeCall') != null && dialerViewModel.get('phone.activeCall.status') == PhoneCallStatus.ENDED) {
                    dialerViewModel['phone']['activeCall'] = null;
                }
            }

            /* reset attached target */
            dialerViewModel['phone'] = null;
            dialerViewModel['call'] = null;
            dialerViewModel['closeTransition'] = false;
            dialerViewModel['readyModeFilter'] = null;
            dialerViewModel['searchContactValue'] = null;
        }

        /* clear binding on the old target */
        this.detachDialerFromPhone_();
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handlePhoneListChange_(e) {
        const currentState = e.getProperty('currentState');
        if (currentState == ListLoadingState.READY) {

            /* reposition dialer and incoming call panels */
            if (this.dialer_ != null && this.dialer_.isOpen()) {
                this.dialer_.reposition();
            }

            /* reposition other phone panel */
            if (this.otherPhonePanel_ != null && this.otherPhonePanel_.isOpen()) {
                this.otherPhonePanel_.reposition();
            }
        }
    }

    /**
     * Close panel when list is empty
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleOtherPhoneListChange_(e) {
        const currentState = e.getProperty('currentState');
        if (currentState == ListLoadingState.READY) {
            const model = this.getModel();

            if (model['otherPhones'] != null && model['otherPhones'].getCount() == 0) {
                this.otherPhonePanel_.close();
            }
        }
    }

    /**
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleOtherPhonePanelToggle_(e) {
        const panel = this.getOtherPhonePanel_();
        panel.isOpen() ? panel.close() : panel.open();
    }

    /**
     * Handler keypad keypress event
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleDtmf_(e) {
        const dtmf = e.getProperty('dtmf');
        if (!StringUtils.isEmptyOrWhitespace(dtmf)) {
            this.getPresenter().sendDtmf(dtmf);
        }
    }

    /**
     * HG-15283
     * @private
     */
    handleEdgeIncomingRinging_() {
        this.incomingRinging_.currentTime = 0;
        UIUtils.play(this.incomingRinging_);
    }
};