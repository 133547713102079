import { Event } from '../../events/Event.js';
import { ObjectUtils } from '../../object/object.js';

/**
 * Creates a new AppEvent.
 *
 * @augments {Event}
 *
 */
export class AppEvent extends Event {
    /**
     * @param {string} type Event Type.
     * @param {object=} opt_payload Optional parameter representing the event Payload.
     *
     */
    constructor(type, opt_payload) {
        super(type);

        /**
         * Represents the event payload.
         *
         * @type {object}
         * @private
         */
        this.payload_ = opt_payload || {};
    }

    /**
     * Gets the event payload.
     *
     * @returns {object}
     */
    getPayload() {
        const payload = this.payload_;

        return Array.isArray(payload)
            ? [...payload]
            : ObjectUtils.isPlainObject(payload)
                ? { ...payload }
                : payload;
    }

    /**
     * Adds a payload entry.
     *
     * @param {string} key
     * @param {*} value
     * @returns {void}
     */
    addPayloadEntry(key, value) {
        this.payload_[key] = value;
    }

    /**
     * Gets a payload entry.
     *
     * @param {string} key
     * @returns {*}
     */
    getPayloadEntry(key) {
        return this.payload_[key];
    }
}
