import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {UIComponentEventTypes, UIComponentHideMode} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {
    List,
    ListEventType,
    ListItemsLayout,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {HgUIEventType} from "./../../../common/ui/events/EventType.js";
import {MessageItemContent} from "./MessageItemContent.js";
import {ReplyMessageItemContent} from "./ReplyMessageItemContent.js";
import {MessageThread} from "./MessageThread.js";
import {EditableMessage} from "./../../../data/model/message/EditableMessage.js";
import {RepliesThreadViewmodel} from "./../viewmodel/RepliesThread.js";
import {NewMessageButton} from "./../../../common/ui/button/NewMessageButton.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {EventType as ScrollHandlerEventType} from "./../../../../../../hubfront/phpnoenc/js/events/ScrollHandler.js";

/**
 * Creates a new {@see TeamTopicView} oview.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class TeamTopicView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Messages list
         * @type {hf.ui.list.List}
         * @private
         */
        this.messageList_ = this.messageList_ === undefined ? null : this.messageList_;

        /**
         *
         * @type {hf.ui.Button}
         * @private
         */
        this.newMessageBtn_ = this.newMessageBtn_ === undefined ? null : this.newMessageBtn_;

        /**
         *
         * @type {number}
         * @private
         */
        this.newMessagesCount_ = this.newMessagesCount_ === undefined ? 0 : this.newMessagesCount_;
    }

    /**
     * Scrolls to the last message.
     * @param {boolean=} opt_force True to scroll to the last message no matter what.
     * If false, the messages list will scroll to the last message ONLY if the scroll is near the last message.
     */
    scrollToLastMessage(opt_force) {
        opt_force = !!opt_force;

        if (opt_force || this.messageList_.getScroller().isScrollAtTheTop()) {
            this.messageList_.getScroller().scrollToTop();
        } else {
            this.dispatchMediaViewportResizeEvent_();
        }
    }

    /**
     *
     * @param {*} rootMessage
     * @param {Object} opt_replyMessageData
     */
    goToMessage(rootMessage, opt_replyMessageData) {
        setTimeout(() => {
            if (rootMessage) {
                const messagesList = this.messageList_,
                    masterListItem = this.messageList_.getUIItemFromDataItem(rootMessage);

                if (masterListItem) {
                    /* if the target message is the root message itself */
                    if (opt_replyMessageData == null) {
                        this.messageList_.scrollItemIntoViewport(masterListItem, true);

                        masterListItem.removeExtraCSSClass('isNavigationTarget');
                        setTimeout(() => {
                            masterListItem.addExtraCSSClass('isNavigationTarget');
                        }, 20);
                    } else {
                        /* if the target message is a reply */
                        const masterMessageItemContent = masterListItem.getChildAt(0);
                        if (masterMessageItemContent) {
                            const messageThreadVM = masterMessageItemContent.getModel();
                            if (messageThreadVM) {
                                messageThreadVM.targetMessage(opt_replyMessageData['messageId'])
                                    .then((message) => {
                                        if (message) {
                                            const replyListItem = masterMessageItemContent.getMessageItemFromMessageDataItem(message);
                                            if (replyListItem) {
                                                messagesList.scrollItemIntoViewport(replyListItem, true);

                                                replyListItem.removeExtraCSSClass('isNavigationTarget');
                                                setTimeout(() => {
                                                    replyListItem.addExtraCSSClass('isNavigationTarget');
                                                }, 20);
                                            }
                                        }
                                    });
                            }
                        }
                    }
                }
            }
        }, 20);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        /* initialize people list */
        this.messageList_ = new List({
            'itemsLayout': ListItemsLayout.VSTACK,
            'extraCSSClass': 'hg-team-board-message-list',
            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE,
            'itemContentFormatter': function (message) {
                return message ?
                    new MessageThread({
                        'rootMessageItemType': MessageItemContent,
                        'messageItemType': ReplyMessageItemContent,
                        'model': new RepliesThreadViewmodel({
                            'context': {
                                'resourceId': message['inThread']['resourceId'],
                                'resourceType': message['inThread']['resourceType']
                            },
                            'resourceLink': {
                                'resourceId': message['messageId'],
                                'resourceType': HgResourceCanonicalNames.MESSAGE
                            },
                            'resource': message,
                            'editMessageType': EditableMessage,
                            'rootMessage': message,
                            'messageCount': message['thread']['count']
                        })
                    })
                    : null;
            },
            'emptyContentFormatter': function () {
                return new Caption({
                    'content': translator.translate("no_updates")
                });
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            /* hide mode must be set in order to use standard view loader without interfering with the meta data*/
            'hideMode': UIComponentHideMode.VISIBILITY,
            'scroller': {
                'canScrollToHome': true
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.messageList_ = null;
        this.newMessageBtn_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-team-board-thread-list';
    }

    /**
     * @inheritDoc
     */
    createDom() {
        super.createDom();

        this.addChild(this.messageList_, true);
        this.addChild(this.getNewMessageButton(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.messageList_.getScrollHandler(), ScrollHandlerEventType.SCROLL_AWAY, this.handleScrollawayEvent_)
            .listen(this.messageList_, HgUIEventType.FILE_PREVIEW_TOGGLE, this.handleFilePreviewToggle_)
            .listen(this.messageList_, ListEventType.LOADING_STATE_CHANGED, this.handleMessageListChange_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.messageList_, {'set': this.messageList_.setItemsSource}, 'messages');
        this.setBinding(this, {'set': this.onNewMessage}, 'thread.thread.lastMessage');
    }

    /**
     * Processes a new message.
     * @param {Message} newMessage
     * @protected
     */
    onNewMessage(newMessage) {
        if (newMessage != null && newMessage['isNewlyAdded']) {
            if (!newMessage['isMine']) {
                /* display scroll to last message shortcut if is not already at the last message */
                this.getNewMessageButton().setModel(newMessage);
            }
        }
    }

    /**
     *
     * @return {hf.ui.Button}
     * @protected
     */
    getNewMessageButton() {
        if (this.newMessageBtn_ == null) {
            this.newMessageBtn_ = new NewMessageButton({
                'extraCSSClass': ['arrow-bottom-to-top'],
                'scrollHandler': this.messageList_.getScrollHandler(),
                'scrollDirection': 'top',
                'tooltip': {
                    'content': Translator.translate("see_last_update"),
                    'showArrow': true,
                    'autoHide': true,
                    'placement': PopupPlacementMode.TOP_MIDDLE
                }
            });
        }

        return this.newMessageBtn_;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleScrollawayEvent_(e) {
        const scrollaway = e.getProperty('scrollaway');

        this.dispatchMediaViewportResizeEvent_();
    }

    /**
     * HG-5639: Handle history load to dispatch media viewport change event so that media cleans up source if necessary
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleMessageListChange_(e) {
        const currentState = e.getProperty('currentState');
        if (currentState == ListLoadingState.READY) {
            this.dispatchMediaViewportResizeEvent_();
        }
    }

    /**
     * Handle media file preview toggle
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePreviewToggle_(e) {
        this.dispatchMediaViewportResizeEvent_();
    }

    /**
     * @private
     */
    dispatchMediaViewportResizeEvent_() {
        /* simulate a media viewport resize event to allow media to pause if required */
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport': this.messageList_.getElement()
        });
    }
}