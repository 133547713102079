import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";

import {HgAppConfig} from "./../../../app/Config.js";

import {HgAppEvents} from "./../../../app/Events.js";
import {Facet} from "./../../../data/model/common/Facet.js";
import {SearchFacet} from "./../../../data/model/common/SearchFacet.js";
import {CommonFacetSelectionTypes, CommonFacetViewmodel} from "./../viewmodel/Facet.js";
import {AppDataGlobalKey} from "./../../../data/model/appdata/Enums.js";
import {BasePresenter} from "./BasePresenter.js";
import AppDataService from "./../../../data/service/AppDataService.js";
import Scheduler from "./../../../data/service/Scheduler.js";

/**
 * Creates a new Facet presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class AbstractFacetPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         * AppData service reference
         * @type {hg.data.service.AppDataService}
         * @protected
         */
        this.appDataService;

        /**
         * @type {Object|undefined}
         * @private
         */
        this.defaultFacet_;

        /**
         *
         * @type {Promise}
         * @private
         */
        this.initDataPromise_;

        /**
         * @type {number?}
         * @private
         */
        this.reloadDynamicFacetsTask_;

        /**
         * @type {boolean}
         * @private
         */
        this.isDataInitialized_ = this.isDataInitialized_ === undefined ? false : this.isDataInitialized_;

        /**
         *
         * @type {boolean}
         * @private
         */
        this.isLoadingDynamicFacets_ = this.isLoadingDynamicFacets_ === undefined ? false : this.isLoadingDynamicFacets_;

        /**
         * Indicates whether a request for reloading the dynamic facets was made
         * while the reload process was suspended
         * @type {boolean}
         * @private
         */
        this.needsReload_ = this.needsReload_ === undefined ? false : this.needsReload_;

        /**
         * @type {boolean}
         * @private
         */
        this.isDynamicFacetsReloadEnabled_ = this.isDynamicFacetsReloadEnabled_ === undefined ? false : this.isDynamicFacetsReloadEnabled_;
    }

    /**
     * Set a task for dynamic facet reload on predefined time range
     * Demanded on dynamic facets panel open
     * @param {boolean} enable
     */
    enableDynamicFacetsReload(enable) {
        if(this.isDynamicFacetsReloadEnabled_ === enable) {
            return;
        }

        this.isDynamicFacetsReloadEnabled_ = enable;

        const scheduler = Scheduler;

        if(!this.reloadDynamicFacetsTask_) {
            this.reloadDynamicFacetsTask_ = scheduler.addTask(this.loadDynamicFacetsAsync.bind(this), HgAppConfig.REFRESH_FACET, true);
        }

        if(enable) {
            if(this.needsReload_) {
                this.loadDynamicFacetsAsync();
            }
            else {
                scheduler.enableTask(this.reloadDynamicFacetsTask_, true);
            }
        }
        else {
            scheduler.enableTask(this.reloadDynamicFacetsTask_, false);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    isDynamicFacetsReloadEnabled() {
        return this.isDynamicFacetsReloadEnabled_;
    }

    /**
     * Resets the current facet to the default one.
     */
    reset() {
        const model = this.getModel();
        if(model) {
            model.set('currentFacet', undefined, true);
            model.set('currentFacet', this.getDefaultFacet());
        }
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.appDataService = AppDataService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.appDataService = null;

        if(this.reloadDynamicFacetsTask_) {
            const scheduler = Scheduler;

            scheduler.removeTask(this.reloadDynamicFacetsTask_);
        }
        this.reloadDynamicFacetsTask_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        const model = this.getModel();
        if(model != null) {
            model['selectionType'] = this.getSelectionTypeFromAppState(currentAppState);
        }
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /* Listen to filter selection (facets) */
        this.getHandler()
            .listen(eventBus, HgAppEvents.REQUEST_FACET, this.handleFacetRequest_)
            .listen(eventBus, HgAppEvents.SEARCH, this.handleSearchRequest_)
            .listen(eventBus, HgAppEvents.RESET_FACET, this.reset)
            .listen(eventBus, HgAppEvents.APP_CONTEXT_CHANGE, this.handleAppContextChange_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_);
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        if(model != null) {
            this.getModelEventHandler()
                .listen(/** @type {hf.events.Listenable} */ (model), ObservableChangeEventName, this.handleModelInternalChange_);
        }
    }

    /**
     * @return {Object|undefined}
     * @protected
     */
    getDefaultFacet() {
        if(this.defaultFacet_ == null) {
            const staticFacets = this.loadStaticFacet(),
                defaultSelection = this.getDefaultSelection();

            this.defaultFacet_ = staticFacets.find(function(facet) { return facet['uid'] == defaultSelection; });
        }

        return this.defaultFacet_;
    }

    /**
     * @return {Object}
     * @protected
     */
    createModel() {
        return new CommonFacetViewmodel({
            'staticFacets'      : this.loadStaticFacet(),
            'dynamicFacets'     : new ListDataSource({
                'dataProvider' : this.loadDynamicFacet.bind(this),
                'localGroupers': [{
                    'groupBy': function(facet) {
                        return {
                            'target': facet['target'],
                            'category': facet['category']
                        }
                    }
                }]
            })
        });
    }

    /**
     * Load the model based on the state of the app
     * @return {Promise}
     * @protected
     */
    loadModel() {
        this.isDataInitialized_ = false;

        const model = this.createModel();
        model['isReady'] = false;

        this.setModel(model);

        return this.initDataPromise_ = this.loadAppData()
            .then((result) => {
                result = result || {};
                result[AppDataGlobalKey.FACET] = result[AppDataGlobalKey.FACET] || this.getDefaultFacet();
                result[AppDataGlobalKey.SEARCH] = result[AppDataGlobalKey.SEARCH] || null;

                return result;
            })
            .catch((err) => {
                return {
                    [AppDataGlobalKey.FACET] : this.getDefaultFacet(),
                    [AppDataGlobalKey.SEARCH] : null
                }
            })
            .then((result) => {
                /* NOTE: on errback the defaults are used */
                let currentFacet = this.getDefaultFacet(),
                    currentSearch = null;

                if(result) {
                    currentFacet = result[AppDataGlobalKey.FACET] || this.getDefaultFacet();
                    currentSearch = result[AppDataGlobalKey.SEARCH];
                }

                /* fix the legacy issues */
                currentSearch = currentSearch != null && currentSearch.hasOwnProperty('uid') ? currentSearch : null;

                model.set('currentFacet', currentFacet);
                model.set('currentSearch', currentSearch);
                model.set('selectionType', this.getSelectionTypeFromAppState(this.getState()));
                model.set('isReady', true);

                this.isDataInitialized_ = true;
                this.initDataPromise_ = null;
            })
            .finally(() => {
                const model = this.getModel(),
                    selectionType = model != null ? model['selectionType'] : null;

                 if(selectionType === CommonFacetSelectionTypes.SEARCH && model['currentSearch'] == null) {
                     model['selectionType'] = CommonFacetSelectionTypes.FACET;
                 }
            });
    }

    /**
     * @protected
     */
    loadAppData() {
        return this.appDataService.loadAppDataParams(this.getCategory())
            .then((appDataParams) => {
                const filterCriteria = {};
                appDataParams.forEach(function(param) {
                    filterCriteria[param['key']] = param['value'];
                });

                return filterCriteria;
            });
    }

    /**
     *
     * @return {boolean}
     * @protected
     */
    isActive() {
        const currentState = this.getState();

        return currentState != null && this.isListState(currentState) || this.isSearchState(currentState);
    }

    /**
     * Asynchronously load dynamic facets.
     * @param {boolean=} opt_force
     * @protected
     */
    loadDynamicFacetsAsync(opt_force) {
        const model = this.getModel();

        if(!this.isActive() || (!this.isDynamicFacetsReloadEnabled_ && !opt_force)) {
            // indicate that a refresh must be done when the 'reload' process will be resumed
            this.needsReload_ = true;

            if(model['dynamicFacets']) {
                /**@type {hf.data.ListDataSource}*/(model['dynamicFacets']).clear();
            }

            return Promise.resolve();
        }

        const scheduler = Scheduler;

        if(this.reloadDynamicFacetsTask_){
            scheduler.enableTask(this.reloadDynamicFacetsTask_, false);
        }

        this.isLoadingDynamicFacets_ = true;

        // reload dynamic facets
        model['dynamicFacets'].invalidate()
            .then(() => this.isLoadingDynamicFacets_ = false);

        if(this.isDynamicFacetsReloadEnabled_) {
            scheduler.enableTask(this.reloadDynamicFacetsTask_, true);
        }

        this.needsReload_ = false;
    }

    /**
     * @param {boolean=} opt_force
     * @protected
     */
    applyCurrentFilter(opt_force) {
        if(this.isBusy() || !this.isActive() || !this.isDataInitialized_) {
            return;
        }

        const model = this.getModel(),
            selectionType = model != null ? model['selectionType'] : null;

        if(selectionType == CommonFacetSelectionTypes.SEARCH_RESULT) {
            return;
        }

        /* if no selection then the default filter is currentFacet; this cover the use cases like the following:
        * - the Edit/View Panel for person is opened,
        * - Refresh,
        * - The Edit/View Panel must be opened after refresh and behind it must be the list of people on which is applied the current filter criteria */
        const facet = selectionType == CommonFacetSelectionTypes.SEARCH ? model ['currentSearch'] : model ['currentFacet'];

        if(selectionType != null && facet != null) {
            // /* navigate to the corresponding app state */
            // this.navigateTo(selectionType == CommonFacetSelectionTypes.FACET ?
            //     this.getListState() : this.getSearchState()
            // );

            this.saveCurrentFilter(facet);
        }

        /* Instructs the listeners to apply the current filter criteria: facet filter or search filter */
        setTimeout(() => this.dispatchEvent(HgAppEvents.APPLY_FACET, { 'facet': facet, 'reset': !!opt_force}));
        //this.dispatchEvent(hg.HgAppEvents.APPLY_FACET, { 'facet': facet });
    }

    /**
     *
     * @param {Object} currentFilter
     */
    saveCurrentFilter(currentFilter) {
        const model = this.getModel(),
            selectionType = model != null ? model['selectionType'] : null;

        if(currentFilter != null && selectionType != null) {
            currentFilter = currentFilter instanceof Facet ? /**@type {hg.data.model.common.Facet}*/(currentFilter).toJSONObject() : currentFilter;

            /* update app param */
            this.appDataService.updateAppDataParam(
                this.getCategory(),
                selectionType == CommonFacetSelectionTypes.FACET ? AppDataGlobalKey.FACET : AppDataGlobalKey.SEARCH,
                currentFilter,
                false,
                false);
        }
    }

    /**
     *
     * @param {hf.app.state.AppState} appState
     * @protected
     */
    getSelectionTypeFromAppState(appState) {
        return this.isListState(appState) ? CommonFacetSelectionTypes.FACET :
            this.isSearchState(appState) ? CommonFacetSelectionTypes.SEARCH :
                this.isSearchResultState(appState) ? CommonFacetSelectionTypes.SEARCH_RESULT: null;
    }

    /**
     * Check if this is a search state
     * Necessary for determining the action for a new applied filter:
     * - pass event on EventBus
     * - change state (facets and search are mutually exclusive)
     *
     * @param {hf.app.state.AppState | undefined} state State to check
     * @return {boolean} Return true if this is a search state, false otherwise
     *
     * @protected
     */
    isListState(state) {
        return state != null && state.getName() == this.getListState();
    }

    /**
     * Check if this is a search state
     * Necessary for determining the action for a new applied filter:
     * - pass event on EventBus
     * - change state (facets and search are mutually exclusive)
     *
     * @param {hf.app.state.AppState | undefined} state State to check
     * @return {boolean} Return true if this is a search state, false otherwise
     *
     * @protected
     */
    isSearchState(state) {
        return state != null && state.getName() == this.getSearchState();
    }

    /**
     *
     * @param {hf.app.state.AppState | undefined} state State to check
     * @return {boolean} Return true if this is a search result details state, false otherwise
     *
     * @protected
     */
    isSearchResultState(state) {
        return false;
    }

    /**
     * Returns the category under which the currently applied facet is saved
     * @return {string}
     * @protected
     */
    getCategory() { throw new Error('unimplemented abstract method'); }

    /**
     * Returns the default selection used when no option is provided in the AppData
     * or when clearing other selections
     * @return {string}
     * @protected
     */
    getDefaultSelection() { throw new Error('unimplemented abstract method'); }

    /**
     * Loads static facet
     * @return {Array.<hg.data.model.common.Facet>}
     * @protected
     */
    loadStaticFacet() { throw new Error('unimplemented abstract method'); }

    /**
     * Loads dynamic facet
     * @return {Promise}
     * @protected
     */
    loadDynamicFacet() { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the name of the search state.
     *
     * @return {hg.HgAppStates} The name of the search state.
     *
     * @protected
     */
    getSearchState() { throw new Error('unimplemented abstract method'); }

    /**
     * Gets the name of the list state.
     *
     * @return {hg.HgAppStates} The name of the list state.
     *
     * @protected
     */
    getListState() { throw new Error('unimplemented abstract method'); }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleModelInternalChange_(e) {
        if(e && e['payload'] && this.isDataInitialized_) {
            const payload = e['payload'];

            if(payload['field'] == 'selectionType'
                || payload['field'] == 'currentFacet'
                || payload['field'] == 'currentSearch') {

                const model = this.getModel(),
                    selectionType = model != null ? model['selectionType'] : null;

                if(selectionType != null) {
                    /* navigate to the corresponding app state */
                    if(selectionType == CommonFacetSelectionTypes.FACET
                    || selectionType == CommonFacetSelectionTypes.SEARCH) {
                        this.navigateTo(selectionType == CommonFacetSelectionTypes.FACET ?
                            this.getListState() : this.getSearchState()
                        );
                    }

                    this.applyCurrentFilter();
                }
            }
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleFacetRequest_(e) {
        if(this.initDataPromise_ != null) {
            this.initDataPromise_.finally(() => {
                /* NOTE: on errback the defaults are used */
                this.applyCurrentFilter();
            });
        }
        else {
            this.applyCurrentFilter();
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleSearchRequest_(e) {
        if(this.isActive()) {
            const model = this.getModel(),
                searchCriteria = e.getPayload();

            model['currentSearch'] = new SearchFacet({
                'target': this.getDefaultFacet()['target'],
                'filter': {
                    'filter': searchCriteria['filters'],
                    'search': searchCriteria['searchValue'],
                    'quick' : searchCriteria['isQuickSearch']
                }
            });

            /* force the entering into the SEARCH state; usually the selectionType is automatically set to SEARCH
            * when the current search is changed EXCEPT when the current search is the same with the new search criteria */
            if(model['selectionType'] != CommonFacetSelectionTypes.SEARCH) {
                model['selectionType'] = CommonFacetSelectionTypes.SEARCH;
            }
        }
    }

    /**
     *
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleAppContextChange_(e) {
        const payload = e.getPayload() || {};

        if (payload['oldContext'] != null && payload['newContext'] != null) {
            if (payload['oldContext'] == payload['newContext']) {
                this.reset();
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        /* reload the model */
        this.loadModel().then((result) => {
            this.applyCurrentFilter(true);
        });
    }
};
//hf.app.ui.IPresenter.addImplementation(hg.common.ui.presenter.AbstractFacetPresenter);

/**
 * Busy state reasons (display different busy indicators on each reason)
 * @enum {number}
 */
AbstractFacetPresenter.BusyContext = {
    /* loading dynamic facets */
    LOAD_DYNAMIC: 1
};