import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {DropDownList} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {List, ListItemsLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {Scroller} from "./../../../../../../hubfront/phpnoenc/js/ui/scroll/Scroller.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import {ImportTaskPersonCSVMappingTo} from "./../../../data/model/import/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new object representing an item from the people list.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ChooseMappingImport extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The title of this import step
         * @type {hf.ui.Caption}
         * @private
         */
        this.checkMappingTitle_;

        /**
         * The list of mappings
         * @type {hf.ui.list.List}
         * @private
         */
        this.mappingsList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getDefaultBaseCSSClass();

        this.checkMappingTitle_ = HgCaptionUtils.createTitle(translator.translate('check_fields_mapping'), '', baseCSSClass + '-title');

        this.mappingsList_ = new List({
            'extraCSSClass'	: baseCSSClass + '-mapping-list',
            'itemsLayout'	: ListItemsLayout.VSTACK,
            'itemContentFormatter': (model, listItem) => {
                if (model == null) {
                    return null;
                }

                const mappingItemContent = new UIComponent();

                const mappingToDropDownList = new DropDownList({
                    'itemsSource': Object.values(ImportTaskPersonCSVMappingTo),
                    'itemContentFormatter': function (personCSVMappingTo) {
                        return translator.translate(personCSVMappingTo);
                    },
                    'itemStyle': function (value) {
                        if (value === ImportTaskPersonCSVMappingTo.IGNORE) {
                            return 'import-ignore';
                        }

                        return '';
                    }
                });

                listItem.setBinding(mappingToDropDownList,
                    {'set': mappingToDropDownList.setValue, 'get': mappingToDropDownList.getValue},
                    {
                        'sourceProperty': 'to',
                        'mode'			: DataBindingMode.TWO_WAY
                    });

                listItem.setBinding(mappingToDropDownList,
                    {
                        'set': function(isDuplicated) {
                            const extraDuplicatedCSSClass = 'hg-import-mapping-duplicated-item';

                            isDuplicated ? this.addExtraCSSClass(extraDuplicatedCSSClass) : this.removeExtraCSSClass(extraDuplicatedCSSClass);
                        }
                    },
                    'isDuplicated'
                );

                listItem.setBinding(mappingToDropDownList,
                    {
                        'set': function(to) {
                            const extraIgnoreCSSClass = "import-ignore";

                            to == ImportTaskPersonCSVMappingTo.IGNORE ?
                                this.addExtraCSSClass(extraIgnoreCSSClass) : this.removeExtraCSSClass(extraIgnoreCSSClass);
                        }
                    },
                    'to'
                );

                const mappingLabel = new Caption({
                    'extraCSSClass': 'hg-import-mapping-fieldname',
                    'contentFormatter': function (model) {
                        return model;
                    },
                    'ellipsis': true,
                    'showTooltipWithEllipsis': true,
                    'tooltip': {
                        'showDelay': 200,
                        'verticalOffset': -36,
                        'placement': PopupPlacementMode.CENTER,
                        'extraCSSClass': ['grayscheme', 'hg-tooltip', 'hg-import-mapping-label-tooltip'],
                        'showArrow': true,
                        'contentFormatter': function (model) {
                            return model != null ? DomUtils.createDom('span', '', model) : null;
                        }
                    }
                });
                listItem.setBinding(mappingLabel, { 'set': mappingLabel.setModel }, 'preview');

                mappingItemContent.addChild(mappingLabel, true);
                mappingItemContent.addChild(new UIComponent( {
                    'baseCSSClass'	: 'hg-import-mapping-tomarker'
                }), true);
                mappingItemContent.addChild(mappingToDropDownList, true);

                return mappingItemContent;
            },
            'itemStyle': function (model) {
                return model ? model['cssClass'] : [];
            }
        });
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-import-choose-mapping-form';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const contentContainer = new VerticalStack(),
            scroller = new Scroller();

        scroller.setContent(contentContainer);

        contentContainer.addChild(this.checkMappingTitle_, true);
        contentContainer.addChild(this.mappingsList_, true);

        this.addChild(scroller, true);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.mappingsList_, {'set': this.mappingsList_.setItemsSource},
            'csvMapping'
        );
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.checkMappingTitle_);
        delete this.checkMappingTitle_;

        BaseUtils.dispose(this.mappingsList_);
        delete this.mappingsList_;
    }
};