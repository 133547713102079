import {FilterOperators} from "./../../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {CollectionView} from "./../../../../../../hubfront/phpnoenc/js/structs/collectionview/CollectionView.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {Share} from "./../../../data/model/share/Share.js";
import {ShareCollection} from "./../../../data/model/share/ShareCollection.js";
import {RecipientSelectorViewmodel} from "./RecipientSelector.js";
import {ShareChanges} from "./../../../data/model/share/ShareChanges.js";
import {ResourceShareActions, ResourceShareGranteeTypes} from "./../../../data/model/share/Enums.js";

import {HgCurrentUser} from "./../../../app/CurrentUser.js";
import {ShareRecipientSearchResult} from "./../../../data/model/share/RecipientSearchResult.js";
import {HgResourceAccessLevels} from "./../../../data/model/resource/Enums.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import LookupService from "./../../../data/service/LookupService.js";
import ShareService from "../../../data/service/ShareService.js";

/**
 * The type of the async operation.
 * @enum {string}
 * @readonly
 */
export const ResourceShareAsyncOperationTypes = {
    LOAD_SHARE_DATA: StringUtils.createUniqueString('__hg_common_ui_viewmodel_share_async_operation_type_load_share_data'),

    UPDATE_SHARE:  StringUtils.createUniqueString('__hg_common_ui_viewmodel_share_async_operation_type_update_share')
};

/**
 * @extends {RecipientSelectorViewmodel}
 * @unrestricted
*/
export class ResourceShareViewmodel extends RecipientSelectorViewmodel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         *
         * @type {Promise}
         * @private
         */
        this.loadShareDataPromise_ = this.loadShareDataPromise_ === undefined ? null : this.loadShareDataPromise_;
    }

    /**
     * Adds a new {@see hg.data.model.share.Share} to the collection of shares.
     * @param {hg.data.model.party.RecipientBaseSearchResult} recipientCandidate
     */
    addRecipient(recipientCandidate) {
        if (recipientCandidate) {
            const share = new Share({
                'resource': {
                    'resourceId': this.getResourceId(),
                    'resourceType': this['resourceType']
                },
                'sharedBy': {
                    'authorId': HgPersonUtils.ME,
                    'type': AuthorType.USER
                },
                'grantee': {
                    'granteeId': recipientCandidate['resourceId'],
                    'type': recipientCandidate['resourceType'],
                    'name': recipientCandidate['name'],
                    'avatar': recipientCandidate['avatar']
                }
            });

            this.addShareInternal_(share);
        }
    }

    /**
     * Removes a {@see hg.data.model.share.Share} object from the collection of shares.
     * @param {hg.data.model.share.Share} share
     */
    removeRecipient(share) {
        if (!(share instanceof  Share)) {
            throw new Error('Invalid share object to remove.');
        }

        this.removeShareInternal_(share);
    }

    /**
     * Updates the access level for a share.
     * @param {hg.data.model.share.Share} share
     * @param {HgResourceAccessLevels} accessLevel
     */
    updateAccessLevel(share, accessLevel) {
        if (!(share instanceof Share)) {
            throw new Error('Invalid share object to update.');
        }

        this.updateAccessLevelInternal_(share, accessLevel);
    }

    /**
     * Returns true if any change was made to the Resource's share.
     * @return {boolean}
     */
    isDirty() {
        return this.hasValue('shareChanges') && (/**@type {hg.data.model.share.ShareChanges}*/(this['shareChanges'])).isDirty();
    }

    /**
     * Returns true if any change was made to the Resource's share and all the changes submit to the validation rules.
     * @returns {boolean}
     */
    isSavable() {
        return this.hasValue('shareChanges') && (/**@type {hg.data.model.share.ShareChanges}*/(this['shareChanges'])).isSavable();
    }

    /**
     * Commits the changes made to the Resource's share.
     * @return {Promise}
     */
    updateShare() {
        return this.executeAsync(
            // async op
            this.updateShareInternal_,

            // callback
            null,

            // errback
            null,

            // operation context
            ResourceShareAsyncOperationTypes.UPDATE_SHARE
        );
    }

    /**
     * Discard the changes made to the Resource's share.
     */
    discardChanges() {
        this.loadShareDataPromise_ = null;

        this.resetFieldValue('isLoadingShareData', true);

        this.resetFieldValue('shareChanges', true);
        this.resetFieldValue('shareResult', true);
        this.resetFieldValue('shares', true);
        this.resetFieldValue('selectedRecipients', true);

        /* Reset the default fields */
        this.resetFieldValue('busyContext', true);
        this.resetFieldValue('isBusy', true);
        this.resetFieldValue('errorContext', true);
        this.resetFieldValue('error', true);
    }

    /** @inheritDoc */
    init(opt_initData) {
        opt_initData = opt_initData || {};

        super.init(opt_initData);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /**
     * @inheritDoc
     * @suppress {visibility}
     */
    defineFields() {
        super.defineFields();

        /* isLoadingShareData */
        this.addField({'name': 'isLoadingShareData', 'value': false});

        /* resourceId - the id of the resource to share*/
        this.addField({'name': 'resourceId', 'value': null});

        /* resourceType - The type of the resource to share */
        this.addField({'name': 'resourceType', 'value': null});

        /* resource - The resource itself (the data of the resource); this may not be provided */
        this.addField({'name': 'resource', 'getter': this.createLazyGetter('resource', function() {
            if(!this.hasValue('resource') && !this['isLoadingShareData']) {
                this.loadShareData();
            }

            return this.getInternal('resource');
        })});

        /* isResourceLoaded */
        this.addField({'name': 'isResourceLoaded', 'getter': function() {
            return this.hasValue('resource') && this.getFieldValue('resource') != null;
        }});

        /* ownedByMe - I (i.e. the Current User ) am the author of the resource */
        this.addField({'name': 'ownedByMe', 'getter': this.createLazyGetter('ownedByMe',
            function() {
                if (this['isResourceLoaded']) {
                    return HgPersonUtils.isMe(this.get('resource.author.authorId'));
                }

                return false;
            })
        });

        /* isLoadingShares */
        this.addField({'name': 'isLoadingShares', 'value': false});

        /* private shares */
        this.addField({'name': 'shares', 'getter': this.createLazyGetter('shares',
            function () {
                if(!this.hasValue('shares')) {
                    // silently set the field value; there is no need to notify it changed
                    this.setInternal(
                        'shares',
                        new ShareCollection(),
                        true
                    );

                    if(!this['isLoadingShareData']) {
                        this.loadShareData();
                    }
                }

                return this.getInternal('shares');
            })
        });

        /* shareChanges - stores the current changes (uncommited) to the Resource's chare */
        this.addField({'name': 'shareChanges', 'getter': this.createLazyGetter('shareChanges',
            function () {
                if(!this.hasValue('shareChanges')) {
                    const shareChanges = new ShareChanges({
                        'resource': {
                            'resourceId': this.getResourceId(),
                            'resourceType': this['resourceType']
                        }
                    });
                    shareChanges.acceptChanges(true);

                    // silently set the field value; there is no need to notify it changed
                    this.setInternal('shareChanges', shareChanges, true);

                    if(!this['isLoadingShareData']) {
                        this.loadShareData();
                    }
                }

                return this.getInternal('shareChanges');
            })
        });

        /* shareResult - stores the share result */
        this.addField({'name': 'shareResult'});
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName === 'shareResult') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'resource') {
            this.set('ownedByMe', undefined);
        }

        if(fieldName === 'shares') {
            this.resetFieldValue('selectedRecipients');
        }
    }

    /** @inheritDoc */
    createSelectedRecipientsCollection() {
        return this['shares'] != null ?
            new CollectionView({
                'source': this['shares'],
                'sorters': [
                    {
                        'comparator': function (share1, share2) {
                            let share1_isNew = share1.isNew() && !share1['isAuthor'],
                                share2_isNew = share2.isNew() && !share2['isAuthor'];

                            // if (share1_isNew && share2_isNew) {
                            //     return DateUtils.compare(share1['created'], share2['created']);
                            // }

                            if (!share1_isNew && !share2_isNew) {
                                if (share1['isAuthor']) {
                                    return 1;
                                }

                                if (share2['isAuthor']) {
                                    return -1;
                                }

                                return share1['grantee']['type'] === ResourceShareGranteeTypes.PUBLIC ?
                                    1 : share2['grantee']['type'] === ResourceShareGranteeTypes.PUBLIC ? -1 : 0;
                            }

                            return share1_isNew ? 1 : share2_isNew ? -1 : 0;
                        },
                        'direction': SortDirection.DESC
                    }
                ]
            })
            : null;
    }

    /** @inheritDoc */
    getSelectedRecipient(recipientId) {
        return this['shares'].getShare(recipientId);
    }

    /** @inheritDoc */
    filterOutExistingRecipient(dataItem) {
        const existingRecipient = this.getSelectedRecipient(dataItem['recipientId']);

        dataItem['isSelected'] = existingRecipient != null;

        /* detect whether the share is new or not... */
        dataItem['selectionIsOld'] = existingRecipient != null && (!existingRecipient.isNew() || existingRecipient['isAuthor']);

        dataItem['accessLevel'] = existingRecipient != null ? existingRecipient['isAuthor'] ? 'OWNER' : existingRecipient['grantee']['accessLevel'] : undefined;

        return true;
    }

    /** @inheritDoc */
    searchForRecipients(searchCriteria) {
        /* exclude @me from results if I'm not a owner or admin OR if I'm the author of the resource */
        if(!HgCurrentUser['isOwnerOrAdmin'] || this['ownedByMe'] || this['resource'].isNew()) {
            searchCriteria.filter({
                'filterBy'   : 'recipientId',
                'filterOp'   : FilterOperators.NOT_EQUAL_TO,
                'filterValue': '@me'
            });
        }

        return super.searchForRecipients(searchCriteria);
    }

    /** @inheritDoc */
    searchForRecipientsInternal(searchCriteria) {
        const lookupService = LookupService;

        if(lookupService) {
            return lookupService.search(searchCriteria, ShareRecipientSearchResult);
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    loadShareData() {
        if(this.loadShareDataPromise_ != null) {
            return this.loadShareDataPromise_;
        }

        this['isLoadingShareData'] = true;

        this.loadShareDataPromise_ = this.executeAsync(
            // async op
            this.loadShareDataInternal,

            // callback
            function(result) {
                return result;
            },

            // errback
            function(err) {
                return err;
            },

            // operation context
            ResourceShareAsyncOperationTypes.LOAD_SHARE_DATA
        );

        this.loadShareDataPromise_
            .finally(() => this['isLoadingShareData'] = false);

        return this.loadShareDataPromise_;
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    loadShareDataInternal() {
        return Promise.all([this.loadResourceData(), this.loadShares()]);
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    loadResourceData() {
        return this.loadResourceDataAsync()
            .then((result) => { return this.onResourceDataLoaded(result) });
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    loadResourceDataAsync() {
        return Promise.resolve(this.getFieldValue('resource'));
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    onResourceDataLoaded(result) {
        this['resource'] = result;

        return result;
    }

    /**
     *
     * @returns {Promise}
     * @protected
     */
    loadShares() {
        return this.loadSharesAsync()
            .then((result) => { return this.onSharesLoaded(result)})
            .then((result) => {
                this['isLoadingShares'] = false;

                return result;
            });
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    loadSharesAsync() {
        this['isLoadingShares'] = true;

        const resourceId = this.getResourceId(),
            resourceType = this['resourceType'],
            shareService = ShareService;

        if (shareService != null && resourceId != null && resourceType != null) {
            return shareService.loadShares({
                'resourceId'    : resourceId,
                'resourceType'  : this['resourceType']
            });
        }

        return Promise.resolve(QueryDataResult.empty());
    }

    /**
     * @param {*} result
     * @return {hf.data.QueryDataResult}
     * @protected
     */
    onSharesLoaded(result) {
        result = result instanceof QueryDataResult ? result : QueryDataResult.empty();

        if(result instanceof QueryDataResult) {
            const shares = /**@type {hf.data.QueryDataResult}*/(result).getItems();

            shares.forEach(this.addShareInternal_, this);
        }

        return result;
    }

    /**
     *
     * @return {Promise}
     * @protected
     */
    updateShareInternal_() {
        const shareService = ShareService;

        if (this.hasValue('shareChanges') && this['shareChanges'].isSavable() && shareService != null) {
            /* make sure the share action has all the info about the resource */
            this['shareChanges']['resource']['resourceId'] = this.getResourceId();
            this['shareChanges']['resource']['resourceType'] = this['resourceType'];

            /**/
            this.resetFieldValue('shareResult', true);

            return shareService.share(this['shareChanges'].toJSONObject())
                .then((result) => {
                    this['shareResult'] = result;

                    return result;
                });
        }

        return Promise.resolve([]);
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @protected
     */
    addShareInternal_(share) {
        const shares = /**@type {hg.data.model.share.ShareCollection}*/(this['shares']);
        if(shares) {
            /* add the share to the list of shares */
            share = shares.addShare(share);

            if(share && share.isNew()) {
                /* if this is a new share the update the ShareChanges's grantees */
                /**@type {hg.data.model.share.GranteeCollection}*/(this['shareChanges']['grantee']).add(share['grantee']);
                share['grantee']['shareAction'] = ResourceShareActions.ADD;
            }
        }
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @param {HgResourceAccessLevels} accessLevel
     * @protected
     */
    updateAccessLevelInternal_(share, accessLevel) {
        if(share['grantee']['accessLevel'] != accessLevel) {
            share['grantee']['accessLevel'] = accessLevel;

            if (!share.isNew()) {
                if (share['grantee'].isDirty()) {
                    /* If the grantee is already added to the ShareChanges's grantees it will not be added again. */
                    /**@type {hg.data.model.share.GranteeCollection}*/(this['shareChanges']['grantee']).add(share['grantee']);

                    share['grantee']['shareAction'] = ResourceShareActions.UPDATE;
                }
                else {
                    /**@type {hg.data.model.share.GranteeCollection}*/(this['shareChanges']['grantee']).remove(share['grantee']);

                    share['grantee']['shareAction'] = undefined;
                }
            }
        }
    }

    /**
     * @param {hg.data.model.share.Share} share
     * @protected
     */
    removeShareInternal_(share) {
        const shares = /**@type {hg.data.model.share.ShareCollection}*/(this['shares']);
        if(shares) {
            shares.removeShare(share);
            /* update the ShareChanges's grantees */
            if (share.isNew()) {
                /* To remove a new share of a resource the grantee should be removed from the ShareChanges's grantees */
                /**@type {hg.data.model.share.GranteeCollection}*/(this['shareChanges']['grantee']).remove(share['grantee']);
            }
            else {
                /* To remove an existing share of a resource the grantee accessLevel should be set to NONE. */
                share['grantee']['accessLevel'] = HgResourceAccessLevels.NONE;
                /**@type {hg.data.model.share.GranteeCollection}*/(this['shareChanges']['grantee']).add(share['grantee']);
                share['grantee']['shareAction'] = ResourceShareActions.REMOVE;
            }
        }
    }

    /**
     * @return {string}
     * @protected
     */
    getResourceId() {
        return this['resourceId'];
    }

    /**
     * Triggers Successful notification - used on mobile
     * @param {Object} notificationData
     */
    triggerSuccessfulNotification(notificationData) {
        if (!userAgent.device.isDesktop()) {
            const shareService = ShareService;
            if (shareService != null && notificationData != null) {
                shareService.dispatchAppEvent(HgAppEvents.HANDLE_SHARE_NOTIFICATION, notificationData);
            }
        }
    }
};