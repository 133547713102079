import {AbstractAnchor} from "./../../../../../../hubfront/phpnoenc/js/domain/model/AbstractAnchor.js";

import {
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules
} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {HgRegExpUtils} from "./../../../common/regexp.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractAnchor}
 * @unrestricted 
*/
export class PersonReference extends AbstractAnchor {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'anchor',
            'minLength'     : 4
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'anchor',
            'maxLength'     : 65
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'anchor',
            'pattern'       : HgRegExpUtils.VALID_NAME_RE,
            'failMessage'   : translator.translate('Provide a name that does not contain characters like %characters%', ['\"@><{}[]+/#!~|$%^,()=?;*:']),
            'priority'      : 1
        }));
    }
};