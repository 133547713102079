import {CommonDataMapping} from "./Common.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObjectMapper} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {AuthAccountType, AuthObjectType, AuthTokenStatus} from "./../../model/auth/Enums.js";

/**
 *
 * @unrestricted
*/
export class AuthDataMapping {
    constructor() {
        //
    }
};



/**
 * Mapping template for auth objects model: used in CR operations
 * @type {Object}
 * @readonly
 */
AuthDataMapping.AuthObjects = {
    // The AuthToken mapping
    'read': {
        'path': '',
        'field': {
            'accountId': 'account.userId',
            'accountType': 'account.type',
            'exception': 'account.exception',
            'identity': 'identity.identity',
            'status': 'account.status',
            'name': 'user.person.fullName',
            'elevated': function(rawData){
                if (rawData['status'] == null) {
                    return undefined;
                }

                return true;
            },
            'session': function(rawData) {
                if(rawData['user'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance().transform(rawData, AuthDataMapping.Session['read']);
            },
            'authToken': function(rawData) {
                if(rawData['humanToken'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance().transform(rawData['humanToken'], AuthDataMapping.AuthToken['read']);
            }
        }
    },

    // The AuthObject mapping
    'write': {
        'path': '',
        'field': {
            'type': function(rawData) {

                if (rawData['type'] === AuthAccountType.HUBGETSINVITE) {
                    return AuthObjectType.SIGNUPTOKEN;
                }

                return AuthObjectType.SECRET;
            },
            'identity': function(rawData) {
                const identity = {
                    identity: rawData['identity'],
                    secret: rawData['secret'],
                    type: 'native'
                };
                delete rawData['secret'];
                return identity;
            },
            'secret':  function() {
                return undefined;
            },
            'context': 'context',
            'param':  function(rawData) {
                if(rawData['param'] == null) {
                    return undefined;
                }

                if(rawData['username']) {
                    rawData['identity'] = rawData['username'];
                    delete rawData['username'];
                }

                if(rawData['password']) {
                    rawData['secret'] = rawData['password'];
                    delete rawData['password'];
                }

                rawData['param']['invitationToken'] = {}
                if(rawData['param']['tokenExpire']) {
                    rawData['param']['invitationToken']['expires'] = rawData['param']['tokenExpire'];
                }

                if(rawData['param']['tokenHash']) {
                    rawData['param']['invitationToken']['hash'] = rawData['param']['tokenHash'];
                }

                return rawData['param'];
            },
            'humanToken': function(rawData) {
                if(rawData['authToken'] == null) {
                    return undefined;
                }

                return ObjectMapper.getInstance().transform(rawData['authToken'], AuthDataMapping.AuthToken['write']);
            },

        }
    }
}

/**
 * Mapping template for auth account model: used in CRU operations
 * @type {Object}
 * @readonly
 */
AuthDataMapping.AuthAccount = {
    'read': {
        'path': '',
        'field': {
            'userId'	: 'person.userId',
            'personId'	: 'person.personId',
            'firstName'	: 'person.firstName',
            'lastName'	: 'person.lastName',
            'fullName'	: 'person.fullName',
			'avatar'    : function(rawData) {
                let avatar = rawData['person']['avatar'];

                avatar = BaseUtils.isString(avatar) ? [avatar] : avatar;

                return avatar;
            },
            'pageURL'  	: 'person.page',
            'address'   : 'person.address',
            'contact'   : CommonDataMapping.getContactMapping("read", "person.contact"),
            'role'		: 'role',
            'created'	: 'created',
            'updated'	: 'updated'
        }
    }
};

/**
 * Mapping template for auth user model: used in R operations
 * @type {Object}
 * @readonly
 */
AuthDataMapping.Session = {
    'read': {
        'path': '',
        'field': {
            'hgState': function(rawData) {
                const state = 1;
                if (!rawData?.requirement) {
                    return state; // account is active
                }
                for (const requirement of rawData.requirement) {
                    if (requirement.id === 'HANDLE_APP_STATE') {
                        return requirement?.method[0]?.state
                    }
                }
                return state;
            },
            'personId': 'user.person.personId',
            'orgIdentifier': 'user.organization.identifier',
            'role': 'user.role'
        }
    }
}



/**
 * Mapping template for auth user model: used in R operations
 * @type {Object}
 * @readonly
 */
AuthDataMapping.AuthToken = {
    'read': {
        'path': '',
        'field': {
            'authTokenId': 'humanTokenId',
            'type': 'type',
            'value': 'value',
            'expires': 'expires',
            'identifier': 'identifier',
            'resource': function(rawData) {
                if(rawData['param'] !== undefined) {
                    for(let i=0, len = rawData['param'].length; i < len; i++) {
                        if(rawData['param'][i].key === 'uri') {
                            return rawData['param'][i].value;
                        }
                    }
                }
            }
        }
    },
    'write': {
        'path': '',
        'field': {
            'humanTokenId': 'authTokenId',
            'type': 'type',
            'value': 'value',
            'expires': 'expires',
            'identifier': 'identifier'
        }
    }
}
