import {List, ListLoadingTrigger} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ObjectUtils} from "./../../../../../../hubfront/phpnoenc/js/object/object.js";

import {DialogButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {AbstractDialogView} from "./../../../common/ui/view/AbstractDialog.js";
import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {ListUtils} from "./../../../common/ui/list/List.js";
import {HgCaptionUtils} from "./../../../common/ui/labs/Caption.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.global.view.MessageLikersView} view.
 *
 * @extends {AbstractDialogView}
 * @unrestricted 
*/
export class MessageLikersView extends AbstractDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.titleCaption_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.likersInfoCaption_;

        /**
         * @type {hf.ui.list.List}
         * @private
         */
        this.likersList_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.titleCaption_ = HgCaptionUtils.createTitle(translator.translate('who_liked_this'), '', 'hg-likers-title');

        this.likersInfoCaption_ = new Caption({
            'extraCSSClass'	: ['hg-likers-summary'],
            'contentFormatter': function(likeInfo) {
                if(!likeInfo) {
                    return null;
                }

                const likedByMe = !!likeInfo['likedByMe'],
                    likeCount = likeInfo['likeCount'] || 0;

                if(likeCount == 0) {
                    return;
                }

                const likersCount = likedByMe ? likeCount - 1 : likeCount;

                let subtitle = '';

                if(likedByMe) {
                    subtitle = likersCount == 0 ?
                        translator.translate('you_liked_this') : translator.translate('you_and_teammates', [likersCount]);
                }
                else {
                    subtitle = translator.translate('likes_number_teammates', [likersCount]);
                }

                return subtitle;
            }
        });

        this.likersList_ = new List({
            'extraCSSClass'       : ['hg-likers-list'],
            'itemContentFormatter': (model, item) => {
                if (model) {
                    const personModel = ObjectUtils.getPropertyByPath(model, 'author');

                    const listItemContent = [],
                        personInfo = new HgPartyListItemContent({
                            'model': personModel,
                            'openInChatOnNameClick': true
                        });

                    listItemContent.push(personInfo);

                    return listItemContent;
                }

                return null;
            },
            'errorFormatter': ListUtils.createErrorFormatter,
            'loadMoreItemsTrigger'  : ListLoadingTrigger.END_EDGE
        });

        this.setHasCloseButton(true);
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDialogTitleDom(model, titleControl) {
        return model ? [this.titleCaption_] : null;
    }

    /** @inheritDoc */
    createDialogBodyDom(model, bodyControl) {
        return model ? [this.likersList_] : null;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, bodyControl) {
        return model ? [this.likersInfoCaption_] : null;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass(['hg-appview-dialog-message-likers']);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.likersList_ = null;
        this.titleCaption_ = null;
        this.likersInfoCaption_ = null;
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-message-likers-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator;

        const buttonsSet = new DialogButtonSet();
        // buttonsSet.addButton(hg.HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
        //     'name': DialogDefaultButtonName.CANCEL
        // }));
        // buttonsSet.addButton(hg.HgButtonUtils.createPrimaryButton(null, translator.translate('ok'), false, {
        //     'name'    : DialogDefaultButtonName.SAVE,
        //     'loader': {
        //         'extraCSSClass': 'grayscheme'
        //     }
        // }));

        return buttonsSet;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.likersInfoCaption_, {'set': this.likersInfoCaption_.setModel}, {
            'sources': [
                {'sourceProperty': 'likedByMe'},
                {'sourceProperty': 'likeCount'}
            ],
            'converter': {
                'sourceToTargetFn': function(values) {
                    return {
                        'likedByMe': !!values[0],
                        'likeCount': values[1]
                    };
                }
            }
        });

        this.setBinding(this.likersList_, {'set': this.likersList_.setItemsSource}, 'likes');
    }
};