import { AbstractHTML5Media } from './AbstractHTML5Media.js';

import { FunctionsUtils } from '../../functions/Functions.js';
import { BaseUtils } from '../../base.js';
import { HTML5MediaSource } from './Source.js';
import { VolumeSliderExpand, VolumeSliderShrink } from './fx.js';
import { HTML5AudioTemplate } from '../../_templates/media.js';
import { LayoutContainer } from '../layout/LayoutContainer.js';
import { HorizontalStack } from '../layout/HorizontalStack.js';
import { VerticalStack } from '../layout/VerticalStack.js';
import { MediaSlider } from './Slider.js';
import { UIComponent } from '../UIComponent.js';
import { UIComponentEventTypes } from '../Consts.js';
import { Bubble } from '../popup/Bubble.js';
import { PopupPlacementMode } from '../popup/Popup.js';
import Translator from '../../translator/Translator.js';

/**
 * Creates a new HTML5 Audio media object with the provided configuration.
 *
 * @augments {AbstractHTML5Media}
 *
 */
export class HTML5Audio extends AbstractHTML5Media {
    /**
     * @param {!object=} opt_config The optional configuration object.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The container where the errors will be displayed
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.errorContainer = this.errorContainer === undefined ? null : this.errorContainer;

        /**
         * @type {hf.ui.popup.Bubble}
         * @protected
         */
        this.volumeSliderBubble = this.volumeSliderBubble === undefined ? null : this.volumeSliderBubble;
    }

    /**
     * @inheritDoc
     *
     */
    addSourceAt(source, index) {
        if (source instanceof HTML5MediaSource) {
            /* The MIME type should start with 'audio/' */
            const type = source.getType().toLowerCase();
            if (type.indexOf('audio/') !== 0) {
                source.setType(`audio/${type}`);
            }
        }
        /* Call parent method. */
        super.addSourceAt(source, index);
    }

    /**
     * @inheritDoc
     *
     */
    canPlayType(type) {
        if (BaseUtils.isString(type)) {
            /* The MIME type should start with 'audio/' */
            type = type.toLowerCase();
            if (type.indexOf('audio/') !== 0) {
                type = `audio/${type}`;
            }
        }
        /* Call parent method. */
        return super.canPlayType(type);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], 'audio');

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.errorContainer);
        this.errorContainer = null;

        BaseUtils.dispose(this.volumeSliderBubble);
        this.volumeSliderBubble = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-media-audio';
    }

    /** @inheritDoc */
    getDefaultRenderTpl() {
        return HTML5AudioTemplate;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('audio');
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        const volumeSlider = this.getVolumeSlider();
        if (volumeSlider) {
            this.getHandler()
                .listen(volumeSlider, UIComponentEventTypes.CHANGE, this.handleVolumeSliderChange);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.volumeSliderBubble != null) {
            this.volumeSliderBubble.close();
        }
    }

    /**
     * Returns the media element. For HTML5 audio, this is the <audio> element.
     *
     * @override
     */
    getMediaElement() {
        let mediaElement = super.getMediaElement();
        if (mediaElement == null) {
            const el = this.getElement();
            if (el == null) {
                return null;
            }
            if (el.tagName.toLowerCase() === 'audio') {
                mediaElement = el;
            } else {
                mediaElement = el.getElementsByTagName('audio')[0];
            }
        }
        this.setMediaElement(mediaElement);
        return mediaElement;
    }

    /** @inheritDoc */
    createActionControlsContainer() {
        const actionControlsContainer = new HorizontalStack({
            extraCSSClass: `${this.getBaseCSSClass()}-ui-controls-content`
        });

        const baseCSSClass = this.getBaseCSSClass();

        const seekContainer = new LayoutContainer({
            extraCSSClass: `${baseCSSClass}-` + 'seek-container'
        });
        seekContainer.addChild(this.getDurationControl(), true);
        seekContainer.addChild(this.getSeekSlider(), true);

        const volumeContainer = new LayoutContainer({
            extraCSSClass: `${baseCSSClass}-` + 'volume-container'
        });
        volumeContainer.addChild(this.getVolumeButton(), true);

        this.volumeSliderBubble = new Bubble({
            content: this.getVolumeSlider(),
            showDelay: 150,
            hideDelay: 0,
            showArrow: false,
            placementTarget: this.getVolumeButton(),
            placement: PopupPlacementMode.TOP_MIDDLE,
            extraCSSClass: `${baseCSSClass}-` + 'volume-slider-bubble',
            openAnimation: {
                type: VolumeSliderExpand,
                scope: this,
                prePlay: false
            },
            closeAnimation: {
                type: VolumeSliderShrink,
                scope: this
            },
            verticalOffset: -3
        });

        const rightContainer = new VerticalStack({
            extraCSSClass: `${baseCSSClass}-` + 'right-container'
        });
        rightContainer.addChild(seekContainer, true);
        rightContainer.addChild(volumeContainer, true);
        rightContainer.addChild(this.sourceName, true);

        actionControlsContainer.addChild(this.getPlayButton(), true);
        actionControlsContainer.addChild(rightContainer, true);

        return actionControlsContainer;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable) {
        const playBtn = this.getPlayButton();
        if (playBtn) {
            playBtn.setBusy(enable);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable) {
        if (enable) {
            const translator = Translator;

            this.sourceName.setContent(translator.translate('cannot_play_audio'));

            const seekSlider = this.getSeekSlider();
            if (seekSlider) {
                seekSlider.setEnabled(false);
            }

            const volumeButton = this.getVolumeButton();
            if (volumeButton) {
                volumeButton.setEnabled(false);
            }

            const playBtn = this.getPlayButton();
            if (playBtn) {
                playBtn.setEnabled(false);

                const errorContainer = this.getErrorContainer();
                if (errorContainer.getParent() == null) {
                    const errorHost = playBtn.getParent();
                    if (errorHost) {
                        const index = errorHost.indexOfChild(playBtn);
                        errorHost.addChildAt(errorContainer, index + 1, true);
                    }
                }
            }
        } else {
            const playBtn = this.getPlayButton();
            if (playBtn) {
                const errorHost = playBtn.getParent();

                if (errorHost && this.errorContainer != null && errorHost == this.errorContainer.getParent()) {
                    errorHost.removeChild(this.errorContainer, true);

                    BaseUtils.dispose(this.errorContainer);
                    this.errorContainer = null;
                }
            }
        }
    }

    /** @inheritDoc */
    createErrorContainer() {
        return new UIComponent({
            baseCSSClass: `${this.getBaseCSSClass()}-` + 'play-error'
        });
    }

    /** @inheritDoc */
    handleControlsEnter(e) {

    }

    /** @inheritDoc */
    handleControlsLeave(e) {

    }

    /**
     * Handler for changing an volume slider UI control.
     * The volume is set according to the volume slider, if the volume slider has changed.
     *
     * @param {hf.events.Event} e The change event.
     * @protected
     */
    handleVolumeSliderChange(e) {
        if (e.target instanceof MediaSlider) {
            /* establish on which control the change was made */
            const targetId = e.target.getRenderTplData('id');
            const id = this.getId();

            if (targetId == (`${id}-volume-slider`)) {
                this.setVolume(this.getVolumeSlider().getValue() / 100);
            }
        }
    }
}
