import {BaseView} from "./../../../common/ui/view/BaseView.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {AuthPanel} from "./../component/AuthPanel.js";
import {Recover} from "./../component/form/Recover.js";
import {ChangePass} from "./../component/form/ChangePass.js";
import {EmailConfirmation} from "./../component/EmailConfirmation.js";
import {PasswordConfirmation} from "./../component/PasswordConfirmation.js";
import {InvalidToken} from "./../component/InvalidToken.js";
import {HgAuthBusyContext, HgAuthEventType, HgAuthRecoverMode} from "./../Common.js";
import {FormEventType} from "./../../../common/ui/Form.js";
import {CaptchaEventType} from "./../../../common/ui/Captcha.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new View object.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class RecoveryView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * @type {hg.module.auth.form.Recover}
         * @private
         */
        this.recoverContent_;

        /**
         * @type {hg.module.auth.form.ChangePass}
         * @private
         */
        this.changePassContent_;

        /**
         * @type {hg.module.auth.EmailConfirmation}
         * @private
         */
        this.emailConfirmationContent_;

        /**
         * @type {hg.module.auth.PasswordConfirmation}
         * @private
         */
        this.passwordConfirmationContent_;

        /**
         * @type {hf.ui.UIControl}
         * @private
         */
        this.contentContainer_;

        /**
         * The container where the errors will be displayed
         *
         * @type {hg.module.auth.AuthPanel}
         * @private
         */
        this.errorContainer_ = this.errorContainer_ === undefined ? null : this.errorContainer_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-recovery';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        /* container to hold different pieces of content */
        this.contentContainer_ = new AuthPanel();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this, {'set': this.setMode}, 'mode');

        /*this.setBinding(this.contentContainer_, {'set': FunctionsUtils.bind(function (isDeviceKnown) {
            if (isDeviceKnown != null) {
                var err = new Error(translator.translate('You are logging in from an unknown device. For your security extra measures have been enforced.'));

                this.contentContainer_.setHasError(!isDeviceKnown, {'error': err, 'context': HgAuthBusyContext.DEVICE_UNKNOWN});
            }
        }, this)}, 'isDeviceKnown');*/
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.contentContainer_, true);
    }

    /**
     * Set current contact mode for this bubble.
     * @param {!HgAuthRecoverMode} mode
     * @protected
     */
    setMode(mode) {
        if (mode != null && !(Object.values(HgAuthRecoverMode).includes(mode))) {
            throw new Error('Invalid state, set of supported states contains: ' + Object.values(HgAuthRecoverMode) + '.');
        }

        if (this.mode_ != mode) {
            const previousMode = this.mode_;

            this.mode_ = mode;

            /* process selector and button set on state change */
            this.updateContent_(mode, previousMode);
        }
    }

    /**
     * Update content
     * @param {hf.ui.UIComponent} content
     * @private
     */
    setContentInternal_(content) {
        this.contentContainer_.setContent(content);
    }

    /**
     * @return {hf.ui.UIComponent} Current content component
     * @private
     */
    getContent_() {
        return /** @type {hf.ui.UIComponent} */(this.contentContainer_.getContent());
    }

    /**
     * Update content
     * @param {HgAuthRecoverMode} mode
     * @param {HgAuthRecoverMode?} previousMode
     * @private
     */
    updateContent_(mode, previousMode) {
        switch (mode) {
            case HgAuthRecoverMode.FORGOT_PASS:
            case HgAuthRecoverMode.FORGOT_DOMAIN:
                if (this.recoverContent_ == null) {
                    this.recoverContent_ = new Recover({'name': 'forgotPass'});
                    this.setBinding(this.recoverContent_, {'set': this.recoverContent_.setModel}, '');
                }

                this.contentContainer_.addExtraCSSClass('hg-auth-panel-recovery');
                this.setContentInternal_(this.recoverContent_);
                break;

            case HgAuthRecoverMode.CHANGE_PASS:
                if (this.changePassContent_ == null) {
                    this.changePassContent_ = new ChangePass({'name': 'changePass'});
                    this.setBinding(this.changePassContent_, {'set': this.changePassContent_.setModel}, '');
                }

                this.contentContainer_.addExtraCSSClass('hg-auth-panel-change-password');
                this.setContentInternal_(this.changePassContent_);
                break;

            case HgAuthRecoverMode.EMAIL_CONFIRMATION:
                if (this.emailConfirmationContent_ == null) {
                    this.emailConfirmationContent_ = new EmailConfirmation();
                }
                this.emailConfirmationContent_.setModel(previousMode);

                this.contentContainer_.addExtraCSSClass('hg-auth-panel-recovery');
                this.setContentInternal_(this.emailConfirmationContent_);

                break;

            case HgAuthRecoverMode.PASSWORD_CONFIRMATION:
                if (this.passwordConfirmationContent_ == null) {
                    this.passwordConfirmationContent_ = new PasswordConfirmation();
                }

                this.contentContainer_.addExtraCSSClass('hg-auth-panel-recovery');
                this.setContentInternal_(this.passwordConfirmationContent_);

                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            /* listen to form submit events (either browser default on ENTER or click on submit button) */
            .listen(this, FormEventType.SUBMIT, this.handleSubmit_)

            .listen(this, HgAuthEventType.BACK_LOGIN, this.handleBackToLoginAction_)

            .listen(this, HgAuthEventType.FORGOT_PASS, this.handleBackToResetAction_)

            /* listen to captcha refresh requests */
            .listen(this, CaptchaEventType.REFRESH, this.handleCaptchaRefresh_)

            .listen(this, HgAuthEventType.CLEAR_ORGANIZATION, this.handleClearOrganization_);

        this.playShowFormAnimation();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.recoverContent_);
        this.recoverContent_ = null;

        BaseUtils.dispose(this.changePassContent_);
        this.changePassContent_ = null;

        BaseUtils.dispose(this.emailConfirmationContent_);
        this.emailConfirmationContent_ = null;

        BaseUtils.dispose(this.passwordConfirmationContent_);
        this.passwordConfirmationContent_ = null;

        BaseUtils.dispose(this.contentContainer_);
        this.contentContainer_ = null;

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        this.contentContainer_.setBusy(enable, opt_busyContext);
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if (this.contentContainer_ && BaseUtils.isFunction(this.contentContainer_.setHasError)) {
            this.contentContainer_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        }

        switch (errorInfo['context']) {
            case HgAuthBusyContext.GENERATE_HUMAN_TOKEN:
                break;

            case HgAuthBusyContext.INVALID_SECRET_TOKEN:
                const errorContainer = this.getErrorContainer(errorInfo);
                if (enable) {
                    if (errorContainer.getParent() == null) {
                        this.contentContainer_.setContent(errorContainer);
                    }
                } else {
                    if (errorContainer.getParent() === this.contentContainer_) {
                        this.updateContent_(this.mode_, null);

                        BaseUtils.dispose(this.errorContainer);
                        this.errorContainer = null;
                    }
                }
                this.contentContainer_.addExtraCSSClass('hg-auth-panel-invalid-token');
                break;

            default:
                break;
        }
    }

    /** @inheritDoc */
    createErrorContainer(errorInfo) {
        const translator = Translator;

        return new InvalidToken({
            'model': {
                'canReset': true,
                'caption' : /** @type {Error} */(errorInfo['error']).message
            }
        });
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleBackToLoginAction_(e) {
        /** @type {hg.module.auth.presenter.RecoveryPresenter} */(this.getPresenter()).navigateToLogin();
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleBackToResetAction_(e) {
        /** @type {hg.module.auth.presenter.RecoveryPresenter} */(this.getPresenter()).navigateToReset();
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSubmit_(e) {
        /** @type {hg.module.auth.presenter.RecoveryPresenter} */(this.getPresenter()).saveChanges();
    }

    /**
     * Handles captcha image refresh
     * @param {hf.events.Event} e The event
     * @private
     */
    handleCaptchaRefresh_(e) {
        this.refreshCaptcha_();
    }

    /**
     * Refresh captcha challenge
     * @private
     */
    refreshCaptcha_() {
        /* generate a new captcha */
        /** @type {hg.module.auth.presenter.RecoveryPresenter} */(this.getPresenter()).generateAuthToken();
    }

    /** Display login form with animation */
    playShowFormAnimation() {
        if (this.contentContainer_ != null) {
            this.contentContainer_.playShowFormAnimation();
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearOrganization_(e) {
        /** @type {hg.module.auth.presenter.RecoveryPresenter} */(this.getPresenter()).clearOrganization();
    }
};