import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {HgCaptionUtils} from "./../labs/Caption.js";
import {GenericResourceView} from "./GenericResourceView.js";
import {HgResourceStatus} from "./../../../data/model/resource/Enums.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates content of the TopicInfo panel (without footer)
 * @extends {GenericResourceView}
 * @unrestricted 
*/
export class TopicView extends GenericResourceView {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /**
     * Creates the status label.
     * @returns {hf.ui.Caption}
     * @protected
     */
    createStatusLabel() {
        const translator = Translator;

        return HgCaptionUtils.createStatusLabel({
            'contentFormatter': function(topic) {
                if(!topic) {
                    return null;
                }

                const status = topic['status'];
                let statusLabel = null;

                switch(status) {
                    case HgResourceStatus.CLOSED:
                        statusLabel = 'CLOSED';
                        break;

                    case HgResourceStatus.OPEN:
                        statusLabel = 'OPEN';
                        break;
                }

                return statusLabel ? translator.translate(statusLabel) : null;
            },
            'extraCSSClass': function(topic) {
                if(!topic) {
                    return null;
                }

                const status = topic['status'],
                    css = [GenericResourceView.CssClass_.RESOURCE_STATUS, 'hg-topic-status'];

                switch(status) {
                    case HgResourceStatus.CLOSED:
                        css.push('hg-status-closed');
                        break;

                    case HgResourceStatus.OPEN:
                        css.push('hg-status-open');
                        break;
                }

                if(topic['watchedByMe']) {
                    css.push('watched-by-me', 'green');
                } else {
                    css.push('gray');
                }

                return css;
            },
            'tooltip': {
                'baseCSSClass'  	: 'hg-tooltip',
                'extraCSSClass' 	: function (topic) {
                    const classPath = ['grayscheme'];

                    if (topic) {
                        const status = topic['isOpen'];

                        if (status) {
                            classPath.push('hg-topic-list-topic-open');
                        } else {
                            classPath.push('hg-topic-list-topic-closed');
                        }
                    }

                    return classPath;
                },
                'showArrow': true,
                'placement'         : PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset'    : -4,
                'contentFormatter'	: function(topic) {
                    if(!topic) {
                        return null;
                    }

                    if(topic['isOpen']) {
                        const content = document.createDocumentFragment(),
                            watchedByMe = topic['watchedByMe'];

                        content.appendChild(DomUtils.createDom('div', '', watchedByMe ? translator.translate('topic_is_watched') : translator.translate('topic_not_watched')));
                        //content.appendChild(DomUtils.createDom('div', '', watchedByMe ? translator.translate('click_to_unwatch') : translator.translate('click_to_watch')));

                        return content;
                    }
                    else {
                        return translator.translate('topic_is_archived');
                    }
                }
            }
        });
    }
};