import {HgResourceAccessLevels} from "./../../../data/model/resource/Enums.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {Text} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {TextArea} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Textarea.js";
import {
    FormFieldLabelLayout,
    FormFieldValidateOn
} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {DataBindingMode} from "./../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {AvatarSelector, AvatarSelectorEventType} from "./../../../common/ui/avatar/AvatarSelector.js";
import {RecipientSelector, RecipientSelectorEventType} from "./../../../common/ui/labs/RecipientSelector.js";
import {AbstractDialogLikeContent} from "./../../../common/ui/AbstractDialogLikeContent.js";
import {ShareGrantee} from "./../../../common/ui/share/ShareGrantee.js";
import {ShareUIEventType} from "./../../../common/ui/share/EventType.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {AbstractDialogLikeContent}
 * @unrestricted 
*/
export class AddTopicContent extends AbstractDialogLikeContent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * The avatar selector
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @private
         */
        this.avatarSelector_ = this.avatarSelector_ === undefined ? null : this.avatarSelector_;

        /**
         *
         * @type {hf.ui.Caption}
         * @private
         */
        this.title_ = this.title_ === undefined ? null : this.title_;

        /**
         *
         * @type {hf.ui.form.field.Text}
         * @private
         */
        this.topicName_ = this.topicName_ === undefined ? null : this.topicName_;

        /**
         *
         * @type {hf.ui.form.field.TextArea}
         * @private
         */
        this.topicDescription_ = this.topicDescription_ === undefined ? null : this.topicDescription_;

        /**
         *
         * @type {hg.common.ui.RecipientSelector}
         * @private
         */
        this.topicParticipants_ = this.topicParticipants_ === undefined ? null : this.topicParticipants_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'hg-topic-add-form';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        this.avatarSelector_ = new AvatarSelector({
            'extraCSSClass': 'hg-topic-avatar'
        });

        this.title_ = new Caption({
            'extraCSSClass': [baseCSSClass + '-' + 'topic-title', 'hg-title-caption'],
            'content': translator.translate('create_topic')
        });

        this.topicName_ = new Text({
            'placeholder'       : translator.translate('topic_name_required'),
            'required'          : true,
            'extraCSSClass'     : ['hg-topic-name', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': true
            }
        });

        this.topicDescription_ = new TextArea({
            'placeholder'       : translator.translate('what_is_about') + "?",
            'extraCSSClass'     : ['hg-topic-description', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 500,
            'resizable'         : false
        });

        this.topicParticipants_ = new RecipientSelector({
            'extraCSSClass': 'hg-share-recipient-selector',
            'search': {
                'label'     : {
                    'content'   : translator.translate('share_with'),
                    'layout'    : FormFieldLabelLayout.TOP
                },
                'placeholder': translator.translate('search_add_participants'),
                'itemStyle'     : function (model) {
                    const extraCss = ['hg-share-recipient-suggestion-item'];

                    if(model && !StringUtils.isEmptyOrWhitespace(model['accessLevel'])) {
                        extraCss.push('access-level-' + model['accessLevel'].toLowerCase());
                    }

                    return extraCss;
                },
                'popup': {
                    'extraCSSClass':  ['hg-share-recipient-suggestions-popup']
                }
            },
            'selection': {
                'itemContentFormatter'  : function (model) {
                    return model ? new ShareGrantee({'model': model}) : null;
                },
                'emptyContentFormatter' : function () {
                    return translator.translate("search_people_bots");
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.title_ = null;
        this.avatarSelector_ = null;
        this.topicName_ = null;
        this.topicDescription_ = null;
        this.topicParticipants_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-topic-add-content';
    }

    /** @inheritDoc */
    createContent(contentContainer) {
        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        const header = new HorizontalStack({'extraCSSClass': baseCSSClass + '-' + 'header-container'});
        header.addChild(this.avatarSelector_, true);
        header.addChild(this.title_, true);

        contentContainer.addChild(header, true);
        contentContainer.addChild(this.topicName_, true);
        contentContainer.addChild(this.topicDescription_, true);
        contentContainer.addChild(this.topicParticipants_, true);
    }

    /** @inheritDoc */
    createButtonSet() {
        const translator = Translator;

        return HgButtonUtils.createPrimarySecondaryButtonSet(translator.translate('ADD'), translator.translate('Cancel'));
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, RecipientSelectorEventType.ADD_RECIPIENT, this.handleAddShare_)
            .listen(this, RecipientSelectorEventType.REMOVE_RECIPIENT, this.handleRemoveShare_)
            .listen(this, ShareUIEventType.CHANGE_ACCESS_LEVEL, this.handleChangeAccess_)
            .listen(this, AvatarSelectorEventType.OPEN_AVATAR_PANEL, this.handleOpenAvatarPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const btnSet = this.getButtonSet(),
            submitBtn = btnSet ? btnSet.getButtonByName(HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) : null;
        if (submitBtn) {
            this.setBinding(submitBtn, {'set': submitBtn.setEnabled}, {
                'converter': {
                    'sourceToTargetFn': function (share) {
                        return share && share.isSavable();
                    }
                }
            });
        }

        this.setBinding(this.avatarSelector_, {'set': this.avatarSelector_.setModel}, 'topic');

        this.setBinding(this.topicName_, {'get': this.topicName_.getValue, 'set': this.topicName_.setValue}, {
            'sourceProperty': 'topic.name',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.topicDescription_, {'get': this.topicDescription_.getValue, 'set': this.topicDescription_.setValue}, {
            'sourceProperty': 'topic.description',
            'mode': DataBindingMode.TWO_WAY
        });

        this.setBinding(this.topicParticipants_, {'set': this.topicParticipants_.setModel}, '');

        /* handling error, busy changes */
        this.setBinding(this, {'set': this.onErrorChange_}, 'error');
        this.setBinding(this, {'set': this.onBusyChange_}, 'isBusy');
    }

    /**
     * @return {Promise}
     * @private
     */
    saveTopic_() {
        const topicViewModel = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel());

        return (topicViewModel != null && topicViewModel.isSavable()) ? topicViewModel.saveTopic() : Promise.reject(null);
    }

    /**
     * Handle viewmodel busy change
     * @param {boolean} isBusy
     * @private
     */
    onBusyChange_(isBusy) {
        const model = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            context = model ? model['busyContext'] : undefined;

        this.setBusy(isBusy, context);
    }

    /**
     * Handle viewmodel error change
     * @param {Array} error
     * @private
     */
    onErrorChange_(error) {
        const model = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            enable = error !== null,
            context = model ? /** @type {ErrorInfo} */({
                'error': error,
                'context': model['errorContext']
            }) : undefined;

        this.setHasError(enable, context);
    }

    /** @inheritDoc */
    onButtonAction(buttonName) {
        if (buttonName == HgButtonUtils.ButtonSetName.PRIMARY_BUTTON) {
            this.saveTopic_().then(() => this.dispatchButtonActionEvent(buttonName));
        }
        else {
            return this.dispatchButtonActionEvent(buttonName);
        }

        return true;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleAddShare_(e) {
        const topicViewModel = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.party.RecipientBaseSearchResult} */(e.getProperty("recipient"));

        if(topicViewModel != null && share != null) {
            topicViewModel.addRecipient(share);
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleRemoveShare_(e) {
        const topicViewModel = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.share.Share} */(e.getProperty("recipient"));

        if(topicViewModel != null && share != null) {
            const result = topicViewModel.removeRecipient(share);
            if (result) {
                e.addProperty('result', result);
            }
        }
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleChangeAccess_(e) {
        const topicViewModel = /** @type {hg.topic.viewmodel.TopicViewmodel} */(this.getModel()),
            share = /** @type {hg.data.model.share.Share} */(e.getProperty('share')),
            accessLevel = /** @type {HgResourceAccessLevels} */(e.getProperty('accessLevel'));

        if(topicViewModel != null && share != null) {
            topicViewModel.updateAccessLevel(share, accessLevel);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenAvatarPanel_(e) {
        e.addProperty('renderParent', this.getContentElement());
        e.addProperty('placementTarget', this.getContentElement());
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }
};