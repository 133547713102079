import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {AuthToken} from "./AuthToken.js";

/**
 * A collection of {@link hg.data.model.auth.AuthToken}s.
 * @extends {DataModelCollection}
 * @unrestricted
*/
export class AuthTokenCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
  *
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': AuthToken
     };

     super(opt_config);
 }
};
