import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {MaxLengthRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {Rule} from "./../../../../../../hubfront/phpnoenc/js/validation/Rule.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class CardTokenDetails extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* Cardholder's full name. */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* The card number, as a string without any separators. */
        this.addField({'name': 'number', 'type': DataModelField.PredefinedTypes.STRING});

        /* Card security code. Required unless your account is registered in Australia, Canada, or the United States.
         Highly recommended to always include this value. */
        this.addField({'name': 'cvc', 'type': DataModelField.PredefinedTypes.STRING});

        /* Two digit number representing the card's expiration month. */
        this.addField({'name': 'exp_month', 'type': DataModelField.PredefinedTypes.STRING});

        /* Two or four digit number representing the card's expiration year. */
        this.addField({'name': 'exp_year', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'address_city', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'address_country', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'address_line1', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'address_state', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'address_zip', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'name'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'number'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'cvc'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'exp_month'
        }));

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'exp_year'
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'number',
            'maxLength'		: 16,
            'priority'      : 1
        }));


        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'cvc',
            'maxLength'     : 4,
            'priority'      : 1
        }));

        this.addValidationRule(new Rule({
            'targetProperties'	: [
                'exp_month',
                'exp_year'
            ],
            'validationHandler'	: function (context) {
                const target = context['target'],
                    expMonth = target['exp_month'],
                    expYear = target['exp_year'];

                return !StringUtils.isEmptyOrWhitespace(expMonth) && !StringUtils.isEmptyOrWhitespace(expYear) && Stripe.card.validateExpiry(expMonth, expYear);
            },
            'severity'			: ValidationRuleSeverity.ERROR,
            'failMessage'		: translator.translate('expiration_date_error'),
            'priority'			: 1
        }));

        this.addValidationRule(new Rule({
            'targetProperties': [
                'number'
            ],
            'validationHandler'	: function (context) {
                const target = context['target'],
                    numberValue = target['number'];

                /* validate card number only when all group fields are completed */
                return numberValue != null && Stripe.card.validateCardNumber(numberValue);
            },
            'severity'			: ValidationRuleSeverity.ERROR,
            'failMessage'		: translator.translate('The card number is not formatted correctly!'),
            'priority'			: 2
        }));

        // this.addValidationRule(new hf.validation.Rule({
        // 	'targetProperties': [
        //     'number'
        //   ],
        // 	'validationHandler'	: function (context) {
        // 		var target = context['target'],
        // 			numberValue = target['number'];
        //
        // 		/* validate card number only when all group fields are completed */
        // 		return numberValue != null && Stripe.card.cardType(numberValue) !== 'Unknown';
        // 	},
        // 	'severity'			: ValidationRuleSeverity.ERROR,
        // 	'failMessage'		: translator.translate('The card type is not recognized!'),
        // 	'priority'			: 3
        // }));

        this.addValidationRule(new Rule({
            'targetProperties'	: [
                'cvc'
            ],
            'validationHandler'	: function (context) {
                const target = context['target'],
                    cvcValue = target['cvc'];

                return cvcValue != null && Stripe.card.validateCVC(cvcValue);
            },
            'severity'			: ValidationRuleSeverity.ERROR,
            'failMessage'		: translator.translate('not_valid_card'),
            'priority'			: 2
        }));
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);
    }
};