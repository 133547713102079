import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {AuthorType} from "./../../../data/model/author/Enums.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {AbstractPhoneHistoryView, PhoneHistoryViewEventTypes} from "./AbstractPhoneHistoryView.js";

/**
 * Creates a new object representing the content of a view that is a branch for a call item in callhistory list.
 *
 * @extends {AbstractPhoneHistoryView}
 * @unrestricted 
*/
export class BranchPhoneHistoryView extends AbstractPhoneHistoryView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.mainPartyAvatar_, {'set': this.mainPartyAvatar_.setModel}, 'view.source.participant');

        this.setBinding(this.mainPartyName_, {'set': this.mainPartyName_.setModel}, 'view.source.participant');

        this.setBinding(this.mainPartyPhone_, {'set': this.mainPartyPhone_.setModel}, 'view.source');

        this.setBinding(this.addUnknownPartyBtn_, {'set': this.addUnknownPartyBtn_.setVisible}, {
            'sourceProperty': 'view.source.participant.type',
            'converter': {
                'sourceToTargetFn': function(callPartyType) {
                    return callPartyType === AuthorType.THIRDPARTY;
                }
            }
        });

        this.setBinding(this.secondaryPartyName_, {'set': this.secondaryPartyName_.setModel}, 'view.destination.participant');

        this.setBinding(this.secondaryPartyPhone_, {'set': this.secondaryPartyPhone_.setModel}, 'view.destination');

        this.setBinding(this.startedDate_, {'set': this.startedDate_.setDateTime}, 'view.started');

        this.setBinding(this.disposition_,	{'set': this.disposition_.setModel}, '');
    }

    /** @inheritDoc */
    createDispositionDetailsDom() {
        const dispositionContainer = new VerticalStack({
            'extraCSSClass': 'hg-ch-disposition-info-container'
        });

        const infoItemTopContainer = new HorizontalStack({'extraCSSClass': 'hg-ch-disposition-details-container'});
        infoItemTopContainer.addChild(this.startedDate_, true);

        const infoItemBottomContainer = new HorizontalStack({'extraCSSClass': 'hg-ch-disposition-resources-container'});
        infoItemBottomContainer.addChild(this.resourcesContainer_, true);
        infoItemBottomContainer.addChild(this.disposition_, true);

        dispositionContainer.addChild(infoItemTopContainer, true);
        dispositionContainer.addChild(infoItemBottomContainer, true);

        return dispositionContainer;
    }

    /** @inheritDoc */
    handleResourceSelection(e) {
        /* a resource has type {'attached': true/false, 'type': FAX/REC/VM} */
        const selectedResource = /** @type {hf.ui.Button} */(e.getTarget()),
            resourceModel = selectedResource.getModel(),
            phoneHistoryViewModel = this.getModel();

        if (resourceModel == null || phoneHistoryViewModel == null) {
            return;
        }

        if ((resourceModel['attached'] === true) &&
            phoneHistoryViewModel['phoneHistoryViewId'] != null &&
            phoneHistoryViewModel['view'] != null) {

            const showResourceEvent = new Event(PhoneHistoryViewEventTypes.SHOW_RESOURCE);
            showResourceEvent.addProperty('phoneHistoryViewId', phoneHistoryViewModel['phoneHistoryViewId']);

            /* viewExtension could be used in order to execute readMedia in paralel with readById for viewDetails state.
            * This parameter could be used only for a call leg, when callHistoryView state is required from app; in this
            * case, the extension number is known. When callHistoryView state is loaded by redirect from resource email, the
            * extension number is undefined before readById() result.
            * In order to have a consistent load of callHistoryView state, the viewExtension value is ignored in this case. */
            //showResourceEvent.addProperty('viewExtension', phoneHistoryViewModel['view']['extension']);

            this.dispatchEvent(showResourceEvent);
        }
    }
};