import {CurrentApp} from "./../../../../../../../hubfront/phpnoenc/js/app/App.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {Popup, PopupPlacementMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {DialogButtonSet} from "./../../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {ErrorAlertMessage} from "./../../../../common/ui/alert/Error.js";
import {HgButtonUtils} from "./../../../../common/ui/button/Common.js";
import {PhoneBusyContext, PhoneEventType} from "./../../Common.js";
import {DialogLikeContent} from "./../../../../common/ui/DialogLikeContent.js";
import {AbstractDialogLikeContentEventType} from "./../../../../common/ui/AbstractDialogLikeContent.js";
import {WindowManager} from "./../../../../data/service/WindowManager.js";
import {UIComponentEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FailureState extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.ErrorAlertMessage}
         * @private
         */
        this.error_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.btn_;

        /**
         * The popup containing failure reason
         * @type {hf.ui.popup.Popup}
         * @private
         */
        this.infoDialog_ = this.infoDialog_ === undefined ? null : this.infoDialog_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-phone-conflict';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        

        const translator = Translator;

        this.error_ = new ErrorAlertMessage({
            'content'       : '',
            'extraCSSClass' : ['small']
        });

        this.btn_ = HgButtonUtils.createLinkButton(null, false, {
            'extraCSSClass' : 'hg-btn-retry',
            'content'       : translator.translate('retry'),
            'loader'        : {
                'extraCSSClass': 'grayscheme'
            }
        });

        super.init(opt_config);
    }

    /** @param {Array} sources */
    onStateChanged_(sources) {
        if (sources == null) {
            return;
        }

        const translator = Translator;
        let canRetry = sources[0],
            automaticRetry = sources[1];
        const hasReason = !StringUtils.isEmptyOrWhitespace(sources[2]) && sources[2] == PhoneBusyContext.NO_WEBRTC_SUPPORT;

        this.btn_.setContent(!canRetry && hasReason ? translator.translate('why') : translator.translate('retry'));

        this.btn_.setEnabled(!!canRetry || hasReason);
        this.btn_.setVisible(!!canRetry || hasReason);
        this.btn_.setBusy(!!automaticRetry);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.btn_, {'set': this.onStateChanged_.bind(this)}, {
            'sources': [
                {'sourceProperty': 'canRetry'},
                {'sourceProperty': 'automaticRetry'},
                {'sourceProperty': 'reason'}
            ]
        });

        this.setBinding(this.error_, {'set': this.error_.setContent}, {
            'sources': [
                {'sourceProperty': 'automaticRetry'},
                {'sourceProperty': 'reason'}
            ],
            'converter': {
                'sourceToTargetFn': function (sources) {
                    let automaticRetry = sources[0];
                    const reason = sources[1];
                    let title, message, htmlString;

                    if (!!automaticRetry) {
                        title = translator.translate('Web phone');
                        message = translator.translate('resuming_connection');
                    } else if (StringUtils.isEmptyOrWhitespace(reason)) {
                        title = translator.translate('Web phone');
                        message = translator.translate(!!automaticRetry? 'resuming_connection' : 'phone_initialization_failed');
                    }
                    else {
                        switch (reason) {
                            case PhoneBusyContext.NO_WEBRTC_SUPPORT:
                                title = translator.translate('Web phone');
                                message = translator.translate('phone_initialization_failed');
                                break;

                            case PhoneBusyContext.NO_AUDIO_DEVICE:
                                title = translator.translate('No_microphone_detected');
                                message = translator.translate('please_plug_microphone');
                                break;

                            case PhoneBusyContext.MEDIA_REQUEST:
                                htmlString = translator.translate('review_settings_here');
                                htmlString = htmlString.replace(RegExpUtils.LP_LINK_RE,
                                        function(fullMatch, linkText) {
                                            return `<span class="hg-linklike" data-role="service-setup">${linkText}</span>`;
                                        }
                                    );

                                title = translator.translate('product_requires_intervention', [CurrentApp.Name]);
                                message = DomUtils.htmlToDocumentFragment(htmlString);
                                break;

                            default:
                                htmlString = translator.translate('please_review_settings');
                                htmlString = htmlString.replace(RegExpUtils.LP_LINK_RE,
                                    function(fullMatch, linkText) {
                                        return `<span class="hg-linklike" data-role="service-setup">${linkText}</span>`;
                                    }
                                );

                                title = translator.translate('Hubgets cannot access your microphone');
                                message = DomUtils.htmlToDocumentFragment(htmlString);
                                break;
                        }
                    }

                    return DomUtils.createDom('div', '',
                        DomUtils.createDom('div', 'hg-phone-alias', title),
                        DomUtils.createDom('div', 'hg-error-message', message)
                    );
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.error_, true);
        this.addChild(this.btn_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.btn_, UIComponentEventTypes.ACTION, this.handleButtonAction_)
            .listen(this.error_.getElement(), BrowserEventType.CLICK, this.handleErrorAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.infoDialog_ != null) {
            this.infoDialog_.exitDocument();
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.infoDialog_ != null) {
            BaseUtils.dispose(this.infoDialog_);
            delete this.infoDialog_;
        }

        BaseUtils.dispose(this.btn_);
        this.btn_ = null;

        BaseUtils.dispose(this.error_);
        this.error_ = null;
    }

    /**
     * Lazy create error dialog
     * @private
     * @return {hf.ui.popup.Popup}
     */
    getInfoDialog_() {
        if (this.infoDialog_ == null) {
            this.infoDialog_ = new Popup({
                'placementTarget'       : this,
                'placement'             : PopupPlacementMode.BOTTOM_RIGHT,            
                'extraCSSClass'         : ['hg-phone-error-popup', 'whitescheme', 'hg-popup'],
                'showArrow'             : true,
                'staysOpen'				: true
            });

            this.infoDialog_.addListener(AbstractDialogLikeContentEventType.BUTTON_ACTION, this.handleAckButtonAction_, false, this);
        }

        return this.infoDialog_;
    }

    /**
     * Compute content specific to contextErr for info dialog
     * @param {PhoneBusyContext} reason
     * @return {UIControlContent}
     * @private
     */
    getInfoDialogContent_(reason) {
        const translator = Translator,
            content = document.createDocumentFragment();

        if (reason == PhoneBusyContext.NO_WEBRTC_SUPPORT) {
            const browsers = CallNow.getBrowserRequirements();

            content.appendChild(DomUtils.createDom('div', 'hg-phone-err-title', translator.translate('browser_not_voice')));
            content.appendChild(DomUtils.createDom('div', 'hg-phone-err-description', translator.translate('switch_browser', [CurrentApp.Name])));

            if (BaseUtils.isArrayLike(browsers)) {
                const nodeList = [];

                browsers.forEach(function (browser) {
                    nodeList.push(DomUtils.createDom('li', '', translator.translate("browser_newer_version", [browser.label, browser.minVersion])));
                });

                content.appendChild(DomUtils.createDom('ul', {className: 'hg-phone-err-valid-browsers-list'}, nodeList));
            }
        } else {
            content.appendChild(DomUtils.createDom('div', 'hg-phone-err-title', translator.translate('unknown_reason')));
            content.appendChild(DomUtils.createDom('div', 'hg-phone-err-description', translator.translate('reason_phone_failure')));
        }

        const buttons = [];
            buttons.push(HgButtonUtils.createPrimaryButton(null, translator.translate('ok'), false, {
                'name'  : HgButtonUtils.ButtonSetName.PRIMARY_BUTTON,
                'loader': {
                    'extraCSSClass': 'grayscheme'
                }
            }));

        return new DialogLikeContent({
            'content'   : new ErrorAlertMessage({
                'content'       : content,
                'extraCSSClass' : ['large']
            }),
            'buttonSet' : new DialogButtonSet({
                'buttons': buttons
            })
        });
    }

    /**
     * Handle force registration request
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        e.preventDefault();

        const model = this.getModel();

        if (model && !!model['canRetry']) {
            this.dispatchEvent(PhoneEventType.RETRY_INITIALIZATION);
        } else {
            /* display panel with */
            const popup = this.getInfoDialog_();

            if (!popup.isOpen()) {
                /* compute content */
                const content = this.getInfoDialogContent_(model['reason']);

                popup.setContent(content);
                popup.open();

                /* add event listener for links in error messages */
                popup.getHandler()
                    .listen(popup.getElement(), BrowserEventType.CLICK, this.handleInfoLinkClick_);
            } else {
                popup.close();
            }
        }
    }

    /**
     * Handle error message click
     * @param {hf.events.Event} e
     * @private
     */
    handleErrorAction_(e) {
        const target = e.getTarget();

        if (target && target.nodeType == Node.ELEMENT_NODE && target.getAttribute('data-role') == 'service-setup') {
            this.dispatchEvent(PhoneEventType.SERVICE_REVIEW);
        }
    }

    /**
     * Handles press on err acknowledge button
     * @param {hf.events.Event} e The button set action event
     * @private
     */
    handleAckButtonAction_(e) {
        e.preventDefault();

        this.infoDialog_.close();
    }

    /**
     * Handles the click on link in errors, intercept and pass through WindowManager
     * @param {hf.events.Event} e
     * @private
     */
    handleInfoLinkClick_(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (target && target.nodeType == Node.ELEMENT_NODE && target.tagName == 'A') {
            e.preventDefault();

            /* open link */
            const linkRef = target.getAttribute('href');
            if (!StringUtils.isEmptyOrWhitespace(linkRef)) {
                WindowManager.open(linkRef);
            }

            return false;
        }

        return true;
    }
};