import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {EmailRules, MaxLengthRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {AuthAccountType, AuthTokenType} from "./../../../data/model/auth/Enums.js";
import {AuthTokenCollection} from "./AuthTokenCollection.js";

/**
 * Create new AuthObject model
 * @extends {DataModel}
 * @unrestricted
*/
export class RecoveryObject extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'identity', 'type': DataModelField.PredefinedTypes.STRING});

        /* multiple optional auth tokens can be passed at the authentication. */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* multiple optional auth tokens can be passed at the authentication. */
        this.addField({'name': 'authToken', 'type': AuthTokenCollection});

        /* Because from authToken only the CAPTCHA token is used for now */
        this.addField({'name': 'captchaToken', 'isReadOnly': true, 'getter': this.createLazyGetter('captchaToken',
                function() {
                    return /** @type {hg.data.model.auth.AuthToken} */(this['authToken'].find(function (authToken) {
                        return authToken.get('type') == AuthTokenType.CAPTCHA;
                    }));
                }
            )});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'identity'
        }));

        /* required size for email value on server side */
        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'identity',
            'maxLength'     : 255
        }));

        this.addValidationRule(new EmailRules({
            'targetProperty': 'identity',
            'priority'      : 1
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        /* default values */
        rawData['type'] = rawData['type'] || AuthAccountType.EMAIL;

        /* reset the 'captchaToken' field */
        this.setInternal('captchaToken', undefined);
    }
};
