import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {RecipientBase} from "./../party/Recipient.js";

/**
 * @extends {DataModel}
 * @unrestricted
*/
export class ThreadSearchResult extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * @inheritDoc
     */
    getUIdField() {
        return 'resultId';
    }

    /**
     * @inheritDoc
     */
    defineFields() {
        /* The id of result */
        this.addField({'name': 'resultId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The thread object that contains the message. */
        this.addField({'name': 'thread', 'type': RecipientBase});

        /* The Message object */
        this.addField({'name': 'message', 'type': Object});

        /* A summary of the message that matches the search query. */
        this.addField({'name': 'summary', 'type': DataModelField.PredefinedTypes.STRING});

        /* The relevance of the result */
        this.addField({'name': 'relevance', 'type': DataModelField.PredefinedTypes.NUMBER});
    }

    /**
     * @inheritDoc
     */
    defineCustomFields() {
        super.defineCustomFields();

        this.addField({'name': 'isReplyMessage', 'type': DataModelField.PredefinedTypes.BOOL,
            'getter': this.createLazyGetter('isReplyMessage', function() {
                return this['message']['replyTo'] != null;
            })
        });
    }
}