import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class ResourceLink extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);

        ResourceLink.instanceCount_++;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        ResourceLink.instanceCount_--;
    }

    /** @inheritDoc */
    getUIdField() {
        return 'resourceId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the resource */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* The type of the target source (a canonical name): HgResourceCanonicalNames */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'mustSerialize': true});

        /* Optional information about the referred resource. */
        this.addField({'name': 'hint', 'type': Object,
            'getter': function() {
                return this.getFieldValue('hint');
            }
        });
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }

    /** @inheritDoc */
    parseFieldValue(fieldName, value) {
        /* do not automatically transform into observable objects the values for these fields */
        if(fieldName === 'hint') {
            return value;
        }

        return super.parseFieldValue(fieldName, value);
    }
};

/**
 *
 * @type {number}
 * @protected
 */
ResourceLink.instanceCount_ = 0;