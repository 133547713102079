import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {Password} from "./Password.js";
import {ValidationRuleSeverity} from "./../../../../../../hubfront/phpnoenc/js/validation/RuleSeverity.js";
import Translator from "./../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {RegExpMatchRules, RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {RegExpUtils} from "./../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {StringUtils} from "./../../../../../../hubfront/phpnoenc/js/string/string.js";


/**
 * Create new InviteObjectParam model
 * Used when authentication type = HUBGETSINVITE
 * The invitation is checked and an username and a password must be provided, they will be stored here
 * tokenExpire and tokenHash will contain the invitation token info
 * @extends {Password}
 * @unrestricted
 */
export class InviteObjectParam extends Password {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* When AuthObject type = HUBGETSINVITE, username for the invited user. */
        this.addField({'name': 'username', 'type': DataModelField.PredefinedTypes.STRING});

        /* When AuthObject type = HUBGETSINVITE, password for the invited user. */
        this.addField({'name': 'password', 'type': DataModelField.PredefinedTypes.STRING});

        /* When AuthObject type = HUBGETSINVITE, expiration date for the token. */
        this.addField({'name': 'tokenExpire', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* When AuthObject type = HUBGETSINVITE, hash for the token. */
        this.addField({'name': 'tokenHash', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        /* The model is considered invalid if it has at least one warning */
        this.errorsSeverityLevel = ValidationRuleSeverity.WARNING;

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'username'
        }));

        /* Username rules */
        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'username',
            'pattern'       : RegExpUtils.USERNAME_LENGTH_RE,
            'priority'      : 1,
            'failMessage'   : translator.translate("username_requirements", [3])
        }));

        super.defineValidationRules();
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName == 'username') {
            /* the password strength depends on the username also */
            this.computePasswordStrength();
        }
    }

    /** @inheritDoc */
    getUserInputsForPasswordStrength() {
        const userInputs = [];

        if(!StringUtils.isEmptyOrWhitespace(this['username'])) {
            userInputs.push(this['username']);
        }

        return userInputs;
    }
};