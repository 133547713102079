import {DialogButtonSet, DialogDefaultButtonName} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Form} from "./../../../common/ui/Form.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {Extension} from "./../component/form/communicationdevices/Extension.js";
import {BrowserServiceForm} from "./../component/form/communicationdevices/BrowserService.js";
import {SettingsDevAppEventTypes, SettingsEventTypes, SettingsBusyContext} from "./../Enums.js";
import {BrowserServicePermissions} from "./../../../data/model/common/BrowserService.js";
import {MobileDevicesInstallationsContent} from "./../component/communicationdevices/MobileDevicesInstallationsContent.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.CommunicationDevicesView} object.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class CommunicationDevicesView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.settings.form.BrowserServiceForm}
         * @private
         */
        this.browserServiceForm_ = this.browserServiceForm_ === undefined ? null : this.browserServiceForm_;

        /**
         * Form displayed on app tab
         * @type {hg.module.settings.form.Extension}
         * @private
         */
        this.hubgetsPhoneForm_ = this.hubgetsPhoneForm_ === undefined ? null : this.hubgetsPhoneForm_;

        /**
         *
         * @type {hg.module.settings.MobileDevicesInstallationsContent}
         * @private
         */
        this.mobileDevicesInstallationsContent_ = this.mobileDevicesInstallationsContent_ === undefined ? null : this.mobileDevicesInstallationsContent_;

        /**
         *
         * @type {Object}
         * @private
         */
        this.actionButtons_ = this.actionButtons_ === undefined ? null : this.actionButtons_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.actionButtons_ = null;

        BaseUtils.dispose(this.hubgetsPhoneForm_);
        this.hubgetsPhoneForm_ = null;
        
        BaseUtils.dispose(this.mobileDevicesInstallationsContent_);
        this.mobileDevicesInstallationsContent_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, SettingsEventTypes.SERVICE_PERMISSION, this.handleServicePermission_)
            .listen(this, SettingsEventTypes.SERVICE_INSTALL, this.handleServiceInstall_)
            .listen(this, SettingsDevAppEventTypes.UNINSTALL_PLATFORM_APP_FROM_DEVICE, this.handleUninstallPlatformApp_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateDialogButtonsSet_}, {
            'sources': [
                {'sourceProperty': 'services'},
                {'sourceProperty': 'services.media'},
                {'sourceProperty': 'services.fullPermission'}
            ]
        });

        this.setBinding(this, {'set': this.onDirtyViewModelChange.bind(this)},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isDirty();
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-settings-communication-devices-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        const translator = Translator,
            footerButtonSet = new DialogButtonSet();

        this.actionButtons_ = {};

        /* Close button */
        this.actionButtons_[DialogDefaultButtonName.CLOSE] = HgButtonUtils.createSecondaryButton(null, translator.translate('Close'), false, {
            'name': DialogDefaultButtonName.CLOSE
        });

        /* Cancel button */
        this.actionButtons_[DialogDefaultButtonName.CANCEL] = HgButtonUtils.createSecondaryButton(null, translator.translate('Cancel'), false, {
            'name': DialogDefaultButtonName.CANCEL
        });

        /* Submit button */
        const saveBtn = HgButtonUtils.createPrimaryButton(null, translator.translate('APPLY'), false, {
            'name': DialogDefaultButtonName.SAVE,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });
        this.actionButtons_[DialogDefaultButtonName.SAVE] = saveBtn;
        this.setBinding(saveBtn, {'set': saveBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isSavable();
                    }
                }
            }
        );

        /* FIX button */
        this.actionButtons_[CommunicationDevicesView.Button.FIX] = HgButtonUtils.createPrimaryButton(null, translator.translate('fix'), false, {
            'name'  : CommunicationDevicesView.Button.FIX,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        });

        return footerButtonSet;
    }

    /**
     * Checker for change on viewmodel
     * In settings mode disable all other tabs except for the current one
     * @param {boolean} isDirty
     * @protected
     */
    onDirtyViewModelChange(isDirty) {
        const items = this.getTabSelector().getItems(),
            selectedIndex = this.getTabSelector().getSelectedIndex();

        if(items != null) {
            /* disable all items except the current one */
            items.forEach(function (item, index) {
                if (isDirty) {
                    item['enabled'] = (index != selectedIndex) ? false : true;
                } else {
                    /* activate all */
                    item['enabled'] = true;
                }
            });
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case CommonBusyContexts.SUBMIT:
                const submitBtn = this.actionButtons_[DialogDefaultButtonName.SAVE];
                if(submitBtn) {
                    submitBtn.setBusy(enable);
                }
                break;

            case SettingsBusyContext.LOAD_TAB:
                const currentForm = this.contentContainer.getContent();

                if (currentForm instanceof Form) {
                    currentForm.setBusy(enable, opt_busyContext);
                }

                break;

            case SettingsBusyContext.UNINSTALL_PLATFORM_APP:
                // do nothing
                break;

            default:
                super.enableIsBusyBehavior(enable, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    onCurrentCategoryChange(settingCategory) {
        super.onCurrentCategoryChange(settingCategory);

        /* update the content */
        this.setContentInternal(this.getContent_(settingCategory));

        /* update the footer button set */
        this.updateDialogButtonsSet_();

        const model = this.getModel();
        if (settingCategory == AccountMenuItemCategories.SERVICES &&
            model != null) {
            /* trigger services viewmodel loading */
            /** @type {hg.module.settings.presenter.CommunicationDevicesPresenter}*/(this.getPresenter()).loadServices();
        }
    }

    /**
     * Update the content according to the current selected category
     * @param {AccountMenuItemCategories} settingCategory
     * @return {?hf.ui.UIComponent}
     * @private
     */
    getContent_(settingCategory) {
        let content = null;

        switch (settingCategory) {
            case AccountMenuItemCategories.HUBGETS_PHONE:
                if (this.hubgetsPhoneForm_ == null) {
                    this.hubgetsPhoneForm_ = new Extension();

                    this.setBinding(this.hubgetsPhoneForm_, {'set': this.hubgetsPhoneForm_.setModel}, '');
                }

                content = this.hubgetsPhoneForm_;
                break;

            case AccountMenuItemCategories.MOBILE_DEVICES:
                if (this.mobileDevicesInstallationsContent_ == null) {
                    this.mobileDevicesInstallationsContent_ = new MobileDevicesInstallationsContent();

                    this.setBinding(this.mobileDevicesInstallationsContent_, {'set': this.mobileDevicesInstallationsContent_.setModel}, {
                        'source': this,
                        'sourceProperty': {'get': this.mobileDevicesInstallationsContent_.getModel}
                    });
                }

                content = this.mobileDevicesInstallationsContent_;
                break;

            case AccountMenuItemCategories.SERVICES:
                if (this.browserServiceForm_ == null) {
                    this.browserServiceForm_ = new BrowserServiceForm();

                    this.setBinding(this.browserServiceForm_, {'set': this.browserServiceForm_.setModel}, 'services');
                }

                content = this.browserServiceForm_;
                break;
        }

        return content;
    }

    /**
     *
     * @private
     */
    updateDialogButtonsSet_() {
        const translator = Translator,
            dialogButtonSet = this.getDialogButtonSet(),

            model = this.getModel(),
            currentCategory = model ? model['currentCategory'] : null,
            currentSettingModel = model ? model.getCurrentSettingModel() : null;

        const saveBtn = this.actionButtons_[DialogDefaultButtonName.SAVE],
            cancelBtn = this.actionButtons_[DialogDefaultButtonName.CANCEL],
            closeBtn = this.actionButtons_[DialogDefaultButtonName.CLOSE],
            fixBtn = this.actionButtons_[CommunicationDevicesView.Button.FIX];

        /* firstly, remove the existing buttons */
        dialogButtonSet.removeAll();

        /* secondly, update footer button set */
        switch (currentCategory) {
            case AccountMenuItemCategories.HUBGETS_PHONE:
                dialogButtonSet.addButton(cancelBtn);
                dialogButtonSet.addButton(saveBtn);

                break;

            case AccountMenuItemCategories.SERVICES:
                dialogButtonSet.addButton(cancelBtn);

                if(currentSettingModel != null
                    && currentSettingModel['media'] === BrowserServicePermissions.GRANTED
                    && (currentSettingModel['fullPermission'] || currentSettingModel.isDirty())) {
                    dialogButtonSet.addButton(saveBtn);
                }

                if(currentSettingModel != null
                    && !currentSettingModel['fullPermission']
                    && !currentSettingModel.isDirty()) {
                    dialogButtonSet.addButton(fixBtn);
                }

                break;

            case AccountMenuItemCategories.MOBILE_DEVICES:
                dialogButtonSet.addButton(closeBtn);
                
                break;
        }
    }

    /**
     * Handles service permission request
     * @param {hf.events.Event} e
     * @private
     */
    handleServicePermission_(e) {
        const service = /** @type {BrowserServices} */(e.getProperty('service'));

        if (!StringUtils.isEmptyOrWhitespace(service)) {
            /** @type {hg.module.settings.presenter.CommunicationDevicesPresenter} */(this.getPresenter()).requestServicePermission(service);
        }
    }

    /**
     * Handles service install request
     * @param {hf.events.Event} e
     * @private
     */
    handleServiceInstall_(e) {
        const service = /** @type {BrowserServices} */(e.getProperty('service'));

        if (!StringUtils.isEmptyOrWhitespace(service)) {
            /** @type {hg.module.settings.presenter.CommunicationDevicesPresenter} */(this.getPresenter()).installService(service);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleUninstallPlatformApp_(e) {
        let promisedResult = /**@type {Promise}*/(e.getProperty('promisedResult'));
        const appInstallation = /**@type {hg.data.model.dev.AppInstallation}*/(e.getProperty('appInstallation')),
            presenter = /**@type {hg.module.settings.presenter.CommunicationDevicesPresenter}*/(this.getPresenter());

        promisedResult = presenter.uninstallPlatformApp(appInstallation);

        e.addProperty('promisedResult', promisedResult);
    }

    /** @inheritDoc */
    onButtonAction(buttonKey) {
        if (buttonKey == CommunicationDevicesView.Button.FIX) {
            /** @type {hg.module.settings.presenter.CommunicationDevicesPresenter} */(this.getPresenter()).fixServicePermission();
            return false;
        } else {
            return super.onButtonAction(buttonKey);
        }
    }
};
//hf.app.ui.IView.addImplementation(hg.module.settings.view.CommunicationDevicesView);
/**
 * Specific button names
 * @enum {string}
 */
CommunicationDevicesView.Button = {
    FIX : 'fix'
};