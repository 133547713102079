import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {KeyValCollection} from "./../common/KeyValCollection.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Create new ServiceToken model
 * @extends {DataModel}
 * @unrestricted 
*/
export class ServiceToken extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'serviceTokenId';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'serviceTokenId', 'type': DataModelField.PredefinedTypes.STRING});

        /* AuthServiceTokenType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* AgentDevice */
        this.addField({'name': 'agentDevice', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'deviceId', 'type': DataModelField.PredefinedTypes.STRING});

        this.addField({'name': 'deviceInstanceId', 'type': DataModelField.PredefinedTypes.STRING});

        /* an unique identifier used when authentication is required for a specific entity. */
        this.addField({'name': 'identifier', 'type': DataModelField.PredefinedTypes.STRING});

        /* value of the token */
        this.addField({'name': 'value', 'type': DataModelField.PredefinedTypes.STRING});

        /* optional expire date */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* Time in seconds until the token expires. */
        this.addField({'name': 'lifetime', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* The URI of the ServiceToken is valid. */
        this.addField({'name': 'uri', 'type': DataModelField.PredefinedTypes.STRING});

        /* array of KeyVal that are associated with the ServiceToken (optional) */
        this.addField({'name': 'param', 'type': KeyValCollection});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* hostname where this ServiceToken is valid */
        this.addField({'name': 'hostname', 'type': DataModelField.PredefinedTypes.STRING});

        /* port where this ServiceToken is valid */
        this.addField({'name': 'port', 'type': DataModelField.PredefinedTypes.NUMBER});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
     
        if(!StringUtils.isEmptyOrWhitespace(rawData['uri'])) {
            const uri = UriUtils.createURL(rawData['uri']);

            rawData['hostname'] = uri.hostname || 'localhost';
            rawData['port'] = parseInt(uri.port, 10);
        }
    }
};