import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {AuthTokenType} from "./Enums.js";
import {RequiredRules} from "./../../../../../../hubfront/phpnoenc/js/validation/Rules.js";

/**
 * Create new AuthToken model
 * @extends {DataModel}
 * @unrestricted
*/
export class AuthToken extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'authTokenId';
    }

    /** @inheritDoc */
    defineFields() {
        this.addField({'name': 'authTokenId', 'type': DataModelField.PredefinedTypes.STRING});

        /* AuthTokenType */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* an uri that is used to serve the token */
        this.addField({'name': 'resource', 'type': DataModelField.PredefinedTypes.STRING});

        /* the value of the token */
        this.addField({'name': 'value', 'type': DataModelField.PredefinedTypes.STRING});

        /* optional expire date for the token */
        this.addField({'name': 'expires', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* an unique identifier used when authentication is required for a specific entity */
        this.addField({'name': 'identifier', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'value'
        }));
    }

    /**
     * @inheritDoc
     */
    onDataLoading(rawData) {
        /* default values */
        rawData['type'] = rawData['type'] ||  AuthTokenType.CAPTCHA;

    }
};
