import { BaseUtils } from '../base.js';
import { DateInterval } from './DateInterval.js';

/**
 *
 *
 */
export class DateUtils {
    constructor() {
        //
    }

    /**
     * Compares two dates. May be used as a sorting function.
     *
     * @param {Date} date1 Date to compare.
     * @param {Date} date2 Date to compare.
     * @returns {number} Comparison result. 0 if dates are the same, less than 0 if
     *     date1 is earlier than date2, greater than 0 if date1 is later than date2.
     */
    static compare(date1, date2) {
        let date1Time = date1 != null ? date1.getTime() : 0,
            date2Time = date2 != null ? date2.getTime() : 0;

        return date1Time - date2Time;
    }

    /**
     * Converts a timestamp to a string. The result string will look like one
     * of the following, depending how large the timestamp is:
     * Y years and M months
     * M months and D days
     * D days and h hours
     * h hours and m minutes
     * m minutes and s seconds
     *
     * @param {number} timestamp The timestamp (milliseconds).
     * @returns {string} The result string.
     * @throws {TypeError} When having an invalid parameter.
     */
    static timestampToString(timestamp) {
        if (!BaseUtils.isNumber(timestamp)) {
            throw new TypeError('The timestamp parameter should be a number.');
        }
        let years, months, days, hours, minutes, seconds, returnValue;
        timestamp = Math.round(timestamp / 1000);
        years = Math.floor(timestamp / 31556926);
        timestamp %= 31556926;
        months = Math.floor(timestamp / 2629744);
        if (years) {
            returnValue = `${years} year${years !== 1 ? 's' : ''}`;
            if (months) {
                returnValue += ` and ${months} month${months !== 1 ? 's' : ''}`;
            }
            return returnValue;
        }
        timestamp %= 2629744;
        days = Math.floor(timestamp / 86400);
        if (months) {
            returnValue = `${months} month${months !== 1 ? 's' : ''}`;
            if (days) {
                returnValue += ` and ${days} day${days !== 1 ? 's' : ''}`;
            }
            return returnValue;
        }
        timestamp %= 86400;
        hours = Math.floor(timestamp / 3600);
        if (days) {
            returnValue = `${days} day${days !== 1 ? 's' : ''}`;
            if (hours) {
                returnValue += ` and ${hours} hour${hours !== 1 ? 's' : ''}`;
            }
            return returnValue;
        }
        timestamp %= 3600;
        minutes = Math.floor(timestamp / 60);
        if (hours) {
            returnValue = `${hours} hour${hours !== 1 ? 's' : ''}`;
            if (minutes) {
                returnValue += ` and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
            }
            return returnValue;
        }
        seconds = timestamp % 60;
        if (minutes) {
            returnValue = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
            if (seconds) {
                returnValue += ` and ${seconds} second${seconds !== 1 ? 's' : ''}`;
            }
            return returnValue;
        }
        return 'a few moments';
    }

    /**
     * Transforms a number of seconds into a hh:mm:ss string.
     *
     * @param {number} seconds The number of seconds to be transformed.
     * @param {string=} opt_separator The separator between hh, mm, ss; by default is ":"
     * @returns {string} The formatted string.
     */
    static secondsToTime(seconds, opt_separator) {
        const separator = opt_separator || ':';

        if (BaseUtils.isEmpty(seconds)) {
            return `0${separator}00${separator}00`;
        }

        /* number of seconds from an hour */
        const secondsHour = 3600;
        /* number of seconds from a minute */
        const secondsMinute = 60;

        /* number of hours in the provided seconds */
        const hours = Math.floor(seconds / secondsHour);
        let remainder = seconds % secondsHour;

        /* number of minutes in the provided seconds */
        const minutes = Math.floor(remainder / secondsMinute);
        remainder = Math.floor(remainder % secondsMinute);

        return hours + separator + String(minutes).padStart(2, '0') + separator + String(remainder).padStart(2, '0');
    }

    /**
     * Generates a time string from a number of milliseconds. The result looks like this:
     *  - 2:03:46 (which means 2 hours, 3 minutes, 46 seconds)
     *  - 12:53 (which means 12 minutes and 53 seconds)
     *  - 00:24 (which means 24 seconds)
     * Note that this function does not show days, months or years. If the number of hours exceeds 24, it will show
     * something like 178:34:20, which means 178 hours, 34 minutes, 20 seconds.
     *
     * @param {number} milliseconds The number of milliseconds.
     * @returns {string} The result string.
     * @throws {TypeError} When having an invalid parameter.
     * @throws {RangeError} If the number of milliseconds is negative.
     *
     */
    static formatMillisecondsToTimeString(milliseconds) {
        if (!BaseUtils.isNumber(milliseconds)) {
            throw new TypeError('The milliseconds parameter should be a number.');
        }
        if (milliseconds < 0) {
            throw new RangeError('The milliseconds parameter should be a non-negative number.');
        }
        milliseconds = Math.round(milliseconds);
        let resultStr = '';

        const hours = Math.floor(milliseconds / 3600000);
        if (hours > 0) {
            resultStr += `${hours}:`;
        }
        milliseconds %= 3600000;

        const minutes = Math.floor(milliseconds / 60000);
        if (minutes < 10) {
            resultStr += '0';
        }
        resultStr += `${minutes}:`;
        milliseconds %= 60000;

        const seconds = Math.floor(milliseconds / 1000);
        if (seconds < 10) {
            resultStr += '0';
        }
        resultStr += seconds;

        return resultStr;
    }

    /**
     * Returns the number of days for a given month.
     *
     * @param {number} year Year part of date.
     * @param {number} month Month part of date.
     * @returns {number} The number of days for the given month.
     */
    static getNumberOfDaysInMonth(year, month) {
        switch (month) {
            case 1:
                return (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) ? 29 : 28;
            case 3:
            case 5:
            case 8:
            case 10:
                return 30;
        }
        return 31;
    }

    /**
     * Gets the tomorrow date time including delay between client-server.
     *
     * @returns {!Date} The tomorrow date time.
     */
    static tomorrow() {
        const now = new Date();

        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);

        DateUtils.addInterval(now, new DateInterval('d', 1));

        return now;
    }

    /**
     * Returns true if the 2 dates are in the same day.
     *
     * @param {Date} date The time to check.
     * @param {Date=} opt_now The current time.
     * @returns {boolean} Whether the dates are on the same day.
     */
    static isSameDay(date, opt_now) {
        const now = opt_now || new Date();
        return date.getDate() == now.getDate() && DateUtils.isSameMonth(date, now);
    }

    /**
     * Returns true if the 2 dates are in the same month.
     *
     * @param {Date} date The time to check.
     * @param {Date=} opt_now The current time.
     * @returns {boolean} Whether the dates are in the same calendar month.
     */
    static isSameMonth(date, opt_now) {
        const now = opt_now || new Date();
        return date.getMonth() == now.getMonth() && DateUtils.isSameYear(date, now);
    }

    /**
     * Returns true if the 2 dates are in the same year.
     *
     * @param {Date} date The time to check.
     * @param {Date=} opt_now The current time.
     * @returns {boolean} Whether the dates are in the same calendar year.
     */
    static isSameYear(date, opt_now) {
        const now = opt_now || new Date();
        return date.getFullYear() == now.getFullYear();
    }

    /**
     * Get the number of the current week
     *
     * @returns {number}
     */
    static getNumberOfWeek() {
        const today = new Date();
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today - firstDayOfYear) / 86400000;

        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    /**
     * @param {Date} date
     * @returns {number} The day of week according to firstDayOfWeek setting.
     */
    static getWeekday(date) {
        return (((date.getDay() + 6) % 7) - 7) % 7;
    }

    /**
     *
     * @param {string} format
     * @param {string=} locale
     */
    static getWeekdaysNames(format = 'short', locale = navigator.language) {
        let formatter = new Intl.DateTimeFormat(locale, { weekday: format }),
            date = new Date(0),
            weekdays = [];

        for (let i = 0; i < 7; i++) {
            date.setDate(4 + i);
            weekdays.push(formatter.format(date));
        }

        return weekdays;
    }

    /**
     * Returns an array containing the names of the months for a specified locales
     *
     * @param {string} format
     * @param {string=} locale
     * @returns {Array}
     */
    static getMonthsNames(format = 'short', locale = navigator.language) {
        let formatter = new Intl.DateTimeFormat(locale, { month: format }),
            date = new Date(Date.UTC(2000, 1, 1, 0, 0, 0)),
            months = [];

        for (let i = 0; i < 12; i++) {
            date.setMonth(i);
            months.push(formatter.format(date));
        }

        return months;
    }

    /**
     * Performs date calculation by adding the supplied interval to the date.
     *
     * @param {Date} date
     * @param {DateInterval} interval Date interval to add.
     */
    static addInterval(date, interval) {
        if (interval.years || interval.months) {
            // As months have different number of days adding a month to Jan 31 by just
            // setting the month would result in a date in early March rather than Feb
            // 28 or 29. Doing it this way overcomes that problem.

            // adjust year and month, accounting for both directions
            let month = date.getMonth() + interval.months + interval.years * 12;
            const year = date.getFullYear() + Math.floor(month / 12);
            month %= 12;
            if (month < 0) {
                month += 12;
            }

            const daysInTargetMonth = DateUtils.getNumberOfDaysInMonth(year, month);
            const newDate = Math.min(daysInTargetMonth, date.getDate());

            // avoid inadvertently causing rollovers to adjacent months
            date.setDate(1);

            date.setFullYear(year);
            date.setMonth(month);
            date.setDate(newDate);
        }

        if (interval.days) {
            // Convert the days to milliseconds and add it to the UNIX timestamp.
            // Taking noon helps to avoid 1 day error due to the daylight saving.
            const noon = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
            const result = new Date(noon.getTime() + interval.days * 86400000);

            // Set date to 1 to prevent rollover caused by setting the year or month.
            date.setDate(1);
            date.setFullYear(result.getFullYear());
            date.setMonth(result.getMonth());
            date.setDate(result.getDate());

            if (date.getDate() != result.getDate()) {
                const dir = date.getDate() < result.getDate() ? 1 : -1;
                date.setUTCHours(date.getUTCHours() + dir);
            }
        }

        if (interval.hours) {
            date.setUTCHours(date.getUTCHours() + interval.hours);
        }

        if (interval.minutes) {
            date.setUTCMinutes(date.getUTCMinutes() + interval.minutes);
        }

        if (interval.seconds) {
            date.setUTCSeconds(date.getUTCSeconds() + interval.seconds);
        }
    }

    /**
     * Returns the string format based on the language selected in the browser.
     *
     * @returns {*|string}
     */
    static getLocaleDateFormatString() {
        let formats = {
            'ar-SA': 'dd/MM/yy',
            'bg-BG': 'dd.M.yyyy',
            'ca-ES': 'dd/MM/yyyy',
            'zh-TW': 'yyyy/M/d',
            'cs-CZ': 'd.M.yyyy',
            'da-DK': 'dd-MM-yyyy',
            'de-DE': 'dd.MM.yyyy',
            'el-GR': 'd/M/yyyy',
            'en-US': 'M/d/yyyy',
            'fi-FI': 'd.M.yyyy',
            'fr-FR': 'dd/MM/yyyy',
            'he-IL': 'dd/MM/yyyy',
            'hu-HU': 'yyyy. MM. dd.',
            'is-IS': 'd.M.yyyy',
            'it-IT': 'dd/MM/yyyy',
            'ja-JP': 'yyyy/MM/dd',
            'ko-KR': 'yyyy-MM-dd',
            'nl-NL': 'd-M-yyyy',
            'nb-NO': 'dd.MM.yyyy',
            'pl-PL': 'yyyy-MM-dd',
            'pt-BR': 'd/M/yyyy',
            ro: 'dd.MM.yyyy',
            'ru-RU': 'dd.MM.yyyy',
            'hr-HR': 'd.M.yyyy',
            'sk-SK': 'd. M. yyyy',
            'sq-AL': 'yyyy-MM-dd',
            'sv-SE': 'yyyy-MM-dd',
            'th-TH': 'd/M/yyyy',
            'tr-TR': 'dd.MM.yyyy',
            'ur-PK': 'dd/MM/yyyy',
            'id-ID': 'dd/MM/yyyy',
            'uk-UA': 'dd.MM.yyyy',
            'be-BY': 'dd.MM.yyyy',
            'sl-SI': 'd.M.yyyy',
            'et-EE': 'd.MM.yyyy',
            'lv-LV': 'yyyy.MM.dd.',
            'lt-LT': 'yyyy.MM.dd',
            'fa-IR': 'MM/dd/yyyy',
            'vi-VN': 'dd/MM/yyyy',
            'hy-AM': 'dd.MM.yyyy',
            'az-Latn-AZ': 'dd.MM.yyyy',
            'eu-ES': 'yyyy/MM/dd',
            'mk-MK': 'dd.MM.yyyy',
            'af-ZA': 'yyyy/MM/dd',
            'ka-GE': 'dd.MM.yyyy',
            'fo-FO': 'dd-MM-yyyy',
            'hi-IN': 'dd-MM-yyyy',
            'ms-MY': 'dd/MM/yyyy',
            'kk-KZ': 'dd.MM.yyyy',
            'ky-KG': 'dd.MM.yy',
            'sw-KE': 'M/d/yyyy',
            'uz-Latn-UZ': 'dd/MM yyyy',
            'tt-RU': 'dd.MM.yyyy',
            'pa-IN': 'dd-MM-yy',
            'gu-IN': 'dd-MM-yy',
            'ta-IN': 'dd-MM-yyyy',
            'te-IN': 'dd-MM-yy',
            'kn-IN': 'dd-MM-yy',
            'mr-IN': 'dd-MM-yyyy',
            'sa-IN': 'dd-MM-yyyy',
            'mn-MN': 'yy.MM.dd',
            'gl-ES': 'dd/MM/yy',
            'kok-IN': 'dd-MM-yyyy',
            'syr-SY': 'dd/MM/yyyy',
            'dv-MV': 'dd/MM/yy',
            'ar-IQ': 'dd/MM/yyyy',
            'zh-CN': 'yyyy/M/d',
            'de-CH': 'dd.MM.yyyy',
            'en-GB': 'dd/MM/yyyy',
            'es-MX': 'dd/MM/yyyy',
            'fr-BE': 'd/MM/yyyy',
            'it-CH': 'dd.MM.yyyy',
            'nl-BE': 'd/MM/yyyy',
            'nn-NO': 'dd.MM.yyyy',
            'pt-PT': 'dd-MM-yyyy',
            'sr-Latn-CS': 'd.M.yyyy',
            'sv-FI': 'd.M.yyyy',
            'az-Cyrl-AZ': 'dd.MM.yyyy',
            'ms-BN': 'dd/MM/yyyy',
            'uz-Cyrl-UZ': 'dd.MM.yyyy',
            'ar-EG': 'dd/MM/yyyy',
            'zh-HK': 'd/M/yyyy',
            'de-AT': 'dd.MM.yyyy',
            'en-AU': 'd/MM/yyyy',
            'es-ES': 'dd/MM/yyyy',
            'fr-CA': 'yyyy-MM-dd',
            'sr-Cyrl-CS': 'd.M.yyyy',
            'ar-LY': 'dd/MM/yyyy',
            'zh-SG': 'd/M/yyyy',
            'de-LU': 'dd.MM.yyyy',
            'en-CA': 'dd/MM/yyyy',
            'es-GT': 'dd/MM/yyyy',
            'fr-CH': 'dd.MM.yyyy',
            'ar-DZ': 'dd-MM-yyyy',
            'zh-MO': 'd/M/yyyy',
            'de-LI': 'dd.MM.yyyy',
            'en-NZ': 'd/MM/yyyy',
            'es-CR': 'dd/MM/yyyy',
            'fr-LU': 'dd/MM/yyyy',
            'ar-MA': 'dd-MM-yyyy',
            'en-IE': 'dd/MM/yyyy',
            'es-PA': 'MM/dd/yyyy',
            'fr-MC': 'dd/MM/yyyy',
            'ar-TN': 'dd-MM-yyyy',
            'en-ZA': 'yyyy/MM/dd',
            'es-DO': 'dd/MM/yyyy',
            'ar-OM': 'dd/MM/yyyy',
            'en-JM': 'dd/MM/yyyy',
            'es-VE': 'dd/MM/yyyy',
            'ar-YE': 'dd/MM/yyyy',
            'en-029': 'MM/dd/yyyy',
            'es-CO': 'dd/MM/yyyy',
            'ar-SY': 'dd/MM/yyyy',
            'en-BZ': 'dd/MM/yyyy',
            'es-PE': 'dd/MM/yyyy',
            'ar-JO': 'dd/MM/yyyy',
            'en-TT': 'dd/MM/yyyy',
            'es-AR': 'dd/MM/yyyy',
            'ar-LB': 'dd/MM/yyyy',
            'en-ZW': 'M/d/yyyy',
            'es-EC': 'dd/MM/yyyy',
            'ar-KW': 'dd/MM/yyyy',
            'en-PH': 'M/d/yyyy',
            'es-CL': 'dd-MM-yyyy',
            'ar-AE': 'dd/MM/yyyy',
            'es-UY': 'dd/MM/yyyy',
            'ar-BH': 'dd/MM/yyyy',
            'es-PY': 'dd/MM/yyyy',
            'ar-QA': 'dd/MM/yyyy',
            'es-BO': 'dd/MM/yyyy',
            'es-SV': 'dd/MM/yyyy',
            'es-HN': 'dd/MM/yyyy',
            'es-NI': 'dd/MM/yyyy',
            'es-PR': 'dd/MM/yyyy',
            'am-ET': 'd/M/yyyy',
            'tzm-Latn-DZ': 'dd-MM-yyyy',
            'iu-Latn-CA': 'd/MM/yyyy',
            'sma-NO': 'dd.MM.yyyy',
            'mn-Mong-CN': 'yyyy/M/d',
            'gd-GB': 'dd/MM/yyyy',
            'en-MY': 'd/M/yyyy',
            'prs-AF': 'dd/MM/yy',
            'bn-BD': 'dd-MM-yy',
            'wo-SN': 'dd/MM/yyyy',
            'rw-RW': 'M/d/yyyy',
            'qut-GT': 'dd/MM/yyyy',
            'sah-RU': 'MM.dd.yyyy',
            'gsw-FR': 'dd/MM/yyyy',
            'co-FR': 'dd/MM/yyyy',
            'oc-FR': 'dd/MM/yyyy',
            'mi-NZ': 'dd/MM/yyyy',
            'ga-IE': 'dd/MM/yyyy',
            'se-SE': 'yyyy-MM-dd',
            'br-FR': 'dd/MM/yyyy',
            'smn-FI': 'd.M.yyyy',
            'moh-CA': 'M/d/yyyy',
            'arn-CL': 'dd-MM-yyyy',
            'ii-CN': 'yyyy/M/d',
            'dsb-DE': 'd. M. yyyy',
            'ig-NG': 'd/M/yyyy',
            'kl-GL': 'dd-MM-yyyy',
            'lb-LU': 'dd/MM/yyyy',
            'ba-RU': 'dd.MM.yy',
            'nso-ZA': 'yyyy/MM/dd',
            'quz-BO': 'dd/MM/yyyy',
            'yo-NG': 'd/M/yyyy',
            'ha-Latn-NG': 'd/M/yyyy',
            'fil-PH': 'M/d/yyyy',
            'ps-AF': 'dd/MM/yy',
            'fy-NL': 'd-M-yyyy',
            'ne-NP': 'M/d/yyyy',
            'se-NO': 'dd.MM.yyyy',
            'iu-Cans-CA': 'd/M/yyyy',
            'sr-Latn-RS': 'd.M.yyyy',
            'si-LK': 'yyyy-MM-dd',
            'sr-Cyrl-RS': 'd.M.yyyy',
            'lo-LA': 'dd/MM/yyyy',
            'km-KH': 'yyyy-MM-dd',
            'cy-GB': 'dd/MM/yyyy',
            'bo-CN': 'yyyy/M/d',
            'sms-FI': 'd.M.yyyy',
            'as-IN': 'dd-MM-yyyy',
            'ml-IN': 'dd-MM-yy',
            'en-IN': 'dd-MM-yyyy',
            'or-IN': 'dd-MM-yy',
            'bn-IN': 'dd-MM-yy',
            'tk-TM': 'dd.MM.yy',
            'bs-Latn-BA': 'd.M.yyyy',
            'mt-MT': 'dd/MM/yyyy',
            'sr-Cyrl-ME': 'd.M.yyyy',
            'se-FI': 'd.M.yyyy',
            'zu-ZA': 'yyyy/MM/dd',
            'xh-ZA': 'yyyy/MM/dd',
            'tn-ZA': 'yyyy/MM/dd',
            'hsb-DE': 'd. M. yyyy',
            'bs-Cyrl-BA': 'd.M.yyyy',
            'tg-Cyrl-TJ': 'dd.MM.yy',
            'sr-Latn-BA': 'd.M.yyyy',
            'smj-NO': 'dd.MM.yyyy',
            'rm-CH': 'dd/MM/yyyy',
            'smj-SE': 'yyyy-MM-dd',
            'quz-EC': 'dd/MM/yyyy',
            'quz-PE': 'dd/MM/yyyy',
            'hr-BA': 'd.M.yyyy.',
            'sr-Latn-ME': 'd.M.yyyy',
            'sma-SE': 'yyyy-MM-dd',
            'en-SG': 'd/M/yyyy',
            'ug-CN': 'yyyy-M-d',
            'sr-Cyrl-BA': 'd.M.yyyy',
            'es-US': 'M/d/yyyy'
        };

        return formats[window.navigator.language] || 'dd/MM/yyyy';
    }

    /**
     * Converts a string date to MonthDayYear default format from the locale date format it is in.
     *
     * @param {string} dateString
     * @returns {string}
     */
    static convertToMonthDayYearDateFromLocaleFormat(dateString) {
        const stringFormat = this.getLocaleDateFormatString(),
            formatParts = stringFormat.split(/\W+/),
            dateParts = dateString.split(/\D+/),
            MDYForm = [0, 0, 0];

        /* Do nothing if the dateString does not have a date format */
        if (dateParts.length != 3) {
            return dateString;
        }

        formatParts.forEach((currentValue, index) => {
            switch (currentValue[0]) {
                case 'd':
                    MDYForm[1] = dateParts[index];
                    break;
                case 'M':
                    MDYForm[0] = dateParts[index];
                    break;
                case 'y':
                    MDYForm[2] = dateParts[index];
                    break;
            }
        });

        return MDYForm.join('/');
    }

    /**
     * Creates a date placeholder considering the local format.
     *
     * @param {object=} opt_format for ex. HgAppConfig.SHORT_DATE_FORMAT |
     *      HgAppConfig.MEDIUM_DATE_FORMAT | HgAppConfig.MEDIUM_DATE_TIME_FORMAT
     * @returns {string}
     */
    static getLocalDatePlaceholder(opt_format) {
        const stringFormat = this.getLocaleDateFormatString(),
            dateParts = stringFormat.split(/\W+/),
            separator = stringFormat[stringFormat.indexOf(dateParts[1]) - 1];
        if (opt_format && opt_format.year == '2-digit') {
            return (dateParts[0].slice(0, 2) + separator + dateParts[1].slice(0, 2) + separator + dateParts[2].slice(0, 2)).toUpperCase();
        }

        return (dateParts[0] + separator + dateParts[1] + separator + dateParts[2]).toUpperCase();
    }
}
