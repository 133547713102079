import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
/**
 * Constants for event names dispatched by bubbles.
 * @enum {string}
 * @readonly
 */
export const ContactBubbleEventType = {
    /** triggered from the PhoneContent when clicking on a phone number to call a contact
     * @event ContactBubbleEventType.PERSON_CALL */
    PERSON_CALL : StringUtils.createUniqueString('personcall'),

    /** triggered from the ContactBubble when submitting an email
     * @event ContactBubbleEventType.PERSON_EMAIL */
    PERSON_EMAIL : StringUtils.createUniqueString('personemail'),

    /** triggered from the ContactBubble when choosing to text (chat) a person
     * @event ContactBubbleEventType.PERSON_TEXT */
    PERSON_TEXT : StringUtils.createUniqueString('persontext')
};