import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {PersonCSVMapping} from "./PersonCSVMapping.js";

/**
 * PersonCSVMapping collection
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class PersonCSVMappingCollection extends DataModelCollection {
 /**
  * @param {Array=} opt_initItems
 */
 constructor(opt_initItems) {
     const opt_config = {
         'defaultItems': opt_initItems,
         'model': PersonCSVMapping
     };

     super(opt_config);
 }
};