import {FormFieldValidateOn} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {FieldGroup} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {Password} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Password.js";
import {Text} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {DropDownList} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Label, LabelTextAlign} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Label.js";
import {Radio} from "./../../../../../../../../hubfront/phpnoenc/js/ui/form/field/Radio.js";
import {HgCurrentUser} from "./../../../../../app/CurrentUser.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {HgSettingsModuleUtils} from "./../../../Common.js";
import {AuthAccountType} from "./../../../../../data/model/auth/Enums.js";
import {ListItemsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {PRIMARY_CONTACT_LABEL} from "./../../../../../data/model/person/Enums.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class AppSettings extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The radio group containing color scheme options
         * @type {hf.ui.selector.Selector}
         * @private
         */
        this.themeRadioGroup_;

        /**
         * Color scheme label
         * @type {hf.ui.Label}
         * @protected
         */
        this.themeLabel_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'ux';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    createContentDom() {
        this.contentContainer.addExtraCSSClass('hg-setup-category-content-app');
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.themeRadioGroup_);
        this.themeRadioGroup_ = null;

        BaseUtils.dispose(this.themeLabel_);
        this.themeLabel_ = null;
    }

    /**
     * Init bindings and components based on account type
     * @param {string} accountType
     */
    updateContent(accountType) {

        // todo: make sure accountType is defined with an accepted value;

        this.initFieldsInternal_(accountType);

        this.createContentDomInternal_(accountType);

        this.initBindingsInternal_(accountType);
    }

    /**
     * Init the form fields based on the accountType
     * @param {string} accountType
     * @private
     */
    initFieldsInternal_(accountType) {
        const translator = Translator;

        if ( accountType ==  AuthAccountType.HUBGETS ) {
            this.addField(new Text({
                'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('password_recovery_email')),
                'name'        : AppSettings.FieldName.EMAIL,
                'readonly'    : true,
                'autocomplete': false
            }));

            this.addField(new Text({
                'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('username')),
                'name'        : AppSettings.FieldName.USERNAME,
                'readonly'    : true,
                'autocomplete': false
            }));

            this.addField(new Password({
                'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('old_password')),
                'name'        : AppSettings.FieldName.PASS,
                'autocomplete': false,
                'autofocus'   : true
            }));

            this.addField(new Password({
                'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('new_password')),
                'name'        : AppSettings.FieldName.NEW_PASS,
                'showPasswordTips': true,
                'hint'        : {
                    'tips': [
                        translator.translate('password_doesnot_contain_username'),
                        translator.translate('password_min_length'),
                        translator.translate('password_contains_upperlower_chars'),
                        translator.translate('password_contains_digits_chars'),
                        translator.translate('password_contains_special_chars'),
                        translator.translate('password_doesnot_contain_dictionary_words')
                    ],
                    'showAlways': true,
                    'tooltip': {
                        'header': translator.translate('password_tips'),
                        'extraCSSClass': this.getDefaultBaseCSSClass() + '-hint-popup',
                        'horizontalOffset': 4
                    }
                }
            }));

            this.addField(new Password({
                'label'       : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('repeat_new_password')),
                'name'        : AppSettings.FieldName.CONFIRM_PASS,
                'autocomplete': false,
                'validation'  : {
                    'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                    'markInvalidDelay': 0,
                    'showErrors': true,
                    'errorsTooltip': {
                        'horizontalOffset': 4
                    }
                }
            }));
        }

        this.addField(new DropDownList({
            'itemsSource'    : [{'code': 'en', 'name': translator.translate('default_language', ['English'])}, {'code': 'en', 'name': 'English'}],
            'label'          : HgSettingsModuleUtils.getFieldLabelConfigOptions(translator.translate('language')),
            'displayField'   : 'name',
            'valueField'     : 'code',
            'name'           : AppSettings.FieldName.LANGUAGE,
            'value'          : 'en'
        }));

        this.themeLabel_ = new Label({
            'content'       : translator.translate('skin'),
            'extraCSSClass' : 'hf-label-left',
            'textAlign'     : LabelTextAlign.RIGHT
        });

        this.themeRadioGroup_ = new Selector({
            'extraCSSClass'             : 'hg-form-field-radio-group hg-theme-selector',
            'itemsLayout'               : ListItemsLayout.HSTACK,
            'itemsSource'				: ['blue'],
            'allowsSingleSelectionToggling': false,
            'allowsMultipleSelection': false,
            'itemContentFormatter'      : function (model, selectorItem) {
                if(model == null) {
                    return null;
                }

                const inputLabel = '';
                let radioItem, isChecked = false;

                switch(model) {
                    case 'blue':
                        isChecked = true;
                        break;

                }

                radioItem = new Radio({
                    'name'      : 'color-scheme-radio',
                    'extraCSSClass' : 'hg-theme-option hg-theme-option-' + model,
                    'checked'   : true
                });

                /* default theme  is selected, uncomment when managing themes */
                /*selectorItem.setSelected(isChecked);

                 selectorItem.setBinding(
                 radioItem,
                 {'set': radioItem.setChecked, 'get': radioItem.isChecked},
                 {
                 'source': selectorItem,
                 'sourceProperty': {'set': selectorItem.setSelected, 'get': selectorItem.isSelected},
                 'updateTargetTrigger': [UIComponentEventTypes.SELECT, UIComponentEventTypes.UNSELECT],
                 'converter': {
                 'sourceToTargetFn': function(value) {
                 return value;
                 }
                 }
                 }
                 );*/

                return radioItem;
            }
        });
    }

    /**
     * @param {string} accountType
     * @private
     */
    createContentDomInternal_(accountType) {
        const translator = Translator;

        if ( accountType ==  AuthAccountType.HUBGETS ) {
            const passFieldGroup = new FieldGroup({
                'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
                'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('account_security'), translator.translate('forget_password')),
                'fields': [
                    this.getField(AppSettings.FieldName.EMAIL),
                    this.getField(AppSettings.FieldName.USERNAME),
                    this.getField(AppSettings.FieldName.PASS),
                    this.getField(AppSettings.FieldName.NEW_PASS),
                    this.getField(AppSettings.FieldName.CONFIRM_PASS)
                ]
            });

            this.contentContainer.addChild(passFieldGroup, true);
        }

        const uxFieldGroup = new FieldGroup({
            'extraCSSClass': 'hg-setup-category-content-app-ux-field-group',
            'fieldsLayout': FieldGroupFieldsLayout.VERTICAL,
            'label': HgSettingsModuleUtils.getFieldGroupLabelConfigOptions(translator.translate('interface'), translator.translate('customize_display_preferences')),
            'fields': [
                this.getField(AppSettings.FieldName.LANGUAGE)
            ]
        });

        this.contentContainer.addChild(uxFieldGroup, true);
        this.contentContainer.addChild(this.themeLabel_, true);
        this.contentContainer.addChild(this.themeRadioGroup_, true);
    }

    /**
     * @param {string} accountType
     * @private
     */
    initBindingsInternal_(accountType) {

        if ( accountType ==  AuthAccountType.HUBGETS ) {
            const emailField = this.getField(AppSettings.FieldName.EMAIL);
            this.setBinding(emailField, {'set': emailField.setValue},
                {
                    'sourceProperty': 'contact.email',
                    'converter': {
                        'sourceToTargetFn': function (emailCollection) {
                            if (emailCollection != null) {
                                emailCollection = /** @type {hg.data.model.person.contact.EmailCollection} */(emailCollection);

                                const preferredEmail = emailCollection.find(function (email) {
                                    return email['label'] == PRIMARY_CONTACT_LABEL;
                                });

                                if (preferredEmail) {
                                    return preferredEmail['value'];
                                }
                            }

                            return '';
                        }
                    }
                });

            const usernameField = this.getField(AppSettings.FieldName.USERNAME);
            this.setBinding(usernameField, {'set': usernameField.setValue}, {
                'source': HgCurrentUser,
                'sourceProperty': 'username'
            });

            this.bindFieldValue(this.getField(AppSettings.FieldName.PASS), 'passToken.token');

            const password = this.getField(AppSettings.FieldName.NEW_PASS);
            this.bindFieldValue(password, 'passToken.password');
            this.setBinding(password, {'set': password.setStrengthLevel}, 'passToken.passwordStrength');

            const confirmPassword = this.getField(AppSettings.FieldName.CONFIRM_PASS);
            this.bindFieldValue(confirmPassword, 'passToken.confirmPassword');

            this.setBinding(confirmPassword, {'set': confirmPassword.setEnabled},
                {
                    'sourceProperty': 'passToken.passwordStrength',
                    'converter': {
                        'sourceToTargetFn': function (passwordStrength) {
                            return passwordStrength >= 2;
                        }
                    }
                }
            );
        }
    }
};
/**
 * Field names used in the form
 * @enum {string}
 */
AppSettings.FieldName = {
    EMAIL           : 'person_email',
    USERNAME        : 'person_username',
    PASS            : 'person_password',
    NEW_PASS        : 'person_new_password',
    CONFIRM_PASS    : 'person_confirm_password',
    LANGUAGE        : 'ux_language',
    THEME           : 'ux_theme'
};