import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {
    CollectionChangeEvent,
    ObservableCollectionChangeAction
} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {TagAggregatorViewmodel} from "./../viewmodel/TagAggregator.js";
import {HgMetacontentUtils} from "./../../string/metacontent.js";

/**
 * Creates a {@see hg.common.ui.message.MessageActionViewModel} object
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class MessageActionViewModel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    init(opt_initialData) {
        opt_initialData = opt_initialData || {};

        super.init(opt_initialData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The target message */
        this.addField({'name': 'message'});

        /* the parent message group the message belongs to. It may be missing. For it applies only for chat messages */
        this.addField({'name': 'messageGroup'});

        /* Manages message tags */
        this.addField({'name': 'tagAggregator', 'getter': this.createLazyGetter('tagAggregator',
            function() {
                const message = this['message'];

                return message != null ?
                    new TagAggregatorViewmodel({
                        'resourceId'    : message['messageId'],
                        'resourceType'  : HgResourceCanonicalNames.MESSAGE,
                        'resource'      : message,
                        'tagCount'      : message['tagCount'],
                        'tags'          : message['tag']
                    }) :
                    null;
            }
        )});

        /* permalink */
        this.addField({'name': 'permalink', 'getter': this.createLazyGetter('permalink',
            function() {
                return this.getPermalink_();
            }
        )});    
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'message') {
            this.set('tagAggregator', undefined);
            this.set('permalink', undefined);
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const ret = super.onChildChange(fieldName, e);

        if(fieldName === 'message' && e['payload']['field'] == 'messageId' && this.fieldHasValue('tagAggregator')) {
            this['tagAggregator']['resourceId'] =  this['message']['messageId'];
        }

        /* if there is a message group (chat + chat history) then make sure to update the 'message' with the first message of the group */
        if(fieldName === 'messageGroup' && /**@type {string}*/(e['payload']['fieldPath']).indexOf('message') > -1 && e instanceof CollectionChangeEvent) {
            const allowedChangeActions = [ObservableCollectionChangeAction.ADD,
                ObservableCollectionChangeAction.REMOVE,
                ObservableCollectionChangeAction.REPLACE,
                ObservableCollectionChangeAction.RESET];

            if(allowedChangeActions.includes(e['payload']['action'])) {
                this['message'] = this['messageGroup']['message'].getAt(0);
            }
        }

        return ret;
    }

    /**
     * @returns {?string}
     * @private
     */
    getPermalink_() {
        const message = this['message'];
        let tag = '';

        if (message) {
            tag = HgMetacontentUtils.buildActionMetaTag(HgMetacontentUtils.ActionTag.MESSAGE, message);
        }

        return tag;
    }
};