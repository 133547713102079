/**
 * A base class for event objects, so that they can support preventDefault and
 * stopPropagation.
 *
 * @suppress {underscore} Several properties on this class are technically
 *     public, but referencing these properties outside this package is strongly
 *     discouraged.
 *
 *
 */
export class Event {
    /**
     * @param {string} type Event Type.
     * @param {object=} opt_target Reference to the object that is the target of
     *     this event. It has to implement the {@code EventTarget} interface
     *     declared at {@link http://developer.mozilla.org/en/DOM/EventTarget}.
     */
    constructor(type, opt_target) {
        /**
         * Event type.
         *
         * @type {string}
         */
        this.type = type;

        /**
         * TODO(tbreisacher): The type should probably be
         * EventTarget|hf.events.EventTarget.
         *
         * Target of the event.
         *
         * @type {object | undefined}
         */
        this.target = opt_target;

        /**
         * Object that had the listener attached.
         *
         * @type {object | undefined}
         */
        this.currentTarget = this.target;

        /**
         * Whether to cancel the event in internal capture/bubble processing for IE.
         *
         * @type {boolean}
         * @public
         */
        this.propagationStopped_ = false;

        /**
         * Whether the default action has been prevented.
         * This is a property to match the W3C specification at
         * {@link http://www.w3.org/TR/DOM-Level-3-Events/
         * #events-event-type-defaultPrevented}.
         * Must be treated as read-only outside the class.
         *
         * @type {boolean}
         */
        this.defaultPrevented = false;

        /**
         * Return value for in internal capture/bubble processing for IE.
         *
         * @type {boolean}
         * @public
         */
        this.returnValue_ = true;
    }

    /**
     * Stops event propagation.
     */
    stopPropagation() {
        this.propagationStopped_ = true;
    }

    /**
     * Prevents the default action, for example a link redirecting to a url.
     */
    preventDefault() {
        this.defaultPrevented = true;
        this.returnValue_ = false;
    }

    /**
     * Appends a new custom property to the base closure event
     *
     * @param {string} key Event property
     * @param {*} value Event property value
     */
    addProperty(key, value) {
        this[key] = value;
    }

    /**
     * Fetch event custom property
     *
     * @param {string} key Event property
     * @returns {*} Value associated with this property
     *
     */
    getProperty(key) {
        /* Custom defined */
        if (this[key] !== undefined) {
            return this[key];
        }

        /* Standard goog event property */
        switch (key) {
            case 'type': {
                return this.type;
            }
            case 'target': {
                return this.target;
            }
            case 'currentTarget': {
                return this.currentTarget;
            }
            default: {
                return null;
            }
        }
    }

    /**
     * Fetch event type
     *
     * @returns {string} Value associated with this property
     *
     */
    getType() {
        return this.type;
    }

    /**
     * Fetch event target
     *
     * @returns {*} Value associated with this property
     *
     */
    getTarget() {
        return this.target;
    }

    /**
     * Fetch event current target
     *
     * @returns {*} Value associated with this property
     *
     */
    getCurrentTarget() {
        return this.currentTarget;
    }

    /**
     * Stops the propagation of the event. It is equivalent to
     * {@code e.stopPropagation()}, but can be used as the callback argument of
     * {@link EventsUtils.listen} without declaring another function.
     *
     * @param {!hf.events.Event} e An event.
     */
    static stopPropagation(e) {
        e.stopPropagation();
    }

    /**
     * Prevents the default action. It is equivalent to
     * {@code e.preventDefault()}, but can be used as the callback argument of
     * {@link EventsUtils.listen} without declaring another function.
     *
     * @param {!hf.events.Event} e An event.
     */
    static preventDefault(e) {
        e.preventDefault();
    }
}
