/**
 * Interface for an App Service Locator.
 *
 * @interface
 *
 */
export class IAppServiceLocator {
    constructor() {
        /**
         * Gets the App events.
         *
         * @returns {object.<string, string>}
         */
        this.getAppEvents;

        /**
         * Gets the App states.
         *
         * @returns {Array.<object>}
         */
        this.getAppStates;

        /**
         * Gets the event bus used by this App.
         *
         * @returns {hf.events.IEventBus}
         */
        this.getEventBus;

        /**
         * Gets the domain services factory used by this App.
         *
         * @returns {hf.app.ui.IResourceRegistry}
         */
        this.getDomainServicesRegistry;

        /**
         * Gets a service by its registration key.
         *
         * @param {string} key
         * @returns {*}
         */
        this.getService;

        /**
         * Gets a domain service by its registration key.
         *
         * @param {string} key
         * @returns {*}
         */
        this.getDomainService;

        /**
         * Gets the views factory used by this App.
         *
         * @returns {hf.app.ui.IResourceRegistry}
         */
        this.getViewsRegistry;

        /**
         * Gets the display regions factory used by this App.
         *
         * @returns {hf.app.ui.IDisplayRegionsRegistry}
         */
        this.getDisplayRegionsRegistry;

        /**
         * Gets the skin manager
         *
         * @returns {hf.skin.SkinManager}
         */
        this.getSkinManager;

        /**
         * @param {string} name
         * @param {*} service
         */
        this.registerService;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IAppServiceLocator.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IAppServiceLocator.IMPLEMENTED_BY_PROP_]);
    }
}

/**
 * An expando property to indicate that an object implements
 * hf.app.IApp.
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IAppServiceLocator.IMPLEMENTED_BY_PROP_ = '__hubfront_app_iappservicelocator';
