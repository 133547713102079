import {DataModelCollection} from "./../../../../../../hubfront/phpnoenc/js/data/model/ModelCollection.js";
import {Filter} from "./Filter.js";

/**
 * A collection of {@link hg.data.model.common.KeyVal}s
 * @extends {DataModelCollection}
 * @unrestricted 
*/
export class FilterCollection extends DataModelCollection {
 /**
  * @param opt_initItems
 */
 constructor(opt_initItems) {
     super({
      'defaultItems': opt_initItems,
      'model'       : Filter
     });
 }
};