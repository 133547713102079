import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";

/**
 * Creates a new {@see CommonToolbarViewmodel} model.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class CommonToolbarViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /**
     * Resets the filters' data
     */
    resetAdvancedSearchFilters() {
        this.set('searchFilter', undefined);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        this.addField({'name': 'title', 'value': null});

        this.addField({'name': 'filterCriteriaInfo', 'value': null});

        this.addField({'name': 'isInSearch', 'value': false});

        this.addField({'name': 'searchValue', 'value': null});

        this.addField({'name': 'searchFilter', 'getter': this.createLazyGetter('searchFilter', this.getSearchFilter)});

        this.addField({'name': 'canGoBack', 'value': false});
    }

    /**
     * @return {object}
     * @protected
     */
    getSearchFilter() {
        throw new Error('unimplemented abstract method');
    }
}