import {Size} from "./../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {UIComponentStates} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ArrayUtils} from "./../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {ImageUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {IObservable} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/IObservable.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {FileLabels, FileTypes} from "./../../../data/model/file/Enums.js";
import {FileMeta} from "./../../../data/model/file/FileMeta.js";
import {VideoPlayer} from "./VideoPlayer.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Extra events fired by this component
 * @enum {string}
 */
export const FileAvatarEventType = {
    /**
     * Dispatched when the component exits from the BUSY state.
     * @event FileAvatarEventType.READY
     */
    READY: StringUtils.createUniqueString('ready'),

    /**
     * Dispatched when the component enters into the BUSY state.
     * @event FileAvatarEventType.BUSY
     */
    BUSY: StringUtils.createUniqueString('busy'),

    /**
     * Dispatched when the component enters into the ERROR state.
     * @event FileAvatarEventType.ERROR
     */
    ERROR: StringUtils.createUniqueString('error')
};

/**
 * @enum {string}
 * @readonly
 * @protected
 */
export const FileAvatarPreviewMode = {
    IMAGE: 'image-preview',

    PLAYER: 'player-preview',

    NO_PREVIEW: 'no-preview'
};

/**
 * Creates a new {@code hg.common.ui.file.FileAvatar} component.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class FileAvatar extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.embedPreview If true, you can play video/audio files inside the media grid item; otherwise the Media Preview is opened. Defaults to true.
     *   @param {FileLabels=} opt_config.imageSize If the file is an IMAGE then it specifies which size of the image must be used: 's', 'o' or 'l'. The default is 's'.
     *   @param {boolean=} opt_config.allowReloadImageSrc If true it allows you to reload of the IMAGE src if it previously failed to load successfully; defaults to false.
     *   @param {hf.math.Size=} opt_config.minSize
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The Image element
         * @type {Image}
         * @protected
         */
        this.image_ = this.image_ === undefined ? null : this.image_;

        /**
         * Embed video player
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.player_ = this.player_ === undefined ? null : this.player_;

        /**
         * The current preview mode
         * @type {?FileAvatarPreviewMode}
         * @private
         */
        this.currentPreviewMode_ = this.currentPreviewMode_ === undefined ? null : this.currentPreviewMode_;

        /**
         * @type {?string | !Array.<string>}
         * @private
         */
        this.fileExtraCss_ = this.fileExtraCss_ === undefined ? null : this.fileExtraCss_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;

        /**
         * @type {Promise}
         * @protected
         */
        this.loadImagePromise_ = this.loadImagePromise_ === undefined ? null : this.loadImagePromise_;

        /**
         *
         * @type {boolean}
         * @protected
         */
        this.hasImageLoadFailed_ = this.hasImageLoadFailed_ === undefined ? false : this.hasImageLoadFailed_;
    }

    /**
     * Returns true if the image was reloaded
     * @return {boolean}
     */
    isImageSrcReloaded() {
        if (this.image_ == null || !this.getElement().contains(this.image_)) {
            return false;
        }

        const src = this.image_.getAttribute('src');
        let reload = this.image_.getAttribute('data-reload') == 'true';

        return !StringUtils.isEmptyOrWhitespace(src) && !!reload;
    }

    /**
     * Try to reload the image src.
     * @return {boolean} True if the reload of image src was triggered.
     */
    reloadImageSrc() {
        const allowReloadImageSrc = this.getConfigOptions()['allowReloadImageSrc'];

        if (!this.isBusy() && allowReloadImageSrc && this.image_ != null && this.getElement().contains(this.image_)) {
            const file = /** @type {hg.data.model.file.File} */(this.getModel()),
                fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null;

            if (fileMeta && fileMeta['mType'] === FileTypes.IMAGE) {
                /* workaround to make sure a reload is done when the src string is not changed cross browser */
                this.image_.setAttribute('data-reload', true);
                this.image_.setAttribute('src', '');

                this.setImageSrc(this.image_, FileAvatar.getAvatarUri_(file, this.computeImageSize()));

                return true;
            }
        }

        return false;
    }

    /**
     * Sets the image src to null, forcing the control to display error
     * Used when deleting a file, to force it to display error in chat thread
     */
    invalidateImageSrc() {
        if (!this.isBusy()
            && this.image_ != null
            && this.getElement().contains(this.image_)) {

            const file = /** @type {hg.data.model.file.File} */(this.getModel()),
                fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null;

            if (fileMeta && fileMeta['mType'] === FileTypes.IMAGE) {
                /* workaround to make sure a reload is done when the src string is not changed cross browser */
                this.image_.setAttribute('data-reload', true);
                this.image_.setAttribute('src', '');

                this.setImageSrc(this.image_, '');

                return true;
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.setSupportedState(UIComponentStates.FOCUSED, false);
        this.setDispatchTransitionEvents(UIComponentStates.FOCUSED, false);
        this.setFocusable(false);

        /* include BUSY, ERROR  states in the set of supported states */
        this.setSupportedState(FileAvatar.State.BUSY, true);
        this.setDispatchTransitionEvents(FileAvatar.State.BUSY, false);
        this.setSupportedState(FileAvatar.State.ERROR, true);
        this.setDispatchTransitionEvents(FileAvatar.State.ERROR, false);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.image_ = null;

        BaseUtils.dispose(this.player_);
        this.player_ = null;

        this.fileExtraCss_ = null;
        this.currentPreviewMode_ = null;

        BaseUtils.dispose(this.busyIndicator_);
        this.busyIndicator_ = null;

        this.loadImagePromise_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return FileAvatar.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return FileAvatar.CssClasses.BASE;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.updatePreview();
    }

    /** @inheritDoc */
    exitDocument() {
        this.setIsBusy(false);
        this.setHasError(false);
        this.clearPreview();

        this.currentPreviewMode_ = null;

        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }

    /** @inheritDoc */
    onModelChanged(model) {
        super.onModelChanged(model);

        this.updatePreview();
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        if (IObservable.isImplementedBy(/** @type {Object} */ (model))) {
            this.getHandler().listen(/** @type {ListenableType} */ (model), ObservableChangeEventName, this.handleModelInternalChange);
        }
    }

    /** @inheritDoc */
    unlistenFromModelEvents(model) {
        if (IObservable.isImplementedBy(/** @type {Object} */ (model))) {
            this.getHandler().listen(/** @type {ListenableType} */ (model), ObservableChangeEventName, this.handleModelInternalChange);
        }
    }

    /** @inheritDoc */
    performActionInternal(e) {
        if (!e.defaultPrevented && !this.isBusy()) {
            if (this.hasError()) {
                /* try to reload image on click.
                 If the load of the image src was launched then mark the event as handled */
                return !this.reloadImageSrc();
            }
        }

        return super.performActionInternal(e);
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[FileAvatar.State.BUSY] = 'busy';
        cssMappingObject[FileAvatar.State.ERROR] = 'error';

        return cssMappingObject;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['embedPreview'] = opt_config['embedPreview'] || false;

        opt_config['minSize'] = opt_config['minSize'] || new Size(FileAvatar.MIN_WIDTH, FileAvatar.MIN_HEIGHT);
        opt_config['allowReloadImageSrc'] = opt_config['allowReloadImageSrc'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @param {Object} file
     * @return FileAvatarPreviewMode
     * @protected
     */
    computeFilePreviewMode(file) {
        let previewMode = FileAvatarPreviewMode.NO_PREVIEW;

        if (file) {
            /** the file is a hga.data.model.common.Giphy file - see hga.data.model.common.Giphy */
            if (file.hasOwnProperty('src') && !StringUtils.isEmptyOrWhitespace(file['src'])) {
                previewMode = FileAvatarPreviewMode.IMAGE;
            } else if (HgFileUtils.isImageOrPoster(file) || HgFileUtils.isVideoOrAudio(file)) {
                previewMode = HgFileUtils.isVideoOrAudio(file) && this.getConfigOptions()['embedPreview'] ?
                    FileAvatarPreviewMode.PLAYER : FileAvatarPreviewMode.IMAGE;
            }
        }

        return previewMode;
    }

    /**
     * @protected
     */
    updatePreview() {
        const file = this.getModel(),
            newPreviewMode = this.computeFilePreviewMode(/**@type {Object}*/(file));

        /* clear previous preview mode */
        this.clearPreview(newPreviewMode);

        if (newPreviewMode === FileAvatarPreviewMode.IMAGE) {
            const image = this.getImage();
            if (image) {
                const fileUri = FileAvatar.getAvatarUri_(/**@type {Object}*/(file), this.computeImageSize());
                if (!StringUtils.isEmptyOrWhitespace(fileUri)) {
                    this.setImageSrc(image, fileUri);

                    if (!this.getElement().contains(image)) {
                        this.getElement().appendChild(image);
                    }
                }
            }
        } else if (newPreviewMode === FileAvatarPreviewMode.PLAYER) {
            const player = this.getPlayer();
            if (player) {
                /* I must simulate the transition BUSY - READY so that the READY event will be dispatched - see HGA-1930 */
                this.setIsBusy(true);
                this.setHasError(false);

                player.setModel(file);

                if (this.indexOfChild(this.player_) == -1) {
                    this.addChild(player, true);
                }

                this.setIsBusy(false);
            }
        }

        this.updateFileExtraCss(newPreviewMode);

        /* store the new preview mode */
        this.currentPreviewMode_ = newPreviewMode;
    }

    /**
     * Gets the current preview mode.
     * @return {?FileAvatarPreviewMode}
     * @protected
     */
    getPreviewMode() {
        return this.currentPreviewMode_;
    }

    /**
     * @param {FileAvatarPreviewMode=} opt_newPreviewMode
     * @protected
     */
    clearPreview(opt_newPreviewMode) {
        //this.setIsBusy(false);
        //this.setHasError(false);

        this.loadImagePromise_ = null;
        this.hasImageLoadFailed_ = false;

        if (this.getPreviewMode() !== opt_newPreviewMode) {
            if (this.image_ != null && this.getElement().contains(this.image_) && this.image_.parentNode) {
                this.image_.parentNode.removeChild(this.image_);
            }

            if (this.player_ != null && this.indexOfChild(this.player_) !== -1) {
                this.removeChild(this.player_, true);
            }

            this.clearFileExtraCss();
        }
    }

    /**
     *
     * @return {Image}
     * @protected
     */
    getImage() {
        if (this.image_ == null) {
            /* insert the image element */
            this.image_ = /**@type {Image}*/(DomUtils.createDom('IMG', {
                'class': FileAvatar.CssClasses.IMG
            }));
        }

        return this.image_;
    }

    /**
     * @protected
     */
    computeImageSize() {
        return this.getConfigOptions()['imageSize'];
    }

    /**
     * @param {Image} imgElement
     * @param {string} fileUri
     * @protected
     */
    setImageSrc(imgElement, fileUri) {
        const imgSrcUri = UriUtils.createURL(imgElement.getAttribute('src')).toString();

        /* check if the current image src is identical with the new src -> in this case the image is not loaded */
        if (imgSrcUri !== fileUri) {
            this.hasImageLoadFailed_ = false;

            this.setIsBusy(true);
            this.setHasError(false);

            const element = this.getElement();
            if (element && element.contains(imgElement)) {
                element.removeChild(imgElement);
            }

            this.loadImagePromise_ = this.setImageSrcDebounced(fileUri)
                .then((loadedImageEl) => {
                    const element = this.getElement();
                    if (element && !element.contains(imgElement)) {
                        element.appendChild(imgElement);
                    }

                    this.onImageSrcLoadSuccess(loadedImageEl);

                    this.setIsBusy(false);

                    return loadedImageEl;
                })
                .catch((error) => {
                    const element = this.getElement();
                    if (element && !element.contains(imgElement)) {
                        element.appendChild(imgElement);
                    }

                    this.onImageSrcLoadError();

                    this.setHasError(true);
                    /* silently exit from BUSY state, so that the transition between BUSY and ERROR is smooth;
                     otherwise the READY event might be handled before ERROR event causing a flicker */
                    this.setIsBusy(false, true);

                    throw error;
                });
        }
    }

    /**
     * @param {string} src
     * @param {number=} opt_retry
     * @return {Promise}
     * @protected
     */
    setImageSrcDebounced(src, opt_retry) {
        if (userAgent.browser.isChrome() && FileAvatar.reqCount_ > FileAvatar.IMAGE_LOAD_MAX_REQUESTS) {
            opt_retry = opt_retry || 0;

            if (opt_retry < FileAvatar.IMAGE_LOAD_MAX_RETRIES) {
                /* increase number of retries */
                opt_retry++;

                /* try again in x seconds, if reqCount_ did not drop below than exit with error */
                const delay = Math.floor(Math.random() * 200 + 120);

                Logger.get('FileAvatar')
                    .info(FileAvatar.reqCount_ + ' pending requests, schedule retry(' + opt_retry + ') in ' + delay + 'ms.');

                return new Promise((resolve, reject) => {
                    setTimeout((src, opt_retry) => {
                        this.setImageSrcDebounced(src, opt_retry)
                            .then((result) => {
                                resolve(result);
                            })
                            .catch((err) => {
                                reject(err);
                            });

                    }, delay, src, opt_retry);
                });
            } else {
                Logger.get('FileAvatar')
                    .info('Exit retry after ' + opt_retry + ' attempts, handling error.');

                return Promise.reject(new Error('Too many retries'));
            }
        } else {
            FileAvatar.reqCount_++;

            Logger.get('FileAvatar')
                .info('Loading image source... (' + FileAvatar.reqCount_ + ' pending requests)');

            /* load the image async */
            return ImageUtils.load(src, this.image_);
        }
    }

    /**
     * @return {boolean}
     * @protected
     */
    hasImageLoadFailed() {
        return this.hasImageLoadFailed_;
    }

    /**
     * Handles image load success
     * @param {Image} loadedImageEl
     * @protected
     */
    onImageSrcLoadSuccess(loadedImageEl) {
        if (loadedImageEl) {
            let reload = loadedImageEl.getAttribute('data-reload') == 'true';

            if (!reload) {
                if (FileAvatar.reqCount_ > 1) {
                    FileAvatar.reqCount_--;
                }

                loadedImageEl.setAttribute('data-reload', false);
            }
        }
    }

    /**
     * Handles image load error
     * @protected
     */
    onImageSrcLoadError() {
        if (FileAvatar.reqCount_ > 1) {
            FileAvatar.reqCount_--;
        }

        const imageElement = this.image_;
        if (imageElement != null) {
            imageElement.setAttribute('data-reload', false);
        }

        this.hasImageLoadFailed_ = true;
    }

    /**
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getPlayer() {
        if (this.player_ == null) {
            this.player_ = this.createPlayer();
            this.player_.addExtraCSSClass(FileAvatar.CssClasses.PLAYER)
        }

        return this.player_;
    }

    /**
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createPlayer() {
        const cfg = this.getConfigOptions();

        return new VideoPlayer({
            'hasFileActionControl': false,
            'embedPreview': cfg['embedPreview'],
            'mediaViewportWidthGetter': cfg['mediaViewportWidthGetter'],
            'mediaViewportResizeSensor': cfg['mediaViewportResizeSensor'],
            'allowedActions': cfg['allowedActions'],
            'defaultToolbarLayout': cfg['defaultToolbarLayout']
        });
    }

    /**
     * @param {FileAvatarPreviewMode} previewMode
     * @protected
     */
    updateFileExtraCss(previewMode) {
        const currentFileExtraCss = this.fileExtraCss_,
            newFileExtraCss = this.computeFileExtraCss(previewMode);

        const fileExtraCssShouldChange =
            BaseUtils.isArray(currentFileExtraCss) || BaseUtils.isArray(newFileExtraCss) ? !ArrayUtils.equals(/**@type {IArrayLike<?>}*/(currentFileExtraCss), /**@type {IArrayLike<?>}*/(newFileExtraCss)) :
                newFileExtraCss != currentFileExtraCss;

        if (fileExtraCssShouldChange) {
            if (currentFileExtraCss) {
                this.removeExtraCSSClass(currentFileExtraCss);
            }

            if (newFileExtraCss) {
                this.addExtraCSSClass(newFileExtraCss);
            }

            this.fileExtraCss_ = newFileExtraCss;
        }
    }

    /**
     * @protected
     */
    clearFileExtraCss() {
        if (this.fileExtraCss_) {
            this.removeExtraCSSClass(this.fileExtraCss_);
        }

        this.fileExtraCss_ = null;
    }

    /**
     * @param {FileAvatarPreviewMode} previewMode
     * @return {!Array.<string>}
     * @protected
     */
    computeFileExtraCss(previewMode) {
        const extraCss = [previewMode],
            file = this.getModel();

        if (file) {
            const fileMeta = file instanceof FileMeta ? file : file['meta'],
                mType = fileMeta ? fileMeta['mType'] : null;

            if (!StringUtils.isEmptyOrWhitespace(mType)) {
                extraCss.push(mType.toLowerCase());

                if (mType === FileTypes.VIDEO) {
                    if (fileMeta['posterNo'] == null || fileMeta['posterNo'] <= 0) {
                        extraCss.push(FileAvatar.CssClasses.NO_VIDEO_POSTER);
                    }
                }
            }
        }

        return extraCss;
    }

    /**
     * Set busy state
     * @param {boolean} isBusy Whether to enable or disable busy state
     * @param {boolean=} opt_silent Whether to dispatch events for entering/exiting from BUSY state
     * @see #isTransitionAllowed
     */
    setIsBusy(isBusy, opt_silent) {
        if (this.isTransitionAllowed(FileAvatar.State.BUSY, isBusy)) {
            this.setState(FileAvatar.State.BUSY, isBusy);

            if (!opt_silent) {
                isBusy ? this.dispatchEvent(FileAvatarEventType.BUSY) : this.dispatchEvent(FileAvatarEventType.READY);
            }

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(FileAvatar.State.BUSY);
    }

    /**
     *
     * @param {boolean} isBusy
     * @protected
     */
    enableIsBusyBehavior(isBusy) {
        if (isBusy) {
            if (this.busyIndicator_ == null) {
                const busyIndicator = this.getBusyIndicator();
                this.addChild(busyIndicator, true);
            }
        } else {
            if (this.busyIndicator_ != null) {
                if (this.indexOfChild(this.busyIndicator_) > -1) {
                    this.removeChild(this.busyIndicator_, true);
                }
                BaseUtils.dispose(this.busyIndicator_);
                this.busyIndicator_ = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator() {
        if (this.busyIndicator_ == null) {
            this.busyIndicator_ = this.createBusyIndicator();
        }

        return this.busyIndicator_;
    }

    /**
     * Creates a busy indicator.
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator() {
        const loaderConfig = this.getConfigOptions()['loader'] || {};

        loaderConfig['size'] = loaderConfig['size'] || Loader.Size.MEDIUM;
        loaderConfig['type'] = loaderConfig['type'] || !userAgent.device.isDesktop() ? Loader.Type.CIRCULAR_LINE : Loader.Type.CIRCULAR;

        return new Loader(loaderConfig);
    }

    /**
     * Set error
     * Add a css class with an error indicator as background and a fixed width = fixed height of the image previews
     * @param {boolean} hasError
     */
    setHasError(hasError) {
        if (this.isTransitionAllowed(FileAvatar.State.ERROR, hasError)) {
            this.setState(FileAvatar.State.ERROR, hasError);

            if (hasError) {
                this.dispatchEvent(FileAvatarEventType.ERROR);
            }

            this.enableHasErrorBehavior(hasError);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(FileAvatar.State.ERROR);
    }

    /**
     *
     * @param {boolean} enable
     * @protected
     */
    enableHasErrorBehavior(enable) {
        const allowReloadImageSrc = this.getConfigOptions()['allowReloadImageSrc'],
            file = /** @type {hg.data.model.file.File} */(this.getModel()),
            fileMeta = file != null ? file instanceof FileMeta ? file : file['meta'] : null,
            isImage = fileMeta != null && fileMeta['mType'] === FileTypes.IMAGE;

        if (enable && allowReloadImageSrc && isImage) {
            this.addExtraCSSClass(FileAvatar.CssClasses.CAN_RELOAD);
        } else {
            this.removeExtraCSSClass(FileAvatar.CssClasses.CAN_RELOAD);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleModelInternalChange(e) {
        const payload = e['payload'] || {};

        if ((payload['field'] == '' && (payload['fieldPath'] == '' || payload['fieldPath'] == 'meta')) || payload['field'] == 'meta') {
            this.updatePreview();
        }
    }

    /**
     * @param {Object} file
     * @param {FileLabels=} imageSize
     * @return {string}
     * @private
     */
    static getAvatarUri_(file, imageSize) {
        let fileAvatarSrc = '';

        if (file) {
            /** the file is a hga.data.model.common.Giphy file - see hga.data.model.common.Giphy */
            if (file.hasOwnProperty('src') && !StringUtils.isEmptyOrWhitespace(file['src'])) {
                // // todo: try to remove downsized from src?
                // uri = UriUtils.createURL(file['src']);
                // fileAvatarSrc = uri ? uri.toString() : '';
                fileAvatarSrc = file['src'];
            } else {
                let fileURL;
                const fileMeta = file instanceof FileMeta ? file : file['meta'];

                /** the file is a hg.data.model.file.File object */
                if (fileMeta) {
                    switch (fileMeta['mType']) {
                        case FileTypes.IMAGE:
                            fileURL = UriUtils.createURL(fileMeta['downloadPath']);
                            if (imageSize) {
                                fileURL.searchParams.set('l', imageSize);
                            }

                            break;

                        case FileTypes.VIDEO:
                            if (fileMeta['posterNo'] != null && fileMeta['posterNo'] > 0) {
                                fileURL = UriUtils.createURL(fileMeta['downloadPath']);
                                fileURL.searchParams.set('label', 'poster1');
                            }

                            break;
                    }
                }

                fileAvatarSrc = fileURL ? fileURL.toString() : '';
            }
        }

        return fileAvatarSrc;
    }
}
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
FileAvatar.CSS_CLASS_PREFIX = 'hg-file-avatar';

/**
 * Maximum number of img fetch retry if pending requests number is reached
 * @const
 * @type {number}
 */
FileAvatar.IMAGE_LOAD_MAX_RETRIES = 3;

/**
 * Maximum number of pending requests that are allowed, what goes beyond are debounced
 * @const
 * @type {number}
 */
FileAvatar.IMAGE_LOAD_MAX_REQUESTS = 50;

/**
 * Minimum item height
 * @const
 * @type {number}
 */
FileAvatar.MIN_HEIGHT = 24;

/**
 * Minimum item width
 * @const
 * @type {number}
 */
FileAvatar.MIN_WIDTH = 24;
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
FileAvatar.CssClasses = {
    BASE            : FileAvatar.CSS_CLASS_PREFIX,

    IMG             : 'image',

    PLAYER          : 'player',

    NO_VIDEO_POSTER : 'no-poster',

    CAN_RELOAD      : 'can-reload'
};

/**
 * Extra states supported by this component
 * @enum {number}
 */
FileAvatar.State = {
    /**
     * The file avatar is loading.
     */
    BUSY: 0x400,

    /**
     * The file avatar failed to load.
     */
    ERROR: 0x800,
};
/**
 * Number of pending requests on image src
 * @type {number}
 * @private
 */
FileAvatar.reqCount_ = 0;