import {
    ApplicationEventType,
    BaseUtils,
    StringUtils,
    ArrayUtils,
    QueryData,
    SortDirection
} from "../../../../../hubfront/phpnoenc/js/index.js";

import {HgAppConfig} from "./../../app/Config.js";
import {HgDateUtils} from "./../../common/date/date.js";
import {HgAuthorUtils} from "./../model/author/Common.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import {AbstractService} from "./AbstractService.js";
import {MessageEvents, MessageTypes, TypingMessageEvent} from "./../model/message/Enums.js";
import {Message} from "./../model/message/Message.js";
import {HgResourceCanonicalNames, HgResourceStatus, MY_ORGANIZATION} from "./../model/resource/Enums.js";
import {TopicType} from "./../model/thread/Enums.js";
import {AuthorType} from "./../model/author/Enums.js";

import {HgAppEvents} from "./../../app/Events.js";
import {DataChannelResource, DataChannelVerb, DataChannelConnectionStatus} from "./datachannel/DataChannelBaseService.js";
import {IThread} from "./../model/thread/IThread.js";
import DataChannelService from "./datachannel/DataChannelService.js";
import {HgMetacontentUtils} from "./../../common/string/metacontent.js";

/**
 * Type declaration for stored unconfirmed messages
 * @typedef {{
 *  resource: string,
 *  verb: string,
 *  payload: Object,
 *  nonce: string,
 *  messageInfo: Array.<{
 *      message: Message,
 *      thread: ResourceLike
 *  }>
 * }}
 */
export let SMPayload;

const SeenEvents = [MessageEvents.SEEN,  MessageEvents.TYPINGSTART,  MessageEvents.TYPINGSTOP];

/**
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class MessageExchangeService extends AbstractService {
    constructor() {
        super();

        /**
         *
         * @type {Date}
         * @private
         */
        this.lastTimeDCAlive_;

        /**
         * @type {boolean}
         * @private
         */
        this.firstConnection_ = this.firstConnection_ === undefined ? true : this.firstConnection_;

        /**
         * xep-0198 Queue of sent messages unconfirmed by server
         * It stored also messages that are sent while the connection is not available
         * Cannot be stored in stream management because there it would be lost on resume connection
         * with a session initialization (not stream resume)
         * @type {Array}
         * @private
         */
        this.undeliveredMessageQueue_ = this.undeliveredMessageQueue_ === undefined ? [] : this.undeliveredMessageQueue_;

        /**
         * Map of message IDs to message Element.  Used for constant-time
         * random access to undelivered messages by ID.
         * @private {Object}
         */
        this.undeliveredMessageIndex_ = this.undeliveredMessageIndex_ === undefined ? {} : this.undeliveredMessageIndex_;
    }

    /**
     * Get the last known datetime when the connection to Data Channel was confirmed to be alive.
     * (last stanza received from server => for sent messages I should receive stream management confirmation)
     * @return {!Date}
     */
    getLastTimeDCAlive() {
        return this.lastTimeDCAlive_ || HgDateUtils.now();
    }

    /**
     * Check chat connection status
     * @param {boolean=} opt_onload True if the connection status is checked onload, in which case add a delay to check
     * @return {boolean} True if connection is alive, false otherwise
     */
    isConnectionAlive(opt_onload) {
        const dataChannel = DataChannelService.getInstance();

        return dataChannel != null && dataChannel.isConnected();
    }

    /**
     * Send COMPOSING chat state
     *
     * COMPOSING
     * <message from="ralucac@192.168.14.7/ralucac-vm" type="chat" xml:lang="en" to="ralucal@192.168.14.7/ralucac-vm" id="ad34a" >
     *  <composing xmlns="http://jabber.org/protocol/chatstates"/>
     * </message>
     *
     * PAUSED
     * <message from="ralucac@192.168.14.7/ralucac-vm" type="chat" xml:lang="en" to="ralucal@192.168.14.7/ralucac-vm" id="ad35a" >
     *  <paused xmlns="http://jabber.org/protocol/chatstates"/>
     * </message>
     *
     * @param {TypingMessageEvent} event Chat state
     * @param {!IThread} thread Message exchange wrapper: conversation or topic
     */
    sendComposingEvent(event, thread) {
        // send chat state only for DIRECT Topics
        if ((thread['type'] === TopicType.DIRECT)
            && this.isConnectionAlive()
            && Object.values(TypingMessageEvent).includes(event)
            && thread['interlocutor']['type'] !== AuthorType.BOT) {

            DataChannelService.getInstance().sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.NEW,
                {
                    'to': discoverRecipient_(thread),
                    'event': event
                }
            );
        }
    }

    /**
     * Send message to conference or unique participant
     * Specific <to> is going to be computed, either jabberId@ or conference@
     * After message is sent using portal, an app event is sent tp announce presenters on new message
     *
     * @param {!(IThread|ResourceLike)} thread Message exchange wrapper: conversation or topic
     * @param {string} messageBody Text message to send to the interlocutor
     * @return {Promise<Message>}
     */
    async sendMessage(messageBody, thread) {
        const recipient = discoverRecipient_(thread);

        /* Was nonce encoded in the message? If not the newContent returned will be the same as the old one and nonce will be null */
        let decodedNonce = HgMetacontentUtils.decodeNonce(messageBody);
        const nonce = decodedNonce.nonce ? decodedNonce.nonce : StringUtils.getRandomString();
        messageBody = decodedNonce.newContent;

        // remove newlines from the beginning of the text!!
        messageBody = messageBody.replace(/^(\s*\n)*/, '');

        const sender = HgAuthorUtils.getAuthorDataForCurrentUser();
        const messageInstance = new Message({
            'nonce': nonce,
            'type': MessageTypes.MSG,
            'author': sender,
            'recipientId': recipient['recipientId'],
            'recipientType': recipient['type'],
            'inThread': {
                'resourceId': thread['resourceId'],
                'resourceType': thread['resourceType']
            },
            'body': messageBody,
            'created': HgDateUtils.now()
        });

        this.getLogger().log('Sent message at: ' + HgDateUtils.now().toISOString());

        this.sendMessageInternal(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            {
                'body': messageBody,
                'to': recipient,
                'sender': sender
            },
            nonce,
            [{
                message: messageInstance,
                thread: thread
            }]
        )
            .then(outcome => this.onMessageSendSuccess(messageInstance, outcome))
            .catch(err => this.onMessageSendFailure(messageInstance, err));


        return messageInstance;
    }

    /**
     * Send message to multiple recipients
     * @param {string} subject Subject
     * @param {string} message Message to send
     * @param {Array.<hg.data.model.party.RecipientBase>} recipients Empty for sendToAll
     */
    sendMassMessage(subject, message, recipients) {
        if (recipients.length) {
            return this.sendMassMessageToParty_(subject, message, recipients);
        } else {
            return this.sendMassMessageToTeam_(subject, message);
        }
    }

    /**
     * Resend message (most likely traffic rate exceeded)
     * @param {!Message} message
     * @param {!IThread} thread Message exchange wrapper: conversation or topic
     */
    async resendMessage(message, thread) {
        if (!message['errorSending']) return;

        /*message['errorSending'] = false;
        message['unconfirmed'] = false;*/
        message['created'] = HgDateUtils.now();
        //message.acceptChanges();

        /* make sure message is removed from undeliveredQueue! */
        this.clearUndeliveredMessage(message['nonce']);


        this.getLogger().log('Resent message at: ' + HgDateUtils.now().toISOString());

        this.sendMessageInternal(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            {
                'to': discoverRecipient_(thread),
                'body': message['body']
            },
            message['nonce'],
            [{
                message: message,
                thread: thread
            }]
        )
            .then(outcome => this.onMessageSendSuccess(message, outcome))
            .catch(err => this.onMessageSendFailure(message, err));
    }

    /**
     * Sends ack (seen) for a specific thread.
     *
     * @param {!IThread|ResourceLike} thread Message exchange wrapper: conversation or topic
     * @return {Promise}
     */
    sendAckForThread(thread) {
        this.getLogger().log('Send message ack in thread: {threadId: ' + thread['resourceId'] + ', threadType: ' + thread['resourceType'] + '}');

        return this.sendMessageInternal(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            {
                'event': MessageEvents.SEEN,
                'to': {
                    'recipientId': thread['resourceId'],
                    'type': thread['resourceType']
                }
            })
    }

    /**
     * Sends ack (seen) for all threads.
     * @return {Promise}
     */
    sendAckForAllThreads() {
        return this.sendMessageInternal(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            {
                'to': '@all',
                'event': MessageEvents.SEEN
            })
    }

    /**
     * Check if there are unconfirmed messages
     */
    hasUndeliveredMessages() {
        const queue = this.undeliveredMessageQueue_;

        /* check if there are other messages diff from chatstats */
        if (queue.length) {
            const match = queue.find(function (item) {
                item = /** @type {SMPayload} */(item);

                return this.isRTMessage(item);
            }, this);

            return match != null;
        }

        return false;
    }

    /**
     * Check if there are unconfirmed messages
     * @param {!IThread} thread Message exchange wrapper: conversation or topic
     */
    hasUndeliveredMessagesInThread(thread) {
        const queue = this.undeliveredMessageQueue_;

        /* check if there are other messages diff from chatstats */
        if (queue.length) {
            const recipient = discoverRecipient_(thread);

            const match = queue.find(function (item) {
                item = /** @type {SMPayload} */(item);

                // select undelivered NEW chat messages (exclude stats messages like typing, seen, etc)
                return this.isRTMessage(item)
                    && (recipient['type'] === item.payload['to']['type']
                        && recipient['recipientId'] === item.payload['to']['recipientId']);
            }, this);

            return match != null;
        }

        return false;
    }

    /**
     * Tries to resend all failed messages of a message thread.
     *
     * @param {IThread} thread
     * @param {Array} failedMessages
     * @returns {boolean} True if the errored messages were resent, false otherwise.
     */
    flushFailedMessages(thread, failedMessages = []) {
        if (!thread || !failedMessages.length) return false;

        /* make sure the list is ordered */
        failedMessages = new QueryData(failedMessages).sort([{
            'sortBy': 'created',
            'direction': SortDirection.ASC
        }]).toArray();

        failedMessages.forEach(failedMessage => this.resendMessage(failedMessage, thread))

        return true;
    }

    /**
     * Removes from undelivered queues the unsent messages attached to the provide message thread
     *
     * @param {Array} failedMessages
     */
    clearFailedMessages(failedMessages = []) {
        failedMessages.forEach(failedMessage => this.clearUndeliveredMessage(failedMessage['nonce']));
    }

    /**
     * Resend undelivered messages
     * @param {!IThread} thread Message exchange wrapper: conversation or topic
     * @param {Array.<Message>} missedMessages
     * @param {Array.<Message>} errorMessages
     * @param {boolean=} opt_isResume
     * @public
     */
    resumeUndeliveredMessages(thread, missedMessages = [], errorMessages = [], opt_isResume) {
        const errorMessagesMap = {};
        errorMessages.forEach((errorMessage) => {
            const message = this.undeliveredMessageIndex_[errorMessage['nonce']];

            if (message == null) {
                errorMessagesMap[errorMessage['nonce']] = errorMessage;
            }
        });

        missedMessages.forEach((message) => {
            message['errorSending'] = false;
            message['unconfirmed'] = false;

            this.clearUndeliveredMessage(message['nonce']);

            delete errorMessagesMap[message['nonce']];
        });

        /* convert errorMessagesMap to array */
        let undeliveredMessages = [];
        for (let key in errorMessagesMap) {
            undeliveredMessages.push(errorMessagesMap[key]);
        }

        /* flush undelivered messages to interlocutor */
        const flushedMessages = this.flushMessagesTo(thread, opt_isResume);
        if (flushedMessages.length) {
            undeliveredMessages.splice(undeliveredMessages.length, 0, ...flushedMessages);
        }

        /* make sure they are sorted! */
        undeliveredMessages = new QueryData(undeliveredMessages).sort([{
            'sortBy': 'created',
            'direction': SortDirection.ASC
        }]).toArray();

        return undeliveredMessages;
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('MessageExchangeService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.undeliveredMessageQueue_ = [];
        this.undeliveredMessageIndex_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.undeliveredMessageQueue_ = null;
        this.undeliveredMessageIndex_ = null;
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, ApplicationEventType.APP_SHUTDOWN, this.handleAppShutdown)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_CONNECTION_STATUS_CHANGE, this.handleConnectionStatusChange);
    }

    /**
     * Send mass message to entire team
     * @param {string} subject Subject
     * @param {string} message Message to send
     * @param {Array.<hg.data.model.party.RecipientBase>} recipients Empty for sendToAll
     * @return {Promise}
     * @private
     */
    sendMassMessageToParty_(subject, message, recipients) {
        if (recipients.length > 0) {
            const nonce = StringUtils.getRandomString(),
                payload = {
                    'isMass': true,
                    'to': {
                        'recipientId': recipients[0]['recipientId'],
                        'type': recipients[0]['type']
                    },
                    'body': message
                };

            if (recipients.length > 1) {
                payload['cc'] = [];
                let i = 1;
                const len = recipients.length;
                for (; i < len; i++) {
                    payload['cc'].push({
                        'recipientId': recipients[i]['recipientId'],
                        'type': recipients[i]['type']
                    });
                }
            }

            if (!StringUtils.isEmptyOrWhitespace(subject)) {
                payload['subject'] = subject;
            }

            return DataChannelService.getInstance().sendMessage(
                DataChannelResource.MESSAGE,
                DataChannelVerb.NEW,
                payload,
                nonce
            );

        }

        return Promise.resolve(null);
    }

    /**
     * Send mass message to entire team
     * @param {string} subject Subject
     * @param {string} message Message to send
     * @return {Promise}
     * @private
     */
    sendMassMessageToTeam_(subject, message) {
        const nonce = StringUtils.getRandomString(),
            payload = {
                'isMass': true,
                'to': {
                    'recipientId': MY_ORGANIZATION,
                    'type': HgResourceCanonicalNames.ORGANIZATION
                },
                'body': message
            };

        if (!StringUtils.isEmptyOrWhitespace(subject)) {
            payload['subject'] = subject;
        }

        return DataChannelService.getInstance().sendMessage(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            payload,
            nonce
        );
    }

    /**
     * @param {string} resource The type of Resource targeted by the message
     * @param {string} verb The verb 'addressed' to the Resource
     * @param {Object} payload an optional payload carried by Message
     * @param {string=} opt_nonce If not provided one is assigned by default
     * @param {Array.<{
     *      message: Message,
     *      thread: ResourceLike
     *  }>=} opt_messageInfo
     * @return {Promise}
     * @protected
     */
    sendMessageInternal(resource, verb, payload, opt_nonce, opt_messageInfo) {
        const storedCommand = {
            resource: resource,
            verb: verb,
            payload: payload,
            nonce: opt_nonce,
            messageInfo: opt_messageInfo
        };

        if (!StringUtils.isEmptyOrWhitespace(opt_nonce)) {
            this.undeliveredMessageIndex_[opt_nonce] = storedCommand;
        }
        this.undeliveredMessageQueue_.push(storedCommand);

        setTimeout((opt_nonce, opt_messageInfo) => {
            if (!StringUtils.isEmptyOrWhitespace(opt_nonce)
                && this.undeliveredMessageIndex_[opt_nonce] != null) {

                if (opt_messageInfo) {
                    opt_messageInfo.forEach(function (item) {
                        item.message['unconfirmed'] = true;
                    })
                }
            }
        }, HgAppConfig.MESSAGE_TIMEOUT, opt_nonce, opt_messageInfo);

        let promisedResult = DataChannelService.getInstance().sendMessage(resource, verb, payload, opt_nonce);

        promisedResult
            .then((outcome) => {
                if (!StringUtils.isEmptyOrWhitespace(opt_nonce)) {
                    delete this.undeliveredMessageIndex_[opt_nonce];

                    if (opt_messageInfo) {
                        opt_messageInfo.forEach(function (item) {
                            item.message['unconfirmed'] = false;

                            /* make sure thread status is not denied (as we could not have received backend notification on thread reopening) */
                            if (item.thread['status'] != HgResourceStatus.OPEN && item.thread['status'] != HgResourceStatus.OPEN) {
                                item.thread['status'] = HgResourceStatus.OPEN;

                                /* send ack as protection */
                                this.sendAckForThread(item.thread);
                            }
                        }, this);
                    }
                }

                ArrayUtils.remove(this.undeliveredMessageQueue_, storedCommand);
            });

        return promisedResult;
    }

    /**
     * @param {!Message|Array.<Message>} message
     * @param {*} outcome For single message this is the messageId
     * @protected
     */
    onMessageSendSuccess(message, outcome) {
        const onSuccess = function (message, outcome) {
            let crDate = HgDateUtils.now();

            if (BaseUtils.isObject(outcome)) {
                /* update the messageId and inThread data. The first message from outcome['dispatched'] is guaranteed to belong to the original thread */
                if (outcome['nonce'] == message['nonce']) {
                    message['messageId'] = outcome['messageId'];

                    message['inThread'] = HgResourceUtils.getResourceLink(outcome['inThread']);
                }

                /* update the message 'created' date */
                if (outcome.hasOwnProperty('created')) {
                    crDate = outcome['created'];
                }

                /* update message tags */
                if (outcome.hasOwnProperty('tag')) {
                    message['tag'] = outcome['tag'];
                }
            } else {
                message['messageId'] = outcome;
            }

            message['isNewlyAdded'] = false;

            message['created'] = message['updated'] = crDate;

            /* make sure message is not marked with error as success callback can be called with delay */
            message['unconfirmed'] = false;
            message['errorSending'] = false;

            message.acceptChanges(true);
        };

        message = BaseUtils.isArrayLike(message) ? /**@type {Array}*/(message) : [message];

        /** @type {Array.<Message>} */(message).forEach(function (singleMessage) {
            onSuccess(singleMessage, outcome);
        }, this);

        const nonce = message[0]['nonce'];
        if (!StringUtils.isEmptyOrWhitespace(nonce)) {
            this.clearUndeliveredMessage(nonce);
        }
    }

    /**
     * We could use the
     * @param {!Message|Array.<Message>} message
     * @param {*} err
     * @protected
     */
    onMessageSendFailure(message, err) {
        const errorFlag = 'errorSending';//this.isConnectionAlive() ? 'errorSending' : 'unconfirmed';

        if (BaseUtils.isArrayLike(message)) {
            /** @type {Array.<Message>} */(message).forEach(function (singleMessage) {
                /** @type {Message} */(singleMessage)[errorFlag] = true;
            }, this);
        } else {
            message[errorFlag] = true;
        }
    }

    /**
     * Flush message stanzas from queue snapshot
     * @param {string} nonce
     * @protected
     *
     */
    clearUndeliveredMessage(nonce) {
        let message = this.undeliveredMessageIndex_[nonce];

        if (message != null) {
            delete this.undeliveredMessageIndex_[nonce];
            ArrayUtils.remove(this.undeliveredMessageQueue_, message);
        }
    }

    isRTMessage(item) {
        return item.resource === DataChannelResource.MESSAGE
            && item.verb === DataChannelVerb.NEW
            && item.payload && (!item.payload.event || !SeenEvents.includes(item.payload.event));
    }

    /**
     * Flush message stanzas from queue snapshot, filter specific interlocutor only
     * @param {!IThread} thread Message exchange wrapper: conversation or topic
     * @param {boolean=} opt_isResume
     * @return {Array.<Message>}
     * @protected
     */
    flushMessagesTo(thread, opt_isResume) {
        /* resend, remove from queue */
        const recipient = discoverRecipient_(thread);

        const undeliveredMessageQueue = this.undeliveredMessageQueue_.slice(0);

        const localMessages = [];
        undeliveredMessageQueue.forEach(function (item) {
            item = /** @type {SMPayload} */(item);

            let isRTMessage = this.isRTMessage(item);

            if (isRTMessage &&
                ((recipient['type'] == item.payload['to']['type'] && recipient['recipientId'] == item.payload['to']['recipientId'])
                    || item.payload['to']['type'] == HgResourceCanonicalNames.ORGANIZATION)) {

                if (opt_isResume) {
                    if (!item.messageInfo || !item.messageInfo[0].message['errorSending']) {
                        const promisedResult = this.sendMessageInternal(
                            item.resource,
                            item.verb,
                            item.payload,
                            item.nonce,
                            item.messageInfo
                        );

                        if (item.messageInfo) {
                            const messageInstances = item.messageInfo.map(item => item.message);

                            promisedResult
                                .then(this.onMessageSendSuccess.bind(this, messageInstances))
                                .catch(this.onMessageSendFailure.bind(this, messageInstances));
                        }
                    }
                } else if (item.messageInfo) {
                    /* dispatch as error all messages in order to be added again in message list */
                    item.messageInfo.map((singleMessageInfo) => {
                        singleMessageInfo.message['unconfirmed'] = false;
                        singleMessageInfo.message['errorSending'] = true;
                    });
                }

                if (item.messageInfo) {
                    item.messageInfo.forEach((singleMessageInfo) => {
                        localMessages.push(/** @type {Message} */(singleMessageInfo.message));
                    });
                }

                if (!StringUtils.isEmptyOrWhitespace(item.nonce)) {
                    delete this.undeliveredMessageIndex_[item.nonce];
                }

                ArrayUtils.remove(this.undeliveredMessageQueue_, item);
            }
        }, this);

        return localMessages;
    }

    /**
     * Flush message ack stanzas from queue snapshot
     * @protected
     */
    flushMessageAck() {
        const undeliveredMessageQueue = this.undeliveredMessageQueue_.slice(0);

        undeliveredMessageQueue.forEach(function (item) {
            item = /** @type {SMPayload} */(item);

            if (!this.isRTMessage(item)) {
                ArrayUtils.remove(this.undeliveredMessageQueue_, item);

                this.sendMessageInternal(
                    DataChannelResource.MESSAGE,
                    DataChannelVerb.NEW,
                    item.payload
                );
            }
        }, this);
    }

    /**
     * Handles message portal connection transition
     * @param {Event} e Connection status change event
     * @protected
     */
    handleConnectionStatusChange(e) {
        const payload = e.getPayload();
        if (payload['isConnected']) {
            this.getLogger().log(`DC connection alive: ${HgDateUtils.now().toISOString()}`);

            const isFirstConnection = this.firstConnection_;
            if (this.firstConnection_) {
                this.firstConnection_ = false;

                //this.lastTimeDCAlive_ = DataChannelService.getInstance().getLastTimeAlive();
                this.lastTimeDCAlive_ = HgDateUtils.now();
            } else {
                /* dispatch undelivered message acks before invalidating threads
                 to avoid a read from db marking the thread locally as unread again */
                this.flushMessageAck();
            }

            this.dispatchAppEvent(HgAppEvents.CHAT_CONNECTION_CHANGE, {
                isConnected: true,
                isReconnection: !isFirstConnection,
                lastTimeDCAlive: this.lastTimeDCAlive_
            });

        } else if(payload['status'] === DataChannelConnectionStatus.DISCONNECTED) {
            //this.lastTimeDCAlive_ = DataChannelService.getInstance().getLastTimeAlive();
            this.lastTimeDCAlive_ = HgDateUtils.now();

            this.getLogger().log(`DC connection lost: ${this.lastTimeDCAlive_.toISOString()}`);

            this.dispatchAppEvent(HgAppEvents.CHAT_CONNECTION_CHANGE, {
                isConnected: false,
                lastTimeDCAlive: this.lastTimeDCAlive_
            });
        }
    }

    /**
     *
     * @param {AppEvent} e
     */
    handleAppShutdown(e) {
        if(this.hasUndeliveredMessages()) {
            /* block the shutting down if there are opened threads with unsent messages */
            e.preventDefault();
        }
    }
}

/**
 * @param {!(IThread|ResourceLike)} thread Message exchange wrapper: conversation or topic
 * @return {Object}
 * @private
 */
function discoverRecipient_(thread) {
    const recipientInfo = {
        'recipientId': thread['resourceId'],
        'type': thread['resourceType']
    };

    if(thread['type'] == TopicType.DIRECT
        && IThread.isImplementedBy(thread)) {
        //StringUtils.isEmptyOrWhitespace(thread['threadId'])) {
        recipientInfo['recipientId'] = thread['resourceId'] || thread['interlocutor']['authorId'];
        recipientInfo['type'] = thread['resourceId'] ? thread['resourceType'] : thread['interlocutor']['type'];
    }

    return recipientInfo;
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new MessageExchangeService();

export default instance;
export {MessageExchangeService};