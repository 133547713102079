import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    List,
    ListEventType,
    ListLoadingState,
    ListLoadingTrigger
} from "./../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {CommonBusyContexts, UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";

import {EventsUtils} from "./../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {RelativeDate} from "./../../../../../../hubfront/phpnoenc/js/ui/RelativeDate.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {MessageGroup} from "./../message/MessageGroup.js";
import {MessageEvents, MessageGroupTypes} from "./../../../data/model/message/Enums.js";
import {GeneralEventGroup} from "./../message/GeneralEventGroup.js";
import {ScreenShareEventGroup} from "./ScreenShareEventGroup.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgButtonUtils} from "./../button/Common.js";
import {MessageActionControlEventTypes} from "./../message/MessageActionControl.js";
import {ShareButtonEventType} from "./../share/ShareButton.js";
import {ForwardButtonEventType} from "./../forward/ForwardButton.js";
import {EditTopicButtonEventType} from "./../../../module/topic/component/EditTopicButton.js";
import {ChatThreadActions, DisplayContexts} from "./../../enums/Enums.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";
import {RelativeDateUtils} from "./../../../../../../hubfront/phpnoenc/js/date/Relative.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.common.ui.thread.ThreadView} component.
 *
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ThreadView extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {!Object=} opt_config.header The configuration object for the thread header
     *   @param {!Object=} opt_config.messagesList The configuration object for the messages list
     *   @param {string=} opt_config.returnToListCaption
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Thread header
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * The messages' history list.
         * @type {hf.ui.list.List}
         * @private
         */
        this.messagesList_ = this.messagesList_ === undefined ? null : this.messagesList_;

        /**
         * Mask layer used as busy indicator in the view
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.busyIndicator_ = this.busyIndicator_ === undefined ? null : this.busyIndicator_;

        /**
         * The container where the errors will be displayed
         *
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.errorContainer_ = this.errorContainer_ === undefined ? null : this.errorContainer_;

        /**
         * @type {Function}
         * @private
         */
        this.viewportResizeDebouncedFn_ = this.viewportResizeDebouncedFn_ === undefined ? null : this.viewportResizeDebouncedFn_;
    }

    /**
     *
     * @param {boolean} isBusy
     * @param {*=} opt_busyContext
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     *
     * @param {boolean} hasError
     * @param {*=} errorInfo
     */
    setHasError(hasError, errorInfo) {
        this.enableHasErrorBehavior(hasError, errorInfo);
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.header_ = null;
        this.messagesList_ = null;

        BaseUtils.dispose(this.busyIndicator_);
        this.busyIndicator_ = null;

        BaseUtils.dispose(this.errorContainer_);
        this.errorContainer_ = null;

        this.viewportResizeDebouncedFn_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return ThreadView.CSS_CLASS_PREFIX;
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return ThreadView.CSS_CLASS_PREFIX;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.getHeader(), true);
        this.addChild(this.getMessagesList(), true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.debounceCSSElementQuery_();

        this.getHandler()
            .listen(this.getMessagesList(), HgUIEventType.FILE_PREVIEW_TOGGLE, this.handleFilePreviewToggle_)
            .listen(this.getMessagesList(), ListEventType.LOADING_STATE_CHANGED, this.handleMessagesListLoadingStateChange_)

            .listen(this, ShareButtonEventType.OPEN_SHARE_PANEL, this.handleOpenSharePanel_)
            .listen(this, ForwardButtonEventType.OPEN_FORWARD_PANEL, this.handleOpenForwardPanel_)
            .listen(this, MessageActionControlEventTypes.OPEN_MESSAGE_ACTION_MENU_BUBBLE, this.handleOpenMessageActionBubble_)
            .listen(this, EditTopicButtonEventType.OPEN_EDIT_TOPIC_PANEL, this.handleOpenEditTopicPanel_);
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        this.setBusy(false, null);
        this.setHasError(false, null);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.getHeader(), {'set': this.getHeader().setModel}, '');

        this.setBinding(this.getMessagesList(), {'set': this.getMessagesList().setItemsSource}, 'messages' );

        this.setBinding(this, {'set': this.setBusy}, {
                'sources': [
                    {'sourceProperty': 'isBusy'},
                    {'sourceProperty': 'busyContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const isBusy = !!values[0],
                            busyContext = values[1];

                        if (busyContext
                            && busyContext['operation'] === CommonBusyContexts.LOAD_FIELD
                            && busyContext['fieldName'] === 'thread') {

                            return isBusy;
                        }

                        return false;
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.setHasError}, {
                'sources': [
                    {'sourceProperty': 'error'},
                    {'sourceProperty': 'errorContext'}
                ],
                'converter': {
                    'sourceToTargetFn': function (values) {
                        const error = !!values[0],
                            errorContext = values[1];

                        if (errorContext
                            && errorContext['operation'] == CommonBusyContexts.LOAD_FIELD
                            && errorContext['fieldName'] == 'thread') {

                            return error != null;
                        }

                        return false;
                    }
                }
            }
        );
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        super.listenToModelEvents(model);
    }

    /** @inheritDoc */
    unlistenFromModelEvents(model) {
        super.unlistenFromModelEvents(model);
    }

    /** @inheritDoc */
    onResize() {
        super.onResize();

        this.debounceCSSElementQuery_();
    }

    /**
     * Gets the header component.
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getHeader() {
        if(this.header_ == null) {
            this.header_ = this.createHeader();
            this.header_.addExtraCSSClass(ThreadView.CssClasses.HEADER);
        }

        return this.header_;
    }

    /**
     *
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createHeader() {
        const configOpt = this.getConfigOptions()['header'] || {};
        if(configOpt['type'] == null) {
            throw new Error('Cannot create thread header: invalid type');
        }

        return new configOpt['type'](configOpt);
    }

    /**
     * @return {hf.ui.list.List}
     * @protected
     */
    getMessagesList() {
        return this.messagesList_ || (this.messagesList_ = new List(this.getMessagesListConfigOptions()));
    }

    /**
     * @return {!Object}
     * @protected
     */
    getMessagesListConfigOptions() {
        const translator = Translator,
            configOpt = Object.assign({}, this.getConfigOptions()['messagesList'] || {});

        configOpt['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(configOpt['extraCSSClass'], [ThreadView.CssClasses.MESSAGES_LIST]);

        configOpt['itemContentFormatter'] = ThreadView.defaultMessageListItemContentFormatter.bind(null, configOpt);

        configOpt['itemStyle'] = configOpt['itemStyle'] || ThreadView.defaultMessageListItemStyleFormatter.bind(null, configOpt);

        configOpt['emptyContentFormatter'] = configOpt['emptyContentFormatter'] || function() {
            return translator.translate("no_records");
        };
        configOpt['isScrollable'] = true;

        configOpt['scroller'] = configOpt['scroller'] || {};
        configOpt['scroller']['invertScrolling'] = configOpt['scroller']['invertScrolling'] != null ? configOpt['scroller']['invertScrolling'] : true;
        configOpt['scroller']['saveCurrentContentOffset'] = configOpt['scroller']['saveCurrentContentOffset'] || false;
        configOpt['scroller']['canScrollToHome'] = configOpt['scroller']['canScrollToHome'] != null ? configOpt['scroller']['canScrollToHome'] : true;
        configOpt['scroller']['saveCurrentContentOffset'] = true;
        configOpt['scroller']['canScrollToHome'] = false;

        configOpt['autoLoad'] = false;
        configOpt['loadMoreItemsTrigger'] = configOpt['loadMoreItemsTrigger'] || ListLoadingTrigger.ANY_EDGE;

        return configOpt;
    }

    /**
     * @private
     */
    dispatchMediaViewportResizeEvent_() {
        EventsUtils.dispatchCustomDocEvent(BrowserEventType.MEDIA_VIEWPORT_RESIZE, {
            'viewport' : this.getMessagesList().getElement()
        });
    }

    /**
     * @protected
     */
    debounceCSSElementQuery_() {
        if (!this.viewportResizeDebouncedFn_) {
            this.viewportResizeDebouncedFn_ = FunctionsUtils.debounce(function () {
                if (this.isInDocument()) {
                    const elem = this.getElement();

                    let viewportWidth = window.getComputedStyle(elem).width;

                    if (!StringUtils.isEmptyOrWhitespace(viewportWidth)) {
                        viewportWidth = parseFloat(viewportWidth);
                    }

                    viewportWidth = /** @type {number} */(viewportWidth);

                    if (viewportWidth < 680) {
                        if (viewportWidth < 570) {
                            elem.setAttribute('max-width', '570px');
                        } else {
                            elem.setAttribute('max-width', '680px');
                        }
                    } else {
                        elem.removeAttribute('max-width');
                    }

                }
            }, 80, this);
        }

        this.viewportResizeDebouncedFn_();
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if(this.isDisposed()) {
            return;
        }

        if(this.header_) {
            this.header_.setVisible(!enable);
        }
        if(this.messagesList_) {
            this.messagesList_.setVisible(!enable);
        }

        if(enable) {
            if(this.isInDocument() && this.busyIndicator_ == null) {
                const busyIndicator = this.getBusyIndicator(opt_busyContext);
                this.addChild(busyIndicator, true);
            }
        }
        else {
            if(this.busyIndicator_ != null) {
                if(this.indexOfChild(this.busyIndicator_) > -1) {
                    this.removeChild(this.busyIndicator_, true);
                }
                BaseUtils.dispose(this.busyIndicator_);
                this.busyIndicator_ = null;
            }
        }
    }

    /**
     * Lazy initialize the busy indicator on first use
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getBusyIndicator(opt_busyContext) {
        if (this.busyIndicator_ == null) {
            this.busyIndicator_ = this.createBusyIndicator(opt_busyContext);
        }

        return this.busyIndicator_;
    }

    /**
     * Creates a busy indicator.
     *
     * @param {*=} opt_busyContext
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createBusyIndicator(opt_busyContext) {
        const busyIndicator = new LayoutContainer({
            'extraCSSClass': ThreadView.CssClasses.BUSY_INDICATOR
        });

        busyIndicator.addChild(new Loader({
            'size': Loader.Size.LARGE
        }), true);

        return busyIndicator;
    }

    /**
     * Enables/disables the 'has error' behavior.
     *
     * This method will be overridden by the inheritors if they need to provide a custom 'has error' behavior.
     * Currently, this method implements the default 'has error' behavior.
     *
     * @param {boolean} enable Whether to enable the 'hasError' behavior
     * @param {*=} errorInfo Contains information about the error.
     * @protected
     */
    enableHasErrorBehavior(enable, errorInfo) {
        if(this.isDisposed()) {
            return;
        }

        if(this.header_) {
            this.header_.setVisible(!enable);
        }
        if(this.messagesList_) {
            this.messagesList_.setVisible(!enable);
        }

        if(enable) {
            const errorContainer = this.getErrorContainer(errorInfo);

            errorContainer.setModel(errorInfo);

            if (errorContainer.getParent() == null) {
                this.addChild(errorContainer, true);
            }
        }
        else if (this.errorContainer_ != null) {
            /* remove error container */
            if(this.indexOfChild(this.errorContainer_) > -1) {
                this.removeChild(this.errorContainer_, /* un-render */ true);
            }

            this.errorContainer_.setModel(null);
            BaseUtils.dispose(this.errorContainer_);
            this.errorContainer_ = null;
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     *
     * @param {*=} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    getErrorContainer(errorInfo) {
        if (this.errorContainer_ == null) {
            this.errorContainer_ = this.createErrorContainer(errorInfo);
        }

        return this.errorContainer_;
    }

    /**
     * Creates the error container.
     *
     * @param {*=} errorInfo
     * @return {hf.ui.UIComponent}
     * @protected
     */
    createErrorContainer(errorInfo) {
        const translator = Translator,
            returnToListButtonCaption = this.getConfigOptions()['returnToListCaption'] || translator.translate('Return to threads list');

        return new UIControl({
            'extraCSSClass': ThreadView.CssClasses.ERROR_CONTAINER,
            'model': errorInfo,
            'contentFormatter': (errorInfo) => {
                const errorMessage = new Caption({
                        'extraCSSClass': ThreadView.CssClasses.ERROR_MSG,
                        'content': translator.translate('load_thread_failure')
                    }),
                    returnToListBtn = HgButtonUtils.createLinkButton(null, false, {
                        'content': returnToListButtonCaption
                    });

                returnToListBtn.addListener(UIComponentEventTypes.ACTION,
                    function(e) {
                        const event = new Event(HgUIEventType.THREAD_ACTION);
                        event.addProperty('payload', {
                            'action': ChatThreadActions.GO_TO_LIST
                        });

                        returnToListBtn.dispatchEvent(event);

                    }, false, this);

                return [errorMessage, returnToListBtn];
            }
        });
    }

    /**
     * HG-5639: Handle history load to dispatch media viewport change event so that media cleans up source if necessary
     * @param {hf.events.Event} e The emitted event.
     * @private
     */
    handleMessagesListLoadingStateChange_(e) {
        if(e.getTarget() == this.getMessagesList()) {
            const currentState = e.getProperty('currentState'),
                dataInvalidated = e.getProperty('payload')['dataInvalidated'];

            if (currentState == ListLoadingState.READY) {
                this.dispatchMediaViewportResizeEvent_();

                if (dataInvalidated) {
                        let targetGroup;
                        const dataItems = this.getMessagesList().getItems();

                        if (dataItems != null) {
                            const messageGroups = dataItems.getGroups();
                            if (messageGroups != null) {
                                targetGroup = messageGroups.find(function (group) {
                                    return group['isSearchContext'];
                                });
                            }
                        }

                        if (targetGroup) {
                            this.getMessagesList().scrollDataItemIntoViewport(targetGroup, true);
                            setTimeout(() => {
                                this.getMessagesList().scrollDataItemIntoViewport(targetGroup, true);
                            }, 20)
                        }

                }
            }
        }
    }

    /**
     * Handle media file preview toggle
     * @param {hf.events.Event} e
     * @private
     */
    handleFilePreviewToggle_(e) {
        this.dispatchMediaViewportResizeEvent_();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenSharePanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenForwardPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenMessageActionBubble_(e) {
        e.addProperty('renderParent', this);

        e.stopPropagation();
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleOpenEditTopicPanel_(e) {
        e.addProperty('renderParent', this);
        e.addProperty('placementTarget', this);
        e.addProperty('placement', PopupPlacementMode.CENTER);

        e.stopPropagation();
    }

    /**
     * @param {!Object} configOptions
     * @param {*} messageGroup
     * @return {(string | Array.<string>)}}
     * @protected
     */
    static defaultMessageListItemStyleFormatter(configOptions, messageGroup) {
        const cssClasses = [ThreadView.CssClasses.MESSAGES_LIST_ITEM];

        if(messageGroup != null) {
            /* add type css class */
            if (!StringUtils.isEmptyOrWhitespace(messageGroup['type'])) {
                cssClasses.push(messageGroup['type'].toLowerCase());

                if (messageGroup['type'] === MessageGroupTypes.EVENT && !StringUtils.isEmptyOrWhitespace(messageGroup['event'])) {
                    cssClasses.push(messageGroup['event'].toLowerCase());
                }
            }

            if (messageGroup['author'] != null) {
                cssClasses.push(HgPersonUtils.isMe(messageGroup['author']['authorId']) ?
                    ThreadView.CssClasses.MESSAGE_AUTHOR_IS_ME : ThreadView.CssClasses.MESSAGE_AUTHOR_IS_OTHER);
            }

            if (messageGroup['isSearchContext']) {
                cssClasses.push(ThreadView.CssClasses.MESSAGE_IS_SEARCH_CONTEXT);
            }
        }

        return cssClasses;
    }

    /**
     *
     * @param {!Object} configOptions
     * @param {!Object} messageGroup
     * @param {hf.ui.UIComponent} listItem
     * @return {UIControlContent}
     */
    static defaultMessageListItemContentFormatter(configOptions, messageGroup, listItem) {
        if(messageGroup == null) {
            return null;
        }

        const threadLink = messageGroup['thread'];

        if (messageGroup['type'] === MessageGroupTypes.MSG) {
            return new MessageGroup({
                'model'               : messageGroup,
                'showAuthor'          : true,
                'showAvatar'          : false,
                'displayContext'      : DisplayContexts.HISTORY,
                'messageActionBubble' : {
                    'placement': PopupPlacementMode.TOP_MIDDLE
                }
            });
        }
        else {
            /* event message group */
            let content = '';

            switch (messageGroup['event']) {
                case MessageEvents.SSHARESTART:
                case MessageEvents.SSHARESTOP:
                    // we need the chatThread: screenShare session identified for this message Group
                    content = new ScreenShareEventGroup({
                        'model': messageGroup,
                        'showAuthor': true,
                        'showAvatar': false,
                        'displayContext': DisplayContexts.HISTORY,
                        'messageActionBubble': {
                            'placement': PopupPlacementMode.TOP_MIDDLE
                        }
                    });
                    break;

                case MessageEvents.DATECHANGE:
                    content = new RelativeDate({
                        'datetime': /**@type {Date}*/(messageGroup['created']),
                        'relativeDateTimeInterval': 'PT48H',
                        'relativeDateFormatter': RelativeDateUtils.formatDay
                    });

                    break;

                default:
                    content = new GeneralEventGroup({
                        'model': messageGroup,
                        'showAuthor': true,
                        'showAvatar': false,
                        'displayContext': DisplayContexts.HISTORY,
                        'messageActionBubble': {
                            'placement': PopupPlacementMode.TOP_MIDDLE
                        }
                    });
                    break;
            }

            return content;
        }
    }
};

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
ThreadView.CSS_CLASS_PREFIX = 'hg-thread-view';

/**
 *
 * @enum {string}
 * @readonly
 * @protected
 */
ThreadView.CssClasses = {
    BASE    : ThreadView.CSS_CLASS_PREFIX,

    HEADER   : ThreadView.CSS_CLASS_PREFIX + '-' + 'header',

    MESSAGES_LIST: ThreadView.CSS_CLASS_PREFIX + '-' + 'messages-list',

    MESSAGES_LIST_ITEM: ThreadView.CSS_CLASS_PREFIX + '-' + 'messages-list-item',

    MESSAGE_AUTHOR_IS_ME: 'myself',

    MESSAGE_AUTHOR_IS_OTHER: 'interlocutor',

    MESSAGE_IS_SEARCH_CONTEXT: 'is-search-context',

    BUSY_INDICATOR: ThreadView.CSS_CLASS_PREFIX + '-' + 'busy-indicator',

    ERROR_CONTAINER: ThreadView.CSS_CLASS_PREFIX + '-' + 'error-container',

    ERROR_MSG: ThreadView.CSS_CLASS_PREFIX + '-' + 'error-msg',

    RETURN_TO_LIST_BTN: ThreadView.CSS_CLASS_PREFIX + '-' + 'return-to-list-btn'
};