import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new vcard job
 * @extends {Caption}
 * @unrestricted 
*/
export class Occupation extends Caption {
    /**
     * @param {!Object=} opt_config The configuration object
     *   @param {boolean=} opt_config.isMultiLine True to display role and company without linkage separator, false otherwise; Default false
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return Occupation.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return Occupation.CssClasses.BASE;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* update the dom content if the model is already set */
        if(this.getModel() != null) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    handleModelInternalChange(e) {
        super.handleModelInternalChange(e);

        const payload = e['payload'];

        if (e.target == this.getModel() && payload && (payload['field'] === '' || payload['field'] === 'organizationName' || payload['field'] === 'title')) {
            this.updateDomContent();
        }
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['ellipsis'] = opt_config['ellipsis'] != null ? opt_config['ellipsis'] : true;
        opt_config['isMultiLine'] = opt_config['isMultiLine'] || false;

        /* contentFormatter */
        opt_config['contentFormatter'] = Occupation.defaultContentFormatter_.bind(null, opt_config);

        /* extraCSSClass */
        if (opt_config['isMultiLine']) {
            opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], 'multiline');
        }

        return super.normalizeConfigOptions(opt_config);
    }

    /**
     * @param {Object} opt_config
     * @param {*} occupation
     * @return {(?UIControlContent | undefined)}
     * @private
     */
    static defaultContentFormatter_(opt_config, occupation) {
        if (!occupation) {
            return null;
        }

        const translator = Translator,
            content = document.createDocumentFragment();

        if (opt_config['isMultiLine'] != null && opt_config['isMultiLine']) {
            if (occupation['title'] != null) {
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.POSITION, occupation['title']));
            }

            if (occupation['organizationName'] != null) {
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.ORGANIZATION, occupation['organizationName']));
            }
        }
        else {
            if (StringUtils.isEmptyOrWhitespace(occupation['title']) || StringUtils.isEmptyOrWhitespace(occupation['organizationName'])) {
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.POSITION, occupation['title'] || occupation['organizationName']));
            }
            else {
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.POSITION, occupation['title']));
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.SEPARATOR, translator.translate('at')));
                content.appendChild(DomUtils.createDom('SPAN', Occupation.CssClasses.ORGANIZATION, occupation['organizationName']));
            }
        }

        return content;
    }
};
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 */
Occupation.CSS_CLASS_PREFIX = 'hg-vcard-occupation';
/**
 * CSS classes by this component
 * @enum {string}
 * @protected
 */
Occupation.CssClasses = {
    BASE            : Occupation.CSS_CLASS_PREFIX,

    POSITION        : Occupation.CSS_CLASS_PREFIX + '-' + 'position',

    ORGANIZATION    : Occupation.CSS_CLASS_PREFIX + '-' + 'organization',

    SEPARATOR       : Occupation.CSS_CLASS_PREFIX + '-' + 'separator'
};