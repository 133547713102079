import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    Orientation,
    UIComponentEventTypes,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BrowserEventType} from "./../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {UriUtils} from "./../../../../../../hubfront/phpnoenc/js/uri/uri.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {ImageUtils} from "./../../../../../../hubfront/phpnoenc/js/ui/image/Common.js";
import {Size} from "./../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Bar} from "./../../../../../../hubfront/phpnoenc/js/ui/progress/Bar.js";
import {VerticalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/VerticalStack.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {HgUIEventType} from "./../events/EventType.js";
import {HgStringUtils} from "./../../string/string.js";
import {FileLabels, FileTypes} from "./../../../data/model/file/Enums.js";
import {HgFileUtils} from "./../../../data/model/file/Common.js";
import {HgButtonUtils} from "./../button/Common.js";

import {FileUpload} from "./../../../data/model/file/FileUpload.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import FileService from "./../../../data/service/FileService.js"

/**
 * Hardcoded item height used to compute if item can be previewed or it must be sent automatically
 * @const
 * @type {number}
 */
export const EDITOR_ATTACHMENT_ESTIMATE_HEIGHT = 45;

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class Attachment extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Progress bar used to indicate progress on file upload
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.progressBar_;

        /**
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.actionButtonSet_;

        /**
         * @type {hf.ui.Caption}
         * @private
         */
        this.fileName_;

        /**
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.fileMarker_;

        /**
         * The busy indicator for file upload > progress bar
         * @type {hf.ui.progress.Bar}
         * @private
         */
        this.busyIndicator_;

        /**
         * Container where errors will be displayed
         * @type {hf.ui.Caption}
         * @private
         */
        this.errorContainer_;

        /**
         * File name content.
         * @typedef {{
         *  name: string,
         *  size: string,
         * }}
         */
        this.fileContent_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-editor-file-preview';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        /* include BUSY and ERROR states in the set of supported states */
        this.setSupportedState(Attachment.State.BUSY, true);
        this.setSupportedState(Attachment.State.ERROR, true);

        const translator = Translator,
            baseCSSClass = this.getDefaultBaseCSSClass();

        this.fileName_ = new Caption({
            'content': translator.translate('Unknown')
            //'hidden' : true
        });

        this.progressBar_ = new Bar({
            'orientation': Orientation.HORIZONTAL,
            'extraCSSClass' : 'hg-progressbar-simple'
        });

        this.actionButtonSet_ = new ButtonSet({
            'extraCSSClass': baseCSSClass + '-' + 'btnset'
        });
        this.actionButtonSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('remove'), false, {
            'name'  : Attachment.Button_.REMOVE
        }));

        this.previewButton_ = HgButtonUtils.createSecondaryButton(null, translator.translate('View'), false, {
            'name'  : Attachment.Button_.PREVIEW,
            'hidden': true
        });
        this.actionButtonSet_.addButton(this.previewButton_);

        this.fileMarker_ = new UIControl({
            'baseCSSClass': baseCSSClass + '-' + 'icon',
            'hideMode'    : 'visibility'
        });

        this.fileContent_ = {};

        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.fileName_);
        delete this.fileName_;

        BaseUtils.dispose(this.progressBar_);
        delete this.progressBar_;

        BaseUtils.dispose(this.actionButtonSet_);
        delete this.actionButtonSet_;

        BaseUtils.dispose(this.fileMarker_);
        delete this.fileMarker_;

        BaseUtils.dispose(this.busyIndicator_);
        delete this.busyIndicator_;

        BaseUtils.dispose(this.errorContainer_);
        delete this.errorContainer_;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.fileName_, {'set': this.fileName_.setContent},
            {
                'sourceProperty': '',
                'converter': {
                    'sourceToTargetFn': (fileUpload) => {
                        if (fileUpload instanceof FileUpload) {
                            let fileNameContent = '';

                            if (fileUpload['originalFile'] != null) {
                                fileNameContent = fileUpload['originalFile'].name;
                            } else {
                                fileNameContent = fileUpload['name'];

                                if (!StringUtils.isEmptyOrWhitespace(fileUpload['originalExtension'])) {
                                    fileNameContent = fileNameContent + '.' + fileUpload['originalExtension'];
                                }
                            }

                            if (fileUpload['version'].getCount() > 0) {
                                const originalView = HgFileUtils.getOriginalView(fileUpload);
                                if (originalView != null) {
                                    if (!StringUtils.isEmptyOrWhitespace(originalView['size']) && !isNaN(originalView['size'])) {
                                        this.fileContent_.size = ' (' + HgStringUtils.formatFileSize(originalView['size'] * 1000, true) + ')';
                                    }
                                }
                            }

                            this.fileContent_.name = fileNameContent;

                            return this.fileContent_.name;
                        }

                        return '';
                    }
                }
            }
        );

        this.setBinding(this.fileMarker_, {'set': this.fileMarker_.setExtraCSSClass}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': (fileUpload) => {
                    if (fileUpload instanceof FileUpload) {
                        const originalView = HgFileUtils.getOriginalView(fileUpload);
                        const mimeType = (originalView ? originalView['mime'] : '')  || (fileUpload['originalFile'] != null ? fileUpload['originalFile'].type : '');

                        if (mimeType != null) {
                            if (mimeType.match(/audio.*/)) {
                                return 'audio';
                            }

                            if (mimeType.match(/video.*/)) {
                                return 'video';
                            }

                            return HgFileUtils.isSupportedImage(fileUpload) ? 'image' : '';
                        }
                    }

                    return '';
                }
            }
        });

        this.setBinding(this.previewButton_, {'set': this.previewButton_.setVisible}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': (fileUpload) => {
                    if (fileUpload instanceof FileUpload) {

                        const originalView =  fileUpload.get('version.0.fileView') && fileUpload.get('version.0.fileView').items_ ? HgFileUtils.getOriginalView(fileUpload) : null;

                        const mimeType = (originalView ? originalView['mime'] : null) || (fileUpload['originalFile'] != null ? fileUpload['originalFile'].type : null),
                            metaMType = fileUpload['meta'] != null ? fileUpload['meta']['mType'] : null;
                        let media = null;

                        if (metaMType != null && mimeType != null) {
                            const acceptedTypes = [
                                FileTypes.AUDIO,
                                FileTypes.VIDEO,
                                FileTypes.IMAGE
                            ];

                            if (acceptedTypes.includes(metaMType)) {
                                if (mimeType.match(/audio.*/)) {
                                    media = DomUtils.createDom('audio');
                                    return ['probably', 'maybe'].includes(media.canPlayType(mimeType));
                                }

                                if (mimeType.match(/video.*/)) {
                                    media = DomUtils.createDom('video');
                                    return ['probably', 'maybe'].includes(media.canPlayType(mimeType));
                                }
                            }

                            return HgFileUtils.isSupportedImage(fileUpload);
                        }
                    }

                    return false;
                }
            }
        });

        this.setBinding(this.fileMarker_, {'set': this.fileMarker_.setContent},
            {
                'sourceProperty': '',
                'converter': {
                    'sourceToTargetFn': (fileUpload) => {
                        if (fileUpload != null && HgFileUtils.isSupportedImage(fileUpload)) {
                            const views = fileUpload.get('version.0.fileView');
                            if (views) {
                                let imageUri = HgFileUtils.getSmallUri(fileUpload),
                                    imageSrc;

                                if (imageUri == '') {
                                    imageUri = HgFileUtils.getOriginalUri(fileUpload);

                                    const imageUriObj = UriUtils.createURL(imageUri);
                                    imageUriObj.searchParams.set('label', FileLabels.SMALL);

                                    imageSrc = imageUriObj.toString();
                                } else {
                                    imageSrc = imageUri;
                                }

                                if (!StringUtils.isEmptyOrWhitespace(imageSrc)) {
                                    const image = DomUtils.createDom('img');

                                    this.getHandler()
                                        .listenOnce(image, [BrowserEventType.LOAD, BrowserEventType.ERROR], this.handleImageLoad_);

                                    image.setAttribute('src', imageSrc);

                                    return image;
                                }
                            }
                        }

                        return '';
                    }
                }
            }
        );

        this.setBinding(this, {'set': this.setBusy}, {
            'sourceProperty': 'progress',
            'converter': {
                'sourceToTargetFn': function (progress) {
                    return progress < 1;
                }
            }
        });

        /* error when file upload fails */
        this.setBinding(this, {'set': this.setError}, 'error');
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const baseCSSClass = this.getDefaultBaseCSSClass();
        this.loader_ = new HorizontalStack({
            'extraCSSClass': baseCSSClass + '-' + 'loader'
        });
        this.loader_.addChild(this.actionButtonSet_, true);

        this.fileInfo_ = new VerticalStack({
            'extraCSSClass' : baseCSSClass + '-' + 'info'
        });
        this.fileInfo_.addChild(this.fileName_, true);
        this.fileInfo_.addChild(this.loader_, true);

        this.addChild(this.fileMarker_, true);
        this.addChild(this.fileInfo_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.actionButtonSet_, UIComponentEventTypes.ACTION, this.handleButtonAction_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setBusy(false);
        this.clearError();

        super.exitDocument();
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[Attachment.State.BUSY] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'busy-ie' : 'busy';

        cssMappingObject[Attachment.State.ERROR] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'error-ie' : 'error';

        return cssMappingObject;
    }

    /**
     * Calculates orientation of the image depends on the exif information
     * @private
     */
    calculateOrientation_() {
        const model = this.getModel();

        /* Get media */
        const originalView = model['version'].getItems()[0]['fileView'].items_ ? HgFileUtils.getOriginalView(model) : null,
            media = originalView['media'];

        if (media == null) {
            return;
        }

        const imgElement = this.fileMarker_.getContent(),
            orientation = ImageUtils.calculateOrientation(
                imgElement,
                new Size(0, 0),
                new Size(Attachment.ICON_WIDTH, Attachment.ICON_HEIGHT),
                null,
                HgFileUtils.getOrientation(imgElement.getAttribute('src'))
            );

        if (orientation) {
            ImageUtils.flipAndRotate(imgElement, orientation.translate, orientation.rotate, orientation.scale, orientation.size);
        }
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        if (this.isTransitionAllowed(UIComponentStates.HOVER, highlighted)) {
            if (highlighted) {
                const content = document.createDocumentFragment();

                content.appendChild(DomUtils.createDom('span', 'hg-file-name', this.fileContent_.name));
                content.appendChild(DomUtils.createDom('span', 'hg-file-size', this.fileContent_.size));

                this.fileName_.setContent(content)
            } else {
                this.fileName_.setContent(this.fileContent_.name);
            }

            super.setHighlighted(highlighted);
        }
    }

    /**
     * Set busy state
     * Add a css class with a loading indicator as background and a fixed width = fixed height of the image previews
     * @param {boolean} isBusy Whether to enable or disable busy state
     * @see #isTransitionAllowed
     */
    setBusy(isBusy) {
        if (this.isTransitionAllowed(Attachment.State.BUSY, isBusy)) {
            this.setState(Attachment.State.BUSY, isBusy);

            /* clear the error state */
            if (isBusy) {
                this.clearError();
            }

            this.enableIsBusyBehavior(isBusy);
        }
    }

    /**
     * Returns true if the control is busy, false otherwise.
     * @return {boolean} Whether the component is busy.
     */
    isBusy() {
        return this.hasState(Attachment.State.BUSY);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @protected
     */
    enableIsBusyBehavior(enable) {
        if (this.isInDocument()) {
            const translator = Translator;

            if (enable) {
                if (this.busyIndicator_ == null) {
                    this.busyIndicator_ = this.createBusyIndicator();
                }

                if(this.loader_.indexOfChild(this.busyIndicator_) < 0) {
                    this.loader_.addChildAt(this.busyIndicator_, 0, true);
                }

                /* set binding for busy indicator */
                this.setBinding(this.busyIndicator_, {'set': this.busyIndicator_.setValue}, {
                    'sourceProperty': 'progress',
                    'converter'     : {
                        'sourceToTargetFn': function (progress) {
                            return BaseUtils.isNumber(progress) ? progress * 100 : 0;
                        }
                    }
                });

                /* show cancel button, hide remove and view buttons */
                this.actionButtonSet_.removeButton(this.previewButton_);

                const removeButton = this.actionButtonSet_.getButtonByName(Attachment.Button_.REMOVE);
                if (removeButton) {
                    removeButton.setContent(translator.translate('Cancel'));
                }
            } else {
                if (this.busyIndicator_ != null) {
                    this.clearBinding(this.busyIndicator_, {'set': this.busyIndicator_.setValue});

                    if(this.loader_.indexOfChild(this.busyIndicator_) > -1) {
                        this.loader_.removeChild(this.busyIndicator_, true);
                    }

                    BaseUtils.dispose(this.busyIndicator_);
                    this.busyIndicator_ = null;
                }

                /* hide cancel button, show remove and view buttons */
                const removeButton = this.actionButtonSet_.getButtonByName(Attachment.Button_.REMOVE);
                if (removeButton) {
                    removeButton.setContent(translator.translate('remove'));
                }

                this.actionButtonSet_.addButton(this.previewButton_);
            }
        }
    }

    /**
     * Create busyIndicator
     * @return {hf.ui.progress.Bar}
     * @protected
     */
    createBusyIndicator() {
        return new Bar({
            'orientation'   : Orientation.HORIZONTAL,
            'extraCSSClass' : 'hg-progressbar-bars'
        });
    }

    /**
     * Set error
     * Add a css class with an error indicator as background and a fixed width = fixed height of the image previews
     * @param {*} errorInfo
     */
    setError(errorInfo) {
        const hasError = errorInfo != null;

        if (this.isTransitionAllowed(Attachment.State.ERROR, hasError)) {
            this.setState(Attachment.State.ERROR, hasError);

            /* clear the busy state*/
            if (hasError) {
                this.setBusy(false);
            }

            this.enableHasErrorBehavior(hasError, errorInfo);
        }
    }

    /**
     * Returns true if the control ic currently displaying an error, false otherwise.
     * @return {boolean}
     */
    hasError() {
        return this.hasState(Attachment.State.ERROR);
    }

    /**
     * Clears the current errror
     */
    clearError() {
        this.setState(Attachment.State.ERROR, false);
    }

    /**
     * @param {boolean} enable
     * @param {*=} opt_errorInfo
     * @protected
     */
    enableHasErrorBehavior(enable, opt_errorInfo) {
        if(this.isInDocument()) {
            const errorHost = this.fileInfo_,
                errorContainer = this.getErrorContainer();

            if (enable) {
                errorContainer.setContent(this.getErrorMessage(opt_errorInfo));

                errorHost.addChildAt(errorContainer, 1, true);

                /* hide view button */
                this.actionButtonSet_.removeButton(this.previewButton_);

                /* hide file name */
                //this.fileName_.setVisible(false);
            } else {
                if (errorHost.indexOfChild(errorContainer) > -1) {
                    errorHost.removeChild(errorContainer, true);

                    BaseUtils.dispose(this.errorContainer_);
                    this.errorContainer_ = null;
                }

                /* show view button */
                this.actionButtonSet_.addButton(this.previewButton_);

                /* show file name */
                //this.fileName_.setVisible(true);
            }
        }
    }

    /**
     * Lazy initialize the standard error component on first use.
     * @return {hf.ui.UIControl}
     * @protected
     */
    getErrorContainer() {
        if (this.errorContainer_ == null) {
            this.errorContainer_ = new Caption({
                'baseCSSClass'  : 'hg-error-description'
            });
        }

        return this.errorContainer_;
    }

    /**
     *
     * @param {*=} opt_ErrorInfo
     * @return {string}
     * @protected
     */
    getErrorMessage(opt_ErrorInfo) {
        const fileService = FileService.getInstance();
        return fileService.getErrorMessage(opt_ErrorInfo.code);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleButtonAction_(e) {
        const btn = /** @type {hf.ui.Button} */(e.getTarget()),
            file = this.getModel();;

        switch (btn.getName()) {
            case Attachment.Button_.REMOVE:
                const fileRemoveEvent   = new Event(HgUIEventType.FILE_REMOVE);

                fileRemoveEvent.addProperty('file', file);

                this.dispatchEvent(fileRemoveEvent);
                break;

            case Attachment.Button_.PREVIEW:
                const filePreviewEvent   = new Event(HgUIEventType.FILE_PREVIEW);

                filePreviewEvent.addProperty('file', file);

                this.dispatchEvent(filePreviewEvent);
                break;

            default:
                break;
        }
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleImageLoad_(e) {
        if (e.getType() == BrowserEventType.LOAD) {
            /* when the busy state is over, hide the preview container
            for the rotation not to be visible */
            this.fileMarker_.setVisible(false);

            this.calculateOrientation_();
        }

        /* show the preview, that have been hiding since
        busy state was over, after preview rotation */
        this.fileMarker_.setVisible(true);
    }
};
/**
 * Extra states supported by this component
 * @enum {number}
 */
Attachment.State = {
    /** Used when the file is being uploaded */
    BUSY: 0x400,

    /** Used on upload error */
    ERROR: 0x800
};

/**
 * @const
 * @type {number}
 */
Attachment.ICON_WIDTH = 26;

/**
 * @const
 * @type {number}
 */
Attachment.ICON_HEIGHT = 32;

/**
 * Default button keys.
 * @enum {string}
 * @private
 */
Attachment.Button_ = {
    /* cancel button in busy state, remove button in normal state */
    REMOVE  : 'btn-remove',

    /* file preview button on files that allow: photo, audio, video */
    PREVIEW : 'btn-preview'
};