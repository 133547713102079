import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { Binding } from './Binding.js';
import { MultiBinding } from './MultiBinding.js';
import { BindingDescriptor } from './BindingDescriptor.js';
import { MultiBindingDescriptor } from './MultiBindingDescriptor.js';

/**
 * Provides an abstraction of a property on a class
 *
 * @typedef { string | { get: (Function | undefined), set: (Function | undefined) }}
 */
export let PropertyDescriptor;

/**
 * Creates a new hf.ui.databinding.BindingManager object.
 *
 * @augments {Disposable}
 *
 */
export class BindingManager extends Disposable {
    /**
     * @param {!hf.ui.UIComponent} bindingContext The binding context.
     *
     */
    constructor(bindingContext) {
        super();

        if (bindingContext == null) {
            throw new Error('The binding context must be defined.');
        }
        /**
         * The binding context.
         *
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.bindingContext_ = bindingContext;

        /**
         * @type Array.<hf.ui.databinding.BindingBase>
         * @private
         */
        this.bindings_ = [];

        /**
         * @type {boolean}
         * @private
         * @default false
         */
        this.isActive_ = false;
    }

    /**
     * Activates all the registered bindings.
     *
     * @returns {void}
     */
    activate() {
        if (this.isActive_) {
            return;
        }

        this.isActive_ = true;

        this.activateBindings(true);
    }

    /**
     * Deactivates all the registered bindings.
     *
     * @returns {void}
     */
    deactivate() {
        if (!this.isActive_) {
            return;
        }

        this.activateBindings(false);

        this.isActive_ = false;
    }

    /**
     * When the model of the binding context changes,
     * you must update the sources of the bindings that are linked directly to the binding's context model (i.e. the binding source is the binding context's model)
     *
     * @returns {void}
     */
    updateBindingsSources() {
        if (!this.isActive_) {
            return;
        }

        this.bindings_.forEach(this.syncBindingSourceWithBindingContextModel, this);
    }

    /**
     * Creates and associates a new instance of {@see hf.ui.databinding.BindingBase} with the specified binding target property.
     *
     * @param {!object} targetObject
     * @param {PropertyDescriptor} targetProperty
     * @param {string | !object} bindingInfo
     * @returns {void}
     */
    setBinding(targetObject, targetProperty, bindingInfo) {
        /* create the coresponding binding descriptor */
        const bindingDescriptor = BaseUtils.isString(bindingInfo)
            ? new BindingDescriptor({ sourceProperty: bindingInfo })
            : bindingInfo.hasOwnProperty('sources')
                ? new MultiBindingDescriptor((bindingInfo))
                : new BindingDescriptor((bindingInfo));

        /* 1. create the new binding */
        const binding = bindingDescriptor.createBinding(targetObject, targetProperty);

        /* 2. store the new binding */
        // NOTE: JSCompiler can't optimize away Array#push.
        // targetBindings[targetBindings.length] = binding;
        this.bindings_[this.bindings_.length] = binding;

        /* 3. activate the new binding...if the BindingsManager is active */
        if (this.isActive_) {
            this.activateBinding(binding, true);
        }
    }

    /**
     * Removes the binding from a property if there is one.
     *
     * @param {!object} targetObject
     * @param {PropertyDescriptor} targetProperty
     * @returns {void}
     */
    clearBinding(targetObject, targetProperty) {
        for (let i = this.bindings_.length - 1; i >= 0; i--) {
            const binding = this.bindings_[i];

            if (this.isTargetBinding(binding, targetObject, targetProperty)) {
                // binding.resetTargetValue();

                /* dispose implies the binding deactivate */
                BaseUtils.dispose(binding);

                this.bindings_.splice(i, 1);
            }
        }
    }

    /**
     * Removes all bindings associated with the specified target object.
     *
     * @param {!object} targetObject
     * @returns {void}
     */
    clearBindings(targetObject) {
        for (let i = this.bindings_.length - 1; i >= 0; i--) {
            const binding = this.bindings_[i];

            if (this.isTargetBinding(binding, targetObject)) {
                // binding.resetTargetValue();

                /* dispose implies the binding deactivate */
                BaseUtils.dispose(binding);

                this.bindings_.splice(i, 1);
            }
        }
    }

    /**
     * Removes all bindings created into this binding context.
     *
     * @returns {void}
     */
    clearAllBindings() {
        for (let i = this.bindings_.length - 1; i >= 0; i--) {
            const binding = this.bindings_[i];

            // binding.resetTargetValue();

            /* dispose implies the binding deactivate */
            BaseUtils.dispose(binding);
        }

        this.bindings_.length = 0;
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.clearAllBindings();

        this.bindings_ = null;

        this.bindingContext_ = null;

        this.isActive_ = false;
    }

    /**
     * Activates / Deactivates the bindings.
     *
     * @param {boolean} activate
     * @protected
     */
    activateBindings(activate) {
        this.bindings_.forEach(
            function (binding) {
                this.activateBinding(binding, activate);
            },
            this
        );

        // /* Keep in mind that a target may be the subject of multiple bindings, so if the target's value will be reset when, for example,
        //  * the first binding (it is involved in) is deactivated, then all the other bindings (it is involved in) are forced to respond
        //  * by updating the sorce because they are not deactivated yet.
        //  * The solution: on deactivating the bindings, reset the bindings' target's value as well,
        //  * but ONLY after all the bindings were deactivated (i.e. the links between the targets and the sources were broken).
        //  */
        // if(!activate) {
        //     this.bindings_.forEach(
        //         function(binding) {
        //             ///** @type {hf.ui.databinding.Binding} */(binding['binding']).resetTargetValue();
        //         },
        //         this
        //     );
        // }
    }

    /**
     * Activates/Deactivates a binding.
     *
     * @param {hf.ui.databinding.BindingBase} binding
     * @param {boolean} activate
     * @protected
     */
    activateBinding(binding, activate) {
        if (activate) {
            this.syncBindingSourceWithBindingContextModel(binding);

            binding.activate();
        } else {
            binding.deactivate();
        }
    }

    /**
     *
     * @param {hf.ui.databinding.BindingBase} binding
     * @protected
     */
    syncBindingSourceWithBindingContextModel(binding) {
        const source = /** @type {object} */ (this.bindingContext_.getModel());

        if (binding instanceof Binding) {
            /** @type {hf.ui.databinding.Binding} */ (binding).setSource(source);
        } else if (binding instanceof MultiBinding) {
            /** @type {hf.ui.databinding.MultiBinding} */ (binding).updateBindingsSources(source);
        }
    }

    /**
     *
     * @param {hf.ui.databinding.BindingBase} binding
     * @param {!object} targetObject
     * @param {PropertyDescriptor=} opt_targetProperty
     * @returns {boolean}
     * @protected
     */
    isTargetBinding(binding, targetObject, opt_targetProperty) {
        if (opt_targetProperty != null) {
            if (binding.getTarget() === targetObject) {
                const bindingTargetProperty = binding.getTargetProperty();

                if (BaseUtils.isString(opt_targetProperty)) {
                    return opt_targetProperty === bindingTargetProperty.fullPath;
                }
                if (BaseUtils.isObject(opt_targetProperty)) {
                    const firstGetter = opt_targetProperty.get,
                        firstSetter = opt_targetProperty.set,
                        secondGetter = bindingTargetProperty.getter,
                        secondSetter = bindingTargetProperty.setter;

                    return firstGetter === secondGetter && firstSetter === secondSetter;
                }
            }

            return false;
        }

        return binding.getTarget() === targetObject;
    }
}
