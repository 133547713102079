import {BaseView} from "./../../../common/ui/view/BaseView.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {DailyMoodInquiry} from "./form/DailyInquiry.js";

/**
 * Creates a new {@see hg.module.global.view.DailyMoodInquiryView} view.
 *
 * @extends {BaseView}
 * @unrestricted 
*/
export class DailyMoodInquiryView extends BaseView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.module.global.form.DailyMoodInquiry}
         * @private
         */
        this.dailyInquiryForm_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.dailyInquiryForm_ = new DailyMoodInquiry();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dailyInquiryForm_);
        this.dailyInquiryForm_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-appview-dialog';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.dailyInquiryForm_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.updateExtraCssClass_}, 'message');

        this.setBinding(this.dailyInquiryForm_, {'set': this.dailyInquiryForm_.setModel}, '');
    }

    /**
     *
     * @param {string} message
     * @private
     */
    updateExtraCssClass_(message) {
        this.setExtraCSSClass(['hg-appview-daily-mood-inquiry', message]);
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.dailyInquiryForm_ != null) {
            this.dailyInquiryForm_.setBusy(enable, opt_busyContext);
        }
    }

    /** @inheritDoc */
    enableHasErrorBehavior(enable, errorInfo) {
        if (this.dailyInquiryForm_ != null) {
            this.dailyInquiryForm_.setHasError(enable, /** @type {ErrorInfo} */(errorInfo));
        }
    }
};