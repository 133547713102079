import { AbstractCheckable } from './AbstractCheckable.js';
import { UIComponentStates } from '../../Consts.js';
import { BaseUtils } from '../../../base.js';
import { FieldCheckboxTemplate } from '../../../_templates/form.js';

/**
 * Creates a new Checkbox object with the provided configuration.
 *
 * @example
    var exampleObj = new hf.ui.form.field.Checkbox(opt_config);
    'opt_config': opt_config = {
        'inputLabel' : 'cat',
        'checked' : true
    };
 *
 * @augments {AbstractCheckable}
 *
 */
export class Checkbox extends AbstractCheckable {
    /**
     * @param {!object=} opt_config The optional configuration object.
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * @inheritDoc
     */
    init(opt_config = {}) {


        opt_config.value = BaseUtils.isBoolean(opt_config.checked) ? opt_config.checked : opt_config.value;
        if (!BaseUtils.isBoolean(opt_config.value)) {
            opt_config.value = false;
        }

        // Call the parent method
        super.init(opt_config);

        // synchronize 'checked' with 'value'
        this.setChecked(opt_config.value);
    }

    /**
     * @inheritDoc
     */
    getDefaultIdPrefix() {
        return 'hf-form-field-checkbox';
    }

    /**
     * @inheritDoc
     */
    getDefaultBaseCSSClass() {
        return 'hf-form-field-checkbox';
    }

    /**
     * @inheritDoc
     */
    getDefaultRenderTpl() {
        return FieldCheckboxTemplate;
    }

    /**
     * @inheritDoc
     */
    performCheck(e) {
        if (!this.isAutoState(UIComponentStates.CHECKED)) {
            return false;
        }

        let isChecked = this.isChecked();

        this.setChecked(!isChecked);

        return isChecked != this.isChecked();
    }

    /**
     * @inheritDoc
     */
    isValueValid(value) {
        return BaseUtils.isBoolean(value);
    }

    /**
     * @inheritDoc
     */
    updateRawValue() {
        super.updateRawValue();

        const currentValue = /** @type {boolean} */ (this.getValueInternal());

        this.setChecked(currentValue);
    }

    /**
     * @inheritDoc
     */
    getEditorValue() {
        const inputElement = this.getInputElement();
        if (inputElement) {
            return inputElement.checked;
        }

        return undefined;
    }

    /**
     * @inheritDoc
     */
    setEditorValue(value) {
        const inputElement = this.getInputElement();
        if (inputElement) {
            inputElement.checked = value;
        } else {
            this.updateRenderTplData('checked', value);
        }
    }

    /**
     *
     * @param {boolean} check
     * @protected
     */
    onCheckedChange(check) {
        this.setValue(check);
    }
}
