import { BindingBase, DataBindingMode } from './BindingBase.js';
import { BaseUtils } from '../../base.js';
import { Binding } from './Binding.js';

/**
 * Creates a new MultiBinding object.
 *
 * @example
       var fullNameToInputFieldBinding = new hf.ui.databinding.MultiBinding({
            'descriptor': fullNameToInputFieldBindingDescriptor, // it was created previously
 
            'bindings': [
                {
                    'source': personModel, // personModel is an instance of myapp.data.models.Person
                    'field': 'firstName',
                    'changeTrigger': ObservableChangeEventName, // optional - the default is ObservableChangeEventName
                },
                {
                    'source': personModel, // personModel is an instance of myapp.data.models.Person
                    'field': 'lastName',
                    'changeTrigger': ObservableChangeEventName, // optional - the default is ObservableChangeEventName
                    'valueConverter': {
                        'targetToSourceFn': function(value) {
                            return value.charAt(0).toUpperCase() + value.slice(1);
                        }
                    }
                }
            ],
 
            'target': {
                'target': document.getElementById('fullNameInput'), // HTML input text field
                'field': 'value'
                'changeTrigger': UIComponentEventTypes.CHANGE, // optional - the default is UIComponentEventTypes.CHANGE
            },
 
            'valueConverter': {
                'sourceToTargetFn': function (values) {
                    return value[0] + ' ' + value[1];
                },
                'targetToSourceFn': function (value) {
                    return value.split(' ');
                })
             },
 
            'mode': DataBindingMode.TWO_WAY,
 
            'updateSourceDelay': 150,
       });
 *
 * @augments {BindingBase}
 *
 */
export class MultiBinding extends BindingBase {
    /**
     * @param {!object} opt_config Configuration object
     *   @param {!hf.ui.databinding.BindingDescriptorBase} opt_config.descriptor The binding descriptor.
     *
     *   @param {!Array.<!object>} opt_config.bindings The array of configuration objects representing the bindings of this {@code hf.ui.databinding.MultiBinding} instance
     *
     *   @param {!object} opt_config.target The binding target details.
     *      @param {object=} opt_config.target.target The object used as the binding target.
     *      @param {!PropertyDescriptor} opt_config.target.field The details about the target property which may include: the name of the property, a reference to the getter function, and/or a reference to the setter function.
     *      @param {string=} opt_config.target.changeTrigger The name of the event that triggers the data flow from target to source.
     *
     *   @param {DataBindingValueConverter=} opt_config.valueConverter The converter to use to convert the source values to or from the target value
     *      It includes 2 functions:
     *      sourceToTargetFn - A function that is called when the binding engine propagates a value from the binding sources to the binding target.
     *                         The input parameter will be an array representing the values of the sources of the bindings' collection.
     *      targetToSourceFn - A function that is called when the binding engine propagates a value from the binding target to the binding source. The
     *
     *   @param {DataBindingMode=} opt_config.mode Indicates the direction of the data flow in the binding.
     *      It is also used as the default value for all the bindings in the collection unless individual binding overrides it.
     *      For example, if the Mode property on the {@code hf.ui.databinding.MultiBinding} object is set to {@code DataBindingMode.TWO_WAY}, then all the bindings in the collection are considered TWO_WAY
     *      unless you set a different Mode value on one of the bindings explicitly.
     *
     *   @param {number=} opt_config.updateSourceDelay The amount of time, in milliseconds, to wait before updating the binding source after the value on the target changes; the default is 0.
     *      It is also used as the default value for all the bindings in the collection unless individual binding overrides it.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        MultiBinding.instanceCount_++;

        /**
         * The Array of bindings.
         *
         * @type {Array.<hf.ui.databinding.Binding>}
         * @private
         */
        this.bindings_;
    }

    /**
     * When the model of the binding context changes,
     * you must update the sources of the bindings that are linked directly to the binding's context model (i.e. the binding source is the binding context's model)
     *
     * @param {*} source
     * @returns {void}
     */
    updateBindingsSources(source) {
        if (BaseUtils.isArray(this.bindings_)) {
            this.bindings_.forEach((binding) => {
                if (!binding.bindsDirectlyToSource()) {
                    binding.setSource(/** @type {object | null} */(source));
                }
            });

            if (this.isActive()) {
                this.sync();
            }
        }
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        this.setBindings_(/** @type {Array} */ (opt_config.bindings));
    }

    /** @inheritDoc */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        MultiBinding.instanceCount_--;

        // clear the inner bindings' collection.
        for (let i = this.bindings_.length - 1; i >= 0; i--) {
            BaseUtils.dispose(this.bindings_[i]);
        }

        this.bindings_.length = 0;
        this.bindings_ = null;
    }

    /** @inheritDoc */
    canActivate() {
        return super.canActivate()
            && this.getTarget() != null
            && this.bindings_.every(
                (binding) => binding.canActivate()
            );
    }

    /** @inheritDoc */
    onBind() {
        super.onBind();

        this.bindings_.forEach((binding) => {
            binding.activate();
        });

        this.sync();
    }

    /** @inheritDoc */
    onUnbind() {
        super.onUnbind();

        this.bindings_.forEach((binding) => {
            binding.deactivate();
        });
    }

    /** @inheritDoc */
    updateSourceValue(convertedTargetValue) {
        if (!BaseUtils.isArray(convertedTargetValue) || convertedTargetValue.length == 0) {
            return;
        }

        let i = 0;
        const len = this.bindings_.length;
        for (; i < len; i++) {
            const binding = this.bindings_[i];
            if (binding.canUpdateSource()) {
                const targetValue = binding.convertTargetValue(convertedTargetValue[i]);
                binding.updateSourceValue(targetValue);
            }
        }
    }

    /** @inheritDoc */
    getSourceRawValue() {
        return this.bindings_.map((binding) =>
            // it is returned the source value that is already converted with the inner converter (if any)
            binding.getSourceValueForTarget());
    }

    /**
     * Initializes the collection of bindings.
     *
     * @param {Array} bindings
     * @private
     */
    setBindings_(bindings) {
        if (!BaseUtils.isArray(bindings) || bindings.length == 0) {
            throw new Error('The bindings must be a not empty array');
        }

        this.bindings_ = bindings.map(function (bindingInfo) {
            const binding = new Binding({
                source: {
                    source: bindingInfo.source,
                    field: bindingInfo.field,
                    changeTrigger: bindingInfo.changeTrigger
                },
                /* the target must be set on each binding, so that each binding will be able to update the validation of the target */
                target: {
                    target: this.getTarget(),
                    field: this.getTargetProperty().toPropertyDescriptor(),
                    changeTrigger: this.getTargetTrigger()
                },
                valueConverter: bindingInfo.valueConverter,
                mode: bindingInfo.mode || this.getMode(),
                updateSourceDelay: bindingInfo.updateSourceDelay || this.getUpdateSourceDelay()
            });
            binding.setParent(this);

            return binding;
        }, this);
    }
}

/**
 * @type {number}
 * @protected
 */
MultiBinding.instanceCount_ = 0;
