import { Listenable } from '../../events/Listenable.js';
import { EventTarget } from '../../events/EventTarget.js';
import { EventHandler } from '../../events/EventHandler.js';
import { BaseUtils } from '../../base.js';
import { ObjectUtils } from '../../object/object.js';
import { JsonUtils } from '../../json/Json.js';
import { IObservable, IObservableCollection, IObservableObject } from './IObservable.js';
import {
    CollectionChangeEvent,
    FieldChangeEvent,
    ObservableChangeEventName,
    ObservableCollectionChangeAction,
    ObservableCollectionMutableChangeActions
} from './ChangeEvent.js';
import { ObservableObjectField } from './Field.js';
import { Collection } from '../collection/Collection.js';
import { ICollection } from '../collection/ICollection.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new {@see hf.structs.observable.Observable} object.
 *
 * @augments {EventTarget}
 * @implements {hf.structs.observable.IObservable}
 *
 */
export class Observable extends EventTarget {
    /**
     * @param {!object=} opt_config Object containing the configuration options (if any).
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * The event handler the model's events will be added to.
         * The events will be automatically cleared when the model changes or on dispose.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * Stores a value indicating whether the object dispatches the {@see hf.structs.observable.IObservable.CHANGE} event.
         *
         * @type {boolean}
         * @default true
         * @private
         */
        this.isChangeNotificationEnabled_ = this.isChangeNotificationEnabled_ === undefined ? true : this.isChangeNotificationEnabled_;

        /**
         *
         * @type {number}
         * @private
         */
        this.disableChangeNotificationLevelsCount_ = this.disableChangeNotificationLevelsCount_ === undefined ? 0 : this.disableChangeNotificationLevelsCount_;
    }

    /**
     * @inheritDoc
     *
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    enableChangeNotification(enable) {
        this.disableChangeNotificationLevelsCount_ = enable ? this.disableChangeNotificationLevelsCount_ - 1 : this.disableChangeNotificationLevelsCount_ + 1;

        if (this.disableChangeNotificationLevelsCount_ < 0) {
            this.disableChangeNotificationLevelsCount_ = 0;
        }

        this.isChangeNotificationEnabled_ = this.disableChangeNotificationLevelsCount_ == 0;
    }

    /**
     * Initialization routine
     *
     * @param {!object=} opt_config Object containing the configuration options (if any).
     * @protected
     */
    init(opt_config = {}) {
        this.isChangeNotificationEnabled_ = true;
        this.disableChangeNotificationLevelsCount_ = 0;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        // unlisten from all the model's events
        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getEventHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Dispatches the {@see hf.structs.observable.IObservable} event.
     *
     * @param {!object.<string, *>} eventData The {@see hf.structs.observable.IObservable} event data.
     * @returns {boolean}
     * @fires ObservableChangeEventName
     * @protected
     */
    dispatchChangeEvent(eventData) {
        const event = new FieldChangeEvent(eventData, this);

        return this.dispatchEvent(event);
    }

    /**
     * @inheritDoc
     */
    dispatchEvent(e) {
        const ancestorsTree = [];
        let ancestor = this.getParentEventTarget();
        if (ancestor) {
            for (; ancestor; ancestor = ancestor.getParentEventTarget()) {
                ancestorsTree.push(ancestor);
            }
        }

        if (!this.isChangeNotificationEnabled()
            || ancestorsTree.some((ancestor) => !ancestor.isChangeNotificationEnabled())) {
            return false;
        }

        return super.dispatchEvent(e);
    }

    /**
     * Returns whether the provided value is a {@see hf.structs.observable.ObservableObject}
     *
     * @param {*} val
     * @returns {boolean}
     */
    static isObservableObject(val) {
        return BaseUtils.isObject(val) && val instanceof ObservableObject;
    }

    /**
     * Returns whether the provided value is a {@see hf.structs.observable.ObservableCollection}
     *
     * @param {*} val
     * @returns {boolean}
     */
    static isObservableCollection(val) {
        return BaseUtils.isObject(val) && val instanceof ObservableCollection;
    }
}
// implements interfaces:
Listenable.addImplementation(Observable);
IObservable.addImplementation(Observable);

/**
 * Creates a new {@see hf.structs.observable.ObservableObject} object.
 *
 * @example
 var personObs = new hf.structs.observable.ObservableObject({
        "firstName" : "John",
        "surname"   : "Doe",
        "age"       : 33,
        "contact"   : {
            "phone"     : "0895642334",
            "email"     : "john.doe@emailme.com",
            "faceboook" : "https://www.facebook.com/john.does",
            "address"   : {
                "street"  : "No name strest",
                "city"    : "Dallas",
                "country" : "Texas"
                "country" : "United States of America"
            }
        }
    });
 *
 * @augments {EventTarget}
 * @implements {hf.structs.observable.IObservableObject}
 *
 */
export class ObservableObject extends EventTarget {
    /**
     * @param {!object=} opt_initialData Source object from which this instance gets the initial fields and values
     *
     */
    constructor(opt_initialData) {
        /* Call the base class constructor */
        super();

        /**
         * @type {object.<string, hf.structs.observable.ObservableObjectField>}
         * @private
         */
        this.fieldsMap_ = {};

        /**
         * An unique id of this object
         *
         * @type {string}
         * @private
         */
        this.clientUId_ = this.generateClientId();

        /**
         * The event handler the object's events will be added to.
         * The events will be automatically cleared when the object changes or on dispose.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * Stores a value indicating whether the object dispatches the CHANGE event.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isChangeNotificationEnabled_ = true;

        /**
         *
         * @type {number}
         * @private
         */
        this.disableChangeNotificationLevelsCount_ = 0;

        /* Initialize data */

        /* suspend change notifications while initializing object data */
        this.enableChangeNotification(false);

        this.init(opt_initialData);

        /* re-enable change notifications after initialization */
        this.enableChangeNotification(true);

        ObservableObject.instanceCount_++;
    }

    /**
     * @inheritDoc
     *
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    enableChangeNotification(enable) {
        this.disableChangeNotificationLevelsCount_ = enable ? this.disableChangeNotificationLevelsCount_ - 1 : this.disableChangeNotificationLevelsCount_ + 1;

        if (this.disableChangeNotificationLevelsCount_ < 0) {
            this.disableChangeNotificationLevelsCount_ = 0;
        }

        this.isChangeNotificationEnabled_ = this.disableChangeNotificationLevelsCount_ == 0;
    }

    /**
     * @inheritDoc
     *
     */
    getClientUId() {
        return this.clientUId_;
    }

    /**
     * @inheritDoc
     *
     */
    loadData(source, opt_loadOptions) {
        this.loadDataInternal(source, opt_loadOptions);
    }

    /**
     * @inheritDoc
     *
     */
    set(fieldPath, value, opt_silent) {
        // check whether fieldPath contains more than one segments - TODO: an improvement is welcome
        const pathSegments = fieldPath.split('.'),
            count = pathSegments.length;

        if (count > 1) {
            const lastIndexOfDot = fieldPath.lastIndexOf('.'),
                fieldName = fieldPath.substring(lastIndexOfDot + 1),
                pathToField = fieldPath.substring(0, lastIndexOfDot);

            const obj = this.get(pathToField);
            if (Observable.isObservableObject(obj)) {
                /** @type {hf.structs.observable.IObservableObject} */(obj).set(fieldName, value, opt_silent);
            } else if (BaseUtils.isObject(obj)) {
                obj[fieldName] = value;
            }
        } else {
            opt_silent = !!opt_silent;

            this.enableChangeNotification(!opt_silent);

            this[fieldPath] = value;

            this.enableChangeNotification(true);
        }
    }

    /**
     * @inheritDoc
     *
     */
    load(fieldPath, value, opt_silent) {
        // check whether fieldPath contains more than one segments - TODO: an improvement is welcome
        const pathSegments = fieldPath.split('.'),
            count = pathSegments.length;

        if (count > 1) {
            const lastIndexOfDot = fieldPath.lastIndexOf('.'),
                fieldName = fieldPath.substring(lastIndexOfDot + 1),
                pathToField = fieldPath.substring(0, lastIndexOfDot);

            const obj = this.get(pathToField);
            if (Observable.isObservableObject(obj)) {
                /** @type {hf.structs.observable.IObservableObject} */(obj).load(fieldName, value, opt_silent);
            } else if (BaseUtils.isObject(obj)) {
                obj[fieldName] = value;
            }
        } else {
            opt_silent = !!opt_silent;

            this.enableChangeNotification(!opt_silent);

            this.loadFieldValue(fieldPath, value);

            this.enableChangeNotification(true);
        }
    }

    /**
     * @inheritDoc
     *
     */
    get(fieldPath) {
        // check whether fieldPath contains more than one segments - TODO: an improvement is welcome
        const pathSegments = fieldPath.split('.'),
            count = pathSegments.length;

        if (count > 1) {
            return ObjectUtils.getPropertyByPath(this, fieldPath);
        }

        return this[fieldPath];

    }

    /**
     * @inheritDoc
     *
     */
    hasValue(fieldName) {
        return this.fieldHasValue(fieldName);
    }

    /**
     * @inheritDoc
     *
     */
    hasField(fieldName) {
        return this.fieldsMap_.hasOwnProperty(fieldName);
    }

    /**
     * @inheritDoc
     *
     */
    equals(compareToObject) {
        /* if the object to compare with is not a plain object (json object) and it's not an instance of hf.structs.observable.ObservableObject either, then return false. */
        if (compareToObject == null || (!ObjectUtils.isPlainObject(compareToObject) && !(compareToObject instanceof ObservableObject))) {
            return false;
        }

        const me = this.toJSONObject();

        if (compareToObject instanceof ObservableObject) {
            compareToObject = (/** @type {hf.structs.observable.ObservableObject} */ (compareToObject)).toJSONObject();
        }

        return ObjectUtils.equals(me, compareToObject);
    }

    /**
     * @inheritDoc
     *
     */
    toJSONObject(opt_serializeOptions) {
        opt_serializeOptions = opt_serializeOptions || {};
        opt_serializeOptions.excludeUnchanged = opt_serializeOptions.excludeUnchanged || false;

        const result = {};

        const fields = Object.keys(this.getFields());
        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            const fieldName = fields[i];

            if (!this.canSerializeField(fieldName, /** @type {!object} */ (opt_serializeOptions))) {
                continue;
            }

            result[fields[i]] = this.serializeValue(this.getFieldValue(fieldName), opt_serializeOptions);
        }

        return result;
    }

    /**
     * Serializes this object into a JSON format.
     *
     * @returns {string} A JSON string representation of this instance
     *
     */
    toJSON() {
        return JSON.stringify(this.toJSONObject());
    }

    /**
     * Clones this object.
     * NOTE: this is under testing! Probably I shoud clone the state as well (see dirty)
     *
     * @returns {hf.structs.observable.ObservableObject}
     *
     */
    clone() {
        const data = this.toJSONObject(),
            proto = Object.getPrototypeOf(this);

        return new proto.constructor(data);
    }

    /**
     * Initialization routine
     *
     * @param {!object=} opt_config
     * @protected
     */
    init(opt_config = {}) {
        if (opt_config != null) {
            this.loadDataInternal(opt_config, { generateFieldsIfNotFound: true });
        }
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        ObservableObject.instanceCount_--;

        /* dispose the event handler and unlisten from all the object's events */
        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;

        /* dispose the fields collection */
        const fields = Object.keys(this.getFields());
        let i = 0;
        const len = fields.length;
        for (; i < len; i++) {
            BaseUtils.dispose(this.fieldsMap_[fields[i]]);
        }

        this.fieldsMap_ = null;
    }

    /**
     * @protected
     * @returns {string}
     */
    generateClientId() {
        return StringUtils.createUniqueString('__hf_observable_observableobject_');
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getEventHandler() {
        return this.eventHandler_ || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Define the fields of this object using a source object.
     *
     * @param {!object} source The data source values are loaded from.
     * @param {!object=} opt_loadOptions An object describing load data options
     *   @param {boolean=} opt_loadOptions.overrideChanges A value indicating whether to override the changes or not.
     *   @param {boolean=} opt_loadOptions.generateFieldsIfNotFound Generate fields if not found.
     * @returns {void}
     * @protected
     */
    loadDataInternal(source, opt_loadOptions) {
        if (!ObjectUtils.isPlainObject(source)) {
            throw new Error('Invalid source object to load data from.');
        }

        opt_loadOptions = opt_loadOptions || {};
        opt_loadOptions.overrideChanges = opt_loadOptions.overrideChanges != null ? opt_loadOptions.overrideChanges : false;
        opt_loadOptions.generateFieldsIfNotFound = opt_loadOptions.generateFieldsIfNotFound != null ? opt_loadOptions.generateFieldsIfNotFound : true;

        const overrideChanges = !!opt_loadOptions.overrideChanges,
            generateFieldsIfNotFound = !!opt_loadOptions.generateFieldsIfNotFound;

        // Make sure that no 'CHANGE' event will be dispatched while the new data is loaded.
        this.enableChangeNotification(false);

        // start the process of loading the data
        this.onDataLoading(source);

        for (let propertyName in source) {
            if (!source.hasOwnProperty(propertyName)) {
                continue;
            }

            // Adds a field if the field doesn't already exist.
            if (!this.hasField(propertyName)) {
                if (generateFieldsIfNotFound) {
                    this.addField({ name: propertyName });
                } else {
                    continue;
                }
            }

            if (overrideChanges || !this.isFieldDirty(propertyName)) {
                // loads the value into the field (override the current value if it already exists)
                this.loadFieldValue(propertyName, source[propertyName], opt_loadOptions);
            }
        }

        this.onDataLoaded();

        this.enableChangeNotification(true);
    }

    /**
     * Called before a new version of data is loaded into the object.
     *
     * @param {!object} rawData
     * @protected
     */
    onDataLoading(rawData) {
        // nop
    }

    /**
     * Called after a new version of data is loaded into the object.
     *
     * @protected
     */
    onDataLoaded() {
        // nop
    }

    /**
     * Adds a field to this object.
     *
     * @param {!object} fieldParams The field options
     * @returns {hf.structs.observable.ObservableObject}
     * @protected
     */
    addField(fieldParams) {
        const name = fieldParams.name;

        if (this.hasField(name)) {
            throw new Error(`The field ${name} already exists.`);
        }

        // firstly add the field to the collection of managed fields.
        this.fieldsMap_[name] = this.createField(fieldParams);

        // secondly create a property for the interaction with the outside world.
        this.createProperty(fieldParams);

        return this;
    }

    /**
     * Creates a new managed field.
     *
     * @param {!object} fieldParams
     * @returns {hf.structs.observable.ObservableObjectField}
     * @protected
     */
    createField(fieldParams) {
        return new ObservableObjectField(fieldParams);
    }

    /**
     * Creates a new property which is used by the outside world
     * to interact with the managed field having the same name.
     *
     * @param {!object} fieldParams
     * @protected
     */
    createProperty(fieldParams) {
        const name = fieldParams.name,
            getterFn = () => this.getInternal(name),
            setterFn = (val, opt_silent) => {
                this.setInternal(name, val, opt_silent);
            };

        Object.defineProperty(this, name, {
            get: getterFn,
            set: setterFn
        });
    }

    /**
     * Gets the fields of this object
     *
     * @returns {!object.<string, hf.structs.observable.ObservableObjectField>}
     * @protected
     */
    getFields() {
        return /** @type {!object.<string, hf.structs.observable.ObservableObjectField>} */(this.fieldsMap_);
    }

    /**
     * Gets a field by name.
     *
     * @param {string} fieldName
     * @returns {hf.structs.observable.ObservableObjectField}
     * @protected
     */
    getField(fieldName) {
        return this.fieldsMap_[fieldName];
    }

    /**
     * Determines whether the field can be serialized to JSON representation of this instance.
     *
     * @param {string} fieldName
     * @param {!object} serializeOptions
     * @protected
     */
    canSerializeField(fieldName, serializeOptions) {
        let excludeUnchanged = serializeOptions.excludeUnchanged || false;
        const field = this.getField(fieldName);


        return field.hasValue() && !BaseUtils.isFunction(field.getValue()) && (!excludeUnchanged || field.isDirty());
    }

    /**
     * Gets whether the field is dirty.
     *
     * @param {string} fieldName
     * @returns {boolean}
     * @protected
     */
    isFieldDirty(fieldName) {
        return this.getField(fieldName).isDirty();
    }

    /**
     * Determines whether the value of the field has been ever set.
     *
     * @param {string} fieldName
     * @returns {boolean}
     * @protected
     */
    fieldHasValue(fieldName) {
        return !this.isDisposed() && this.getField(fieldName).hasValue();
    }

    /**
     *
     * @param {string} fieldName
     * @returns {*}
     * @protected
     */
    getInternal(fieldName) {
        return this.getFieldValue(fieldName);
    }

    /**
     *
     * @param {string} fieldName The name of the field
     * @param {*} value The new value to be assigned to the field
     * @param {boolean=} opt_silent Flag indicating whether to dispatch the change event
     */
    setInternal(fieldName, value, opt_silent) {
        /* store the current value of the field */
        const currentValue = this.getFieldValue(fieldName);

        /* normalize the provided value */
        value = this.parseFieldValue(fieldName, value);

        /* try to set the provided value as the new value of the field */
        if (this.setFieldValue(fieldName, value, true)) {
            /* the newValue was accepted as the new value of the field */

            if (BaseUtils.isObject(currentValue)) {
                this.removeParentChildLink(/** @type {object | null} */(currentValue), fieldName);
            }

            if (BaseUtils.isObject(value)) {
                this.createParentChildLink(/** @type {object | null} */(value), fieldName);
            }

            if (!opt_silent && this.isChangeNotificationEnabled_) {
                this.onFieldValueChanged(fieldName, value, currentValue);
            }
        }
    }

    /**
     * Sets the field value only if the field has no value
     *
     * @param {string} fieldName The name of the field
     * @param {*} value The new value to be assigned to the field
     * @param {boolean=} opt_silent Flag indicating whether to dispatch the change event
     * @protected
     */
    setIfHasNoValueInternal(fieldName, value, opt_silent) {
        if (!this.fieldHasValue(fieldName)) {
            this.setInternal(fieldName, value, opt_silent);
        }
    }

    /**
     * Gets the value of a managed field.
     *
     * @param {string} fieldName The name of the backing field
     * @returns {*} The value of the field.
     * @protected
     */
    getFieldValue(fieldName) {
        return this.getField(fieldName).getValue();
    }

    /**
     * Sets the value of a managed field.
     *
     * @param {string} fieldName The name of the backing field whose value is being set.
     * @param {*} newValue The new value to be set on the field.
     * @param {boolean} markDirty Flag indicating whether to mark the field as dirty.
     * @returns {boolean} Returns true whether the value was accepted as the new value of the field (commited); otherwise returns false.
     * @protected
     */
    setFieldValue(fieldName, newValue, markDirty) {
        return this.getField(fieldName).setValue(newValue, markDirty);
    }

    /**
     *
     * @param {string} fieldName The name of the field
     * @param {boolean=} opt_silent Flag indicating whether to dispatch the change event
     * @protected
     */
    resetFieldValue(fieldName, opt_silent) {
        const field = this.getField(fieldName);

        if (field != null) {
            this.setInternal(fieldName, field.getDefaultValue(), !!opt_silent);
        }
    }

    /**
     *
     * @param {string} fieldName The name of the field
     * @param {boolean=} opt_silent Flag indicating whether to dispatch the change event
     * @protected
     */
    discardFieldValue(fieldName, opt_silent) {
        const field = this.getField(fieldName);

        if (field != null) {
            this.setInternal(fieldName, field.getOriginalValue(), !!opt_silent);
        }
    }

    /**
     * Loads a value into a field.
     *
     * @param {string} fieldName The name of the field
     * @param {*} value The new value to be assigned to the field
     * @param {!object=} opt_loadOptions An object describing load data options
     * @protected
     */
    loadFieldValue(fieldName, value, opt_loadOptions) {
        /* store the current value of the field */
        const currentValue = this.getFieldValue(fieldName);

        if (value != null && Observable.isObservableObject(currentValue)) {
            /* Note: Use #loadDataInternal instead of #loadData because in hf.data.DataModel #loadData calls #acceptChanges, which is not ok in this case */
            /** @type {hf.structs.observable.IObservableObject} */(currentValue).loadDataInternal(/** @type {!object} */(value), opt_loadOptions);
        } else if (value != null && Observable.isObservableCollection(currentValue)) {
            /** @type {hf.structs.ICollection} */(currentValue).reset(/** @type {Array} */(value));
        } else {
            this.setInternal(fieldName, value);
        }
    }

    /**
     * Tries to wrap a value into an hf.structs.observable.ObservableObject or hf.structs.observable.ObservableCollection
     *
     * @param {string} fieldName The fieldName of the field whose value is wrapped.
     * @param {*} value The value to wrap.
     * @returns {*} the wrapped value.
     * @protected
     */
    parseFieldValue(fieldName, value) {
        // Do not go any further if the value is a number, or a string, or a boolean, or a Date, or a function.
        if (value == null
            || BaseUtils.isNumber(value)
            || BaseUtils.isString(value)
            || BaseUtils.isDate(value)
            || BaseUtils.isFunction(value)
            || BaseUtils.isBoolean(value)) {
            return value;
        }

        // Wrap the value into a hf.structs.observable.ObservableCollection if the value is an array
        if (BaseUtils.isArray(value)) {
            value = new ObservableCollection({
                defaultItems: /** @type {!Array} */ (value),
                itemConverter: ObservableCollection.wrapChildrenIntoObservablesConverter
            });
        }
        // Wrap the value into an hf.structs.observable.ObservableObject if the value is a plain object.
        else if (ObjectUtils.isPlainObject(/** @type {object} */(value))) {
            value = new ObservableObject((value));
        }

        return value;
    }

    /**
     * @param {*} value The value to serialize.
     * @param {object=} opt_serializeOptions
     * @protected
     */
    serializeValue(value, opt_serializeOptions) {
        if (value instanceof Date) {
            return (/** @type {Date} */ (value));
        }
        if (value instanceof ObservableObject) {
            return (/** @type {hf.structs.observable.ObservableObject} */ (value)).toJSONObject(opt_serializeOptions);
        }
        if (BaseUtils.isArray(value) || ICollection.isImplementedBy(/** @type {object} */ (value))) {
            const items = BaseUtils.isArray(value) ? /** @type {Array} */(value) : (/** @type {hf.structs.ICollection} */ (value)).getAll(),
                result = [];

            let i = 0;
            const len = items.length;
            for (; i < len; i++) {
                const serializedItem = this.serializeValue(items[i], opt_serializeOptions);

                if ((BaseUtils.isObject(/** @type {!object} */(serializedItem)) && Object.keys(/** @type {!object} */(serializedItem)).length === 0)
                    || (BaseUtils.isArray(/** @type {Array} */(serializedItem)) && !(serializedItem).length)) {
                    continue;
                }

                // NOTE: JSCompiler can't optimize away Array#push.
                result[result.length] = serializedItem;
            }

            return result;
        }

        return value;
    }

    /**
     * Performs processing required when a field value has changed.
     *
     * @param {string} fieldName
     * @param {*} newValue
     * @param {*} oldValue
     * @protected
     */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        // announce the listeners that a field has changed.
        this.dispatchChangeEvent({
            field: fieldName,
            fieldPath: fieldName,
            newValue,
            oldValue
        });
    }

    /**
     * Links this object to its child.
     *
     * @param {object} child
     * @param {string} fieldName
     * @protected
     */
    createParentChildLink(child, fieldName) {
        if (!IObservable.isImplementedBy(child)) {
            return;
        }

        const listenableChild = /** @type {hf.events.EventTarget} */ (child);

        // listen to child 'CHANGE' event
        this.getEventHandler()
            .listen(listenableChild, ObservableChangeEventName, (e) => this.handleChildChange(fieldName, e));
    }

    /**
     * Un-links this object from its child.
     *
     * @param {object} child
     * @param {string} fieldName
     * @protected
     */
    removeParentChildLink(child, fieldName) {
        if (!IObservable.isImplementedBy(child)) {
            return;
        }

        const listenableChild = /** @type {hf.events.EventTarget} */ (child);

        // unlisten from child 'CHANGE' event
        this.getEventHandler()
            .unlisten(listenableChild, ObservableChangeEventName, (e) => this.handleChildChange(fieldName, e));
    }

    /**
     * Handles the CHANGE events of an Observable child: either a v.ObservableObject object or a hf.structs.observable.ObservableCollection object.
     *
     * @param {string} fieldName The name of the field representing the Observable child
     * @param {!hf.structs.observable.events.ChangeEvent} e
     * @returns {boolean}
     * @protected
     */
    handleChildChange(fieldName, e) {
        if (this.isChangeNotificationEnabled()) {
            return this.onChildChange(fieldName, e);
        }

        return true;
    }

    /**
     * Handles the CHANGE events of an Observable child: either a v.ObservableObject object or a hf.structs.observable.ObservableCollection object.
     *
     * @param {string} fieldName The name of the field representing the Observable child
     * @param {!hf.events.Event} e
     * @returns {boolean}
     * @protected
     */
    onChildChange(fieldName, e) {
        const payload = { ...e.payload || {} }, /* clone the payload. Do not modify the same payload */
            /* create a new specific CHANGE event to be dispatched */
            newChangeEvent = e instanceof CollectionChangeEvent
                // new hf.structs.observable.events.CollectionChangeEvent(payload, e.target) : new hf.structs.observable.events.FieldChangeEvent(payload, e.target),
                ? new CollectionChangeEvent(payload, e.target) : new FieldChangeEvent(payload, e.target),
            childFieldPath = payload.fieldPath;
        let fieldPath = '';

        // var changedChild = e.getCurrentTarget();
        // if (hf.structs.ICollection.isImplementedBy(/**@type {Object}*/(changedChild))) {
        //     var newItems = e['payload']['newItems'],
        //         oldItems = e['payload']['oldItems'],
        //         changedItem = hf.BaseUtils.isArray(newItems) && newItems.length > 0 ? newItems[0] :
        //             hf.BaseUtils.isArray(oldItems) && oldItems.length > 0 ? oldItems[0] : null;
        //
        //     fieldPath = changedItem != null ? fieldName + '.' + changedItem['index'] : fieldName;
        //
        //     fieldPath = StringUtils.isEmptyOrWhitespace(childFieldPath) ? fieldPath : fieldPath + '.' + childFieldPath;
        // }
        // else {
        //     fieldPath = StringUtils.isEmptyOrWhitespace(childFieldPath) ? fieldName : fieldName + '.' + childFieldPath;
        // }


        if (e instanceof CollectionChangeEvent) {
            const newItems = e.payload.newItems,
                oldItems = e.payload.oldItems,
                changedItem = BaseUtils.isArray(newItems) && newItems.length > 0 ? newItems[0]
                    : BaseUtils.isArray(oldItems) && oldItems.length > 0 ? oldItems[0] : null;

            fieldPath = changedItem != null ? `${fieldName}.${changedItem.index}` : fieldName;

            fieldPath = StringUtils.isEmptyOrWhitespace(childFieldPath) ? fieldPath : `${fieldPath}.${childFieldPath}`;
        } else {
            fieldPath = StringUtils.isEmptyOrWhitespace(childFieldPath) ? fieldName : `${fieldName}.${childFieldPath}`;
        }

        payload.fieldPath = fieldPath;

        return this.dispatchEvent(newChangeEvent);
    }

    /**
     * Dispatches the CHANGE event
     *
     * @param {!object.<string, *>=} eventData The CHANGE event data.
     * @returns {boolean}
     * @protected
     */
    dispatchChangeEvent(eventData) {
        if (this.isChangeNotificationEnabled()) {
            eventData = eventData || { field: '', fieldPath: '' };
            eventData.field = eventData.field || '';
            eventData.fieldPath = eventData.fieldPath || '';

            const event = new FieldChangeEvent(eventData, this);

            return this.dispatchEvent(event);
        }

        return false;
    }
}
// implements interfaces:
Listenable.addImplementation(ObservableObject);
IObservable.addImplementation(ObservableObject);
IObservableObject.addImplementation(ObservableObject);

/**
 *
 * @type {number}
 * @protected
 */
ObservableObject.instanceCount_ = 0;

/**
 * Creates a new {@see hf.structs.observable.ObservableCollection} object.
 *
 * @example
 var personsCollection = new hf.structs.observable.ObservableCollection(
 {
     'defaultItems': [{name: "John Doe"}, {name: "Jane Doe"}, {name: "Kid Doe"}],
     'itemConverter': function(rawItem){
         return new Person(item);
     }
 });
 *
 * @augments {Collection}
 * @implements {hf.structs.observable.IObservableCollection}
 *
 */
export class ObservableCollection extends Collection {
    /**
     * @param {!object=} opt_config The configuration object containing the config parameters
     *    @param {Array|hf.structs.ICollection=} opt_config.defaultItems The collection of items from which the elements are copied.
     *    @param {function(*): *=} opt_config.itemConverter A converter function which will be applied when an item is added to the collection.
     *
     */
    constructor(opt_config = {}) {
        // Call the base class constructor
        super();

        /**
         * A converter function which will be applied when an item is added to the collection.
         * The input value is the item that is about to be added to the collection, and the
         * output value is the item that will be actually added to the collection.
         *
         * @type {function(*): *}
         * @private
         */
        this.itemConverter_ = opt_config.itemConverter
            || function (opt_returnValue, var_args) { return opt_returnValue; };


        /**
         * The number of items in the collection
         *
         * @type {number}
         * @private
         */
        this.count_ = 0;
        Object.defineProperty(this, 'count', {
            get() {
                return this.count_;
            },
            set(value) {
                const oldValue = this.count_;
                if (value === oldValue) {
                    return;
                }

                this.count_ = value;

                this.onFieldValueChange('count', value, oldValue);
            }
        });

        /**
         * The event handler the model's events will be added to.
         * The events will be automatically cleared when the model changes or on dispose.
         *
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * Stores a value indicating whether the collection dispatches the CHANGE event.
         *
         * @type {boolean}
         * @default false
         * @private
         */
        this.isChangeNotificationEnabled_ = true;

        /**
         *
         * @type {number}
         * @private
         */
        this.disableChangeNotificationLevelsCount_ = 0;

        this.initItems(opt_config.defaultItems);
    }

    /**
     * @inheritDoc
     *
     */
    isChangeNotificationEnabled() {
        return this.isChangeNotificationEnabled_;
    }

    /**
     * @inheritDoc
     *
     */
    enableChangeNotification(enable) {
        this.disableChangeNotificationLevelsCount_ = enable ? this.disableChangeNotificationLevelsCount_ - 1 : this.disableChangeNotificationLevelsCount_ + 1;

        if (this.disableChangeNotificationLevelsCount_ < 0) {
            this.disableChangeNotificationLevelsCount_ = 0;
        }

        this.isChangeNotificationEnabled_ = this.disableChangeNotificationLevelsCount_ == 0;
    }

    /**
     *
     * @inheritDoc
     *
     */
    addRangeAt(items, startIndex) {
        if (items.length == 0) {
            return;
        }

        items = items.map(
            function (item) {
                return this.convertItem(item);
            }, this
        );

        super.addRangeAt(items, startIndex);

        this.onItemsRangeAdded(items, startIndex);
    }

    /**
     * @inheritDoc
     *
     */
    removeRange(startIndex, count) {
        const removedItems = super.removeRange(startIndex, count);

        return this.onItemsRangeRemoved(removedItems, startIndex);
    }

    /**
     * @inheritDoc
     *
     */
    reset(newItems) {
        this.enableChangeNotification(false);

        super.reset(newItems);

        this.enableChangeNotification(true);

        this.onCollectionChange({
            action: ObservableCollectionChangeAction.RESET // the action that caused the event
        });
    }

    /**
     * Returns a JSON representation
     *
     * @returns {string} A JSON string representation of this instance
     */
    toJSON() {
        const sb = [],
            count = this.getCount();
        let sep = '';

        // NOTE: JSCompiler can't optimize away Array#push.
        sb[sb.length] = '[';

        for (let i = 0; i < count; i++) {
            // NOTE: JSCompiler can't optimize away Array#push.
            sb[sb.length] = sep;

            const item = this.getAt(i);
            if (IObservable.isImplementedBy(/** @type {object} */ (item))) {
                // NOTE: JSCompiler can't optimize away Array#push.
                sb[sb.length] = /** @type {hf.structs.observable.IObservable} */ (item).toJSON();
            } else {
                // NOTE: JSCompiler can't optimize away Array#push.
                sb[sb.length] = JsonUtils.stringify(item);
            }

            sep = ',';
        }

        // NOTE: JSCompiler can't optimize away Array#push.
        sb[sb.length] = ']';

        return sb.join('');
    }

    /**
     * @inheritDoc
     */
    initItems(opt_defaultItems) {
        this.enableChangeNotification(false);

        if (ICollection.isImplementedBy(/** @type {object} */ (opt_defaultItems))) {
            // Obtains a copy of the collection items (an array).
            opt_defaultItems = /** @type {hf.structs.ICollection} */ (opt_defaultItems).getAll();
        } else if (BaseUtils.isArray(opt_defaultItems)) {
            // Copy the items into another array
            opt_defaultItems = [].concat(opt_defaultItems);
        } else {
            opt_defaultItems = [];
        }

        // call the base class method to add the items
        super.initItems(opt_defaultItems);

        this.enableChangeNotification(true);

        this.count = this.getCount();
    }

    /**
     * Returns the event handler for this presenter, lazily created the first time
     * this method is called.
     * The events' listeners will be added on this handler; they will be automatically cleared on dispose.
     *
     * @returns {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getEventHandler() {
        return this.eventHandler_
            || (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Dispatches the CHANGE event
     *
     * @param {!object.<string, *>} eventData The CHANGE event data.
     * @returns {boolean}
     * @fires {@see ObservableChangeEventName}
     * @protected
     */
    dispatchChangeEvent(eventData) {
        if (this.isChangeNotificationEnabled()) {
            const event = new CollectionChangeEvent(eventData, this);

            return this.dispatchEvent(event);
        }

        return false;
    }

    /**
     *
     * @param {!object} changeData
     * @protected
     */
    onCollectionChange(changeData) {
        const changeAction = changeData.action;
        if (ObservableCollectionMutableChangeActions.includes(changeAction)) {
            this.count = this.getCount();
        }

        this.dispatchChangeEvent(changeData);
    }

    /**
     * @param {string} field
     * @param {*} newValue
     * @param {*} oldValue
     * @returns {boolean}
     * @protected
     */
    onFieldValueChange(field, newValue, oldValue) {
        if (this.isChangeNotificationEnabled()) {
            const eventData = {
                field,
                fieldPath: field,
                newValue,
                oldValue
            };

            const event = new FieldChangeEvent(eventData, this);

            /* announce the listeners that a field of the collection has changed. */
            return this.dispatchEvent(event);
        }

        return false;
    }

    /**
     * Handle the post-insert of a range of items logic.
     * By default it dispatches the {@see ObservableChangeEventName} event,
     * announcing the listeners about the inserting of an item.
     *
     * @param {!Array} items
     * @param {number} startIndex
     * @protected
     */
    onItemsRangeAdded(items, startIndex) {
        const newItems = items.map((item, index) => ({ index: startIndex + index, item }), this);

        this.onCollectionChange({
            action: ObservableCollectionChangeAction.ADD, // the action that caused the event
            newItems // the list of new items involved in the change
        });
    }

    /**
     * Handle the post-remove of a range of items logic.
     * By default it dispatches the {@see ObservableChangeEventName} event,
     * announcing the listeners about the inserting of an item.
     *
     * @param {!Array} removedItems
     * @param {number} startIndex
     * @returns {!Array}
     * @protected
     */
    onItemsRangeRemoved(removedItems, startIndex) {
        removedItems = removedItems.map((item, index) => ({ index: startIndex + index, item }), this);

        this.onCollectionChange({
            action: ObservableCollectionChangeAction.REMOVE, // the action that caused the event
            oldItems: removedItems // get the list of items afected by the REMOVE action
        });

        return removedItems;
    }

    /**
     * @inheritDoc
     */
    insertItem(item, index) {
        if (item === undefined) {
            return;
        }

        // try to create the links for the new item (if possible)
        item = this.convertItem(item);

        super.insertItem(item, index);

        this.onItemInserted(item, index);
    }

    /**
     * Handle the post-insert logic.
     * By default it dispatches the {@see ObservableChangeEventName} event,
     * announcing the listeners about the inserting of an item.
     *
     * @param {*} item
     * @param {number} index
     * @protected
     */
    onItemInserted(item, index) {
        this.onCollectionChange({
            action: ObservableCollectionChangeAction.ADD, // the action that caused the event
            newItems: [{ index, item }] // the list of new items involved in the change
        });
    }

    /**
     * @inheritDoc
     */
    moveItem(oldIndex, newIndex) {
        const hasMoved = super.moveItem(oldIndex, newIndex),
            item = this.getAt(newIndex);
        if (hasMoved) {
            this.onCollectionChange({
                action: ObservableCollectionChangeAction.MOVE, // the action that caused the event
                newItems: [{ index: newIndex, item }], // the list of new items involved in the change
                oldItems: [{ index: oldIndex, item }] // get the list of items affected by the MOVE action
            });
        }

        return hasMoved;
    }

    /**
     * @inheritDoc
     */
    setItem(item, index) {
        const oldItem = this.getAt(index);

        // clean up the old item links (if any)
        this.unlistenFromChildEvents(oldItem);

        // create the links for the new item (if possible)
        item = this.convertItem(item);

        super.setItem(item, index);

        this.onItemReplaced(oldItem, item, index);
    }

    /**
     * Handle the post-replace logic.
     * By default it dispatches the {@see ObservableChangeEventName} event,
     * announcing the listeners about the replacing of an item.
     *
     * @param {*} oldItem
     * @param {*} newItem
     * @param {number} index
     * @protected
     */
    onItemReplaced(oldItem, newItem, index) {
        this.onCollectionChange({
            action: ObservableCollectionChangeAction.REPLACE, // the action that caused the event
            newItems: [{ index, item: newItem }], // the list of new items involved in the change
            oldItems: [{ index, item: oldItem }] // get the list of items affected by the REPLACE action
        });
    }

    /**
     * @inheritDoc
     */
    removeItem(index) {
        const itemToBeRemoved = this.getAt(index);

        super.removeItem(index);

        this.onItemRemoved(itemToBeRemoved, index);
    }

    /**
     * Handle the post-remove logic.
     * By default it dispatches the {@see ObservableChangeEventName} event,
     * announcing the listeners about the removing of an item.
     *
     * @param {*} removedItem
     * @param {number} index
     * @protected
     */
    onItemRemoved(removedItem, index) {
        // clean up the removed item links (if any)
        this.unlistenFromChildEvents(removedItem);

        this.onCollectionChange({
            action: ObservableCollectionChangeAction.REMOVE, // the action that caused the event
            oldItems: [{ index, item: removedItem }] // get the list of items affected by the REMOVE action
        });
    }

    /**
     * @inheritDoc
     */
    clearItems() {
        if (this.getCount() == 0) {
            return;
        }

        const items = this.getItems();

        // clean up the items links (if any)
        items.forEach(function (item) {
            this.unlistenFromChildEvents(item);
        }, this);

        super.clearItems();

        this.onCollectionChange({
            action: ObservableCollectionChangeAction.RESET // the action that caused the event
        });
    }

    /**
     * Handles the inner change of an item.
     *
     * @param {*} item
     * @param {number} index
     * @param {string} field
     * @param {string} fieldPath
     * @param {*} newValue
     * @param {*} oldValue
     * @protected
     */
    onItemChange(item, index, field, fieldPath, newValue, oldValue) {
        this.onCollectionChange({
            action: ObservableCollectionChangeAction.ITEM_CHANGE, // the action that caused the event
            newItems: [{ index, item }], // the list of new items involved in the change
            field, // the field whose value has changed,
            fieldPath, // the field whose value has changed,
            newValue,
            oldValue
        });
    }

    /**
     * Converts a raw rawItem into an rawItem that this collection expects.
     * This function is called when an rawItem is added to the collection.
     *
     * @param {*} rawItem The rawItem to be converted.
     * @returns {*}
     * @protected
     */
    convertItem(rawItem) {
        const item = this.itemConverter_(rawItem);

        // if(item instanceof hf.structs.observable.ObservableObject) {
        if (IObservableObject.isImplementedBy(/** @type {object} */(item))) {
            this.listenToChildEvents(/** @type {!hf.structs.observable.IObservableObject} */ (item));
        }

        return item;
    }

    /**
     * Listen to the events of a hf.structs.observable.IObservableObject item.
     *
     * @param {*} item A hf.structs.observable.IObservableObject item.
     * @returns {void}
     * @protected
     */
    listenToChildEvents(item) {
        // if(!(item instanceof hf.structs.observable.ObservableObject)) {
        if (!(IObservableObject.isImplementedBy(/** @type {object} */(item)))) {
            return;
        }

        // I have to listen to the 'CHANGE' event on item because I need to dispatch the collection 'CHANGE' event (it carries extra info).
        this.getEventHandler()
            .listen(/** @type {hf.events.EventTarget} */(item), ObservableChangeEventName, this.handleChildChange);
    }

    /**
     * Unlisten from the events of a hf.structs.observable.IObservableObject item.
     *
     * @param {*} item A hf.structs.observable.IObservableObject item.
     * @returns {void}
     * @protected
     */
    unlistenFromChildEvents(item) {
        // if(!(item instanceof hf.structs.observable.ObservableObject)) {
        if (!(IObservableObject.isImplementedBy(/** @type {object} */(item)))) {
            return;
        }

        this.getEventHandler()
            .unlisten(/** @type {hf.events.EventTarget} */(item), ObservableChangeEventName, this.handleChildChange);
    }

    /**
     * Handles the CHANGE event of a ObservableChangeEventName item.
     *
     * @param {!hf.events.Event} e
     * @returns {boolean}
     * @protected
     */
    handleChildChange(e) {
        const child = e.getCurrentTarget(), // the child the event handler was attached to
            index = this.indexOf(child),
            payload = e.payload;

        if (!this.contains(child)) {
            throw new Error('This item does not belong to the collection.');
        }

        this.onItemChange(child, index, payload.field, payload.fieldPath, payload.newValue, payload.oldValue);

        return true;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        // Call the superclass's disposeInternal() method.
        super.disposeInternal();

        // unlisten from all the model's events
        BaseUtils.dispose(this.eventHandler_);
        this.eventHandler_ = null;
    }

    /**
     * Converts a plain-object into an ObservableObject
     *
     * @param {*} rawItem The object to be converted into an observable
     *
     */
    static wrapChildrenIntoObservablesConverter(rawItem) {
        if (ObjectUtils.isPlainObject(/** @type {object} */(rawItem))) {
            rawItem = new ObservableObject((rawItem));
        }

        return rawItem;
    }
}
// implements the interfaces
Listenable.addImplementation(ObservableCollection);
IObservable.addImplementation(ObservableCollection);
IObservableCollection.addImplementation(ObservableCollection);
ICollection.addImplementation(ObservableCollection);
