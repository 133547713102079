import { LayoutContainer } from '../../../ui/layout/LayoutContainer.js';
import { DisplayRegion } from '../displayregion/DisplayRegion.js';
import { Disposable } from '../../../disposable/Disposable.js';
import { EventsUtils } from '../../../events/Events.js';
import { BrowserEventType } from '../../../events/EventType.js';

/**
 * Creates a new Layout object.
 *
 * @augments {Disposable}
 *
 */
export class AbstractLayout extends Disposable {
    /**
     * @param {hf.app.state.AppState} state
     */
    constructor(state) {
        /* call the base class constructor */
        super();

        /* provide current state for the layout, necessary if we develop complex layouts with animation on region containers */
        /**
         * Current app state the layout is running on
         *
         * @type {hf.app.state.AppState}
         * @private
         */
        this.state_ = state;

        /* initialize internal layout render tree structure */
        /**
         * Array of nodes in the layout: regions and region containers. This property is strictly private and must
         * not be accessed directly outside of this class!
         *
         * @type {Array.<hf.app.ui.DisplayRegion|hf.ui.layout.LayoutContainer|hf.ui.UIComponent>}
         * @private
         */
        this.tree_ = [];

        /**
         * Whether the layout is in the document.
         *
         * @type {boolean}
         * @private
         */
        this.inDocument_ = false;

        /**
         * @type {EventKey}
         * @private
         */
        this.windowResizeListenerKey_ = null;
    }

    /**
     * Gets the state.
     *
     * @protected
     * @returns {hf.app.state.AppState}
     */
    getState() {
        return this.state_;
    }

    /**
     * Adds the specified region or region container as the last node of this layout
     *
     * @param {hf.app.ui.DisplayRegion|hf.ui.layout.LayoutContainer|hf.ui.UIComponent} child The child to add to this layout. Can be a DisplayRegion, or a Container to help with positioning.
     */
    add(child) {
        this.tree_.push(child);
    }

    /**
     * Returns the child at the given index, or null if the index is out of bounds.
     *
     * @param {number} index 0-based index.
     * @returns {hf.app.ui.DisplayRegion|hf.ui.layout.LayoutContainer|hf.ui.UIComponent} The child at the given index; null if none.
     */
    getItemAt(index) {
        // Use children_ for access by index.
        return this.tree_ ? this.tree_[index] || null : null;
    }

    /**
     * Calls the given function on each of this component's regions/region containers in order.  If
     * {@code opt_obj} is provided, it will be used as the 'this' object in the
     * function when called.  The function should take two arguments:  the child
     * component and its 0-based index.  The return value is ignored.
     *
     * @param {function(this:T,?,number):?} f The function to call for every
     * child component; should take 2 arguments (the child and its index).
     * @param {T=} opt_obj Used as the 'this' object in f when called.
     * @template T
     */
    forEachChild(f, opt_obj) {
        if (this.tree_) {
            this.tree_.forEach(f, opt_obj);
        }
    }

    /**
     * Gets the layout's render tree
     *
     * @returns {boolean} Returns true if layout render tree is created, false otherwise
     */
    hasRenderTree() {
        return this.tree_.length > 0;
    }

    /**
     * Returns the name of the layout
     *
     * @returns {string}
     */
    getName() { throw new Error('unimplemented abstract method'); }

    /**
     * The user must override this method to create a new layout.
     */
    create() { throw new Error('unimplemented abstract method'); }

    /**
     * Updates the current layout according to the new state
     * The current layout does not need to change, the updates are small animations that cannot be performed on regions themselves, but on containers of regions
     *
     * @param {hf.app.state.AppState} newState
     * @param {hf.app.state.AppState} previousState
     * @returns {void}
     */
    update(newState, previousState) {
        this.state_ = newState;
    }

    /**
     * Determines whether the layout has been added to the document.
     *
     * @returns {boolean} TRUE if rendered. Otherwise, FALSE.
     */
    isInDocument() {
        return this.inDocument_;
    }

    /**
     * Called by the layout manager to clean up the regions on layouts
     * that exit the document. The layout can be later on reused.
     * It's internal structure is not cleared.
     *
     * Layout cleanup:
     *      - remove styles from regions
     *      - remove regions from containers, in order to be able to add them in new containers on other layouts: they must not be in the document and they must not have children
     *
     * It should be possible for the layout to be rendered again once this method
     * has been called.
     */
    exitDocument() {
        const clearLayout = function (child) {
            // if (child instanceof hf.ui.container.Container) {
            if (!(child instanceof DisplayRegion)) {
                /* clear (remove styles) regions recursively because containers might hold other containers and/or regions */
                child.forEachChild(clearLayout);

                /* remove regions from containers */
                if (child instanceof LayoutContainer) {
                    child.removeChildren(true);
                }
                /* dispose region containers: implies region removal from document and parent-child relationship break, only if not inside other containers
                 * in this case they will be cleared by their parent containers */
                if (!child.getParent()) {
                    child.dispose();
                }
            } else {
                /* remove all inline styles from regions */
                child.clearStyle();

                /* TBD: remove all css classes from regions */

                /* remove regions from document if not inside containers */
                if (!child.getParent()) {
                    if (child.getElement() && child.getElement().parentNode) {
                        child.getElement().parentNode.removeChild(child.getElement());
                    }
                    child.exitDocument();
                }
            }
        };

        /* parse layout tree and cleanup all regions */
        this.forEachChild(clearLayout);

        /* cleanup internal render tree structure */
        this.tree_ = [];

        EventsUtils.unlistenByKey(this.windowResizeListenerKey_);
        this.windowResizeListenerKey_ = null;

        this.inDocument_ = false;
    }

    /**
     * Called when the layout enters the document.
     */
    enterDocument() {
        this.inDocument_ = true;

        // Propagate enterDocument to child components that have a DOM, if any.
        this.forEachChild((child) => {
            if (!child.isInDocument() && child.getElement()) {
                child.enterDocument();
            }
        });

        this.windowResizeListenerKey_ = EventsUtils.listen(
            /** @type {EventTarget} */ (window),
            BrowserEventType.RESIZE,
            EventsUtils.debounceListener(this.handleWindowResize, this.getResizeDebouncePeriod(), false), false, this
        );
    }

    /**
     * Renders the layout.  The layout is rendered directly in the document body.
     */
    render() {
        let renderTree = this.hasRenderTree();
        if (!renderTree) {
            this.create();
        }

        /* render layout tree nodes: all regions and region containers */
        this.forEachChild((child) => {
            child.render();
        });

        /* mark layout in document */
        this.enterDocument();
    }

    /**
     * Fetch debounce period (milliseconds)
     *
     * @returns {number}
     */
    getResizeDebouncePeriod() {
        return 200;
    }

    /**
     * Handles window resize event
     *
     * @param {hf.events.Event} e
     * @protected
     */
    handleWindowResize(e) {
        // nop
    }

    /** @inheritDoc */
    disposeInternal() {
        this.state_ = null;
        this.tree_ = null;
        this.windowResizeListenerKey_ = null;

        super.disposeInternal();
    }
}
