import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ScreenShareStatus} from "./Enums.js";
import {ScreenShareParticipantCollection} from "./ScreenShareParticipantCollection.js";
import {Author} from "./../author/Author.js";
import {HgAuthorUtils} from "./../author/Common.js";

/**
 * @extends {DataModel}
 * @unrestricted 
*/
export class ScreenShare extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'sessionId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* HubgetsScreenSharing.Session id or HubgetsScreenSharing.ScreenEvent roomId */
        this.addField({'name': 'sessionId', 'type': DataModelField.PredefinedTypes.STRING});

        /* ScreenShareUserRole */
        this.addField({'name': 'userRole', 'type': DataModelField.PredefinedTypes.STRING});

        /* starter of screen sharing */
        this.addField({'name': 'author', 'type': Author,
            'parser': HgAuthorUtils.getAuthor,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* list of viewers joining this screen share */
        this.addField({'name': 'participant', 'type': ScreenShareParticipantCollection});

        /* ScreenShareWithTypes - Canonical type: USER, INVITATION, TOPIC */
        this.addField({'name': 'shareWithType', 'type': DataModelField.PredefinedTypes.STRING});

        /* threadId */
        this.addField({'name': 'shareWithId', 'type': DataModelField.PredefinedTypes.STRING});

        /* HTMLMediaElement: video; duration can be fetched from mediaElement.currentTime */
        this.addField({'name': 'mediaElement', 'type': HTMLMediaElement, 'isPersistable': false});

        /* HubgetsScreenSharing.Session: TODO: should not necessarily be kept here, maybe in ScreenShareService! */
        this.addField({'name': 'session', 'value': null, 'isPersistable': false});

        /* ScreenShareStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING, 'value': ScreenShareStatus.ONAIR});

        this.addField({'name': 'isInFullScreen', 'type': DataModelField.PredefinedTypes.BOOL, 'value': false});
    }

    /** @inheritDoc */
    defineCustomFields() {
        this.addField({'name': 'participantCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'value': 0});

        this.addField({'name': 'isMine', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': this.createLazyGetter('isMine',
                function() {
                    return this.get('author.isMe');
                }
            )
        });
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);


        if (fieldName == 'participant') {
            this['participantCount'] = this['participant'].getCount();
        }
    }

    /** @inheritDoc */
    onChildChange(fieldName, e) {
        const result = super.onChildChange(fieldName, e);

        if (fieldName == 'participant') {
            this['participantCount'] = this['participant'].getCount();
        }

        return result;
    }
};