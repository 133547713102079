import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { ObjectUtils } from '../../object/object.js';
import { ICriteria } from './ICriteria.js';
import { FilterDescriptor, FilterOperators } from '../FilterDescriptor.js';
import { SortDescriptor, SortDirection } from '../SortDescriptor.js';
import { StringUtils } from '../../string/string.js';
import { MAX_SAFE_INTEGER } from '../../math/Math.js';

/**
 * Format the filter value
 *
 * @param {*} value
 * @returns {*}
 */
function formatValue(value) {
    /* todo: extend this for all necessary types */
    if (BaseUtils.isDate(value)) {
        value = (/** @type {Date} */(value)).toISOString();
    }

    return value;
}

/**
 *
 * @enum {string}
 * @readonly
 */
export const FetchNextChunkPointer = {
    START_INDEX: 'start_index',

    START_ITEM: 'start_item'
};

/**
 *
 * @enum {string}
 * @readonly
 */
export const FetchDirection = {
    /* Fetch the next chunck of data */
    FORWARD: 'fetch_forward',

    /* Fetch the previous chunck of data */
    REVERSE: 'fetch_reverse'
};

/**
 * Creates a new hf.data.criteria.FetchCriteria object.
 *
 * @example
    var criteria1 = new hf.data.criteria.FetchCriteria({
        'filters': [
            { 'filterBy': 'age', 'filterOp': 'greater', 'filterValue': 18 },
            { 'filterBy': 'lastName', 'filterOp': 'startsWith', 'filterValue': 'ada' }
        ],
        'sorters': [
            { 'sortBy': 'firstName', 'direction': 'desc' }
        ],
 
        'searchValue': 'Ana',
 
        'isQuickSearch': true
 
        'selectedFields': '@short',
 
        'startIndex': 19,
 
        'fetchSize': 20,
    });
 
    var criteria2 = new hf.data.criteria.FetchCriteria().addFilter({'filterBy': 'age', 'filterValue': 18, 'filterOp': 'greater'})
                                               .addFilter({'filterBy': 'lastName', 'filterValue': 'ada', 'filterOp': 'startsWith'})
                                               .sortBy('firstName')
                                               .sortBy('lastName', 'desc')
                                               .setStartIndex(19)
                                               .setFetchSize(20)
                                               .selectFields('@short');
 *
 * @augments {Disposable}
 * @implements {ICriteria}
 *
 */
export class FetchCriteria extends Disposable {
    /**
     * @param {!object=} opt_config The configuration object containing the configuration properties.
     *   @param {Array.<object | FilterDescriptor>=} opt_config.filters The criteria's filters
     *   @param {Array.<object | SortDescriptor>=} opt_config.sorters The criteria's sorters
     *   @param {string=} opt_config.fetchDirection
     *   @param {number=} opt_config.fetchSize The number of items to retrieve. If null then all items must be retrieved.
     *   @param {string=} opt_config.nextChunkPointer
     *   @param {number|string=} opt_config.startIndex Required if nextChunkPointer = START_INDEX
     *   @param {string=} opt_config.startItemProperty Required if nextChunkPointer = START_ITEM
     *   @param {boolean=} opt_config.useStartItemAsStartIndex
     *   @param {string=} opt_config.nextChunk The index of the first object in the next chunk.
     *   @param {string=} opt_config.prevChunk The index of the first object in the prev chunk.
     *   @param {(string | Array.<string>)=} opt_config.selectedFields
     *   @param {string=} opt_config.searchValue
     *   @param {boolean=} opt_config.isQuickSearch
     *   @param {object} opt_config.limit The criteria's Describes where to look for. Used for search.
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * The criteria's filters.
         *
         * @type {Array.<hf.data.FilterDescriptor>}
         * @default []
         * @private
         */
        this.filters_;

        /**
         * The criteria's sorters.
         *
         * @type {Array.<hf.data.SortDescriptor>}
         * @default []
         * @private
         */
        this.sorters_;

        /**
         * Describes where to look for.
         *
         * @type {object}
         * @private
         */
        this.limit_;

        /**
         * The criteria's boost.
         *
         * @type {Array.<!object>}
         * @default []
         * @private
         */
        this.boost_;

        /**
         * @type {string}
         * @private
         */
        this.nextChunkPointer_;

        /**
         * A value indicating the index in the data source at which to start retrieving the the next chunk of items.
         *
         * @type {?number | ?string | undefined}
         * @default 0
         * @private
         */
        this.startIndex_;

        /**
         * A value indicating the item in the data source at which to start retrieving the the next chunk of items.
         *
         * @type {*}
         * @private
         */
        this.startItem_;

        /**
         * The name of the property of the start item; the value of this property will be used to retrieve the next chunk of items.
         *
         * @type {string}
         * @private
         */
        this.startItemProperty_;

        /**
         * Indicates whether the value obtained from the start item property should be used as start index; use case: some APIs accept non-numerical start index -e.g. a Date start-index
         *
         * @type {boolean}
         * @private
         */
        this.useStartItemAsStartIndex_;

        /**
         * The index of the first object in the next chunk
         *
         * @type {string|null|undefined}
         * @private
         */
        this.nextChunk_;

        /**
         * The index of the first object in the prev chunk.
         *
         * @type {string|null|undefined}
         * @private
         */
        this.prevChunk_;

        /**
         * The number of results to return. The default is 20 items.
         * If null then all the items must be retrieved.
         *
         * @type {number}
         * @default 10
         * @private
         */
        this.fetchSize_;

        /**
         * @type {FetchDirection}
         * @default FetchDirection.FORWARD
         * @private
         */
        this.fetchDirection_;

        /**
         * The fields to return from the server.
         *
         * @type {?string | Array.<string> | undefined}
         * @private
         */
        this.selectedFields_;

        /**
         *
         *
         * @type {?string | undefined}
         * @private
         */
        this.searchValue_;

        /**
         *
         *
         * @type {?boolean | undefined}
         * @private
         */
        this.isQuickSearch_;
    }

    /**
     * Adds a filter to the collection of filters.
     *
     * @param {object} filter
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    filter(filter) {
        if (filter == null) {
            throw new Error('The \'filter\' parameter must be defined and not null');
        }

        if (!(filter instanceof FilterDescriptor)) {
            filter = new FilterDescriptor(filter);
        }

        let existingFilter = this.filters_.find((filterDesc) => filterDesc.equals(/** @type {hf.data.FilterDescriptor} */(filter)));
        if (!existingFilter) {
            this.filters_.push(filter);
        }

        return this;
    }

    /**
     * Gets this criteria's filters.
     *
     * @returns {Array.<hf.data.FilterDescriptor>}
     *
     */
    getFilters() {
        return this.filters_;
    }

    /**
     *
     */
    clearFilters() {
        this.filters_.length = 0;
    }

    /**
     * Adds a sorter to the collection of sorters
     *
     * @param {object} sorter
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    sort(sorter) {
        if (sorter == null) {
            throw new Error('The \'sorter\' parameter must be defined and not null');
        }

        if (!(sorter instanceof SortDescriptor)) {
            sorter = new SortDescriptor(sorter);
        }

        let existingSorter = this.sorters_ != null
            ? this.sorters_.find((sorterDesc) => sorterDesc.equals(/** @type {hf.data.SortDescriptor} */(sorter)))
            : null;

        if (!existingSorter) {
            /* create sorters on demand */
            (this.sorters_ || (this.sorters_ = [])).push(sorter);
        }

        return this;
    }

    /**
     * Adds a sorter to the collection of sorters by specifing the sortBy field and the sort direction
     *
     * @param {string} fieldName
     * @param {SortDirection=} opt_direction
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    sortBy(fieldName, opt_direction) {
        if (StringUtils.isEmptyOrWhitespace(fieldName)) {
            throw new Error('The \'fieldName\' parameter must be not empty');
        }

        const sorter = new SortDescriptor({ sortBy: fieldName, direction: opt_direction });

        return this.sort(sorter);
    }

    /**
     * Gets this criteria's sorters.
     *
     * @returns {Array.<hf.data.SortDescriptor>}
     *
     */
    getSorters() {
        return this.sorters_;
    }

    /**
     *
     */
    clearSorters() {
        if (this.sorters_) {
            this.sorters_.length = 0;
        }
    }

    setLimit(limit) {
        this.limit_ = limit;
    }

    /**
     *
     */
    getLimit() {
        return this.limit_;
    }

    /**

     *
     * @param {!object} newBoost
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    addBoost(newBoost) {
        if (Object.keys(newBoost).length === 0) {
            throw new Error('The \'boost\' parameter can not be empty');
        }

        let existingBoost = this.boost_.find((boost) => ObjectUtils.equals(newBoost, boost));
        if (!existingBoost) {
            this.boost_.push(newBoost);
        }

        return this;
    }

    /**
     * Gets this criteria's boost.
     *
     * @returns {Array.<object>}
     *
     */
    getBoost() {
        return this.boost_;
    }

    /**
     *
     */
    clearBoost() {
        this.boost_.length = 0;
    }

    /**
     * Sets the number of entries to be retrieved.
     *
     * @param {number} fetchSize
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setFetchSize(fetchSize) {
        if (!BaseUtils.isNumber(fetchSize)) {
            throw new Error('The \'fetchSize\' parameter must be a number.');
        }

        this.fetchSize_ = fetchSize;

        return this;
    }

    /**
     * Gets the number of entries to be retrieved.
     *
     * @returns {number}
     */
    getFetchSize() {
        return this.fetchSize_;
    }

    /**
     * Sets the fetch direction: FORWARD or REVERSE
     *
     * @param {FetchDirection} fetchDirection
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setFetchDirection(fetchDirection) {
        this.fetchDirection_ = fetchDirection;

        return this;
    }

    /**
     * Gets the fetch direction.
     *
     * @returns {FetchDirection}
     *
     */
    getFetchDirection() {
        return this.fetchDirection_;
    }

    /**
     * Sets the next chunk pointer: NEXT_INDEX or NEXT_ITEM
     *
     * @param {string} nextChunkPointer
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setNextChunkPointer(nextChunkPointer) {
        this.nextChunkPointer_ = nextChunkPointer;

        return this;
    }

    /**
     * Gets the next chunk pointer.
     *
     * @returns {string}
     *
     */
    getNextChunkPointer() {
        return this.nextChunkPointer_;
    }

    /**
     * Sets the start index.
     *
     * @param {number|string} startIndex
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setStartIndex(startIndex) {
        // if(!hf.BaseUtils.isNumber(startIndex)){
        //     throw new Error('The \'startIndex\' parameter must be a number');
        // }

        this.startIndex_ = startIndex;

        return this;
    }

    /**
     * Gets a value indicating the index in the data source at which to start retrieving the new items.
     *
     * @returns {?number | ?string | undefined}
     *
     */
    getStartIndex() {
        return this.startIndex_;
    }

    /**
     * Returns the filter used when the 'next chunk pointer' is START_ITEM.
     * Its characteristic is that the filterOp changes and depends on two pieces of information:
     * 1. the sort direction of the 'start item' sorter (if exists, otherwise it takes into consideration the first sorter from the sorters collection),
     * 2. the fetch direction: FORWARD or REVERSE.
     *
     * @returns {object}
     *
     */
    getStartItemFilter() {
        if (this.nextChunkPointer_ === FetchNextChunkPointer.START_ITEM && this.startItem_ != null) {
            const startItemFilter = {
                filterBy: this.startItemProperty_,
                /* by default consider the items are ordered ASC */
                filterOp: this.fetchDirection_ === FetchDirection.FORWARD
                    ? FilterOperators.GREATER_THAN_OR_EQUAL_TO : FilterOperators.LESS_THAN_OR_EQUAL_TO,
                filterValue: ObjectUtils.getPropertyByPath(/** @type {object} */(this.startItem_), this.startItemProperty_)
            };

            if (this.sorters_ && this.sorters_.length > 0) {
                let startItemSorter = this.sorters_.find(function (sorter) {
                    return sorter.sortBy === this.startItemProperty_;
                }, this);

                /* if 'no start item sorter' is found then take the first sorter by default */
                startItemSorter = startItemSorter || this.sorters_[0];

                startItemFilter.filterOp =
                    startItemSorter.direction === SortDirection.ASC
                        ? this.fetchDirection_ === FetchDirection.FORWARD ? FilterOperators.GREATER_THAN_OR_EQUAL_TO : FilterOperators.LESS_THAN_OR_EQUAL_TO
                        /* sort direction: DESC */
                        : this.fetchDirection_ === FetchDirection.FORWARD ? FilterOperators.LESS_THAN_OR_EQUAL_TO : FilterOperators.GREATER_THAN_OR_EQUAL_TO;

                return startItemFilter;
            }
        }

        return null;
    }

    /**
     * Returns the sorter used when the 'next chunk pointer' is START_ITEM.
     * Its characteristic is that the 'direction' changes and depends on two pieces of information:
     * 1. the sort direction of the 'start item' sorter, if exists,
     * 2. the fetch direction: FORWARD or REVERSE.
     *
     * @returns {object | undefined}
     */
    getStartItemSorter() {
        let startItemSorter = this.sorters_
            ? this.sorters_.find(function (sorter) {
                return sorter.sortBy === this.startItemProperty_;
            }, this)
            : null;

        if (startItemSorter != null) {
            startItemSorter = startItemSorter.toJSONObject();

            if (this.startItem_ != null) {
                startItemSorter = {
                    sortBy: startItemSorter.sortBy,
                    direction: startItemSorter.direction === SortDirection.ASC
                        ? this.fetchDirection_ === FetchDirection.FORWARD ? SortDirection.ASC : SortDirection.DESC
                        /* sort direction: DESC */
                        : this.fetchDirection_ === FetchDirection.FORWARD ? SortDirection.DESC : SortDirection.ASC
                };
            }
        }

        return startItemSorter;
    }

    /**
     * Sets a value indicating the item in the data source at which to start retrieving the next chunk of items.
     *
     * @param {*} startItem
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setStartItem(startItem) {
        this.startItem_ = startItem;

        return this;
    }

    /**
     * Gets a value indicating the item in the data source at which to start retrieving the next chunk of items.
     *
     * @returns {*}
     *
     */
    getStartItem() {
        return this.startItem_;
    }

    /**
     * Sets the name of the property of the start item.
     *
     * @param {string} propertyName
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setStartItemProperty(propertyName) {
        this.startItemProperty_ = propertyName;

        return this;
    }

    /**
     * Gets the name of the property of the start item.
     *
     * @returns {string}
     *
     */
    getStartItemProperty() {
        return this.startItemProperty_;
    }

    /**
     * Gets a value that indicates whether the value obtained from the start item property should be used as start index.
     *
     * @returns {boolean}
     */
    useStartItemAsStartIndex() {
        return this.useStartItemAsStartIndex_;
    }

    /**
     * Sets a value indicating the index of the first object in the next chunk.
     *
     * @param {string|null|undefined} nextChunk
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setNextChunk(nextChunk) {
        this.nextChunk_ = nextChunk;

        return this;
    }

    /**
     * Gets a value indicating the index of the first object in the next chunk.
     *
     * @returns {string|null|undefined}
     *
     */
    getNextChunk() {
        return this.nextChunk_;
    }

    /**
     * Sets a value indicating the index of the first object in the prev chunk.
     *
     * @param {string|null|undefined} prevChunk
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setPrevChunk(prevChunk) {
        this.prevChunk_ = prevChunk;

        return this;
    }

    /**
     * Gets a value indicating the index of the first object in the prev chunk.
     *
     * @returns {string|null|undefined}
     *
     */
    getPrevChunk() {
        return this.prevChunk_;
    }

    /**
     *
     *
     * @param {string | Array.<string>} fields
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    selectFields(fields) {
        this.selectedFields_ = fields;

        return this;
    }

    /**
     *
     * @returns {string | Array.<string> | undefined}
     *
     */
    getSelectedFields() {
        return BaseUtils.isArray(this.selectedFields_)
            /** @type {Array} */? (/** @type {Array} */ (this.selectedFields_).slice(0))
            : this.selectedFields_;
    }

    /**
     * Sets the search value.
     *
     * @param {string} searchValue
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setSearchValue(searchValue) {
        this.searchValue_ = searchValue;

        return this;
    }

    /**
     *
     * @returns {?string | undefined}
     */
    getSearchValue() {
        return this.searchValue_;
    }

    /**
     *
     *
     * @param {boolean} isQuick
     * @returns {!hf.data.criteria.FetchCriteria}
     *
     */
    setIsQuickSearch(isQuick) {
        this.isQuickSearch_ = isQuick;

        return this;
    }

    /**
     *
     * @returns {?boolean | undefined}
     */
    isQuickSearch() {
        return this.isQuickSearch_;
    }

    /**
     * @inheritDoc
     */
    equals(otherCriteria, opt_strictEquality) {
        if (otherCriteria == null) {
            return false;
        }

        const current = this.toJSONObject(),
            other = otherCriteria.toJSONObject();

        if (opt_strictEquality) {
            return ObjectUtils.equals(current, other);
        }

        return BaseUtils.equals(current.filters, other.filters)
            && BaseUtils.equals(current.searchValue, other.searchValue)
            && BaseUtils.equals(current.sorters, other.sorters)
            // BaseUtils.equals(current['limit'], other['limit']) &&
            && BaseUtils.equals(current.nextChunkPointer, other.nextChunkPointer)
            && BaseUtils.equals(current.startItemProperty, other.startItemProperty)
            && BaseUtils.equals(current.useStartItemAsStartIndex, other.useStartItemAsStartIndex);
    }

    /**
     * @inheritDoc
     */
    clone() {
        return new FetchCriteria(this.toJSONObject());
    }

    /**
     * TODO: Wouldn't be better to take the currentCriteria as reference for the resulting criteria?
     * This means to merge the infos provided by the otherCriteria into the currentCriteria by overriding them.
     * CHECH THE IMPACT A.S.A.P
     *
     * @param {object} otherCriteria
     * @returns {hf.data.criteria.FetchCriteria}
     */
    merge(otherCriteria) {
        otherCriteria = otherCriteria instanceof FetchCriteria
        /** @type {hf.data.criteria.FetchCriteria} */ ? (otherCriteria).toJSONObject()
            : otherCriteria;

        const currentCriteria = this.toJSONObject();

        //    /* keep sorters if the new criteria didn't define new ones */
        //    otherCriteria['sorters'] = otherCriteria['sorters'] !== undefined ? otherCriteria['sorters'] : currentCriteria['sorters'];
        //
        //    /* keep next chunk pointer if the new criteria didn't define a new one */
        //    otherCriteria['nextChunkPointer'] = otherCriteria['nextChunkPointer'] !== undefined ? otherCriteria['nextChunkPointer'] : currentCriteria['nextChunkPointer'];
        //
        //    /* keep last item criteria if the new criteria didn't define a new one */
        //    otherCriteria['lastItemCriteria'] = otherCriteria['lastItemCriteria'] !== undefined ? otherCriteria['lastItemCriteria'] : currentCriteria['lastItemCriteria'];
        //
        //    /* keep fetch size if the new criteria didn't define a new one*/
        //    otherCriteria['fetchSize'] = otherCriteria['fetchSize'] !== undefined ? otherCriteria['fetchSize'] : currentCriteria['fetchSize'];
        //
        //    return new hf.data.criteria.FetchCriteria(otherCriteria);

        /* keep filters if the new criteria didn't define new ones */
        if (otherCriteria.filters !== undefined) {
            currentCriteria.filters = otherCriteria.filters;
        }

        /* keep searchValue if the new criteria didn't define new one */
        if (otherCriteria.searchValue !== undefined) {
            currentCriteria.searchValue = otherCriteria.searchValue;
        }

        /* keep isQuickSearch if the new criteria didn't define  a new one */
        if (otherCriteria.isQuickSearch !== undefined) {
            currentCriteria.isQuickSearch = otherCriteria.isQuickSearch;
        }

        /* keep sorters if the new criteria didn't define new ones */
        if (otherCriteria.sorters !== undefined) {
            currentCriteria.sorters = otherCriteria.sorters;
        }

        /* keep limit if the new criteria didn't define new ones */
        if (otherCriteria.limit !== undefined) {
            currentCriteria.limit = otherCriteria.limit;
        }

        /* keep boost if the new criteria didn't define new ones */
        if (otherCriteria.boost !== undefined) {
            currentCriteria.boost = otherCriteria.boost;
        }

        /* keep nextChunkPointer if the new criteria didn't define a new one */
        if (otherCriteria.nextChunkPointer !== undefined) {
            currentCriteria.nextChunkPointer = otherCriteria.nextChunkPointer;
        }

        /* keep startIndex if the new criteria didn't define a new one */
        if (otherCriteria.startIndex !== undefined) {
            currentCriteria.startIndex = otherCriteria.startIndex;
        }

        if (otherCriteria.startItemProperty !== undefined) {
            currentCriteria.startItemProperty = otherCriteria.startItemProperty;
        }

        if (otherCriteria.useStartItemAsStartIndex !== undefined) {
            currentCriteria.useStartItemAsStartIndex = otherCriteria.useStartItemAsStartIndex;
        }

        /* keep nextChunk if the new criteria didn't define a new one */
        if (otherCriteria.nextChunk !== undefined) {
            currentCriteria.nextChunk = otherCriteria.nextChunk;
        }

        /* keep prevChunk if the new criteria didn't define a new one */
        if (otherCriteria.prevChunk !== undefined) {
            currentCriteria.prevChunk = otherCriteria.prevChunk;
        }

        /* keep fetchSize if the new criteria didn't define a new one */
        if (otherCriteria.fetchSize !== undefined) {
            currentCriteria.fetchSize = otherCriteria.fetchSize;
        }

        if (otherCriteria.fetchDirection !== undefined) {
            currentCriteria.fetchDirection = otherCriteria.fetchDirection;
        }

        return new FetchCriteria(currentCriteria);
    }

    /**
     * Transforms this fetch criteria's data in a JSON string
     *
     * @returns {string}
     *
     */
    toJSON() {
        return JSON.stringify(this.toJSONObject());
    }

    /**
     * @inheritDoc
     */
    toJSONObject() {
        const result = {},
            sorters = this.sorters_,
            filters = this.filters_,
            limit = this.limit_,
            boost = this.boost_,
            searchValue = this.searchValue_,
            isQuickSearch = this.isQuickSearch_,
            nextChunkPointer = this.nextChunkPointer_,
            startIndex = this.startIndex_,
            startItem = this.startItem_,
            startItemProperty = this.startItemProperty_,
            useStartItemAsStartIndex = this.useStartItemAsStartIndex_,
            nextChunk = this.nextChunk_,
            prevChunk = this.prevChunk_,
            selectedFields = this.selectedFields_,
            fetchSize = this.fetchSize_,
            fetchDirection = this.fetchDirection_;

        // filters
        if (filters.length > 0) {
            result.filters = filters.map((filter) => filter.toJSONObject());
        }

        // sorters
        if (sorters && sorters.length > 0) {
            result.sorters = sorters.map((sorter) => sorter.toJSONObject());
        }

        if (limit && Object.keys(limit).length > 0) {
            result.limit = limit;
        }

        // boost
        if (boost.length > 0) {
            result.boost = boost.map((boost) => boost);
        }

        // searchValue
        if (searchValue != null) {
            result.searchValue = searchValue;
        }

        // isQuick
        if (BaseUtils.isBoolean(isQuickSearch)) {
            result.isQuickSearch = isQuickSearch;
        }

        // next chunk pointer
        if (nextChunkPointer != null) {
            result.nextChunkPointer = nextChunkPointer;
        }

        // startIndex
        if (startIndex != null) {
            result.startIndex = startIndex;
        }

        // startItem
        if (startItem != null) {
            result.startItem = startItem;
        }

        // startItemProperty
        if (startItemProperty != null) {
            result.startItemProperty = startItemProperty;
        }

        // useStartItemAsStartIndex
        if (useStartItemAsStartIndex != null) {
            result.useStartItemAsStartIndex = useStartItemAsStartIndex;
        }

        // nextChunk
        if (nextChunk != null) {
            result.nextChunk = nextChunk;
        }

        // prevChunk
        if (prevChunk != null) {
            result.prevChunk = prevChunk;
        }

        // fetchSize
        if (fetchSize != null) {
            result.fetchSize = fetchSize;
        }

        // fetchDirection
        if (fetchDirection != null) {
            result.fetchDirection = fetchDirection;
        }

        // selected fields
        if (selectedFields != null) {
            result.selectedFields = selectedFields;
        }

        return result;
    }

    /**
     *
     * @returns {object}
     */
    toPayloadObject() {
        const outputCriteria = {};

        const fetchCriteria = this,
            jsonCriteria = fetchCriteria.toJSONObject();

        // startIndex
        if (fetchCriteria.getNextChunk() != null || fetchCriteria.getPrevChunk() != null) {
            outputCriteria.startIndex = fetchCriteria.getFetchDirection() === FetchDirection.REVERSE
                ? fetchCriteria.getPrevChunk() : fetchCriteria.getNextChunk();
        } else if (fetchCriteria.getNextChunkPointer() === FetchNextChunkPointer.START_INDEX) {
            // startIndex
            if (jsonCriteria.startIndex) {
                outputCriteria.startIndex = jsonCriteria.startIndex;
            }
        }

        // filters
        if (jsonCriteria.filters && jsonCriteria.filters.length > 0) {
            const outputFilters = [];

            let i = 0;
            const len = jsonCriteria.filters.length;
            for (; i < len; i++) {
                const filter = jsonCriteria.filters[i];
                /* excludes the filters that have predicates; these are local filters only */
                if (filter.predicate == null) {
                    outputFilters[outputFilters.length] = {
                        filterBy: filter.filterBy,
                        filterValue: formatValue(filter.filterValue),
                        filterOp: filter.filterOp
                    };
                }
            }

            if (outputFilters.length > 0) {
                outputCriteria.filter = outputFilters;
            }
        }

        // sorters
        const sorters = jsonCriteria.sorters || [];
        if (sorters && sorters.length > 0) {
            /* find the first sorter that hasn't a comparator function; */
            const firstSorter = sorters.find((sorter) => sorter.comparator == null);

            if (firstSorter) {
                outputCriteria.sortField = firstSorter.sortBy;
                outputCriteria.sortOrder = firstSorter.direction;
            }
        }

        // limit
        const limit = jsonCriteria.limit;
        if (limit && Object.keys(limit).length > 0) {
            outputCriteria.limit = jsonCriteria.limit;
        }

        // boost
        if (jsonCriteria.boost && jsonCriteria.boost.length > 0) {
            outputCriteria.boost = jsonCriteria.boost.map((boost) => {
                const result = {};

                for (let property in boost) {
                    if (boost.hasOwnProperty(property)) {
                        result[property] = boost[property];
                    }
                }

                return result;
            }, this);
        }

        // fetchSize
        if (jsonCriteria.fetchSize) {
            outputCriteria.count = jsonCriteria.fetchSize == MAX_SAFE_INTEGER ? '@all' : jsonCriteria.fetchSize;
        }

        // selectedFields
        if (jsonCriteria.selectedFields) {
            outputCriteria.fields = jsonCriteria.selectedFields;
        }

        // searchValue
        if (jsonCriteria.searchValue) {
            outputCriteria.search = jsonCriteria.searchValue;
        }

        // isQuickSearch
        if (jsonCriteria.isQuickSearch) {
            outputCriteria.quick = jsonCriteria.isQuickSearch;
        }

        return outputCriteria;
    }

    /**
     * @param {!object=} opt_config
     * @protected
     */
    init(opt_config = {}) {
        opt_config.filters = opt_config.filters || [];
        // opt_config['sorters'] = opt_config['sorters'] || [];
        opt_config.limit = opt_config.limit || {};
        opt_config.boost = opt_config.boost || [];
        opt_config.fetchSize = opt_config.fetchSize || FetchCriteria.DEFAULT_FETCH_SIZE;
        opt_config.fetchDirection = opt_config.fetchDirection || FetchDirection.FORWARD;
        opt_config.nextChunkPointer = opt_config.nextChunkPointer || FetchNextChunkPointer.START_INDEX;
        opt_config.startIndex = opt_config.startIndex || 0;
        opt_config.useStartItemAsStartIndex = opt_config.useStartItemAsStartIndex || false;
        // opt_config['selectedFields'] = opt_config['selectedFields'] || [];

        // init the filters
        this.filters_ = opt_config.filters.map((filter) => {
            if (!(filter instanceof FilterDescriptor)) {
                filter = new FilterDescriptor(filter);
            }

            return filter;
        });

        // init the sorters
        if (opt_config.sorters) {
            this.sorters_ = opt_config.sorters.map((sorter) => {
                if (!(sorter instanceof SortDescriptor)) {
                    sorter = new SortDescriptor(sorter);
                }
                return sorter;
            });
        }

        this.limit_ = opt_config.limit;

        this.boost_ = opt_config.boost;

        // init the fetchSize
        this.setFetchSize(opt_config.fetchSize);

        // init the fetchDirection
        this.setFetchDirection(opt_config.fetchDirection);

        // init the nextChunkPointer
        this.setNextChunkPointer(opt_config.nextChunkPointer);

        // init the startIndex
        this.setStartIndex(opt_config.startIndex);

        // init the startItem
        this.setStartItem(opt_config.startItem);

        // init the startItemProperty
        if ((this.nextChunkPointer_ != FetchNextChunkPointer.START_INDEX) && opt_config.startItemProperty == null) {
            throw new Error('The \'startItemProperty\' must be provided');
        }
        this.setStartItemProperty(opt_config.startItemProperty);

        // init useStartItemAsStartIndex
        this.useStartItemAsStartIndex_ = opt_config.useStartItemAsStartIndex;

        // init the nextChunk
        this.setNextChunk(opt_config.nextChunk);

        // init the prevChunk
        this.setPrevChunk(opt_config.prevChunk);

        // init selected fields
        this.selectedFields_ = opt_config.selectedFields;

        // init searchValue
        this.searchValue_ = opt_config.searchValue;

        // init isQuickSearch
        this.isQuickSearch_ = opt_config.isQuickSearch;
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        this.filters_ = null;
        this.sorters_ = null;
        this.limit_ = null;
        this.boost_ = null;
        this.startItem_ = null;
        this.selectedFields_ = null;
    }
}
// implements interfaces:
ICriteria.addImplementation(FetchCriteria);

/**
 *
 * @type {number}
 * @static
 */
FetchCriteria.DEFAULT_FETCH_SIZE = 10;
