import { Disposable } from '../disposable/Disposable.js';
import { BaseUtils } from '../base.js';
import { ValidationRuleSeverity } from './RuleSeverity.js';
import { StringUtils } from '../string/string.js';

/**
 * Represents the context under which the validation of a rule is being performed.
 *
 * @typedef {{
        target: *,
        targetProperties: Array.<string>,
        args: Object.<string, *>
   }}
 */
export let ValidationContext;

/**
 * A validation rule contains a set of data that defines how an input data is validated.
 *
 * @example
    var exampleObj = new hf.validation.Rule({
        'targetProperties'         : ['firstName'],
        'validationHandler'        : myapp.validation.handlers.maxLength,
        'validationArgs'                 : { 'maxLength': 99},
        'stopOnFailure'            : true, // default false
        'severity'                 : ValidationRuleSeverity.WARNING, // default ValidationRuleSeverity.ERROR
        'priority'                 : 1, // default 0
        'failMessage'              : 'The description must contain maximum %maxLength% characters'
    });
 *
 * @augments {Disposable}
 *
 */
export class Rule extends Disposable {
    /**
     * @param {!object} opt_config The optional configuration object.
     *   @param {Array.<string>=} opt_config.targetProperties
     *   @param {function(ValidationContext): boolean} opt_config.validationHandler A function handling the rule validation.
     *   @param {!object=} opt_config.validationArgs A key/value dictionary that is associated with the validation context
     *   @param {boolean=} opt_config.stopOnFailure True if rules checking process should stop if this rule checking returns failure, false otherwise.
     *   @param {!ValidationRuleSeverity=} opt_config.severity The severity of the rule.
     *   @param {number=} opt_config.priority The priority of the rule. It must be greater than 0.
     *   @param {string} opt_config.failMessage
     *
     */
    constructor(opt_config = {}) {
        super();

        this.init(opt_config);

        /**
         * The rule unique identifier.
         *
         * @type {string}
         * @private
         */
        this.uid_;

        /**
         * The name of the validation rule
         *
         * @type {string}
         * @private
         */
        this.name_;

        /**
         * The current validation target.
         *
         * @type {*}
         * @private
         */
        this.currentTarget_;

        /**
         * The names of the properties name (belonging to the validation target) this rule is attached to. (e.g. starDate, endDate).
         *
         * @type {Array.<string>}
         * @private
         */
        this.targetProperties_;

        /**
         * Function that represents the validation criteria.
         *
         * @type {?function(ValidationContext): boolean}
         * @private
         */
        this.validationHandler_;

        /**
         * A key-value object representing extra information that is used when the rule is executed.
         *
         * @type {object}
         * @default {}
         * @private
         */
        this.validationArgs_;

        /**
         * The severity of the rule.
         *
         * @type {!ValidationRuleSeverity}
         * @default ValidationRuleSeverity.ERROR
         * @private
         */
        this.severity_;

        /**
         * The fail message.
         *
         * @type {string}
         * @private
         */
        this.failMessage_;

        /**
         * If true then the validation process must stop if this rule fails.
         *
         * @type {!boolean}
         * @default false
         * @private
         */
        this.stopOnFailure_ = this.stopOnFailure_ === undefined ? false : this.stopOnFailure_;

        /**
         * The priority of the rule. The highest priority is 0.
         *
         * @type {!number}
         * @default 0
         * @private
         */
        this.priority_ = this.priority_ === undefined ? 0 : this.priority_;
    }

    /**
     * Returns the rule unique identifier.
     *
     * @returns {string} The rule unique identifier.
     *
     */
    getUId() {
        // generate the uid on demand
        if (this.uid_ == null) {
            this.uid_ = StringUtils.createUniqueString('validation_rule_');
        }

        return this.uid_;
    }

    /**
     * Returns the name of the validation rule.
     *
     * @returns {string}
     *
     */
    getName() {
        // generate the uid on demand
        if (this.name_ == null) {
            this.name_ = this.getUId();
        }

        return this.name_;
    }

    /**
     *
     * @param {string} propertyName
     * @returns {boolean}
     *
     */
    isAttachedToProperty(propertyName) {
        return this.targetProperties_.includes(propertyName);
    }

    /**
     * Gets the names of the properties this rule is attached to.
     *
     * @returns {!Array.<string>}
     *
     */
    getTargetProperties() {
        return /** @type {!Array.<string>} */(this.targetProperties_);
    }

    /**
     * Gets the severity field.
     *
     * @returns {ValidationRuleSeverity} The severity of the rule.
     *
     */
    getSeverity() {
        return this.severity_;
    }

    /**
     * Returns the priority of the rule.
     *
     * @returns {number}
     *
     */
    getPriority() {
        return this.priority_;
    }

    /**
     * Returns true if the rules checking should stop if this rule checking returns failure.
     *
     * @returns {boolean}
     *
     */
    stopOnFailure() {
        return this.stopOnFailure_;
    }

    /**
     * TODO
     *
     * @param {hf.validation.Rule} otherRule
     */
    equals(otherRule) {
        // return this.propertyName_ === otherRule.get
    }

    /**
     * Represents the validation rule implementation.
     *
     * @param {object} target The validation target.
     * @returns {boolean}
     *
     */
    validate(target) {
        this.currentTarget_ = target;

        return this.validationHandler_.call(this,
            { // validation context: the information provided to a rule when it is validated.
                target,
                targetProperties: this.targetProperties_,
                args: this.validationArgs_
            });
    }

    /**
     * Gets the fail message template.
     *
     * @returns {string} The fail message
     *
     */
    getFailMessage() {
        return this.failMessage_;
    }

    /**
     * Gets the fail message replacement arguments.
     *
     * @returns {!object}
     *
     */
    getFailMessageArgs() {
        const failMessageArgs = { ...this.validationArgs_ || {} };

        /* add the name of the target properties; e.g. targetProperties[0]: 'Email' */
        this.targetProperties_.forEach((targetProperty, index) => {
            const key = `targetProperties[${index}]`;

            failMessageArgs[key] = StringUtils.capitalize(targetProperty.split(/(?=[A-Z])/).join(' '));
        });

        /* add the values of target properties; e.g. 'email': 'test.user@tst.com' */
        const currentTarget = this.currentTarget_;
        if (currentTarget) {
            this.targetProperties_.forEach((targetProperty, index) => {
                failMessageArgs[targetProperty] = currentTarget[targetProperty];
            });
        }

        return failMessageArgs;
    }

    /**
     * Normalizer for the config options
     *
     * @param {object=} opt_config
     * @returns {!object}
     * @protected
     */
    normalizeConfigOptions(opt_config = {}) {
        let defaultValues = {
            targetProperties: [],
            validationArgs: {},
            stopOnFailure: false,
            priority: 0,
            severity: ValidationRuleSeverity.ERROR
        };

        for (let key in defaultValues) {
            opt_config[key] = opt_config[key] != null ? opt_config[key] : defaultValues[key];
        }

        return /** @type {!object} */(opt_config);
    }

    /**
     * Initializes the class variables with the configuration values provided in the constructor or with the default values.
     *
     * @param {!object=} opt_config The optional configuration object.
     * @protected
     */
    init(opt_config = {}) {
        opt_config = this.normalizeConfigOptions(opt_config);

        /* targetProperties */
        this.targetProperties_ = opt_config.targetProperties;

        /* validationHandler */
        this.setValidationHandler_(opt_config.validationHandler);

        /* validationArgs */
        this.setValidationArgs_(opt_config.validationArgs);

        /* stopOnFailure_ */
        this.stopOnFailure_ = opt_config.stopOnFailure;

        /* priority */
        this.setPriority_(opt_config.priority);

        /* severity */
        this.setSeverity_(opt_config.severity);

        /* fail message */
        this.setFailMessage_(opt_config.failMessage);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.targetProperties_ = null;
        this.validationHandler_ = null;
        this.validationArgs_ = null;
        this.currentTarget_ = null;
    }

    /**
     * Sets the validation criteria function.
     *
     * @param {!function(ValidationContext): boolean} validationHandler
     * @returns {void}
     * @private
     */
    setValidationHandler_(validationHandler) {
        if (!BaseUtils.isFunction(validationHandler)) {
            throw new TypeError('The "validationHandler" parameter is required and must be a function.');
        }
        this.validationHandler_ = validationHandler;
    }

    /**
     * Sets the rule args that represent extra information that is used when the rule is executed.
     *
     * @param {!object} validationArgs The rule args.
     * @returns {void}
     * @private
     */
    setValidationArgs_(validationArgs) {
        if (!BaseUtils.isObject(validationArgs)) {
            throw new TypeError('The validationArgs parameter should be an object.');
        }
        this.validationArgs_ = validationArgs;
    }

    /**
     * Returns the rule args.
     *
     * @returns {object} The rule args.
     * @protected
     */
    getValidationArgs() {
        return this.validationArgs_;
    }

    /**
     * Sets the priority of the rule.
     *
     * @param {!number} priority it must be greater than 0.
     * @returns {void}
     * @private
     */
    setPriority_(priority) {
        if (!BaseUtils.isNumber(priority)) {
            throw new TypeError('The priority parameter should be a number.');
        }
        if (priority < 0) {
            throw new RangeError('The priority parameter should be a non-negative number.');
        }
        this.priority_ = priority;
    }

    /**
     * Sets the severity field.
     *
     * @param {!ValidationRuleSeverity} severity The severity of the rule.
     * @returns {void}
     * @private
     */
    setSeverity_(severity) {
        if (!(Object.values(ValidationRuleSeverity).includes(severity))) {
            throw new TypeError('The \'severity\' parameter must be a valid ValidationRuleSeverity value.');
        }
        this.severity_ = severity;
    }

    /**
     * Sets the failMessage field.
     *
     * @param {string} failMessage
     * @returns {void}
     * @private
     */
    setFailMessage_(failMessage) {
        if (StringUtils.isEmptyOrWhitespace('failMessage')) {
            throw new Error('The \'failMessage\' parameter must be provided');
        }

        this.failMessage_ = failMessage;
    }
}
