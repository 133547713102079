import {DataModel} from "./../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";

/**
 * Create new AuthSessionSession model
 * Contains info about the session
 * @extends {DataModel}
 * @unrestricted
 */
export class AuthSessionSession extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        /* type of account as determined by the technology (optional): AuthAccountType */
        this.addField({'name': 'personId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The role of the user logged in - @type {UserRoles} */
        this.addField({'name': 'role', 'type': DataModelField.PredefinedTypes.STRING});

        /* see HgStates */
        this.addField({'name': 'hgState', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* Organization identifier */
        this.addField({'name': 'orgIdentifier', 'type': DataModelField.PredefinedTypes.STRING});

        /* the user language code */
        this.addField({'name': 'language', 'type': DataModelField.PredefinedTypes.STRING});

        /* maximum file size according to the plan on the organization, in MB */
        this.addField({'name': 'maxFileSize', 'type': DataModelField.PredefinedTypes.NUMBER});

        /* true if the user can invite other users, false otherwise. */
        this.addField({'name': 'canInvite', 'type': DataModelField.PredefinedTypes.BOOL, 'value': true});

    }
};