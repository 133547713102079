import {ResizeHeight} from "./../../../../../../../hubfront/phpnoenc/js/fx/Dom.js";
import {BrowserEventType} from "./../../../../../../../hubfront/phpnoenc/js/events/EventType.js";
import {EventsUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Events.js";
import {ArrayUtils} from "./../../../../../../../hubfront/phpnoenc/js/array/Array.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {Size} from "./../../../../../../../hubfront/phpnoenc/js/math/Size.js";
import {Coordinate} from "./../../../../../../../hubfront/phpnoenc/js/math/Coordinate.js";
import {FunctionsUtils} from "./../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Selector} from "./../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {FxTransitionEventTypes} from "./../../../../../../../hubfront/phpnoenc/js/fx/Transition.js";
import {RosterItem} from "./RosterItem.js";
import {ChatEventType} from "./../../EventType.js";
import {RosterViewmodel} from "./../../viewmodel/Roster.js";
import {ListItemsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {ResizeDirection} from "./../../../../../../../hubfront/phpnoenc/js/fx/Resizer/Common.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * @extends {Selector}
 * @unrestricted 
*/
export class RosterSelector extends Selector {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *   @param {string=} opt_config.primaryRowHeight The desired height of primary row if mode==BRIEF
     *   @param {function(): number} opt_config.maxWidthGetter When viewport cannot be computed, the maxWidthGetter is called
     *   @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense itd
     *   @param {number=} opt_config.resizeTolerance
     *   @param {boolean=} opt_config.fullScreenViewport
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Data items that have been rendered, stored in order to determine easily on resize what to remove and what to add
         * @type {Array}
         * @private
         */
        this.renderedDataItems_;

        /**
         * Cached current height at the beginning of roster resize
         * @type {number}
         * @private
         */
        this.currentHeight_;

        /**
         * Reference to custom resize handle
         * @type {Element}
         * @private
         */
        this.resizeHandle_;

        /**
         * Roster item size: only content size (considered box-sizing if 'border-box' which includes paddings and borders)
         * Necessary on item margin adjusting, height adjusting and resize limits adjusting
         * AvatarSizes.MEDIUM
         * Note: carefull, it includes padding!!!!
         * @type {?hf.math.Size}
         * @private
         */
        this.itemSize_ = this.itemSize_ === undefined ? new Size(36, 36) : this.itemSize_;

        /**
         * @type {number}
         * @private
         */
        this.margin_ = this.margin_ === undefined ? 0 : this.margin_;

        /**
         * Last processed roster width on a hresize event
         * @type {?string}
         * @default null
         * @private
         */
        this.lastProcessedWidth_ = this.lastProcessedWidth_ === undefined ? null : this.lastProcessedWidth_;

        /**
         * @type {?Function}
         * @default null
         * @private
         */
        this.handleHResizeDebouncedFn_ = this.handleHResizeDebouncedFn_ === undefined ? null : this.handleHResizeDebouncedFn_;

        /**
         * Mark if items are in an adjustment transition or not in order to be able to determine if a
         * removeUIItem call should adjust layout or not
         * @type {boolean}
         * @private
         */
        this.isInAdjustTransition_ = this.isInAdjustTransition_ === undefined ? false : this.isInAdjustTransition_;

        /**
         * Mark vertical resize transition
         * @type {boolean}
         * @private
         */
        this.inVResizeTransition_ = this.inVResizeTransition_ === undefined ? false : this.inVResizeTransition_;

        /**
         * Marker to determine if roster size was restored
         * Further adjustments will not relate to user behavior
         * @type {boolean}
         * @private
         */
        this.isRestored_ = this.isRestored_ === undefined ? false : this.isRestored_;
    }

    /** @inheritDoc */
    setVisible(visible, opt_force) {
        const retVal = super.setVisible(visible, opt_force);

        return retVal;
    }

    /** @inheritDoc */
    onResize() {
        this.onHorizontalResize_();
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-chat-roster-selector';
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        /* set maximum number of rows allowed to be displayed if not defined */
        opt_config['maxRows'] = opt_config['maxRows'] || 8;

        /* set viewport min-height, to be adjusted once the initial set of data is loaded */
        opt_config['height'] = opt_config['height'] || 0;

        // resizer should be displayed only when necessary
        opt_config['resizeOptions'] = opt_config['resizeOptions'] || {'directions': [ResizeDirection.BOTTOM]};

        opt_config['itemsLayout'] =  ListItemsLayout.HWRAP;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.renderedDataItems_ = [];
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.itemSize_);
        this.itemSize_= null;

        this.lastProcessedWidth_= null;
        this.handleHResizeDebouncedFn_ = null;
        this.resizeHandle_= null;

        this.renderedDataItems_ = [];
        delete this.currentHeight_;
    }

    /** @inheritDoc */
    getListItemType() {
        return RosterItem;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.resizeHandle_ = DomUtils.createDom('div', {
                'class': 'hf-fx-resizer hf-fx-resizer-wrapper-bottom disabled',
                'style': 'left: 0px; right: 0px;'
            },
            DomUtils.createDom('div', 'hf-fx-resizer-bottom'));

        this.getElement().appendChild(this.resizeHandle_);
    }

    /** @inheritDoc */
    removeUIItem(uiItemToRemove) {
        const ret = super.removeUIItem(uiItemToRemove);

        /* adjust layout */
        if (!this.isInAdjustTransition_) {
            this.adjustItems();
        }

        return ret;
    }

    /** @inheritDoc */
    moveUIItem(oldIndex, newIndex) {
        const cv = /** @type {hf.structs.CollectionView} */(this.getDataItems());

        const dataItem = /** @type {hg.data.model.party.RecipientBase} */(cv.getAt(newIndex));
        if (!dataItem['isInVisibleRoster']) {
            /* custom logic for moving... add to newIndex and extract one from the end of the visibleRoster */
            this.addItemInternal_(dataItem, newIndex);

            const lastIndex = this.renderedDataItems_.length - 1;
            this.removeItemInternal_(lastIndex);
        } else {
            super.moveUIItem(oldIndex, newIndex);
        }
    }

    /** @inheritDoc */
    onDataItemReplaced(e) {
        const newItems = e['payload']['newItems'],
            oldItems = e['payload']['oldItems'];

        let i = 0;
        const len = newItems.length;
        for (; i < len; i++) {
            const newIndex = newItems[i]['index'],
                oldIndex = oldItems[i]['index'];

            // ...and then move it to the new index.
            const uiItem = /** @type {ListUIItem} */ (this.getItemsContainer().getChildAt(oldIndex));
            if (uiItem == null) {
                if (oldIndex != newIndex) {
                    this.moveUIItem(oldIndex, newIndex);
                }
            }
        }
    }

    /**
     * @param {hg.data.model.party.RecipientBase} dataItem
     * @param {number=} opt_index
     * @private
     */
    addItemInternal_(dataItem, opt_index) {
        const uiItemsContainer = this.getItemsContainer();
        if (uiItemsContainer) {
            /** @type {hg.data.model.party.RecipientBase} */(dataItem).set('isInVisibleRoster', true, true);

            let uiItem;
            if (BaseUtils.isNumber(opt_index)) {
                Logger.get('hg.module.chat.RosterSelector').log('Add UI item at index <' + opt_index + '>');
                this.renderedDataItems_.splice(opt_index, 0, dataItem);

                uiItem = this.addUIItem(dataItem);
                uiItemsContainer.addChildAt(uiItem, /** @type {number} */(opt_index), true);
            } else {
                Logger.get('hg.module.chat.RosterSelector').log('Add UI item at the end');
                this.renderedDataItems_.push(dataItem);

                uiItem = this.addUIItem(dataItem);
                uiItemsContainer.addChild(uiItem, true);
            }

            /* check selection */
            uiItem.setSelected(this.getSelectedItem() === dataItem);
        }
    }

    /**
     * @param {number} index
     * @private
     */
    removeItemInternal_(index) {
        const uiItemsContainer = this.getItemsContainer();
        if (uiItemsContainer) {
            const dataItem = /** @type {hg.data.model.party.RecipientBase} */(this.renderedDataItems_[index]);

            dataItem.set('isInVisibleRoster', false, true);

            Logger.get('hg.module.chat.RosterSelector').log('Remove UI item at index <' + index + '>');
            this.renderedDataItems_.splice(index, 1);

            const removedUIItem = uiItemsContainer.removeChildAt(index, true);
            this.removeUIItem(/**@type {ListUIItem}*/ (removedUIItem));

            if (this.getSelectedItem() === dataItem) {
                /* selected data item becomes invisible, we should schedule a move randomly in the first 5 positions */
                setTimeout(() => this.rearrangeRosterItem_(dataItem));
            }
        }
    }

    /**
     * @param {hg.data.model.party.RecipientBase} dataItem
     * @private
     */
    rearrangeRosterItem_(dataItem) {
        if (!dataItem['isInVisibleRoster'] && !this.isItemsSourceEmpty()) {
            /* try to insert it randomly in one of the first 5 positions, than shift the list to the right */
            const availablePositions = [0, 1, 2, 3, 4],
                dataItems = this.getDataItems().getItems();

            const newIndex = availablePositions[Math.floor(Math.random() * availablePositions.length)],
                itemAtNewIndex = dataItems[newIndex];

            let oldAdjustTransition = this.isInAdjustTransition_;
            this.isInAdjustTransition_ = true;

            dataItem['computedPositionInRoster'] = itemAtNewIndex['computedPositionInRoster']-1;

            if (!oldAdjustTransition) {
                this.isInAdjustTransition_ = false;
            }
        }
    }

    /**
     * Default order for roster  selector items on reset!:
     * - first 5 positions have a preset computedPositionInRoster in order to allow sorting after them and
     * @private
     */
    defaultArrangeItems_() {
        const dataItems = this.getDataItems().getItems();

        if (dataItems.length > 0) {
            for (let i = 0; i < Math.min(dataItems.length, 5); i++) {
                dataItems[i]['computedPositionInRoster'] = RosterSelector.SORT_GAP * (i+1);
            }
        }
    }

    /** @inheritDoc */
    selectItem(dataItem, opt_select) {
        const ret = super.selectItem(dataItem, opt_select);

        if (opt_select && dataItem) {
            this.rearrangeRosterItem_(/** @type {hg.data.model.party.RecipientBase} */(dataItem));
        }

        return ret;
    }

    /** @inheritDoc */
    unloadUIItemsFromViewport(reset, opt_removedDataItems) {
        this.isInAdjustTransition_ = true;

        if (!reset) {
            const uiItemsContainer = this.getItemsContainer(),
                itemsToRemove = opt_removedDataItems || [];

            if(itemsToRemove.length > 0) {
                const renderedDataItemsCount = this.renderedDataItems_.length,
                    dataItems = this.getDataItems().getItems(),
                    hasOverflow = (dataItems.length - renderedDataItemsCount - itemsToRemove.length) > 0;

                ArrayUtils.forEachRight(itemsToRemove, function (itemToRemove) {
                    const index = itemToRemove['index'];

                    if (this.renderedDataItems_[index]) {
                        this.removeItemInternal_(index);

                        if (hasOverflow) {
                            /* add a new item at the end */
                            const dataItem = dataItems[this.renderedDataItems_.length];
                            this.addItemInternal_(dataItem);
                        }
                    }
                }, this);
            }
        } else {
            super.unloadUIItemsFromViewport(reset, opt_removedDataItems);
        }

        this.isInAdjustTransition_ = false;
    }

    /** @inheritDoc */
    loadUIItemsIntoViewport(reset, opt_addedDataItems) {
        if (!reset && !BaseUtils.isArray(opt_addedDataItems)) {
            throw new Error('The data items that were added must be provided.');
        }

        opt_addedDataItems = opt_addedDataItems || [];

        if (reset) {
            /* set first 5 positions with static indexes and gaps between them in order to support proper arrangement mechanism */
            this.defaultArrangeItems_();
        }

        const dataItems = this.getDataItems().getItems(),
            newDataItems = reset ? dataItems : opt_addedDataItems;

        if (newDataItems.length > 0) {
            if (reset) {
                Logger.get('hg.module.chat.RosterSelector').log('Reset all ui items.');

                this.renderedDataItems_ = [];

                /* consider standard size (32, 32), do not try to compute it in any way, performance loss */
                return this.adjustLayout(newDataItems, true)
                    .then((finalDataItems) => {
                        // we need to set proper height
                        const viewportWidth = this.getViewportWidth(),
                            cfg = this.getConfigOptions();

                        const rows = Math.ceil(finalDataItems.length / Math.floor(viewportWidth / this.itemSize_.width));

                        this.resizeHandle_.style.display = 'none';
                        this.adjustHeightForRows_(rows > 1 ? Math.min(rows, cfg['maxRows']) : 1, true)
                            .then(() => {
                                this.resizeHandle_.style.display = 'block';
                            });

                        this.onEndProcessing_(finalDataItems);
                    });
            }
            else {
                const maxItems = Math.floor(this.getViewportWidth()) / (this.itemSize_.width + 2 * this.margin_);

                if (newDataItems.length > 0) {
                    this.isInAdjustTransition_ = true;

                    newDataItems.forEach(function (newDataItem) {
                        if (newDataItem['index'] < this.renderedDataItems_.length) {
                            /* add new item */
                            this.addItemInternal_(newDataItem['item'], newDataItem['index']);

                            /* remove the last one IF needs adjustment ONLY ! */
                            if (this.renderedDataItems_.length > maxItems) {
                                const lastIndex = this.renderedDataItems_.length - 1;
                                this.removeItemInternal_(lastIndex);
                            }
                        } else if (this.renderedDataItems_.length < maxItems) {
                            /* index of new item exceeds rendered items, ignore it IF list has already overflow */
                            this.addItemInternal_(newDataItem['item'], newDataItem['index']);
                        }
                    }, this);

                    this.isInAdjustTransition_ = false;
                }
            }
        }
        else {
            /* we need to adjust height to min 1 to display empty list marker */
            if (this.getDataItems().getCount() == 0) {
                this.adjustHeightForRows_(1, true);
            } else {
                /* on item removal we need to make sure last row does not remain empty */
                const maxRows = this.getMaxRows(),
                    maxHeight = this.computeHeightForRows(maxRows);
                if (this.currentHeight_ && maxHeight < this.currentHeight_) {
                    this.adjustHeightForRows_(maxRows, true);
                }
            }
        }

        return Promise.resolve()
    }

    /**
     * @param {Array} dataItems
     * @param {boolean=} opt_isReset
     * @return {Promise}
     * @protected
     */
    adjustLayout(dataItems, opt_isReset) {
        const uiItemsContainer = this.getItemsContainer(),
            isItemsViewportInDocument = this.indexOfChild(uiItemsContainer) > -1 && uiItemsContainer.isInDocument();

        if (isItemsViewportInDocument && opt_isReset) {
            this.removeChild(uiItemsContainer, true);
        }

        let restoreNumRows = 1;
        if (!this.isRestored_) {
            restoreNumRows = RosterViewmodel.NUM_ROWS;
            this.isRestored_ = true;
        }
        else {
            restoreNumRows = this.getCurrentRows();
        }

        return new Promise((resolve, reject) => {
            const finalDataItems = [],
                rowInfo = {'rows': 0, 'max': restoreNumRows};

            this.margin_ = 0;
            this.processRow_(dataItems, this.getViewportWidth(), finalDataItems, rowInfo, resolve);

            /* adjust height, enable resizing if we have unrendered items */
            this.enableResizing(dataItems.length > 0);
        });
    }

    /**
     * @param {Array} dataItems
     * @param {number} viewportWidth
     * @param {Array} finalDataItems
     * @param {Object} rowInfo
     * @param {!Function} resolveFn
     * @private
     */
    processRow_(dataItems, viewportWidth, finalDataItems, rowInfo, resolveFn) {
        const secondRowItems = this.computeItemsFittingRow_(dataItems, viewportWidth);

        finalDataItems.push(...secondRowItems);

        rowInfo['rows']++;

        if (dataItems.length > 0 && rowInfo['rows'] < rowInfo['max']) {
            setTimeout(() => this.processRow_(dataItems, viewportWidth, finalDataItems, rowInfo, resolveFn));
        }
        else {
            resolveFn(finalDataItems);
        }
    }

    /**
     * @param {Array} dataItems
     * @param {number} viewportWidth
     * @private
     */
    computeItemsFittingRow_(dataItems, viewportWidth) {
        const count = dataItems.length,
            items = [];
        let width = 0,
            massWidth = 0,
            dataItem;

        for (let i = 0; i < count; i++) {
            if (i in dataItems) {
                dataItem = /** @type {hg.data.model.party.RecipientBase} */(dataItems[i]);

                if (width + this.itemSize_.width > viewportWidth) {
                    /* process overflow and break - do not include last item, enlarge margins */
                    massWidth = viewportWidth - width;
                    break;
                } else {
                    items.push(dataItem);
                    width = width + this.itemSize_.width;
                }
            }
        }

        /* compute global margins! */
        if (massWidth > 0) {
            this.margin_ = (massWidth / items.length) / 2;
            this.margin_ = parseFloat(this.margin_.toFixed(2));
            this.margin_ = this.margin_ - 0.05;
        }

        items.forEach(function (item) {
            ArrayUtils.remove(dataItems, item);
        });

        return items;
    }

    /**
     * @param {Array} finalDataItems
     * @private
     */
    onEndProcessing_(finalDataItems) {
        const uiItemsContainer = this.getItemsContainer();
        let isItemsViewportInDocument = this.indexOfChild(uiItemsContainer) > -1;

        if (finalDataItems.length > 0) {
            const addedUIItems = finalDataItems.map(function (dataItem) {
                /** @type {hg.data.model.party.RecipientBase} */(dataItem).set('isInVisibleRoster', true, true);

                this.renderedDataItems_.push(dataItem);

                return this.addUIItem(dataItem);
            }, this);

            uiItemsContainer.addChildren(addedUIItems);
        }

        /* the add process finished: bring the items viewport into the document */
        if (!isItemsViewportInDocument) {
            this.addChild(uiItemsContainer, true);

            uiItemsContainer.setStyle(({
                'paddingLeft'   : RosterSelector.SELECTOR_PADDING.x + 'px',
                'paddingRight'  : RosterSelector.SELECTOR_PADDING.x + 'px',
                'paddingTop'    : RosterSelector.SELECTOR_PADDING.y + 'px',
                'paddingBottom' : RosterSelector.SELECTOR_PADDING.y + 'px'
            }));
        }
    }

    /** @inheritDoc */
    addUIItem(dataItem) {
        const uiItem = super.addUIItem(dataItem);

        this.adjustUIItem(uiItem);

        return uiItem;
    }

    /**
     * @param {ListUIItem} uiItem
     * @protected
     */
    adjustUIItem(uiItem) {
        if (this.margin_ >= 0) {
            uiItem.setStyle({
                'marginLeft': this.margin_ + 'px',
                'marginRight': this.margin_ + 'px'
            });
        }
    }

    /**
     * @param {number} rowsCount
     * @private
     */
    computeHeightForRows(rowsCount) {
        return Math.max(rowsCount, 1) * this.itemSize_.height
            + 2*RosterSelector.SELECTOR_PADDING.y;
    }

    /**
     * Adjusts roster height
     * @param {number} rows The number of rows to adjust height to
     * @param {boolean=} opt_animation True to play an animation while setting the height, false not to play it. if this is not provided, true is considered by default.
     * @return {Promise}
     * @private
     */
    adjustHeightForRows_(rows, opt_animation) {
        if (!this.isInDocument() || this.getModel() === null) {
            return Promise.resolve();
        }

        opt_animation = opt_animation || false;

        /* check if opt_row is lower than the total number of rows */
        rows = Math.min(rows, this.getMaxRows());

        /* if new opt_rows differ from appDataParam we should update the record */
        if (RosterViewmodel.NUM_ROWS !== rows) {
            RosterViewmodel.NUM_ROWS = rows;

            setTimeout(() => this.dispatchEvent(ChatEventType.ROSTER_SYNC_NUM_ROWS), 100);
        }

        /* compute height according to row */
        const height = this.computeHeightForRows(rows);
        if (!this.currentHeight_ || this.currentHeight_ != height) {
            return new Promise((resolve, reject) => {
                if (opt_animation) {
                    /* Run the animation */
                    const size = this.getSize(true),
                        animation = new ResizeHeight(this.getElement(), size.height, height, 500);

                    /* Make sure the correct position is set after the animation */
                    EventsUtils.listenOnce(animation, FxTransitionEventTypes.END, (e) => {
                        this.setHeight(height);

                        resolve();
                    });

                    animation.play();
                }
                else {
                    this.setHeight(height, false);

                    resolve();
                }

                this.currentHeight_ = height;
            });
        }

        return Promise.resolve();
    }

    /**
     * @protected
     */
    adjustItems() {
        this.isInAdjustTransition_ = true;

        const dataItemsCollection = this.getDataItems(),
            dataItems = dataItemsCollection ? dataItemsCollection.getItems() : [];

        Logger.get('hg.module.chat.RosterSelector').log('Adjusting items...' + this.renderedDataItems_.length + ' rendered items.');

        /* consider standard size (32, 32), do nto try to compute it in any way, performance loss */
        this.adjustLayout(dataItems, false)
            .then((finalDataItems) => {
                /* remove items that do not fit any more if any */
                const uiItemsContainer = this.getItemsContainer(),
                    renderedDataItems = this.renderedDataItems_.slice(0);

                ArrayUtils.forEachRight(renderedDataItems, function (renderedDataItem, i) {
                    if (!finalDataItems.includes(renderedDataItem)) {
                        /* remove item */
                        this.removeItemInternal_(i);
                    }
                }, this);

                /* insert or adjust existing items */
                finalDataItems.forEach(function (dataItem, finalIndex) {
                    const idx = this.renderedDataItems_.indexOf(dataItem);

                    if (idx == -1) {
                        /* add new item, must determine on which index */
                        const newIndex = Math.min(finalIndex, uiItemsContainer.getChildCount());

                        /** @type {hg.data.model.party.RecipientBase} */(dataItem).set('isInVisibleRoster', true, true);

                        this.addItemInternal_(dataItem, newIndex);
                    } else if (!this.inVResizeTransition_) {
                        /* adjust already present item */
                        const uiItem = uiItemsContainer.getChildAt(idx);

                        Logger.get('hg.module.chat.RosterSelector').log('Adjust item at index <' + idx + '>...');

                        this.adjustUIItem(/** @type {ListUIItem} */(uiItem));
                    }
                }, this);

                this.isInAdjustTransition_ = false;
            })
            .catch((err) => {
                this.isInAdjustTransition_ = false;

                throw err;
            });
    }

    /** @inheritDoc */
    applyResizing() {
        if (this.resizeHandle_ != null) {
            const isDesktop = userAgent.device.isDesktop();

            if (this.isResizable()) {
                this.resizeHandle_.classList.remove('disabled');

                this.getHandler()
                    .listen(this.resizeHandle_, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold_);
            } else {
                this.resizeHandle_.classList.add('disabled');

                this.getHandler()
                    .unlisten(this.resizeHandle_, isDesktop ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, this.handleHold_);
            }
        }
    }

    /**
     * Compute maximum number of rows that can be displayed taken into consideration viewport width and items count
     * @return {number}
     */
    getMaxRows() {
        const dataItemsCollection = this.getDataItems(),
            itemsCount = dataItemsCollection ? dataItemsCollection.getCount() : 0;

        if (itemsCount > 0) {
            const viewportWidth = this.getViewportWidth(),
                cfg = this.getConfigOptions();

            const rows = Math.ceil(itemsCount / Math.floor(viewportWidth / this.itemSize_.width));

            return rows > 1 ? Math.min(rows, cfg['maxRows']) : 1;
        }

        return 1;
    }

    /**
     * Compute maximum number of rows that can be displayed taken into consideration viewport width and items count
     * @return {number}
     */
    getCurrentRows() {
        /* compute closest number of rows to resize to */
        const currentHeight = this.getHeight(true) - 2 * RosterSelector.SELECTOR_PADDING.y;
        return Math.round(currentHeight / this.itemSize_.height);
    }

    /**
     * Compute the viewport width
     * @param {boolean=} opt_force Force recompute of viewport width
     * @return {number}
     * @protected
     */
    getViewportWidth(opt_force) {
        if (!this.lastProcessedWidth_ || opt_force) {
            this.lastProcessedWidth_ = this.getWidth(true);
        }

        return this.lastProcessedWidth_ - 2*RosterSelector.SELECTOR_PADDING.x;
    }

    /**
     * Handles horizontal resize processing: window is resized, wrapper social container is resized
     * On horizontal resize the margins must be adjusted, the roster height and resizer must be updated
     * @private
     */
    onHorizontalResize_() {
        if(!this.isInAdjustTransition_ && !this.handleHResizeDebouncedFn_) {
            this.handleHResizeDebouncedFn_ = FunctionsUtils.debounce(function () {
                Logger.get('hg.module.chat.RosterSelector').log('Start to adjust items.');
                const width = this.getWidth(true);

                if (width > 0 && width != this.lastProcessedWidth_) {
                    /* store last processed width */
                    this.lastProcessedWidth_ = width;

                    this.adjustItems();

                    /* adjust height, make sure we do not have empty rows... */
                    /* compute nearest optimal height so that an entire number of rows is displayed */
                    const row = this.getMaxRows();

                    if (this.currentHeight_ == null) {
                        let size = this.getSize();
                        /* force size fetch if could not be determined */
                        if (isNaN(size.height)) {
                            size = this.getSize(true);
                        }
                        this.currentHeight_ = size.height;
                    }

                    /* changed number of displayed rows */
                    const optimalHeight = this.computeHeightForRows(row);
                    if (optimalHeight < this.currentHeight_) {
                        Logger.get('hg.module.chat.RosterSelector').log('Set new height on hresize.');
                        this.setHeight(optimalHeight, false);
                        this.currentHeight_ = optimalHeight;
                    }
                }

            }, 80, this);
        }

        Logger.get('hg.module.chat.RosterSelector').log('Debounce horizontal resize.');
        this.handleHResizeDebouncedFn_();
    }

    /**
     * Resize selector
     * @param {hf.events.Event} e
     * @private
     */
    onVerticalResize_(e) {
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent()),
            mousePosition = new Coordinate(touchData.clientX, touchData.clientY),
            offsetY = mousePosition.y - this.currentMousePosition_.y;

        /* compute new absolute height */
        const newHeight = this.currentHeight_ + offsetY;

        /* compute nearest optimal height so that an entire number of rows is displayed */
        const row = Math.min(Math.round(newHeight / this.itemSize_.height), this.getMaxRows());

        /* changed number of displayed rows */
        const optimalHeight = this.computeHeightForRows(row);
        if (optimalHeight !== this.currentHeight_) {
            Logger.get('hg.module.chat.RosterSelector').log('Set new height on vresize.');
            this.setHeight(optimalHeight, false);

            Logger.get('hg.module.chat.RosterSelector').log('Adjust items because of vresize.');
            this.adjustItems();

            /* update current mouse position and size */
            this.currentMousePosition_ = mousePosition;
            this.currentHeight_ =  optimalHeight;
        }
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleHold_(e) {
        this.resizeHandle_.classList.add('hold');

        this.inVResizeTransition_ = true;

        /* prevent text selection on resize */
        e.preventDefault();

        /* store initial mouse position on resize start */
        const touchData = EventsUtils.getTouchData(e.getBrowserEvent());
        this.currentMousePosition_ = new Coordinate(touchData.clientX, touchData.clientY);

        /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
        let size = this.getSize();
        /* force size fetch if could not be determined */
        if (isNaN(size.height)) {
            size = this.getSize(true);
        }
        this.currentHeight_ = size.height;

        /* listen to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove_, false, this);
        EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease_, false, this);
    }

    /**
     * Handles the mouse move events
     * @param {hf.events.Event} e
     * @private
     */
    handleMove_(e) {
        this.onVerticalResize_(e);
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @private
     */
    handleRelease_(e) {
        /* unlisten to mousemove and release events */
        const isDesktop = userAgent.device.isDesktop();

        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleMove_, false, this);
        EventsUtils.unlisten(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleRelease_, false, this);

        /* adjust roster height so it displays entire rows */
        this.onVerticalResize_(e);

        this.resizeHandle_.classList.remove('hold');
        this.inVResizeTransition_ = false;
    }
};
/**
 * @const
 * @type {Object}
 */
RosterSelector.SELECTOR_PADDING = {x: 10, y: 6};

/**
 * Gap between first 5 items in order to allow a proper algorithm
 * @const
 * @type {number}
 */
RosterSelector.SORT_GAP = 150;