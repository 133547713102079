import {AbstractService} from "./AbstractService.js";
import {UIComponentEventTypes, UIComponentStates} from "./../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {GiphyBubble, GiphyBubbleMode} from "./../../common/ui/GiphyBubble.js";
import {Giphy} from "./../model/giphy/Giphy.js";
import {GiphyBubbleViewmodel} from "./../../common/ui/viewmodel/GiphyBubble.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HgAppEvents} from "./../../app/Events.js";
import {HgAppConfig} from "./../../app/Config.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";

/**
 * Service provider for the remote Giphy service.
 * @extends {AbstractService}
 * @unrestricted 
*/
class GiphyService extends AbstractService {
    constructor(opt_config = {}) {
        opt_config['endpoint'] = opt_config['endpoint'] || HgAppConfig.GIPHY_REST_SERVICE_ENDPOINT;

        super(opt_config);

        /**
         * @private
         */
        this.giphyPopup_ = this.giphyPopup_ === undefined ? {
            /** @type {hg.common.ui.GiphyBubble} */  MINI_CHAT   : null,
            /** @type {hg.common.ui.GiphyBubble} */  DEFAULT     : null
        } : this.giphyPopup_;
    }

    /**
     *
     * @param {Object} payload
     * @returns {hg.common.ui.GiphyBubble}
     */
    openGifsBubble(payload) {
        return this.openGifsBubbleInternal_(payload);
    }

    /**
     * Loads the gifs
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria used for querying for the gifs.(f search criteria is empty it defaults to trending gifs)
     * @return {Promise}
     */
    getGifs(fetchCriteria) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'withCredentials': true
            }
        });

        const newCriteria = {
            'preview': [HgAppConfig.GIPHY_PREVIEW_IN_BUBBLE, HgAppConfig.GIPHY_PREVIEW_IN_MESSAGE],
            'search': fetchCriteria.getSearchValue(),
            'startIndex': fetchCriteria.getStartIndex(),
            'count': fetchCriteria.getFetchSize()
        };

        return this.handleErrors(dataPortal.load(Giphy, newCriteria), 'load_gifs_failure')
                    .then((result) => {
                        return new QueryDataResult({
                            'items'         : result.getItems(),
                            'totalCount'    : result.getCount() > 0 ? MAX_SAFE_INTEGER : 0,
                            'nextChunk'     : result['nextChunk'],
                            'prevChunk'     : result['prevChunk']
                        });
                    });

    }

    /** @inheritDoc */
    init(opt_config = {}) {


        opt_config['endpoint'] = opt_config['endpoint'] || HgAppConfig.GIPHY_REST_SERVICE_ENDPOINT;

        super.init(opt_config);
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.SHOW_GIFS, this.handleGiphyBubbleDisplay_)
            .listen(eventBus, HgAppEvents.EDITOR_RESIZE, this.handleEditorResize_)
            .listen(eventBus, HgAppEvents.CONNECTION_STATUS_CHANGE, this.onConnectionChange_);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.giphyPopup_ !== null) {
            BaseUtils.dispose(this.giphyPopup_);
            delete this.giphyPopup_;
        }
    }

    /**
     *
     * @param {Object} payload
     * @returns {hg.common.ui.GiphyBubble}
     * @private
     */
    openGifsBubbleInternal_(payload) {
        const placementTarget = payload['placementTarget'],
            placement = payload['placement'],
            staysOpenWhenClicking = payload['staysOpenWhenClicking'],
            eventTarget = payload['eventTarget'],
            bubbleMode = payload['mode'];

        this.toggleActionButton_ = payload['toggleActionButton'] || null;

        const giphyBubble = this.getGiphyBubble_(bubbleMode);
        let canOpen = false;

        // if open and has the same target close it, otherwise close it and reopen with different target
        if (giphyBubble.isOpen()) {
            const currentTarget = giphyBubble.getPlacementTarget();

            /* close bubble for current target */
            giphyBubble.close();
            if (this.toggleActionButton_ &&  this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
                this.toggleActionButton_.setChecked(false);
            }

            if (placementTarget != currentTarget) {
                canOpen = true;
            }
        } else {
            canOpen = true;
        }

        if (canOpen) {
            giphyBubble.setPlacementTarget(placementTarget);
            if(bubbleMode == GiphyBubbleMode.MINI_CHAT) {
                giphyBubble.setRenderParent(payload['renderParent']);
            }

            if (staysOpenWhenClicking != null) {
                giphyBubble.setStaysOpenWhenClicking(staysOpenWhenClicking);
            }

            if (placement != null) {
                giphyBubble.setPlacement(placement);
            }

            /* bubble content events to desired component */
            if (eventTarget != null) {
                giphyBubble.setParentEventTarget(eventTarget);
            }

            if (this.toggleActionButton_ && this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
                this.toggleActionButton_.setChecked(true);
            }

            /* create and set the model for giphy bubble */
            const giphyViewModel = new GiphyBubbleViewmodel();
            giphyViewModel.setGifsDataProvider(this);
            giphyBubble.setModel(giphyViewModel);

            /* open giphy bubble */
            giphyBubble.open();
        }

        return giphyBubble;
    }

    /**
     * Opens the gif selector popup.
     * @param {number=} bubbleMode
     * @return {hg.common.ui.GiphyBubble}
     * @private
     */
    getGiphyBubble_(bubbleMode) {
        if(bubbleMode == GiphyBubbleMode.DEFAULT || bubbleMode == null) {
            if(this.giphyPopup_.DEFAULT == null) {
                this.giphyPopup_.DEFAULT = this.createGiphyBubble();

                this.giphyPopup_.DEFAULT.addListener(UIComponentEventTypes.CLOSE, this.handleGiphyBubbleClose_, false, this);
            }
            return this.giphyPopup_.DEFAULT;
        } else if(bubbleMode == GiphyBubbleMode.MINI_CHAT){
            if(this.giphyPopup_.MINI_CHAT == null) {
                this.giphyPopup_.MINI_CHAT = this.createGiphyBubble({
                    'showArrow'     : false,
                    'extraCSSClass' : ['hg-giphy-bubble-mini-chat'],
                    'mode'          : bubbleMode,
                    'staysOpen'     : true
                });

                this.giphyPopup_.MINI_CHAT.addListener(UIComponentEventTypes.CLOSE, this.handleGiphyBubbleClose_, false, this);
            }
            return this.giphyPopup_.MINI_CHAT;
        }

        return this.giphyPopup_.DEFAULT;
    }

    /**
     * @param {!Object=} opt_config
     * @protected
     */
    createGiphyBubble(opt_config = {}) {
        return new GiphyBubble(opt_config);
    }

    /**
     * Opens or closes the giphy bubble selector.
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleGiphyBubbleDisplay_(e) {
        this.openGifsBubbleInternal_(e.getPayload());
    }

    /**
     * Repositions the popup on editor resize.
     * @private
     */
    handleEditorResize_() {
        if (this.giphyPopup_.DEFAULT != null && this.giphyPopup_.DEFAULT.isOpen()) {
            this.giphyPopup_.DEFAULT.reposition();
        }

        if (this.giphyPopup_.MINI_CHAT != null && this.giphyPopup_.MINI_CHAT.isOpen()) {
            this.giphyPopup_.MINI_CHAT.reposition();
        }
    }

    /**
     * @param {hf.events.Event} e The event object.
     * @private
     */
    handleGiphyBubbleClose_(e) {
        /**@type {hg.common.ui.GiphyBubble}*/(e.getTarget()).setModel(null);

        if (this.toggleActionButton_ && this.toggleActionButton_.isSupportedState(UIComponentStates.CHECKED)) {
            this.toggleActionButton_.setChecked(false);
        }
    }

    /**
     * Close giphy bubble if connection not available
     * @param e
     * @private
     */
    onConnectionChange_(e) {
        const payload = e.getPayload() || {};
        let isConnected = payload['isConnected'];
        if(isConnected != null && !isConnected){
            this.getGiphyBubble_().close();
        }
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
let instance;

function setInstance(soleInstance) {
    instance = soleInstance;
}
function getInstance() {
    return instance;
}

setInstance(new GiphyService());

export default {
    GiphyService,
    getInstance,
    setInstance
};