import { HfError } from '../../../error/Error.js';
import { BaseUtils } from '../../../base.js';
import { JsonUtils } from '../../../json/Json.js';
import { StringUtils } from '../../../string/string.js';

/**
 * @augments {HfError}
 *
 */
export class DataProxyError extends HfError {
    /**
     * @param {number} httpStatus The HTTP status code for the error.
     * @param {string} message The error message.
     * @param {number|string=} opt_code The error code.
     * @param {?*=} opt_data Any data that came with the error
     */
    constructor(httpStatus, message, opt_code, opt_data) {
        super(message, opt_data);

        /**
         * Error code from RPC service
         *
         * @type {number|string|undefined}
         */
        this.code = opt_code;

        /**
         * The HTTP status code for the error.
         *
         * @type {number}
         */
        this.httpStatus = httpStatus;

        /** @override */
        this.name = this.name === undefined ? 'RESTError' : this.name;
    }

    /**
     * Creates an error from the XHR that failed.
     *
     * @param {!XMLHttpRequest} xhr The xhr object
     * @returns {hf.data.DataProxyError} The error object
     *
     */
    static fromXhr(xhr) {
        const httpStatus = xhr.status;

        if (xhr.responseType === '' || xhr.responseType === 'text') {
            const responseText = xhr.responseText;

            if (!StringUtils.isEmptyOrWhitespace(responseText) && JsonUtils.isValidJSON(responseText)) {
                const json = /** @type {object} */(JsonUtils.parse(responseText));

                /** There are request that doesn't return error as JSON object
                 * eg: 415 Unsupported Media Type send error response as string
                 */
                let errorData = json;

                if (BaseUtils.isObject(json)) {
                    errorData = 'error' in json ? json.error : json;
                }

                if (BaseUtils.isString(errorData)) {
                    return new DataProxyError(httpStatus, /** @type {string} */(errorData), 0);
                }

                return new DataProxyError(httpStatus, errorData.message, errorData.code, errorData.data);
            }

        }


        return new DataProxyError(httpStatus, 'Invalid response', 0);
    }
}
