import {HgPartyListItemContent} from "./../../../common/ui/list/HgPartyListItemContent.js";
import {AbstractToolbarView} from "./../../../common/ui/view/AbstractToolbar.js";
import {AdvancedSearch} from "./../component/AdvancedSearch.js";
import {PersonShort} from "./../../../data/model/person/PersonShort.js";
import {HgPersonUtils} from "./../../../data/model/person/Common.js";

/**
 * Creates a new CallHistoryListToolbar view object.
 *
 * @extends {AbstractToolbarView}
 * @unrestricted 
*/
export class ChUiToolbarView extends AbstractToolbarView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addExtraCSSClass('hg-toolbar-ch');
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(
            this.getSearchField(),
            {'set': this.getSearchField().setItemsSource},
            'contacts'
        );
    }

    /** @inheritDoc */
    getSearchFieldConfig() {
        const searchConfig = super.getSearchFieldConfig();

        searchConfig['itemContentFormatter'] = function(model) {
            if(model == null) {
                return null;
            }
            return new HgPartyListItemContent({'model': model });
        };
        searchConfig['popup']['extraCSSClass'].push('hg-call-history-quick-search-popup');

        return searchConfig;
    }

    /** @inheritDoc */
    createAdvancedSearchPopupContent() {
        return new AdvancedSearch();
    }

    /** @inheritDoc */
    handleSearchFieldSelect(evt) {
        const filterValue = evt.getProperty("filterValue");

        if(filterValue instanceof PersonShort) {
            const author = HgPersonUtils.convertPersonToAuthor(/**@type {Object}*/(filterValue));

            this.getPresenter().searchHistoryByParty(author['authorId'], author['type']);
        }
    }
};