import {BasePresenter} from "./../../../common/ui/presenter/BasePresenter.js";

import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";
import {HgAppViews} from "./../../../app/Views.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {QuickConnectInvitation} from "./../../../data/model/visitor/QuickConnectInvitation.js";
import {ToolbarViewmodel} from "./../viewmodel/Toolbar.js";
import {HgHotKeyTypes} from "./../../../common/Hotkey.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {ChatBusyContext} from "./../BusyReason.js";
import {ChatToolbarView} from "./../view/Toolbar.js";
import ConnectInvitationService from "../../../data/service/ConnectInvitationService.js";
import ScreenShareService from "../../../data/service/ScreenShareService.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import MessageExchangeService from "./../../../data/service/MessageExchange.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import RosterService from "./../../../data/service/RosterService.js";
import PersonService from "./../../../data/service/PersonService.js";

/**
 * Creates a new Search presenter.
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ChatToolbarPresenter extends BasePresenter {
    /**
     * @param {!hf.app.state.AppState} state
     */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * @type {hg.data.service.MessageExchangeService}
         * @private
         */
        this.messageExchangeService_;

        /**
         * @type {PersonService}
         * @private
         */
        this.personService_;

        /**
         * @type {RosterService}
         * @private
         */
        this.rosterService_;

        /**
         * @type {hg.data.service.ScreenShareService}
         * @private
         */
        this.screenShareService_;
    }

    /**
     * Install screen share extension
     * @return {Promise}
     */
    installScreenShareExtension() {
        return this.screenShareService_.installExtension();
    }

    /**
     * Close screen sharing session
     * @param {string} sessionId
     */
    leaveScreenShare(sessionId) {
        if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
            this.screenShareService_.leave(sessionId);
        }
    }

    /**
     * Close screen sharing session
     * @param {string} sessionId
     * @return {Promise}
     */
    joinScreenShare(sessionId) {
        if (!StringUtils.isEmptyOrWhitespace(sessionId)) {
            return this.screenShareService_.join(sessionId);
        }

        return Promise.resolve();
    }

    /**
     * Send mass message
     * @return {Promise}
     */
    sendMassMessage() {
        const translator = Translator;
        const model = this.getModel(),
            massMessage = model ? model['massMessage'] : null;

        if (massMessage != null) {
            let recipients = [];

            if (!massMessage['sendToAll']) {
                /* compute list of all jabber ids */
                recipients = massMessage['selectedRecipients'].getAll();
            }

            return this.executeAsync(
                // busy operation
                () => {
                    return this.messageExchangeService_.sendMassMessage(/** @type {string} */(massMessage.get('message.subject')), /** @type {string} */(massMessage.get('message.body')), recipients);
                },

                // callback
                (result) => {
                    /* once message send, clean model */
                    this.discardMassMessageChanges();
                },

                // errback
                (err) => {
                    return new Error(translator.translate('cannot_send_massMessage'));
                },
                //busy reason
                CommonBusyContexts.SUBMIT
            );
        }

        return Promise.resolve();
    }

    /** Discard any change on mass messageClose dialog and discard changes */
    discardMassMessageChanges() {
        const model = this.getModel();

        if (model) {
            /* reset the massMessage */
            model.set('massMessage', undefined);
        }
    }

    /** Discard any change on invitation. Close dialog and discard changes */
    discardInvitationChanges() {
        const model = this.getModel();

        if (model) {
            /* reset invitation */
            model.set('invitation', undefined, true);
        }
    }

    /**
     * Send invitation
     * @param {hg.data.model.visitor.QuickConnectInvitation} invitation
     * @return {Promise}
     */
    sendInvitation(invitation) {
        if (invitation instanceof QuickConnectInvitation &&
            /**@type {hg.data.model.visitor.QuickConnectInvitation}*/(invitation).isSavable()) {
            const connectInvitationService = ConnectInvitationService;

            return this.executeAsync(
                // busy operation
                () => {
                    return connectInvitationService.sendConnectInvitation(invitation);
                },

                // callback
                (result) => {
                    /* once the invitation is sent, clean the model model */
                    this.discardInvitationChanges();
                },

                // errback
                (error) => {
                    return error;
                },

                //busy reason
                CommonBusyContexts.SUBMIT
            );
        }

        return Promise.resolve();
    }

    /**
     * Handles request of roster display mode change
     * @param {Object} rosterFilter
     */
    updateRosterPolicy(rosterFilter) {
        if (BaseUtils.isObject(rosterFilter) && Object.keys(/** @type {!Object} */(rosterFilter)).length > 0) {
            const model = this.getModel();
            if (model) {
                model['rosterFilter'] = rosterFilter;
            }

            this.rosterService_.updatePolicy(rosterFilter);
        }
    }

    /**
     * Handles the chanage of the OPENED state;
     * the chat's toolbar is considered to be OPENED if ANY of the following popups are opened:
     * mass message, send invitation, screen sharing, the threads suggestions, the Customize Roster menu
     * @param {boolean} isOpen
     */
    updateOpenState(isOpen) {
        this.dispatchEvent(HgAppEvents.CHAT_TOOLBAR_OPEN_STATE_CHANGE, {'isOpen': isOpen});
    }

    /**
     * Dispatches an open thread event
     * @param {string} recipientId
     * @param {HgPartyTypes} type
     */
    openThread(recipientId, type) {
        this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
            'recipientId': recipientId,
            'type': type
        });
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CHATTOOLBAR;
    }

    /** @inheritDoc */
    loadView() {
        return new ChatToolbarView();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        /* Listen to roster state change in order to determine busy indicator on thread */
        this.getHandler()
            .listen(eventBus, HgAppEvents.ROSTER_STATE_CHANGE, this.handleRosterStateChange_)

            .listen(eventBus, [HgAppEvents.DATA_CHANNEL_MESSAGE_USER_CREATE, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE], this.handleDataChannelEventUserModified_)

            .listen(eventBus, HgAppEvents.INVITE_TO_PAGE, this.handleInviteToPage_)

            .listen(eventBus, HgAppEvents.HOTKEY_TRIGGERED, this.handleHotkey_);
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();

        /* set busy layer on top of the toolbar: disable action buttons until notification of chat roster active state is received */
        this.markBusy(ChatBusyContext.LOADING);
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.recentUpdatesList_ = new ObservableCollection();

        this.messageExchangeService_ = MessageExchangeService;
        this.personService_ = PersonService;
        this.rosterService_ = RosterService;
        this.screenShareService_ = ScreenShareService;
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.messageExchangeService_ = null;
        this.personService_ = null;
        this.rosterService_ = null;
        this.screenShareService_ = null;
    }

    /**
     * Load view model
     * @protected
     */
    loadModel() {
        const model = new ToolbarViewmodel();
        this.setModel(model);
    }

    /**
     * Handles the roster state change event to update busy indicator
     * @param {hf.app.AppEvent} e
     */
    handleRosterStateChange_(e) {
        const payload = e.getPayload();

        /* exit busy state is any */
        this.markIdle();

        const model = this.getModel();
        if (model) {
            model['rosterState'] = payload['state'];
        }
    }

    handleDataChannelEventUserModified_(e) {
        const model = this.getModel();
        if (model) {
            model['activeUsersCount'] = undefined;
        }
    }

    handleInviteToPage_(e) {
        /**@type {hg.module.chat.view.ChatToolbarView}*/(this.getView()).openSendInvitationDialog();
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleHotkey_(e) {
        const hotkey = e.getPayload()['hotkey'];

        switch (hotkey) {
            case HgHotKeyTypes.INVITE_TO_PAGE:
                /**@type {hg.module.chat.view.ChatToolbarView}*/(this.getView()).openSendInvitationDialog();
                break;
        }
    }
}