import {DataModel} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Model.js";

import {DataModelField} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {
    MaxLengthRules,
    MinLengthRules,
    RegExpMatchRules,
    RequiredRules
} from "./../../../../../../../hubfront/phpnoenc/js/validation/Rules.js";
import {IHgResource} from "./../../resource/IHgResource.js";
import {HgResourceCanonicalNames, HgResourceStatus} from "./../../resource/Enums.js";
import {HgPersonUtils} from "./../../person/Common.js";
import {HgRegExpUtils} from "./../../../../common/regexp.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {AuthorCollection} from "../../author/AuthorCollection.js";

/**
 * Constructor for a new topic full model
 * @extends {DataModel}
 * @unrestricted
 */
export class TopicEdit extends DataModel {
    /**
     * @param {!Object=} opt_initData
     *
     */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'topicId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* The id of the topic */
        this.addField({'name': 'topicId', 'type': DataModelField.PredefinedTypes.STRING});

        /* The topic type: TEAM, PERSONAL, DIRECT. It may be missing */
        this.addField({'name': 'type', 'type': DataModelField.PredefinedTypes.STRING});

        /* The author of the topic */
        this.addField({'name': 'author', 'type': AuthorCollection, 'isPersistable': false,
            'getter': function() {
                return this.getFieldValue('author');
            }
        });

        /* The name of the topic; max 140 characters */
        this.addField({'name': 'name', 'type': DataModelField.PredefinedTypes.STRING});

        /* avatar */
        this.addField({'name': 'avatar', 'type': Array, 'isPersistable': false});

        /* The description of the topic; max 500 characters */
        this.addField({'name': 'description', 'type': DataModelField.PredefinedTypes.STRING});

        /* The status of the topic: HgResourceStatus */
        this.addField({'name': 'status', 'type': DataModelField.PredefinedTypes.STRING});

        /* How many participants are actually watching the Topic */
        this.addField({'name': 'watcherCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        /* True if the topic is watched by me, false otherwise */
        this.addField({'name': 'watchedByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isPersistable': false});

        /* True if the topic is watched by me, false otherwise */
        this.addField({'name': 'messageCount', 'type': DataModelField.PredefinedTypes.NUMBER, 'isPersistable': false});

        this.addField({'name': 'created', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        this.addField({'name': 'updated', 'type': DataModelField.PredefinedTypes.DATE_TIME, 'isPersistable': false});

        /* The priority of the topic for the reader: Priority */
        this.addField({'name': 'priority', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        /* resourceId - The id of the HgResource involved in Hubgets. */
        this.addField({'name': 'resourceId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,
            'getter': function() {
                return this.getUIdField() != null ? this.getFieldValue(this.getUIdField()) : this.getFieldValue('resourceId');
            }
        });

        /* resourceType - The type of the HgResource: HgResourceCanonicalNames  */
        this.addField({'name': 'resourceType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true,});

        this.addField({'name': 'threadId', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        this.addField({'name': 'threadType', 'type': DataModelField.PredefinedTypes.STRING, 'isReadOnly': true});

        this.addField({'name': 'createdByMe', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true,
            'getter': function() {
                return HgPersonUtils.isMe(this.get('author.authorId'));
            }
        });

        this.addField({'name': 'isOpen', 'type': DataModelField.PredefinedTypes.BOOL, 'isReadOnly': true });
    }

    /** @inheritDoc */
    defineValidationRules() {
        super.defineValidationRules();

        const translator = Translator;

        this.addValidationRule(new RequiredRules({
            'targetProperty': 'name'
        }));

        this.addValidationRule(new MinLengthRules({
            'targetProperty': 'name',
            'minLength'     : 4,
            'priority'      : 1
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'name',
            'maxLength'     : 32,
            'priority'      : 1
        }));

        this.addValidationRule(new RegExpMatchRules({
            'targetProperty': 'name',
            'pattern'       : HgRegExpUtils.VALID_TOPIC_NAME_RE,
            'failMessage'   : translator.translate('Provide a name that does not contain characters like %characters%', ['\\"@><{}[]+/#!~|$%^']),
            'priority'      : 2
        }));

        this.addValidationRule(new MaxLengthRules({
            'targetProperty': 'description',
            'maxLength'     : 500
        }));
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        // reset the author field.
        this.setInternal('author', undefined, true);

        rawData['threadId'] = rawData['topicId'];
        rawData['threadType'] = HgResourceCanonicalNames.TOPIC;

        rawData['resourceId'] = rawData['resourceId'] || rawData['topicId'];
        rawData['resourceType'] = rawData['resourceType'] || HgResourceCanonicalNames.TOPIC;
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.setInternal('isOpen', this['status'] === HgResourceStatus.OPEN);
    }

    /** @inheritDoc */
    onFieldValueChanged(fieldName, newValue, oldValue) {
        super.onFieldValueChanged(fieldName, newValue, oldValue);

        if(fieldName === 'status') {
            this.setInternal('isOpen', this['status'] === HgResourceStatus.OPEN);
        }
    }

    /** @inheritDoc */
    canSerializeField(fieldName, serializeOptions) {
        /* serialize the avatar only if the data model is new */
        if(fieldName === 'avatar') {
            let excludeUnchanged = serializeOptions['excludeUnchanged'] || false;
            const dataField = /**@type {DataModelField}*/(this.getField(fieldName));

            return this.isNew() ?
                dataField.hasValue() && (!excludeUnchanged || dataField.isDirty()) :
                super.canSerializeField(fieldName, serializeOptions);
        }

        return super.canSerializeField(fieldName, serializeOptions);
    }
};
// interface implementation
IHgResource.addImplementation(TopicEdit);