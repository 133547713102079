import {SuggestionsBubbleBase} from "./SuggestionsBubbleBase.js";

import {DomUtils} from "./../../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {HgMetacontentUtils} from "./../../../../string/metacontent.js";
import {FunctionsUtils} from "./../../../../../../../../hubfront/phpnoenc/js/functions/Functions.js";
import {Selector} from "./../../../../../../../../hubfront/phpnoenc/js/ui/selector/Selector.js";
import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {ListItemsLayout} from "./../../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new bubble for PersonReference editor plugin
 * @extends {SuggestionsBubbleBase}
 * @unrestricted 
*/
export class HgEmoticonBubbleEditorPlugin extends SuggestionsBubbleBase {
    /**
     * @param {!Object=} opt_config The configuration object
    */
    constructor(opt_config = {}) {


        opt_config['extraCSSClass'] = FunctionsUtils.normalizeExtraCSSClass(opt_config['extraCSSClass'] || [], ['hg-editor-bubble-emoticon']);

        super(opt_config);
    }

    /** @inheritDoc */
    createSuggestionList() {
        const translator = Translator;

        return new Selector({
            'autoLoadData'			: false,
            'displayField'          : 'code',
            'itemsLayout'           : ListItemsLayout.VSTACK,
            'emptyContentFormatter' : function() {
                return translator.translate('no_emoji_found');
            },
            'itemContentFormatter'  : this.createEmoticonDataDom_.bind(this),
            'isScrollable'          : true
        });
    }

    /** @inheritDoc */
    createTitle() {
        const translator = Translator;

        return new Caption({
            'baseCSSClass'  : 'hg-header',
            'content'       : translator.translate('emoji_suggestion')
        });
    }

    createEmoticonDataDom_(model, parent) {
        if(model == null) {
            return null;
        }

        const emojiData = new Caption({
            'baseCSSClass': 'hg-emoticon-suggestion'
        });

        parent.setBinding(emojiData, {'set': emojiData.setContent}, {
            'source': model,
            'sourceProperty': 'code',
            'converter': {
                'sourceToTargetFn': function(key) {
                    const emoji = HgMetacontentUtils.decodeEmoticonTag(key, HgMetacontentUtils.EmoticonDecodeType.MEDIUM),
                        content = DomUtils.htmlToDocumentFragment(emoji + '<span class="hg-metacontent-reference">' + key + '</span>');

                    return content;
                }
            }
        });

        return emojiData;
    }
};