import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UserBotShort} from "./UserBotShort.js";
import {DevAssetResourceOperationType} from "./Enums.js";

/**
 * Create new {@see hg.data.model.dev.ActiveBot} model
 * @extends {UserBotShort}
 * @unrestricted 
*/
export class ActiveBot extends UserBotShort {
    /**
     * @param {!Object=} opt_initData
     *
    */
    constructor(opt_initData) {
        super(opt_initData);

        /**
         * @type {Array}
         * @private
         */
        this.permissionsMap_;
    }

    /**
     *
     * @param {string} resourceType
     * @return {boolean}
     */
    canRead(resourceType) {
        return BaseUtils.isArray(this.permissionsMap_) &&
            this.permissionsMap_.includes(resourceType + ':' + DevAssetResourceOperationType.READ);
    }

    /**
     *
     * @param {string} resourceType
     * @return {boolean}
     */
    canWrite(resourceType) {
        return BaseUtils.isArray(this.permissionsMap_) &&
            this.permissionsMap_.includes(resourceType + ':' + DevAssetResourceOperationType.WRITE);
    }

    /** @inheritDoc */
    onDataLoaded() {
        super.onDataLoaded();

        this.updatePermissionsMap_();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.permissionsMap_ = null;
    }

    /**
     *
     * @private
     */
    updatePermissionsMap_() {
        this.permissionsMap_ = [];

        this['scope'].forEach(function(appScope) {
            this.permissionsMap_.push(appScope['resourceType'] + ':' + appScope['operation']);
        }, this);
    }
};