import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {
    UIComponentEventTypes,
    UIComponentHideMode,
    UIComponentStates
} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {UIComponentBase} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponentBase.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {NormalImageStateContent} from "./NormalImageStateContent.js";
import {ZoomImageStateContent} from "./ZoomImageStateContent.js";
import {FilePreviewEventType} from "./Common.js";
import userAgent from "../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new ImagePreview object.
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ImagePreviewContent extends UIComponent {
    /**
     * @param {!Object=} opt_config Optional configuration object
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /** @inheritDoc */
        this.stateTransitionEventFetcher = ImagePreviewContent.getStateTransitionEvent;

        /**
         * @type {hg.common.ui.file.NormalImageStateContent}
         * @protected
         */
        this.content;

        /**
         * @type {hg.common.ui.file.ZoomImageStateContent}
         * @protected
         */
        this.zoomContent;

        /**
         * @type {hf.ui.UIControl}
         * @protected
         */
        this.imageContainer_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-image-preview';
    }

    /**
     * Enter/leave zoom mode.
     * Does nothing if this state transition is disallowed.
     * @param {boolean} enabled
     * @see #isTransitionAllowed
     */
    setZoomEnabled(enabled) {
        if (this.isTransitionAllowed(ImagePreviewContent.State.ZOOM, enabled)) {
            this.dispatchEvent(FilePreviewEventType.BUSY);

            if (enabled) {
                if (this.zoomContent == null) {
                    this.zoomContent = new ZoomImageStateContent();
                    this.setBinding(this.zoomContent, {'set': this.zoomContent.setModel}, '');
                }

                this.imageContainer_.setContent(this.zoomContent);
            } else {
                this.imageContainer_.setContent(this.content);
            }

            this.setState(ImagePreviewContent.State.ZOOM, enabled);

            const model = this.getModel();
            if (model != null) {
                model['zoomEnabled'] = enabled;
            }
        }
    }

    /**
     * Returns true if the button is in zoom mode, false otherwise.
     * @return {boolean}
     */
    isZoomEnabled() {
        return this.hasState(ImagePreviewContent.State.ZOOM);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['readonly'] = opt_config['readonly'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        // deactivate all states + all state transition events
        this.setSupportedState(UIComponentStates.ALL, false);
        this.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        this.setFocusable(false);
        this.enableMouseEvents(false);

        const baseCSSClass = this.getDefaultBaseCSSClass();

        /* initialize normal content for this component, zoom is initialized on request only! */
        this.content = new NormalImageStateContent({
            'readonly' : opt_config['readonly']
        });
        
        this.imageContainer_ = new UIControl({
            'content'   : this.content,
            'hideMode'  : UIComponentHideMode.VISIBILITY,
            'extraCSSClass': baseCSSClass + '-' + 'state'
        });
        this.imageContainer_.setSupportedState(UIComponentStates.ALL, false);
        this.imageContainer_.setDispatchTransitionEvents(UIComponentStates.ALL, false);
        this.imageContainer_.setFocusable(false);
        this.imageContainer_.enableMouseEvents(false);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.onZoomCapabilityChange_}, 'canZoom');

        this.setBinding(this, {'set': this.onModelChange_}, '');
    }

    /**
     * @param {*=} model
     * @private
     */
    onModelChange_(model) {
        this.content.setModel(model);

        if (model != null) {
            model['zoomEnabled'] = this.isZoomEnabled();
        }
    }

    /**
     * @param {boolean} canZoom
     * @private
     */
    onZoomCapabilityChange_(canZoom) {
        this.setSupportedState(ImagePreviewContent.State.ZOOM, canZoom);
    }

    /** @inheritDoc */
    createCSSMappingObject() {
        const cssMappingObject = super.createCSSMappingObject();

        cssMappingObject[ImagePreviewContent.State.ZOOM] =
            (userAgent.browser.isIE() && userAgent.engine.getVersion() <= 8) ? 'zoom-ie' : 'zoom';

        return cssMappingObject;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();
        
        this.addChild(this.imageContainer_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, [FilePreviewEventType.ZOOM_IN, FilePreviewEventType.ZOOM_OUT], this.handleZoomToggle_)
            .listen(this, FilePreviewEventType.LOAD, this.handleImageLoad_)
            .listen(this, FilePreviewEventType.ERROR, this.handleImageError_);
    }

    /** @inheritDoc */
    exitDocument() {
        this.setZoomEnabled(false);

        super.exitDocument();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.content);
        this.content = null;

        BaseUtils.dispose(this.zoomContent);
        this.zoomContent = null;

        BaseUtils.dispose(this.imageContainer_);
        this.imageContainer_ = null;
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleZoomToggle_(e) {
        this.setZoomEnabled(e.getType() == FilePreviewEventType.ZOOM_IN);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleImageLoad_(e) {
        this.dispatchEvent(FilePreviewEventType.LOAD);
    }

    /**
     * @param {hf.events.Event} e
     * @private
     */
    handleImageError_(e) {
        this.dispatchEvent(FilePreviewEventType.ERROR);
    }

    /**
     * Static helper method; returns the type of event components are expected to
     * dispatch when transitioning to or from the given state.
     * @param {UIComponentStates|hg.common.ui.file.ImagePreviewContent.State} state State to/from which the component
     *     is transitioning.
     * @param {boolean} isEntering Whether the component is entering or leaving the
     *     state.
     * @return {UIComponentEventTypes|hg.common.ui.file.ImagePreviewContent.EventType} Event type to dispatch.
     */
    static getStateTransitionEvent(state, isEntering) {
        switch (state) {
            case ImagePreviewContent.State.ZOOM:
                return isEntering ? ImagePreviewContent.EventType.ENTER_ZOOM :
                    ImagePreviewContent.EventType.LEAVE_ZOOM;
            default:
                // Fall through to the base
                return UIComponentBase.getStateTransitionEvent(/** @type {UIComponentStates} */ (state), isEntering);
        }
    }
};
/**
 * Extra events fired by this
 * Events dispatched before a state transition should be cancelable to prevent
 * the corresponding state change.
 * @enum {string}
 */
ImagePreviewContent.EventType = {
    /**
     * Dispatched before the component enters zoom mode.
     * @event hg.common.ui.file.ImagePreviewContent.EventType.ENTER_ZOOM
     */
    ENTER_ZOOM: 'enterzoom',

    /** Dispatched before the component leaves zoom mode.
     * @event hg.common.ui.file.ImagePreviewContent.EventType.LEAVE_ZOOM
     */

    LEAVE_ZOOM: 'leavezoom'
};

/**
 * Extra states supported by this component
 * @enum {number}
 */
ImagePreviewContent.State = {
    /**
     * Preview is in zoom state
     * @see hg.common.ui.file.ImagePreviewContent.EventType.ENTER_ZOOM
     * @see hg.common.ui.file.ImagePreviewContent.EventType.LEAVE_ZOOM
     */
    ZOOM: 0x400
};