import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {EventHandler} from "./../../../../../hubfront/phpnoenc/js/events/EventHandler.js";
import {EventTarget} from "./../../../../../hubfront/phpnoenc/js/events/EventTarget.js";
import {AppEvent} from "./../../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";

import {IService} from "./IService.js";
import EventBus from "./../../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";

/**
 * Base class for client domain services.
 *
 * @extends {EventTarget}
 * @implements {IService}
 
 * @unrestricted 
*/
export class BaseService extends EventTarget {
 /**
  * @param {Object=} opt_config Configuration object
  *
 */
 constructor(opt_config = {}) {
  /* Call the base class constructor */
  super();

  this.init(opt_config);

  /**
   * Represent the configuration options used to initialize this component.
   *
   * @type {Object}
   * @private
   */
  this.configOptions_;

  /**
   * Event handler.
   * @type {hf.events.EventHandler}
   * @private
   */
  this.eventHandler_;
     
 }

 /**
  * Gets the app event bus.
  *
  * @return {hf.events.IEventBus}
  * @protected
  */
 getEventBus() {
     return EventBus;
 }

 /**
  * Returns the event handler for this component, lazily created the first time
  * this method is called.
  * @return {!hf.events.EventHandler} Event handler for this component.
  * @protected
  */
 getHandler() {
     return this.eventHandler_ ||
         (this.eventHandler_ = new EventHandler(this));
 }

 /**
  * Gets the logger for the hg.data.service subsystem.
  *
  * @return {Logger}
  * @protected
  */
 getLogger() {
     return Logger.get('hg.data.service.BaseService');
 }

 /**
  * Initialization routine.
  * @param {Object=} opt_config
  * @protected
  */
 init(opt_config = {}) {
     this.setConfigOptions(opt_config);

     this.listenToEvents();
 }

 /**
  * Gets the object containing the configuration options used to initialize this Component.
  *
  * @return {!Object}
  * @protected
  */
 getConfigOptions() {
     return /** @type {!Object} */ (this.configOptions_);
 }

 /**
  * Sets the object containing the configuration options used to initialize this Component.
  *
  * @param {Object=} configOptions
  * @protected
  */
 setConfigOptions(configOptions) {
     this.configOptions_ = configOptions || {};
 }

 /** @inheritDoc */
 disposeInternal() {
     super.disposeInternal();

     BaseUtils.dispose(this.eventHandler_);
     this.eventHandler_ = null;

     this.configOptions_ = null;
 }

 /**
  * Inheritors will listen to event bus events.
  * @protected
  */
 listenToEvents() {
    //nop
 }

 /**
  * Dispatches an App event on the Event Bus. *
  * @param {string | hf.app.AppEvent} event
  * @param {Object=} opt_payload
  * @return {boolean} If anyone called preventDefault on the event object (or if any of the listeners returns false) this will also return false.
  */
 dispatchAppEvent(event, opt_payload) {
     if (!BaseUtils.isString(event) && !(event instanceof AppEvent)) {
         throw new Error('Invalid App Event to dispatch.');
     }

     if(BaseUtils.isString(event)) {
         event = new AppEvent((event), opt_payload);
     }

     return this.getEventBus().dispatchEvent(event);
 }
};
IService.addImplementation(BaseService);