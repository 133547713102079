/**
 * Interface for service to which editor event processing is delegated
 *
 * @interface
 
 *
 */
export class IMetacontentService {
    constructor() {
        /**
         * Hostname used on metacontent encode
         *
         * @returns {URL}
         */
        this.getHost;

        /**
         * Register editor whose data request events to handle
         *
         * @param {hf.ui.editor.Field} editor
         */
        this.registerEditor;

        /**
         * Unregister editor whose data request events to handle
         *
         * @param {hf.ui.editor.Field} editor
         */
        this.unregisterEditor;

        /**
         * Register metacontent display whose data request events to handle
         *
         * @param {hf.ui.metacontent.Display} display
         */
        this.registerDisplay;

        /**
         * Unregister metacontent display whose data request events to handle
         *
         * @param {hf.ui.metacontent.Display} display
         */
        this.unregisterDisplay;
    }

    /**
     * Marks a given class (constructor) as an implementation of
     * Listenable, do that we can query that fact at runtime. The class
     * must have already implemented the interface.
     *
     * @param {!Function} cls The class constructor. The corresponding
     *     class must have already implemented the interface.
     */
    static addImplementation(cls) {
        cls.prototype[IMetacontentService.IMPLEMENTED_BY_PROP_] = true;
    }

    /**
     * @param {object} obj The object to check.
     * @returns {boolean} Whether a given instance implements
     *     Listenable. The class/superclass of the instance must call
     *     addImplementation.
     */
    static isImplementedBy(obj) {
        return !!(obj && obj[IMetacontentService.IMPLEMENTED_BY_PROP_]);
    }
}
/**
 * An expando property to indicate that an object implements
 * hf.domain.service.IMetacontentService
 *
 * See addImplementation/isImplementedBy.
 *
 * @type {string}
 * @constant
 * @private
 */
IMetacontentService.IMPLEMENTED_BY_PROP_ = '__hubfront_domain_service_imetacontent';
