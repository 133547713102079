import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ServiceLocator} from "./../../../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Button} from "./../../../../../../hubfront/phpnoenc/js/ui/button/Button.js";
import {ButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/button/ButtonSet.js";
import {Loader} from "./../../../../../../hubfront/phpnoenc/js/ui/Loader.js";
import {UIControl} from "./../../../../../../hubfront/phpnoenc/js/ui/UIControl.js";
import {AuthAccountType, AuthIPCService} from "./../../../data/model/auth/Enums.js";
import {HgAppServices} from "./../../../app/Services.js";
import {JsonUtils} from "./../../../../../../hubfront/phpnoenc/js/json/Json.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The event types dispatched by this component
 * @enum {string}
 * @readonly
 * @public
 */
export const SocialLoginEventType = {
    /**
     * Dispatched when google sign-in flow is successful
     * @event SocialLoginEventType.GOOGLE_SUBMIT */
    GOOGLE_SUBMIT : 'google-submit',

    /**
     * Dispatched when there is an error in google sign-in flow
     * @event SocialLoginEventType.GOOGLE_ERROR */
    GOOGLE_ERROR  : 'google-error'
};

/**
 * Error thrown by Google sign-up
 * @enum {string}
 * @readonly
 * @public
 */
export const GoogleLoginError = {
    /* The user closed the popup before finishing the sign in flow. */
    POPUP_CLOSED_BY_USER    : 'popup_closed_by_user',

    /* The user denied the permission to the scopes required. */
    ACCESS_DENIED           : 'access_denied'
};

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class SocialLogin extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button
         * @type {hf.ui.ButtonSet}
         * @private
         */
        this.buttonSet_;

        /**
         * The target origin for inter frame communication
         * @type {string}
         * @private
         */
        this.targetOrigin_;

        /**
         * Iframe hidden wrapper
         * @type {hf.ui.UIControl}
         * @private
         */
        this.iframeWrapper_;

        /**
         * @type {boolean}
         * @private
         */
        this.forcedPrompt_ = false;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-social';
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-auth-social';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCssClass = this.getBaseCSSClass();

        this.buttonSet_ = new ButtonSet({
            'extraCSSClass' : baseCssClass + '-' + 'btnset'
        });

        this.buttonSet_.addButton(new Button({
            'extraCSSClass'		: ['hg-button-primary', SocialLogin.CssClasses.GOOGLE],
            'name'          	: SocialLogin.Buttons.GOOGLE,
            'content'		    : opt_config['googleButtonText'] || translator.translate('google_sign_in'),
            'loader': {
                'size' : Loader.Size.LARGE,
                'extraCSSClass': 'grayscheme'
            }
        }));

        this.iframeWrapper_ = new UIControl({
            'extraCSSClass'     : baseCssClass + '-' + 'hidden'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.buttonSet_);
        this.buttonSet_ = null;

        BaseUtils.dispose(this.iframeWrapper_);
        this.iframeWrapper_ = null;

        delete this.forcedPrompt_;
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.buttonSet_, true);
        this.addChild(this.iframeWrapper_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            .listen(this.buttonSet_, UIComponentEventTypes.ACTION, this.handleButtonSetAction_);

        const ipcEventBus = /** @type {hg.events.IPCMessageBus} */ (/**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getService(HgAppServices.IPC_MESSAGE_BUS));
        if (ipcEventBus) {
            ipcEventBus.on(AuthIPCService.GOOGLE_LOGIN, this.handleGoogleLogin_.bind(this));
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        const ipcEventBus = /** @type {hg.events.IPCMessageBus} */ (/**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getService(HgAppServices.IPC_MESSAGE_BUS));
        if (ipcEventBus) {
            ipcEventBus.removeListener(AuthIPCService.GOOGLE_LOGIN, this.handleGoogleLogin_.bind(this));
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this, {'set': this.createIframeWrapperContent_}, 'frameURI');

    }

    /**
     *
     * @param {string} googleLoginPath
     * @private
     */
    createIframeWrapperContent_(googleLoginPath) {
        const content = DomUtils.createDom('IFRAME', {
            'src': googleLoginPath
        });

        this.iframeWrapper_.setContent(content);

        const tmp = googleLoginPath.split('/');
        this.targetOrigin_ = tmp[0] + '//' + tmp[2];
    }

    /**
     *
     * @param {string} action
     * @param {Object=} payload
     */
    postGoogleLoginMessage(action, payload = {}) {
        if (this.targetOrigin_ != null) {
            let iframeWrapperElement = this.iframeWrapper_.getElement(),
                googleLoginIframe    = iframeWrapperElement.children[0],
                messageObject        = {'action': action};

            for (let key in payload) {
                messageObject[key] = payload[key];
            }

            // Post mesage to the iframe in order to init google login
            googleLoginIframe.contentWindow.postMessage(`google-login:${JSON.stringify(messageObject)}`, this.targetOrigin_);
        }
    }

    /**
     * Handles CLICK on a social login button. Inits the signup flow
     * @param {hf.events.Event} e
     * @protected
     */
    handleButtonSetAction_(e) {
        const button = e.getTarget();

        if (button instanceof Button) {
            if (button.getName() === SocialLogin.Buttons.GOOGLE) {
                // Put button in busy state
                button.setBusy(true);

                this.postGoogleLoginMessage('init', {
                    'forcedPrompt': this.forcedPrompt_
                })
            }
        }
    }

    /**
     * Handle the Google login
     * @param payload
     * @private
     */
    handleGoogleLogin_(payload) {
        if ( payload != null ) {
            const response = JsonUtils.parse(payload);

            let event;
            const googleButton = this.buttonSet_.getButtonByName(SocialLogin.Buttons.GOOGLE);

            if ( response['error'] == null ) {
                // Sign In successful case
                // keep busy state on button and remove it on Promise's callback
                event = new Event(SocialLoginEventType.GOOGLE_SUBMIT);
                event.addProperty('payload', {
                    'type'      : AuthAccountType.GOOGLE,
                    'email'     : response['email'],
                    'id_token'  : response['id_token']
                });
            } else {
                // Error cases
                googleButton.setBusy(false);

                event = new Event(SocialLoginEventType.GOOGLE_ERROR);
                event.addProperty('payload', {
                    /* Possible values:
                     *  case GoogleLoginError.POPUP_CLOSED_BY_USER
                     *  case GoogleLoginError.ACCESS_DENIED
                     */
                    'error'     : response['error']
                });
            }

            this.dispatchEvent(event);

            const promisedResult = event.getProperty('promisedResult');
            if(promisedResult instanceof Promise) {
                promisedResult
                    .catch((err) => {
                        this.forcedPrompt_ = true;
                    })
                    .finally(() => {
                        googleButton.setBusy(false);
                    });
            }
            else {
                googleButton.setBusy(false);
            }
        }
    }
};
/**
 * The CSS classes used by this component.
 * @enum {string}
 * @readonly
 * @public
 */
SocialLogin.CssClasses = {
    GOOGLE 	    : 'google'
};

/**
 * The names of social buttons
 * @enum {string}
 * @readonly
 * @protected
 */
SocialLogin.Buttons = {
    GOOGLE 	    : 'google'
};