import { EventsUtils } from './Events.js';
import { BrowserEvent } from './BrowserEvent.js';
import { EventTarget } from './EventTarget.js';
import { BrowserEventType } from './EventType.js';
import { Coordinate } from '../math/Coordinate.js';
import userAgent from '../../thirdparty/hubmodule/useragent.js';

/**
 * @augments {EventTarget}
 
 *
 */
export class TouchHandler extends EventTarget {
    /**
     * @param {Element} element  The element that you want to listen for touch events on.
     * @param {!object=} opt_config
     * @param {boolean=} opt_capture
     *
     */
    constructor(element, opt_config = {}, opt_capture) {
        super();

        opt_config.swipeThreshold = opt_config.swipeThreshold || 100;
        opt_config.tapThreshold = opt_config.tapThreshold || 150; // range of time where a tap event could be detected
        opt_config.dbltapThreshold = opt_config.dbltapThreshold || 250; // delay needed to detect a double tap
        opt_config.longtapThreshold = opt_config.longtapThreshold || 600; // delay needed to detect a long tap
        opt_config.tapPrecision = opt_config.tapPrecision || 60 / 2; // touch events boundaries ( 60px by default )

        /**
         * The config options
         *
         * @type {object}
         * @private
         */
        this.configOptions_ = opt_config;

        /**
         * The element that you want to listen for input events on.
         *
         * @type {Element}
         * @private
         */
        this.element_ = element;

        /**
         *
         * @type {*}
         * @private
         */
        this.pointerId_;

        /**
         * Id of a timer used to postpone firing input event in emulation mode.
         *
         * @type {?number}
         * @private
         */
        this.longtapTimer_ = null;

        /**
         * Id of a timer used to postpone firing input event in emulation mode.
         *
         * @type {?number}
         * @private
         */
        this.tapTimer_ = null;

        /**
         *
         * @type {?Element}
         * @private
         */
        this.touchTarget_ = null;

        /**
         *
         * @type {?number}
         * @private
         */
        this.lastTouchTimestamp_ = null;

        /**
         *
         * @type {hf.math.Coordinate}
         * @private
         */
        this.currentTouchCoordinates_ = new Coordinate();

        /**
         *
         * @type {hf.math.Coordinate}
         * @private
         */
        this.cachedTouchCoordinates_ = new Coordinate();

        /* listen to touche events only on mobile and tablet */
        if (!userAgent.device.isDesktop()) {

            /**
             * Store the listen key so it easier to unlisten in dispose.
             *
             * @private
             * @type {EventKey}
             */
            this.listenKeyTouchStart_ = EventsUtils.listen(element, BrowserEventType.TOUCHSTART, this.handleEvent, opt_capture, this);

            /**
             * Store the listen key so it easier to unlisten in dispose.
             *
             * @private
             * @type {EventKey}
             */
            this.listenKeyTouchEnd_ = EventsUtils.listen(element, BrowserEventType.TOUCHEND, this.handleEvent, opt_capture, this);

            /**
             * Store the listen key so it easier to unlisten in dispose.
             *
             * @private
             * @type {EventKey}
             */
            this.listenKeyTouchMove_ = EventsUtils.listen(element, BrowserEventType.TOUCHMOVE, this.handleEvent, opt_capture, this);
        }
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();

        EventsUtils.unlistenByKey(this.listenKeyTouchStart_);
        this.listenKeyTouchStart_ = null;

        EventsUtils.unlistenByKey(this.listenKeyTouchEnd_);
        this.listenKeyTouchEnd_ = null;

        EventsUtils.unlistenByKey(this.listenKeyTouchMove_);
        this.listenKeyTouchMove_ = null;

        clearTimeout(this.tapTimer_);
        this.tapTimer_ = null;

        clearTimeout(this.longtapTimer_);
        this.longtapTimer_ = null;

        this.configOptions_ = null;
        this.element_ = null;
        this.pointerId_ = undefined;
        this.touchTarget_ = null;
        this.lastTouchTimestamp_ = null;
        this.currentTouchCoordinates_ = null;
        this.cachedTouchCoordinates_ = null;
    }

    /**
     *
     * @param {Event} e
     * @returns {boolean}
     * @protected
     */
    isTheSameFingerId(e) {
        return !e.pointerId || typeof this.pointerId_ === 'undefined' || e.pointerId === this.pointerId_;
    }

    /**
     *
     * @param {Event} e
     * @returns {Event}
     * @protected
     */
    getPointerEvent(e) {
        return e.targetTouches ? e.targetTouches[0] : e;
    }

    /**
     *
     * @param {TouchHandlerEventType} eventType
     * @param {Event} originalEvent
     * @param {object=} opt_data
     * @returns {boolean} An input event.
     * @protected
     */
    dispatchTapEvent(eventType, originalEvent, opt_data) {
        const touchEvent = new BrowserEvent(originalEvent);
        touchEvent.type = eventType;

        for (let key in opt_data) {
            touchEvent[key] = opt_data[key];
        }

        return this.dispatchEvent(touchEvent);
    }

    /**
     * This handles the underlying events and dispatches a new event.
     *
     * @param {hf.events.BrowserEvent} e  The underlying browser event.
     */
    handleEvent(e) {
        const be = e.getBrowserEvent();

        switch (e.type) {
            case BrowserEventType.TOUCHSTART:
                this.handleTouchStart(be);
                break;

            case BrowserEventType.TOUCHEND:
                this.handleTouchEnd(be);
                break;

            case BrowserEventType.TOUCHMOVE:
                this.handleTouchMove(be);
                break;
        }
    }

    /**
     *
     * @param {Event} e The underlying browser event.
     * @protected
     */
    handleTouchStart(e) {
        if (!this.isTheSameFingerId(e)) {
            return;
        }

        this.pointerId_ = e.pointerId;

        const pointer = this.getPointerEvent(e);

        /* caching the current x */
        this.cachedTouchCoordinates_.x = this.currentTouchCoordinates_.x = pointer.pageX;
        /* caching the current y */
        this.cachedTouchCoordinates_.y = this.currentTouchCoordinates_.y = pointer.pageY;

        clearTimeout(this.longtapTimer_);
        this.longtapTimer_ = setTimeout(() => {
            this.longtapTimer_ = null;

            this.dispatchTapEvent(TouchHandlerEventType.LONG_TAP, e);

            /* simulate a TOUCHEND so that all draggers will cancel the dragging */
            const document = /** @type {!Document} */ (this.touchTarget_.nodeType == Node.DOCUMENT_NODE ? this.touchTarget_ : this.touchTarget_.ownerDocument || this.touchTarget_.document),
                touchendEv = new Event(BrowserEventType.TOUCHEND);

            document.dispatchEvent(touchendEv);

            /* reset the touch target */
            this.touchTarget_ = null;
        }, this.configOptions_.longtapThreshold);

        this.touchTarget_ = /** @type {Element} */(e.target);
    }

    /**
     *
     * @param {Event} e The underlying browser event.
     * @protected
     */
    handleTouchEnd(e) {
        if (!this.isTheSameFingerId(e)) {
            return;
        }

        this.pointerId_ = undefined;

        const now = Date.now(),
            deltaT = now - this.lastTouchTimestamp_ || 0,
            deltaY = this.cachedTouchCoordinates_.y - this.currentTouchCoordinates_.y,
            deltaX = this.cachedTouchCoordinates_.x - this.currentTouchCoordinates_.x;

        /* clear the previous timers if it was set */
        clearTimeout(this.tapTimer_);
        this.tapTimer_ = null;

        /* kill the long tap timer */
        clearTimeout(this.longtapTimer_);
        this.longtapTimer_ = null;

        if (Math.abs(deltaX) >= this.configOptions_.swipeThreshold || Math.abs(deltaY) >= this.configOptions_.swipeThreshold) {
            this.dispatchTapEvent(TouchHandlerEventType.SWIPE, e, {
                x: this.currentTouchCoordinates_.x,
                y: this.currentTouchCoordinates_.y,
                deltaX,
                deltaY
            });
        } else {
            if (this.touchTarget_ === e.target
                && deltaX >= -this.configOptions_.tapPrecision && deltaX <= this.configOptions_.tapPrecision
                && deltaY >= -this.configOptions_.tapPrecision && deltaY <= this.configOptions_.tapPrecision) {

                if (deltaT > 20 && deltaT < this.configOptions_.dbltapThreshold) {
                    clearTimeout(this.tapTimer_);
                    this.tapTimer_ = null;

                    this.dispatchTapEvent(TouchHandlerEventType.DOUBLE_TAP, e);
                } else {
                    this.lastTouchTimestamp_ = now;

                    // reset the tapCounter
                    this.tapTimer_ = setTimeout(() => this.dispatchTapEvent(TouchHandlerEventType.TAP, e), this.configOptions_.dbltapThreshold);
                }

            }
        }
    }

    /**
     *
     * @param {Event} e The underlying browser event.
     * @protected
     */
    handleTouchMove(e) {
        if (!this.isTheSameFingerId(e)) {
            return;
        }

        const pointer = this.getPointerEvent(e);

        this.currentTouchCoordinates_.x = pointer.pageX;
        this.currentTouchCoordinates_.y = pointer.pageY;

        const deltaY = this.cachedTouchCoordinates_.y - this.currentTouchCoordinates_.y,
            deltaX = this.cachedTouchCoordinates_.x - this.currentTouchCoordinates_.x;

        /* if the touch move is outside tap precission then stop the tap and long tap timers */
        if (this.touchTarget_ === e.target
            && deltaX >= -this.configOptions_.tapPrecision && deltaX <= this.configOptions_.tapPrecision
            && deltaY >= -this.configOptions_.tapPrecision && deltaY <= this.configOptions_.tapPrecision) {

            /* kill the tap timer */
            clearTimeout(this.tapTimer_);
            this.tapTimer_ = null;

            /* kill the long tap timer */
            clearTimeout(this.longtapTimer_);
            this.longtapTimer_ = null;

        }
    }
}

/**
 * Enum type for the events fired by the touch handler
 *
 * @enum {string}
 */
export const TouchHandlerEventType = {
    TAP: 'tap',

    DOUBLE_TAP: 'dbltap',

    LONG_TAP: 'longtap',

    SWIPE: 'swipe'
};
