import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {HfLinkEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/Link.js";
import {
    EditorCommandType,
    EditorPluginEventType,
    EditorRange
} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {LinkPreviewer, LinkPreviewerEventType} from "./../../LinkPreviewer.js";
import {PreviewResourceTypes} from "./../../../../data/model/preview/Enums.js";
import {URLPreview} from "./../../../../data/model/preview/URLPreview.js";
import {FileTypes} from "./../../../../data/model/file/Enums.js";
import {LinkifyUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/linkify.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";

/**
 * Creates a new editor plugin
 * @extends {HfLinkEditorPlugin}
 * @unrestricted 
*/
export class HgLinkEditorPlugin extends HfLinkEditorPlugin {
    /**
     * @param {ResourceLike=} opt_resourceLink The resource edited, used for encoding a link
     *  which has preview in the context of the respective resource; This is useful for knowing where in the app to route the user
     *  on a link with preview when the message is consumed by other xmpp clients.
     * @param {?boolean=} opt_encodeOnEnter Whether the plugin should encode the emoticon code as emoji symbol
     * when
     * press
     * Enter key. Default: true
     * @param {boolean=} opt_enablePreview True to enable preview on fiest link, false otherwise. Default: true;
    */
    constructor(opt_resourceLink, opt_encodeOnEnter, opt_enablePreview) {
        super(opt_encodeOnEnter);

        this.resourceLink_ = opt_resourceLink;

        /**
         * True to support preview, false otherwise
         * @type {boolean}
         * @private
         */
        this.enablePreview_ = opt_enablePreview != null ? !!opt_enablePreview : true;

        /**
         * Edited resource in which context the link will be introduced
         * @type {ResourceLike|undefined}
         * @private
         */
        this.resourceLink_;

        /**
         * @type {boolean}
         * @private
         */
        this.isLoadingPreviewData_ = false;

        /**
         * Link preview
         * @type {hg.common.ui.LinkPreviewer}
         * @private
         */
        this.preview_ = this.preview_ === undefined ? null : this.preview_;

        /**
         * Href of link preview
         * @type {string|null}
         * @private
         */
        this.previewedUrl_ = this.previewedUrl_ === undefined ? null : this.previewedUrl_;

        /**
         * Link node for which a preview is displayed
         * @type {Node}
         * @private
         */
        this.previewedLink_ = this.previewedLink_ === undefined ? null : this.previewedLink_;

        /**
         * Url for which the last data request was made to avoid making a requesr twice on huge timeout
         * @type {string|null}
         * @private
         */
        this.lastPreviewedRequestUrl_ = this.lastPreviewedRequestUrl_ === undefined ? null : this.lastPreviewedRequestUrl_;

        /**
         * Timer for link preview
         * @type {number|null}
         * @private
         */
        this.timerId_ = this.timerId_ === undefined ? null : this.timerId_;

        /**
         * @type {number|null}
         * @private
         */
        this.timeoutId_ = this.timeoutId_ === undefined ? null : this.timeoutId_;
    }

    /** @override */
    getTrogClassId() {
        return 'Link';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.LINK;
    }

    /** @override */
    hasUnattachedContent() {
        return this.previewedLink_ != null;
    }

    /**
     * Creates a LinkPreviewer with the given config.
     * @param {!Object=} opt_config
     * @returns {hg.common.ui.LinkPreviewer}
     * @protected
     */
    createLinkPreviewer_(opt_config) {
        return new LinkPreviewer(opt_config);
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        /* if inside url than do not allow any content to be pasted or dropped */
        const range = this.getFieldObject().getRange(),
            anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset(),
            rangeNodeParent = anchorNode.parentElement;

        if (rangeNodeParent != null && this.isTargetedAnchor(rangeNodeParent)) {
            if (offset == 0) {
                /* if pasted at the begining extract outside */
                const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                if (prevNode == null || prevNode == rangeNodeParent.parentNode || prevNode.tagName == 'BR') {
                    const emptyNode = this.getDocument().createTextNode('\uFEFF');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                    }
                }

                EditorRange.placeCursorNextTo(rangeNodeParent, true);
            } else if (offset == DomUtils.getTextContent(rangeNodeParent).length) {
                /* if pasted at the end extract outside */
                const nextSibling = rangeNodeParent.nextSibling;

                if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && nextSibling.data.startsWith('\uFEFF')) {
                    /* split after zero-width node and place cursor after it */
                    nextSibling.splitText('\uFEFF'.length);
                    EditorRange.placeCursorNextTo(nextSibling, false);
                } else {
                    const emptyNode = this.getDocument().createTextNode('\uFEFF');
                    if (rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent.nextSibling);
                    }
                    EditorRange.placeCursorNextTo(emptyNode, false);
                }
            } else {
                return '';
            }
        }

        if (LinkifyUtils.isUrlLike(pastedContent)) {
            pastedContent = pastedContent.slice(-1) != ' ' ? pastedContent + ' ' : pastedContent;
        }
        const processedContent = super.processPastedContent(pastedContent);

        /* schedule event for determining necessity of preview on pasted content */
        setTimeout(() => this.onPaste_(processedContent));

        return processedContent;
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        /* inserts preview if necessary */
        this.prepareContentsDom();
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        content = super.prepareContentsHtml(content);
        
        /* content changed, reset preview */
        this.hidePreview();

        return content;
    }

    /** @inheritDoc */
    prepareContentsDom() {
        /* content changed, reset preview */
        this.hidePreview();

        /* inserts preview if necessary */
        const previewedLink = this.findPreviewedLink_();
        if (previewedLink) {
            if (previewedLink.getAttribute('data-unattached')) {
                if (previewedLink.getAttribute('data-preview') == 1) {
                    /* remove the link, but not the preview */
                    previewedLink.innerHTML = "";
                } else {
                    /* detached preview, remove from dom */
                    if (previewedLink.parentNode) {
                        previewedLink.parentNode.removeChild(previewedLink);
                    }

                }
            }

            this.showPreview(previewedLink);
        }
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        /* if the link is removed before the service answers, hide the preview */
        const previewedLinkHref = this.previewedLink_ ? this.previewedLink_.getAttribute('href') : '';
        
        if (!this.isLoadingPreviewData_ &&
            !StringUtils.isEmptyOrWhitespace(previewedLinkHref) &&
            content.indexOf(previewedLinkHref) == -1 && content.indexOf(StringUtils.htmlEscape(previewedLinkHref)) == -1) {
            this.hidePreview();
        }
        /* careful, on editor BLUR is enters here */
        /* HG-5949: we need to add link preview metacontent if preview is not connected to an url in content */
        else if (this.previewedLink_ != null) {
            let inDoc = this.getFieldObject().getElement() != null && this.getFieldObject().getElement().contains(this.previewedLink_);
            if (!inDoc || previewedLinkHref != this.previewedUrl_) {
                const preview = this.previewedLink_.cloneNode(false);
                if (!StringUtils.isEmptyOrWhitespace(this.previewedUrl_)) {
                    preview.setAttribute('href', this.previewedUrl_);
                }

                preview.setAttribute('data-unattached', '1');
                if (this.previewedLink_.getAttribute('data-preview' == null) || this.previewedLink_.getAttribute('data-preview') == 0) {
                    preview.setAttribute('data-preview', '1');
                }

                /* make sure the text content is correct */
                preview.textContent = /** @type {string} */(this.previewedUrl_);

                /* detached preview must be sent at the end of the message (not displayed visibly) */
                const trimmedContent = content.trimRight();
                if (StringUtils.isEmptyOrWhitespace(trimmedContent)) {
                    content = DomUtils.getOuterHtml(/** @type {Element} */(preview));
                } else if (content.indexOf(preview.getAttribute('href')) != -1) {
                    return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.LINK, this.resourceLink_);
                } else {
                    content = trimmedContent + ' ' + DomUtils.getOuterHtml(/** @type {Element} */(preview));
                }
            }
        }

        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.LINK, this.resourceLink_);
    }

    /** @inheritDoc */
    disable(field) {
        super.disable(field);

            /* clear previewed link */
        this.previewedLink_ = null;
        this.hidePreview();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        /* clear link preview */
        if (this.preview_ !== null) {
            BaseUtils.dispose(this.preview_);
            delete this.preview_;
        }

        delete this.previewedUrl_;
    }

    /** @inheritDoc */
    execCommandInternal(command, text) {
        super.execCommandInternal(command, text);

        /* preview newly introduced node if no preview exists */
        let dontShowPreview = false;
        const range = this.getFieldObject().getRange();

        if (range) {
            const anchorNode = range.getAnchorNode();
            let link = (anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE) ? anchorNode : anchorNode.parentNode;

            if (link.tagName != 'A' &&
                anchorNode && anchorNode.nodeType == Node.TEXT_NODE && StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue)) {

                /* on ff and device android mobile the anchor node is the whitespace introduced right after the link,
                 we need to test previous element if the anchor node is text and empty */
                link = userAgent.device.isDesktop() ? anchorNode.previousSibling : anchorNode.previousElementSibling;
            }

            /* check the other children in the editor to see if they match with the previewedLink_
             if yes, don't change the preview */
            if (link.tagName == 'A') {
                for(let node of link.parentNode.childNodes) {
                    if (node == this.previewedLink_) {
                        dontShowPreview = true;
                        break;
                    }
                }
            }

            if (!dontShowPreview && link.tagName == 'A') {
                setTimeout(() => this.showPreview(link));
            }
        }
    }

    /**
     * Find the link which required a preview
     * @return {Element|undefined} The found node or undefined if none is found.
     * @private
     */
    findPreviewedLink_() {
        let element = this.getFieldObject().getElement(),
            linkEl;

        if (element) {
            linkEl = DomUtils.findNode(element, function (node) {
                if (node.nodeType == Node.ELEMENT_NODE) {
                    node = /** @type {Element} */(node);
                    return (node.tagName == 'A' && !!parseInt(node.getAttribute('data-preview'), 10));
                }

                return false;
            });
        }

        return /**@type {Element|undefined}*/(linkEl);
    }

    /**
     * Show link preview for specified node
     * @param {Element} node Anchor node for which to display preview
     * @protected
     */
    showPreview(node) {
        /*no support for preview or the link node does not belong anymore to the editor */
        if (!this.enablePreview_ || !this.getFieldObject().getElement().contains(node)) {
            return;
        }

        /* check if node is still in document */
        if (this.lastPreviewedRequestUrl_ == node.getAttribute('href')) {
            this.attachPreviewNode_(node);
            return;
        }

        this.lastPreviewedRequestUrl_ = node.getAttribute('href');

        /* HG-7061: preview true on first link, false only on user dismiss or data fetch error */
        this.attachPreviewNode_(node);

        const resources = {};
        resources[PreviewResourceTypes.LINK] = 1;

        const previewEvent = new Event(EditorPluginEventType.CAN_DISPLAY_PREVIEW);
            previewEvent.addProperty('resources', resources);

        /* can display preview, editor has enough space on the current resolution to display preview */
        if (this.dispatchEvent(previewEvent)) {
            const event = new Event(EditorPluginEventType.DATA_REQUEST);
                event.addProperty('url', node.getAttribute('href'));

            if (this.dispatchEvent(event)) {
                /* event processed, check requested data */
                const data = event.getProperty('data');

                if (data instanceof Promise) {
                    this.isLoadingPreviewData_ = true;

                    this.showPreviewInternal_(/** @type {Promise} */(data), node);
                }
            }
        }
        if (node.getAttribute('data-unattached')) {
            if (node && node.parentNode) {
                node.parentNode.removeChild(node);
            }
        }
    }

    /**
     * Show link preview carousel
     * Preview source could be fetched for encountered links
     * @param {Promise} source Promise with canonical link preview
     * @param {Element} node Node to attach to preview if data can be fetched
     * @private
     */
    showPreviewInternal_(source, node) {
        const editor = this.getFieldObject();
            editor.startChangeEvents(true, true);

        if (this.preview_ == null) {
            this.preview_ = this.createLinkPreviewer_();
            this.registerPreviewEvents();
        }

        if (!this.preview_.isInDocument()) {
            const editorParent = editor.getParentEventTarget();

            /* check parent component of editor element */
            if (editorParent != null) {
                const textEditorParent = editorParent.getParentEventTarget();

                /* check text editor parent - it must be instance of hg.module.chat.editor.Editor */
                if (textEditorParent != null && BaseUtils.isFunction(textEditorParent.addLinkPreviewChild)) {
                    textEditorParent.addLinkPreviewChild(this.preview_);
                }
            }
        }

        clearTimeout(this.timeoutId_);
        this.timeoutId_ = setTimeout(() => {
            this.hidePreview();
            this.timeoutId_ = null;

            /* restart change and delayed change events, emit event on starting (to adjust scrollbars on preview) */
            editor.startChangeEvents(true, true);
        }, 10000);

        /* set preview data source */
        source
            .then((data) => {
                let canContinue = false;
                if (this.preview_ != null && data instanceof URLPreview) {
                    /* preview an image */
                    if (data['type'] == FileTypes.IMAGE && data['thumbnailCount'] > 0) {
                        canContinue = true;
                    }

                    if (!StringUtils.isEmptyOrWhitespace(data['title']) || !StringUtils.isEmptyOrWhitespace(data['description'])) {
                        canContinue = true;
                    }
                }

                if (canContinue) {
                    this.attachPreviewNode_(node, /** @type {hg.data.model.preview.URLPreview} */(data));
                    this.preview_.setModel(data);
                } else {
                    this.hidePreview();
                }
            })
            .catch((err) => this.hidePreview())
            .finally(() => {
                if (this.timeoutId_ != null) {
                    clearTimeout(this.timeoutId_);
                }

                /* restart change and delayed change events, emit event on starting (to adjust scrollbars on preview) */
                editor.startChangeEvents(true, true);
            });
    }

    /**
     * Hide link preview is displayed
     * Triggered when link reference was removed from the content
     * @protected
     */
    hidePreview() {
        /* cancel any request for data fetch */
        if (this.isLoadingPreviewData_) {

            if (this.timeoutId_ != null) {
                clearTimeout(this.timeoutId_);
            }
        }
        this.isLoadingPreviewData_ = false;

        this.lastPreviewedRequestUrl_ = null;

        /* detach link node to this preview */
        this.detachPreviewNode__();

        if (this.preview_ != null) {
            this.preview_.setModel(null);

            if (this.preview_.isInDocument()) {
                this.preview_.exitDocument();
                if (this.preview_.getElement() && this.preview_.getElement().parentNode) {
                    this.preview_.getElement().parentNode.removeChild(this.preview_.getElement());
                }
            }

            BaseUtils.dispose(this.preview_);
            delete this.preview_;
        }
    }

    /**
     * Register events for interracting with preview control on chosen options
     * @protected
     */
    registerPreviewEvents() {
        if (this.preview_ != null) {
            this.getHandler().
                listen(this.preview_, LinkPreviewerEventType.OPTIONS_CHANGED, this.handlePreviewOptionChange_);
        }
    }

    /**
     * Attach anchor node to the link preview
     * @param {Element} node Node to attach to the link preview
     * @param {hg.data.model.preview.URLPreview=} opt_data Node to attach to the link preview
     * @private
     */
    attachPreviewNode_(node, opt_data) {
        this.previewedLink_ = node;

        this.previewedLink_.setAttribute('data-preview', '1');
        this.previewedLink_.setAttribute('data-stb', '1');

        this.previewedUrl_ = node.getAttribute('data-unattached') ? null : node.getAttribute('href');
    }

    /**
     * Detach anchor node to the link preview
     * @private
     */
    detachPreviewNode__() {
        if (this.previewedLink_) {
            this.previewedLink_.setAttribute('data-preview', '0');
            this.previewedLink_.removeAttribute('data-tbi');
            this.previewedLink_.removeAttribute('data-stb');

            delete this.previewedLink_;
        }

        this.previewedUrl_ = null;
    }

    /**
     * Processing after paste event takes place
     * Determine if previewedLink is still in the document after a paste (the pasted content might go on top and remove it)
     * If no preview available preview the first, try to preview the first link found in the pasted content
     * Careful, it might match on another node previously added with the same href, known issue
     * @param {string} pastedContent Intercepted pasted content to process (innerHTML of paste element)
     */
    onPaste_(pastedContent) {
        const element = this.getFieldObject().getElement();
        let noPreview = true;

        if (this.previewedLink_ !== null) {
            const inDoc = element != null && element.contains(this.previewedLink_);
            if (inDoc) {
                noPreview = false;
            }
        }

        /* try to preview link from pasted content */
        if (noPreview) {
            const pastedFragment = DomUtils.htmlToDocumentFragment(pastedContent);

            let firstLinkInPaste;

            /* paste an URL*/
            if (pastedFragment.nodeType == Node.ELEMENT_NODE && pastedFragment.tagName == 'A' && !(pastedFragment.getAttribute('href') || '').startsWith('mailto:')) {
                firstLinkInPaste = pastedFragment;
            } else {
                /*  paste text that contains URLs*/
                firstLinkInPaste = DomUtils.findNode(pastedFragment, function (node) {
                    return (node.nodeType == Node.ELEMENT_NODE && node.tagName == 'A' && !(node.getAttribute('href') || '').startsWith('mailto:'));
                });
            }

            if (firstLinkInPaste) {
                const node = DomUtils.findNode(element, function (node) {
                    return (node.nodeType == Node.ELEMENT_NODE && node.tagName == 'A'
                        && node.getAttribute('href') == firstLinkInPaste.getAttribute('href'));
                });

                if (node) {
                    this.showPreview(/** @type {Element} */(node));
                }
            }
        }
    }

    /** @inheritDoc */
    linkify(text) {
        return HgMetacontentUtils.decodeActionTag(text, HgMetacontentUtils.ActionTag.LINK, true, this.resourceLink_);
    }

    /** @inheritDoc */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement()
            && target.tagName == 'A') {

            const preview = target.getAttribute('data-preview'),
                linkText = DomUtils.getTextContent(target),
                resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return preview !== undefined && resourceTypeAttr == null && !StringUtils.isEmptyOrWhitespace(linkText) &&
                (LinkifyUtils.isUrlLike(linkText) || target.getAttribute('data-text') == linkText);
        }

        return false;
    }

    /**
     * Handles change event in the preview options: hidePreview, thumbnailId, showThumbnail properties
     * @param {hf.events.Event} e
     * @private
     */
    handlePreviewOptionChange_(e) {
        let field = e.getProperty('field');

        if (!this.previewedLink_) {
            return;
        }

        switch (field) {
            case 'hidePreview':
                this.hidePreview();
                this.getFieldObject().dispatchChange();
                break;

            case 'showThumbnail':
                const show = e.getProperty('newValue');
                if (show) {
                    const config = e.getTarget().getConfiguration();
                    this.previewedLink_.setAttribute('data-tbi', config['thumbnailId']);
                    this.previewedLink_.setAttribute('data-stb', '1');
                } else {
                    this.previewedLink_.removeAttribute('data-tbi');
                    this.previewedLink_.setAttribute('data-stb', '0');
                }
                this.getFieldObject().dispatchChange();

                break;

            case 'thumbnailId':
                const thumbnailId = e.getProperty('newValue');

                if (thumbnailId) {
                    this.previewedLink_.setAttribute('data-tbi', thumbnailId);
                    this.getFieldObject().dispatchChange();
                }
                break;

            default:
                break;
        }

        /* avoid losing focus from editor */
        const editor = this.getFieldObject();
            editor.focus();
        if (field ==  'showThumbnail' && userAgent.engine.isGecko()) {
            // If the cursor is at the beginning of the field, make sure that it is
            // in the first user-visible line break, e.g.,
            // no selection: <div><p>...</p></div> --> <div><p>|cursor|...</p></div>
            // <div>|cursor|<p>...</p></div> --> <div><p>|cursor|...</p></div>
            // <body>|cursor|<p>...</p></body> --> <body><p>|cursor|...</p></body>

            field = editor.getElement();
            const range = editor.getRange();

            if (range) {
                let focusNode = range.getFocusNode();
                if (range.getFocusOffset() == 0 && (!focusNode || focusNode.parentNode == field || focusNode.parentNode.tagName == 'A')) {
                    TextDomRange.select(DomRangeUtils.createCaret(EditorRange.getRightMostLeaf(field), 0).getBrowserRange());
                }
            }
        }
    }

    /** @inheritDoc */
    reEvaluate(anchor) {
        super.reEvaluate(anchor);
        const linkText = DomUtils.getTextContent(anchor);

        if (!StringUtils.isEmptyOrWhitespace(linkText) && LinkifyUtils.isUrlLike(linkText)) {
            /* make sure we detach previewedLink_ from node if changed */
            if (this.previewedLink_ != null && this.previewedLink_.getAttribute('href') != this.previewedUrl_) {
                const preview = this.previewedLink_.cloneNode(false);
                if (!StringUtils.isEmptyOrWhitespace(this.previewedUrl_)) {
                    preview.setAttribute('href', this.previewedUrl_);
                }
                    preview.setAttribute('data-unattached', '1');

                /* make sure the text content is correct */
                preview.textContent = /** @type {string} */(this.previewedUrl_);

                this.previewedLink_.setAttribute('data-preview', '0');
                this.previewedLink_.removeAttribute('data-tbi');
                this.previewedLink_.removeAttribute('data-stb');

                this.previewedLink_ = preview;
            }
        }
    }

    /** @inheritDoc */
    handleAnchorUpdate(e) {
        const refreshPreview = this.targetTag != null && this.targetTag == this.previewedLink_;

        if (refreshPreview) {
            const popup = this.getUpdateBubble_(),
                model = popup.getModel();

            if (this.lastPreviewedRequestUrl_ != model['anchor']) {
                /* make sure we detach previewedLink_ from node if changed */
                const preview = this.previewedLink_.cloneNode(false);
                    preview.setAttribute('href', this.previewedUrl_);
                    preview.setAttribute('data-unattached', '1');

                /* make sure the text content is correct */
                preview.textContent = /** @type {string} */(this.previewedUrl_);

                this.previewedLink_.setAttribute('data-preview', '0');
                this.previewedLink_.removeAttribute('data-tbi');
                this.previewedLink_.removeAttribute('data-stb');

                this.previewedLink_ = preview;
            }
        }

        super.handleAnchorUpdate(e);
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (!KeysUtils.isTextModifyingKeyEvent(e)) {
            return false;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode();

        /* anchor node is children of an A element (use case: space after a pasted URL) */
        const rangeNodeParent = anchorNode.parentElement;
        let linkNode = null;

        if (rangeNodeParent != null && rangeNodeParent.tagName == 'A') {
            linkNode = rangeNodeParent;
        } else {
            const siblingNode = DomUtils.getNextElementSibling(anchorNode);
            if (siblingNode != null && siblingNode.tagName == 'A') {
                linkNode = siblingNode;
            }
        }

        if (linkNode != null) {
            const resourceTypeAttr = linkNode.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);
            if (resourceTypeAttr == null) {
                /* inside a decoded link, any change must re-evaluate it */
                this.reEvaluate(/** @type {Element} */(linkNode));
                return true;
            }
        }

        return false;
    }
};