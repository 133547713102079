import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {QueryableCache} from "./../../../../../hubfront/phpnoenc/js/cache/QueryableCache.js";
import {ObservableCollectionChangeAction} from "./../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {HgAppEvents} from "./../../app/Events.js";
import {AbstractService} from "./AbstractService.js";
import {PersonDataMapping} from "./datamapping/Person.js";
import {PersonEdit} from "./../model/person/PersonEdit.js";
import {PersonFull} from "./../model/person/PersonFull.js";
import {PersonShort} from "./../model/person/PersonShort.js";
import {Facet} from "./../model/common/Facet.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {
    EmailContactLabels,
    FaxContactLabels,
    PersonContactCapabilities,
    PersonTypes,
    PhoneContactLabels
} from "./../model/person/Enums.js";
import {Organization} from "./../model/person/Organization.js";
import {FacetTargets} from "./../model/common/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgAppConfig} from "./../../app/Config.js";

/**
 * Creates a new person service
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class PersonService extends AbstractService {
    constructor() {
        super();

        /**
         * Local cache for persons that are Hubgets users (teammates).
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.teammatesCache_;

        /**
         * A volatile cache of people representing the current snapshot of people' list.
         * @type {hf.cache.QueryableCache}
         * @private
         */
        this.peopleCache_;

        /**
         * Data portal used to sync create and update full person operation
         * @type {hf.data.DataPortal}
         * @protected
         */
        this.personFullDataPortal_;

        /**
         * Cached Promise for single person fetch
         * @type {Object.<string, Promise>}
         * @private
         */
        this.loadPersonPromise_;
    }

    /**
     * Load short person details of all people in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch people on.
     * @return {Promise}
     */
    loadPeopleList(fetchCriteria) {
        return this.handleErrors(this.loadPeopleInternal_(fetchCriteria), 'people_details_failure')
            .then((result) => { return this.onPeopleLoad_(result) });
    }

    /**
     * Load short person details of all people in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch people on.
     * @return {Promise}
     */
    loadPeople(fetchCriteria) {
        return this.handleErrors(this.loadPeopleInternal_(fetchCriteria), 'people_details_failure')
    }

    /**
     * Load short person details of all people matching the search criteria in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria The criteria to search people on.
     * @param {Object=} opt_searchOptions Other search options like 'excludeMe'
     * @return {Promise}
     */
    searchPeople(searchCriteria, opt_searchOptions) {
        return this.searchPeopleInternal_(searchCriteria, opt_searchOptions);
    }

    /**
     * Load short person details of all people matching the search criteria in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria The criteria to search people on.
     * @param {Object=} opt_searchOptions Other search options like 'excludeMe'
     * @return {Promise}
     */
    quickSearchPeople(searchCriteria, opt_searchOptions) {
        opt_searchOptions = opt_searchOptions || {};

        /* 1. for search operation the remote data source will return the results in a 'relevance' order;
         *    so no sorters should be sent in request to the remote data source;
         * 2. make sure the input search criteria is not altered, so clone it; */
        searchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(searchCriteria.clone());

        let searchTerm = searchCriteria.getSearchValue() || '';

        searchTerm = searchTerm.startsWith('@') ?
            searchTerm.substring(1) : searchTerm;

        if(StringUtils.isEmptyOrWhitespace(searchTerm)) {
            /* if no sorters exists then add a default one by fullName*/
            const sorters = searchCriteria.getSorters();
            if(sorters && sorters.length === 0) {
                searchCriteria.sortBy('fullName', SortDirection.ASC);
            }

            return this.loadPeopleInternal_(searchCriteria);
        }

        searchCriteria.setSearchValue(searchTerm);
        searchCriteria.setIsQuickSearch(true);

        return this.searchPeopleInternal_(searchCriteria, opt_searchOptions);
    }

    /**
     * Fetch short information on contact in address book having a specific number
     * Note: only
     * @param {string} phoneNumber
     * @return {Promise}
     */
    getPersonByPhoneNumber(phoneNumber) {
        if(StringUtils.isEmptyOrWhitespace(phoneNumber)) {
            return Promise.resolve(null);
        }

        /* try to identify the person based on the number, fetch only the most probably */

        /* firstly, look into the peers cache */
        const result = this.teammatesCache_.query({
            'filters': [{
                'predicate': function (person) {

                    let internalPhones = person.get('contact.phoneInternal');
                    if (internalPhones) {
                        internalPhones = internalPhones.getAll();
                        return internalPhones.some(function (phone) {
                            return phone['value'] == phoneNumber;
                        });
                    }

                    let phones = person.get('contact.phone');
                    if (phones) {
                        phones = phones.getAll();
                        return phones.some(function (phone) {
                            return phone['value'] == phoneNumber;
                        });
                    }

                    return false;
                }
            }]
        });

        if(result.getCount() > 0) {
            return Promise.resolve(result.getItems()[0]);
        }

        /* if not found in cache then ask from the remote service */
        const dataPortal = DataPortal.createPortal({
                'proxy': {
                    'type': DataProxyType.REST,
                    'endpoint': this.getEndpoint() + '/search/',
                    'dataMapper': PersonDataMapping.PersonShort,
                    'withCredentials': true
                }
            }),

            fetchCriteria = new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'contact.capability.value',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': phoneNumber
                    },
                    {
                        'filterBy': 'contact.capability.label',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': PersonContactCapabilities.PHONE
                    }
                ],
                'boost': [
                    {
                        'field': 'contact.phoneInternal.value',
                        'boost': 5,
                        'term': phoneNumber
                    }
                ],
                'fetchSize': 1
            });

        return this.handleErrors(dataPortal.load(PersonShort, fetchCriteria), 'people_details_failure')
            .then((queryResult) => {
                return this.extractSingleQueryResult(queryResult);
            });
    }

    /**
     * Retrieves a person by its id;
     * firstly it looks up into the local cache; if it's not found there then it tries to load it from the remote data source
     *
     * @param {string} personId The id of the person to search for.
     * @param {boolean=} opt_cacheLookupOnly Indicates whether the lookup must be done only in local cache; default false.
     * @return {Promise}
     */
    getPerson(personId, opt_cacheLookupOnly) {
        if(personId == null) {
            return Promise.resolve(null);
        }

        opt_cacheLookupOnly = opt_cacheLookupOnly || false;

        /* firstly, look up into the people's volatile cache, i.e. the current people list view */
        if(this.peopleCache_.contains(personId)) {
            return Promise.resolve(this.peopleCache_.get(personId));
        }

        /* IMPORTANT! Make sure that when called the method this.getPersonInternal_ gets the right personId and opt_cacheLookupOnly - see HG-8813 */
        return this.getPersonInternal_(personId, opt_cacheLookupOnly);
    }

    /**
     * Loads a person data model from  the remote data source.
     * @param {string} personId
     * @param {!function(new:hf.data.DataModel, !Object=)=} opt_personModelType The type of person data model to retrieve.
     * @returns {Promise}
     */
    loadPerson(personId, opt_personModelType) {
        const translator = Translator;

        let promisedResult;

        if(!StringUtils.isEmptyOrWhitespace(personId)) {
            opt_personModelType = opt_personModelType || PersonFull;

            if (this.loadPersonPromise_[personId] == null) {
                promisedResult = this.loadPersonPromise_[personId] = this.personFullDataPortal_.loadById(opt_personModelType, personId);

                promisedResult.finally(() => { delete this.loadPersonPromise_[personId]; });
            }
            else {
                promisedResult = /**@type {Promise}*/(this.loadPersonPromise_[personId]);
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('person_load_failure'))), 'person_load_failure');
    }

    /**
     * Update a contact in my address book.
     *
     * @param {!hg.data.model.person.PersonEdit} person The person model to be updated.
     * @return {Promise}
     */
    savePerson(person) {
        const translator = Translator;

        let promisedResult;

        if(person instanceof PersonEdit) {
            /* before saving accept all the changes done in edit mode */
            //person.endEdit(true);

            if(person.isSavable()) {
                promisedResult = this.personFullDataPortal_.save(person)
                    .then((saveResult) => {
                        return saveResult.created.length > 0 ? saveResult.created[0] : saveResult.updated[0];
                    });
            }
            else {
                promisedResult = Promise.resolve(null);
            }
        }

        return this.handleErrors(promisedResult || Promise.reject(new Error(translator.translate('person_save_failure'))), 'person_save_failure');
    }

    /**
     * Save avatar for a person
     * !!Note: method introduced because of the splitting in 2 of person data saving (https://jira.4psa.me/browse/HG-2094)
     * @param {!hf.data.DataModel} person The person model to be updated.
     * @return {Promise}
     * @suppress {visibility}
     */
    saveAvatar(person) {
        if (!person.isFieldDirty('avatar')) {
            /* unchanged avatar (fileId unchanges, avatar could be cropped ) */
            return Promise.resolve(person['avatar']);
        }

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/' + person['personId'] + '/',
                'withCredentials': true
            }
        });

        const avatarJSONObject = {
            'avatar': person['avatar']
        };

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.PUT, null, avatarJSONObject), 'update_avatar_failure');
    }

    /**
     * Removes contacts from my address book.
     *
     * @param {Array} personsIdsToDelete An array with the personIds to be deleted.
     * @return {Promise}
     */
    deletePeople(personsIdsToDelete) {
        if (!BaseUtils.isArray(personsIdsToDelete) || personsIdsToDelete.length <= 0) {
            throw new Error('The \'personsIdsToDelete\' parameter must be a non-empty array.');
        }

        return this.handleErrors(this.personFullDataPortal_.destroy(personsIdsToDelete), 'remove_contacts_failure');
    }

    /**
     * Loads dynamic facet for the people module
     * @return {Promise}
     */
    readDynamicFacet() {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/facet/',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Facet, {}), 'load_facets_failure')
            .then((result) => {
                const items = result.getItems();
                items.forEach(function(item){
                    item['target'] = FacetTargets.PERSON;
                });

                return result;
            });
    }

    /**
     * Loads static facet for the people module
     * @return {Array.<hg.data.model.common.Facet>}
     */
    readStaticFacet() {
        const staticFacet = [];

        staticFacet.push(new Facet({
            'uid'       : PersonStaticFacets.COLLEAGUES,
            'target'    : FacetTargets.PERSON,
            'category'  : 'static',
            'filter'    : {
                'filter'    : [
                    {
                        'filterBy'      : 'type',
                        'filterOp'      : FilterOperators.EQUAL_TO,
                        'filterValue'   : PersonTypes.COWORKER
                    }
                ],
                'sortField': 'fullName',
                'sortOrder': SortDirection.ASC
            }
         }));

        staticFacet.push(new Facet({
            'uid'       : PersonStaticFacets.CUSTOMERS,
            'target'    : FacetTargets.PERSON,
            'category'  : 'static',
            'filter'    : {
                'filter'    : [
                    {
                        'filterBy'      : 'type',
                        'filterOp'      : FilterOperators.EQUAL_TO,
                        'filterValue'   : PersonTypes.CUSTOMER
                    }
                ],
                'sortField': 'fullName',
                'sortOrder': SortDirection.ASC
            }
        }));

        staticFacet.push(new Facet({
            'uid'       : PersonStaticFacets.ALL,
            'target'    : FacetTargets.PERSON,
            'category'  : 'static',
            'filter'    : {
                'sortField': 'fullName',
                'sortOrder': SortDirection.ASC
            }
        }));

        return staticFacet;
    }

    /**
     * Loads the list of organizations for the suggestion feature.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to load Organization names on
     * @return {Promise}
     */
    loadSystemOrganizations(fetchCriteria) {
        if (fetchCriteria.getFilters().length <= 0) {
            throw new Error('Loading System\'s Organizations requires at least one Filter Descriptor.');
        }

        const nameFilter = fetchCriteria.getFilters()[0];

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/organization/',
                'dataMapper': PersonDataMapping.Organization,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(Organization, {'inputName': nameFilter.filterValue}), 'load_organizations_failure');
    }

    /**
     * Sends a new email.
     *
     * @param {!hg.data.model.common.Email} email The email model to send to the server.
     * @return {Promise}
     */
    sendEmail(email) {
        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/email',
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.invoke(HTTPVerbs.POST, null, email.toJSONObject()), 'send_email_failure');
    }

    /**
     * Returns the list of available phone labels
     * @return {Array}
     */
    getPhoneLabels() {
        const translator = Translator,
            labels = [];

        for (let key in PhoneContactLabels) {
            let value = PhoneContactLabels[key];
            labels.push({
                'value': value,
                'text': translator.translate(value.toLowerCase())
            });
        }

        return labels;
    }

    /**
     * Returns the list of available email labels
     * @return {Array}
     */
    getEmailLabels() {
        let translator = Translator,
            labels = [];

        for (let key in EmailContactLabels) {
            let value =  EmailContactLabels[key];
            labels.push({
                'value': value,
                'text': translator.translate(value.toLowerCase())
            });
        }

        return labels;
    }

    /**
     * Returns the list of available fax labels
     * @return {Array}
     */
    getFaxLabels() {
        let translator = Translator,
            labels = [];

        for (let key in FaxContactLabels) {
            let value = FaxContactLabels[key];
            labels.push({
                'value': value,
                'text': translator.translate(value.toLowerCase())
            });
        }

        return labels;
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.PersonService');
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        opt_config = opt_config || {};

        opt_config['endpoint'] = HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/person';

        super.init(opt_config);

        this.teammatesCache_ = new QueryableCache();
        this.peopleCache_ = new QueryableCache();

        this.loadPersonPromise_ = {};

        /* initialize a data portal to be used on full person fetch and update operations */
        this.personFullDataPortal_ = DataPortal.createPortal({
            'proxy'     : {
                'type'              : DataProxyType.REST,
                'endpoint'          : opt_config['endpoint'],
                'dataMapper'        : PersonDataMapping.PersonEdit,
                'withCredentials'   : true
            }
        });
    }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.loadPersonPromise_);
        this.loadPersonPromise_ = null;

        BaseUtils.dispose(this.personFullDataPortal_);
        this.personFullDataPortal_ = null;

        BaseUtils.dispose(this.teammatesCache_);
        this.teammatesCache_ = null;

        BaseUtils.dispose(this.peopleCache_);
        this.peopleCache_ = null;
    }

    /**
     * @inheritDoc
     */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_CREATE, this.handleNewUser_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE, this.handleUpdateUser_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_UPDATE, this.handleUpdateCustomer_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_PERSON_DELETE, this.handleDeleteCustomer_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_);
    }

    /**
     * Retrieves a person by its id;
     * firstly it looks up into the teammates cache; if it's not found there then it tries to load it from the remote data source
     *
     * @param {string} personId The id of the person to search for.
     * @param {boolean=} opt_cacheLookupOnly Indicates whether the lookup must be done only in local cache; default false.
     * @return {Promise}
     * @private
     */
    getPersonInternal_(personId, opt_cacheLookupOnly) {
        /* secondly, look up into the peers cache */
        const peer = this.getPeer_(personId);
        if (peer != null) {
            return Promise.resolve(peer);
        }

        if (opt_cacheLookupOnly) {
            return Promise.resolve(null);
        }
        else {
            /* ...if not found into the peers cache then fetch it from the remote data source;
             *  use PersonService.readById (personFullDataPortal_) because is faster...but wrap the result into a hg.data.model.person.PersonShort object */
            return this.loadPerson(personId, PersonShort);
        }
    }

    /**
     * Gets a peer by a provided id.
     *
     * @param {string} personId The id of the peer to search for.
     * @return {hg.data.model.person.PersonShort}
     * @private
     */
    getPeer_(personId) {
        if(personId == null) {
            return null;
        }

        if(this.teammatesCache_.contains(personId)) {
            return /**@type {hg.data.model.person.PersonShort}*/(this.teammatesCache_.get(personId));
        }

        return null;
    }

    /**
     * Fetch short information on contact in address book having a specific userId
     * @param {string} userId
     * @return {hg.data.model.person.PersonShort}
     */
    getPeerByUserId_(userId) {
        if (StringUtils.isEmptyOrWhitespace(userId)) {
            return null;
        }

        if (HgPersonUtils.isMe(userId)) {
            userId = HgPersonUtils.ME;
        }

        const result = this.teammatesCache_.query({
            'filters': [{
                'filterBy': 'userId',
                'filterOp': FilterOperators.EQUAL_TO,
                'filterValue': userId
            }]
        });

        return result.getCount() > 0 ? result.getItems()[0] : null;
    }

    /**
     * Load short person details of all people in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The criteria to fetch people on.
     * @return {Promise}
     * @private
     */
    loadPeopleInternal_(fetchCriteria) {
        // exclude me from reads
        fetchCriteria.filter({
            'filterBy'   : 'personId',
            'filterOp'   : FilterOperators.NOT_EQUAL_TO,
            'filterValue': HgPersonUtils.ME
        });

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint(),
                'dataMapper': PersonDataMapping.PersonShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(PersonShort, fetchCriteria), 'people_details_failure');
    }

    /**
     * Load short person details of all people in the address book.
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria The criteria to fetch people on.
     * @param {Object=} opt_searchOptions Other search options like 'excludeMe'
     * @return {Promise}
     * @private
     */
    searchPeopleInternal_(searchCriteria, opt_searchOptions) {
        opt_searchOptions = opt_searchOptions || {};

        const excludeMe = BaseUtils.isBoolean(opt_searchOptions['excludeMe']) ? opt_searchOptions['excludeMe'] : true;

        /* exclude me from search results */
        if(excludeMe) {
            searchCriteria.filter({
                'filterBy': 'personId',
                'filterOp': FilterOperators.NOT_EQUAL_TO,
                'filterValue': HgPersonUtils.ME
            });
        }

        const excludeDisabled = BaseUtils.isBoolean(opt_searchOptions['excludeDisabled']) ? opt_searchOptions['excludeDisabled'] : false;
        searchCriteria.filter({
            'filterBy': 'status',
            'filterOp': FilterOperators.CONTAINED_IN,
            'filterValue': excludeDisabled ? ['OPEN'] : ['OPEN', 'CLOSED']
        });

        /* 1. for search operation the remote data source will return the results in a 'relevance' order;
         *    so no sorters should be sent in request to the remote data source;
         * 2. make sure the input search criteria is not altered, so clone it; */
        searchCriteria = /**@type {!hf.data.criteria.FetchCriteria}*/(searchCriteria.clone());
        searchCriteria.clearSorters();

        const dataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': this.getEndpoint() + '/search/',
                'dataMapper': PersonDataMapping.PersonShort,
                'withCredentials': true
            }
        });

        return this.handleErrors(dataPortal.load(PersonShort, searchCriteria), 'people_details_failure');
    }

    /**
     *
     * @param {hf.data.QueryDataResult} result
     * @private
     */
    onPeopleLoad_(result) {
        const people = result.getItems();
        let i = 0;
        const len = people.length;
        for(; i < len; i++) {
            const person = /**@type {hf.data.DataModel}*/ (people[i]),
                uid = /**@type {hf.data.DataModel}*/ (people[i]).getUId();

            // check whether the person exists in peers cache
            if(this.teammatesCache_.contains(uid)) {
                const existingPeer = /**@type {hf.data.DataModel}*/(this.teammatesCache_.get(uid));

                // merge existing person with the new person's data
                //existingPeer.loadData(person.toJSONObject());

                people[i] = existingPeer;
            }

            /* add the person into the people 'volatile' cache */
            people[i] = this.updatePeopleVolatileCache_(people[i], true);
        }

        return new QueryDataResult({
            'items': people,
            'totalCount': result.getTotalCount(),
            'nextChunk':  result.getNextChunk(),
            'prevChunk':  result.getPrevChunk()
        });
    }

    /**
     *
     * @param {hg.data.model.person.PersonShort} person
     * @param {boolean=} opt_silent
     * @return {hg.data.model.person.PersonShort}
     * @private
     */
    updatePeopleVolatileCache_(person, opt_silent) {
        const uid = person.getUId();
        let changeAction;

        if(!this.peopleCache_.contains(uid)) {
            this.peopleCache_.set(uid, person);
            person.acceptChanges();

            changeAction = ObservableCollectionChangeAction.ADD;
        }
        else {
            const existingPerson = /** @type {hg.data.model.person.PersonShort} */ (this.peopleCache_.get(uid));
            if(existingPerson != person) {
                existingPerson.loadData(person.toJSONObject());
            }

            person = existingPerson;

            changeAction = ObservableCollectionChangeAction.REPLACE;
        }

        if(!opt_silent) {
            this.dispatchAppEvent(HgAppEvents.PEOPLE_CHANGE,
                { 'people': [person], 'changeAction': changeAction });
        }

        return person;
    }

    /**
     *
     * @param {Array} peers
     * @private
     */
    loadPeersInCache_(peers) {
        peers.forEach(function(peer) {
            if(peer['type'] == PersonTypes.COWORKER) {
                const peerId = /**@type {hf.data.DataModel}*/ (peer).getUId();

                if(HgPersonUtils.isMe(peer['userId'])) {
                    peer['personId'] = HgPersonUtils.ME;
                }

                // if the peer is found in cache then update it, otherwise add it to the cache
                if(this.teammatesCache_.contains(peerId)) {
                    const exitingPeer = /** @type {hf.data.DataModel} */ (this.teammatesCache_.get(peerId));
                    exitingPeer.loadData(peer.toJSONObject());
                }
                else {
                    this.teammatesCache_.set(peerId, peer);
                }
            }

        }, this);

        const currentUser = HgCurrentUser,
            me = new PersonShort(currentUser.toJSONObject());

        /* also add in cache the Current User (@me) if it's not already there */
        if(!this.teammatesCache_.contains(me.getUId())) {
            this.teammatesCache_.set(me.getUId(), me);
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleNewUser_(e) {
        /* payload is a User dataType */
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && payload['userId'] != null) {
            const payloadPerson = payload['person'];

            if (payloadPerson != null) {
                const payloadPersonAsModel = /**@type {!Object}*/(ObjectMapper.getInstance().transform(payloadPerson, PersonDataMapping.PersonShort['read'])),
                    newPeer = new PersonShort();

                newPeer.loadData(payloadPersonAsModel);

                this.loadPeersInCache_([newPeer]);

                this.updatePeopleVolatileCache_(newPeer);

                this.dispatchAppEvent(HgAppEvents.USER_NEW, payloadPersonAsModel);
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateUser_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && payload['userId'] != null) {
            const payloadPerson = payload['person'];

            /* if person field is defined, then update all person details */
            if (payloadPerson != null) {
                const userId = payloadPerson['userId'];

                /* secondly update the peer from the peers cache.
                   NOTE: the peers cache contains the Current User also - a peer that has current user details */

                const person = this.getPeerByUserId_(userId);
                if(person != null) {
                    const personData = /**@type {!Object}*/(ObjectMapper.getInstance()
                        .transform(payloadPerson, PersonDataMapping.PersonShort['read']));

                    const prevStatus = person['status'],
                        newStatus = personData['status'];

                    if(HgPersonUtils.isMe(userId)) {
                        personData['personId'] = HgPersonUtils.ME;
                    }

                    person.loadData(personData);

                    this.updatePeopleVolatileCache_(person);

                    if (prevStatus !== newStatus) {
                        this.dispatchAppEvent(HgAppEvents.USER_STATUS_CHANGE, {
                            'userId': userId,
                            'status': newStatus
                        });
                    }

                    /* @ralucac: dispatch event even if not in cache, new person appeared on the block
                     * new registered person can be displayed in people list before register is complete and create event is dispatched
                     * so that she can be in cache, protection against broken flow!! */
                    this.dispatchAppEvent(HgAppEvents.USER_UPDATE, personData);
                }
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateCustomer_(e) {
        let payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && payload['personId'] != null) {
            payload = /**@type {!Object}*/(ObjectMapper.getInstance().transform(payload, PersonDataMapping.PersonShort['read']));

            const customer = new PersonShort(payload);

            this.updatePeopleVolatileCache_(customer);
        }
        else {
            this.dispatchAppEvent(HgAppEvents.PEOPLE_CHANGE,
                { 'changeAction': ObservableCollectionChangeAction.RESET });
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleDeleteCustomer_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if(payload != null && BaseUtils.isArray(payload['deleted'])) {
            const deleted = /**@type {Array}*/(payload['deleted']);

            deleted.forEach(function(person) {
                this.peopleCache_.remove(person['personId']);
            }, this);

            if(deleted.length > 0) {
                this.dispatchAppEvent(HgAppEvents.PEOPLE_CHANGE,
                    { 'people': deleted, 'changeAction': ObservableCollectionChangeAction.REMOVE });
            }
        }
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES: reset the volatile people list');

        /* invalidate the volatile cache */
        this.peopleCache_.clear();
    }
};
/**
 * The lifetime of the cache on the last personFull fetched from the server
 * @type {number}
 * @private
 * @const
 */
PersonService.PERSONFULL_CACHE_LIFETIME = 1000*60*3; // 3 minutes

/**
 * The static facet names in the person module.
 * @enum {string}
 */
export const PersonStaticFacets = {
    /**
     * All the people in the list
     */
    ALL : "all",

    /**
     * Only the colleagues in the list
     */
    COLLEAGUES : "coworker",

	/**
	 * Only the customers in the list
	 */
	CUSTOMERS : "customer"
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new PersonService();

export default instance;