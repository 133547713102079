import {ObservableCollection} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";


import {HgAppEvents} from "./../../../app/Events.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";
import {CallHistoryBusyContext} from "./../Common.js";
import {CallViewViewmodel} from "./../viewmodel/CallView.js";
import {HgAppViews} from "./../../../app/Views.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {ChUiViewDialogView} from "./../view/ViewDialog.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PhoneHistoryService from "../../../data/service/PhoneHistoryService.js";

/**
 * Creates a new CallHistory view presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class ChUiViewDialogPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);

        /**
         * Reference to phone history service
         * @type {hg.data.service.PhoneHistoryService}
         * @private
         */
        this.callHistoryService_;
    }

    /**
     * Remove a media resouce for a call history item
     * @param {string} phoneHistoryMediaId The id of call resource media to be removed
     * @return {Promise}
     */
    removeCallMediaResource(phoneHistoryMediaId) {
        return this.callHistoryService_.deleteCallResource([phoneHistoryMediaId])
            .then((result) => {
                const removedPhoneHistoryMediaId = result[0],
                    model = this.getModel();

                if (removedPhoneHistoryMediaId == null || model == null) {
                    return;
                }

                const phoneHistoryMediaCollection = model['mediaCollection'];

                if (phoneHistoryMediaCollection instanceof ObservableCollection) {
                    const removedPhoneHistoryMedia = phoneHistoryMediaCollection.find(function (phoneHistoryMedia) {
                        return phoneHistoryMedia['phoneHistoryMediaId'] === removedPhoneHistoryMediaId;
                    });

                    /* remove the item on client side */
                    if (removedPhoneHistoryMedia != null) {
                        phoneHistoryMediaCollection.remove(removedPhoneHistoryMedia);
                    }
                }
            })
            .catch((err) => {
                this.setError(/** @type{Object} */(err), CallHistoryBusyContext.DELETE_MEDIA_RESOURCE);
            });
    }

    /**
     * Dispatch CALL_PERSON event in order to call the person
     * @param {Object} callInfo
     */
    quickCallParty(callInfo) {
        this.dispatchEvent(HgAppEvents.CALL_PERSON, callInfo);
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.callHistoryService_ = PhoneHistoryService;
    }

    /** @inheritDoc */
    getViewName() {
        return HgAppViews.CALLHISTORY_VIEW;
    }

    /** @inheritDoc */
    loadView() {
        return new ChUiViewDialogView();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();

        this.callHistoryService_ = null;
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        this.loadModel();
    }

    /** @inheritDoc */
    onUpdate(previousAppState, currentAppState) {
        super.onUpdate(previousAppState, currentAppState);

        this.loadModel();
    }

    /**
     * @protected
     */
    loadModel() {
        const model = this.getModel(),
            payload = this.getState().getPayload();

        this.executeAsync(
            this.loadModelAsync_,
            this.onModelLoaded,
            function(err) {
                this.setModel(null);
                return err;
            },
            CommonBusyContexts.LOAD
        );
    }

    /**
     * Loads the model with the IDs in the payload
     * @returns {Promise}
     * @private
     */
    loadModelAsync_() {
        const payload = this.getState().getPayload(),
            phoneHistoryId = payload ? /** @type {string} */ (payload['phoneHistoryId']) : "",
            phoneHistoryViewId = payload ? /** @type {string} */ (payload['phoneHistoryViewId']) : "";

        return this.callHistoryService_.loadCallViewDetails(phoneHistoryId, phoneHistoryViewId);
    }

    /**
     *
     * @param {*} model
     * @protected
     */
    onModelLoaded(model) {
        if (model == null) {
            const translator = Translator;

            let errMessage = translator.translate('preview_load_failure');
                errMessage = errMessage + ' ' + translator.translate('might_been_removed');

            this.setModel(null);

            this.setError(new Error(errMessage), CommonBusyContexts.LOAD);
        }
        else {
            this.setModel(new CallViewViewmodel({'callDetails': model}));

            this.openDialog();
        }
    }
};