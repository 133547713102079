import { BrowserEvent } from './BrowserEvent.js';
import { EventHandler } from './EventHandler.js';
import { EventTarget } from './EventTarget.js';
import { BrowserEventType } from './EventType.js';

/**
 * A files drag and drop event detector. Gets an {@code element} as parameter
 * and fires {@code FileDropHandlerEventType.DROP} event when files
 * are dropped in the {@code element}.
 *
 * @augments {EventTarget}
 * @final
 
 *
 */
export class FileDropHandler extends EventTarget {
    /**
     * @param {Element|Document} element The element or document to listen on.
     * @param {boolean=} opt_preventDropOutside Whether to prevent a drop on the
     *     area outside the {@code element}. Default false.
     */
    constructor(element, opt_preventDropOutside) {
        super();

        /**
         * Handler for drag/drop events., cum e aici document
         *
         * @type {!hf.events.EventHandler<!hf.events.FileDropHandler>}
         * @private
         */
        this.eventHandler_ = new EventHandler(this);

        let doc = element;
        if (opt_preventDropOutside) {
            doc = document;
        }

        // Add dragenter listener to the owner document of the element.
        this.eventHandler_.listen(
            doc, BrowserEventType.DRAGENTER, this.onDocDragEnter_
        );

        // Add dragover listener to the owner document of the element only if the
        // document is not the element itself.
        if (doc != element) {
            this.eventHandler_.listen(
                doc, BrowserEventType.DRAGOVER, this.onDocDragOver_
            );
        }

        // Add dragover and drop listeners to the element.
        this.eventHandler_.listen(
            element, BrowserEventType.DRAGOVER, this.onElemDragOver_
        );
        this.eventHandler_.listen(
            element, BrowserEventType.DROP, this.onElemDrop_
        );

        /**
         * Whether the drag event contains files. It is initialized only in the
         * dragenter event. It is used in all the drag events to prevent default actions
         * only if the drag contains files. Preventing default actions is necessary to
         * go from dragenter to dragover and from dragover to drop. However we do not
         * always want to prevent default actions, e.g. when the user drags text or
         * links on a text area we should not prevent the browser default action that
         * inserts the text in the text area. It is also necessary to stop propagation
         * when handling drag events on the element to prevent them from propagating
         * to the document.
         *
         * @private
         * @type {boolean}
         */
        this.dndContainsFiles_ = this.dndContainsFiles_ === undefined ? false : this.dndContainsFiles_;
    }

    /** @override */
    disposeInternal() {
        super.disposeInternal();
        this.eventHandler_.dispose();
    }

    /**
     * Dispatches the DROP event.
     *
     * @param {hf.events.BrowserEvent} e The underlying browser event.
     * @private
     */
    dispatch_(e) {
        const event = new BrowserEvent(e.getBrowserEvent());
        event.type = FileDropHandlerEventType.DROP;
        this.dispatchEvent(event);
    }

    /**
     * Handles dragenter on the document.
     *
     * @param {hf.events.BrowserEvent} e The dragenter event.
     * @private
     */
    onDocDragEnter_(e) {
        const dt = e.getBrowserEvent().dataTransfer;
        // Check whether the drag event contains files.
        this.dndContainsFiles_ = !!(
            dt && ((dt.types && (dt.types.includes('Files')
            || dt.types.includes('public.file-url')))
            || (dt.files && dt.files.length > 0)));
        // If it does
        if (this.dndContainsFiles_) {
            // Prevent default actions.
            e.preventDefault();
        }
    }

    /**
     * Handles dragging something over the document.
     *
     * @param {hf.events.BrowserEvent} e The dragover event.
     * @private
     */
    onDocDragOver_(e) {
        if (this.dndContainsFiles_) {
            // Prevent default actions.
            e.preventDefault();
            // Disable the drop on the document outside the drop zone.
            const dt = e.getBrowserEvent().dataTransfer;
            dt.dropEffect = 'none';
        }
    }

    /**
     * Handles dragging something over the element (drop zone).
     *
     * @param {hf.events.BrowserEvent} e The dragover event.
     * @private
     */
    onElemDragOver_(e) {
        if (this.dndContainsFiles_) {
            // Prevent default actions and stop the event from propagating further to
            // the document. Both lines are needed! (See comment above).
            e.preventDefault();
            e.stopPropagation();
            // Allow the drop on the drop zone.
            const dt = e.getBrowserEvent().dataTransfer;

            // IE bug #811625 (https://goo.gl/UWuxX0) will throw error SCRIPT65535
            // when attempting to set property effectAllowed on IE10+.
            // See more: https://github.com/google/closure-library/issues/485.
            try {
                dt.effectAllowed = 'all';
            } catch (err) {
            }
            dt.dropEffect = 'copy';
        }
    }

    /**
     * Handles dropping something onto the element (drop zone).
     *
     * @param {hf.events.BrowserEvent} e The drop event.
     * @private
     */
    onElemDrop_(e) {
        // If the drag and drop event contains files.
        if (this.dndContainsFiles_) {
            // Prevent default actions and stop the event from propagating further to
            // the document. Both lines are needed! (See comment above).
            e.preventDefault();
            e.stopPropagation();
            // Dispatch DROP event.
            this.dispatch_(e);
        }
    }
}

/**
 * The types of events fired by this class.
 *
 * @enum {string}
 */
export const FileDropHandlerEventType = {
    DROP: BrowserEventType.DROP
};
