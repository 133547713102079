import {UIComponent} from "./../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {ResourceActionControl} from "./ResourceActionControl.js";
import {HgResourceActionTypes, HgResourceCanonicalNames} from "./../../data/model/resource/Enums.js";
import {HgButtonUtils} from "./button/Common.js";
import {ElementResizeHandlerEventType} from "./../../../../../hubfront/phpnoenc/js/events/elementresize/Common.js";
import {ElementResizeHandler} from "./../../../../../hubfront/phpnoenc/js/events/elementresize/ElementResizeHandler.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new File metacontent plugin
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ResponsiveFileActionControl extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
     *  @param {Promise} opt_config.mediaViewportWidthGetter When media viewport cannot be computed, the mediaViewportWidthGetter is called
     *  @param {hf.events.ElementResizeHandler|Function} opt_config.mediaViewportResizeSensor Resize sensor for media viewport, sometimes viewport wrapper dictated the resize and actual media viewport cannot sense it
     *  @param {!Array.<HgResourceActionTypes>=} opt_config.allowedActions
     *  @param {?hg.HgButtonUtils.DisplayLayout|number=} opt_config.defaultToolbarLayout
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.ResourceActionControl}
         * @private
         */
        this.resourceActionControl_ = this.resourceActionControl_ === undefined ? null : this.resourceActionControl_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-file-action-btnstack';
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        if (StringUtils.isEmptyOrWhitespace(opt_config['defaultToolbarLayout'])) {
            opt_config['defaultToolbarLayout'] = HgButtonUtils.DisplayLayout.INLINE;
        }

        super.init(opt_config);

        const translator = Translator,
            baseCSSClass = this.getBaseCSSClass();

        /* insert by default resource download option */
        opt_config['allowedActions'] = opt_config['allowedActions'] || [HgResourceActionTypes.SHARE];
        if (!opt_config['allowedActions'].includes(HgResourceActionTypes.DOWNLOAD)) {
            opt_config['allowedActions'].push(HgResourceActionTypes.DOWNLOAD);
        }

        this.resourceActionControl_ = new ResourceActionControl({
            'allowedActions' : opt_config['allowedActions'],
            'layout'         : opt_config['defaultToolbarLayout']
        });
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.resourceActionControl_, {'set': this.resourceActionControl_.setModel}, {
            'converter': {
                'sourceToTargetFn': function (filePreview) {
                    if (filePreview) {
                        filePreview = /** @type {hg.data.model.file.FileMeta} */(filePreview);

                        return {
                            'resourceId'    : filePreview['id'],
                            'resourceType'  : HgResourceCanonicalNames.FILE,
                            'meta'          : filePreview
                        };
                    }

                    return null;
                }
            }
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.resourceActionControl_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.adjustLayout();

        const cfg = this.getConfigOptions();
        if (cfg['resizeSensor'] != null && cfg['resizeSensor'] instanceof ElementResizeHandler) {
            this.getHandler()
                .listen(cfg['resizeSensor'], ElementResizeHandlerEventType.RESIZE, this.handleResize)
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        /*var cfg = this.getConfigOptions();
        if (cfg['resizeSensor'] != null && cfg['resizeSensor'] instanceof hf.events.ElementResizeHandler) {
            cfg['resizeSensor'].removeEventListener(ElementResizeHandlerEventType.RESIZE, this.handleResize, true, this);
        }*/
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.resourceActionControl_);
        this.resourceActionControl_ = null;
    }

    /**
     * @return {hg.HgButtonUtils.DisplayLayout}
     * @protected
     */
    computeLayout() {
        // todo: we need to use mediaViewportWidthGetter to determine if we need to display the buttons inline or in a bubble

        const layout = this.getConfigOptions()['defaultToolbarLayout'],
            element = this.getElement(),
            parentElement = element ? element.parentNode : null;

        // if(parentElement) {
        //     layout = parentElement.clientWidth < 100 || parentElement.clientHeight < 100 ?
        //         hg.HgButtonUtils.DisplayLayout.BUBBLE : layout;
        // }

        return layout;
    }

    /**
     * @protected
     */
    adjustLayout() {
        // todo: adjust layout, we might need to display action buttons in a bubble or back inline,
        // basically we need to hide downloadButton and update the allowedOperations for ResourceActionControl
        const layout = this.computeLayout();
        this.resourceActionControl_.setLayout(layout);
    }

    /** @inheritDoc */
    handleMouseDown(e) {
        super.handleMouseDown(e);

        e.stopPropagation();
    }

    /** @inheritDoc */
    setHighlighted(highlighted) {
        super.setHighlighted(highlighted);

        this.resourceActionControl_.setHighlighted(highlighted);
    }

    /**
     * @param {hf.events.Event} e
     * @protected
     */
    handleResize(e) {
        this.adjustLayout();
    }

    /**
     * Instructs the View to display or hide the 'Busy' state.
     *
     * @param {boolean} isBusy Whether to mark the View as busy or idle.
     * @param {*=} opt_busyContext Contains information about the context that triggered the entering into the 'Busy' state.
     */
    setBusy(isBusy, opt_busyContext) {
        this.enableIsBusyBehavior(isBusy, opt_busyContext);
    }

    /**
     * Enables/disables the 'is busy' behavior.
     * This method will be overridden by the inheritors if they need to provide a custom 'is busy' behavior.
     * Currently, this method implements the default 'is busy' behavior.
     *
     * @param {boolean} enable Whether to enable the 'isBusy' behavior
     * @param {*=} opt_busyContext Contains information about the reason that triggered the entering into the 'Busy' state.
     * @protected
     */
    enableIsBusyBehavior(enable, opt_busyContext) {
        if (this.isInDocument()) {
            this.resourceActionControl_.setBusy(enable, opt_busyContext);
        }
    }
};