import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {EditorCommandType, EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {RegExpUtils} from "./../../../../../../../hubfront/phpnoenc/js/regexp/regexp.js";
import {KeyCodes, KeysUtils} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new TextFormatter plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgTextFormatterEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'TextFormatter';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.TEXT_FORMATTER;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        pastedContent = HgMetacontentUtils.decodeStyleTag(pastedContent, HgMetacontentUtils.StyleTag.BOLD);
        pastedContent = HgMetacontentUtils.decodeStyleTag(pastedContent, HgMetacontentUtils.StyleTag.ITALIC);
        pastedContent = HgMetacontentUtils.decodeStyleTag(pastedContent, HgMetacontentUtils.StyleTag.UNDERLINE);

        const regexp = RegExpUtils.RegExp('<img class="emojione (.*?)">', 'gi');

        let lastMatchPos = 0,
            nextMatchPos = 0;
        pastedContent.replace(regexp, (match) => {
            nextMatchPos = nextMatchPos != 0 ? nextMatchPos : pastedContent.indexOf(match);

            const beforeLastMatch = pastedContent.substring(0, lastMatchPos),
                firstPart = this.style_(pastedContent.substring(lastMatchPos, nextMatchPos)),
                lastPart = ((pastedContent.substring(nextMatchPos + match.length).match(regexp)) != null ? pastedContent.substring(nextMatchPos + match.length) : this.style_(pastedContent.substring(nextMatchPos + match.length)));

            lastMatchPos = beforeLastMatch.length + firstPart.length + match.length;
            nextMatchPos = lastPart.match(regexp) ? lastMatchPos + lastPart.indexOf(lastPart.match(regexp)[0]) : 0;

            pastedContent = beforeLastMatch + firstPart + match + lastPart;
            return pastedContent;
        });

        return pastedContent;
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        let formattedContent = HgMetacontentUtils.normalizeStyleTags(content);
        formattedContent = HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.UNDERLINE);
        formattedContent = HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.BOLD);
        return HgMetacontentUtils.encodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.ITALIC);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and format the text according to metacontent tags
        const element = field.getElement();
        if (element) {
            let formattedContent = HgMetacontentUtils.decodeStyleTag(element.innerHTML, HgMetacontentUtils.StyleTag.UNDERLINE);
            formattedContent = HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.BOLD);
            element.innerHTML = HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.ITALIC);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {

        // this.getDocument().addEventListener('copy', /** @type {(EventListener|function (Event): (boolean|undefined)|null)} */ (FunctionsUtils.bind(this.copyFormattedText_,this)), true);

        let formattedContent = HgMetacontentUtils.decodeStyleTag(content, HgMetacontentUtils.StyleTag.UNDERLINE);
        formattedContent = HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.BOLD);
        return HgMetacontentUtils.decodeStyleTag(formattedContent, HgMetacontentUtils.StyleTag.ITALIC);
    }

    /** @inheritDoc */
    handleKeyboardShortcut(e, key, isModifierPressed) {
        if (!isModifierPressed) {
            return false;
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyUp(e) {
        if (userAgent.engine.isGecko()) {
            e.keyCode = KeysUtils.normalizeGeckoKeyCode(e.keyCode);
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset();

        const position = (anchorNode.nodeValue || '').indexOf('*', offset);

        const latestText = (anchorNode.nodeValue || '').slice(0, offset);

        if (KeysUtils.isTextModifyingKeyEvent(e)) {
            /* bold/italics on nodes(person, topic, hashtag) */
            if (anchorNode.textContent.charAt(1) === '*' || anchorNode.textContent.charAt(1) === '_') {
                const style = anchorNode.textContent.charAt(1);
                if (anchorNode.previousSibling != null &&
                    (anchorNode.previousSibling.tagName == 'SPAN' ||
                    style === '*' && (anchorNode.previousSibling.tagName == 'EM' || anchorNode.previousSibling.tagName == 'I') ||
                    style === '_' && (anchorNode.previousSibling.tagName == 'B' || anchorNode.previousSibling.tagName == 'STRONG'))) {
                    const prevPrev = anchorNode.previousSibling.previousSibling;
                    if (prevPrev != null) {
                        if (prevPrev.textContent.charAt(prevPrev.length - 1) == style ||
                            prevPrev.textContent.charAt(prevPrev.length - 2) == style) {
                            this.formatNodes_(style == '*' ? true : false, anchorNode);
                            /* remove '*'s */
                            anchorNode.textContent = anchorNode.textContent.replace(style, '');
                            if (prevPrev.textContent.charAt(prevPrev.length - 2) == style) {
                                prevPrev.textContent = prevPrev.textContent.substring(0, prevPrev.textContent.length - 2);
                            } else {
                                prevPrev.textContent = prevPrev.textContent.substring(0, prevPrev.textContent.length - 1);
                            }
                            return true;
                        }
                    }
                }
            }

            //Copy formatted text to clipboard

            // if(!!e.ctrlKey && e.keyCode == KeyCodes.C) {
            //     var nodesToBeCopied = range.getContainer(),
            //         document = this.getDocument();
            //
            //     if ((nodesToBeCopied.parentNode != null && (nodesToBeCopied.parentNode.tagName == 'STRONG' || nodesToBeCopied.parentNode.tagName == 'EM'
            //         || nodesToBeCopied.parentNode.tagName == 'B' || nodesToBeCopied.parentNode.tagName == 'I')) ||
            //         (nodesToBeCopied.previousSibling != null && (nodesToBeCopied.previousSibling.tagName == 'STRONG' || nodesToBeCopied.previousSibling.tagName == 'EM'
            //         || nodesToBeCopied.previousSibling.tagName == 'B' || nodesToBeCopied.previousSibling.tagName == 'I'))) {
            //         e.preventDefault();
            //         document.execCommand('copy');
            //     }
            // }

            if (!StringUtils.isEmptyOrWhitespace(latestText) && this.isStyleLike_(latestText)) {
                /* actually split text node in order to process the smiley code */
                if (position > 0) {
                    anchorNode.splitText(position + 1);
                } else {
                    anchorNode.splitText(offset);
                }

                const parent = this.getParentWithSameStyle(anchorNode, anchorNode.nodeValue);

                if (parent != null) {
                    if (parent.tagName == 'B') {
                        anchorNode.nodeValue = latestText.replace(HgTextFormatterEditorPlugin.FIND_BOLD_TRIGGER_RE_, '');
                    }
                    else if (parent.tagName == 'I') {
                        anchorNode.nodeValue = latestText.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_TRIGGER_RE_, '');
                    }

                    EditorRange.placeCursorNextTo(anchorNode, false);
                    return false;
                }
                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

                this.execCommand(EditorCommandType.TEXT_FORMATTER, anchorNode.nodeValue);

                return true;
            }
        }

        /* checks the case of returning and writing text between the trigger characters(_ _) and transforms it in italics */
        if (e.keyCode != KeyCodes.SHIFT) {
            const formattedText = this.containsStyleLike_(latestText);
            if (!StringUtils.isEmptyOrWhitespace(latestText) && !StringUtils.isEmptyOrWhitespace(formattedText)) {
                anchorNode.splitText(anchorNode.textContent.indexOf(formattedText) + formattedText.length);
                anchorNode.splitText(anchorNode.textContent.indexOf(formattedText));

                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode.nextSibling, false).getBrowserRange());

                this.execCommand(EditorCommandType.TEXT_FORMATTER, anchorNode.nextSibling.nodeValue);

                return true;
            }
        }

        return false;
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        if (e.keyCode !== KeyCodes.BACKSPACE && e.keyCode !== KeyCodes.ENTER && e.keyCode !== KeyCodes.DELETE &&
            !KeysUtils.isTextModifyingKeyEvent(e)) {
            return false;
        }

        /* determine if cursor is right next to style tag */
        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject(),
            range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode(),
            offset = range.getAnchorOffset(),
            latestText = anchorNode.nodeValue || '',
            selectedText = range.getText(),
            rangeNodeParent = anchorNode.parentElement;

        if (e.keyCode == KeyCodes.BACKSPACE || e.keyCode == KeyCodes.DELETE) {
            if (this.isTargetedAnchor(rangeNodeParent) && offset == latestText.length && range.isCollapsed()) {
                const cursorNode = anchorNode.lastChild || anchorNode;

                //reposition cursor inside the text node for it to delete one character at a time
                EditorRange.placeCursorNextTo(cursorNode, false);

                //if there is only one character remaining, delete the whole formatted node
                if(latestText.length == 1) {
                    if (rangeNodeParent && rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                    }
                    e.preventDefault();
                    return true;
                }

                return false;
            } else if(this.isTargetedAnchor(rangeNodeParent) && !range.isCollapsed()){
                //delete the entire formatted node if all test is selected
                if(Math.abs(range.getStartOffset() - range.getEndOffset()) == latestText.length ||
                    (range.getStartOffset() == 0 && offset == 0)) {
                    if (rangeNodeParent && rangeNodeParent.parentNode) {
                        rangeNodeParent.parentNode.removeChild(rangeNodeParent);
                    }
                    return true;
                }

            } else if (!StringUtils.isEmptyOrWhitespace(selectedText) && rangeNodeParent && rangeNodeParent.nodeType == Node.ELEMENT_NODE) {
                if (rangeNodeParent == editor.getOriginalElement()) {
                    const startIndexRange = rangeNodeParent.textContent.indexOf(selectedText),
                        endIndexRange = startIndexRange + selectedText.length;
                    let currentIndex = 0,
                        len = 0,
                        node,
                        i;
                    /* remove the styled nodes that are inside the range to be deleted. */
                    for (i = 0; i < rangeNodeParent.childNodes.length; i++) {
                        node = rangeNodeParent.childNodes[i];
                        if (currentIndex >= startIndexRange && currentIndex + node.textContent.length <= endIndexRange) {
                            if (this.isTargetedAnchor(node) && node && node.parentNode) {
                                node.parentNode.removeChild(node);
                            }
                        }

                        len = node.textContent.length;
                        currentIndex = currentIndex + len;
                    }
                    return true;
               }
            }
        }

        /* transform remaining styleLike words before sending the message */
        else if (e.keyCode == KeyCodes.ENTER) {
            const formattedText = this.containsStyleLike_(latestText);
            if (!StringUtils.isEmptyOrWhitespace(latestText) && !StringUtils.isEmptyOrWhitespace(formattedText)) {
                anchorNode.splitText(anchorNode.textContent.indexOf(formattedText) + formattedText.length);
                anchorNode.splitText(anchorNode.textContent.indexOf(formattedText));

                /* set the newest node of the field Object as the current selection only if it has emoticons */
                TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode.nextSibling, false).getBrowserRange());

                this.execCommand(EditorCommandType.TEXT_FORMATTER, anchorNode.nextSibling.nodeValue);
            }
        } else if (KeysUtils.isTextModifyingKeyEvent(e)) {
            /* !!! I can use this mechanism only for EM and STRONG nodes, because if I include B nodes for example,
            I will be extracted outside everytime I try to use ctrl + B to write something bold; TODO: Still no solution for Underline .
            When editing, I only have Em and Strong nodes for italic and bold */
            if (rangeNodeParent != null && (rangeNodeParent.tagName == 'EM' || rangeNodeParent.tagName == 'STRONG')) {

                const nodeLength = DomUtils.getTextContent(anchorNode).length;

                if (offset == 0 && rangeNodeParent.firstChild == anchorNode) {
                    /* if typing at the beginning extract outside */
                    const prevNode = DomUtils.getPreviousNode(rangeNodeParent);
                    if (prevNode == null || (prevNode.nodeType != Node.TEXT_NODE && prevNode == rangeNodeParent.parentNode)) {
                        const emptyNode = this.getDocument().createTextNode('\uFEFF');

                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(emptyNode, rangeNodeParent);
                        }
                        EditorRange.placeCursorNextTo(rangeNodeParent, true);
                    }

                    return true;
                }

                if (offset == nodeLength && rangeNodeParent.lastChild == anchorNode) {
                    /* if typing at the end extract outside */
                    const nextSibling = rangeNodeParent.nextSibling;

                    if (!(nextSibling && nextSibling.nodeType == Node.TEXT_NODE)) {
                        const textNode = this.getDocument().createTextNode('\uFEFF');

                        if (rangeNodeParent.parentNode) {
                            rangeNodeParent.parentNode.insertBefore(textNode, rangeNodeParent.nextSibling);
                        }
                        EditorRange.placeCursorNextTo(textNode, false);
                    }

                    return true;
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, text) {
        const editor = this.getFieldObject(),
            range = editor.getRange(),
            offset = range.getAnchorOffset();

        if (range && BaseUtils.isString(text)) {
            const anchorStr = this.style_(StringUtils.htmlEscape(/** @type {string} */(text)));

            /* create a new node and set the node content with the string that contains the links for all found urls */
            const anchor = DomUtils.htmlToDocumentFragment(/** @type {string} */(anchorStr)),
                position = (anchor.nodeValue || '').indexOf('*', offset),
                editorElement = editor.getOriginalElement(),
                scrollVPosition = editorElement.scrollTop,
                scrollHPosition = editorElement.scrollLeft;

            range.replaceContentsWithNode(anchor);

            /* currently in chrome you cannot position the cursor outside a link, check comments for hf.ui.EditorRange.placeCursorNextTo
             * using dummy empty node instead */
            if (range) {
                let lookAfterStyleTag,
                    anchorNode;
                if (userAgent.browser.isIE() && editorElement.childNodes.length != 0) {
                    lookAfterStyleTag = editorElement.childNodes[0];
                    if(editorElement.childNodes[0].tagName == 'SPAN' && editorElement.childNodes[0].className === 'hg-metacontent-reference' && editorElement.childNodes[0].childNodes.length != 0) {
                        lookAfterStyleTag = editorElement.childNodes[0].childNodes[0];
                    }
                } else {
                    anchorNode = range.getAnchorNode();
                    lookAfterStyleTag = anchorNode.nextElementSibling;
                }

                const emptyNode = this.getDocument().createTextNode('\uFEFF');
                let styleTag = null;

                if (!userAgent.browser.isIE() && lookAfterStyleTag && lookAfterStyleTag.nodeType == Node.ELEMENT_NODE) {
                    styleTag = lookAfterStyleTag;
                } else {
                    let i;
                    const regexp = RegExpUtils.RegExp('(<strong>|<em>)(.*?)(<\/strong>|<\/em>)'),
                        match = regexp.exec(anchorStr),
                        formattedText = match ? match[2] : null;

                    for (i = lookAfterStyleTag.parentNode.childNodes.length - 1; i >= 0; i--) {
                        if (lookAfterStyleTag.parentNode.childNodes[i] && lookAfterStyleTag.parentNode.childNodes[i].nodeType == Node.ELEMENT_NODE &&
                            lookAfterStyleTag.parentNode.childNodes[i].textContent == formattedText) {

                            styleTag = lookAfterStyleTag.parentNode.childNodes[i];
                            break;
                        }
                    }
                }

                if (styleTag) {
                    /* there is an issue with ff, when there is a word before this styled one */
                    if (userAgent.engine.isGecko() && styleTag == editor.getElement()) {
                        styleTag = anchorNode.previousSibling;
                    }

                    if (styleTag.parentNode) {
                        styleTag.parentNode.insertBefore(emptyNode, styleTag.nextSibling);
                    }

                    if (position > 0) {
                        EditorRange.placeCursorNextTo(styleTag, true);
                    } else {
                        EditorRange.placeCursorNextTo(emptyNode, false);
                    }
                }
            }

            //restore scroll positions
            editorElement.scrollTop = scrollVPosition;
            editorElement.scrollLeft = scrollHPosition;

        }
    }

    /**
     * Add html style tags
     * @param {string} str The string to check
     * @returns {string}
     */
    style_(str) {
        /* check if bold style is found */
        str = str.replace(HgTextFormatterEditorPlugin.FIND_BOLD_RE_, function (content, strippedMatch) {
            // make sure content is not only made of *
            const part = strippedMatch.replace(HgTextFormatterEditorPlugin.FIND_BOLD_TRIGGER_RE_, '');
            if (StringUtils.isEmptyOrWhitespace(part)) {
                return content;
            } else {
                return content.replace(content.replace(/^[\s\xa0\\_]+|[\s\xa0\\_]+$/g, ''), '<strong>'+ strippedMatch +'</strong>');// trim + '_' for bold
            }
        });

        /* check if italic style is found */
        return str.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_RE_, function (content, strippedMatch) {
            // make sure content is not only made of _
            const part = strippedMatch.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_TRIGGER_RE_, '');
            if (StringUtils.isEmptyOrWhitespace(part)) {
                return content;
            } else {
                return content.replace(content.replace(/^[\s\xa0\\*]+|[\s\xa0\\*]+$/g, ''), '<em>'+ strippedMatch +'</em>'); // trim + '*' for italic
            }
        });
    }

    /**
     * Add style tags on nodes
     * @param {boolean} style true-bold, false-italic
     * @param {Node} anchorNode
     */
    formatNodes_(style, anchorNode) {
        const editor = this.getFieldObject(),
            range = editor.getRange(),
            tags = style ? '<strong></strong>' : '<em></em>',
            anchor = DomUtils.htmlToDocumentFragment(/** @type {string} */(tags));

        if (anchorNode.parentNode) {
            anchorNode.parentNode.insertBefore(anchor, anchorNode.nextSibling);
        }
        anchor.appendChild(anchorNode.previousSibling);

        const emptyNode = this.getDocument().createTextNode('\uFEFF');
        const emptyNodeBefore = this.getDocument().createTextNode('\uFEFF');

        range.replaceContentsWithNode(anchor);

        if (anchor.parentNode) {
            anchor.parentNode.insertBefore(emptyNode, anchor.nextSibling);
        }
        if (anchor.previousSibling.parentNode) {
            anchor.previousSibling.parentNode.insertBefore(emptyNodeBefore, anchor.previousSibling.nextSibling);
        }

        EditorRange.placeCursorNextTo(emptyNode, false);
    }

    /**
     * Checks if a string contains the bold/italics triggers
     * @param {string} str The string to check
     * @returns {string} The text that matches the trigger text or '' if there is none
     */
    containsStyleLike_(str) {
        if (str.search(HgTextFormatterEditorPlugin.FIND_ITALIC_RE_) != -1 &&
            str.search(HgTextFormatterEditorPlugin.FIND_BOLD_RE_) != -1) {
            return '';
        }

        const initialStr = str;
        let styleContent = '';

        /* check if bold style is found */
        str.replace(HgTextFormatterEditorPlugin.FIND_BOLD_RE_, function (content, strippedMatch) {
            // make sure content is not only made of _
            const part = strippedMatch.replace(HgTextFormatterEditorPlugin.FIND_BOLD_TRIGGER_RE_, '');
            if (StringUtils.isEmptyOrWhitespace(part)) {
                return '';
            } else {
                styleContent = content.trim();
                return content;
            }
        });

        /* check if italic style is found */
        str.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_RE_, function (content, strippedMatch) {
            // make sure content is not only made of _
            const part = strippedMatch.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_TRIGGER_RE_, '');
            if (StringUtils.isEmptyOrWhitespace(part)) {
                return '';
            } else {
                styleContent = content.trim();
                return content;
            }
        });

        if (str != initialStr) {
            return '';
        }

        return styleContent;
    }

    /**
     * Checks if a string is formatted with one of the supported triggers
     * @param {string} str The string to check
     * @returns {boolean}
     */
    isStyleLike_(str) {
        if (StringUtils.isEmptyOrWhitespace(str) ||
            (!str.endsWith('*') && !str.endsWith('_'))) {

            return false;
        }

        let match, found;

        /* check if bold style is found */
        match = str.match(HgTextFormatterEditorPlugin.BOLD_END_RE_);
        if (match != null) {
            found = match.find(function(part) {
                part = part.replace(HgTextFormatterEditorPlugin.FIND_BOLD_TRIGGER_RE_, '');

                return !StringUtils.isEmptyOrWhitespace(part);
            });

            if (found != null) {
                return true;
            }
        }

        /* check if italic style is found */
        match = str.match(HgTextFormatterEditorPlugin.ITALIC_END_RE_);
        if (match != null) {
            found = match.find(function(part) {
                part = part.replace(HgTextFormatterEditorPlugin.FIND_ITALIC_TRIGGER_RE_, '');

                return !StringUtils.isEmptyOrWhitespace(part);
            });

            if (found != null) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks if the latest text has the same style as its parent
     * @param {string} latestText The string to check
     * @param {Node} parent The parent node of the latestText
     * @returns {boolean} true - both have the same style
     */
    haveSameStyle_(latestText, parent) {
        if (StringUtils.isEmptyOrWhitespace(latestText) ||
            (!latestText.endsWith('*') && !latestText.endsWith('_'))) {
            return false;
        }

        if (parent.tagName == 'B' && latestText.indexOf('*', 0) != -1) {
            return true;
        }
        if (parent.tagName == 'I' && latestText.indexOf('_', 0) != -1) {
            return true;
        }
        return false;
    }

    /**
     * Checks recursively for a parent that has the same style as the given node.
     * Stops if the editor root node is reached and return null.
     * @param {Node} node
     * @param {string} style
     * @return {Node} parent
     * @protected
     */
    getParentWithSameStyle(node, style) {
        if (node.parentNode == this.getFieldObject().getElement()) {
            return null;
        }
        if (this.haveSameStyle_(style, node.parentNode)) {
            return node.parentNode;
        }

        return this.getParentWithSameStyle(node.parentNode, style);
    }

    /**
     * Check if target is a style tag
     * @param {Node} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const editor = this.getFieldObject(),
            styleTags = ['EM', 'I', 'STRONG', 'B', 'U'];

        return target != null && target.nodeType == Node.ELEMENT_NODE && target != editor.getOriginalElement() && styleTags.includes(target.tagName);
    }
};
/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.FIND_ITALIC_TRIGGER_RE_ = /_/g;

/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.FIND_BOLD_TRIGGER_RE_ = /\*/g;

/**
 * @type {!string}
 * @const
 */
HgTextFormatterEditorPlugin.ITALIC_METATAG = '(?:^|\\s|\\*|\\u200B|<br\\s*/?>)_(?!_)(\\S(?:[^\\n*]*?[^\\s*])?)_(?!_)';

/**
 * @type {!string}
 * @const
 */
HgTextFormatterEditorPlugin.BOLD_METATAG = '(?:^|[\\s_\\u200B]|<br\\s*\\/?>)\\*(?!\\*)(\\S(?:[^\\n*]*?[^\\s*])?)\\*(?!\\*)';

/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.FIND_ITALIC_RE_ = new RegExp(HgTextFormatterEditorPlugin.ITALIC_METATAG, 'g');

/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.FIND_BOLD_RE_ = new RegExp(HgTextFormatterEditorPlugin.BOLD_METATAG, 'g');

/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.ITALIC_END_RE_ = new RegExp(HgTextFormatterEditorPlugin.ITALIC_METATAG + '$');

/**
 * @type {!RegExp}
 * @const
 */
HgTextFormatterEditorPlugin.BOLD_END_RE_ = new RegExp(HgTextFormatterEditorPlugin.BOLD_METATAG + '$');