import {HgAppViews} from "./../../../app/Views.js";
import {MessageLikersViewmodel} from "./ViewModel.js";
import {MessageLikersView} from "./View.js";
import {AbstractDialogPresenter} from "./../../../common/ui/presenter/AbstractDialog.js";

/**
 * Creates a new Presence presenter.
 * @extends {AbstractDialogPresenter}
 * @unrestricted 
*/
export class MessageLikersPresenter extends AbstractDialogPresenter {
    /**
     * @param {!hf.app.state.AppState} state
    */
    constructor(state) {
        /* Call the base class constructor */
        super(state);
    }

    /**
     * @inheritDoc
     */
    getViewName() {
        return HgAppViews.TEAM_TOPIC_MESSAGE_LIKERS;
    }

    /** @inheritDoc */
    loadView() {
        return new MessageLikersView();
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        super.cleanup();
    }

    /** @inheritDoc */
    onStartup() {
        super.onStartup();

        /* load model */
        this.loadModel();

        this.openDialog();
    }

    /**
     * Load model (empty AuthObject)
     * @protected
     */
    loadModel() {
        const payload = this.getState().getPayload(),
            likedResource = payload ? payload['likedResource'] : null,
            likeCount = payload ? payload['likeCount'] : 0,
            likedByMe = payload ? payload['likedByMe'] : false;

        const viewmodel = new MessageLikersViewmodel({
            'likedResource': likedResource,
            'likedByMe': likedByMe,
            'likeCount': likeCount
        });

        this.setModel(viewmodel);
    }
};