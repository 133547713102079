import {DataModel} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Model.js";
import {DataModelField} from "./../../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {ObservableCollection} from "./../../../../../../../hubfront/phpnoenc/js/structs/observable/Observable.js";

/**
 * Constructor for a new SearchFilter Model
 * @extends {DataModel}
 * @unrestricted 
*/
export class SearchFilter extends DataModel {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* A start value for a period of time in which a message was created */
        this.addField({'name': 'messageCreatedDateRangeStart', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A end value for a period of time in which a message was created */
        this.addField({'name': 'messageCreatedDateRangeEnd', 'type': DataModelField.PredefinedTypes.DATE_TIME});

        /* A list of interlocutors */
        this.addField({'name': 'interlocutors', 'type': ObservableCollection /* type: hf.structs.observable.ObservableCollection.<string> */});

        /*  */
        this.addField({'name': 'tags', 'type': Array});
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        rawData['interlocutors'] = new ObservableCollection();

        rawData['tags'] = rawData['tags'] || [];
    }
};