/**
 * Default form field template.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field wrapper element id
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.content Specific form field
 * @returns {string}
 */
export function FieldBaseTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        content = args.content || '';

    return `<div id="${id}" class="hf-form-field ${baseCSSClass} ${extraCSSClass}">`
        + `<div id="${id}-value-wrapper" class="hf-form-field-value-wrapper ${baseCSSClass}-value-wrapper">${content}</div>`
        + '</div>';
}

/**
 * Default form input picker field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.editor Specific form field
 * @returns {string}
 */
export function FieldTriggerBaseTemplate(args) {
    let baseCSSClass = args.baseCSSClass || '',
        editor = args.editor || '';

    args.content = `<div class="hf-form-field-trigger-editor-wrapper ${baseCSSClass}-editor-wrapper">${editor}</div>`;

    return FieldBaseTemplate(args);
}

/**
 * Default implementation of Form component.
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Component element identifier.
 *  @param {string} args.name Form name
 *  @param {string} args.action URL of the CGI program
 *  @param {string} args.method How to transfer the data to the CGI
 *  @param {string} args.target What frames to put the results in
 *  @param {string} args.enctype What type of form this is
 * @returns {string}
 */
export function FormTemplate(args) {
    let id = args.id || '',
        name = args.name || '',
        action = args.action || '',
        method = args.method || '',
        target = args.target ? `target="${args.target}"` : '',
        enctype = args.enctype ? `enctype="${args.enctype}"` : '';

    return `<form id="${id}" name="${name}" class="hf-form" action="${action}" method="${method}" ${target} ${enctype} onSubmit="return false;"></form>`;
}

/**
 * Default form input text field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string}  args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.type The type of the field. Can have one of the following values: 'text', 'email', 'url', 'search', 'color', 'date', 'month', 'week', 'time', 'datetime', 'datetime-local'. Defaults to: text.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.autocomplete The 'autocomplete' attribute.
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.readOnly Specifies if the element is readonly
 * @returns {string}
 */
export function FieldTextTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        type = args.type || 'text',
        name = args.name || '',
        maxlength = args.maxlength ? `maxlength="${args.maxlength}"` : '',
        size = args.size ? `size="${args.size}"` : '',
        value = args.value ? `value="${args.value}"` : '',
        autocomplete = args.autocomplete ? `autocomplete="${args.autocomplete}"` : '',
        placeholder = args.placeholder ? `placeholder="${args.placeholder}"` : '',
        accessKey = args.accessKey ? `accessKey="${args.accessKey}"` : '',
        tabindex = args.tabIndex ? `tabindex="${args.tabIndex}"` : '',
        autofocus = args.autofocus ? 'autofocus' : '',
        readOnly = args.readOnly ? 'readonly="readonly"' : '',

        tpl = `<input id="${id}-value-editor" name="${name}" class="hf-form-field-value-editor ${baseCSSClass}-value-editor"
            type="${type}" ${maxlength} ${size} ${value} ${accessKey} ${tabindex} ${autocomplete} ${readOnly} ${placeholder} ${autofocus}/>`;

    return FieldBaseTemplate({
        id,
        baseCSSClass,
        extraCSSClass,
        content: tpl
    });
}

/**
 * Default form input password field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.type The type of the field. Can have one of the following values: 'text', 'password'. Defaults to: 'password'.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.autocomplete The 'autocomplete' attribute.
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 * @returns {string}
 */
export function FieldPasswordTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        type = args.type || 'password',
        name = args.name || '',
        maxlength = args.maxlength ? `maxlength="${args.maxlength}"` : '',
        size = args.size ? `size="${args.size}"` : '',
        value = args.value ? `value="${args.value}"` : '',
        autocomplete = args.autocomplete ? `autocomplete="${args.autocomplete}"` : '',
        placeholder = args.placeholder ? `placeholder="${args.placeholder}"` : '',
        accessKey = args.accessKey ? `accessKey="${args.accessKey}"` : '',
        tabindex = args.tabIndex ? `tabindex="${args.tabIndex}"` : '',
        autofocus = args.autofocus ? 'autofocus' : '',
        readOnly = args.readOnly ? 'readonly="readonly"' : '';

    return `<div id="${id}" class="hf-form-field ${baseCSSClass} ${extraCSSClass}">`
        + `<div id="${id}-content-wrapper" class="${baseCSSClass}-content-wrapper">`
            + `<div id="${id}-value-wrapper" class="hf-form-field-value-wrapper ${baseCSSClass}-value-wrapper">`
                + `<input id="${id}-value-editor" name="${name}" class="hf-form-field-value-editor ${baseCSSClass}-value-editor" type="${type}" `
                + `${maxlength} ${size} ${value} ${accessKey} ${tabindex} ${autocomplete} ${readOnly} ${placeholder} ${autofocus}/>`
            + '</div>'
        + '</div>'
    + '</div>';
}

/**
 * Default implementation of a trigger button.
 *
 * @param {object<string, *>=} args
 *  @param {string=} args.id Component element identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 * @returns {string}
 */
export function TriggerButtonTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '';

    return `<div id="${id}-trigger-button" class="hf-form-field-trigger-trigger-button ${baseCSSClass}">`
                + `<div class="hf-form-field-trigger-trigger-button-content ${baseCSSClass}-content"></div>`
            + '</div>';
}

/**
 * Default form input combo box field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 * @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 * @returns {string}
 */
export function FieldComboBoxTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        name = args.name || '',
        maxlength = args.maxlength ? `maxlength="${args.maxlength}"` : '',
        size = args.size ? `size="${args.size}"` : '',
        value = args.value ? `value="${args.value}"` : '',
        placeholder = args.placeholder ? `placeholder="${args.placeholder}"` : '',
        accessKey = args.accessKey ? `accessKey="${args.accessKey}"` : '',
        tabindex = args.tabIndex ? `tabindex="${args.tabIndex}"` : '',
        autofocus = args.autofocus ? 'autofocus' : '',
        readOnly = args.readOnly ? 'readonly="readonly"' : '',

        tpl = `<input id="${id}-value-editor" name="${name}" class="hf-form-field-value-editor ${baseCSSClass}-value-editor" autocomplete="off" type="text" `
            + `${maxlength} ${size} ${value} ${accessKey} ${tabindex} ${readOnly} ${placeholder} ${autofocus}/>`;

    return FieldTriggerBaseTemplate({
        id,
        baseCSSClass,
        extraCSSClass,
        editor: tpl
    });
}

/**
 * Default form input drop down list field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 * @returns {string}
 */
export function FieldDropDownListTemplate(args) {
    let id = args.id || '',
        name = args.name || '',
        baseCSSClass = args.baseCSSClass || '',

        editor = `<input id="${id}-value-editor-hidden" name="${name}" type="text" class="hf-form-field-value-editor hf-form-field-value-editor-hidden" autocomplete="off"/>`;

    return FieldTriggerBaseTemplate({
        id,
        baseCSSClass,
        editor
    });
}

/**
 * Default form input date picker field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 * @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 * @returns {string}
 */
export function FieldDatePickerTemplate(args) {
    return FieldComboBoxTemplate(args);
}

/**
 * Default form input autocomplete field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 * @returns {string}
 */
export function FieldAutoCompleteTemplate(args) {
    return FieldComboBoxTemplate(args);
}

/**
 * Default form input search field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the input text field.
 *  @param {string=} args.size The width, in characters, of an <input> element
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 * @returns {string}
 */
export function FieldSearchTemplate(args) {
    return FieldComboBoxTemplate(args);
}

/**
 * Default form input combo box field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 */
export function MultiSelectTemplate(args) {
    return FieldBaseTemplate(args);
}

/**
 * Default form textarea field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id Form field identifier
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The base CSS class name.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.maxlength The maximum size, in characters, of the text area field.
 *  @param {string=} args.autocomplete The 'autocomplete' attribute.
 *  @param {string=} args.accessKey Specifies a shortcut key to access an element
 *  @param {string=} args.tabIndex Specifies the tab order of an element
 *  @param {string=} args.autofocus Specifies if the element is autofocused
 *  @param {string=} args.style The style to be applied on the <textarea> tags
 *  @param {string=} args.rows The visible number of rows
 *  @param {string=} args.cols The visible number of columns
 *  @param {string=} args.wrap The html 5 'wrap' attribute: ow the text in a text area is to be wrapped when submitted in a form
 *  @param {string=} args.placeholder The 'placeholder' attribute.
 * @returns {string}
 */
export function FieldTextAreaTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',

        name = args.name || '',
        accessKey = args.accessKey ? `accessKey="${args.accessKey}"` : '',
        tabIndex = args.tabIndex ? `tabindex="${args.tabIndex}"` : '',
        autocomplete = args.autocomplete ? `autocomplete="${args.autocomplete}"` : '',
        autofocus = args.autofocus ? 'autofocus' : '',
        rows = args.rows ? `rows="${args.rows}"` : '',
        cols = args.cols ? `cols="${args.cols}"` : '',
        wrap = args.wrap ? `wrap="${args.wrap}"` : '',
        style = args.style ? `style="${args.style} resize: none"` : '',
        maxlength = args.maxlength ? `maxlength="${args.maxlength}"` : '',
        placeholder = args.placeholder ? `placeholder="${args.placeholder}"` : '',
        value = args.value || '',

        content = `<textarea id="${id}-value-editor" name="${name}" class="hf-form-field-value-editor ${baseCSSClass}-value-editor" `
		    + `${accessKey} ${tabIndex} ${autocomplete} ${autofocus} ${rows} ${cols} ${wrap} ${style} ${maxlength} ${placeholder}>`
		    + `${value}</textarea>`
            + `<textarea id="${id}-value-editor-hidden" name="${name}-value-editor-hidden" class="${baseCSSClass}-value-editor-hidden" tabindex="-1">`
            + `${value}</textarea>`;

    return FieldBaseTemplate({
        id,
        baseCSSClass,
        extraCSSClass,
        content
    });
}

/**
 * Default form display field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id The field identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.value The value of the field.
 * @returns {string}
 */
export function FieldDisplayTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        value = args.value || '',

        content = `<span id="${id}-value-editor" class="hf-form-field-value-editor ${baseCSSClass}-span">${value}</span>`;

    return FieldBaseTemplate({
        id,
        baseCSSClass,
        extraCSSClass,
        content
    });
}

/**
 * Default form checkable field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id The field identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.type The type of the field.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.checked Whether the checkable field starts as checked or not
 *  @param {string} args.type The type of the field. Can have one of the following values: 'checkbox', 'radio'
 * @returns {string}
 */
export function FieldCheckableTemplate(args) {
    let id = args.id || '',
        baseCSSClass = args.baseCSSClass || '',
        extraCSSClass = args.extraCSSClass || '',
        type = args.type || '',
        name = args.name || '',
        value = args.value ? `value="${args.value}"` : '',
        checked = args.value ? 'checked="checked"' : '';

    return `<div id="${id}" class="hf-form-field ${baseCSSClass} ${extraCSSClass}">`
        + `<div id="${id}-content-wrapper" class="hf-form-field-checkable-content-wrapper ${baseCSSClass}-content-wrapper">`
            + `<div id="${id}-value-wrapper" class="hf-form-field-value-wrapper ${baseCSSClass}-value-wrapper">`
                + `<input id="${id}-value-editor" type="${type}" name="${name}" class="hf-form-field-value-editor ${baseCSSClass}-value-editor" ${value} ${checked} />`
            + '</div>'
        + '</div>'
    + '</div>';
}

/**
 * Default form checkbox field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id The field identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.type The type of the field.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.checked Whether the checkable field starts as checked or not
 *  @param {string} args.type The type of the field. Can have one of the following values: 'checkbox', 'radio'
 * @returns {string}
 */
export function FieldCheckboxTemplate(args) {
    args.type = 'checkbox';
    return FieldCheckableTemplate(args);
}

/**
 * Default form checkbox field template.
 * The HTML content of the field (just the field) should be passed in the $content parameter to the .FieldBase template
 *
 * @param {object<string, *>=} args
 *  @param {string} args.id The field identifier.
 *  @param {string=} args.baseCSSClass The base CSS class name.
 *  @param {string=} args.extraCSSClass The extra CSS class names.
 *  @param {string=} args.type The type of the field.
 *  @param {string=} args.name The name of the field.
 *  @param {string=} args.value The value of the field.
 *  @param {string=} args.checked Whether the checkable field starts as checked or not
 *  @param {string} args.type The type of the field. Can have one of the following values: 'checkbox', 'radio'
 * @returns {string}
 */
export function FieldRadioTemplate(args) {
    args.type = 'radio';
    return FieldCheckableTemplate(args);
}
