import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {DelayedActionButton, DelayedActionButtonEventType} from "./../button/DelayedActionButton.js";
import {MenuButton, MenuButtonEventType} from "./../button/MenuButton.js";
import {TopicActions} from "./../../enums/Enums.js";
import {ShareButton} from "./../share/ShareButton.js";
import {TopicShareViewmodel} from "./../viewmodel/TopicShare.js";
import {HgUIEventType} from "./../events/EventType.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 * @type {string}
 * @protected
 */
const CSS_CLASS_PREFIX = 'hg-thread-header';

/**
 * Base thread header.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class ThreadDetailsHeaderBase extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {UIComponent}
         * @protected
         */
        this.threadActionsContainer = this.threadActionsContainer === undefined ? null : this.threadActionsContainer;

        /**
         * Button to open/close the thread actions menu.
         * @type {MenuButton}
         * @private
         */
        this.threadActionsMenuButton_ = this.threadActionsMenuButton_ === undefined ? null : this.threadActionsMenuButton_;

        /**
         * @type {ShareButton}
         * @protected
         */
        this.shareButton = this.shareButton === undefined ? null : this.shareButton;
    }

    /** inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.threadActionsContainer = new HorizontalStack({'extraCSSClass': 'thread-actions-container'});

        this.threadActionsMenuButton_ = new MenuButton(this.getThreadActionsMenuButtonsConfig());
    }

    /** inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.threadActionsContainer = null;
        this.threadActionsMenuButton_ = null;

        BaseUtils.dispose(this.shareButton);
        this.shareButton = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return CSS_CLASS_PREFIX;
    }

    /** inheritDoc */
    createDom() {
        super.createDom();

        this.threadActionsContainer.addChild(this.threadActionsMenuButton_, true);
    }

    /** inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.threadActionsMenuButton_, MenuButtonEventType.MENU_ITEM_ACTION, this.handleMenuItemAction)
            .listen(this.threadActionsMenuButton_, DelayedActionButtonEventType.DELAYED_ACTION, this.handleDelayedAction);
    }

    /** inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.shareButton != null) {
            this.shareButton.close();
        }
    }

    /** inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.threadActionsMenuButton_, {'set': this.threadActionsMenuButton_.setModel}, {
            'sourceProperty': 'actions',
            'converter': {
                'sourceToTargetFn': function (actions) {
                    return actions ? actions.getAll() : [];
                }
            }
        });

        this.setBinding(this.threadActionsMenuButton_, {'set': this.threadActionsMenuButton_.setEnabled}, {
            'sourceProperty': 'actions',
            'converter': {
                'sourceToTargetFn': function (actions) {
                    return actions ? /** @type {hf.structs.observable.ObservableCollection} */(actions).getCount() > 0 : false;
                }
            }
        });
    }

    /**
     * @return {MenuButton}
     * @protected
     */
    getThreadActionsButton() {
        return this.threadActionsMenuButton_;
    }

    /**
     *
     * @returns {Object!}}
     */
    getThreadActionsMenuButtonsConfig() {
        const translator = Translator;

        return {
            'name': ThreadDetailsHeaderBase.Button.THREAD_ACTION,
            'extraCSSClass': [CSS_CLASS_PREFIX + '-' + 'menu-button', 'hg-button-menu-arrow'],
            'menu': {
                'extraCSSClass': CSS_CLASS_PREFIX + '-' + 'menu',
                'itemContentFormatter': this.formatThreadActionsMenuItem.bind(this),
                'itemStyle': this.styleThreadActionsMenuItem.bind(this)
            },
            'popup': {
                'extraCSSClass': CSS_CLASS_PREFIX + '-' + 'menu-popup',
                'placement': PopupPlacementMode.BOTTOM_RIGHT,
                'horizontalOffset': 10,
                'verticalOffset': 4
            },
            'tooltip': {
                'content': translator.translate("thread_options"),
                'placement': PopupPlacementMode.TOP_MIDDLE,
                'verticalOffset': -1,
                'showArrow': true
            }
        };
    }

    /**
     * @param {*} threadAction
     * @param {UIComponent} menuItem
     * @return {UIControlContent}
     * @protected
     */
    formatThreadActionsMenuItem(threadAction, menuItem) {
        const translator = Translator;

        if (threadAction['type'] == TopicActions.DELETE) {
            return new DelayedActionButton({
                'displayProgressMode': DelayedActionButton.DisplayProgressMode.BAR,
                'extraCSSClass': [CSS_CLASS_PREFIX + '-' + 'menu-item'],
                'content': translator.translate(threadAction['caption']),
                'model': threadAction
            });
        }

        if (threadAction['type'] == TopicActions.CLOSE) {
            return new DelayedActionButton({
                'displayProgressMode': DelayedActionButton.DisplayProgressMode.BAR,
                'extraCSSClass': [CSS_CLASS_PREFIX + '-' + 'menu-item'],
                'content': translator.translate(threadAction['caption']),
                'model': threadAction
            });
        }

        return threadAction != null ? translator.translate(threadAction['caption']) : null;
    }

    /**
     * @param {*} threadAction
     * @return {(string | !Array.<string> | function(*): (string | !Array.<string>))}
     * @protected
     */
    styleThreadActionsMenuItem(threadAction) {
        const css = [CSS_CLASS_PREFIX + '-' + 'menu-item'];

        if (threadAction != null) {
            css.push(threadAction['cssClass']);
        }

        return css;
    }

    /**
     * @param {*} threadAction
     * @return {*}
     * @protected
     */
    onThreadAction(threadAction) {
        if (this.getModel() == null) {
            return;
        }

        if (threadAction['type'] != null && threadAction['type'] == TopicActions.SHARE) {
            this.openShareButton();

            return;
        }

        if (threadAction['type'] != null && threadAction['type'] === TopicActions.DELETE) {
            threadAction = TopicActions.DELETE;
        }

        const event = new Event(HgUIEventType.THREAD_ACTION);
        event.addProperty('payload', {
            'action': threadAction,
            'thread': this.getModel()['thread']
        });

        this.dispatchEvent(event);

        return event.getProperty('promisedResult');
    }

    /**
     * @protected
     */
    openShareButton() {
        const chatThread = this.getModel(),
            shareButton = this.getShareButton();

        if (chatThread && shareButton) {
            if (this.indexOfChild(shareButton) === -1) {
                this.addChild(shareButton, true);
            }

            let shareThreadResourceViewmodel = chatThread['threadId']
                ? new TopicShareViewmodel({'resourceId': chatThread['threadId']})
                : null;

            shareButton.setModel(shareThreadResourceViewmodel);

            shareButton.open();
        }
    }

    /**
     *
     * @returns {ShareButton}
     * @protected
     */
    getShareButton() {
        return this.shareButton || (this.shareButton = new ShareButton({'hidden': true}));
    }

    /**
     * Handler menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleMenuItemAction(e) {
        const menuItem = e.getTarget();
        if (menuItem) {
            const threadAction = e.getProperty('dataItem');
            if (threadAction != null
                && threadAction['type'] != TopicActions.DELETE
                && threadAction['type'] != TopicActions.CLOSE) {
                this.onThreadAction(threadAction);
            }
        }
    }

    /**
     * Handler menu item action
     * @param {hf.events.Event} e The emitted event.
     * @protected
     */
    handleDelayedAction(e) {
        const target = e.getTarget(),
            threadAction = target.getModel();

        this.threadActionsMenuButton_.close();

        this.onThreadAction(threadAction);
    }
}

/**
 * Specific button names
 * @enum {string}
 * @protected
 */
ThreadDetailsHeaderBase.Button = {
    THREAD_ACTION   : 'thread-action',
    AUDIOCALL   : 'audiocall',
    HANG_UP   : 'hang_up',
    VIDEOCALL   : 'videocall'
};