import Translator from '../translator/Translator.js';

import { DateInterval } from './DateInterval.js';
import { BaseUtils } from '../base.js';
import { DateUtils } from './date.js';

/**
 * The set of levels at which a date time object can be decremented. {@see RelativeDateUtils.decrementDatetime_}
 *
 * @enum {string}
 * @readonly
 * @private
 */
export const RelativeDateLevel_ = {
    YEAR: 'y',
    MONTH: 'm',
    DAY: 'd',
    HOUR: 'h',
    MINUTE: 'n',
    SECOND: 's'
};

/**
 * The codes for translation messages for displaying relative dates.
 *
 * @enum {string}
 * @readonly
 */
export const RelativeDateCode = {
    /** The code for displaying a relative date between 1 and 7 days old. */
    DAY: 'Yesterday',
    DAYS: '%no% days ago',

    /** The code for displaying a relative date between 1 and 24 hours old. */
    HOUR: '1h ago',
    HOURS: '%no%h ago',
    HOUR_MINUTES: '1h and %no%m ago',
    HOURS_MINUTES: '%no%h and %no%m ago',

    /** The code for displaying a relative date between 1 and 60 minutes old. */
    MINUTE: '1m ago',
    MINUTES: '%no%m ago',

    /** The code for displaying a relative date less than 1 minute old. */
    SHORT: 'just now'
};

/**
 * Represents the 'time frame' in which a date time can be placed relative to a reference date time.
 *
 * @typedef {{
 *  relativeDateCode: string,
 *  dateParts: (!Array.<number> | undefined)
 * }}
 */
export let TimeFrame;

/**
 *
 *
 */
export class RelativeDateUtils {
    constructor() {
        //
    }

    /**
     * Formats a date time in order to express the relative time distance between the input timestamp and the present. For
     * example:
     * 0 - 60 seconds passed - "now"
     * 1 - 59 minutes passed - "X minutes ago"
     * 1 - 24 hours passed - "X hours ago" (round to nearest integer)
     * 1 - 6 days passed - "X days ago" (round to nearest integer)
     * over 7 days it will show the date exactly (default: day precision, no minutes).
     *
     * @param {!Date} datetime The date time to be compared with the present.
     * @param {Date=} opt_referenceDatetime The date time against which to compute relative
     * @param {object=} opt_absoluteDateFormat Optional date format to be used when the time interval between the
     *                                                  input timestamp and the present time is longer than 7 days. If not defined, a default formatter will be used.
     * @returns {string} The formatted string which expresses the time distance.
     *
     */
    static format(datetime, opt_referenceDatetime, opt_absoluteDateFormat) {
        const currentDateTime = opt_referenceDatetime || RelativeDateUtils.getCurrentDateTime_();

        /* do not allow relative date in the future :) */
        if (datetime > currentDateTime) {
            datetime = currentDateTime;
        }

        const timeInterval = RelativeDateUtils.getTimeInterval_(datetime, currentDateTime);

        if (timeInterval.years > 0 || timeInterval.months > 0 || timeInterval.days >= 7) {
            return RelativeDateUtils.displayPreciseDate(datetime,
                opt_absoluteDateFormat ? new Intl.DateTimeFormat(window.navigator.language, opt_absoluteDateFormat) : RelativeDateUtils.getDefaultDateTimeFormatter_());
        }

        const timeFrame = RelativeDateUtils.getTimeFrameInternal_(datetime, currentDateTime, timeInterval);

        return RelativeDateUtils.getRelativeDateText_(timeFrame);
    }

    /**
     * Gets the time frame for a DateTime relative to an optional reference DateTime.
     *
     * @param {!Date} datetime The date time to be compared with the present.
     * @param {Date=} opt_referenceDatetime The date time against which to compute relative.
     *
     * @returns {TimeFrame}
     *
     */
    static getTimeFrame(datetime, opt_referenceDatetime) {
        const currentDateTime = opt_referenceDatetime || RelativeDateUtils.getCurrentDateTime_();

        /* do not allow relative date in the future :) */
        if (datetime > currentDateTime) {
            datetime = currentDateTime;
        }

        const timeInterval = RelativeDateUtils.getTimeInterval_(datetime, currentDateTime);

        return RelativeDateUtils.getTimeFrameInternal_(datetime, currentDateTime, timeInterval);
    }

    /**
     * @param {!Date} datetime The date time to be compared with the present.
     * @param {Date} currentDateTime The current date time.
     * @param {hf.DateInterval} timeInterval The time interval.
     *
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameInternal_(datetime, currentDateTime, timeInterval) {
        if (timeInterval.years == 0 && timeInterval.months == 0 && timeInterval.days > 0) {
            let days_ = timeInterval.days;

            /* check days taken into consideration midnight, although 42h passed instead of 48h
             * we might consider 2 days ago not yesterday if the date passed midnight */
            timeInterval.days = 0;

            const secondsSinceMidnight = currentDateTime.getSeconds() + (currentDateTime.getMinutes() + currentDateTime.getHours() * 60) * 60;
            if (timeInterval.getTotalSeconds() > secondsSinceMidnight) {
                days_++;
            }

            return RelativeDateUtils.getTimeFrameDays_(days_);
        }

        if (timeInterval.hours > 0) {
            /**
             * Bug fixing: HG-4387
             *
             * Relative dateTime format:
             * - up to 6 hours: 4 hours and 34 min ago (if minutes = 0, then display only hours)
             * - more than six hours:
             * 		- if minutes <= 30, then display: x hours ago;
             * 		- if minutes > 30, then display: (x + 1) hours ago.
             * 		- for hours = 23 and minutes > 30, display 'Yesterday'
             */

            /* display 'Yesterday' when the real time is hours: 23 and minutes > 30 */
            if (timeInterval.hours === 23 && timeInterval.minutes > 30) {
                return RelativeDateUtils.getTimeFrameDays_(1);
            }

            /* algorithm to display dateTime when dateTime < 6 hours */
            if (timeInterval.hours < 6) {
                if (timeInterval.minutes == 0) {
                    return RelativeDateUtils.getTimeFrameHours_(timeInterval.hours);
                }
                return RelativeDateUtils.getTimeFrameHoursMinutes_(timeInterval.hours, timeInterval.minutes);

            } /* algorithm to display dateTime when dateTime < 6 hours */
            if (timeInterval.minutes <= 30) {
                return RelativeDateUtils.getTimeFrameHours_(timeInterval.hours);
            }
            return RelativeDateUtils.getTimeFrameHours_(timeInterval.hours + 1);


        }

        if (timeInterval.minutes > 0) {
            return RelativeDateUtils.getTimeFrameMinutes_(timeInterval.minutes);
        }

        return RelativeDateUtils.getTimeFrameShortInterval_();
    }

    /**
     * Accepts a timestamp in milliseconds and outputs a relative day. i.e. "Today",
     * "Yesterday", "Tomorrow", or "Sept 15".
     *
     * @param {!Date} datetime The date time to be compared with the present.
     * @param {Date=} opt_referenceDatetime The date time against which to compute relative
     * @param {object=} opt_absoluteDateFormat Optional date format to be used when the time interval between the
     *                                                  input timestamp and the present time is longer than 7 days. If not defined, a default formatter will be used.
     * @returns {string} The formatted date.
     */
    static formatDay(datetime, opt_referenceDatetime, opt_absoluteDateFormat) {
        const today = opt_referenceDatetime || RelativeDateUtils.getCurrentDateTime_();

        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        const datetimeMs = datetime.getTime(),
            day_ms_ = 86400000,
            yesterday = new Date(today.getTime() - day_ms_),
            tomorrow = new Date(today.getTime() + day_ms_),
            dayAfterTomorrow = new Date(today.getTime() + 2 * day_ms_);

        const translator = Translator;
        let message;

        if (datetimeMs >= today.getTime() && datetimeMs < tomorrow.getTime()) {
            message = translator.translate('today');
        } else if (datetimeMs >= yesterday.getTime() && datetimeMs < today.getTime()) {
            message = translator.translate('yesterday');
        } else {
            /* If we don't have a special relative term for this date, then return the
             short date format (or a custom-formatted date). */
            const formatter = new Intl.DateTimeFormat(window.navigator.language, opt_absoluteDateFormat || {
                year: 'numeric',
                month: 'short'
            });

            message = formatter.format(datetime);
        }

        return message;
    }

    /**
     * Computes the relative date
     * Extracts a time interval from the current datetime; the time interval is expressed as a
     *
     * @param {string|Date} expr
     * @returns {Date}
     */
    static formatTimeExpression(expr) {
        if (BaseUtils.isDate(expr)) {
            return /** @type {Date} */ (expr);
        }

        const date = new Date();
        let interval;

        switch (expr) {
            case '-24h': {
                /* 24 hours ago */
                interval = new DateInterval(0, 0, 0, -24);
                break;
            }
            case '-1w': {
                /* one week ago */
                interval = new DateInterval(0, 0, -7);
                break;
            }
            case '-1m': {
                /* one month ago */
                interval = new DateInterval(0, -1);
                break;
            }
            default: {
                interval = new DateInterval();
                break;
            }
        }

        DateUtils.addInterval(date, interval);

        return date;
    }

    /**
     * Gets the current date time.
     *
     * @returns {!Date} The current date time.
     * @private
     */
    static getCurrentDateTime_() {
        return new Date();
    }

    /**
     * Gets the time interval between the 2 input date time objects. If the second date is more recent than the first one,
     * then return a default hf.DateInterval with 0 values
     *
     * @param {!Date} datetime The first (older) date time object.
     * @param {!Date} currentDateTime The second (more recent) date time object.
     * @returns {hf.DateInterval} The interval object.
     *
     */
    static getTimeInterval(datetime, currentDateTime) {
        if (DateUtils.compare(datetime, currentDateTime) > 0) {
            return new DateInterval(0, 0, 0, 0, 0, 0);
        }

        return RelativeDateUtils.getTimeInterval_(datetime, currentDateTime);
    }

    /**
     * Gets the time interval between the 2 input date time objects.
     * The time interval is represented by a hf.DateInterval object, which is defined by a number of years, months, days,
     * hours, minutes and seconds between the two dates.
     * The algorithm is a little tricky, because sometimes the month, days, hours, minutes or seconds might be lower in the
     * second date time object than in the first one, such as computing the interval between February 4th 2014 and
     * November 23rd 2012.
     *
     * @param {!Date} datetime1 The first (older) date time object.
     * @param {!Date} datetime2 The second (more recent) date time object.
     * @returns {hf.DateInterval} The interval object.
     * @private
     */
    static getTimeInterval_(datetime1, datetime2) {
        /* Exchange the two values if the first date is more recent than the second one. */
        if (DateUtils.compare(datetime1, datetime2) > 0) {
            const aux = datetime1;
            datetime1 = datetime2;
            datetime2 = aux;
        }
        const datetime2Tmp = new Date(datetime2.getTime());
        let years, months, days, hours, minutes, seconds;

        seconds = datetime2Tmp.getUTCSeconds() - datetime1.getUTCSeconds();
        if (seconds < 0) {
            seconds += 60;
            RelativeDateUtils.decrementDatetime_(datetime2Tmp, RelativeDateLevel_.MINUTE);
        }
        minutes = datetime2Tmp.getUTCMinutes() - datetime1.getUTCMinutes();
        if (minutes < 0) {
            minutes += 60;
            RelativeDateUtils.decrementDatetime_(datetime2Tmp, RelativeDateLevel_.HOUR);
        }
        hours = datetime2Tmp.getUTCHours() - datetime1.getUTCHours();
        if (hours < 0) {
            hours += 24;
            RelativeDateUtils.decrementDatetime_(datetime2Tmp, RelativeDateLevel_.DAY);
        }
        days = datetime2Tmp.getUTCDate() - datetime1.getUTCDate();
        if (days < 0) {
            RelativeDateUtils.decrementDatetime_(datetime2Tmp, RelativeDateLevel_.MONTH);
            days += DateUtils.getNumberOfDaysInMonth(datetime2Tmp.getUTCFullYear(), datetime2Tmp.getUTCMonth());
        }
        months = datetime2Tmp.getUTCMonth() - datetime1.getUTCMonth();
        if (months < 0) {
            RelativeDateUtils.decrementDatetime_(datetime2Tmp, RelativeDateLevel_.YEAR);
            months += 12;
        }
        years = datetime2Tmp.getUTCFullYear() - datetime1.getUTCFullYear();

        return new DateInterval(years, months, days, hours, minutes, seconds);
    }

    /**
     * Decrements a datetime object. It will decrement it with one second, one minute, one hour, one day, one month or one
     * year, depending on the level parameter.
     * Examples:
     *  - decrement 28/12/2013 12:00:00 with one second will result in 28/12/2013 11:59:59
     *  - decrement 01/01/2014 00:00:00 with one day will result in 31/12/2013 00:00:00
     *
     * @param {!Date} datetime The date time object.
     * @param {RelativeDateLevel_} level The level at which the decrementation occurs.
     * @private
     */
    static decrementDatetime_(datetime, level) {
        switch (level) {
            case RelativeDateLevel_.SECOND:
                const seconds = datetime.getUTCSeconds();
                if (seconds === 0) {
                    datetime.setUTCSeconds(59);
                    RelativeDateUtils.decrementDatetime_(datetime, RelativeDateLevel_.MINUTE);
                } else {
                    datetime.setUTCSeconds(seconds - 1);
                }
                break;

            case RelativeDateLevel_.MINUTE:
                const minutes = datetime.getUTCMinutes();
                if (minutes === 0) {
                    datetime.setUTCMinutes(59);
                    RelativeDateUtils.decrementDatetime_(datetime, RelativeDateLevel_.HOUR);
                } else {
                    datetime.setUTCMinutes(minutes - 1);
                }
                break;

            case RelativeDateLevel_.HOUR:
                const hours = datetime.getUTCHours();
                if (hours === 0) {
                    datetime.setUTCHours(23);
                    RelativeDateUtils.decrementDatetime_(datetime, RelativeDateLevel_.DAY);
                } else {
                    datetime.setUTCHours(hours - 1);
                }
                break;

            case RelativeDateLevel_.DAY:
                const days = datetime.getUTCDate();
                if (days === 0) {
                    let month = datetime.getUTCMonth();
                    if (month === 0) {
                        month = 11;
                    } else {
                        month--;
                    }
                    datetime.setUTCDate(DateUtils.getNumberOfDaysInMonth(datetime.getUTCFullYear(), month));
                    RelativeDateUtils.decrementDatetime_(datetime, RelativeDateLevel_.MONTH);
                } else {
                    datetime.setUTCDate(days - 1);
                }
                break;

            case RelativeDateLevel_.MONTH:
                let month = datetime.getUTCMonth();
                if (month === 0) {
                    datetime.setUTCMonth(11);
                    RelativeDateUtils.decrementDatetime_(datetime, RelativeDateLevel_.YEAR);
                } else {
                    datetime.setUTCMonth(month - 1);
                }
                break;

            case RelativeDateLevel_.YEAR:
                datetime.setUTCFullYear(datetime.getUTCFullYear() - 1);
        }
    }

    /**
     * Displays a precise date. Used for dates which are older than a week.
     *
     * @param {!Date} datetime The date time to be displayed.
     * @param {!Intl.DateTimeFormat} formatter The formatter object.
     * @returns {string} The formatted string representing the precise date.
     */
    static displayPreciseDate(datetime, formatter) {
        return formatter.format(datetime);
    }

    /**
     * Returns a time frame for a relative date which occurred X number of days ago.
     *
     * @param {number} days The number of days.
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameDays_(days) {
        let relativeDateCode = '',
            dateParts = [];

        if (days == 1) {
            relativeDateCode = RelativeDateCode.DAY;
        } else {
            relativeDateCode = RelativeDateCode.DAYS;
            dateParts = [days];
        }

        return {
            relativeDateCode,
            dateParts
        };
    }

    /**
     * Returns a time frame for a relative date which occurred X number of hours ago.
     *
     * @param {number} hours The number of hours.
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameHours_(hours) {
        let relativeDateCode = '',
            dateParts = [];

        if (hours == 1) {
            relativeDateCode = RelativeDateCode.HOUR;
        } else {
            relativeDateCode = RelativeDateCode.HOURS;
            dateParts = [hours];
        }

        return {
            relativeDateCode,
            dateParts
        };
    }

    /**
     * Returns the time frame for a relative date which occurred X number of hours ago and Y number of minutes ago.
     *
     * @param {number} hours The number of hours.
     * @param {number} minutes The number of minutes.
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameHoursMinutes_(hours, minutes) {
        let relativeDateCode = '',
            dateParts = [];

        if (hours == 1) {
            relativeDateCode = RelativeDateCode.HOUR_MINUTES;
            dateParts = [minutes];
        } else {
            relativeDateCode = RelativeDateCode.HOURS_MINUTES;
            dateParts = [hours, minutes];
        }

        return {
            relativeDateCode,
            dateParts
        };
    }

    /**
     * Returns the time frame for a relative date which occurred X number of minutes ago.
     *
     * @param {number} minutes The number of minutes.
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameMinutes_(minutes) {
        let relativeDateCode = '',
            dateParts = [];

        if (minutes == 1) {
            relativeDateCode = RelativeDateCode.MINUTE;
        } else {
            relativeDateCode = RelativeDateCode.MINUTES;
            dateParts = [minutes];
        }

        return {
            relativeDateCode,
            dateParts
        };
    }

    /**
     * Returns the time frame for a relative date which occurred a very short time ago.
     *
     * @returns {TimeFrame}
     * @private
     */
    static getTimeFrameShortInterval_() {
        return {
            relativeDateCode: RelativeDateCode.SHORT
        };
    }

    /**
     * @param timeFrame
     */
    static getRelativeDateText_(timeFrame) {
        return Translator.translate(timeFrame.relativeDateCode, timeFrame.dateParts || []);
    }

    /**
     * Gets the default date time formatter.
     *
     * @returns {!Intl.DateTimeFormat} The default date time formatter object.
     * @private
     */
    static getDefaultDateTimeFormatter_() {
        return RelativeDateUtils.defaultDateTimeFormatter_
            || (RelativeDateUtils.defaultDateTimeFormatter_ = new Intl.DateTimeFormat(window.navigator.language, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            }));
    }
}

/**
 * @type {Intl.DateTimeFormat}
 * @private
 */
RelativeDateUtils.defaultDateTimeFormatter_ = null;
