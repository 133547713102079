import {EditorCommandType, EditorRange} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/Common.js";
import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {DomRangeUtils, TextDomRange} from "./../../../../../../../hubfront/phpnoenc/js/dom/Range.js";
import {DomUtils} from "./../../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {MessageEditorCommands} from "./../Enums.js";
import {HgRegExpUtils} from "./../../../regexp.js";
import {EmailifyUtils} from "./../../../../../../../hubfront/phpnoenc/js/string/emailify.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new editor plugin
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgUnorderedListEditorPlugin extends AbstractEditorPlugin {
    /**
     * @param {boolean=} encodeOnEnter Whether the plugin should encode the emoticon code as emoji symbol when press Enter key
    */
    constructor(encodeOnEnter) {
        super();

        const enableEncodeOnEnter = (encodeOnEnter != null && BaseUtils.isBoolean(encodeOnEnter)) ? encodeOnEnter : true;
        this.setEncodeOnEnter(enableEncodeOnEnter);

        /**
         * Whether the plugin should encode the "* option" as list item when press Enter key
         * @type {boolean}
         * @private
         */
        this.encodeOnEnter_ = this.encodeOnEnter_ === undefined ? true : this.encodeOnEnter_;
    }

    /** @override */
    getTrogClassId() {
        return 'UnorderedList';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == MessageEditorCommands.ULIST;
    }

    /**
     * Enable or disable the behavior that encode the "* option" code when press Enter key
     * @param {boolean} encodeOnEnter
     */
    setEncodeOnEnter(encodeOnEnter) {
        this.encodeOnEnter_ = encodeOnEnter;
    }

    /** @inheritDoc */
    processPastedContent(pastedContent) {
        const ulFinder = HgRegExpUtils.UL_FINDER;
        const transformer = HgRegExpUtils.LI_TRANSFORM;
        const ulFinderNl = HgRegExpUtils.UL_FINDER_NL;

        pastedContent = pastedContent.replace(ulFinder, function (_, ul) {
            if (ul.match(ulFinderNl)) {
                return "{hg:ul}" + ul.replace(transformer, '{hg:li}$1{/hg:li}') + "{/hg:ul}\n"
            }
            return "{hg:ul}" + ul.replace(transformer, '{hg:li}$1{/hg:li}') + "{/hg:ul}";
        });

        return HgMetacontentUtils.decodeUnorderedList(pastedContent);
    }

    /** @inheritDoc */
    enable(field) {
        super.enable(field);

        // Parses the current content of editable DOM element and replace emoticons with graphical images.
        const element = field.getElement();
        if (element) {
            element.innerHTML = HgMetacontentUtils.decodeUnorderedList(element.innerHTML);
        }
    }

    /** @inheritDoc */
    prepareContentsHtml(content) {
        return HgMetacontentUtils.decodeUnorderedList(content);
    }

    /** @inheritDoc */
    cleanContentsHtml(content) {
        const formattedContent = HgMetacontentUtils.normalizeStyleTagsAndUnorderedList(content);
        return HgMetacontentUtils.encodeUnorderedList(formattedContent);
    }

    /** @override */
    handleKeyboardShortcut() {
        return false;
    }

    /** @override */
    handleKeyDown(e) {
        return this.treatPlugin(e);
    }

    /** @override */
    handleKeyUp(e) {
        return false;
    }

    /**
     *
     * @param e
     * @returns {boolean}
     */

    treatPlugin(e) {
        const keyCode = e.keyCode || e.charCode,
            enableEncodeOnEnter = ((this.encodeOnEnter_ || (e != null && !!e.shiftKey)) && keyCode === KeyCodes.ENTER);

        if (keyCode != KeyCodes.SPACE
            && keyCode != KeyCodes.BACKSPACE
            && keyCode != KeyCodes.ENTER) {

            return false;
        }

        /* merge adjacent text nodes, remove empty text nodes */
        const editor = this.getFieldObject();

        editor.getElement().normalize();

        const range = editor.getRange();
        if (range == null) {
            return false;
        }
        const anchorNode = range.getAnchorNode(),
            li = (anchorNode && anchorNode.nodeType == Node.ELEMENT_NODE) ? anchorNode : anchorNode.parentNode;

        /* check if \n*<space> => transform to list */
        if (keyCode == KeyCodes.SPACE) {
            const startOffset = range.getStartOffset();

            if ((anchorNode.nodeValue || '').trim() == '*' && li.tagName != 'LI') {
                /* transform to unordered list only if anchorNode is the first node in the editor or if the previous sibling is a <br> */
                const isNewlineBeforeStar = anchorNode.textContent[0] == '\n' ? true : false;
                if (li.childNodes[0] == anchorNode || (anchorNode.previousSibling != null && (anchorNode.previousSibling.tagName == 'BR' || isNewlineBeforeStar)) ||
                    this.triggerUnorderedList_(anchorNode.previousSibling)) {
                    /* set the newest node of the field Object as the current selection only if it has emoticons */
                    TextDomRange.select(DomRangeUtils.createFromNodeContents(anchorNode, false).getBrowserRange());

                    //Search if there are multiple nodes to insert into UL
                    const nodesToInsert = this.searchNodesInRow_(anchorNode);

                    this.execCommand(MessageEditorCommands.ULIST, nodesToInsert);

                    e.preventDefault();

                    return true;
                }

                return false;
            /* transform *<space> => list in the middle of the text if there is whitespace/nl before it */
            } else if (anchorNode.nodeValue != null &&
                anchorNode.nodeValue.charAt(startOffset - 1) == '*' &&
                (startOffset == 1 || StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue.charAt(startOffset - 2))) &&
                li.tagName != 'LI') {

                /* prevent transforming *<space> if there is no text after it */
                if (startOffset == anchorNode.nodeValue.length ||
                    (startOffset + 1 == anchorNode.nodeValue.length && anchorNode.nodeValue.charCodeAt(anchorNode.nodeValue.length - 1) == 65279)) {
                    return false;
                }

                //Search if there are multiple nodes to insert into UL
                const nodesToInsert = this.searchNodesInRow_(anchorNode);
                if(nodesToInsert.length > 0) {
                    this.execCommand(MessageEditorCommands.ULIST, nodesToInsert);
                } else {
                    let offset = range.getStartOffset() - 1,
                        secondNode = anchorNode.splitText(offset),
                        star = secondNode.textContent.indexOf('\n') > -1 ?
                            secondNode.splitText(secondNode.textContent.indexOf('\n') + 1).previousSibling : secondNode,
                        futureItemList = star.splitText(1);

                    TextDomRange.select(DomRangeUtils.createFromNodeContents(star, false).getBrowserRange());

                    this.execCommand(MessageEditorCommands.ULIST, futureItemList.textContent);
                    if (futureItemList && futureItemList.parentNode) {
                        futureItemList.parentNode.removeChild(futureItemList);
                    }
                }

                return true;
            } else {
                return false;
            }
        }

        function getNextLi(li, context, isParentNode) {
            const offset = range.getAnchorOffset();

            let liText = li.innerHTML;
            const ul = li.parentElement,
                isLastNode = ul.childNodes[ul.children.length - 1] == li;

            if (context.mustHandleTextInputEvent() && liText == "<br>") {
                liText = '';
                li.innerHTML = '';
            }

            let areChildrenNotText = false;
            if (isParentNode){
                const liChildren = Array.prototype.slice.call(li.children),
                    index = liChildren.findIndex(function (li) {
                        if (li.tagName == 'B' || li.tagName == 'I' || li.tagName == 'U'
                            || li.tagName == 'STRONG') {
                            return true;
                        }
                        return false;
                    });

                if (index != -1) {
                    areChildrenNotText = true;
                }
            }

            /* only if cursor is placed at the beginning of the node!! */
            //offset can be 0, but it can reffer to one of the li's children
            //if li has children that are not text nodes, backspace should have default behaviour
            if (keyCode == KeyCodes.BACKSPACE
                && anchorNode.previousSibling === null
                && ((li.innerHTML.startsWith('\uFEFF') && offset == 1 && !areChildrenNotText) || (offset == 0 && !areChildrenNotText))) {

                /* move this nodes content in previous sibling node on new line */
                const previousSibling = li.previousSibling;
                const previousULSibling = ul.previousSibling;

                /* has previous sibling */
                if (previousSibling) {
                    const nl = context.getDocument().createTextNode('\n');
                    previousSibling.appendChild(nl);

                    const html = liText.replace('\uFEFF', '');
                    if (!StringUtils.isEmptyOrWhitespace(html)) {
                        const newTextNode = DomUtils.htmlToDocumentFragment(html),
                            firstChild = newTextNode.firstChild || newTextNode;

                        previousSibling.appendChild(newTextNode);

                        EditorRange.placeCursorNextTo(firstChild, true);
                    } else {
                        EditorRange.placeCursorNextTo(nl.lastChild || nl, true);
                    }

                    /* remove list, entered new line */
                    if (li && li.parentNode) {
                        li.parentNode.removeChild(li);
                    }

                    e.preventDefault();
                    return true;
                } else if (StringUtils.isEmptyOrWhitespace(liText) && isLastNode) {
                    /* remove list, enter new line */
                    EditorRange.placeCursorNextTo(ul, true);
                    if (ul && ul.parentNode) {
                        ul.parentNode.removeChild(ul);
                    }
                    if(previousULSibling) {
                        e.preventDefault();
                        return true;
                    }
                }

                if(li.previousSibling === null) {
                    if(li.nextSibling === null) {
                        if (ul.parentNode) {
                            ul.parentNode.insertBefore(DomUtils.htmlToDocumentFragment(liText.replace('\uFEFF', '').replace('<br>','')), ul.nextSibling);
                        }
                        if (ul && ul.parentNode) {
                            ul.parentNode.removeChild(ul);
                        }
                    } else {
                        if (ul.parentNode) {
                            ul.parentNode.insertBefore(DomUtils.htmlToDocumentFragment(liText.replace('\uFEFF', '').replace('<br>','')), ul.nextSibling);
                        }
                        if (li && li.parentNode) {
                            li.parentNode.removeChild(li);
                        }
                    }
                    return true;
                }

                /* acts just like wiki plugin, does not remove ul if this is the only node */
                return false;
            }

            if (enableEncodeOnEnter) {
                if (StringUtils.isEmptyOrWhitespace(liText)) {
                    /* fetch next element Sibling in case we need to split list */
                    let nextLi = DomUtils.getNextElementSibling(li);

                    /* if inside list with empty node, jump outside list */
                    if (li && li.parentNode) {
                        li.parentNode.removeChild(li);
                    }

                    const isEmptyList = ul.children.length == 0;

                    if (isEmptyList) {
                        /* remove list */
                        EditorRange.placeCursorNextTo(ul, false);
                        if (ul && ul.parentNode) {
                            ul.parentNode.removeChild(ul);
                        }
                    } else if (isLastNode) {
                        /* last node, exit list  */
                        const emptyNode = context.getDocument().createTextNode('\uFEFF');
                        const br = DomUtils.createDom('br');

                        if (ul.parentNode) {
                            ul.parentNode.insertBefore(br, ul.nextSibling);
                        }

                        if (context.getsStuckInNewLine_(ul) && br.parentNode) {
                            br.parentNode.insertBefore(emptyNode, br.nextSibling);
                        }

                        EditorRange.placeCursorNextTo(br, false);
                    } else {
                        /* split list in 2 (replace ul with the 2 and place cursor after the first) */
                        const secondList = document.createElement('ul');

                        while (nextLi = DomUtils.getNextElementSibling(nextLi)) {
                            secondList.appendChild(nextLi);
                        }

                        secondList.appendChild(ul.childNodes[ul.children.length - 1]);

                        if (ul.parentNode) {
                            ul.parentNode.insertBefore(secondList, ul.nextSibling);
                        }

                        /* insert new line after first list and place cursor */
                        const emptyNode = context.getDocument().createTextNode('\uFEFF');

                        if (context.getsStuckInNewLine_(ul)) {
                            const br = DomUtils.createDom('br');
                            if (ul.parentNode) {
                                ul.parentNode.insertBefore(emptyNode, ul.nextSibling);
                            }
                            if (emptyNode.parentNode) {
                                emptyNode.parentNode.insertBefore(br, emptyNode.nextSibling);
                            }
                            EditorRange.placeCursorNextTo(br, true);
                        } else {
                            EditorRange.placeCursorNextTo(ul, false);
                        }
                    }

                    e.preventDefault();

                    return true;
                } else if  (!StringUtils.isEmptyOrWhitespace(liText)){

                    if(keyCode == KeyCodes.ENTER && li.lastChild != null) {

                        if (EmailifyUtils.findFirstEmail(liText)) {

                            TextDomRange.select(DomRangeUtils.createFromNodeContents(li.lastChild, false).getBrowserRange());
                            editor.execCommand(EditorCommandType.EMAIL, li.lastChild.nodeValue, e);

                        } else if ((li.lastChild.tagName == 'B' || li.lastChild.tagName == 'I' || li.lastChild.tagName == 'U')
                            && li.lastChild.lastChild.nodeValue != null && EmailifyUtils.findFirstEmail(li.lastChild.lastChild.nodeValue)) {

                            TextDomRange.select(DomRangeUtils.createFromNodeContents(li.lastChild, false).getBrowserRange());
                            editor.execCommand(EditorCommandType.EMAIL, li.lastChild.lastChild.nodeValue, e);

                        } else if (li.lastChild.previousSibling != null && li.lastChild.previousSibling.tagName == 'STRONG'
                            && li.lastChild.previousSibling.lastChild.nodeValue != null && EmailifyUtils.findFirstEmail(li.lastChild.previousSibling.lastChild.nodeValue)) {

                            TextDomRange.select(DomRangeUtils.createFromNodeContents(li.lastChild.previousSibling, false).getBrowserRange());
                            editor.execCommand(EditorCommandType.EMAIL, li.lastChild.previousSibling.lastChild.nodeValue, e);
                        }
                    }

                    /* if inside list with non-empty node, just on a new inserted list item,
                     * CAREFUL: new line inside current li node if SenOnEnter is disabled (default browser action) */
                    const newLi = DomUtils.createDom('li', '', context.getDocument().createTextNode(anchorNode.nodeValue != null && !StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue) && !StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue.charAt(offset)) && offset < anchorNode.nodeValue.length ? anchorNode.nodeValue.slice(offset, anchorNode.nodeValue.length) : '\uFEFF'));

                    if(anchorNode.nodeValue != null && !StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue)
                       && !StringUtils.isEmptyOrWhitespace(anchorNode.nodeValue.charAt(offset)) && offset < anchorNode.nodeValue.length) {
                        anchorNode.nodeValue = liText.slice(0, offset);
                    }

                    if (li.parentNode) {
                        li.parentNode.insertBefore(newLi, li.nextSibling);
                    }

                    context.placeCursorInsideLi_(newLi);

                    e.preventDefault();

                    editor.dispatchChange(true);

                    return true;
                }
            } else if (keyCode == KeyCodes.ENTER && (StringUtils.isEmptyOrWhitespace(liText) && ul.children.length == 1)) {
                /* make sure we do not submit an empty ul */
                EditorRange.placeCursorNextTo(ul, true);
                if (ul && ul.parentNode) {
                    ul.parentNode.removeChild(ul);
                }

                e.preventDefault();

                return true;

            } else if (keyCode == KeyCodes.BACKSPACE && anchorNode.previousSibling !== null && StringUtils.isEmptyOrWhitespace(liText)) {
                const prevLi = anchorNode.previousSibling;
                if (li && li.parentNode) {
                    li.parentNode.removeChild(li);
                }
                EditorRange.placeCursorNextTo(prevLi, false);
                return true;
            }
        }

        if (li.tagName == 'LI') {
            return getNextLi(li, this, false);
        } else if ((li.tagName == 'B' || li.tagName == 'I' || li.tagName == 'U' || li.tagName == 'STRONG') && li.parentNode != null) {
            /* there is a styling tag inside a <li> */
            if (li.parentNode.tagName == 'LI') {
                return getNextLi(li.parentNode, this, true);
            }
            const liParent = li.parentNode;

            /* there are two styling tags inside a <li> */
            if ((liParent.tagName == 'B' || liParent.tagName == 'I' || liParent.tagName == 'U' || liParent.tagName == 'STRONG') && liParent.parentNode != null) {
                if (liParent.parentNode.tagName == 'LI') {
                    return getNextLi(liParent.parentNode, this, true);
                }
                const liGrandparent = liParent.parentNode;

                /* there are three styling tags inside a <li> */
                if ((liGrandparent.tagName == 'B' || liGrandparent.tagName == 'I' || liGrandparent.tagName == 'U' || liGrandparent.tagName == 'STRONG') && liGrandparent.parentNode != null) {
                    if (liGrandparent.parentNode.tagName == 'LI') {
                        return getNextLi(liGrandparent.parentNode, this, true);
                    }
                }
            }
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, content) {
        const editor = this.getFieldObject(),
            range = editor.getRange();

        if (range) {
            const ul = DomUtils.createDom('ul');
            let li;

            if (content != null && (BaseUtils.isNumber(content) || BaseUtils.isString(content))) {
                li = DomUtils.createDom('li', '', this.getDocument().createTextNode(/** @type {number|string} */(content)));
            } else if(content != null && BaseUtils.isArray(content)) {
                li = DomUtils.createDom('li');
                content.forEach(function(node){
                    if (!StringUtils.isEmptyOrWhitespace(node.textContent) ||
                        (StringUtils.isEmptyOrWhitespace(node.textContent) && (node.textContent != null && node.textContent.charCodeAt(0) != 160))) {
                        li.appendChild(node);
                    }
                });
            } else {
                li = DomUtils.createDom('li', '', this.getDocument().createTextNode('\uFEFF'));
            }

            if (userAgent.platform.isAndroid()) {
                if (li.textContent.length > 0) {
                    ul.appendChild(li);
                    range.replaceContentsWithNode(ul);
                    this.placeCursorInsideLi_(li);
                } else {
                    ul.innerHTML = '<li></li>';
                    range.replaceContentsWithNode(ul);
                }
            } else {
                ul.appendChild(li);
                range.replaceContentsWithNode(ul);
                this.placeCursorInsideLi_(li);
            }
        }
    }

    /**
     * @param {Node} li
     * @private
     */
    placeCursorInsideLi_(li) {
        let cursorNode = li.lastChild || li;

        if (cursorNode && cursorNode.nodeType == Node.ELEMENT_NODE) {
            const resourceTypeAttr = cursorNode.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);
            if (resourceTypeAttr != null) {
                if (cursorNode.parentNode) {
                    cursorNode.parentNode.insertBefore(this.getDocument().createTextNode('\uFEFF'), cursorNode.nextSibling);
                }
                cursorNode = cursorNode.nextSibling;
            }
        }

        if (userAgent.browser.isIE() && !userAgent.browser.isEdge()) {
            setTimeout(() => EditorRange.placeCursorNextTo(cursorNode, false));
        } else {
            EditorRange.placeCursorNextTo(cursorNode, false);
        }
    }

    /**
     * Detects if we must use dummy node in order to place cursor outside link
     * @param {Element} anchor
     * @return {boolean}
     */
    getsStuckInNewLine_(anchor) {
        const nextSibling = anchor.nextSibling;
        
        // Check if there is already a space after the link.  Only handle the
        // simple case - the next node is a non empty text node.espace(nextSibling.data)) {
        if (nextSibling && nextSibling.nodeType == Node.TEXT_NODE && !StringUtils.isEmptyOrWhitespace(nextSibling.data)) {
            return false;
        } else {
            // If there isn't an obvious space to use, create one after the link.
            return true;
        }
    }

    /**
     * Checks if the unordered list should be triggered after the given node.
     * True for code, giphy and hug stickers.
     * @param {Node} node
     * @return {boolean}
     */
    triggerUnorderedList_(node) {
        if (node != null && node.hasAttribute('class')) {
            const nodeClass = node.getAttribute('class');
            if ((nodeClass == HgMetacontentUtils.MacroClassName[HgMetacontentUtils.Macro.CODE]) ||
                (nodeClass == HgMetacontentUtils.GIPHY_WRAP) ||
                (nodeClass.startsWith('sticker'))) {
                return true;
            }
        }
        return false;
    }

    /**
     * Search if there are multiple nodes to insert into UL
     * @param {Node} anchorNode
     * @return {Array}
     */
    searchNodesInRow_(anchorNode) {
        const nodes = anchorNode.parentNode ? anchorNode.parentNode.childNodes : [],
            nodesToInsert = [];
        let start = false;
        for (let i=0; i<nodes.length; i++) {
            //Start search from a node that has * as a first character -> this is where the user want to start from
            if(nodes[i].nodeValue != null && nodes[i].nodeValue.trimLeft().startsWith('*')) {
                nodes[i].nodeValue = nodes[i].nodeValue.slice(anchorNode.nodeValue.indexOf('*') + 1, nodes[i].nodeValue.length); // remove '*' from first node
                start = true;
            }

            if(start){
                //After a BR node quit the execution.
                if(nodes[i].tagName == 'BR' || nodes[i].textContent[0] == '\n') {
                    break;
                }

                nodesToInsert.push(nodes[i]);
            }
        }

        return nodesToInsert;
    }
};