import {SortDirection} from "./../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";
import {QueryDataResult} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";

import {ArrayUtils} from "./../../../../../hubfront/phpnoenc/js/array/Array.js";
import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {QueryData} from "./../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {DataSource} from "./../../../../../hubfront/phpnoenc/js/data/datasource/DataSource.js";
import {DateInterval} from "./../../../../../hubfront/phpnoenc/js/date/DateInterval.js";
import {AbstractService} from "./AbstractService.js";
import {Message} from "./../model/message/Message.js";
import {HgDateUtils} from "./../../common/date/date.js";
import {MessageTypes} from "./../model/message/Enums.js";
import {HgAuthorUtils} from "./../model/author/Common.js";
import {HgResourceCanonicalNames} from "./../model/resource/Enums.js";
import {
    DataChannelConnectionStatus,
    DataChannelResource,
    DataChannelVerb
} from "./datachannel/DataChannelBaseService.js";
import {HgAppConfig} from "./../../app/Config.js";

import {HgAppEvents} from "./../../app/Events.js";
import {HgResourceUtils} from "./../model/resource/Common.js";
import MessageService, {createMessagesHistoryLoader} from "./MessageService.js";
import {DateUtils} from "./../../../../../hubfront/phpnoenc/js/date/date.js";
import DataChannelService from "./datachannel/DataChannelService.js";
import {StringUtils} from "../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import {HgMetacontentUtils} from "./../../common/string/metacontent.js";

/**
 * Creates a new {@see hg.data.service.ResourceCommentsService} object
 *
 * @extends {AbstractService}
 * @unrestricted 
*/
class ResourceCommentsService extends AbstractService {
    constructor() {
        super();

        /**
         * @type {hg.data.service.DataChannelService}
         * @protected
         */
        this.dataChannelService;

        /**
         * @type {Object}
         * @protected
         */
        this.resourceCommentsCache_;

        /**
         * @type {hg.data.service.MessageService}
         * @protected
         */
        this.messageService;

        /**
         * @type {number}
         * @private
         */
        this.invalidateMessageThreadsTimerId_;

        /**
         *
         * @type {Date}
         * @private
         */
        this.lastTimeDCAlive_;

        /**
         * xep-0198 Queue of sent messages unconfirmed by server
         * It stored also messages that are sent while the connection is not available
         * Cannot be stored in stream management because there it would be lost on resume connection
         * with a session initialization (not stream resume)
         * @type {Array}
         * @private
         */
        this.undeliveredMessageQueue_ = this.undeliveredMessageQueue_ === undefined ? [] : this.undeliveredMessageQueue_;

        /**
         * Map of message IDs to message Element.  Used for constant-time
         * random access to undelivered messages by ID.
         * @private {Object}
         */
        this.undeliveredMessageIndex_ = this.undeliveredMessageIndex_ === undefined ? {} : this.undeliveredMessageIndex_;
    }

    /**
     * Add a comment on resource.
     * @param {string} messageBody Text message to attach to file
     * @param {ResourceLike} resourceLink The resource this message (comment) is attached to
     * @param {ResourceLike=} opt_threadLink
     * @return {Promise}
     */
    sendMessage(messageBody, resourceLink, opt_threadLink) {
        if (StringUtils.isEmptyOrWhitespace(messageBody)) {
            throw new Error('The comment cannot be empty');
        }

        /* Was nonce encoded in the message? If not the newContent returned will be the same as the old one and nonce will be null */
        let decodedNonce = HgMetacontentUtils.decodeNonce(messageBody);
        const nonce = decodedNonce.nonce ? decodedNonce.nonce : StringUtils.getRandomString();
        messageBody = decodedNonce.newContent;

        const messageData = {
            'nonce': nonce,
            'type': MessageTypes.MSG,
            'author': HgAuthorUtils.getAuthorDataForCurrentUser(),
            'inThread': opt_threadLink ?
                {
                    'resourceType': opt_threadLink['resourceType'],
                    'resourceId': opt_threadLink['resourceId']
                } :
                {
                    'resourceType': resourceLink['resourceType'],
                    'resourceId': resourceLink['resourceId']
                },
            'body': messageBody,
            'created': HgDateUtils.now()
        };

        if(resourceLink['resourceType'] == HgResourceCanonicalNames.MESSAGE) {
            messageData['replyTo'] = resourceLink['resourceId'];
        }
        else {
            if(opt_threadLink) {
                messageData['reference'] = {
                    'resourceType': resourceLink['resourceType'],
                    'resourceId': resourceLink['resourceId'],
                    'hint': resourceLink['hint'] != null ? resourceLink['hint'] : undefined
                }
            }
        }

        const messageInstance = this.createMessage(messageData);

        const payload = this.getSendMessageCommandPayload(messageInstance);

        const promisedResult = this.sendMessageInternal(
            DataChannelResource.MESSAGE,
            DataChannelVerb.NEW,
            payload,
            messageInstance['nonce'],
            messageInstance
        )
            .then(this.onMessageSendSuccess.bind(this, messageInstance))
            .catch(this.onMessageSendFailure.bind(this, messageInstance));

        this.processNewMessage(messageInstance);

        return this.handleErrors(promisedResult, 'cannot_post_message');
    }

    /**
     * Resend undelivered messages
     * @param {ResourceLike} resourceLink
     * @param {Array.<hg.data.model.message.Message>} missedMessages
     * @param {Array.<hg.data.model.message.Message>} errorMessages
     * @param {boolean=} opt_isResume
     * @public
     */
    resumeUndeliveredMessages(resourceLink, missedMessages, errorMessages, opt_isResume) {
        missedMessages = missedMessages || [];

        const errorMessagesMap = {};
        errorMessages.forEach(function(errorMessage) {
            const message = this.undeliveredMessageIndex_[errorMessage['nonce']];

            if (message == null) {
                errorMessagesMap[errorMessage['nonce']] = errorMessage;
            }
        }, this);

        missedMessages.forEach(function(message) {
            message['errorSending'] = false;
            message['unconfirmed'] = false;

            this.clearUndeliveredMessage(message['nonce']);
            delete errorMessagesMap[message['nonce']];
        }, this);

        /* convert errorMessagesMap to array */
        let undeliveredMessages = [];
        for (let key in errorMessagesMap) {
            undeliveredMessages.push(errorMessagesMap[key]);
        }

        /* flush undelivered messages to interlocutor */
        const flushedMessages = this.flushFailedMessages_(resourceLink, opt_isResume);
        if (flushedMessages.length) {
            undeliveredMessages.splice(undeliveredMessages.length, 0, ...flushedMessages);
        }

        /* make sure they are sorted! */
        undeliveredMessages = new QueryData(undeliveredMessages).sort([{'sortBy': 'created', 'direction': SortDirection.ASC}]).toArray();

        /* dispatch event with delay so that messages are added to the list once loaded */
        undeliveredMessages.forEach(function(undeliveredMessage) {
            /* make sure we do not dispatch all events at once */
            const delay = 400;

            setTimeout((undeliveredMessage) => this.processNewMessage(undeliveredMessage), delay, undeliveredMessage);
        }, this);
    }

    /**
     * Try resending all failed messages in a message group.
     * @param {MessageThreadViewmodel} messageThread
     */
    flushFailedMessages(messageThread) {
        if (messageThread && !!messageThread['errorSending']) {
            let errorMessagesArr = messageThread.getErrorMessages();

            /* make sure the list is ordered */
            errorMessagesArr = new QueryData(errorMessagesArr).sort([{'sortBy': 'created', 'direction': SortDirection.ASC}]).toArray();

            errorMessagesArr.forEach(function (failedMessage) {
                this.resendMessage(failedMessage);
            }, this);
        }
    }

    /**
     * Removes from undelivered queues the unsent messages attached to the provide message thread
     *
     * @param {MessageThreadViewmodel} messageThread
     */
    clearFailedMessages(messageThread) {
        const thread = /** @type {!IThread} */(messageThread['thread']),
            errorMessagesArr = messageThread.getErrorMessages();

        errorMessagesArr.forEach(function (failedMessage) {
            this.clearUndeliveredMessage(failedMessage['nonce']);
        }, this);
    }

    /**
     * Load the messages that may be missed because a message portal disconnection
     * @param {Date} lastTimeDCAlive
     * @param {Object} resourceLink
     */
    loadMissedMessages(resourceLink, lastTimeDCAlive = this.lastTimeDCAlive_) {
        const now = HgDateUtils.now();
        const readSince = new Date(lastTimeDCAlive - HgAppConfig.READ_LATEST_EPSILON);

        if ((now - lastTimeDCAlive) < HgAppConfig.RTM_LOGS_INTEGRITY_INTERVAL) {
            return MessageService.loadLatestMessages({
                    'resourceId': resourceLink['resourceId'],
                    'resourceType': resourceLink['resourceType']
                },
                readSince);

        } else {
            return this.loadResourceCommentsInternal_(resourceLink, new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'created',
                        'filterOp': FilterOperators.GREATER_THAN_OR_EQUAL_TO,
                        'filterValue': lastTimeDCAlive
                    }
                ],
                'fetchSize': 10 * HgAppConfig.DEFAULT_FETCH_SIZE /* 100 items */
            }))
                .then((result) => {
                    const resourceCommentsDS = this.getResourceCommentsDataSource(resourceLink),
                        missedMessages = result.getItems();

                    return resourceCommentsDS ? resourceCommentsDS.addRange(missedMessages) : [];
                });
        }
    }

    /**
     *  Parse the messages query result and process the records into specific message groups
     *  A message group is formed when:
     *  - the sender changes
     *  - the date changes
     *  - the date between messages is greater than hg.HgAppConfig.MESSAGE_GROUP_TIMERANGE
     *
     * @param {ResourceLike} resourceLink The context of the message (conversation, topic, board)
     * @param {hg.data.model.thread.IThread} thread The context of the message (conversation, topic, board)
     * @param {?string=} opt_targetMessageId
     * @param {number=} opt_fetchSize
     * @param {number=} opt_initialFetchSizeFactor
     * @returns {hf.data.ListDataSource}
     */
    getResourceCommentsLoader(
        resourceLink,
        thread,
        opt_targetMessageId,
        opt_fetchSize,
        opt_initialFetchSizeFactor
    ) {
        return createMessagesHistoryLoader(
            this.loadResourceComments.bind(this, resourceLink),
            thread,
            opt_targetMessageId || null,
            opt_fetchSize || HgAppConfig.DEFAULT_FETCH_SIZE,
            opt_initialFetchSizeFactor
        );
    }

    /**
     * @param {ResourceLike=} opt_resourceLink The context of the message (conversation, topic, board)
     */
    clearCommentsCache(opt_resourceLink) {
        if(opt_resourceLink != null
            && !StringUtils.isEmptyOrWhitespace(opt_resourceLink['resourceId'])
            && this.resourceCommentsCache_.hasOwnProperty(opt_resourceLink['resourceId'])) {
            const resourceCommentsDS = this.getResourceCommentsDataSource(opt_resourceLink);

            if(resourceCommentsDS != null) {
                resourceCommentsDS.clear();
                delete this.resourceCommentsCache_[opt_resourceLink['resourceId']];
                BaseUtils.dispose(resourceCommentsDS);

                return true;

            }
        }

        return false;
    }

    /** @inheritDoc */
    init() {
        super.init();

        this.dataChannelService = DataChannelService.getInstance();
        this.messageService = MessageService;

        this.resourceCommentsCache_ = {};

        this.undeliveredMessageQueue_ = [];
        this.undeliveredMessageIndex_ = {};
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.resourceCommentsCache_ = null;

        this.dataChannelService = null;
        this.messageService = null;

        this.undeliveredMessageQueue_ = null;
        this.undeliveredMessageIndex_ = null;

        clearTimeout(this.invalidateMessageThreadsTimerId_);
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_CONNECTION_STATUS_CHANGE, this.handleConnectionChange)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_NEW, this.handleMessageReceived_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_EXISTING, this.handleOldMessages_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_UPDATE, this.handleUpdateMessage_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_RTM_DELETE, this.handleDeleteMessage_)
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.ResourceCommentsService');
    }

    /**
     * @param {!Object=} opt_messageData Initial values for a new message thread
     * @protected
     */
    createMessage(opt_messageData) {
        return new Message(opt_messageData);
    }

    /**
     *
     * @param {Object} resourceLink
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria
     * @returns {Promise}
     * @protected
     */
    loadResourceComments(resourceLink, fetchCriteria) {
        const resourceCommentsDS = this.getResourceCommentsDataSource(resourceLink);

        if(resourceCommentsDS == null) {
            return Promise.resolve(QueryDataResult.empty());
        }

        return resourceCommentsDS.query(fetchCriteria);
    }

    /**
     * @param {Object} resourceLink
     * @returns {hf.data.DataSource}
     * @protected
     */
    getResourceCommentsDataSource(resourceLink) {
        if(!StringUtils.isEmptyOrWhitespace(resourceLink['resourceId'])) {
            return this.resourceCommentsCache_[resourceLink['resourceId']] ||
                (this.resourceCommentsCache_[resourceLink['resourceId']] = new DataSource({'dataProvider': this.loadResourceCommentsInternal_.bind(this, resourceLink)}));
        }

        return null;
    }

    /**
     * Loads the comments for the indicated resource
     * @param {Object} resourceLink
     * @param {!hf.data.criteria.FetchCriteria} fetchCriteria The descriptor used when querying the data source.
     * @return {Promise}
     * @private
     */
    loadResourceCommentsInternal_(resourceLink, fetchCriteria) {
        const translator = Translator;

        let loadResult;

        const messageService = MessageService;
        if(messageService) {
            fetchCriteria.filter({
                'filterBy'   : 'inThread',
                'filterOp'   : FilterOperators.EQUAL_TO,
                'filterValue': {
                    'resourceType'  : resourceLink['resourceType'],
                    'resourceId'    : resourceLink['resourceId']
                }
            });

            loadResult = messageService.loadMessages(fetchCriteria);
        }

        return this.handleErrors(loadResult || Promise.reject(new Error(translator.translate('load_message_failure'))), 'load_message_failure');
    }

    /**
     * @param {!hg.data.model.message.Message} message
     * @protected
     */
    processNewMessage(message) {
        const resourceLinks = [];

        if(message['inThread']) {
            resourceLinks.push(message['inThread']);
        }
        if(message['reference']) {
            resourceLinks.push(message['reference']);
        }
        if(message['replyTo']) {
            resourceLinks.push({
                'resourceId': message['replyTo'],
                'resourceType': HgResourceCanonicalNames.MESSAGE
            });
        }

        resourceLinks.forEach(function(resourceLink) {
            if(this.resourceCommentsCache_.hasOwnProperty(resourceLink['resourceId'])) {
                const resourceCommentsDataSource = this.getResourceCommentsDataSource(resourceLink);

                /* mark the message as new*/
                message['isNewlyAdded'] = true;
                message.acceptChanges(true);

                if(resourceCommentsDataSource != null && resourceCommentsDataSource.add(message) != null) {
                    this.dispatchAppEvent(HgAppEvents.NEW_RESOURCE_COMMENT, {'message': message});
                }
            }
        }, this);
    }

    /**
     * Resend message (most likely traffic rate exceeded)
     * @param {!hg.data.model.message.Message} message
     */
    resendMessage(message) {
        if (!!message['errorSending']) {
            message['errorSending'] = false;
            message['unconfirmed'] = false;

            const payload = this.getSendMessageCommandPayload(message);

            return this.sendMessageInternal(
                DataChannelResource.MESSAGE,
                DataChannelVerb.NEW,
                payload,
                message['nonce'],
                message
            )
                .then(this.onMessageSendSuccess.bind(this, message))
                .catch(this.onMessageSendFailure.bind(this, message));
        }
    }

    /**
     * @param {string} context
     * @param {string} command follows the pattern of event types: {context}/{type}. For example "message/new"
     * @param {Object} payload an optional payload carried by command, has different structure depending on the command type
     * @param {string=} opt_nonce If not provided one is assigned by default
     * @param {hg.data.model.message.Message=} opt_messageInstance
     * @return {Promise}
     * @protected
     */
    sendMessageInternal(context, command, payload, opt_nonce, opt_messageInstance) {
        const storedCommand = {
            context: context,
            command: command,
            payload: payload,
            nonce: opt_nonce,
            message: opt_messageInstance
        };

        if (!StringUtils.isEmptyOrWhitespace(opt_nonce)) {
            this.undeliveredMessageIndex_[opt_nonce] = storedCommand;
        }
        this.undeliveredMessageQueue_.push(storedCommand);

        setTimeout((opt_nonce, opt_messageInstance) => {
            if (!StringUtils.isEmptyOrWhitespace(opt_nonce)
                && this.undeliveredMessageIndex_
                && this.undeliveredMessageIndex_[opt_nonce] != null) {

                if (opt_messageInstance) {
                    opt_messageInstance['unconfirmed'] = true;
                }
            }
        }, HgAppConfig.MESSAGE_TIMEOUT, opt_nonce, opt_messageInstance);

        const promisedResult = this.dataChannelService.sendMessage(context, command, payload, opt_nonce);

        promisedResult
            .then((outcome) => {
                if (!StringUtils.isEmptyOrWhitespace(opt_nonce)) {
                    if (this.undeliveredMessageIndex_) {
                        delete this.undeliveredMessageIndex_[opt_nonce];
                    }

                    if (opt_messageInstance) {
                        opt_messageInstance['unconfirmed'] = false;
                    }
                }

                ArrayUtils.remove(this.undeliveredMessageQueue_, storedCommand);
            });

        return promisedResult;
    }

    /**
     * @param {!hg.data.model.message.Message} message
     * @param {*} outcome
     * @protected
     */
    onMessageSendSuccess(message, outcome) {
        let crDate = HgDateUtils.now();

        if (BaseUtils.isObject(outcome)) {
            /* update the messageId and inThread data. The first message from outcome['dispatched'] is guaranteed to belong to the original thread */
            if (outcome['nonce'] == message['nonce']) {
                message['messageId'] = outcome['messageId'];

                message['inThread'] = HgResourceUtils.getResourceLink(outcome['inThread']);
            }

            /* update the message 'created' date */
            if(outcome.hasOwnProperty('created')) {
                crDate = outcome['created'];
            }

            if(outcome.hasOwnProperty('tag')) {
                message['tag'] = outcome['tag'];
            }
        }
        else {
            message['messageId'] = outcome;
        }

        message['created'] = message['updated'] = crDate;

        /* make sure message is not marked with error as success callback can be called with delay */
        message['unconfirmed'] = false;
        message['errorSending'] = false;


        message.acceptChanges(true);

        const nonce = message['nonce'];
        if (!StringUtils.isEmptyOrWhitespace(nonce)) {
            this.clearUndeliveredMessage(nonce);
        }
    }

    /**
     * We could use the
     * @param {!hg.data.model.message.Message} message
     * @param {*} err
     * @protected
     */
    onMessageSendFailure(message, err) {
        message['errorSending'] = true;
    }

    /**
     * Flush message stanzas from queue snapshot
     * @param {string} nonce
     */
    clearUndeliveredMessage(nonce) {
        let message = this.undeliveredMessageIndex_[nonce];

        if (message != null) {
            delete this.undeliveredMessageIndex_[nonce];
            ArrayUtils.remove(this.undeliveredMessageQueue_, message);
        }
    }

    /**
     * Try resending all failed messages
     * @param {ResourceLike} resourceLink
     * @param {boolean=} opt_isResume
     * @return {Array.<hg.data.model.message.Message>}
     * @private
     */
    flushFailedMessages_(resourceLink, opt_isResume) {
        const undeliveredMessageQueue = this.undeliveredMessageQueue_.slice(0);

        const localMessages = [];
        undeliveredMessageQueue.forEach(function (item) {
            item = /** @type {SMPayload} */(item);

            if (((resourceLink['resourceType'] == item.payload['reference']['resourceType'] && resourceLink['resourceId'] == item.payload['reference']['resourceId']) ||
                (resourceLink['resourceType'] == HgResourceCanonicalNames.MESSAGE && resourceLink['resourceId'] == item.payload['replyTo']['resourceId']))
                && opt_isResume) {
                if (!item.message || !item.message['errorSending']) {
                    item.message['unconfirmed'] = false;
                    item.message['errorSending'] = false;

                    const promisedResult = this.sendMessageInternal(
                        item.context,
                        item.command,
                        item.payload,
                        item.nonce,
                        item.message
                    );

                    if (item.message) {
                        promisedResult
                            .then(this.onMessageSendSuccess.bind(this, item.message))
                            .catch(this.onMessageSendFailure.bind(this, item.message));
                    }
                }
            } else if (item.message) {
                /* dispatch as error all messages in order to be added again in message list */
                item.message['unconfirmed'] = false;
                item.message['errorSending'] = true;
            }

            localMessages.push(/** @type {hg.data.model.message.Message} */(item.message));

            if (!StringUtils.isEmptyOrWhitespace(item.nonce)) {
                delete this.undeliveredMessageIndex_[item.nonce];
            }

            ArrayUtils.remove(this.undeliveredMessageQueue_, item);
        }, this);

        return localMessages;
    }

    /**
     * @protected
     */
    invalidateMessageThreads() {
        let lastTimeDCAlive = this.lastTimeDCAlive_ || HgDateUtils.now();

        this.dispatchAppEvent(HgAppEvents.INVALIDATE_RESOURCE_COMMENTS, {'lastTimeDCAlive': lastTimeDCAlive});
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleMessageReceived_(e) {
        const message = e.getPayload()['message'];

        this.processNewMessage(message);
    }

    /**
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleUpdateMessage_(e) {
        const messageData = e.getPayload()['message'],
            resourceLinks = [];

        if(messageData['inThread']) {
            resourceLinks.push(messageData['inThread']);
        }
        if(messageData['reference']) {
            resourceLinks.push(messageData['reference']);
        }
        if(messageData['replyTo']) {
            resourceLinks.push({
                'resourceId': messageData['replyTo'],
                'resourceType': HgResourceCanonicalNames.MESSAGE
            });
        }

        resourceLinks.forEach(function(resourceLink) {
            if(this.resourceCommentsCache_.hasOwnProperty(resourceLink['resourceId'])) {
                const resourceCommentsDataSource = this.getResourceCommentsDataSource(resourceLink);
                if(resourceCommentsDataSource) {
                    let existingMessage = null;
                    if (resourceCommentsDataSource.contains(messageData['messageId'])) {
                        existingMessage = resourceCommentsDataSource.get(messageData['messageId']);
                    }
                    else {
                        /* might be indexed by nonce */
                        const queryResult = resourceCommentsDataSource.queryLocal({
                            'filters': [{
                                'filterBy': 'messageId',
                                'filterOp': FilterOperators.EQUAL_TO,
                                'filterValue': messageData['messageId']
                            }],
                            'fetchSize': 1
                        });

                        if (queryResult.getCount() == 1) {
                            existingMessage = queryResult.getItems()[0];
                        }
                    }

                    if(existingMessage) {
                        existingMessage.updateData(messageData);

                        this.dispatchAppEvent(HgAppEvents.UPDATE_RESOURCE_COMMENT, {'message': existingMessage});
                    }
                }
            }
        }, this);
    }

    /**
     * Handles the delete of a message on a thread
     * @param {hf.app.AppEvent} e
     * @private
     */
    handleDeleteMessage_(e) {
        const messages = e.getPayload()['deleted'],
            firstMessage = messages[0],
            resourceLinks = [];

        if(firstMessage['inThread']) {
            resourceLinks.push(firstMessage['inThread']);
        }
        if(firstMessage['reference']) {
            resourceLinks.push(firstMessage['reference']);
        }
        if(firstMessage['replyTo']) {
            resourceLinks.push({
                'resourceId': firstMessage['replyTo'],
                'resourceType': HgResourceCanonicalNames.MESSAGE
            });
        }

        resourceLinks.forEach(function(resourceLink) {
            if(this.resourceCommentsCache_.hasOwnProperty(resourceLink['resourceId'])) {
                const resourceCommentsDataSource = this.getResourceCommentsDataSource(resourceLink);
                if(resourceCommentsDataSource) {
                    /* remove the messages from cache */
                    messages.forEach(function (message) {
                        if (resourceCommentsDataSource.contains(message['messageId'])) {
                            resourceCommentsDataSource.remove(message['messageId']);
                        } else {
                            /* might be indexed by nonce */
                            const queryResult = resourceCommentsDataSource.queryLocal({
                                'filters': [{
                                    'filterBy': 'messageId',
                                    'filterOp': FilterOperators.EQUAL_TO,
                                    'filterValue': message['messageId']
                                }],
                                'fetchSize': 1
                            });

                            if (queryResult.getCount() == 1) {
                                resourceCommentsDataSource.remove(queryResult.getItems()[0]['nonce']);
                            }
                        }
                    });
                }

                this.dispatchAppEvent(HgAppEvents.DELETE_RESOURCE_COMMENT, {'deleted': messages});
            }
        }, this);
    }

    /**
     * Handles message portal connection transition
     * @param {hf.events.Event} e Connection status change event
     * @protected
     */
    handleConnectionChange(e) {
        const payload = e.getPayload();

        if (payload['isConnected']) {
            /* invalidate opened message threads, load missing messages from backend (no offline support) */
            clearTimeout(this.invalidateMessageThreadsTimerId_);
            this.invalidateMessageThreadsTimerId_ = setTimeout(() => this.invalidateMessageThreads(), HgAppConfig.MESSAGE_INVALIDATE_DELAY);
        }
        else if(payload['status'] === DataChannelConnectionStatus.DISCONNECTED) {
            this.lastTimeDCAlive_ = this.dataChannelService.getLastTimeAlive();

            clearTimeout(this.invalidateMessageThreadsTimerId_);
        }
    }

    /**
     * Handles a new chat message event (the event is send by the data service on new messages)
     * A new message event might be triggered from both a send and a received message, both are managed in the same way.
     * New message events are added to the messages history only for opened threads.
     *
     * @param {hf.app.AppEvent} e New message app event.
     * @private
     */
    handleOldMessages_(e) {
        // todo: treat payload.integrity: FALSE - an exception occurred during thread retrieval
        const payload = e.getPayload();

        if (payload != null && payload['inThread'] != null && payload['message'] != null) {
            const resourceCommentsDS = this.getResourceCommentsDataSource(payload['inThread']);

            if (resourceCommentsDS != null) {
                const messages = payload['message'].map(function (messageData) {
                    const message = messageData;

                    /* mark the message as new*/
                    if (!message['removed']) {
                        message['isNewlyAdded'] = true;
                    }
                    message.acceptChanges(true);

                    return message;
                }, this);

                this.dispatchAppEvent(HgAppEvents.OLD_RESOURCE_COMMENTS, {
                    'inThread'  : payload['inThread'],
                    'messages'  : messages,
                    'lastTimeDCAlive' : this.dataChannelService.getLastTimeAlive()
                });

                resourceCommentsDS.addRange(messages);
            }
        }
    }

    /**
     *
     * @param {hg.data.model.message.Message} message
     * @return {Object}
     * @protected
     */
    getSendMessageCommandPayload(message) {
        const payload = {
            'body': message['body'],

            'to': {
                'recipientId': message['inThread']['resourceId'],
                'type': message['inThread']['resourceType']
            }
        };

        if (message['reference'] != null) {
            payload['reference'] = {
                'resourceId': message['reference']['resourceId'],
                'resourceType': message['reference']['resourceType']
            };

            if(message['reference']['hint'] != null) {
                payload['reference']['hint'] = message['reference']['hint'];
            }
        }

        if (message['replyTo']) {
            payload['replyTo'] = message['replyTo'];
        }
        
        return payload;
    }
};

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new ResourceCommentsService();

export default instance;