import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";

import {ViewModelBase} from "./../../../../../../hubfront/phpnoenc/js/app/ui/viewmodel/ViewModel.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {SortDirection} from "./../../../../../../hubfront/phpnoenc/js/data/SortDescriptor.js";

import {MAX_SAFE_INTEGER} from "./../../../../../../hubfront/phpnoenc/js/math/Math.js";
import RosterService from "./../../../data/service/RosterService.js";

/**
 * Creates a new {@see hg.module.chat.viewmodel.RosterViewmodel} object.
 *
 * @extends {ViewModelBase}
 * @unrestricted 
*/
export class RosterViewmodel extends ViewModelBase {
    /**
     * @param {!Object=} opt_initData Source object from which this instance gets the initial fields and values
     *
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        const rosterService_ = RosterService;

        /* The default value is undefined; it means we don't know anything about the current thread.
         * If no thread is selected after the roster has been loaded, then the value is null.
         * If a thread is selected after the roster has been loaded, then the value is the id of the thread. */
        this.addField({'name': 'currentRosterId', 'value': undefined});

        /* the roster state */
        this.addField({'name': 'state'});

        /* the collection of roster items */
        this.addField({'name': 'roster', 'getter': this.createLazyGetter('roster', function() {
            return new ListDataSource({
                'dataProvider' : this.loadRoster_.bind(this),
                'fetchCriteria' : {
                    'fetchSize': MAX_SAFE_INTEGER
                },
                'localSorters' : [{
                    /* sort by auto computed position taken into consideration pinned choices, last update and party name */
                    'direction' : SortDirection.ASC,
                    'sortBy'    : 'computedPositionInRoster'
                }]
            });
        })});
    }

    /** @inheritDoc */
    createParentChildLink(child, fieldName) {
        super.createParentChildLink(child, fieldName);

        if(fieldName == 'roster') {
            const itemsView = /**@type {hf.data.ListDataSource}*/(child).getItems();

            this.getEventHandler()
                .listen(itemsView, ObservableChangeEventName, (e) => { return this.onChildChange(fieldName, e); });
        }
    }

    /** @inheritDoc */
    removeParentChildLink(child, fieldName) {
        super.removeParentChildLink(child, fieldName);

        /* see HG-5593*/
        if(fieldName == 'roster') {
            const itemsView = /**@type {hf.data.ListDataSource}*/(child).getItems();

            this.getEventHandler()
                .unlisten(itemsView, ObservableChangeEventName, (e) => { return this.onChildChange(fieldName, e); });
        }
    }

    /**
     *
     * @return {Promise}
     * @private
     */
    loadRoster_() {
        const rosterService = RosterService;

        return rosterService.loadRoster(false);
    }
};
/**
 * Roster load step
 * @type {number}
 * @public
 */
RosterViewmodel.NUM_ROWS = 1;