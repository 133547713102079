/**
 *
 * @unrestricted 
*/
export class HgRegExpUtils {
 constructor() {
     //
 }
};

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.LINKEDIN = /(?:\b|^)(?:https?:\/\/)?(?:www\.)?linkedin\.com\/?/i;


/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.LINKEDIN_PROFILE = /^(?:https?:\/\/)?(?:(?:www|m(?:obile)?)\.)?linkedin\.com\/[a-z0-9]{2,}(?:\/[a-z0-9._\-]+)+\/?$/i;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.TWITTER = /(?:\b|^)(?:https?:\/\/)?(?:www\.)?twitter\.com\/?/i;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.TWITTER_PROFILE = /^(?:https?:\/\/)?(?:(?:www|mobile)\.)?twitter\.com\/[a-z0-9._\-]+\/?$/i;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.FACEBOOK = /(?:\b|^)(?:https?:\/\/)?(?:www\.)?facebook\.com\/?/i;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.FACEBOOK_PROFILE = /^(?:https?:\/\/)?(?:(?:www|m)\.)?facebook\.com\/(?:[a-z0-9._\-]+\/?|profile\.php\?id=\d+)$/i;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.CALLER_PHONE_NUMBER = /(.*?)<(.*?)>/;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.VALID_NAME_RE = /^([^\\"@><{}[\]+\/#!~|$%^,.()=?;*:])*$/;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.VALID_TOPIC_NAME_RE = /^([^\\"@><{}[\]+\/#!~|$%^])*$/;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.GIPHY = /(?:\b|\{highlight})((?:(?:https?):\/\/(?:(?:[a-z0-9]+\.)?giphy\.com(?:\/(?:[\x21\x24\x26-\x2e\x30-\x3b\x3d\x40-\x5a\x5f\x61-\x7a\x7e]|%[0-9a-fA-F][0-9a-fA-F])*)*)\.gif)(?:\?(?:[\x21\x24\x26-\x3b\x3d\x3f-\x5a\x5f\x61-\x7a\x7e]|%[0-9a-fA-F][0-9a-fA-F])*)?(?:#(?:[\x21\x24\x26-\x3b\x3d\x3f-\x5a\x5f\x61-\x7a\x7e]|%[0-9a-fA-F][0-9a-fA-F])*)?)(?:{\/highlight})?/gi;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.GIPHY_FILE_NAME_IN_MESSAGE = /giphy([\w-_]*)?\.gif/g;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.UL_FINDER = /(?:^|\n)( *\* +(?:[^\n]|\n *\*)+\n?)/g;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.UL_FINDER_NL = /(?:^|\n)( *\* +(?:[^\n]|\n *\*)+\n)/g;

/**
 *
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.LI_TRANSFORM = /^ *\* +(.+)\n?/gm;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.ENCODE_STYLE_TAG = /<([ubi]) +style=["']font-(?:weight: (b)old|style: (i)talic).*?["']>([^]*?)<\/\1>/g;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.UNORDERED_LIST_REPLACE = /<ul>|<\/li>|<li>|<\/ul>*/g;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.EMPTY_HIGHLIGHT_REPLACE = /({highlight}{\/highlight})|(<span class="hg-metacontent-highlight"><\/span>)*/g;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.EMPTY_UNORDERED_LIST_REPLACE = /(<ul>(<li>(\s|\t)*<\/li>)*<\/ul>)*/g;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.UNESCAPE_HTML_RE = /&(?:amp|#38|#x26|lt|#60|#x3C|gt|#62|#x3E|apos|#39|#x27|quot|#34|#x22);/;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.EMPTY_TEXT_ONLY_REPLACE = /<div class="textOnly">\s*\n*<\/div>/g;

/**
 * IDN compliant regex for Team Domain. Used in auth process for Android app
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.TEAM_DOMAIN = /^(?:[^\x00-,.\/:-@[-`{-\x7f]+\.){2}[^\x00-\/:-@[-`{-\x7f]+$/;

/**
 * @type {RegExp}
 * @static
 */
HgRegExpUtils.PREVIEWABLE_IMAGE_EXTENSIONS = /^(jpg|jpeg|gif|bmp|png|svg|webp)$/;