import {EventsUtils} from "./../../../../hubfront/phpnoenc/js/events/Events.js";
import {DomUtils} from "./../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../hubfront/phpnoenc/js/base.js";
import {UIUtils} from "./../../../../hubfront/phpnoenc/js/ui/Common.js";
import {AbstractLayout} from "./../../../../hubfront/phpnoenc/js/app/ui/layout/AbstractLayout.js";
import {ServiceLocator} from "./../../../../hubfront/phpnoenc/js/app/servicelocator/ServiceLocator.js";
import {UIComponent} from "./../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {LayoutContainer} from "./../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {BrowserEventType} from "./../../../../hubfront/phpnoenc/js/events/EventType.js";
import {AppEvent} from "./../../../../hubfront/phpnoenc/js/app/events/AppEvent.js";
import {EventHandler} from "./../../../../hubfront/phpnoenc/js/events/EventHandler.js";
import {Popup} from "./../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {ResizeDirection} from "./../../../../hubfront/phpnoenc/js/fx/Resizer/Common.js";
import {LayoutDisplayRegions} from "./LayoutDisplayRegions.js";
import {DialogStates, FSDialogStates, UACStates} from "./../app/States.js";

import {AppDataCategory, AppDataGlobalKey} from "./../data/model/appdata/Enums.js";
import EventBus from "./../../../../hubfront/phpnoenc/js/events/eventbus/EventBus.js";
import {StringUtils} from "../../../../hubfront/phpnoenc/js/string/string.js";
import userAgent from "../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";
import AppDataService from "./../data/service/AppDataService.js";
import { PLT_UID } from "../app/PlatformUID.js";

/**
 * Creates a new Layout object.
 *
 * @extends {AbstractLayout}
 * @unrestricted 
*/
export class AbstractResizableContent extends AbstractLayout {
    /**
     * @param {hf.app.state.AppState} state
    */
    constructor(state) {
        super(state);

        /**
         *
         * @type {Array}
         * @private
         */
        this.tpmResizeListeners_ = [];

        /**
         * An array with the handles for resize.
         * @type {Object}
         * @protected
         */
        this.resizeHandles = {};

        /**
         * Wrap container holding content and dialog region
         * for dialogs that are displayed over the container region
         * @type {hf.ui.UIComponent}
         * @protected
         */
        this.outerContentContainer;

        /**
         * Current resize direction
         * @type {!string}
         * @protected
         */
        this.currentResizer;

        /**
         * Storage of current mouse position in resize event
         * @type {!hf.math.Coordinate}
         * @protected
         */
        this.currentMousePosition;

        /**
         * Storage of outer container width in resize events
         * @type {number}
         * @protected
         */
        this.currentOuterContainerWidth;

        /**
         * Storage of outer container width as set by the user
         * @type {string}
         * @protected
         */
        this.chosenCurrentOuterContainerWidth;

        /**
         * Event handler.
         * @type {hf.events.EventHandler}
         * @private
         */
        this.eventHandler_;

        /**
         * Marker to define if app size adjustment is performed in window resize transition
         * @type {boolean}
         * @protected
         */
        this.inWindowResizeTransition = this.inWindowResizeTransition === undefined ? false : this.inWindowResizeTransition;

        /**
         * @type {number?}
         * @protected
         */
        this.dialogRegionZIndex_ = this.dialogRegionZIndex_ === undefined ? null : this.dialogRegionZIndex_;

        /**
         * @type {number?}
         * @protected
         */
        this.uacRegionZIndex_ = this.uacRegionZIndex_ === undefined ? null : this.uacRegionZIndex_;

        /**
         * @type {number?}
         * @protected
         */
        this.fsdialogRegionZIndex__ = this.fsdialogRegionZIndex__ === undefined ? null : this.fsdialogRegionZIndex__;
    }

    /** @inheritDoc */
    create() {
        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            uacRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.UAC),
            fsDialogRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.FS_DIALOG);

        this.add(/**@type {hf.app.ui.DisplayRegion}*/(uacRegion));
        this.add(/**@type {hf.app.ui.DisplayRegion}*/(fsDialogRegion));

        this.outerContentContainer = new LayoutContainer({
            'extraCSSClass': 'hg-layout-general-content',
            'style': PLT_UID ? {
                'top': '0px'
            } : {}
        });

        /* introduce resize handles */
        this.resizeHandles[ResizeDirection.RIGHT] = new UIComponent({
            'baseCSSClass': 'hg-fx-resizer-handle-' + ResizeDirection.RIGHT
        });
        this.resizeHandles[ResizeDirection.LEFT] = new UIComponent({
            'baseCSSClass': 'hg-fx-resizer-handle-' + ResizeDirection.LEFT
        });
        this.outerContentContainer.addChild(this.resizeHandles[ResizeDirection.RIGHT], true);
        this.outerContentContainer.addChild(this.resizeHandles[ResizeDirection.LEFT], true);

        this.add(this.outerContentContainer);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        if (this.eventHandler_) {
            this.eventHandler_.dispose();
            delete this.eventHandler_;
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        for (let uniqueId in this.resizeHandles) {
            let handle = this.resizeHandles[uniqueId];

            this.getHandler()
                .listen(handle.getElement(), userAgent.device.isDesktop() ? BrowserEventType.MOUSEDOWN : BrowserEventType.TOUCHSTART, (e) => { return this.handleResizeHandleHold(uniqueId, e); });
        }

        this.restoreRegionsWidth();
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if (this.eventHandler_) {
            this.eventHandler_.removeAll();
        }
    }

    /** @inheritDoc */
    update(state) {
        super.update(state);

        this.updateUACRegion();

        this.updateFSDialogRegion();

        this.updateDialogRegion();
    }

    /**
     * Gets the app event bus.
     *
     * @return {hf.events.IEventBus}
     * @protected
     */
    getEventBus() {
        return EventBus;
    }

    /**
     * Returns the event handler for this component, lazily created the first time
     * this method is called.
     * @return {!hf.events.EventHandler} Event handler for this component.
     * @protected
     */
    getHandler() {
        return this.eventHandler_ ||
            (this.eventHandler_ = new EventHandler(this));
    }

    /**
     * Dispatches an App event on the Event Bus.
     *
     * @param {string | hf.app.AppEvent} event
     * @param {Object=} opt_payload
     */
    dispatchAppEvent(event, opt_payload) {
        if (!BaseUtils.isString(event) && !(event instanceof AppEvent)) {
            throw new Error( 'Invalid App Event to dispatch.');
        }

        if(BaseUtils.isString(event)) {
            event = new AppEvent((event), opt_payload);
        }

        this.getEventBus().dispatchEvent(event);
    }

    async restoreRegionsWidth() {
        const appDataService = AppDataService;
        if (appDataService) {
            /* contentZoneParam */
            const contentZoneParam = await appDataService.getAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.CONTENT_ZONE_WIDTH);
            if(contentZoneParam && contentZoneParam['value'] != null) {
                if (contentZoneParam['value'].endsWith('%')) {
                    this.setOuterContainerWidth(Math.min(contentZoneParam['value'].replace('%', ''), 100) + '%', true);
                }
                else {
                    this.setOuterContainerWidth(contentZoneParam['value'], true);
                }
            }
        }
    };

    /**
     * Checks the necessity of the display region based on the  current app state
     *
     * @return {void}
     * @protected
     */
    updateUACRegion() {
        const uacRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.UAC),
            stateName = this.getState().getName();

        this.uacRegionZIndex_ = (UACStates.includes(stateName)? 1 : -1) * 99999999;

        uacRegion.setStyle('zIndex', this.uacRegionZIndex_);
    }

    /**
     * Checks the necessity of the display region based on the  current app state
     *
     * @return {void}
     * @protected
     */
    updateFSDialogRegion() {
        const fsDialogRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.FS_DIALOG),
            stateName = this.getState().getName();

        if(UACStates.includes(stateName)) {
            return;
        }

        /* set the zIndex if it's not set (i.e. the dialog is opening now) */
        if(FSDialogStates.includes(stateName)) {
            if(this.fsdialogRegionZIndex__ == null) {
                this.fsdialogRegionZIndex__ = Popup.POPUP_Z_INDEX++;
            }
        }
        else {
            this.fsdialogRegionZIndex__ = null;
        }

        fsDialogRegion.setStyle('zIndex', this.fsdialogRegionZIndex__);
        fsDialogRegion.setVisible(this.fsdialogRegionZIndex__ != null);
    }

    /**
     * Checks the necessity of the display region based on the  current app state
     *  - removes dialog region from the app if not required by the current state
     *  - adds the dialog region in the app if required by the current state:
     *      rendered either in the left-side container or in the main content container if it does not fit
     *
     * @return {void}
     * @protected
     */
    updateDialogRegion() {
        const dialogRegion = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry().getRegion(LayoutDisplayRegions.DIALOG),
            stateName = this.getState().getName();

        /* do nothing if the state is a UAC state or a FSDialog state*/
        if(UACStates.includes(stateName) || FSDialogStates.includes(stateName)) {
            return;
        }

        const dialogState = DialogStates.find(function (item) {
            return item.name == stateName;
        });

        if (dialogState == null) {
            if (dialogRegion.isInDocument()) {
                /* remove dialog from wrapper container (left-side, content) */
                const container = dialogRegion.getParent();
                container.removeChild(dialogRegion, true);
            }

            this.dialogRegionZIndex_ = null;

            return;
        }

        /* insert dialog region in main content, excluding footer */
        const container = this.contentContainer_;

        dialogRegion.beginMoveTransition();

        /* set the zIndex if it's not set (i.e. the dialog is opening now) */
        if(this.dialogRegionZIndex_ == null) {
            this.dialogRegionZIndex_ = Popup.POPUP_Z_INDEX++;
        }

        //container.addChild(dialogRegion, !dialogRegion.isInDocument());
        if(container.indexOfChild(dialogRegion) == -1) {
            container.addChild(dialogRegion, !dialogRegion.isInDocument());
        }

        /* update the dialog's zIndex */
        dialogRegion.setStyle('zIndex', this.dialogRegionZIndex_);

        dialogRegion.endMoveTransition();
    }

    /**
     * Set the working zone width
     * @param {string} width
     * @param {boolean=} opt_userChosen
     * @private
     */
    setOuterContainerWidth(width, opt_userChosen) {
        this.outerContentContainer.getElement().style.width = width;

        const regionRegistry = /**@type {hf.app.IAppServiceLocator}*/ (ServiceLocator.getLocator()).getDisplayRegionsRegistry(),
            headerRegion = /**@type {!hf.app.ui.DisplayRegion}*/(regionRegistry.getRegion(LayoutDisplayRegions.HEADER));

        const headerElem = headerRegion ? headerRegion.getElement() : null;
        if (headerElem) {
            headerElem.style.width = 'calc(' + width + ' - 12px)';
        }

        if (opt_userChosen) {
            this.chosenCurrentOuterContainerWidth = width;
        }

        this.onViewportResize();
    }

    /**
     * Adjust left side container with on viewport resize (window/outer container)
     * @protected
     */
    onViewportResize() {
        this.updateDialogRegion();
    }

    /**
     * Check if in window resize transition processing
     * @return {boolean}
     */
    isInWindowResizeTransition() {
        return this.inWindowResizeTransition;
    }

    /** @inheritDoc */
    handleWindowResize(e) {
        this.inWindowResizeTransition = true;

        const viewportSize = DomUtils.getViewportSize();
        if (viewportSize.width < 1200) {
            this.setOuterContainerWidth('100%');
            this.currentOuterContainerWidth = viewportSize.width;
        } else {
            if (!StringUtils.isEmptyOrWhitespace(this.chosenCurrentOuterContainerWidth)) {
                this.setOuterContainerWidth(this.chosenCurrentOuterContainerWidth);
            }

            this.currentOuterContainerWidth = this.outerContentContainer.getSize(true).width;
        }

        this.onViewportResize();

        this.inWindowResizeTransition = false;
    }

    /**
     * Handles resize handle hold
     * @param {string} uniqueId
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleHold(uniqueId, e) {
        /* prevent text selection on resize */
        e.preventDefault();

        /* make sure there is no previous resize transaction started
         * could be for handle release over iframes */
        this.endResizeTransition();

        /* store initial mouse position on resize start */
        this.currentMousePosition = UIUtils.getMousePosition(e);
        this.currentResizer = uniqueId;

        /* compute the selector size on resize start to avoid reflows in each MOUSEMOVE event */
        this.currentOuterContainerWidth = this.outerContentContainer.getSize(true).width;

        this.beginResizeTransition();
    }

    /**
     * Handles the mouse move events
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleMove(e) {
        const mousePosition = UIUtils.getMousePosition(e), /* resize with double offset as it resizes both in left and right */
            offsetX = 2 * (mousePosition.x - this.currentMousePosition.x);

        /* update current mouse position */
        this.currentMousePosition = UIUtils.getMousePosition(e);

        this.resizeOuterContainer_(offsetX);

        /* simulate a window resize event to allow popups to reposition when layout is resized */
        document.dispatchEvent(new Event(BrowserEventType.VIEWPORT_RESIZE));
    }

    /**
     * Handles resize handle hold
     * @param {hf.events.Event} e
     * @protected
     */
    handleResizeHandleRelease(e) {
        this.endResizeTransition();

        const mousePosition = UIUtils.getMousePosition(e), /* resize with double offset as it resizes both in left and right */
            offsetX = 2 * (mousePosition.x - this.currentMousePosition.x);

        /* update current mouse position */
        this.currentMousePosition = UIUtils.getMousePosition(e);

        /* update selector size */
        this.resizeOuterContainer_(offsetX);

        /* compute percent size of the container instead of absolute to enable same behavior on other resolutions */
        const viewportSize = DomUtils.getViewportSize(),
            percent = Math.min(100, (this.currentOuterContainerWidth / viewportSize.width) * 100);

        this.setOuterContainerWidth(percent + '%', true);

        this.outerContentContainer.onResize();

        /* store in appdata new value of working viewport */
        const appDataService = AppDataService;
        if (appDataService) {
            appDataService.updateAppDataParam(AppDataCategory.GLOBAL, AppDataGlobalKey.CONTENT_ZONE_WIDTH, percent + '%', true, true);
        }
    }

    /**
     * @protected
     */
    beginResizeTransition() {
        const isDesktop = userAgent.device.isDesktop();

        document.body.classList.add('hg-layout-general-content-resizing');

        /* listen to mousemove and release events */
        this.tpmResizeListeners_.push(EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEMOVE : BrowserEventType.TOUCHMOVE, this.handleResizeHandleMove, false, this));
        this.tpmResizeListeners_.push(EventsUtils.listen(document, isDesktop ? BrowserEventType.MOUSEUP : BrowserEventType.TOUCHEND, this.handleResizeHandleRelease, false, this));
    }

    /**
     * @protected
     */
    endResizeTransition() {
        document.body.classList.remove('hg-layout-general-content-resizing');

        this.tpmResizeListeners_.forEach(EventsUtils.unlistenByKey);
        this.tpmResizeListeners_ = [];
    }

    /**
     * Resize selector
     * @param {number} offsetX
     * @private
     */
    resizeOuterContainer_(offsetX) {
        const factor = (this.currentResizer == ResizeDirection.LEFT) ? -1 : 1;
        let newWidth = this.currentOuterContainerWidth + offsetX * factor;
        const viewportSize = DomUtils.getViewportSize();

        if (newWidth > viewportSize.width) {
            newWidth = viewportSize.width;
        }

        const minWidth = viewportSize.width > 1220 ? 1200 : 548;
        if (newWidth < minWidth) {
            newWidth = minWidth;
        }

        this.setOuterContainerWidth(this.currentOuterContainerWidth + 'px');

        /* update current mouse position and size */
        this.currentOuterContainerWidth = newWidth;
    }
};