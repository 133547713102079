import { LayoutContainer } from './LayoutContainer.js';
import { BaseUtils } from '../../base.js';

/**
 *
 * @augments {LayoutContainer}
 *
 */
export class SingleContentContainer extends LayoutContainer {
    /**
     * @param {!object=} opt_config The configuration object for the vertical layout container.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);
    }

    /**
     * Sets the (single) content of the container
     *
     * @param {hf.ui.UIComponentBase} content
     */
    setContent(content) {
        this.addChildAt(content, 0, true);
    }

    /**
     * Gets the (single) content of the container
     *
     * @returns {hf.ui.UIComponentBase} content
     */
    getContent() {
        return this.getChildAt(0);
    }

    /**
     * Return true if the container has content; otherwise false.
     *
     * @returns {boolean}
     */
    hasContent() {
        return this.getChildCount() > 0;
    }

    /**
     * Return true if the container contains the specified chidl; otherwise false.
     *
     * @param child
     * @returns {boolean}
     */
    contains(child) {
        return this.indexOfChild(child) > 0;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        /* Call the parent method with the right parameters */
        super.init(opt_config);
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return SingleContentContainer.CssClasses.BASE;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return SingleContentContainer.CssClasses.BASE;
    }

    /** @inheritDoc */
    addChildAt(child, index, opt_render) {
        /* if the child is already in container then stop here */
        if (this.indexOfChild(child) > -1) {
            return;
        }

        this.removeChildren(true);

        if (child) {
            (/** @type {hf.ui.UIComponent} */ (child)).addExtraCSSClass(SingleContentContainer.CssClasses.CONTENT);

            super.addChildAt(child, index, opt_render);
        }
    }

    /** @inheritDoc */
    addChildren(children, opt_index) {
        if (!BaseUtils.isArray(children)) {
            throw new Error('Provided \'children\' parameter must be Array.');
        }

        this.setContent(children[0]);
    }

    /** @inheritDoc */
    removeChild(child, opt_unrender) {
        if (child && this.indexOfChild(/** @type {hf.ui.UIComponent} */(child)) > -1) {
            (/** @type {hf.ui.UIComponent} */(child)).removeExtraCSSClass(SingleContentContainer.CssClasses.CONTENT);
        }

        return super.removeChild(child, opt_unrender);
    }
}
/**
 * The prefix we use for the CSS class names for the list itself and its elements.
 *
 * @type {string}
 * @protected
 */
SingleContentContainer.CSS_CLASS_PREFIX = 'hf-layout-single-content-container';
/**
 * CSS classes by this component
 *
 * @static
 * @protected
 */
SingleContentContainer.CssClasses = {
    BASE: SingleContentContainer.CSS_CLASS_PREFIX,

    CONTENT: `${SingleContentContainer.CSS_CLASS_PREFIX}-` + 'content'
};
