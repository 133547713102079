import {DataBindingMode} from "./../../../../../../../hubfront/phpnoenc/js/ui/databinding/BindingBase.js";
import {ListLoadingTrigger} from "./../../../../../../../hubfront/phpnoenc/js/ui/list/List.js";
import {CommonBusyContexts} from "./../../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {AutoComplete} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {Text} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Text.js";
import {Checkbox} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {DropDownList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/DropDownList.js";
import {FormFieldValidateOn} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {FieldGroup} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldGroup.js";
import {FieldList} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/FieldList.js";
import {FieldGroupFieldsLayout} from "./../../../../../../../hubfront/phpnoenc/js/ui/form/fieldgroup/AbstractFieldGroup.js";
import {HgAppConfig} from "./../../../../app/Config.js";

import {HgRegExpUtils} from "./../../../../common/regexp.js";
import {AbstractPerson} from "./AbstractPerson.js";
import {PrefixedText} from "./../../../../common/ui/form/field/PrefixedText.js";
import {AvatarSelector} from "./../../../../common/ui/avatar/AvatarSelector.js";
import {AvatarSizes} from "./../../../../common/ui/avatar/Common.js";
import {StringUtils} from "../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";
import PersonService from "./../../../../data/service/PersonService.js";

/**
 * @extends {AbstractPerson}
 * @unrestricted 
*/
export class EditPerson extends AbstractPerson {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Avatar field
         * @type {hg.common.ui.avatar.AvatarSelector}
         * @private
         */
        this.avatarSelector_;
    }

    /**
     * Get the checkbox used to set the shared level of an user
     * @return {hf.ui.form.field.FormFieldBase}
     */
    getShareField() {
        return this.getField(AbstractPerson.FieldName.SHARE);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.avatarSelector_);
        this.avatarSelector_ = null;
    }

    /** @inheritDoc */
    initFields() {
        const translator = Translator;

        /* Personal details region */

        this.avatarSelector_ = new AvatarSelector({
            'extraCSSClass': ['hg-person-avatar'],
            'avatarSize': AvatarSizes.XXLARGE
        });

        this.addField(new Text({
            'name'              : AbstractPerson.FieldName.LAST_NAME,
            'placeholder'       : translator.translate('last_required'),
            'extraCSSClass'     : ['hg-person-last-name', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'              : AbstractPerson.FieldName.FIRST_NAME,
            'placeholder'       : translator.translate('first_required'),
            'extraCSSClass'     : ['hg-person-first-name', 'hg-medium'],
            'autocomplete'      : false,
            'maxlength'         : 32,
            'autofocus'         : true,
            'validation'        : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': true
            }
        }));

        this.addField(new Text({
            'name'         : AbstractPerson.FieldName.JOB,
            'extraCSSClass': ['hg-medium'],
            'autocomplete' : false,
            'placeholder'  : translator.translate('title'),
            'validation'   : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new AutoComplete({
            'name'         : AbstractPerson.FieldName.COMPANY,
            'extraCSSClass': ['hg-person-organization', 'hg-medium'],
            'placeholder'  : translator.translate('company'),
            'displayField' : 'name',
            'validation'   : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'loadMoreItemsTrigger': ListLoadingTrigger.END_EDGE,
            'popup': {
                'showArrow'     : true,
                'extraCSSClass'	: ['hg-popup', 'whitescheme', 'hg-person-company-autocomplete']
            }
        }));

        /* Adress region */

        this.addField(new Text({
            'label'       : this.getFieldLabelSettings(translator.translate('street')),
            'name'        : AbstractPerson.FieldName.STREET,
            'extraCSSClass' : 'hg-person-details-address-street',
            'autocomplete': false,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'label'       : this.getFieldLabelSettings(translator.translate('city')),
            'name'        : AbstractPerson.FieldName.CITY,
            'extraCSSClass' : 'hg-person-details-address-city',
            'autocomplete': false,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new Text({
            'label'       : this.getFieldLabelSettings(translator.translate('postal_code')),
            'name'        : AbstractPerson.FieldName.PCODE,
            'autocomplete': false,
            'maxlength'   : 10,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new DropDownList({
            'label'          : this.getFieldLabelSettings(translator.translate('country')),
            'displayField'   : 'name',
            'name'           : AbstractPerson.FieldName.COUNTRY,
            'errorFormatter' : function(error) {
                return error['message'] != null ? error['message'] : error;
            },
            'validation'     : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            }
        }));

        this.addField(new DropDownList({
            'label'          : this.getFieldLabelSettings(translator.translate('region')),
            'displayField'   : 'name',
            'valueField'     : 'code',
            'name'           : AbstractPerson.FieldName.REGION,
            'validation'  : {
                'validateOn': FormFieldValidateOn.BLUR,
                'enableValidationWhenNoValue': true,
                'showErrors': false
            }
        }));

        this.fieldGroupAddress = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-address',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : this.getFieldLabelSettings(translator.translate('address')),
            'fields'      : [
                this.getField(AbstractPerson.FieldName.STREET),
                this.getField(AbstractPerson.FieldName.CITY),
                this.getField(AbstractPerson.FieldName.PCODE),
                this.getField(AbstractPerson.FieldName.COUNTRY),
                this.getField(AbstractPerson.FieldName.REGION)
            ]
        });

        /* Contact region */

        const multiFieldEmptyContent = function () {
            return null;
        };

        const personService = PersonService;

        this.fieldEmail = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('email')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'email', personService.getEmailLabels()),
            'emptyContentFormatter': multiFieldEmptyContent,
            'minCount'             : 1,
            'maxCount'             : HgAppConfig.CONTACT_TYPE_MAX_COUNT,
            'addButton'            : {
                'content': translator.translate('add_email')
            }
        });

        this.fieldPhone = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('phone')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'phone', personService.getPhoneLabels()),
            'emptyContentFormatter': multiFieldEmptyContent,
            'minCount'             : 1,
            'maxCount'             : HgAppConfig.CONTACT_TYPE_MAX_COUNT,
            'addButton'            : {
                'content': translator.translate('add_phone')
            }
        });

        this.fieldFax = new FieldList({
            'label'                : this.getFieldLabelSettings(translator.translate('fax')),
            'fieldContentFormatter': this.createMultiFieldItem_.bind(this, 'fax', personService.getFaxLabels()),
            'emptyContentFormatter': multiFieldEmptyContent,
            'minCount'             : 1,
            'maxCount'             : HgAppConfig.CONTACT_TYPE_MAX_COUNT,
            'addButton'            : {
                'content': translator.translate('add_fax')
            }
        });

        this.fieldGroupContact = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-contact',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : this.getFieldLabelSettings(translator.translate('Contact')),
            'fields': [
                this.fieldEmail,
                this.fieldPhone,
                this.fieldFax
            ]
        });

        /* Social region */

        this.addField(new PrefixedText({
            'label'         : this.getFieldLabelSettings(translator.translate('linkedin')),
            'valuePrefix'   : 'linkedin.com/',
            'name'          : AbstractPerson.FieldName.LINKEDIN,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));

        this.addField(new PrefixedText({
            'label'         : this.getFieldLabelSettings(translator.translate('twitter')),
            'valuePrefix'   : 'twitter.com/',
            'name'          : AbstractPerson.FieldName.TWITTER,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));

        this.addField(new PrefixedText({
            'label'         : this.getFieldLabelSettings(translator.translate('facebook')),
            'valuePrefix'   : 'facebook.com/',
            'name'          : AbstractPerson.FieldName.FACEBOOK,
            'autocomplete'  : false,
            'validation'    : {
                'validateOn': FormFieldValidateOn.BLUR,
                'showErrors': false
            },
            'placeholder'   : translator.translate('click_to_configure')
        }));

        this.fieldGroupSocial = new FieldGroup({
            'extraCSSClass' : 'hg-person-details-social',
            'fieldsLayout'  : FieldGroupFieldsLayout.VERTICAL,
            'label'         : this.getFieldLabelSettings(translator.translate('social_profile')),
            'fields'      : [
                this.getField(AbstractPerson.FieldName.LINKEDIN),
                this.getField(AbstractPerson.FieldName.TWITTER),
                this.getField(AbstractPerson.FieldName.FACEBOOK)
            ]
        });

        /* Share */

        this.addField(new Checkbox({
            'name'                      : AbstractPerson.FieldName.SHARE,
            'extraCSSClass'             : 'hg-person-share',
            'readonlyDisplayFormatter'  : function(value, displayValue) {
                return '';
            }
        }));
    }

    /** @inheritDoc */
    initBindings() {
        /* Personal details region */

        this.setBinding(this.avatarSelector_, {'set': this.avatarSelector_.setModel}, 'personEdit');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.FIRST_NAME), 'personEdit.firstName');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.LAST_NAME), 'personEdit.lastName');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.JOB), 'personEdit.job.title');

        this.bindFieldValue(this.getField(AbstractPerson.FieldName.COMPANY), 'personEdit.job.organizationName');
        this.setBinding(this.getField(AbstractPerson.FieldName.COMPANY), {'set': this.getField(AbstractPerson.FieldName.COMPANY).setItemsSource}, 'organizations');

        /* Address region */

        this.bindFieldValue(this.getField(AbstractPerson.FieldName.STREET), 'personEdit.address.street');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.CITY), 'personEdit.address.city');
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.PCODE), 'personEdit.address.postalCode');

        const country = this.getField(AbstractPerson.FieldName.COUNTRY);
        this.bindFieldValue(country, 'personEdit.address.region.country');
        this.setBinding(country, {'set': country.setItemsSource}, 'countries');

        const region = this.getField(AbstractPerson.FieldName.REGION);
        this.bindFieldValue(region, 'personEdit.address.region.code');
        this.setBinding(region, {'set': region.setItemsSource}, 'regions');
        this.setBinding(region, {'set': region.setVisible},
            {
                'sources': [
                    {'sourceProperty': 'personEdit.address.region.code'},
                    {'sourceProperty': 'personEdit.address.region.name'},
                    {'sourceProperty': 'personEdit.address.region.country.regionCount'},
                    {'sourceProperty': 'personEdit.address.region.country'}
                ],
                'converter': {
                    'sourceToTargetFn': function(sources) {
                        const regionCode = sources[0],
                            regionName = sources[1],
                            countryRegionCount = sources[2];

                        return (!StringUtils.isEmptyOrWhitespace(regionCode) && !(StringUtils.isEmptyOrWhitespace(regionName))) ||
                            (countryRegionCount != null && countryRegionCount > 0);
                    }
                }
            }
        );
        this.setBinding(region, {'get': region.getSelectedItem},
            {
                'sourceProperty': 'personEdit.address.region.name',
                'mode': DataBindingMode.ONE_WAY_TO_SOURCE,
                'converter': {
                    'targetToSourceFn': function(regionValue) {
                        return regionValue != null ? regionValue['name'] : undefined;
                    }
                }
            }
        );

        /* Contact region */

        this.bindFieldValue(this.fieldPhone, 'personEdit.contact.phone');
        this.bindFieldValue(this.fieldEmail, 'personEdit.contact.email');
        this.bindFieldValue(this.fieldFax, 'personEdit.contact.fax');

        /* Social region */
        this.bindFieldValue(this.getField(AbstractPerson.FieldName.LINKEDIN), {
            'sourceProperty': 'personEdit.contact.linkedin',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (linkedinAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(linkedinAccount)) {
                        return linkedinAccount.replace(HgRegExpUtils.LINKEDIN, '');
                    }

                    return linkedinAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('linkedin.com')) {
                        return 'https://www.linkedin.com/' + username;
                    }

                    return username;
                }
            }
        });

        this.bindFieldValue(this.getField(AbstractPerson.FieldName.TWITTER), {
            'sourceProperty': 'personEdit.contact.twitter',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (twitterAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(twitterAccount)) {
                        return twitterAccount.replace(HgRegExpUtils.TWITTER, '');
                    }

                    return twitterAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('twitter.com')) {
                        return 'https://twitter.com/' + username;
                    }

                    return username;
                }
            }
        });

        this.bindFieldValue(this.getField(AbstractPerson.FieldName.FACEBOOK), {
            'sourceProperty': 'personEdit.contact.facebook',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function (facebookAccount) {
                    if (!StringUtils.isEmptyOrWhitespace(facebookAccount)) {
                        return facebookAccount.replace(HgRegExpUtils.FACEBOOK, '');
                    }

                    return facebookAccount;
                },
                'targetToSourceFn': function (username) {
                    if (!StringUtils.isEmptyOrWhitespace(username) && !username.includes('facebook.com')) {
                        return 'https://www.facebook.com/' + username;
                    }

                    return username;
                }
            }
        });

        /* Share region */
        const shareBtn = this.getField(AbstractPerson.FieldName.SHARE);
        if (shareBtn) {
            this.setBinding(shareBtn, {'set': shareBtn.setValue, 'get': shareBtn.getValue},
                {
                    'sourceProperty': 'personEdit.orgShared',
                    'mode': DataBindingMode.TWO_WAY
                }
            );
            this.setBinding(shareBtn, {'set': shareBtn.setVisible}, {
                'sourceProperty': 'personEdit',
                'converter': {
                    'sourceToTargetFn': function (person) {
                        return person && person.isNew();
                    }
                }
            });
        }
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        super.enableIsBusyBehavior(enable, opt_busyContext);

        if(opt_busyContext === CommonBusyContexts.SUBMIT) {
            this.forEachField(function(field) { field.setEnabled(!enable); });

            this.avatarSelector_.setEnabled(!enable);

            this.fieldEmail.setEnabled(!enable);
            this.fieldPhone.setEnabled(!enable);
            this.fieldFax.setEnabled(!enable);
        }
    }

    /** @inheritDoc */
    getAvatarComponent() {
        return this.avatarSelector_;
    }

    /** @inheritDoc */
    createNameDom() {
        const container = new LayoutContainer({'extraCSSClass': 'hg-person-name-container'});

        container.addChild(this.getField(AbstractPerson.FieldName.FIRST_NAME), true);
        container.addChild(this.getField(AbstractPerson.FieldName.LAST_NAME), true);

        return container;
    }

    /** @inheritDoc */
    createOccupationDom() {
        const container = new LayoutContainer({'extraCSSClass': 'hg-person-job-container'});
            container.addChild(this.getField(AbstractPerson.FieldName.JOB), true);
            container.addChild(this.getField(AbstractPerson.FieldName.COMPANY), true);

        return container;
    }

    /**
     * Creates a multi field item
     * @param {string} type The type of the contact field, i.e.: email, phone, fax
     * @param {Array} labels Set of supported labels for the field type
     * @param {hg.data.model.label.Label} model The current multi-field item model
     * @param {hf.ui.form.FieldList} field The multi-field instance
     * @param {hf.ui.UIComponent} itemContainer
     * @returns {hf.ui.UIComponent}
     * @private
     */
    createMultiFieldItem_(type, labels, model, field, itemContainer) {
        if(model == null) {
            return null;
        }

        /* Initialize form fields */
        const text = new Text({
            'name': 'person_' + type,
            'extraCSSClass': ['hg-person-multi-field-item', 'hg-person-' + type],
            'validation': {
                'validateOn': FormFieldValidateOn.VALUE_CHANGE,
                'showErrors': false
            }
        });

        if(type === 'phone'){
            text.setMaxlength(HgAppConfig.PHONE_NUMBER_MAX_LENGTH);
        }
        else if(type === 'fax'){
            text.setMaxlength(HgAppConfig.PHONE_NUMBER_MAX_LENGTH);
        }

        const combo = new DropDownList({
            'itemsSource': labels,
            'displayField': 'text',
            'valueField': 'value',
            'name': 'person_' + type + '_label',
            'extraCSSClass': ['hg-person-multi-field-item-label', 'hg-person-' + type + '-label']
        });

        /* initialize bindings on multi-form field item */
        itemContainer.setBinding(text, { 'get': text.getValue, 'set': text.setValue }, {
            'sourceProperty': 'value',
            'mode'          : DataBindingMode.TWO_WAY
        });

        itemContainer.setBinding(combo, { 'get': combo.getValue, 'set': combo.setValue }, {
            'sourceProperty': 'label',
            'mode'          : DataBindingMode.TWO_WAY,
            'converter'     : {
                'sourceToTargetFn': function(value) {
                    return value;
                },
                'targetToSourceFn': function(value) {
                    return value;
                }
            }
        });

        /* Create dom */
        const content = new LayoutContainer();
        content.addChild(text, true);
        content.addChild(combo, true);

        return content;
    }
};