import {HgResourceActionTypes, HgResourceCanonicalNames} from "./../../../data/model/resource/Enums.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {BasePresenter} from "./../presenter/BasePresenter.js";
import {PopupPlacementMode} from "./../../../../../../hubfront/phpnoenc/js/ui/popup/Popup.js";
import {FetchCriteria} from "./../../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {HgAppEvents} from "./../../../app/Events.js";
import {ResourceInfoBubble} from "./ResourceInfoBubble.js";
import {ResourceViewViewmodel} from "./../viewmodel/ResourceView.js";
import {HgResourceUtils} from "./../../../data/model/resource/Common.js";
import {PromiseUtils} from "./../../../../../../hubfront/phpnoenc/js/promise/promise.js";
import WatchService from "./../../../data/service/WatchService.js";
import MessageThreadService from "./../../../data/service/MessageThreadService.js";
import {FunctionsUtils} from "./../../../../../../hubfront/phpnoenc/js/functions/index.js";
import {TopicType} from "../../../data/model/thread/Enums.js";
import {ContactModes} from "../../enums/Enums.js";

/**
 *
 * @extends {BasePresenter}
 * @unrestricted 
*/
export class ResourceActionManager extends BasePresenter {
    constructor() {
        /* Call the base class constructor */
        super();

        /**
         * The Promise used for loading the resource's model.
         * @type {Promise}
         * @private
         */
        this.loadResourceDataPromise_;

        /**
         * The debounced function used to load the resource data
         * @type {?Function}
         * @private
         */
        this.viewResourceInfoDebouncedFn_;

        /**
         * The current resource like object (it contains resourceId and resourceType)
         * @type {ResourceLike}
         * @private
         */
        this.currentResourceLikeObject_;

        /**
         * The current resource model
         * @type {hg.common.ui.viewmodel.ResourceViewViewmodel}
         * @private
         */
        this.resourceViewModel_;

        /**
         * The component that originated the view resource action
         * @type {hf.ui.UIComponent}
         * @private
         */
        this.origin_ = this.origin_ === undefined ? null : this.origin_;
    }

    /**
     *
     * @param {Object} resourceActionInfo
     */
    handleResourceAction(resourceActionInfo) {
        if (resourceActionInfo != null) {
            const resource = resourceActionInfo['resource'],
                resourceAction = resourceActionInfo['resourceAction'];

            if (resource && resourceAction) {
                switch (resourceAction) {
                    case HgResourceActionTypes.VIEW:
                        this.viewResourceDetails(resourceActionInfo);
                        break;

                    case HgResourceActionTypes.VIEW_INFO:
                        if(!this.viewResourceInfoDebouncedFn_) {
                            this.viewResourceInfoDebouncedFn_ = FunctionsUtils.debounce(this.viewResourceInfo, 500, this);
                        }
                        this.viewResourceInfoDebouncedFn_(resourceActionInfo);

                        break;

                    case HgResourceActionTypes.COMMENT:
                        this.commentOnResource(resourceActionInfo);
                        break;
                }
            }
        }
    }

    /**
     * Gets the component that originated the view resource action
     * @return {null|hf.ui.UIComponent|*}
     */
    getOrigin() {
        return this.origin_;
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    cleanup() {
        if (this.getInfoBubble()) {
            this.getInfoBubble().exitDocument();
        }
        BaseUtils.dispose(this.getInfoBubble());

        /* Break the link between this Presenter and its model before calling the base class method;
        the base class method disposes the model which is wrong in this case because
         * the Topic instance is shared (cached) */
        this.setModel(null);

        this.cancelLoadingPromise();
        this.loadResourceDataPromise_ = null;

        this.viewResourceInfoDebouncedFn_ = null;

        this.resourceViewModel_ = null;
        this.currentResourceLikeObject_ = null;

        this.origin_ = null;

        /* call the base class method */
        super.cleanup();
    }

    /** @inheritDoc */
    listenToEventBusEvents(eventBus) {
        super.listenToEventBusEvents(eventBus);

        this.getHandler()
            .listen(eventBus, HgAppEvents.RESOURCE_ACTION, this.handleResourceActionEvent_);
    }

    /** @inheritDoc */
    loadView() {
        const infoBubble = new ResourceInfoBubble();

        infoBubble.addListener(UIComponentEventTypes.OPEN, this.onOpenInfoBubble_, false, this);
        infoBubble.addListener(UIComponentEventTypes.CLOSE, this.onCloseInfoBubble_, false, this);

        return infoBubble;
    }

    /** @inheritDoc */
    showView(opt_DisplayRegion) {
        //nop
    }

    /**
     * Gets the resource' info bubble instance
     * @returns {hg.common.ui.bubble.ResourceInfoBubble}
     * @protected
     */
    getInfoBubble() {
        return /** @type {hg.common.ui.bubble.ResourceInfoBubble} */ (this.getView());
    }

    /**
     * @return {hg.common.ui.viewmodel.ResourceViewViewmodel}
     * @protected
     */
    getResourceViewModel() {
        return this.resourceViewModel_ || (this.resourceViewModel_ = new ResourceViewViewmodel());
    }

    isTheSameResource(resourceLikeObject) {
        const currentResourceLikeObject = this.currentResourceLikeObject_;

        return resourceLikeObject != null
            && currentResourceLikeObject != null
            && resourceLikeObject['resourceId'] === currentResourceLikeObject['resourceId']
            && resourceLikeObject['resourceType'] === currentResourceLikeObject['resourceType']
    }

    /**
     * @param {ResourceLike} resourceLikeObject
     * @return {Promise}
     * @private
     */
    async loadResourceData(resourceLikeObject) {
        const resourceViewModel = this.getResourceViewModel();

        if (!this.isTheSameResource(resourceLikeObject)) {
            this.cancelLoadingPromise();

            this.currentResourceLikeObject_ = resourceLikeObject;

            this.setLoadingPromise(this.loadResourceDetails(resourceLikeObject)
                .then((resource) => {
                    if (resource != null) {
                        return this.loadResourceWatchers_(resourceLikeObject)
                            .then((watchers) => {
                                resourceViewModel['resource'] = resource;

                                if (BaseUtils.isArray(watchers)) {
                                    /**@type {AuthorCollection}*/(resourceViewModel['watchers']).reset(/**@type {!Array}*/(watchers));
                                }

                                return resource;
                            });
                    }

                    return resource;
                }));
        }

        return this.loadResourceDataPromise_;
    }

    /**
     * @param {ResourceLike} resourceLikeObject
     * @return {Promise}
     * @private
     */
    loadResourceDetails(resourceLikeObject) {
        if (HgResourceUtils.isResourceLike(resourceLikeObject)) {
            const messageThreadService = MessageThreadService;
            if (messageThreadService) {
                return messageThreadService.loadThread(resourceLikeObject['resourceId'], resourceLikeObject['resourceType']);
            }
        }

        return Promise.resolve(null);
    }

    /**
     * @param {ResourceLike} resourceLikeObject
     * @return {Promise}
     * @private
     */
    loadResourceWatchers_(resourceLikeObject) {
        if (resourceLikeObject != null) {
            const watchService = WatchService;

            return watchService.getResourceWatchers(
                {'resourceType': resourceLikeObject['resourceType'], 'resourceId': resourceLikeObject['resourceId']},
                new FetchCriteria({'fetchSize': 30}))
                .then((result) => {
                    return result instanceof QueryDataResult ? result.getItems() : [];
                });
        } else {
            return Promise.resolve([]);
        }
    }

    /**
     * Opens up the Resource Info bubble.
     * @param {Object} resourceViewInfo
     * @protected
     */
    viewResourceInfo(resourceViewInfo) {
        if (!resourceViewInfo || !resourceViewInfo['resource']) return Promise.resolve(null);

        const resource = resourceViewInfo['resource'];

        // FIXME: We should identify earlier if there is a DIRECT TOPIC

        const infoBubble = this.getInfoBubble();
        if (infoBubble) {
            infoBubble.setPlacement(resourceViewInfo['placement'] || PopupPlacementMode.BOTTOM_MIDDLE);
            infoBubble.setVerticalOffset(resourceViewInfo['verticalOffset'] || 0);
            infoBubble.setHorizontalOffset(resourceViewInfo['horizontalOffset'] || 0);
            if (resourceViewInfo['placementTarget']) {
                infoBubble.setPlacementTarget(resourceViewInfo['placementTarget']);
            }
        }

        return this.loadResourceData(/**@type {ResourceLike}*/(resource))
            .then((resource) => {
                if (resource != null && !(resource instanceof Error)) {
                    if(resource['resourceType'] === HgResourceCanonicalNames.TOPIC && resource['type'] === TopicType.DIRECT && !resource['isSharedWithMe']) {
                        this.dispatchEvent(HgAppEvents.CONTACT_PERSON, {
                            ...resourceViewInfo,
                            /* contact details*/
                            'contactMode'       : ContactModes.VIEW_INFO,
                            'interlocutor'      : resource['interlocutor'],
                        });

                        return;
                    }

                    /* open up the resource's info bubble only if there is a placement target defined */
                    if (resourceViewInfo['placementTarget'] != null) {
                        this.setModel(this.getResourceViewModel());

                        this.getInfoBubble().open(false, resourceViewInfo['forceOpen']);

                        /* set the origin only after the bubble is opened to make sure it isn't overridden by 'on closing' routine - see onCloseInfoBubble_ */
                        this.origin_ = resourceViewInfo['origin'];
                    }
                }

                return resource;
            });
    }

    /**
     *
     * @param {Object} resourceInfo
     * @protected
     */
    viewResourceDetails(resourceInfo) {
        if (resourceInfo != null && resourceInfo['resource'] != null) {
            switch (resourceInfo['resource']['resourceType']) {
                case HgResourceCanonicalNames.FILE:
                    //todo
                    break;

                case HgResourceCanonicalNames.PERSON:
                    if (resourceInfo['resource']['resourceId']) {
                        this.dispatchEvent(HgAppEvents.VIEW_PERSON_DETAILS, {'id': resourceInfo['resource']['resourceId']});
                    }
                    break;
            }
        }
    }

    /**
     *
     * @param {Object} resourceInfo
     * @protected
     */
    commentOnResource(resourceInfo) {
        if (resourceInfo != null && resourceInfo['resource'] != null) {
            switch (resourceInfo['resource']['resourceType']) {
                case HgResourceCanonicalNames.TOPIC:
                    this.dispatchEvent(HgAppEvents.OPEN_THREAD, {
                        'recipientId': resourceInfo['resource']['resourceId'],
                        'type': resourceInfo['resource']['resourceType']
                    });
                    break;

                case HgResourceCanonicalNames.PERSON:
                    //todo
                    break;

                case HgResourceCanonicalNames.FILE:
                    //todo
                    break;
            }
        }
    }

    /**
     * Resets all the information stored about the prevously displayed resource.
     * @private
     */
    reset_() {
        const infoBubble = this.getInfoBubble();
        if (infoBubble) {
            this.setModel(null);

            infoBubble.setPlacementTarget(null);
            infoBubble.setVerticalOffset(0);
            infoBubble.setHorizontalOffset(0);
        }

        this.cancelLoadingPromise();
        this.loadResourceDataPromise_ = null;

        this.currentResourceLikeObject_ = null;

        this.origin_ = null;
    }

    /**
     *
     * @param {Promise} promise
     * @return {Promise}
     */
    setLoadingPromise(promise) {
        this.loadResourceDataPromise_ = PromiseUtils.getStatefulPromise(promise);

        return this.loadResourceDataPromise_;
    }

    /**
     *
     * @return {boolean}
     */
    isLoadingPromisePending() {
        return this.loadResourceDataPromise_
            && this.loadResourceDataPromise_.isPending();
    }

    /**
     *
     */
    cancelLoadingPromise() {
        if(this.loadResourceDataPromise_) {
            this.loadResourceDataPromise_.reject();
        }
    }

    /**
     * Set model on the resource's info bubble
     * @param {hf.events.Event} e
     * @private
     */
    onOpenInfoBubble_(e) {
        // nop
    }

    /**
     * Set model on the resource's info bubble
     * @param {hf.events.Event} e
     * @private
     */
    onCloseInfoBubble_(e) {
        const infoBubble = this.getInfoBubble();
        if (e == null || e.getTarget() === infoBubble) {
            this.reset_();
        }
    }

    /**
     * Handles a thread action event
     * @param {hf.events.Event} e
     * @private
     */
    handleResourceActionEvent_(e) {
        const payload = e.getPayload();
        if (payload) {
            this.handleResourceAction(/**@type {Object}*/(payload));
        }
    }
}