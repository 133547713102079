import { Event } from '../../../events/Event.js';
import { KeyCodes } from '../../../events/Keys.js';
import { BaseUtils } from '../../../base.js';
import { AbstractEditorPlugin } from './AbstractPlugin.js';
import { EditorCommandType, EditorPluginEventType } from '../Common.js';
import { BrowserEvent } from '../../../events/BrowserEvent.js';

/**
 * Creates a new editor plugin
 *
 * @augments {AbstractEditorPlugin}
 *
 */
export class HfSendOnEnterEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'SendOnEnter';
    }

    /** @override */
    isSupportedCommand(command) {
        return command == EditorCommandType.SEND_ON_ENTER;
    }

    /** @override */
    handleKeyDown(e) {
        const keyCode = e.keyCode || e.charCode;

        if (keyCode == KeyCodes.ENTER && !e.shiftKey) {
            e.preventDefault();

            /* HG-8547: ENTER during paste event is going to be executed from editor when paste is over */
            const editor = this.getFieldObject();
            if (!BaseUtils.isFunction(editor.isInPasteInterceptor) || !editor.isInPasteInterceptor()) {
                this.execCommand(EditorCommandType.SEND_ON_ENTER);
            }

            return true;
        }

        return false;
    }

    /** @inheritDoc */
    execCommandInternal(command, e) {
        if (e != null && (e instanceof BrowserEvent)) {
            e.preventDefault();
        }

        const editor = this.getFieldObject(),
            event = new Event(EditorPluginEventType.DATA_SEND);

        /* dispatch submit event */
        editor.dispatchEvent(event);
    }
}
