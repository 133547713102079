import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {HgAuthEventType, HgAuthRecoverMode} from "./../Common.js";
import {UserAgentUtils} from "./../../../common/useragent/useragent.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {UIComponent}
 * @unrestricted 
*/
export class EmailConfirmation extends UIComponent {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);

        /**
         * Submit button
         * @type {hf.ui.Caption}
         * @private
         */
        this.message_;

        /**
         * Submit button
         * @type {hf.ui.Button}
         * @private
         */
        this.loginBtn_;

        /**
         * @type {hf.ui.Button}
         * @private
         */
        this.clearOrganizationBtn_;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-auth-email-confirmation';
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.message_ = new Caption();
        this.loginBtn_ = HgButtonUtils.create(['hg-auth-goto-login', 'hg-button-link'], translator.translate('back_to_login'), false);
        this.loginBtn_.setVisible(false);

        this.clearOrganizationBtn_ = HgButtonUtils.createLinkButton(null, false, {
            'content'       : translator.translate('another_team_domain'),
            'extraCSSClass' : 'hg-auth-button-desktopapp',
            'hidden'        : true
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.message_, true);
        this.addChild(this.loginBtn_, true);

        /* add link for desktop win/mac only */
        if (UserAgentUtils.ELECTRON) {
            this.addChild(this.clearOrganizationBtn_, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        /* listen to events */
        this.getHandler()
            .listen(this.loginBtn_, UIComponentEventTypes.ACTION, this.handleLoginAction_);

        if (UserAgentUtils.ELECTRON) {
            this.getHandler()
                .listen(this.clearOrganizationBtn_, UIComponentEventTypes.ACTION, this.handleClearOrganization_);
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.message_);
        this.message_ = null;

        BaseUtils.dispose(this.loginBtn_);
        this.loginBtn_ = null;

        BaseUtils.dispose(this.clearOrganizationBtn_);
        this.clearOrganizationBtn_ = null;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.loginBtn_, {'set': this.loginBtn_.setVisible}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function (mode) {
                    return mode != HgAuthRecoverMode.FORGOT_DOMAIN;
                }
            }
        });

        this.setBinding(this.message_, {'set': this.message_.setContent}, {
            'sourceProperty': '',
            'converter': {
                'sourceToTargetFn': function (mode) {
                    const content = document.createDocumentFragment();

                    if (mode == HgAuthRecoverMode.FORGOT_DOMAIN) {
                        content.appendChild(DomUtils.createDom('div', 'hg-auth-email-title', translator.translate('email_sent')));
                        content.appendChild(DomUtils.createDom('div', 'hg-auth-email-body', translator.translate('good_news')));
                    } else {
                        content.appendChild(DomUtils.createDom('div', 'hg-auth-email-title', translator.translate('password_reset')));
                        content.appendChild(DomUtils.createDom('div', 'hg-auth-email-body', translator.translate('password_reset_link')));
                    }

                    return content;
                }
            }
        });

        this.setBinding(this.clearOrganizationBtn_, {'set': this.clearOrganizationBtn_.setVisible}, {
            'sourceProperty': 'fromMode',
            'converter' : {
                'sourceToTargetFn': function (fromMode) {
                    return fromMode == HgAuthRecoverMode.FORGOT_DOMAIN;
                }
            }
        });
    }

    /**
     * Handles the login form submit
     * @param {hf.events.Event} e The event
     * @protected
     */
    handleLoginAction_(e) {
        this.dispatchEvent(HgAuthEventType.BACK_LOGIN);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }

    /**
     * @param {hf.events.Event} e The event
     * @private
     */
    handleClearOrganization_(e) {
        this.dispatchEvent(HgAuthEventType.CLEAR_ORGANIZATION);

        e.stopPropagation();
        e.preventDefault();

        return false;
    }
};