import { BaseUtils } from '../base.js';

/**
 * Class for representing sizes consisting of a width and height. Undefined
 * width and height support is deprecated and results in compiler warning.
 *
 *
 */
export class Size {
    /**
     * @param {number} width Width.
     * @param {number} height Height.
     */
    constructor(width, height) {
        /**
         * Width
         *
         * @type {number}
         */
        this.width = width;

        /**
         * Height
         *
         * @type {number}
         */
        this.height = height;
    }

    /**
     * @returns {!hf.math.Size} A new copy of the Size.
     * @suppress {checkTypes}
     */
    clone() {
        return new Size(this.width, this.height);
    }

    /**
     * @returns {number} The longer of the two dimensions in the size.
     * @suppress {checkTypes}
     */
    getLongest() {
        return Math.max(this.width, this.height);
    }

    /**
     * @returns {number} The shorter of the two dimensions in the size.
     * @suppress {checkTypes}
     */
    getShortest() {
        return Math.min(this.width, this.height);
    }

    /**
     * @returns {number} The area of the size (width * height).
     * @suppress {checkTypes}
     */
    area() {
        return this.width * this.height;
    }

    /**
     * @returns {number} The perimeter of the size (width + height) * 2.
     * @suppress {checkTypes}
     */
    perimeter() {
        return (this.width + this.height) * 2;
    }

    /**
     * @returns {number} The ratio of the size's width to its height.
     * @suppress {checkTypes}
     */
    aspectRatio() {
        return this.width / this.height;
    }

    /**
     * @returns {boolean} True if the size has zero area, false if both dimensions
     *     are non-zero numbers.
     * @suppress {checkTypes}
     */
    isEmpty() {
        return !this.area();
    }

    /**
     * Clamps the width and height parameters upward to integer values.
     *
     * @returns {!hf.math.Size} This size with ceil'd components.
     * @suppress {checkTypes}
     */
    ceil() {
        this.width = Math.ceil(this.width);
        this.height = Math.ceil(this.height);
        return this;
    }

    /**
     * @param {!hf.math.Size} target The target size.
     * @returns {boolean} True if this Size is the same size or smaller than the
     *     target size in both dimensions.
     * @suppress {checkTypes}
     */
    fitsInside(target) {
        return this.width <= target.width && this.height <= target.height;
    }

    /**
     * Clamps the width and height parameters downward to integer values.
     *
     * @returns {!hf.math.Size} This size with floored components.
     * @suppress {checkTypes}
     */
    floor() {
        this.width = Math.floor(this.width);
        this.height = Math.floor(this.height);
        return this;
    }

    /**
     * Rounds the width and height parameters to integer values.
     *
     * @returns {!hf.math.Size} This size with rounded components.
     * @suppress {checkTypes}
     */
    round() {
        this.width = Math.round(this.width);
        this.height = Math.round(this.height);
        return this;
    }

    /**
     * Scales this size by the given scale factors. The width and height are scaled
     * by {@code sx} and {@code opt_sy} respectively.  If {@code opt_sy} is not
     * given, then {@code sx} is used for both the width and height.
     *
     * @param {number} sx The scale factor to use for the width.
     * @param {number=} opt_sy The scale factor to use for the height.
     * @returns {!hf.math.Size} This Size object after scaling.
     * @suppress {checkTypes}
     */
    scale(sx, opt_sy) {
        const sy = BaseUtils.isNumber(opt_sy) ? opt_sy : sx;
        this.width *= sx;
        this.height *= sy;
        return this;
    }

    /**
     * Uniformly scales the size to perfectly cover the dimensions of a given size.
     * If the size is already larger than the target, it will be scaled down to the
     * minimum size at which it still covers the entire target. The original aspect
     * ratio will be preserved.
     *
     * This function assumes that both Sizes contain strictly positive dimensions.
     *
     * @param {!hf.math.Size} target The target size.
     * @returns {!hf.math.Size} This Size object, after optional scaling.
     * @suppress {checkTypes}
     */
    scaleToCover(target) {
        const s = this.aspectRatio() <= target.aspectRatio()
            ? target.width / this.width
            : target.height / this.height;

        return this.scale(s);
    }

    /**
     * Uniformly scales the size to fit inside the dimensions of a given size. The
     * original aspect ratio will be preserved.
     *
     * This function assumes that both Sizes contain strictly positive dimensions.
     *
     * @param {!hf.math.Size} target The target size.
     * @returns {!hf.math.Size} This Size object, after optional scaling.
     * @suppress {checkTypes}
     */
    scaleToFit(target) {
        const s = this.aspectRatio() > target.aspectRatio()
            ? target.width / this.width
            : target.height / this.height;

        return this.scale(s);
    }

    /**
     * Compares sizes for equality.
     *
     * @param {hf.math.Size} a A Size.
     * @param {hf.math.Size} b A Size.
     * @returns {boolean} True iff the sizes have equal widths and equal
     *     heights, or if both are null.
     */
    static equals(a, b) {
        if (a == b) {
            return true;
        }
        if (!a || !b) {
            return false;
        }
        return a.width == b.width && a.height == b.height;
    }
}
