import { Event } from '../../events/Event.js';
import { FunctionsUtils } from '../../functions/Functions.js';
import { UIComponent } from '../UIComponent.js';
import { UIControl } from '../UIControl.js';
import { SelectorEventType } from '../selector/ISelector.js';
import { TabBar } from './TabBar.js';
import { ListItemsLayout } from '../list/List.js';
import { StringUtils } from '../../string/string.js';

/**
 * Creates a new instance of the {@see hf.ui.tab.TabPane} class.
 *
 * @augments {UIComponent}
 *
 */
export class TabPane extends UIComponent {
    /**
     * @param {!object=} opt_config The configuration object
     *
     *   @param {Array|hf.structs.ICollection=} opt_config.itemsSource The data source which is used to dynamically create the UI Tabs.
     *   @param {?string=} opt_config.valueField The field of the item's model whose value is returned by the getSelectedValue method.
     *
     *   @param {!TabPaneTabsPosition=} opt_config.tabsPosition = 'top' The position of the tab bar.
     *
     *   @param {function(*): ?UIControlContent=} opt_config.tabHeaderContentFormatter Used to create the header of a Tab. *
     *   @param {object | ?function(hf.ui.tab.Tab): void=} opt_config.tabHeaderFormatter The function used to alter a Tab (e.g. make it disabled).
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.tabHeaderStyle The extra CSS class to add to each tab.
     *
     *   @param {((function(*): (?UIControlContent | undefined)) | object)=} opt_config.tabContentFormatter Used to create the content of a tab.
     *   @param {(string | !Array.<string> | function(*): (string | !Array.<string>))=} opt_config.tabContentStyle The extra CSS class to add to each tab's content.
     *
     *   @param {!object=} opt_config.tooltip Optional configuration object for the tooltip shown when a tab item is hovered.
     *
     */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The component that will hold all the Tabs.
         *
         * @type {hf.ui.tab.TabBar}
         * @private
         */
        this.tabsHost_ = this.tabsHost_ === undefined ? null : this.tabsHost_;

        /**
         * The component that will hold the content of the currently selected Tab.
         *
         * @type {hf.ui.UIControl}
         * @private
         */
        this.contentHost_ = this.contentHost_ === undefined ? null : this.contentHost_;
    }

    /**
     *
     * @returns {hf.data.ListDataSource}
     *
     */
    getItemsSource() {
        return this.getTabsHost().getItemsSource();
    }

    /**
     * Sets the data source used to dynamically (behind the scenes) create the Tabs.
     *
     * @param {Array | hf.structs.ICollection | hf.data.ListDataSource} itemsSource The external items source.
     *
     */
    setItemsSource(itemsSource) {
        this.getTabsHost().setItemsSource(itemsSource);
    }

    /**
     *
     * @returns {boolean}
     *
     */
    hasItemsSource() {
        return this.getTabsHost().hasItemsSource();
    }

    /**
     * Selects or deselects a data item.
     * In a 'single selection' context, it selects the provided data item and deselects the currently selected data item (if any).
     * In a 'multiple selection' context, it selects or deselects the provided data item.
     *
     * @param {!*} item The data item to select
     * @param {boolean=} opt_select Flag indicating whether to select or deselect the d
     * @returns {void}
     *
     */
    selectItem(item, opt_select) {
        this.getTabsHost().selectItem(item, opt_select);
    }

    /**
     * Gets whether the data item is selected or not.
     *
     * @param {*} item The data item to select.
     * @returns {boolean} True if the data item is selected, otherwise false.
     *
     */
    isItemSelected(item) {
        return this.getTabsHost().isItemSelected(item);
    }

    /**
     * Gets the currently selected data item (single selection context).
     * If this method is called within a multi-selection context, then
     * it will return the firstly selected data item.
     *
     * @returns {*} The selected data item.
     *
     */
    getSelectedItem() {
        return this.getTabsHost().getSelectedItem();
    }

    /**
     * Selects or deselects a value.
     * In a 'single selection' context, it selects the provided value and deselects the currently selected value (if any).
     * In a 'multiple selection' context, it selects or deselects the provided value.
     *
     * @param {!*} value
     * @param {boolean=} opt_select Flag indicating whether to select or deselect the value. By default is true.
     * @returns {boolean}
     *
     */
    selectValue(value, opt_select) {
        return this.getTabsHost().selectValue(value, opt_select);
    }

    /**
     * Gets whether the value is selected or not.
     *
     * @param {*} value
     * @returns {boolean}
     *
     */
    isValueSelected(value) {
        return this.getTabsHost().isValueSelected(value);
    }

    /**
     * Gets the currently selected value (single selection context).
     * If this method is called within a multi-selection context, then
     * it will return the firstly selected value.
     *
     * @returns {*} The selected value.
     *
     */
    getSelectedValue() {
        return this.getTabsHost().getSelectedValue();
    }

    /**
     * Selects or deselects an index.
     *
     * @param {number} index
     * @param {boolean=} opt_select Flag indicating whether to select or deselect the index. By default is true
     * @returns {boolean} True if the index at the specified index was selected, otherwise false.
     *
     */
    selectIndex(index, opt_select) {
        return this.getTabsHost().selectIndex(index, opt_select);
    }

    /**
     * Gets whether the item at the specified index is selected.
     *
     * @param {number} index
     * @returns {boolean} True if the item at the specified index is selected, otherwise false.
     *
     */
    isIndexSelected(index) {
        return this.getTabsHost().isIndexSelected(index);
    }

    /**
     * Gets the currently selected index (single selection context).
     * If this method is called within a multi-selection context, then
     * it will return the firstly selected index.
     *
     * @returns {!number} The index of the selected item or -1, if no item is selected.
     *
     */
    getSelectedIndex() {
        return this.getTabsHost().getSelectedIndex();
    }

    /**
     * Selects or deselects the first index.
     *
     * @param {boolean=} opt_select
     * @returns {boolean}
     *
     */
    selectFirstIndex(opt_select) {
        return this.getTabsHost().selectFirstIndex(opt_select);
    }

    /**
     * Selects or deselects the last index.
     *
     * @param {boolean=} opt_select
     * @returns {boolean}
     *
     */
    selectLastIndex(opt_select) {
        return this.getTabsHost().selectLastIndex(opt_select);
    }

    /**
     * Selects or deselects the next index.
     *
     * @param {boolean=} opt_select
     * @returns {boolean}
     *
     */
    selectNextIndex(opt_select) {
        return this.getTabsHost().selectNextIndex(opt_select);
    }

    /**
     * Selects or deselects the previous index.
     *
     * @param {boolean=} opt_select
     * @returns {boolean}
     *
     */
    selectPreviousIndex(opt_select) {
        return this.getTabsHost().selectPreviousIndex(opt_select);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config.tabsPosition = opt_config.tabsPosition || TabPaneTabsPosition.TOP;

        opt_config.extraCSSClass = FunctionsUtils.normalizeExtraCSSClass(opt_config.extraCSSClass || [], `tabs-position-${opt_config.tabsPosition}`);

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        this.tabsHost_ = null;
        this.contentHost_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hf-tab-pane';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hf-tab-pane';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        const tabsPosition = this.getConfigOptions().tabsPosition,
            tabsHost = this.getTabsHost(),
            contentHost = this.getContentHost();

        if (tabsPosition == TabPaneTabsPosition.BOTTOM || tabsPosition == TabPaneTabsPosition.RIGHT) {
            this.addChild(contentHost, true);
            this.addChild(tabsHost, true);
        } else {
            this.addChild(tabsHost, true);
            this.addChild(contentHost, true);
        }
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.getTabsHost(), SelectorEventType.BEFORE_SELECTION, this.handleBeforeSelectionChangeEvent_)
            .listen(this.getTabsHost(), SelectorEventType.SELECTION_CHANGE, this.handleSelectionChangeEvent_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const tabsHost = this.getTabsHost(),
            contentHost = this.getContentHost();

        this.setBinding(contentHost, { set: contentHost.setModel }, {
            source: tabsHost,
            sourceProperty: { get: tabsHost.getSelectedItem },
            updateTargetTrigger: [SelectorEventType.SELECTION_CHANGE]
        });
    }

    /**
     * Gets the component that will hold the tabs.
     *
     * @returns {hf.ui.tab.TabBar}
     * @protected
     */
    getTabsHost() {
        if (!this.tabsHost_) {
            const opt_config = this.getConfigOptions();

            this.tabsHost_ = new TabBar({
                extraCSSClass: 'hf-tab-pane-tabs-host',
                itemsSource: opt_config.itemsSource,
                valueField: opt_config.valueField,
                itemsLayout: this.getTabListItemsLayout_(),
                itemContentFormatter: opt_config.tabHeaderContentFormatter,
                itemFormatter: opt_config.tabHeaderFormatter,
                itemStyle: opt_config.tabHeaderStyle,
                tooltip: opt_config.tooltip
            });
        }

        return this.tabsHost_;
    }

    /**
     * Creates the component that will host the selected tab content
     
     * @returns {hf.ui.UIControl}
     * @protected
     */
    getContentHost() {
        if (!this.contentHost_) {
            const opt_config = this.getConfigOptions();

            this.contentHost_ = new UIControl({
                baseCSSClass: 'hf-tab-pane-content-host',
                extraCSSClass: opt_config.tabContentStyle,
                contentFormatter: opt_config.tabContentFormatter,
                ariaRole: 'tabpanel'
            });
        }
        return this.contentHost_;
    }

    /**
     * Converts the current tab position to a list layout.
     *
     * @returns {string}
     * @private
     */
    getTabListItemsLayout_() {
        const tabsPosition = this.getConfigOptions().tabsPosition;

        switch (tabsPosition) {
            default:
            case TabPaneTabsPosition.TOP:
            case TabPaneTabsPosition.BOTTOM:
                return ListItemsLayout.HSTACK;

            case TabPaneTabsPosition.RIGHT:
            case TabPaneTabsPosition.LEFT:
                return ListItemsLayout.VSTACK;
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleBeforeSelectionChangeEvent_(e) {
        e.stopPropagation();

        const event = new Event(TabPaneEventType.BEFORE_SELECTION);
        event.addProperty('selectedItem', e.getProperty('selectedItem'));
        event.addProperty('selectedValue', e.getProperty('selectedValue'));

        return this.dispatchEvent(event);
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleSelectionChangeEvent_(e) {
        e.stopPropagation();

        const event = new Event(TabPaneEventType.SELECTION_CHANGE);
        event.addProperty('selectedItems', e.getProperty('selectedItems'));
        event.addProperty('unselectedItems', e.getProperty('unselectedItems'));

        return this.dispatchEvent(event);
    }
}

/**
 * The event types dispatched by this component.
 *
 * @enum {string}
 */
export const TabPaneEventType = {
    BEFORE_SELECTION: StringUtils.createUniqueString('beforeselection'),

    /* The selected item(s) is/are changed. */
    SELECTION_CHANGE: StringUtils.createUniqueString('selectionchange')
};

/**
 * Represents the tab bar position relative to the tab content.
 *
 * @enum {string}
 *
 */
export const TabPaneTabsPosition = {
    LEFT: 'left',
    BOTTOM: 'bottom',
    RIGHT: 'right',
    TOP: 'top'
};
