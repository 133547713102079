import {Caption} from "./../../../../../../../../hubfront/phpnoenc/js/ui/Caption.js";

import {BaseUtils} from "./../../../../../../../../hubfront/phpnoenc/js/base.js";
import {SettingsCategory} from "./../SettingsCategory.js";
import {StringUtils} from "../../../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {SettingsCategory}
 * @unrestricted 
*/
export class SuccesfulSubscribe extends SettingsCategory {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * The title on account status form with CLOSING status
         * @type {hf.ui.Caption}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         * Help message on account status form with CLOSING status
         * @type {hf.ui.Caption}
         * @private
         */
        this.helpMessage_ = this.helpMessage_ === undefined ? null : this.helpMessage_;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['name'] = opt_config['name'] || 'succesfull-subscribe-account-status';

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        this.addExtraCSSClass('hg-billing-succesfull-subscribe-form');

        const translator = Translator;

        this.header_ = new Caption({
            'extraCSSClass'	: ['hg-billing-page-header', 'hg-billing-succesfull-subscribe-header'],
            'content'		: translator.translate('thank_you')
        });

        this.helpMessage_ = new Caption({
            'extraCSSClass'	: 'hg-billing-succesfull-subscribe-help-message'
        });
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.contentContainer.addChild(this.header_, true);
        this.contentContainer.addChild(this.helpMessage_, true);
    }

    /** @inheritDoc */
    createContentDom() {
        const translator = Translator;
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator;

        this.setBinding(this.helpMessage_, {'set': this.helpMessage_.setContent}, {
            'sourceProperty': 'subscription.plan',
            'converter'		: {
                'sourceToTargetFn': function(servicePlan) {
                    if (servicePlan == null) {
                        return null;
                    }

                    const planName = !StringUtils.isEmptyOrWhitespace(servicePlan['name']) ? servicePlan['name'] : '';

                    return translator.translate('now_subscribed', [planName]);
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header_);
        this.header_ = null;

        BaseUtils.dispose(this.helpMessage_);
        this.helpMessage_ = null;
    }
};