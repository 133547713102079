import {KeyCodes} from "./../../../../../../../hubfront/phpnoenc/js/events/Keys.js";
import {AbstractEditorPlugin} from "./../../../../../../../hubfront/phpnoenc/js/ui/editor/plugin/AbstractPlugin.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new PreventTextFormatting plugin
 * !!!! Do not use along with TextFormatter
 * @extends {AbstractEditorPlugin}
 * @unrestricted 
*/
export class HgPreventTextFormattingEditorPlugin extends AbstractEditorPlugin {
    constructor() {
        super();
    }

    /** @override */
    getTrogClassId() {
        return 'PreventTextFormatting';
    }

    /** @inheritDoc */
    handleKeyDown(e) {
        if ((e.ctrlKey || (userAgent.platform.isMacintosh() && e.metaKey)) &&
            (e.keyCode == KeyCodes.B || e.keyCode == KeyCodes.U || e.keyCode == KeyCodes.I)) {
            e.preventDefault();
            e.stopPropagation();

            return true;
        }
        return false;
    }
};