import {Event} from "./../../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {
    AbstractMetacontentPlugin,
    MetacontentPluginEventType
} from "./../../../../../../../hubfront/phpnoenc/js/ui/metacontent/AbstractMetacontentPlugin.js";
import {HgMetacontentUtils} from "./../../../string/metacontent.js";
import {HgResourceCanonicalNames} from "./../../../../data/model/resource/Enums.js";
import {HgResourceUtils} from "./../../../../data/model/resource/Common.js";
import userAgent from "../../../../../../../hubfront/phpnoenc/thirdparty/hubmodule/useragent.js";

/**
 * Creates a new {@see hg.common.ui.metacontent.plugin.HgBotReferMetacontentPlugin} metacontent plugins
 * @extends {AbstractMetacontentPlugin}
 * @unrestricted 
*/
export class HgBotReferMetacontentPlugin extends AbstractMetacontentPlugin {
    /**
     * @param {!string=} opt_actionType
    */
    constructor(opt_actionType) {
        super();

        /**
         * Action to execute on person reference
         * @type {string}
         * @default hg.common.ui.metacontent.plugin.HgBotReferMetacontentPlugin.Action.BUBBLE
         * @private
         */
        this.action_ = opt_actionType || HgBotReferMetacontentPlugin.Action.BUBBLE;
    }

    /** @override */
    getClassId() {
        return 'BotRefer';
    }

    /** @inheritDoc */
    encodeContent(content) {
        return HgMetacontentUtils.encodeActionTag(content, HgMetacontentUtils.ActionTag.BOT);
    }

    /** @inheritDoc */
    decodeContent(content) {
        return HgMetacontentUtils.decodeActionTag(content, HgMetacontentUtils.ActionTag.BOT);
    }

    /** @inheritDoc */
    handleMouseOver(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (this.isTargetedAnchor(target) && (userAgent.device.isDesktop())
            && (this.action_ == HgBotReferMetacontentPlugin.Action.BUBBLE || this.action_ == HgBotReferMetacontentPlugin.Action.FULL)) {

            const event = new Event(MetacontentPluginEventType.DATA_ACTION);

            event.addProperty('action', this.action_);
            event.addProperty('placementTarget', target);
            event.addProperty('origin', this.getDisplayObject());
            event.addProperty('resource', HgResourceUtils.getResourceLink({
                'resourceType'  : HgResourceCanonicalNames.BOT,
                'resourceId'    : target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR)
            }));

            /* display event so it can be handled by presenters (e.g. chat to colleague) */
            return this.dispatchEvent(event);
        }

        return false;
    }

    /**
     * Handles click on:  <span class="hg-metacontent-bot" data-resource-type="BOT" %s>%s</span>
     * Returns true if event is handled to that no other plugins should be called to handle it
     * Event is not propagated to parent display or other components
     *
     * @override
     */
    handleMouseUp(e) {
        if (e.isMouseActionButton()) {
            return this.performActionInternal(e);
        }

        return false;
    }

    /**
     * Check if anchor must be targeted on click (show options bubble)
     * @param {Element} target
     * @return {boolean}
     * @protected
     */
    isTargetedAnchor(target) {
        const display = this.getDisplayObject();

        if (target && target.nodeType == Node.ELEMENT_NODE && target != display.getElement()) {
            const resourceTypeAttr = target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_TYPE_ATTR);

            return (resourceTypeAttr == HgResourceCanonicalNames.BOT);
        }

        return false;
    }

    /**
     *
     * @param {hf.events.Event} e
     * @return {boolean}
     * @protected
     */
    performActionInternal(e) {
        const target = /** @type {Element} */(e.getTarget());

        if (!e.defaultPrevented
            && this.isTargetedAnchor(target)
            && this.action_ != HgBotReferMetacontentPlugin.Action.BUBBLE) {
            let action = this.action_;
            if (this.action_ == HgBotReferMetacontentPlugin.Action.FULL) {
                action = HgBotReferMetacontentPlugin.Action.CHAT;
            }

            const event = new Event(MetacontentPluginEventType.DATA_ACTION);
            event.addProperty('action', action);
            event.addProperty('placementTarget', target);
            event.addProperty('resource', HgResourceUtils.getResourceLink({
                'resourceType'  : HgResourceCanonicalNames.BOT,
                'resourceId'    : target.getAttribute(HgMetacontentUtils.TAG_INTERNAL_RESOURCE_ATTR)
            }));

            /* display event so it can be handled by presenters (e.g. chat to colleague) */
            if (this.dispatchEvent(event)) {
                /* mark the event as handled */
                e.preventDefault();

                return true;
            }
        }

        return false;
    }
};
/**
 * Action to execute on person reference
 * @enum {string}
 * @readonly
 */
HgBotReferMetacontentPlugin.Action = {
    /** On non mobile devices, onclick: chat, on hover: bubble
     * Not used for now */
    FULL: 'full',

    /** On click: chat on non mobile devices */
    CHAT    : 'chat',

    /** On click: nothing on non mobile devices, bubble on mobile devices
     * On hover:  bubble */
    BUBBLE  : 'bubble'
};