import {DataModelField} from "./../../../../../../hubfront/phpnoenc/js/data/model/Field.js";
import {HgParty} from "./HgParty.js";
import {IRecipient} from "./IRecipient.js";

/**
 * @extends {HgParty}
 * @unrestricted 
*/
export class RecipientBaseSearchResult extends HgParty {
    /**
     * @param {!Object=} opt_initData
    */
    constructor(opt_initData) {
        super(opt_initData);
    }

    /** @inheritDoc */
    getUIdField() {
        return 'recipientId';
    }

    /** @inheritDoc */
    defineFields() {
        super.defineFields();

        /* recipientId - The id of the recipient. */
        this.addField({'name': 'recipientId', 'type': DataModelField.PredefinedTypes.STRING});
    }

    /** @inheritDoc */
    defineCustomFields() {
        super.defineCustomFields();
    }

    /** @inheritDoc */
    onDataLoading(rawData) {
        super.onDataLoading(rawData);
    }
};
// interface implementation
IRecipient.addImplementation(RecipientBaseSearchResult);