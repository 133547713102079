import {BaseUtils} from "./../../../../../hubfront/phpnoenc/js/base.js";
import {DataPortal} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/DataPortal.js";
import {FetchCriteria} from "./../../../../../hubfront/phpnoenc/js/data/criteria/FetchCriteria.js";
import {FilterOperators} from "./../../../../../hubfront/phpnoenc/js/data/FilterDescriptor.js";
import {ObjectMapper} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/ObjectMapper.js";
import {AbstractService} from "./AbstractService.js";
import {HgPersonUtils} from "./../model/person/Common.js";
import {AuthDataMapping} from "./datamapping/Auth.js";
import {UserRoles} from "./../model/user/Enums.js";
import {HgCurrentUser} from "./../../app/CurrentUser.js";
import {HgCurrentSession} from "./../../app/CurrentSession.js";
import {HgAppEvents} from "./../../app/Events.js";
import {MAX_SAFE_INTEGER} from "./../../../../../hubfront/phpnoenc/js/math/Math.js";
import PhoneExtensionService from "./PhoneExtensionService.js";
import {PhoneExtensionTypes} from "./../model/phonecall/Enums.js";
import {PhoneExtension} from "./../model/phonecall/PhoneExtension.js";
import DistractionService from "./DistractionService.js";
import {DeviceTypes, DistractionClassType} from "./../model/common/Enums.js";
import {AvailabilityPolicy} from "./../model/presence/Enums.js";
import {HTTPVerbs} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/Common.js";
import {DataProxyType} from "./../../../../../hubfront/phpnoenc/js/data/dataportal/proxy/DataProxy.js";
import {HgAppConfig} from "./../../app/Config.js";
import { PLT_UID } from "./../../app/PlatformUID.js";

/**
 * Createa a new auth service
 * @extends {AbstractService}
 * @unrestricted 
*/
class CurrentUserService extends AbstractService {
    constructor() {
        /* Call the base class constructor */
        super();
    }

    /**
     * Load details for the logged in user, only person details and phone numbers
     * @return {Promise}
     */
    loadAuthAccount() {
        if (!HgCurrentUser.isEmpty() && HgCurrentUser['personId'] != null) {
            return Promise.resolve(HgCurrentUser);
        }

        return this.loadUserData()
            .then((userData) => {
                /* update some user's details from the current session */
                if (HgCurrentSession && HgCurrentUser) {
                    HgCurrentUser['role'] = HgCurrentSession['session'] && HgCurrentSession['session']['role'] != null ? HgCurrentSession['session']['role'] : UserRoles.MEMBER;
                    HgCurrentUser['username'] = HgCurrentSession['identity'];
                    HgCurrentUser['personId'] = HgCurrentSession['session']['personId'];
                    HgCurrentUser['orgIdentifier'] = HgCurrentSession['session']['orgIdentifier'];

                    HgCurrentUser.acceptChanges();
                }

                return HgCurrentUser;
            });
    }

    /**
     * Updates the desired availability for current user
     * @param {*} policy
     * @return {Promise}
     */
    updateAvailabilityPolicy(policy) {
        return DistractionService.updatePolicy(DistractionClassType.POLICY_NOTIFICATION, {'availability': policy}, DeviceTypes.ANY)
            .then((distraction) => {
                const availability = distraction && BaseUtils.isObject(distraction['body']) ? distraction['body']['availability'] : AvailabilityPolicy.FULL;

                /* update current presence in this case... */
                this.getMyPresence(true);

                if (HgCurrentUser) {
                    HgCurrentUser['availabilityPolicy'] = availability;
                }

                return distraction;
            });
    }

    /**
     * Fetch user notification policy
     * @return {Promise} policy
     */
    async getAvailabilityPolicy() {
        let availability = AvailabilityPolicy.FULL;

        try {
            const distraction = await DistractionService.getPolicy(DistractionClassType.POLICY_NOTIFICATION);

            availability = distraction && BaseUtils.isObject(distraction['body'])
                ? distraction['body']['availability'] || AvailabilityPolicy.FULL
                : AvailabilityPolicy.FULL;
        } catch (err) {
            availability = AvailabilityPolicy.FULL
        }

        return availability;
    }

    /** @inheritDoc */
    getLogger() {
        return Logger.get('hg.data.service.CurrentUserService');
    }

    /** @inheritDoc */
    init() {
        super.init();
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();
    }

    /** @inheritDoc */
    listenToEvents() {
        const eventBus = this.getEventBus();

        this.getHandler()
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_USER_UPDATE, this.handleUpdateUser_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_CREATE, this.handlePhoneExtensionCreate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_UPDATE, this.handlePhoneExtensionUpdate_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_EXTENSION_DELETE, this.handlePhoneExtensionDelete_)
            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE, this.handleAvailabilityPolicyUpdate_)

            .listen(eventBus, HgAppEvents.DATA_CHANNEL_MESSAGE_DISTRACTION_UPDATE, this.handleAvailabilityPolicyUpdate_)

            .listen(eventBus, HgAppEvents.CHAT_CONNECTION_CHANGE, this.handleChatConnectionChange_)

            .listen(eventBus, HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES, this.handleUpdateWsDependentResources_)
            .listen(eventBus, HgAppEvents.LOGOUT_SUCCESSFUL, this.handleLogout_);
    }

    /**
     * Load details for the logged in user, only person details and phone numbers
     * @return {Promise}
     * @private
     */
    loadUserData() {
        const userDataPortal = DataPortal.createPortal({
            'proxy': {
                'type': DataProxyType.REST,
                'endpoint': HgAppConfig.REST_SERVICE_ENDPOINT + 'latest/user/' + HgPersonUtils.ME,
                'withCredentials': true
            }
        });

        const promises = [
            /* load user data */
            this.handleErrors(userDataPortal.invoke(HTTPVerbs.GET, {})),

            /* load user's phone extensions */
            this.loadPhoneExtensions()
        ];

        if (!PLT_UID) {
            /* load user's distraction policy */
            promises.push(this.getAvailabilityPolicy());
        }

        return Promise.all(promises)
            .then((results) => {
                const userData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(results[0], AuthDataMapping.AuthAccount['read']));

                userData['phoneExtensions'] = results[1];
                userData['availabilityPolicy'] = results[2] || AvailabilityPolicy.FULL;

                /* update CurrentUser data */
                HgCurrentUser.loadData(userData, {'overrideChanges': true});
                HgCurrentUser.acceptChanges();

                return userData;
            });
    }

    /**
     * Loads user's phone extensions
     *
     * @returns {Promise<Array>}
     */
    async loadPhoneExtensions() {
        let phoneExtensions = [];

        try {
            const result = await PhoneExtensionService.loadExtensions(new FetchCriteria({'fetchSize': MAX_SAFE_INTEGER}), PhoneExtension);
            phoneExtensions = result ? result.getItems() : [];
        } catch (err) {
            phoneExtensions = []
        }

        return phoneExtensions;
    }

    /**
     * @param {AppEvent} e
     * @private
     */
    handleUpdateUser_(e) {
        const payload = /**@type {Object}*/(e.getPayload());

        if (!HgCurrentUser.isEmpty()
            && payload != null
            && HgPersonUtils.isMe(payload['userId'])) {
            const authAccountData = /**@type {!Object}*/(ObjectMapper.getInstance().transform(payload, AuthDataMapping.AuthAccount['read']));

            HgCurrentUser.loadData(authAccountData, {'overrideChanges': true});
        }
    }

    /**
     * Insert phone extension into the set of extensions of the current user
     * !!Note: event must be treated also in phone module to insert it in internal PhoneAggregator.phones_ observable
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionCreate_(e) {
        const payload = e.getPayload();

        if (payload['phoneExtensionId'] != null) {
            const fetchCriteria = new FetchCriteria({
                'filters': [
                    {
                        'filterBy': 'phoneExtensionId',
                        'filterOp': FilterOperators.EQUAL_TO,
                        'filterValue': payload['phoneExtensionId']
                    }
                ],
                'fetchSize': 1
            });

            PhoneExtensionService.loadExtensions(fetchCriteria, PhoneExtension)
                .then((queryResult) => {
                    const phoneExtension = this.extractSingleQueryResult(queryResult);

                    if (phoneExtension != null) {
                        this.onPhoneExtensionCreate_(/** @type {PhoneExtension} */(phoneExtension));
                    }

                    return phoneExtension;
                });
        }
    }

    /**
     * @param {PhoneExtension} phoneExtension
     */
    onPhoneExtensionCreate_(phoneExtension) {
        if (HgCurrentUser.isEmpty() || HgCurrentUser['phoneExtensions'] == null) {
            return;
        }

        if (phoneExtension['type'] == PhoneExtensionTypes.TERMINAL) {
            HgCurrentUser['phoneExtensions'].add(phoneExtension);

            this.dispatchAppEvent(HgAppEvents.PHONE_EXTENSION_CREATED, {
                'extension': phoneExtension
            });
        }
    }

    /**
     * Update phone extension, data contains:
     * {
     *  phoneExtensionId: 333,
     *  alias: 'MyExt',
     *  agentDevice: WEB
     * }
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionUpdate_(e) {
        if (HgCurrentUser.isEmpty() || HgCurrentUser['phoneExtensions'] == null) {
            return;
        }

        const data = e.getPayload(),
            match = HgCurrentUser['phoneExtensions'].find(function (phoneExtension) {
                return phoneExtension['phoneExtensionId'] == data['phoneExtensionId'];
            });

        if (match != null) {
            /* update extension data */
            match.loadData(data);

            if (data['recActive'] != null && match['settings'] != null) {
                match['settings']['recActive'] = data['recActive'];
            }

            this.dispatchAppEvent(HgAppEvents.PHONE_EXTENSION_UPDATED, {
                'extension': match
            });
        }
    }

    /**
     * Delete phone extension.
     * @param {AppEvent} e The event
     * @private
     */
    handlePhoneExtensionDelete_(e) {
        if (HgCurrentUser.isEmpty() || HgCurrentUser['phoneExtensions'] == null) {
            return;
        }

        const deletedExtensions = e.getPayload()['deleted'];
        if (BaseUtils.isArray(deletedExtensions)) {
            deletedExtensions.forEach((extension) => {
                const match = HgCurrentUser['phoneExtensions'].find(function (phoneExtension) {
                    return phoneExtension['phoneExtensionId'] == extension['phoneExtensionId'];
                });

                if (match != null) {
                    /* update extension data */
                    HgCurrentUser['phoneExtensions'].remove(match);

                    this.dispatchAppEvent(HgAppEvents.PHONE_EXTENSION_DELETED, {
                        'extension': match
                    });
                }
            }, this);

            HgCurrentUser['phoneExtensions'].acceptChanges();
        }
    }

    /**
     * Handles availability policy update
     * @param {!AppEvent} e
     * @protected
     */
    handleAvailabilityPolicyUpdate_(e) {
        const distractionLike = e.getPayload();

        if (distractionLike && distractionLike['class'] == DistractionClassType.POLICY_NOTIFICATION) {
            const availability = BaseUtils.isObject(distractionLike['body']) ? distractionLike['body']['availability'] : AvailabilityPolicy.FULL;

            if (HgCurrentUser) {
                HgCurrentUser['availabilityPolicy'] = availability;
            }

            this.dispatchAppEvent(HgAppEvents.POLICY_NOTIFICATION_CHANGE, {'availabilityPolicy': availability});
        }
    }

    /**
     *
     * @param {AppEvent} e
     * @private
     */
    handleChatConnectionChange_(e) {
        const payload = e.getPayload();
        if(payload) {
            HgCurrentUser['canChat'] = payload['isConnected'];
        }
    }

    /**
     * Handle update of ws dependent resources (the event is dispatched after a random number of seconds, 5-25s, after ws
     * reconnects)
     * @param {AppEvent} e
     * @private
     */
    handleUpdateWsDependentResources_(e) {
        this.getLogger().info('Handle hg.HgAppEvents.UPDATE_DATACHANNEL_DEPENDENT_RESOURCES');

        /* reload the user's data */
        this.loadUserData();

    }

    handleLogout_(e) {
        /* clear logged in user account on logout */
        HgCurrentUser.loadData({});
    }
}

/**
 * Static instance property
 * @static
 * @private
 */
const instance = new CurrentUserService();

export default instance;