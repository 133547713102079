import {DomUtils} from "./../../../../../../hubfront/phpnoenc/js/dom/Dom.js";
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {HorizontalStack} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/HorizontalStack.js";
import {DialogButtonSet} from "./../../../../../../hubfront/phpnoenc/js/ui/dialog/Dialog.js";
import {AbstractSettingsDialogView} from "./../../../common/ui/view/AbstractSettingsDialog.js";
import {PersonalInfo} from "./../component/form/myprofile/PersonalInfo.js";
import {InviteTeammates} from "./../component/form/team/InviteTeammates.js";
import {HubgetsPage} from "./../component/form/myprofile/HubgetsPage.js";
import {AccountMenuItemCategories} from "./../../../data/model/common/Enums.js";
import {BrowserServiceForm} from "./../component/form/communicationdevices/BrowserService.js";
import {SettingsEventTypes, SettingsBusyContext} from "./../Enums.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {HgButtonUtils} from "./../../../common/ui/button/Common.js";
import {CommonBusyContexts} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * Creates a new {@see hg.module.settings.view.WizardView} object.
 *
 * @extends {AbstractSettingsDialogView}
 * @unrestricted 
*/
export class WizardView extends AbstractSettingsDialogView {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         *
         * @type {DialogButtonSet}
         * @private
         */
        this.dialogButtonsSet_ = this.dialogButtonsSet_ === undefined ? null : this.dialogButtonsSet_;

        /**
         *
         * @type {hg.module.settings.form.PersonalInfo}
         * @private
         */
        this.personalInfoForm_ = this.personalInfoForm_ === undefined ? null : this.personalInfoForm_;

        /**
         *
         * @type {hg.module.settings.form.InviteTeammates}
         * @private
         */
        this.inviteUsersForm_ = this.inviteUsersForm_ === undefined ? null : this.inviteUsersForm_;

        /**
         * The details about the number of invited users
         * @type {hf.ui.Caption}
         * @private
         */
        this.teamReportCaption_ = this.teamReportCaption_ === undefined ? null : this.teamReportCaption_;

        /**
         * @type {hg.module.settings.form.BrowserServiceForm}
         * @private
         */
        this.browserServiceForm_ = this.browserServiceForm_ === undefined ? null : this.browserServiceForm_;

        /**
         * Form displayed on Hubgets page tab
         * @type {hg.module.settings.form.HubgetsPage}
         * @private
         */
        this.hubgetsPageForm_ = this.hubgetsPageForm_ === undefined ? null : this.hubgetsPageForm_;
    }

    /**
     * Check if current selection is the last enabled tab
     * @return {boolean}
     */
    isLastTabSelected() {
        const items = this.getTabSelector().getItems(),
            selectedIndex = this.getTabSelector().getSelectedIndex(),
            count = items.getCount();

        return selectedIndex == count - 1;
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator;

        this.dialogButtonsSet_ = new DialogButtonSet();
        this.dialogButtonsSet_.addButton(HgButtonUtils.createSecondaryButton(null, translator.translate('previous'), false, {
            'name': WizardView.Button.PREVIOUS
        }));
        this.dialogButtonsSet_.addButton(HgButtonUtils.createPrimaryButton(null, translator.translate('NEXT'), false, {
            'name': WizardView.Button.NEXT,
            'disabled': true,
            'loader': {
                'extraCSSClass': 'grayscheme'
            }
        }));

        this.teamReportCaption_ = new Caption({
            'extraCSSClass'	: 'hg-team-users-report-caption'
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.dialogButtonsSet_);
        this.dialogButtonsSet_ = null;

        BaseUtils.dispose(this.personalInfoForm_);
        this.personalInfoForm_ = null;

        BaseUtils.dispose(this.inviteUsersForm_);
        this.inviteUsersForm_ = null;

        BaseUtils.dispose(this.teamReportCaption_);
        this.teamReportCaption_ = null;

        BaseUtils.dispose(this.browserServiceForm_);
        this.browserServiceForm_ = null;

        BaseUtils.dispose(this.hubgetsPageForm_);
        this.hubgetsPageForm_ = null;
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this, SettingsEventTypes.SERVICE_INSTALL, this.handleServiceInstall_)
            .listen(this, SettingsEventTypes.SERVICE_PERMISSION, this.handleServicePermission_);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        const translator = Translator,
            nextBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.NEXT),
            prevBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.PREVIOUS);

        this.setBinding(nextBtn, {'set': nextBtn.setEnabled},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isValid();
                    }
                }
            }
        );

        /* hide next button if wizzard cannot proceed due to restrictions
         * e.g.: extension tab cannot be completed if there are no phone terminal extensions */
        this.setBinding(nextBtn, {'set': nextBtn.setVisible},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        return model && model.isSavable(true);
                    }
                }
            }
        );

        this.setBinding(prevBtn, {'set': prevBtn.setCaption},
            {
                'converter': {
                    'sourceToTargetFn' : function (model) {
                        const caption = (model && model.isSavable(true)) ? 'previous' : 'leave';
                        return  translator.translate(caption);
                    }
                }
            }
        );

        this.setBinding(this.teamReportCaption_, {'set': this.teamReportCaption_.setVisible}, {
            'sourceProperty': 'currentCategory',
            'converter'		: {
                'sourceToTargetFn': function(category) {
                    return category === AccountMenuItemCategories.INVITE_TEAM;
                }
            }
        });

        this.setBinding(this.teamReportCaption_, {'set': this.teamReportCaption_.setContent}, {
            'sourceProperty': 'teamReport',
            'converter': {
                'sourceToTargetFn' : (teamReport) => {
                    if (teamReport == null || teamReport['invited'] == null) {
                        return null;
                    }

                    const content = document.createDocumentFragment(),
                        formatter = new Intl.NumberFormat(HgAppConfig.LOCALE, {style: 'decimal'});
                    let metadataInfo = '';

                    const activeUsers = teamReport['active'],
                        invitedUsers = teamReport['invited'],

                        activeUsersCountFormatted = formatter.format(activeUsers),
                        invitedUsersCountFormatted = formatter.format(invitedUsers);

                    if (activeUsers > 0 && invitedUsers > 0) {
                        metadataInfo = translator.translate("number_active_invited", [activeUsersCountFormatted, invitedUsersCountFormatted]);
                    }
                    else if (activeUsers > 0) {
                        metadataInfo = translator.translate("number_active", [activeUsersCountFormatted]);
                    }
                    else if (invitedUsers > 0) {
                        metadataInfo = invitedUsers == 1 ?
                            translator.translate("1_person_invited") :
                            translator.translate("number_invited", [invitedUsersCountFormatted]);
                    }

                    if (!StringUtils.isEmptyOrWhitespace(metadataInfo)) {
                        content.appendChild(DomUtils.createDom('span', '', metadataInfo));
                    }

                    return content;
                }
            }
        });
    }

    /** @inheritDoc */
    createDialog() {
        const dialog = super.createDialog();
        dialog.addExtraCSSClass(['hg-setup-wizard-dialog']);

        return dialog;
    }

    /** @inheritDoc */
    createDialogButtonSet() {
        return this.dialogButtonsSet_;
    }

    /** @inheritDoc */
    createDialogFooterDom(model, footerControl) {
        if (this.footer == null) {
            this.footer = new HorizontalStack({'extraCSSClass': 'hg-setup-wizard-dialog-footer-content'});
            this.footer.addChild(this.teamReportCaption_, true);
        }

        return this.footer;
    }

    /** @inheritDoc */
    enableIsBusyBehavior(enable, opt_busyContext) {
        switch (opt_busyContext) {
            case CommonBusyContexts.SUBMIT:
                // mark the submit button
                const submitBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.NEXT);

                submitBtn.setBusy(enable);
                break;

            case SettingsBusyContext.LOAD_TAB:
                const currentForm = this.contentContainer.getContent();

                if (currentForm) {
                    currentForm.setBusy(enable, opt_busyContext);
                }

                break;

            default:
                if (enable) {
                    const busyIndicator = this.getBusyIndicator(opt_busyContext);
                    this.setContentInternal(busyIndicator);
                } else {
                    this.setContentInternal(null);
                }

                //hg.module.settings.view.WizardView.superClass_.enableIsBusyBehavior.call(this, enable, opt_busyContext);
                break;
        }
    }

    /** @inheritDoc */
    onButtonAction(button) {
        super.onButtonAction(button);

        const presenter = /** @type {WizardPresenter} */(this.getPresenter());

        switch (button) {
            case WizardView.Button.PREVIOUS:
                const prevBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.PREVIOUS);
                if (prevBtn.getCaption() == 'leave') {
                    presenter.cancelSetup();
                }
                else {
                    this.selectPreviousTab();
                }
                break;

            case WizardView.Button.NEXT:
                /* incremental submit */
                this.onSubmit();
                break;
        }

        return false;
    }

    /** @inheritDoc */
    onCurrentCategoryChange(settingCategory) {
        super.onCurrentCategoryChange(settingCategory);

        /* update the content */
        this.setContentInternal(this.getContent_(settingCategory));

        /* update the footer button set */
        this.updateDialogButtonsSet_();

        const model = this.getModel();
        if (settingCategory == AccountMenuItemCategories.SERVICES &&
            model != null) {
            /* trigger services viewmodel loading */
            /** @type {WizardPresenter}*/(this.getPresenter()).loadServices();
        }
        else if(settingCategory == AccountMenuItemCategories.HUBGETS_PAGE &&
            model != null && model['publicProfile'] == null) {
            /* trigger publicProfile loading */
            /** @type {WizardPresenter}*/(this.getPresenter()).loadPublicProfile();
        }
    }

    /**
     * Update the content according to the current selected category
     * @param {AccountMenuItemCategories} settingCategory
     * @return {hf.ui.UIComponent}
     * @private
     */
    getContent_(settingCategory) {
        let content = null;

        switch (settingCategory) {
            case AccountMenuItemCategories.PERSONAL_INFO:
                if (this.personalInfoForm_ == null) {
                    this.personalInfoForm_ = new PersonalInfo();

                    this.setBinding(this.personalInfoForm_, {'set': this.personalInfoForm_.setModel}, '');
                }

                content = this.personalInfoForm_;

                break;

            case AccountMenuItemCategories.INVITE_TEAM:
                if (this.inviteUsersForm_ == null) {
                    this.inviteUsersForm_ = new InviteTeammates({'isInSetupWizard': true});

                    this.setBinding(this.inviteUsersForm_, {'set': this.inviteUsersForm_.setModel}, '');
                }

                content = this.inviteUsersForm_;

                break;

            case AccountMenuItemCategories.SERVICES:
                if (this.browserServiceForm_ == null) {
                    this.browserServiceForm_ = new BrowserServiceForm({'isInSetupWizard': true});

                    this.setBinding(this.browserServiceForm_, {'set': this.browserServiceForm_.setModel}, 'services');
                }

                content = this.browserServiceForm_;
                break;

            case AccountMenuItemCategories.HUBGETS_PAGE:
                if (this.hubgetsPageForm_ == null) {
                    this.hubgetsPageForm_ = new HubgetsPage();

                    this.setBinding(this.hubgetsPageForm_, {'set': this.hubgetsPageForm_.setModel}, '');
                }

                content = this.hubgetsPageForm_;

                break;
        }

        return content;
    }

    /**
     *
     * @private
     */
    updateDialogButtonsSet_() {
        const model = this.getModel(),
            settingsCategories = model ? model['settingsCategories'] : null;

        if(settingsCategories != null) {
            const translator = Translator,
                nextBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.NEXT),
                prevBtn = this.dialogButtonsSet_.getButtonByName(WizardView.Button.PREVIOUS),
                count = settingsCategories.getCount(),
                selectedIndex = this.getTabSelector().getSelectedIndex();

            if(prevBtn != null) {
                prevBtn.setVisible(selectedIndex != 0);
            }

            if (nextBtn != null) {
                const caption = (selectedIndex == count - 1) ?
                    translator.translate("thats_it") : translator.translate('NEXT');

                nextBtn.setCaption(caption);
            }
        }
    }

    /**
     * Handles service permission request
     * @param {hf.events.Event} e
     * @private
     */
    handleServicePermission_(e) {
        const service = /** @type {BrowserServices} */(e.getProperty('service'));

        if (!StringUtils.isEmptyOrWhitespace(service)) {
            /** @type {WizardPresenter} */(this.getPresenter()).requestServicePermission(service);
        }
    }

    /**
     * Handles service install request
     * @param {hf.events.Event} e
     * @private
     */
    handleServiceInstall_(e) {
        const service = /** @type {BrowserServices} */(e.getProperty('service'));

        if (!StringUtils.isEmptyOrWhitespace(service)) {
            /** @type {WizardPresenter} */(this.getPresenter()).installService(service);
        }
    }
};
//hf.app.ui.IView.addImplementation(hg.module.settings.view.WizardView);
/**
 * Specific button names
 * @enum {string}
 */
WizardView.Button = {
    PREVIOUS    : 'prev',
    NEXT        : 'next'
};