import {Event} from "./../../../../../../hubfront/phpnoenc/js/events/Event.js";
import {UIComponentEventTypes} from "./../../../../../../hubfront/phpnoenc/js/ui/Consts.js";

import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {UIComponent} from "./../../../../../../hubfront/phpnoenc/js/ui/UIComponent.js";
import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";
import {Search, SearchFieldEventType} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Search.js";
import {FormFieldLabelLayout} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Enums.js";
import {Checkbox} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Checkbox.js";
import {ListDataSource} from "./../../../../../../hubfront/phpnoenc/js/data/datasource/ListDataSource.js";
import {ObservableChangeEventName} from "./../../../../../../hubfront/phpnoenc/js/structs/observable/ChangeEvent.js";
import {QueryData} from "./../../../../../../hubfront/phpnoenc/js/data/QueryData.js";
import {QueryDataResult} from "./../../../../../../hubfront/phpnoenc/js/data/dataportal/QueryDataResult.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {PeopleList, PeopleListDisplayMode, PeopleListEventType} from "./../PeopleList.js";
import {HgAppConfig} from "./../../../app/Config.js";
import {AutoCompleteFindMode} from "./../../../../../../hubfront/phpnoenc/js/ui/form/field/Autocomplete.js";
import {StringUtils} from "../../../../../../hubfront/phpnoenc/js/string/string.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 *
 * @enum {string}
 * @readonly
 */
export const PeopleSelectorEventTypes = {
    /**
     * Dispatched when a person is removed from the selection list
     * @event PeopleSelectorEventTypes.SELECT
     */
    SELECT: StringUtils.createUniqueString('__hf_common_ui_people_selector_select'),

    /**
     * Dispatched when a person is added to the selection list
     * @event PeopleSelectorEventTypes.UNSELECT
     */
    UNSELECT: StringUtils.createUniqueString('__hf_common_ui_people_selector_unselect'),

    /**
     * Dispatched when all the people are selected
     * @event PeopleSelectorEventTypes.SELECT_ALL
     */
    SELECT_ALL: StringUtils.createUniqueString('__hf_common_ui_people_selector_select_all'),

    /**
     * Dispatched when all the people are unselected
     * @event PeopleSelectorEventTypes.UNSELECT_ALL
     */
    UNSELECT_ALL: StringUtils.createUniqueString('__hf_common_ui_people_selector_unselect_all')
};

/**
 * Creates a {@see hg.common.ui.PeopleSelector} object.
 *
 * @extends {UIComponent}
 * @unrestricted 
*/
export class PeopleSelector extends UIComponent {
    /**
     * @param {!Object=} opt_config The configuration object.
     *   @param {string=} opt_config.caption
     *   @param {string=} opt_config.placeholder
     *   @param {Function=} opt_config.peopleSuggestionsProvider
     *   @param {boolean=} opt_config.canSelectAll
     *   @param {string=} opt_config.selectAllCaption
     *   @param {boolean=} opt_config.allowsSingleSelectionOnly
     *   @param {string=} opt_config.selectionTitle
     *
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * Search field
         * @type {hf.ui.form.field.Search}
         * @private
         */
        this.search_;

        /**
         * List of selected users
         * @type {hg.common.ui.PeopleList}
         * @private
         */
        this.selection_;

        /**
         * The title of the selection
         * @type {hf.ui.Caption}
         * @private
         */
        this.selectionTitle_;

        /**
         * Send to all team members checkbox
         * @type {hf.ui.form.field.Checkbox}
         * @private
         */
        this.selectAll_;

        /**
         * @type {Function}
         * @private
         */
        this.peopleSuggestionsProvider_;
    }

    /**
     *
     * @param {Function} provider
     */
    setPeopleSuggestionsProvider(provider) {
        this.peopleSuggestionsProvider_ = provider;
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['canSelectAll'] = opt_config['canSelectAll'] || false;
        opt_config['selectAllCaption'] = opt_config['selectAllCaption'] || 'Select All';
        opt_config['allowsSingleSelectionOnly'] = opt_config['allowsSingleSelectionOnly'] || false;

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    init(opt_config = {}) {
        super.init(opt_config);

        const translator = Translator,
            baseCssClass = this.getBaseCSSClass();

        this.setPeopleSuggestionsProvider(opt_config['peopleSuggestionsProvider']);

        const searchConfig = {
            'extraCSSClass': baseCssClass + '-' + 'search',
            'displayField': 'fullName',
            'itemContentFormatter': function (person) {
                return person ? new HgPartyListItemContent({'displayType': false, 'model': person}) : null;
            },
            // 'emptyContentFormatter': function () {
            //     return translator.translate('no_teammate_found');
            // },
            'popup': {
                'showArrow': true,
                'extraCSSClass': [baseCssClass + '-' + 'search-popup', 'hg-popup']
            },
            'findMode': AutoCompleteFindMode.SEARCH,
            'minChars': 3,
            'selectSuggestionOnHighlight': true,
            'hasTriggers': false,
            'clearValueOnSearch': false
        };

        if(!StringUtils.isEmptyOrWhitespace(opt_config['caption'])) {
            searchConfig['label'] = {
                'content': translator.translate(opt_config['caption']),
                'layout' : 'top'
            }
        }

        if(!StringUtils.isEmptyOrWhitespace(opt_config['placeholder'])) {
            searchConfig['placeholder'] = translator.translate(opt_config['placeholder']);
        }

        this.search_ = new Search(searchConfig);

        this.search_.setItemsSource(new ListDataSource({
            'prefetch'     : false,
            'dataProvider' : this.searchPeople_.bind(this),
            'fetchCriteria': {
                'fetchSize': HgAppConfig.DEFAULT_FETCH_SIZE
            }
        }));

        if(!StringUtils.isEmptyOrWhitespace(opt_config['selectionTitle'])) {
            this.selectionTitle_ = new Caption({
                'content'      : translator.translate(opt_config['selectionTitle']),
                'extraCSSClass':  baseCssClass + '-' + 'list-title',
                'hidden'       : true
            });
        }

        this.selection_ = new PeopleList({
            'displayMode'   : PeopleListDisplayMode.EDIT,
            'extraCSSClass' : baseCssClass + '-' + 'list',
            'isScrollable'  : true
        });

        if(opt_config['canSelectAll']) {
            this.selectAll_ = new Checkbox({
                'label'         : {
                    'content': translator.translate(opt_config['selectAllCaption']),
                    'layout' : FormFieldLabelLayout.LEFT
                },
                'extraCSSClass':  baseCssClass + '-' + 'select-all',
                'checked'      : false
            });
        }
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.search_);
        this.search_ = null;

        BaseUtils.dispose(this.selection_);
        this.selection_ = null;

        BaseUtils.dispose(this.selectionTitle_);
        this.selectionTitle_ = null;

        BaseUtils.dispose(this.selectAll_);
        this.selectAll_ = null;

        this.peopleSuggestionsProvider_ = null;
    }

    /** @inheritDoc */
    getDefaultIdPrefix() {
        return 'hg-people-selector';
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-people-selector';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.search_, true);
        if(this.selectAll_) {
            this.addChild(this.selectAll_, true);
        }

        if(this.selectionTitle_) {
            this.addChild(this.selectionTitle_, true);
        }

        this.addChild(this.selection_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();

        this.getHandler()
            .listen(this.search_, UIComponentEventTypes.ACTION, this.handleAction_)
            .listen(this.search_, SearchFieldEventType.OPTION_SELECT, this.handleSelect_)
            .listen(this.selection_, PeopleListEventType.DELETE, this.handleUnselect_);

        if(this.selectAll_) {
            this.getHandler().listen(this.selectAll_, [UIComponentEventTypes.CHECK, UIComponentEventTypes.UNCHECK], this.handleSelectAll_);
        }
    }

    /** @inheritDoc */
    exitDocument() {
        super.exitDocument();

        if(this.selectAll_) {
            this.selectAll_.setChecked(false);
        }
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.selection_, {'set': this.selection_.setModel}, '');
    }

    /** @inheritDoc */
    listenToModelEvents(model) {
        this.getHandler()
            .listen(/**@type {hf.structs.observable.ObservableCollection}*/(model), ObservableChangeEventName, this.handleModelCollectionChange_);
    }

    /** @inheritDoc */
    unlistenFromModelEvents(model) {
        this.getHandler()
            .unlisten(/**@type {hf.structs.observable.ObservableCollection}*/(model), ObservableChangeEventName, this.handleModelCollectionChange_);
    }

    /**
     * Handles field action button press
     * @param {hf.events.Event} e
     * @private
     */
    handleModelCollectionChange_(e) {
        const modelCollection = /**@type {hf.structs.ICollection}*/(this.getModel());

        if(this.selectionTitle_) {
            this.selectionTitle_.setVisible(modelCollection != null && modelCollection.getCount() > 0);
        }
    }

    /**
     *
     * @param {!hf.data.criteria.FetchCriteria} searchCriteria
     * @private
     */
    searchPeople_(searchCriteria) {
        const model = this.getModel();

        if(model == null || !BaseUtils.isFunction(this.peopleSuggestionsProvider_)) {
            return Promise.resolve(QueryDataResult.empty());
        }

        const alreadySelectedPeople = /**@type {hf.structs.ICollection}*/(model).getAll().map(function (person) {
            return person['personId']
        });

        return this.peopleSuggestionsProvider_.call(null, searchCriteria)
            .then((queryResult) => {
                /* must exclude already selected ppl locally */
                if (queryResult.getCount() == 0) {
                    return queryResult;
                }

                let query = new QueryData(queryResult.getItems());

                query = query.filter({
                    'predicate': function(person) {
                        return alreadySelectedPeople.indexOf(person['personId']) == -1;
                    }
                });

                return new QueryDataResult({
                    'items': query.toArray(),
                    'totalCount': queryResult.getTotalCount(),
                    'nextChunk':  queryResult.getNextChunk(),
                    'prevChunk':  queryResult.getPrevChunk()
                });
            });
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     * @suppress {visibility} searchSource
     */
    handleAction_(e) {
        if(!this.search_.isOpen()) {
            this.search_.searchSource("");
        }
    }

    /**
     * Handle user suggestion selection
     * @param {hf.events.Event} e The event
     * @private
     */
    handleSelect_(e) {
        const person = e.getProperty("filterValue"),
            peopleList = /**@type {hf.structs.observable.ObservableCollection}*/(this.getModel());

        /* clear value on suggestion selection */
        this.search_.clearValue(true);

        if (person == null || peopleList == null) {
            return;
        }

        /* focus the search once again, allow user to select multiple users easier */
        this.search_.focus();

        if(this.getConfigOptions()['allowsSingleSelectionOnly']) {
            peopleList.clear();
        }

        peopleList.add(person);

        const selectEvent = new Event(PeopleSelectorEventTypes.SELECT);
        selectEvent.addProperty('item', person);
        this.dispatchEvent(selectEvent);
    }

    /**
     * Handle user suggestion un-selection
     * @param {hf.events.Event} e The event
     * @private
     */
    handleUnselect_(e) {
        const item = e['item'],
            peopleList = /**@type {hf.structs.observable.ObservableCollection}*/(this.getModel());

        if (item && peopleList && peopleList.contains(item)) {
            peopleList.remove(item);

            const unselectEvent = new Event(PeopleSelectorEventTypes.UNSELECT);
            unselectEvent.addProperty('item', item);
            this.dispatchEvent(unselectEvent);
        }
    }

    /**
     *
     * @param {hf.events.Event} e
     * @private
     */
    handleSelectAll_(e) {
        let selectAll = this.selectAll_.isChecked();

        this.search_.setEnabled(!selectAll);
        this.selection_.setVisible(!selectAll);

        if(selectAll) {
            this.dispatchEvent(PeopleSelectorEventTypes.SELECT_ALL)
        }
        else {
            this.dispatchEvent(PeopleSelectorEventTypes.UNSELECT_ALL)
        }
    }
};