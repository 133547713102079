
import {BaseUtils} from "./../../../../../../hubfront/phpnoenc/js/base.js";
import {LayoutContainer} from "./../../../../../../hubfront/phpnoenc/js/ui/layout/LayoutContainer.js";
import {HgPartyListItemContent} from "./../list/HgPartyListItemContent.js";
import {RecipientSelector} from "./../labs/RecipientSelector.js";
import {Header} from "./Header.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {LayoutContainer}
 * @unrestricted 
*/
export class ForwardContent extends LayoutContainer {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        super(opt_config);

        /**
         * @type {hg.common.ui.forward.Header}
         * @private
         */
        this.header_ = this.header_ === undefined ? null : this.header_;

        /**
         *
         * @type {hg.common.ui.RecipientSelector}
         * @private
         */
        this.recipientsSelector_ = this.recipientsSelector_ === undefined ? null : this.recipientsSelector_;
    }

    /** @inheritDoc */
    init(opt_config = {}) {


        super.init(opt_config);

        const translator = Translator;

        this.header_ = new Header();

        this.recipientsSelector_ = new RecipientSelector({
            'extraCSSClass': 'hg-forward-recipient-selector',
            'search': {
                'placeholder'   : translator.translate('search_for_recipients'),
                'itemStyle'     : function (model) {
                    const extraCss = ['hg-forward-recipient-suggestion-item'];

                    if(model && model['isSelected']) {
                        extraCss.push('is-selected');
                    }

                    return extraCss;
                }
            },
            'selection': {
                'canRemove': true,
                'itemContentFormatter'  : function (model) {
                    return model ? new HgPartyListItemContent({'model': model}) : null;
                },
                'emptyContentFormatter' : function () {
                    //return translator.translate("search_to_forward");
                    return ' ';
                }
            }
        });
    }

    /** @inheritDoc */
    disposeInternal() {
        super.disposeInternal();

        BaseUtils.dispose(this.header_);
        this.header_ = null;

        BaseUtils.dispose(this.recipientsSelector_);
        this.recipientsSelector_ = null;
    }

    /** @inheritDoc */
    getDefaultBaseCSSClass() {
        return 'hg-forward-content';
    }

    /** @inheritDoc */
    createDom() {
        super.createDom();

        this.addChild(this.header_, true);
        this.addChild(this.recipientsSelector_, true);
    }

    /** @inheritDoc */
    enterDocument() {
        super.enterDocument();
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();

        this.setBinding(this.header_, {'set': this.header_.setModel}, '');

        this.setBinding(this.recipientsSelector_, {'set': this.recipientsSelector_.setModel}, '');
    }
};