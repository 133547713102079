import {Caption} from "./../../../../../../hubfront/phpnoenc/js/ui/Caption.js";

import {MessageList} from "./../message/MessageList.js";
import Translator from "../../../../../../hubfront/phpnoenc/js/translator/Translator.js";

/**
 * @extends {MessageList}
 * @unrestricted 
*/
export class CommentList extends MessageList {
    /**
     * @param {!Object=} opt_config The optional configuration object.
    */
    constructor(opt_config = {}) {
        /* Call the base class constructor */
        super(opt_config);
    }

    /** @inheritDoc */
    normalizeConfigOptions(opt_config = {}) {
        opt_config['emptyContentFormatter'] = opt_config['emptyContentFormatter'] ||  function(){
            let translator = Translator;

            return new Caption({
                'content' : translator.translate('first_toLeave_comment')
            });
        };

        return super.normalizeConfigOptions(opt_config);
    }

    /** @inheritDoc */
    initBindings() {
        super.initBindings();
    }
};