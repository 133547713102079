import { Disposable } from '../../disposable/Disposable.js';
import { BaseUtils } from '../../base.js';
import { ServiceLocator } from '../servicelocator/ServiceLocator.js';
import { AppServiceLocator } from '../servicelocator/AppServiceLocator.js';

/**
 * Creates a new Bootstrapper object.
 *
 * @augments {Disposable}
 *
 */
export class BootstrapperBase extends Disposable {
    constructor() {
        // new.target is not supported yet by Closure Compiler
        // if (new.target === hf.app.bootstrap.BootstrapperBase) {
        //     throw new TypeError("Cannot instantiate abstract class");
        // }

        /* Call the base class constructor */
        super();

        if (this.constructor === BootstrapperBase) {
            throw new TypeError('Cannot instantiate abstract class');
        }

        /**
         * @type {boolean}
         * @private
         * @default false
         */
        this.hasRun_ = false;
    }

    /**
     * Starts the bootstrapping process.
     */
    run() {
        if (this.hasRun_) {
            return;
        }

        this.config();

        this.hasRun_ = true;
    }

    /**
     * Defines the configuration process.
     * This method may be overriden by the inheritors
     * in order to add some more configuration steps.
     *
     * @protected
     */
    config() {
        this.configureServiceLocator();
    }

    /**
     * Configures the service locator.
     *
     * @returns {hf.app.ServiceLocator}
     * @protected
     */
    configureServiceLocator() {
        const serviceLocator = new AppServiceLocator();

        ServiceLocator.load(serviceLocator);

        this.registerAppStates(serviceLocator);
        this.registerAppEvents(serviceLocator);
        this.registerDomainServices(serviceLocator);
        this.registerViews(serviceLocator);
        this.registerDisplayRegions(serviceLocator);

        return serviceLocator;
    }

    /**
     * @param {hf.app.ServiceLocator} serviceLocator
     * @protected
     */
    registerAppStates(serviceLocator) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.ServiceLocator} serviceLocator
     * @protected
     */
    registerAppEvents(serviceLocator) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.ServiceLocator} serviceLocator
     * @protected
     */
    registerDomainServices(serviceLocator) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.ServiceLocator} serviceLocator
     * @protected
     */
    registerViews(serviceLocator) { throw new Error('unimplemented abstract method'); }

    /**
     * @param {hf.app.ServiceLocator} serviceLocator
     * @protected
     */
    registerDisplayRegions(serviceLocator) { throw new Error('unimplemented abstract method'); }

    /**
     * @inheritDoc
     */
    disposeInternal() {
        super.disposeInternal();

        const serviceLocator = ServiceLocator.getLocator();
        BaseUtils.dispose(serviceLocator);
    }
}
